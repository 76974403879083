export class ApiRoutes {
    public static readonly endpoint: string = '/api';

    private static readonly authBaseUrl: string = '/auth';

    private static readonly accountBaseUrl: string = '/account';

    private static readonly companyBaseUrl: string = '/company';

    static Auth = class {
        public static readonly login: string = `${ApiRoutes.authBaseUrl}/loginWeb`;
        public static readonly logout: string = `${ApiRoutes.authBaseUrl}/logout`;
        public static readonly refreshToken: string = `${ApiRoutes.authBaseUrl}/refreshToken`;
    };

    static Account = class {
        public static readonly resetPassword: string = `${ApiRoutes.accountBaseUrl}/ResetPassword`;
        public static readonly updatePassword: string = `${ApiRoutes.accountBaseUrl}/UpdatePassword`;
        public static readonly completeRegistration: string = `${ApiRoutes.accountBaseUrl}/CompleteRegistration`;
        public static readonly addCompanyAdmin: string = `${ApiRoutes.accountBaseUrl}/AddCompanyAdmin`;
        public static readonly editCompanyAdmin: string = `${ApiRoutes.accountBaseUrl}/EditCompanyAdmin`;
        public static readonly addCompanyUser: string = `${ApiRoutes.accountBaseUrl}/AddCompanyUser`;
        public static readonly editCompanyUser: string = `${ApiRoutes.accountBaseUrl}/EditCompanyUser`;
        public static readonly editServiceUser: string = `${ApiRoutes.accountBaseUrl}/EditServiceUser`;
        public static readonly deleteUser: string = `${ApiRoutes.accountBaseUrl}/DeleteUser`;
        public static readonly getAllServiceUser: string = `${ApiRoutes.accountBaseUrl}/GetAllServiceUsers`;
        public static readonly addServiceUser: string = `${ApiRoutes.accountBaseUrl}/AddServiceUser`;
        public static readonly deleteVendor: string = `${ApiRoutes.accountBaseUrl}/DeleteVendor`;
        public static readonly addVendor: string = `${ApiRoutes.accountBaseUrl}/AddVendor`;
        public static readonly completeRegistrationVendor: string = `${ApiRoutes.accountBaseUrl}/CompleteRegistrationVendor`;
        public static readonly editVendorForCompany: string = `${ApiRoutes.accountBaseUrl}/EditVendorForCompany`;
        public static readonly getProfile:string=`${ApiRoutes.accountBaseUrl}/GetProfile`;
        public static readonly editProfile:string=`${ApiRoutes.accountBaseUrl}/EditProfile`;
        public static readonly getAllSalesUser: string = `${ApiRoutes.accountBaseUrl}/GetAllSalesUsers`;
        public static readonly editSalesUser: string = `${ApiRoutes.accountBaseUrl}/EditSalesUser`;
        public static readonly addSalesUser: string = `${ApiRoutes.accountBaseUrl}/AddSalesUser`;
    }

    static Company = class {
        public static readonly getAllCompanyAdmin: string = `${ApiRoutes.companyBaseUrl}/GetAllCompanyAdmins`;
        public static readonly getAllCompanyAdminForCompany: string = `${ApiRoutes.companyBaseUrl}/GetAllCompanyAdminsForCompany`;
        public static readonly getAllCompanyUser: string = `${ApiRoutes.companyBaseUrl}/GetAllCompanyUsers`;
        public static readonly getAllVendorsForCompany: string = `${ApiRoutes.companyBaseUrl}/GetAllVendorsForCompany`;
        public static readonly getAllEmailReportConfigurationsForCompany: string = `${ApiRoutes.companyBaseUrl}/GetAllEmailReportConfigurationsForCompany`;
        public static readonly addEditEmailReportConfigurationForCompany: string = `${ApiRoutes.companyBaseUrl}/AddEditEmailReportConfigurationForCompany`;
        public static readonly deleteEmailReportConfigurationForCompany: string = `${ApiRoutes.companyBaseUrl}/DeleteEmailReportConfigurationForCompany`;
    };
}
