import { AqsFlushFloorTunnelDesignEnum } from './../../../../enums/aqs-flush-floor.enums';
import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CanvasFlushFloorSupportFunction } from '../aqs-canvas-flushfloor-support-functions';
import { ConvertDataFunctions } from './../../../../../common';
import { AqsFlushFloorCalculatedValues, AqsFlushFloorViewModel, AqsRoundStorageWiewModel } from './../../../../models';

@Component({
  selector: 'greensleeves-aqs-round-flush-design-four-lane-special',
  templateUrl: './aqs-round-flush-design-four-lane-special.component.html',
  styles: []
})
export class AqsRoundFlushDesignFourLaneSpecialComponent implements OnInit {
  @Input() storageConfiguration: AqsRoundStorageWiewModel;
  @Input() flushFloorConfiguration: AqsFlushFloorViewModel;
  @Input() calcValues: AqsFlushFloorCalculatedValues;

  @Output() currentPageErrorChange = new EventEmitter<boolean>();
  @Output() onChangeFlushFloorModel = new EventEmitter();

  @Input() flushFloorTunnelFillColor: string;
  @Input() flushFloorTunnelStrokeColor: string

  @ViewChild('flushFloorCanvas', { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;

  _a: string;
  _b: number;
  _c: number;
  _d: number;
  _e: number;
  _f: number;
  _g: number;
  _h: number;
  _i: string;
  _iStandard: string;
  _j: string;
  _k: string;
  private jj: number;
  private kk: number;

  _errorAEIUnableToDraw: boolean = false;
  _errorALessOrEqualsE: boolean = false;
  _errorBToLarge: boolean = false;
  _errorCToLarge: boolean = false;
  _errorDToLarge: boolean = false;
  _errorDMoreThenC: boolean = false;
  _errorIGBeyondBinDia: boolean = false;
  _errorVelocities: boolean = false;
  private pageError: boolean = false;

  _supportHeightOptions = CanvasFlushFloorSupportFunction.supportHeightOptions;

  _tunnelSupportOptions1: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport1: { totalTunnelSupport: number, span: number };

  _tunnelSupportOptions2: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport2: { totalTunnelSupport: number, span: number };

  _tunnelSupportOptions3: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport3: { totalTunnelSupport: number, span: number };

  _velocityTable: { value: string, crossSectional: number, surfaceVelocity: number, error: boolean }[];

  _columnsView = CanvasFlushFloorSupportFunction.velocitiesFor2FanTableColumns;

  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;
  private scale: number;

  private get isFourLaneSpecial() {
    return this.flushFloorConfiguration.tunnelChoice == AqsFlushFloorTunnelDesignEnum.FourLaneSpecial
  }
  constructor() { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;
    if (this.flushFloorConfiguration.aa == null) {
      this.calculatedRecommendationParameters();
    }

    this.fillInputValues();
  }

  onChangeValueA() {
    if (this._a != '') {
      let splitStrings = this._a.split('-');
      let val = parseFloat(splitStrings[0]);
      if (!isNaN(val) && (val >= 2 && val < 1000)) {
        this.flushFloorConfiguration.aa = val * 12;
        if (splitStrings.length > 1) {
          let index = this._a.indexOf('-');
          val = parseFloat(this._a.slice(++index));
          if (!isNaN(val)) {
            this.flushFloorConfiguration.aa += val;
          }
        }
        this.calcJ();
        this.checkError();
        this.calcTunnelSupport(2);
        this.reDrawSchema();
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._a = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.aa);
  }

  onChangeValueB() {
    if (this._b >= 2 && this._b % 2 == 0) {
      this.flushFloorConfiguration.bb = this._b * 12;
      this.calcJ();
      this.calculateVelocities();
      this.checkError();
      this.calcTunnelSupport(2);
      this.reDrawSchema();
      this.onChangeFlushFloorModel.emit();
    }
    else this._b = Math.round(this.flushFloorConfiguration.bb / 12);
  }

  onChangeValueC() {
    if (this._c >= 2 && this._c % 2 == 0) {
      this.flushFloorConfiguration.cc = this._c * 12;
      this.calcK();
      this.calculateVelocities();
      this.checkError();
      this.calcTunnelSupport(1);
      this.reDrawSchema();
      this.onChangeFlushFloorModel.emit();
    }
    else this._c = Math.round(this.flushFloorConfiguration.cc / 12);
  }

  onChangeValueD() {
    if (this._d >= 2 && this._d % 2 == 0) {
      this.flushFloorConfiguration.dd = this._d * 12;
      this.calculateVelocities();
      this.checkError();
      this.reDrawSchema();
      this.isFourLaneSpecial && this.calcTunnelSupport(1);
      this.calcTunnelSupport(3);
      this.onChangeFlushFloorModel.emit();
    }
    else this._d = Math.round(this.flushFloorConfiguration.dd / 12);
  }

  onChangeValueE() {
    if (this._e != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._e, this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.ee) {
        this.flushFloorConfiguration.ee = calcValue;
        this.calculateVelocities();
        this.checkError();
        this.reDrawSchema();
        this.calcTunnelSupport(2);
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._e = this.flushFloorConfiguration.ee;
  }

  onChangeValueF() {
    if (this._f != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._f, this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.ff) {
        this.flushFloorConfiguration.ff = calcValue;
        this.calculateVelocities();
        this.calcTunnelSupport(1);
        this.isFourLaneSpecial && this.calcTunnelSupport(3);
        this.reDrawSchema();
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._f = this.flushFloorConfiguration.ff;
  }

  onChangeValueG() {
    if (this._g != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._g, this.isFourLaneSpecial ? this.calcValues.maxWideTunnelWidth : this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.gg) {
        this.flushFloorConfiguration.gg = calcValue;
        this.calculateVelocities();
        this.checkError();
        this.reDrawSchema();
        this.calcTunnelSupport(3);
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._g = this.flushFloorConfiguration.gg;
  }

  onChangeValueH() {
    if (this._h >= 0 && this._h <= 100 && this._h != null) {
      this.flushFloorConfiguration.jj = this._h;
      this.flushFloorConfiguration.hh = (this.calcValues.rad * this._h) / 100;
      this.reDrawSchema();
      this.onChangeFlushFloorModel.emit();
    }
    else this._h = this.flushFloorConfiguration.jj;
  }

  onChangeValueI() {
    if (this._i != '') {
      let splitStrings = this._i.split('-');
      let val = parseFloat(splitStrings[0]);
      if (!isNaN(val) && (val >= 0 && val < 1000)) {
        this.flushFloorConfiguration.ii = val * 12;
        if (splitStrings.length > 1) {
          let index = this._i.indexOf('-');
          val = parseFloat(this._i.slice(++index));
          if (!isNaN(val)) {
            this.flushFloorConfiguration.ii += val;
          }
        }
        this.calcK();
        this.checkError();
        this.calcTunnelSupport(1);
        this.reDrawSchema();
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._i = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.ii);
  }

  onChangeSupportHeight() {
    this.calculateVelocities();
    this.onChangeFlushFloorModel.emit();
  }

  onChangeTunnelSupport(tunnelNumber: number) {
    switch (tunnelNumber) {
      case 1:
        this.flushFloorConfiguration.totalTunnelSupport1 = this._tunnelSupport1.totalTunnelSupport;
        this.flushFloorConfiguration.totalTunnelSupportSpan1 = this._tunnelSupport1.span;
        break;
      case 2:
        this.flushFloorConfiguration.totalTunnelSupport2 = this._tunnelSupport2.totalTunnelSupport;
        this.flushFloorConfiguration.totalTunnelSupportSpan2 = this._tunnelSupport2.span;
        break;
      case 3:
        this.flushFloorConfiguration.totalTunnelSupport3 = this._tunnelSupport3.totalTunnelSupport;
        this.flushFloorConfiguration.totalTunnelSupportSpan3 = this._tunnelSupport3.span;
        break;
    }

    this.onChangeFlushFloorModel.emit();
  }

  numberOnly(event) {
    if (!_isNumberValue(event.key) && event.key !== '.' && event.key !== '-' && event.key !== 'Enter') {
      event.preventDefault();
    }
  }

  private calculatedRecommendationParameters() {
    [this.flushFloorConfiguration.supportHeight1, this.flushFloorConfiguration.ee] = this.calcValues.calcMainTunnelWidth(this.calcValues.maxTunnelWidth);
    this.flushFloorConfiguration.ff = this.flushFloorConfiguration.ee;
    this.flushFloorConfiguration.supportHeight2 = this.flushFloorConfiguration.supportHeight1;
    this.flushFloorConfiguration.jj = 75;//insted of HHH
    this.flushFloorConfiguration.hh = (this.flushFloorConfiguration.jj * this.calcValues.rad) / 100;
    this.flushFloorConfiguration.aa = Math.floor((this.calcValues.rad * 2) / 5);
    this.flushFloorConfiguration.bb = Math.round((Math.sqrt(Math.pow(this.flushFloorConfiguration.hh, 2) - Math.pow(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.ee / 2, 2)) * 2) / 24) * 24;
    if (this.flushFloorConfiguration.bb < 24) this.flushFloorConfiguration.bb = 24;
    //1260 BB=CINT((SQR(HH^2-(AA/2+EE/2)^2)*2)/24)*24: IF BB<24 THEN BB=24
    this.flushFloorConfiguration.ii = Math.floor(this.calcValues.rad * 2 * 0.202);
    //1270 II=INT(RAD*2*.202)
    this.flushFloorConfiguration.cc = Math.round((Math.sqrt(Math.pow(this.flushFloorConfiguration.hh, 2) - Math.pow(this.flushFloorConfiguration.ii + this.flushFloorConfiguration.ff, 2)) * 2) / 24) * 24;
    if (this.flushFloorConfiguration.cc < 24) this.flushFloorConfiguration.cc = 24;
    //1280 CC=CINT((SQR(HH^2-(II+FF)^2)*2)/24)*24: IF CC<24 THEN CC=24
    if (this.isFourLaneSpecial) {
      this.flushFloorConfiguration.gg = this.calcValues.calcSupportTunnelFan(this.flushFloorConfiguration.ff * 1.4, this.calcValues.maxWideTunnelWidth);
      // 1290 GG=FF*1.4: FOR I=1 TO 10: IF CROSSSEC(0,I)>=GG THEN 1310
      // 1300 NEXT I:I=10
      // 1310 GG=CROSSSEC(0,I)
      this.flushFloorConfiguration.dd = this.flushFloorConfiguration.ii + this.flushFloorConfiguration.gg > this.flushFloorConfiguration.hh ? 24 :
        Math.round((Math.sqrt(Math.pow(this.flushFloorConfiguration.hh, 2) - Math.pow(this.flushFloorConfiguration.ii + this.flushFloorConfiguration.gg, 2)) * 2) / 24) * 24;
      //1320 IF II+GG>HH THEN DD=24 ELSE DD=CINT((SQR(HH^2-(II+GG)^2)*2)/24)*24
      if (this.flushFloorConfiguration.dd < 24) this.flushFloorConfiguration.dd = 24;
      //IF DD<24 THEN DD=24

      while (this.flushFloorConfiguration.gg * this.flushFloorConfiguration.dd + (this.flushFloorConfiguration.cc - this.flushFloorConfiguration.dd) * this.flushFloorConfiguration.ff
        > this.flushFloorConfiguration.ee * this.flushFloorConfiguration.bb && this.flushFloorConfiguration.dd > 24) {
        this.flushFloorConfiguration.dd -= 24;
      }
      // 1340 WHILE GG * DD + (CC - DD) * FF > EE * BB AND DD > 24
      // 1350 DD = DD - 24: WEND
      return;
    }

    //recommedation for 4 Lane special with tee ************************

    this.flushFloorConfiguration.dd = Math.floor((this.flushFloorConfiguration.hh - this.flushFloorConfiguration.ii - this.flushFloorConfiguration.ff) / 24) * 24;
    if (this.flushFloorConfiguration.dd < 24) this.flushFloorConfiguration.dd = 24;
    //3770 DD=INT((HH-II-FF)/24)*24: IF DD<24 THEN DD=24
    //3790 IF DD<24 THEN DD=24
    this.flushFloorConfiguration.gg = this.flushFloorConfiguration.ff;
    let j = this.calcValues.crossec.indexOf(this.flushFloorConfiguration.gg);
    //3780 GG=FF
    while (this.flushFloorConfiguration.gg * this.flushFloorConfiguration.dd + (this.flushFloorConfiguration.cc * this.flushFloorConfiguration.ff)
      < this.flushFloorConfiguration.ee * this.flushFloorConfiguration.bb && this.flushFloorConfiguration.gg < 120) {
      j++;
      this.flushFloorConfiguration.gg = this.calcValues.crossec[j];
    }
    //3800 WHILE GG * DD + (CC * FF) < EE * BB AND GG < 120
    //3810 J = J + 1: GG = CROSSSEC(0, J): WEND
  }

  private fillInputValues() {
    this._a = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.aa);
    this._b = Math.round(this.flushFloorConfiguration.bb / 12);
    this._c = Math.round(this.flushFloorConfiguration.cc / 12);
    this._d = Math.round(this.flushFloorConfiguration.dd / 12);
    this._e = this.flushFloorConfiguration.ee;
    this._f = this.flushFloorConfiguration.ff;
    this._g = this.flushFloorConfiguration.gg;
    this._h = this.flushFloorConfiguration.jj;
    this._i = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.ii);
    this._iStandard = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(Math.floor(this.calcValues.rad * 2 * 0.202));
    //1360 ISTANDARD$=STR$(INT(II/12))+"-"+RIGHT$(STR$(CINT(II-(INT(II/12)*12))),LEN(STR$(CINT(II-(INT(II/12)*12))))-1)

    this.calcJ();
    this.calcK();
    this.calculateVelocities();
    this.fillTunnelSupports();
    this.reDrawSchema();
  }

  public fillTunnelSupports() {
    this.calcTunnelSupport(1, true);
    if (!this.flushFloorConfiguration.totalTunnelSupport1) {
      this.onChangeTunnelSupport(1);
    } else {
      this._tunnelSupport1.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport1;
      this._tunnelSupport1.span = this.flushFloorConfiguration.totalTunnelSupportSpan1;
    }

    this.calcTunnelSupport(2, true);
    if (!this.flushFloorConfiguration.totalTunnelSupport2) {
      this.onChangeTunnelSupport(2);
    } else {
      this._tunnelSupport2.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport2;
      this._tunnelSupport2.span = this.flushFloorConfiguration.totalTunnelSupportSpan2;
    }

    this.calcTunnelSupport(3, true);
    if (!this.flushFloorConfiguration.totalTunnelSupport3) {
      this.onChangeTunnelSupport(3);
    } else {
      this._tunnelSupport3.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport3;
      this._tunnelSupport3.span = this.flushFloorConfiguration.totalTunnelSupportSpan3;
    }
  }

  private calcTunnelSupport(tunnelNum: number, install: boolean = false) {
    let tunnelSupportOptions: { label: string, value: { totalTunnelSupport: number, span: number } }[] = [];
    let tunnelDia: number;
    let tunnelLength: number;
    switch (tunnelNum) {
      case 1:
        if (!this.isFourLaneSpecial) {
          tunnelDia = this.flushFloorConfiguration.ff;
          tunnelLength = 2 * (this.flushFloorConfiguration.cc + this.kk);
        } else {
          tunnelDia = this.flushFloorConfiguration.ff;
          tunnelLength = 2 * (this.flushFloorConfiguration.cc + this.kk - this.flushFloorConfiguration.dd);
        }
        break;
      case 2:
        tunnelDia = this.flushFloorConfiguration.ee;
        tunnelLength = 2 * (this.flushFloorConfiguration.bb + this.jj);
        break;
      case 3:
        if (!this.isFourLaneSpecial) {
          tunnelDia = this.flushFloorConfiguration.gg;
          tunnelLength = 2 * this.flushFloorConfiguration.dd;
        } else {
          tunnelDia = this.flushFloorConfiguration.ff + this.flushFloorConfiguration.gg;
          tunnelLength = this.flushFloorConfiguration.dd;
        }
        break;
    }

    let tunnelSupports = CanvasFlushFloorSupportFunction.aqsCalcTunnelSupport(tunnelDia, tunnelLength, this.calcValues.maxSpan, this.calcValues.minSpan);
    tunnelSupports.forEach(item => {
      tunnelSupportOptions.push({
        label: `Supports: ${item.totalTunnelSupport}, Span: ${item.span}'`,
        value: { totalTunnelSupport: item.totalTunnelSupport, span: item.span }
      });
    });

    switch (tunnelNum) {
      case 1:
        this._tunnelSupportOptions1 = tunnelSupportOptions;
        this._tunnelSupport1 = this._tunnelSupportOptions1[0].value;
        if (!install) {
          this.flushFloorConfiguration.totalTunnelSupport1 = this._tunnelSupport1.totalTunnelSupport;
          this.flushFloorConfiguration.totalTunnelSupportSpan1 = this._tunnelSupport1.span;
        }
        break;
      case 2:
        this._tunnelSupportOptions2 = tunnelSupportOptions;
        this._tunnelSupport2 = this._tunnelSupportOptions2[0].value;
        if (!install) {
          this.flushFloorConfiguration.totalTunnelSupport2 = this._tunnelSupport2.totalTunnelSupport;
          this.flushFloorConfiguration.totalTunnelSupportSpan2 = this._tunnelSupport2.span;
        }
        break;
      case 3:
        this._tunnelSupportOptions3 = tunnelSupportOptions;
        this._tunnelSupport3 = this._tunnelSupportOptions3[0].value;
        if (!install) {
          this.flushFloorConfiguration.totalTunnelSupport3 = this._tunnelSupport3.totalTunnelSupport;
          this.flushFloorConfiguration.totalTunnelSupportSpan3 = this._tunnelSupport3.span;
        }
        break;
    }
  }

  private calcJ() {
    this.jj = CanvasFlushFloorSupportFunction.aqsCalcDimensionBeforeTunnel(this.calcValues.rad, this.flushFloorConfiguration.bb, this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.ee / 2);
    this._j = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.jj);
  }

  private calcK() {
    this.kk = CanvasFlushFloorSupportFunction.aqsCalcDimensionBeforeTunnel(this.calcValues.rad, this.flushFloorConfiguration.cc, this.flushFloorConfiguration.ii);
    this._k = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.kk);
  }

  private calculateVelocities() {
    this._velocityTable = [];
    this._velocityTable.push({ value: 'Maximal', crossSectional: this.calcValues.mcsv, surfaceVelocity: this.calcValues.msv, error: false });
    let surfaceArea1 = this.isFourLaneSpecial ? (2 * this.flushFloorConfiguration.ff * this.flushFloorConfiguration.cc + 2 * (this.flushFloorConfiguration.gg - this.flushFloorConfiguration.ff) * this.flushFloorConfiguration.dd) / 144
      //3350 SURFACEAREA1 = ((2 * FF * CC) + (2 * (GG - FF) * DD)) / 144: 'in sq ft
      : (2 * this.flushFloorConfiguration.ff * this.flushFloorConfiguration.cc + 2 * this.flushFloorConfiguration.gg * this.flushFloorConfiguration.dd) / 144;
    //4760 SURFACEAREA1=((2*FF*CC)+(2*GG*DD))/144
    let surfaceArea2 = (this.flushFloorConfiguration.bb * this.flushFloorConfiguration.ee * 2) / 144;
    //3360 SURFACEAREA2 = (BB * EE * 2) / 144: 'in sq ft
    let sv1 = Math.round(((this.calcValues.lowerLarge / 2) / surfaceArea1) * 10) / 10;
    // 3370 SV1=INT((((LOWERLARGE/2)/SURFACEAREA1)*10)+.5)/10: 'surface velocity
    let sv2 = Math.round(((this.calcValues.lowerLarge / 2) / surfaceArea2) * 10) / 10;
    // 3380 SV2=INT((((LOWERLARGE/2)/SURFACEAREA2)*10)+.5)/10: 'surface velocity

    let csv1 = this.calcValues.calcCSVelocityFans(this.flushFloorConfiguration.supportHeight1, this.flushFloorConfiguration.ee, 2);
    let csv2 = this.calcValues.calcCSVelocityFans(this.flushFloorConfiguration.supportHeight2, this.flushFloorConfiguration.ff, 2);
    //3470 CSV2=INT(((LOWERLARGE/2)/(2*ACTUALCSA2))+.5)
    //3480 CSV1=INT(((LOWERLARGE/2)/(2*ACTUALCSA1))+.5)
    let vError1 = sv1 > this.calcValues.msv || csv1 > this.calcValues.mcsv;
    let vError2 = sv2 > this.calcValues.msv || csv2 > this.calcValues.mcsv;
    this._velocityTable.push({ value: 'Actual 1', crossSectional: csv1, surfaceVelocity: sv1, error: vError1 });
    this._velocityTable.push({ value: 'Actual 2', crossSectional: csv2, surfaceVelocity: sv2, error: vError2 });
    this._errorVelocities = vError1 || vError2;
  }

  private checkError() {
    this._errorALessOrEqualsE = this.flushFloorConfiguration.aa <= this.flushFloorConfiguration.ee;
    this._errorAEIUnableToDraw = this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.ee / 2 >= this.flushFloorConfiguration.ii;
    //3610 IF AA/2+EE/2>=II THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW-- E LOCATED BEYOND I";:RETURN
    this._errorBToLarge = this.flushFloorConfiguration.bb > this.calcValues.rad * 2;
    this._errorCToLarge = this.flushFloorConfiguration.cc > this.calcValues.rad * 2;
    this._errorDMoreThenC = this.flushFloorConfiguration.dd >= this.flushFloorConfiguration.cc;
    if (this.isFourLaneSpecial) {
      this._errorIGBeyondBinDia = this.flushFloorConfiguration.ii + this.flushFloorConfiguration.gg > this.calcValues.rad;
    }
    else {
      this._errorDToLarge = this.flushFloorConfiguration.dd > this.calcValues.rad;
    }
    this.pageError = this._errorALessOrEqualsE || this._errorAEIUnableToDraw || this._errorBToLarge || this._errorCToLarge
      || this._errorDMoreThenC || this._errorIGBeyondBinDia || this._errorDToLarge || this._errorVelocities;

    this.currentPageErrorChange.emit(this.pageError);
  }

  private reDrawSchema() {
    if (this.pageError) {
      CanvasFlushFloorSupportFunction.drawErrorBorder(this.ctx);
      return;
    }
    CanvasFlushFloorSupportFunction.clearCanvas(this.ctx);
    this.scale = CanvasFlushFloorSupportFunction.calculateScale(this.ctx, this.storageConfiguration.diameter);
    this.ctx.strokeStyle = this.flushFloorTunnelStrokeColor;
    this.drawFlushSchema();
    this.drawCirclesSchema();
    this.drawSchemaLabels();
  }

  private drawFlushSchema() {
    //RTUNS4.BAS  1390-1850
    let [x, y] = this.determinePointsXY();
    CanvasFlushFloorSupportFunction.drawFlushFloorForFourFans(this.ctx, x, y, this.flushFloorTunnelFillColor);
  }

  private determinePointsXY(): [number[], number[]] {
    let x: number[] = [];
    x.push(-(this.flushFloorConfiguration.ii + this.flushFloorConfiguration.ff));
    x.push(x[0]);
    x.push(x[1]);
    this.isFourLaneSpecial ? x.push(-this.flushFloorConfiguration.ii - this.flushFloorConfiguration.gg)
      : x.push(-this.flushFloorConfiguration.ii - this.flushFloorConfiguration.ff - this.flushFloorConfiguration.dd);
    x.push(x[3]);
    x.push(x[2]);
    x.push(x[5]);
    x.push(x[6] + this.flushFloorConfiguration.ff);
    x.push(x[7]);
    x.push(x[8]);
    x.push(-(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.ee / 2));
    x.push(x[10]);
    x.push(x[11]);
    x.push(x[12] + this.flushFloorConfiguration.ee);
    x.push(x[13]);
    x.push(x[10] + this.flushFloorConfiguration.ee);
    for (let i = 0; i < x.length; i++) {
      x[i] = this.width / 2 + x[i] * this.scale;
    }

    let y: number[] = [];
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow(this.flushFloorConfiguration.ii + this.flushFloorConfiguration.ff, 2)));
    y.push(-this.flushFloorConfiguration.cc / 2);
    this.isFourLaneSpecial ? y.push(-this.flushFloorConfiguration.dd / 2)
      : y.push(-this.flushFloorConfiguration.gg / 2);
    y.push(y[2]);
    this.isFourLaneSpecial ? y.push(this.flushFloorConfiguration.dd / 2)
      : y.push(this.flushFloorConfiguration.gg / 2);
    y.push(y[4]);
    y.push(this.flushFloorConfiguration.cc / 2);
    y.push(y[6]);
    y.push(y[1]);
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow(this.flushFloorConfiguration.ii, 2)));
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.ee / 2, 2)));
    y.push(-this.flushFloorConfiguration.bb / 2);
    y.push(this.flushFloorConfiguration.bb / 2);
    y.push(y[12]);
    y.push(y[11]);
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow(this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.ee / 2, 2)));
    for (let i = 0; i < y.length; i++) {
      y[i] = this.height / 2 - y[i] * this.scale;
    }

    return [x, y];
  }

  private drawCirclesSchema() {
    CanvasFlushFloorSupportFunction.drawCircleFromCenter(this.ctx, this.calcValues.rad, this.scale);
    CanvasFlushFloorSupportFunction.drawCircleFromCenter(this.ctx, this.flushFloorConfiguration.hh, this.scale);
  }

  private drawSchemaLabels() {
    this.ctx.font = `${CanvasFlushFloorSupportFunction.FontSize}px serif`;
    this.ctx.strokeStyle = this.flushFloorTunnelStrokeColor;
    this.ctx.fillStyle = this.flushFloorTunnelStrokeColor;
    this.drawLabelA();
    this.drawLabelB();
    this.drawLabelC();
    this.drawLabelD();
    this.drawLabelE();
    this.drawLabelF();
    this.drawLabelI();
    this.drawLabelG();
    this.drawLabelH();
    this.drawLabel1();
    this.drawLabel2();
    this.drawLabelJ();
    this.drawLabelK();
    this.drawLabel3();
  }

  private drawLabelA() {
    let x = this.width / 2 - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 - this.flushFloorConfiguration.bb / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 3;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithTopLabel(this.ctx, this.scale, x, y, 'A', this.flushFloorConfiguration.aa);
  }

  private drawLabelB() {
    let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.ee / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
    let y = this.height / 2;
    CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'B', this.flushFloorConfiguration.bb);
  }

  private drawLabelC() {
    let x = this.width / 2 - this.flushFloorConfiguration.ii * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
    let y = this.height / 2;
    CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'C', this.flushFloorConfiguration.cc);
  }

  private drawLabelD() {
    if (this.isFourLaneSpecial) {
      let x = this.width / 2 - this.flushFloorConfiguration.ii * this.scale - this.flushFloorConfiguration.gg * this.scale - CanvasFlushFloorSupportFunction.FontSize;
      let y = this.height / 2;
      CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelLeft(this.ctx, this.scale, x, y, 'D', this.flushFloorConfiguration.dd);
      return;
    }
    let x = this.width / 2 - (this.flushFloorConfiguration.ii + this.flushFloorConfiguration.ff + this.flushFloorConfiguration.dd / 2) * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.flushFloorConfiguration.gg / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'D', this.flushFloorConfiguration.dd);
  }

  private drawLabelE() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.calcValues.rad * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'E', this.flushFloorConfiguration.ee);
  }

  private drawLabelF() {
    let x = this.width / 2 + (this.flushFloorConfiguration.ii + this.flushFloorConfiguration.ff / 2) * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.calcValues.rad * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'F', this.flushFloorConfiguration.ff);
  }

  private drawLabelG() {
    if (this.isFourLaneSpecial) {
      let x = this.width / 2 + (this.flushFloorConfiguration.ii + this.flushFloorConfiguration.gg / 2) * this.scale;
      let y = this.height / 2 + this.flushFloorConfiguration.dd / 4 * this.scale;
      CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, 'G');
      return;
    }

    let x = this.width / 2 + (this.flushFloorConfiguration.ii + this.flushFloorConfiguration.ff + this.flushFloorConfiguration.dd) * this.scale + CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2;
    CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'G', this.flushFloorConfiguration.gg);
  }

  private drawLabelH() {
    if (this.flushFloorConfiguration.jj > 0) {
      let x = this.width / 2;
      let y = this.height / 2;
      CanvasFlushFloorSupportFunction.drawObliqueLineTopWithLabel(this.ctx, this.scale, x, y, 'H', this.flushFloorConfiguration.hh);
    }
  }

  private drawLabelI() {
    let x = this.width / 2 - this.flushFloorConfiguration.ii / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.calcValues.rad * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'I', this.flushFloorConfiguration.ii);
  }

  private drawLabel1() {
    let x = this.width / 2 - (this.flushFloorConfiguration.ii + this.flushFloorConfiguration.ff / 2) * this.scale;
    let y = this.height / 2 - this.flushFloorConfiguration.cc / 6 * this.scale;
    CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, '1', 2);
  }

  private drawLabel2() {
    let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale;
    let y = this.height / 2 - this.flushFloorConfiguration.bb / 4 * this.scale;
    CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, '2');
  }

  private drawLabelJ() {
    if (this.jj > 0) {
      let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.ee / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
      let y = this.height / 2 + this.flushFloorConfiguration.bb / 2 * this.scale + this.jj / 2 * this.scale;
      CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'J', this.jj);
    }
  }

  private drawLabelK() {
    if (this.kk > 0) {
      let x = this.width / 2 - this.flushFloorConfiguration.ii * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
      let y = this.height / 2 + this.flushFloorConfiguration.cc / 2 * this.scale + this.kk / 2 * this.scale;
      CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'K', this.kk);
    }
  }

  private drawLabel3() {
    if (!this.isFourLaneSpecial) {
      let x = this.width / 2 - (this.flushFloorConfiguration.ii + this.flushFloorConfiguration.ff + this.flushFloorConfiguration.dd / 2) * this.scale;
      let y = this.height / 2 - this.flushFloorConfiguration.gg / 2 * this.scale;
      CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, '3');
    }
  }
}
