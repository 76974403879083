export class HubMethods {
    static Location = class {
        public static readonly locationAdded: string = 'locationAdded';
        public static readonly locationDeleted: string = 'locationDeleted';
        public static readonly locationInUpdate: string = 'locationInUpdate';
        public static readonly locationUpdated: string = 'locationUpdated';
        public static readonly locationEquipmentsInUpdate: string = 'locationEquipmentsInUpdate';
        public static readonly locationEquipmentsUpdated: string = 'locationEquipmentsUpdated';
        public static readonly locationDeviceInInitialization: string = 'locationDeviceInInitialization';
        public static readonly locationDeviceInitialized: string = 'locationDeviceInitialized';
    }

    static LocationBtx = class {
        public static readonly locationBtxAdded: string = 'locationBtxAdded';
        public static readonly locationBtxDeleted: string = 'locationBtxDeleted';
        public static readonly locationBtxInUpdate: string = 'locationBtxInUpdate';
        public static readonly locationBtxUpdated: string = 'locationBtxUpdated';
        public static readonly locationBtxEquipmentsInUpdate: string = 'locationBtxEquipmentsInUpdate';
        public static readonly locationBtxEquipmentsUpdated: string = 'locationBtxEquipmentsUpdated';
        public static readonly locationBtxDeviceInInitialization: string = 'locationBtxDeviceInInitialization';
        public static readonly locationBtxDeviceInitialized: string = 'locationBtxDeviceInitialized';
    }

    static Company = class {
        public static readonly companyAdded: string = 'companyAdded';
        public static readonly companyDeleted: string = 'companyDeleted';
        public static readonly companyInUpdate: string = 'companyInUpdate';
        public static readonly companyUpdated: string = 'companyUpdated';
    }

    static ServiceUser = class {
        public static readonly serviceUserAdded: string = 'serviceUserAdded';
        public static readonly serviceUserDeleted: string = 'serviceUserDeleted';
        public static readonly serviceUserInUpdate: string = 'serviceUserInUpdate';
        public static readonly serviceUserUpdated: string = 'serviceUserUpdated';
    }

    static SalesUser = class {
        public static readonly salesUserAdded: string = 'salesUserAdded';
        public static readonly salesUserDeleted: string = 'salesUserDeleted';
        public static readonly salesUserInUpdate: string = 'salesUserInUpdate';
        public static readonly salesUserUpdated: string = 'salesUserUpdated';
    }

    static CompanyAdmin = class {
        public static readonly companyAdminAdded: string = 'companyAdminAdded';
        public static readonly companyAdminDeleted: string = 'companyAdminDeleted';
        public static readonly companyAdminInUpdate: string = 'companyAdminInUpdate';
        public static readonly companyAdminUpdated: string = 'companyAdminUpdated';
    }

    static CompanyUser = class {
        public static readonly companyUserAdded: string = 'companyUserAdded';
        public static readonly companyUserDeleted: string = 'companyUserDeleted';
        public static readonly companyUserInUpdate: string = 'companyUserInUpdate';
        public static readonly companyUserUpdated: string = 'companyUserUpdated';
    }

    static Vendor = class {
        public static readonly vendorAdded: string = 'vendorAdded';
        public static readonly vendorDeleted: string = 'vendorDeleted';
        public static readonly vendorInUpdate: string = 'vendorInUpdate';
        public static readonly vendorUpdated: string = 'vendorUpdated';
    }

    static LocationKtx = class {
        public static readonly locationKtxAdded: string = 'locationKtxAdded';
        public static readonly locationKtxInUpdate: string = 'locationKtxInUpdate';
        public static readonly locationKtxUpdated: string = 'locationKtxUpdated';
        public static readonly locationKtxDeleted: string = 'locationKtxDeleted';
        public static readonly locationKtxBinsInUpdate: string = 'locationKtxBinsInUpdate';
        public static readonly locationKtxBinsUpdated: string = 'locationKtxBinsUpdated';
        public static readonly locationKtxDeviceInInitialization: string = 'locationKtxDeviceInInitialization';
        public static readonly locationKtxDeviceInitialized: string = 'locationKtxDeviceInitialized';
        public static readonly locationKtxTemperatureRangeUpdated: string = 'locationKtxTemperatureRangeUpdated';
    }

    static LocationItx = class {
        public static readonly locationItxAdded: string = 'locationItxAdded';
        public static readonly locationItxInUpdate: string = 'locationItxInUpdate';
        public static readonly locationItxUpdated: string = 'locationItxUpdated';
        public static readonly locationItxDeleted: string = 'locationItxDeleted';
        public static readonly locationItxStorageInView: string = 'locationItxStorageInView';
        public static readonly locationItxFullnessRangeUpdated: string = 'locationItxFullnessRangeUpdated';
        public static readonly locationItxDeviceInInitialization: string = 'locationItxDeviceInInitialization';
        public static readonly locationItxDeviceInitialized: string = 'locationItxDeviceInitialized';
    }

    static ItxBin = class {
        public static readonly itxBinAdded: string = 'itxBinAdded';
        public static readonly itxBinInUpdate: string = 'itxBinInUpdate';
        public static readonly itxBinUpdated: string = 'itxBinUpdated';
        public static readonly itxBinDeleted: string = 'itxBinDeleted';
        public static readonly itxBinRadarAdded: string = 'itxBinRadarAdded';
        public static readonly itxBinRadarInUpdate: string = 'itxBinRadarInUpdate';
        public static readonly itxBinRadarUpdated: string = 'itxBinRadarUpdated';
        public static readonly itxBinRadarDeleted: string = 'itxBinRadarDeleted';
    }

    static Updater = class {
        public static readonly updaterProjectUpdateFirmware: string = 'updaterProjectUpdateFirmware';
    }
}