export class RefreshViewModel {
    constructor(
        public authToken: string,
        public refreshToken: string,
        public expiresAt: number,
    ) { }
}

export class RefreshGetModel {
    public authToken: string;
    public refreshToken: string;
    public expiresAt: number;

    public static toViewModel(getModel: RefreshGetModel): RefreshViewModel {
        const viewModel = new RefreshViewModel(getModel.authToken, getModel.refreshToken, getModel.expiresAt);
        return viewModel;
      }
}

export class RefreshPostModel {
    constructor(
        public authToken: string,
        public refreshToken: string,
    ) { }
}