import { ReadingScheduleType } from '../../enums';
import { ModelsTypes } from '../../constants';
import { CommonFunctions, ConvertDataFunctions } from '../../../common';
import {
  BinViewModel,
  BinGetModel,
  AddEditBinSchemePostModel,
  BinSchemeViewModel,
} from '../bin.model';
import {
  TemperatureRangeViewModel,
  AlarmViewModel,
} from '../temperature-range.model';
import { TreeNode } from 'primeng';

export class LocationKtxViewModel {
  public deviceId: string;
  public id: number;
  public locationName: string;
  public companyName: string;

  //Device fields
  public isConnectionFileUpdated: boolean;
  public isInitialized: boolean;
  public readingScheduleType: ReadingScheduleType;
  public scheduleTime: string;
  public countOfBins: number;
  public displaySchemes: boolean;
  public canDeviceBeInitialized: boolean;
  public temperatureRanges: TemperatureRangeViewModel[];
  public alarm: AlarmViewModel;

  //User behavior lock fields
  public isSomeoneUpdateLocation: boolean;
  public isDeviceInInitialization: boolean;

  //Infrastructure fields
  modelType = ModelsTypes.locationKtxViewModel;
}

export class LocationKtxGetModel {
  public deviceId: string;
  public id: number;
  public locationName: string;
  public companyName: string;

  // Device fields
  public isInitialized: boolean;
  public isConnectionFileUpdated: boolean;
  public readingScheduleType: ReadingScheduleType;
  public scheduleTime: number;
  public countOfBins: number;
  public displaySchemes: boolean;
  public canDeviceBeInitialized: boolean;

  public static toViewModel(
    getModel: LocationKtxGetModel
  ): LocationKtxViewModel {
    const viewModel = new LocationKtxViewModel();
    viewModel.deviceId = getModel.deviceId;
    viewModel.id = getModel.id;
    viewModel.locationName = getModel.locationName;
    viewModel.companyName = getModel.companyName;
    viewModel.isInitialized = getModel.isInitialized;
    viewModel.isConnectionFileUpdated = getModel.isConnectionFileUpdated;
    viewModel.displaySchemes = getModel.displaySchemes;
    viewModel.canDeviceBeInitialized = getModel.canDeviceBeInitialized;
    viewModel.readingScheduleType = getModel.readingScheduleType;
    viewModel.scheduleTime =
      getModel.readingScheduleType === ReadingScheduleType.None
        ? ConvertDataFunctions.secondsToString(getModel.scheduleTime, true)
        : ConvertDataFunctions.secondsToString(getModel.scheduleTime);

    viewModel.countOfBins = getModel.countOfBins;

    return viewModel;
  }
}

export class LocationKtxPostModel {
  public locationId: number;
  public readingScheduleType: ReadingScheduleType;
  public scheduleTime: number;

  constructor(location: LocationKtxViewModel) {
    this.locationId = location.id;
    this.readingScheduleType = location.readingScheduleType;
    this.scheduleTime = ConvertDataFunctions.stringToSeconds(
      location.scheduleTime
    );
  }
}

export class LocationKtxSchemeViewModel {
  public locationId: number;
  public binSchemes: BinSchemeViewModel[];
  public wasDeviceInitialized: boolean;
  public height: number;
  public width: number;
  public depth: number;
  public locationName: string;
  public displayScheme: boolean;
  public forLocationView: boolean;

  public temperatureRanges: TemperatureRangeViewModel[];
  public alarm: AlarmViewModel;
  //Infrastructure fields
  public modelType = ModelsTypes.locationKtxSchemeViewModel;

  public static toBinsTreeNode(
    locationScheme: LocationKtxSchemeViewModel
  ): TreeNode[] {
    if (!locationScheme) {
      return;
    }

    const { binSchemes } = locationScheme;
    const locationBinsNodes =
      binSchemes &&
      binSchemes
        .map((binScheme) => {
          let isSelectable = true;

          const locationBinsNode: TreeNode = {
            label: binScheme.name,
            data: binScheme.id,
          };

          if (binScheme.bins) {
            locationBinsNode.children = binScheme.bins
              .filter((b) => !b.isDecorBin)
              .map<TreeNode>((bin) => {
                const locationNode: TreeNode = {
                  label: bin.name,
                  data: bin.id,
                  selectable: true,
                  parent: locationBinsNode,
                };

                return locationNode;
              })
              .sort(CommonFunctions.compareTreeNodeLables);

            isSelectable = false;
          }

          locationBinsNode.selectable = isSelectable;

          return locationBinsNode;
        })
        .sort(CommonFunctions.compareTreeNodeLables);

    return locationBinsNodes;
  }

  public static toReportsBinsTreeNode(
    locationScheme: LocationKtxSchemeViewModel
  ): TreeNode[] {
    if (!locationScheme) {
      return;
    }
    const { binSchemes } = locationScheme;

    const locationBinsNodes =
      binSchemes &&
      binSchemes
        .map((binScheme) => {
          const locationBinsNode: TreeNode = {
            label: binScheme.name,
            data: binScheme.id,
            selectable: true,
            key: binScheme.id.toString(),
          };

          if (binScheme.bins) {
            locationBinsNode.children = binScheme.bins
              .filter((b) => !b.isDecorBin)
              .map<TreeNode>((bin) => {
                const locationNode: TreeNode = {
                  label: bin.name,
                  data: bin.id,
                  selectable: true,
                  key: bin.id.toString(),
                };

                return locationNode;
              })
              .sort(CommonFunctions.compareTreeNodeLables);
          }

          return locationBinsNode;
        })
        .sort(CommonFunctions.compareTreeNodeLables);

    return locationBinsNodes;
  }
}

export class LocationKtxSchemeGetModel {
  public locationId: number;
  public binSchemes: BinViewModel[];
  public wasDeviceInitialized: boolean;
  public height: number;
  public width: number;
  public depth: number;
  public locationName: string;
  public displayScheme: boolean;
  public forLocationView: boolean;

  public temperatureRanges: TemperatureRangeViewModel[];
  public alarm: AlarmViewModel;

  //Infrastructure fields
  public modelType = ModelsTypes.locationKtxSchemeViewModel;

  public static toViewModel(
    getModel: LocationKtxSchemeGetModel
  ): LocationKtxSchemeViewModel {
    const viewModel = new LocationKtxSchemeViewModel();
    viewModel.locationId = getModel.locationId;
    viewModel.binSchemes = getModel.binSchemes.map((b) =>
      BinViewModel.toSchemeViewModel(b)
    );
    viewModel.wasDeviceInitialized = getModel.wasDeviceInitialized;
    viewModel.height = getModel.height;
    viewModel.width = getModel.width;
    viewModel.depth = getModel.depth;
    viewModel.locationName = getModel.locationName;
    viewModel.displayScheme = getModel.displayScheme;
    viewModel.forLocationView = getModel.forLocationView;
    viewModel.alarm = getModel.alarm;
    viewModel.temperatureRanges = getModel.temperatureRanges;

    return viewModel;
  }
}

export class AddEditLocationKtxSchemeViewModel {
  public locationAddress: string;
  public locationName: string;
  public displayScheme: boolean;
  public height: number;
  public width: number;
  public depth: number;
  public bins: BinViewModel[];
}

export class AddEditLocationKtxSchemeGetModel {
  public locationAddress: string;
  public locationName: string;
  public displayScheme: boolean;
  public height: number;
  public width: number;
  public depth: number;
  public bins: BinGetModel[];

  public static toViewModel(
    getModel: AddEditLocationKtxSchemeGetModel
  ): AddEditLocationKtxSchemeViewModel {
    const viewModel = new AddEditLocationKtxSchemeViewModel();
    viewModel.locationAddress = getModel.locationAddress;
    viewModel.locationName = getModel.locationName;
    viewModel.displayScheme = getModel.displayScheme;
    viewModel.height = getModel.height;
    viewModel.width = getModel.width;
    viewModel.depth = getModel.depth;

    if (getModel.bins) {
      viewModel.bins = getModel.bins.map((b) => BinGetModel.toViewModel(b));
    }

    return viewModel;
  }
}

export class AddEditLocationKtxSchemePostModel {
  public locationId: number;
  public locationAddress: string;
  public locationName: string;
  public displayScheme: boolean;
  public height: number;
  public width: number;
  public depth: number;
  public bins: AddEditBinSchemePostModel[];

  constructor(
    locationId: number,
    locationScheme: AddEditLocationKtxSchemeViewModel
  ) {
    this.locationId = locationId;
    this.locationAddress = locationScheme.locationAddress;
    this.locationName = locationScheme.locationName;
    this.displayScheme = locationScheme.displayScheme;
    this.height = locationScheme.height;
    this.width = locationScheme.width;
    this.depth = locationScheme.depth;

    if (locationScheme.bins) {
      this.bins = locationScheme.bins.map(
        (b) => new AddEditBinSchemePostModel(b)
      );
    }
  }
}

export class AddEditLocationKtxSchemeResultModel {
  public isNeedToInitializeDevice: boolean;
  public isBinsOrCablesWereAddedOrRemoved: boolean;
  public locationName: string;
  public locationAddress: string;
  public displayScheme: boolean;
  public countOfBins: number;
  public canDeviceBeInitialized: boolean;
}
