import { ItxEventTypeEnum, KtxEventTypeEnum, BtxEventTypeEnum } from "../enums";

const alarmLabel = 'Alarm';
const warningLabel = 'Warning';
const infoLabel = 'Info';
const errorLabel = 'Error';

const alarmIcon = 'table-icon-alarm';
const warningIcon = 'table-icon-warning';
const infoIcon = 'table-icon-info';
const errorIcon = 'table-icon-error';

export const ItxEventTypesLabel = {
    [ItxEventTypeEnum.Alarm]: alarmLabel,
    [ItxEventTypeEnum.Warning]: warningLabel,
    [ItxEventTypeEnum.Error]: errorLabel,
}

export const ItxEventTypesIconClass = {
    [ItxEventTypeEnum.Alarm]: alarmIcon,
    [ItxEventTypeEnum.Warning]: warningIcon,
    [ItxEventTypeEnum.Error]: errorIcon,
}

export const KtxEventTypesLabel = {
    [KtxEventTypeEnum.Alarm]: alarmLabel,
    [KtxEventTypeEnum.Info]: infoLabel
}

export const KtxEventTypesIconClass = {
    [KtxEventTypeEnum.Alarm]: alarmIcon,
    [KtxEventTypeEnum.Info]: infoIcon
}

export const BtxEventTypesLabel = {
    [BtxEventTypeEnum.Alarm]: alarmLabel,
    [BtxEventTypeEnum.Warning]: warningLabel
}

export const BtxEventTypesIconClass = {
    [BtxEventTypeEnum.Alarm]: alarmIcon,
    [BtxEventTypeEnum.Warning]: warningIcon
}