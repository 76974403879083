import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BinTypeEnum, ColumnTypeEnum } from '../../../enums';
import { BinSchemeViewModel } from '../../../models';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-ktx-unit-details-popup',
  templateUrl: './ktx-unit-details-popup.component.html',
})
export class KtxUnitDetailsPopupComponent extends BasePopupBehavior implements OnInit, AfterViewInit, OnDestroy {
  static NAME_FIELD = 'binWithinUnitName';
  static HEIGHT_FIELD = 'height';
  static DEPTH_FIELD = 'depth';
  static WIDTH_FIELD = 'width';
  static TYPE_FIELD = 'binType';
  static WINDOW_FUNCTION_GET_UNIT_NAME = 'getUnit';
  static WINDOW_FUNCTION_BIN_DETAILS_CLICK_NAME = 'binDetailsClick';
  static WINDOW_FUNCTION_SHOW_SCHEME_NAME = 'showScheme';
  _isSchemeHidden = true;
  _isHidden = true;
  _gridData: BinSchemeViewModel[];
  subscriptions: Subscription[] = [];
  _sortField = 'binWithinUnitName';
  _columnTypeEnum = ColumnTypeEnum;
  _binTypes = BinTypeEnum;
  _currentUnit: BinSchemeViewModel;
  onDetailsClick = new EventEmitter<BinSchemeViewModel>();
  scheme$ = new BehaviorSubject<BinSchemeViewModel>(null);
  onUnitDetailsClick$ = new BehaviorSubject<number>(0);
  showScheme$ = new BehaviorSubject<boolean>(false);
  _isSchemeLoading = true;
  @ViewChild('unitModel', { static: false }) unit: ElementRef;
  @ViewChild('fullScreenContainer', { static: false }) fullScreenContainer: ElementRef;

  _columnsView = [
    { header: 'Bin name', columnType: ColumnTypeEnum.binWithinUnitName, dataField: KtxUnitDetailsPopupComponent.NAME_FIELD },
    { header: 'Height', columnType: ColumnTypeEnum.Height, dataField: KtxUnitDetailsPopupComponent.HEIGHT_FIELD },
    { header: 'Depth', columnType: ColumnTypeEnum.Depth, dataField: KtxUnitDetailsPopupComponent.DEPTH_FIELD },
    { header: 'Width', columnType: ColumnTypeEnum.Width, dataField: KtxUnitDetailsPopupComponent.WIDTH_FIELD },
    { header: 'Type', columnType: ColumnTypeEnum.Type, dataField: KtxUnitDetailsPopupComponent.TYPE_FIELD }
  ];

  _frozenColumns = [
    { header: 'Details', columnType: ColumnTypeEnum.Details, width: 46 },
  ];

  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  constructor(private _el: ElementRef) {
    super();
  }

  ngOnInit() {
    this._currentUnit = new BinSchemeViewModel();
    window[KtxUnitDetailsPopupComponent.WINDOW_FUNCTION_GET_UNIT_NAME] = () => {
      return this.scheme$;
    };

    window[KtxUnitDetailsPopupComponent.WINDOW_FUNCTION_BIN_DETAILS_CLICK_NAME] = () => {
      return this.onUnitDetailsClick$;
    };

    window[KtxUnitDetailsPopupComponent.WINDOW_FUNCTION_SHOW_SCHEME_NAME] = () => {
      return this.showScheme$;
    };

    let binDetailsSub = this.onUnitDetailsClick$.subscribe((id: number) => {
      if (id) {
        const bin = this._currentUnit.bins.find(x => x.id === id);
        if (!bin.isDecorBin) {
          this.onClickDetails(bin);
        }
      }
    });

    this.subscriptions.push(binDetailsSub);
  }

  ngAfterViewInit() {
    this.reinitScrollBar(0);
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.forEach(x => x.unsubscribe());
  }

  onClose() {
    this._currentUnit = new BinSchemeViewModel();
    this._gridData = [];
    this.showScheme$.next(false);
    this.destroyMainScrollBar();
  }

  onClickDetails(scheme: BinSchemeViewModel) {
    scheme.locationId = this._currentUnit.locationId;
    scheme.displaySchemes = this._currentUnit.displaySchemes;
    scheme.temperatureRanges = this._currentUnit.temperatureRanges;
    scheme.alarm = this._currentUnit.alarm;
    scheme.wasDeviceInitialized = this._currentUnit.wasDeviceInitialized;
    scheme.isDeviceInitialized = this._currentUnit.isDeviceInitialized;

    this.onDetailsClick.emit(scheme);
  }

  public show(unit: BinSchemeViewModel) {
    this._isHidden = false;
    this._gridData = JSON.parse(JSON.stringify(unit.bins.filter(b => !b.isDecorBin)));
    this._gridData.forEach(x => {
      x.binWithinUnitName = +x.name;
    });
    unit.bins.forEach(bin => bin.binWithinUnitName = +bin.name);
    this._currentUnit = unit;
    this.scheme$.next(unit);
    this.showScheme$.next(true);
  }

  reinitScrollBar(timeout: number) {
    const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    this.reinitMainScrollBar(el, timeout);
  }

  showFullScreen() {
    this._isSchemeHidden = false;
    this._isSchemeLoading = true;
    this.fullScreenContainer.nativeElement.appendChild(this.unit.nativeElement);
    this.unit.nativeElement.setAttribute('height', '600');
    this.unit.nativeElement.setAttribute('width', '600');
  }

  onFullScreenClose() {
    this._isSchemeHidden = true;
    this._isSchemeLoading = true;
    const modelHolder = this._el.nativeElement.querySelector('.popup-iframe');
    modelHolder.appendChild(this.unit.nativeElement);
    this.unit.nativeElement.setAttribute('height', '300');
    this.unit.nativeElement.setAttribute('width', '300');
  }

  onIframeLoad() {
    this._isSchemeLoading = false;
  }
}
