export class CompleteRegistrationModel {
  constructor(
    public token: string,
    public firstName: string,
    public lastName: string,
    public password: string,
    public confirmPassword: string) { }
}

export class CompleteRegistrationVendorModel extends CompleteRegistrationModel {
  constructor(
    public companyName: string,
    public token: string,
    public firstName: string,
    public lastName: string,
    public password: string,
    public confirmPassword: string) { super(token, firstName, lastName, password, confirmPassword); }
}