import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { LocalStorageService } from './../../../common';
import { TutorialPopupComponent, VendorAddPopupComponent } from '../popups';
import { LocationsItxDatasourceService } from '../../services';
import { TutorialMenuEnum, TutorialParagraphEnum } from '../../enums';

@Component({
  selector: 'greensleeves-vendors-tab',
  templateUrl: './vendors-tab.component.html',
  styles: []
})
export class VendorsTabComponent implements OnInit {
  _itxLocations: { value: number }[];
  private _subscriptions: Subscription[] = [];

  @ViewChild(VendorAddPopupComponent, { read: false, static: false })
  _addModal: VendorAddPopupComponent;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  constructor(
    private _localStorageService: LocalStorageService,
    private _locationsItxService: LocationsItxDatasourceService
  ) { }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    let subscriptionLocationItx = this._locationsItxService.locationsItx$.subscribe(data => {
      if (data) {
        this._itxLocations = data.map(x => ({ value: x.id }));
      }
    });

    this._subscriptions.push(subscriptionLocationItx);
    this._locationsItxService.getLocationsWithBinsForCompany(info ? info.companyId : 0);
  }

  onClickAdd() {
    this._addModal.show();
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.UserManagement, TutorialParagraphEnum.AddVendor);
  }
}
