import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { ItxBinApiService, HubService } from '../http';
import { ErrorModel } from '../../../common';
import {
  ItxBinViewModel, AddItxBinModel, ItxBinAddedHubMessage, EditItxBinModel, ItxBinInUpdateHubMessage, ItxBinUpdatedHubMessage,
  ItxBinDeletedHubMessage, RadarPostModel, RadarViewModel, ItxBinRadarInUpdateHubMessage, ItxBinRadarDeletedHubMessage, ItxBinRadarAddedHubMessage, ItxBinRadarUpdatedHubMessage
  , ItxBinLiveDataModel, ItxSchemeLiveData, ItxStaticScemeData, UserPreferences, UserPreferencesPost, ItxBinFilterParameters, ItxBinParametersRequest, ReportDataFilterPost, ReportHistoricalDataResponse, ItxBinQuickEditPostModel, ItxLocationReportFilter, ItxBinLiveDataViewModel,
} from '../../models';
import { HubMethods } from './../../constants';
import { ItxStorageSortingEnum } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class ItxBinDataSourceService {
  public itxBin$: BehaviorSubject<ItxBinViewModel[]> = new BehaviorSubject([]);
  public itxBinLiveData$: BehaviorSubject<ItxBinLiveDataModel[]> = new BehaviorSubject([]);

  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  public userPreference$: BehaviorSubject<UserPreferences> = new BehaviorSubject(null);
  private _lastLocationId: number;
  constructor(
    private _itxBinApi: ItxBinApiService,
    private _hubService: HubService,
  ) {
    this.subscribeToHubService();
  }
  async getSchemeForLiveData(filterParameters: ItxBinFilterParameters, pageNumber: number, sortParameters: ItxStorageSortingEnum): Promise<ItxSchemeLiveData | ErrorModel> {
    try {
      const filterParametersRequest = ItxBinFilterParameters.toItxBinFilterParametersRequest(filterParameters);
      const requestParameters = new ItxBinParametersRequest(filterParametersRequest, pageNumber, sortParameters);
      const result = await this._itxBinApi.getSchemeForLiveData(requestParameters);
      let data = result as ItxSchemeLiveData;
      return data;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  async setUserPreferences(userPreferences: UserPreferencesPost) {
    try {
      const data = await this._itxBinApi.setUserPreferences(userPreferences);
      this.userPreference$.next(data as UserPreferences);
    }
    catch (error) {
      this.errors$.next(error);
    }
  }

  async getStaticItxBinSchemeData(): Promise<ItxStaticScemeData | ErrorModel> {
    try {
      const data = await this._itxBinApi.getStaticItxBinSchemeData();
      return data;
    }
    catch (error) {
      this.errors$.next(error);
    }
  }

  async getLocationReportFilters(): Promise<ItxLocationReportFilter[] | ErrorModel> {
    try {
      const data = await this._itxBinApi.getLocationReportFilters();
      return data;
    }
    catch (error) {
      this.errors$.next(error);
    }
  }


  public async getRadarTelemetryWithReferences(binId: number): Promise<ItxBinLiveDataViewModel | ErrorModel> {
    try {
      const data = await this._itxBinApi.getItxBinLiveData(binId);
      return data;
    }
    catch (error) {
      this.errors$.next(error);
      return error;
    }
  }


  async getItxBinsByLocationId(locationId: number) {
    this._lastLocationId = locationId;
    try {
      const data = await this._itxBinApi.getItxBinsByLocationId(locationId);
      if (data) {
        this.itxBin$.next(data as ItxBinViewModel[]);
      }
    }
    catch (error) {
      this.errors$.next(error);
    }
  }

  async getDataForLiveData(bins: number[]) {
    try {
      const result = await this._itxBinApi.getDataForLiveData(bins);
      const data = result as ItxBinLiveDataModel[];
      this.itxBinLiveData$.next(data);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  async add(addItxBin: AddItxBinModel): Promise<ItxBinViewModel | ErrorModel> {
    try {
      const result = await this._itxBinApi.add(addItxBin) as ItxBinViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.ItxBin.itxBinAdded, new ItxBinAddedHubMessage(result));
        this.itxBin$.next([
          ...this.itxBin$.getValue(),
          result
        ]);
        return Promise.resolve(result);
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  async edit(editItxBin: EditItxBinModel) {
    try {
      const result = await this._itxBinApi.edit(editItxBin) as ItxBinViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.ItxBin.itxBinUpdated, new ItxBinUpdatedHubMessage(result, true));
        this.itxBin$.next(
          this.itxBin$.getValue().map((l) => (l.id === result.id ? result : l)),
        );
        return Promise.resolve(result);
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  async quickEdit(editItxBin: ItxBinQuickEditPostModel): Promise<boolean | ErrorModel> {
    try {
      const result = await this._itxBinApi.quickEdit(editItxBin) as ItxBinViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.ItxBin.itxBinUpdated, new ItxBinUpdatedHubMessage(result, true));
        this.itxBin$.next(
          this.itxBin$.getValue().map((l) => (l.id === result.id ? result : l)),
        );
        return true;
      }
      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async markItxBinForOthersAsInUpdate(
    itxBinId: number
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.ItxBin.itxBinInUpdate,
      new ItxBinInUpdateHubMessage(itxBinId)
    );
  }

  public async markItxBinRadarForOthersAsInUpdate(
    itxBinId: number, inUpdate: boolean
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.ItxBin.itxBinRadarInUpdate,
      new ItxBinRadarInUpdateHubMessage(itxBinId, inUpdate)
    );
  }

  public async markItxBinForOthersAsUpdated(
    itxBin: ItxBinViewModel,
    withChanges: boolean
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.ItxBin.itxBinUpdated,
      new ItxBinUpdatedHubMessage(itxBin, withChanges)
    );
  }

  public async delete(itxBin: ItxBinViewModel): Promise<boolean | ErrorModel> {
    try {
      const result = await this._itxBinApi.delete(itxBin.id);
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.ItxBin.itxBinDeleted, new ItxBinDeletedHubMessage(itxBin.id, itxBin.locationId));
        const itxBins = this.itxBin$.getValue().filter(l => l.id !== itxBin.id);
        this.itxBin$.next(itxBins);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  private subscribeToHubService() {
    this._hubService.itxBinAdded.subscribe(
      (hubMessage: ItxBinAddedHubMessage) => {
        if (hubMessage && hubMessage.newItxbin) {
          const itxBins = this.itxBin$.getValue();
          const newItxBin = hubMessage.newItxbin;
          if (this._lastLocationId == newItxBin.locationId) {
            this.itxBin$.next([...itxBins, newItxBin]);
          }
        }
      }
    );
    this._hubService.itxBinInUpdate.subscribe(
      (hubMessage: ItxBinInUpdateHubMessage) => {
        if (hubMessage) {
          const itxBin = this.itxBin$.getValue().find(i => i.id == hubMessage.itxBinId);
          if (itxBin) {
            itxBin.isSomeoneUpdateStorage = true;
          }
        }
      }
    );
    this._hubService.itxBinUpdated.subscribe(
      (hubMessage: ItxBinUpdatedHubMessage) => {
        if (hubMessage && hubMessage.updatedItxBin) {
          if (hubMessage.withChanges) {
            hubMessage.updatedItxBin.isSomeoneUpdateStorage = undefined;
            this.itxBin$.next(
              this.itxBin$.getValue().map((l) => {
                if (l.id === hubMessage.updatedItxBin.id) {
                  return hubMessage.updatedItxBin;
                } else {
                  return l;
                }
              })
            );
          } else {
            const itxBin = this.itxBin$
              .getValue()
              .find((l) => l.id == hubMessage.updatedItxBin.id);
            itxBin.isSomeoneUpdateStorage = false;
          }
        }
      }
    );
    this._hubService.itxBinDeleted.subscribe((hubMessage: ItxBinDeletedHubMessage) => {
      if (hubMessage) {
        const itxBins = this.itxBin$.getValue().filter(l => l.id !== hubMessage.itxBinId);
        this.itxBin$.next(itxBins);
      }
    });
    this._hubService.itxBinRadarInUpdate.subscribe((hubMessage: ItxBinRadarInUpdateHubMessage) => {
      if (hubMessage) {
        const itxBin = this.itxBin$.getValue().find(l => l.id == hubMessage.itxBinId);
        if (itxBin) {
          itxBin.isSomeoneUpdateRadar = hubMessage.inUpdate;
          this.itxBin$.next(this.itxBin$.getValue());
        }
      }
    });
    this._hubService.itxBinRadarDeleted.subscribe((hubMessage: ItxBinRadarDeletedHubMessage) => {
      if (hubMessage) {
        const itxBin = this.itxBin$.getValue().find((l) => l.id == hubMessage.deletedRadar.itxBinId);
        if (itxBin) {
          itxBin.radars = itxBin.radars.filter(r => r.id != hubMessage.deletedRadar.id);
          itxBin.countOfRadars = itxBin.radars.length;
          this.itxBin$.next(this.itxBin$.getValue());
        }
      }
    });
    this._hubService.itxBinRadarUpdated.subscribe((hubMessage: ItxBinRadarUpdatedHubMessage) => {
      if (hubMessage) {
        let itxBin = this.itxBin$.getValue().find(i => i.id == hubMessage.radar.itxBinId);
        if (itxBin) {
          let radar = itxBin.radars.find(r => r.id == hubMessage.radar.id);
          if (radar) {
            radar.radarId = hubMessage.radar.radarId;
            radar.radarIdAddress = hubMessage.radar.radarIdAddress;
            this.itxBin$.next(this.itxBin$.getValue());
          }
        }
      }
    });
    this._hubService.itxBinRadarAdded.subscribe((hubMessage: ItxBinRadarAddedHubMessage) => {
      if (hubMessage) {
        let itxBin = this.itxBin$.getValue().find(i => i.id == hubMessage.newRadar.itxBinId);
        if (itxBin) {
          itxBin.radars.push(hubMessage.newRadar);
          itxBin.countOfRadars = itxBin.radars.length;
          this.itxBin$.next(this.itxBin$.getValue());
        }
      }
    });
  }

  async addRadar(model: RadarPostModel): Promise<RadarViewModel | ErrorModel> {

    let newInstance = RadarPostModel.CreateNewInstance(model);
    try {
      const result = await this._itxBinApi.addRadar(newInstance) as RadarViewModel;
      if (result instanceof ErrorModel) {
        return result;
      } else {
        this._hubService.sendToAllServiceUsers(HubMethods.ItxBin.itxBinRadarAdded, new ItxBinRadarAddedHubMessage(result));
        let itxBinModel = await this.itxBin$.getValue().find(i => i.id == result.itxBinId);
        if (itxBinModel) {
          itxBinModel.radars.push(result);
          itxBinModel.countOfRadars = itxBinModel.radars.length;
        }

        this.itxBin$.next([...this.itxBin$.getValue()]);
        return result;
      }
    }
    catch (error) {
      let errorModel = error as ErrorModel
      return errorModel
    }
  }

  async editRadar(model: RadarPostModel): Promise<RadarViewModel | ErrorModel> {
    let newInstance = RadarPostModel.CreateNewInstance(model);
    try {
      const result = await this._itxBinApi.editRadar(newInstance) as RadarViewModel
      if (result instanceof ErrorModel) {
        return result;
      } else {
        this._hubService.sendToAllServiceUsers(HubMethods.ItxBin.itxBinRadarUpdated, new ItxBinRadarUpdatedHubMessage(result));
        let itxBinModel = await this.itxBin$.getValue().find(i => i.id == result.itxBinId);
        let radar = itxBinModel.radars.find(r => r.id == result.id);
        radar.radarId = result.radarId;
        radar.radarIdAddress = result.radarIdAddress;
        radar.radarLocation = result.radarLocation;
        radar.distanceFromBinCenter = result.distanceFromBinCenter;
        this.itxBin$.next([...this.itxBin$.getValue()]);
      }
    } catch (error) {
      let errorModel = error as ErrorModel;
      return errorModel;
    }
  }

  public async deleteRadar(radar: RadarViewModel): Promise<boolean | ErrorModel> {
    try {
      const result = await this._itxBinApi.deleteRadar(radar.id);
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.ItxBin.itxBinRadarDeleted, new ItxBinRadarDeletedHubMessage(radar));
        const itxBin = this.itxBin$.getValue().find((l) => l.id == radar.itxBinId);
        if (itxBin) {
          itxBin.radars = itxBin.radars.filter(r => r.id != radar.id);
          itxBin.countOfRadars = itxBin.radars.length;
          this.itxBin$.next(this.itxBin$.getValue());
        }
        return Promise.resolve(true);
      }
      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }
}
