import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { TreeNode } from 'primeng/api';
import { Calendar } from 'primeng/calendar';

import { ItxEventByPeriodPostModel, ItxEventGetModel, ItxEventsRequestModel, ItxEventViewModel, ItxLocationNameModel } from '@models';
import { EventDatasourceService, LocationsItxDatasourceService, RoleService } from '@services';
import { ItxEventTypesIconClass, ItxEventTypesLabel, EnglishDateLocale, DefaultDateRangeLabel, CalendarDefaultValues } from '../../constants';
import { ColumnTypeEnum, ItxEventTypeEnum, DefaultDateRangeEnum, TutorialMenuEnum, ProjectEnum, ItxNodeTypesEnum } from '../../enums';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ExportNotificationPopupComponent, ItxStorageRadarsPopupComponent, TutorialPopupComponent } from '../popups';
import { UserRoleEnum } from './../../../common';


@Component({
  selector: 'greensleeves-itx-alert-tab',
  templateUrl: './itx-alert-tab.component.html',
  styles: []
})
export class ItxAlertTabComponent implements OnInit, OnDestroy {
  static ALERT_TYPE = 'alertType';
  static SORT_ORDER_DEFAULT = 1;
  static EVENTS_PER_PAGE = 100;

  @ViewChild("calendar", { static: false })
  private calendar: Calendar;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  @ViewChild(ExportNotificationPopupComponent, { read: false, static: false })
  private exportPopup: ExportNotificationPopupComponent;

  @ViewChild(ItxStorageRadarsPopupComponent, { read: false, static: false })
  private listOfRadarsPopup: ItxStorageRadarsPopupComponent;

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = ItxAlertTabComponent.ALERT_TYPE
  _sortOrder = ItxAlertTabComponent.SORT_ORDER_DEFAULT;
  _gridData: ItxEventViewModel[];
  _ps: PerfectScrollbar;
  _alertTypes: { label: string, value: ItxEventTypeEnum }[] = [];
  _isOpenFilterList: boolean = false;
  _totalAlertsCount: number;
  _eventFilter: ItxEventByPeriodPostModel = new ItxEventByPeriodPostModel(ItxAlertTabComponent.EVENTS_PER_PAGE);
  _itxLocations: TreeNode[];
  _nodeSelected: TreeNode[];
  _defaultDateRange: { label: string, value: DefaultDateRangeEnum }[] = [];
  _reportPeriod: FormGroup;
  _rangeDate: Date[] = [];
  _dataLocale: EnglishDateLocale = new EnglishDateLocale();
  _selectedDefaultDateRange: DefaultDateRangeEnum = null;
  _loading: boolean = false;
  _loadMore: boolean = false;
  private defaultDateRangePreviousState: DefaultDateRangeEnum = null;
  private locations: ItxLocationNameModel[] = [];

  _columnsView = [
    { header: 'First Time:', columnType: ColumnTypeEnum.Date, dataField: 'firstNotificationTimeStamp' },
    { header: 'Last Time:', columnType: ColumnTypeEnum.Date, dataField: 'lastNotificationTimeStamp' },
    { header: 'Alert type:', columnType: ColumnTypeEnum.AlertIcon, dataField: 'itxAlertType' },
    { header: 'Message:', columnType: ColumnTypeEnum.AlertTitle, dataField: 'title' },
  ]

  get _startDate() {
    return this._rangeDate[0];
  }
  get _endDate() {
    return this._rangeDate[1] == null ? this._rangeDate[0] : this._rangeDate[1];
  }

  get _isCompanyUsers(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser);
  }

  get getDataPickterYearRange(): string {
    const dateNow = new Date();
    return `${dateNow.getFullYear() - 3}:${dateNow.getFullYear()}`;
  }

  get _isActiveAlert(): boolean {
    return this._eventFilter.dateTimeFrom == null && this._eventFilter.dateTimeTo == null
  }

  constructor(
    private _el: ElementRef,
    private _zone: NgZone,
    private _eventDatasourceService: EventDatasourceService,
    private _itxLocationDataSourceService: LocationsItxDatasourceService,
    private _formBuilder: FormBuilder,
    private _roleService: RoleService,
  ) { }

  async ngOnInit() {
    this.reinitScrollBar(0);
    this.resetCalendar();

    for (const [key, value] of Object.entries(ItxEventTypesLabel)) {
      this._alertTypes.push({ label: value, value: Number(key) });
    }

    for (const [key, value] of Object.entries(DefaultDateRangeLabel)) {
      this._defaultDateRange.push({ label: value, value: Number(key) });
    }

    this._reportPeriod = this._formBuilder.group({
      selectedPeriod: [this._rangeDate],
      dateRange: [],
      startPeriod: [this._startDate],
      endOfPeriod: [this._endDate],
    });

    this.locations = await this._itxLocationDataSourceService.getListOfAssignedItxLocationsWithItxBins() as ItxLocationNameModel[];
    if (this.locations) {
      this._itxLocations = this.initLocationTree();
    }

    this.onUpdateEventsByFilter();
  }

  ngOnDestroy() {
    this._ps && this._ps.destroy();
    this._ps = null;
  }

  ngAfterViewInit() {
    this.reinitScrollBar(0);
  }

  getAlertClassByType(alert: ItxEventTypeEnum) {
    return ItxEventTypesIconClass[alert];
  }

  getAlertLabelByType(alert: ItxEventTypeEnum) {
    return ItxEventTypesLabel[alert];
  }

  openFilterList() {
    this._isOpenFilterList = !this._isOpenFilterList;
  }

  async onUpdateEventsByFilter() {
    this._loading = true;
    this._eventFilter.currentPage = 1;
    let result = await this._eventDatasourceService.getItxEventsByFilter(this._eventFilter) as ItxEventsRequestModel;
    if (result) {
      this._gridData = result.events.map(i => ItxEventGetModel.toViewModel(i));
      this._totalAlertsCount = result.totalCount;
    }
    this._loading = false;
  }

  async getMoreEvents() {
    this._loadMore = true;
    this._eventFilter.currentPage++;
    let result = await this._eventDatasourceService.getItxEventsByFilter(this._eventFilter) as ItxEventsRequestModel;
    if (result) {
      this._gridData.push(...result.events.map(i => ItxEventGetModel.toViewModel(i)));
    }
    this._loadMore = false;
  }

  onClickLocationFilter() {
    this._eventFilter.itxBinIds = [];
    this._eventFilter.locationIds = [];
    this._nodeSelected.forEach(i => {
      switch (i.data.nodeType) {
        case ItxNodeTypesEnum.Location:
          this._eventFilter.locationIds.push(i.data.id);
          break;
        case ItxNodeTypesEnum.Bin:
          this._eventFilter.itxBinIds.push(i.data.id);
          break;
      }
    });
    this.onUpdateEventsByFilter();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.ItxAlerts);
  }

  showRadarCurrentData(binId: number) {
    this.listOfRadarsPopup.showWithLoadTelemetry(binId);
  }

  setFastDate(fastRange: DefaultDateRangeEnum) {
    let endDate = new Date();
    let startDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    this._rangeDate = [];
    switch (fastRange) {
      case DefaultDateRangeEnum.Week:
        startDate.setDate(startDate.getDate() - CalendarDefaultValues.defaultOneWeekDays);
        break;
      case DefaultDateRangeEnum.Month:
        startDate.setDate(startDate.getDate() - CalendarDefaultValues.defaultOneMonthDays);
        break;
    }
    this._selectedDefaultDateRange = fastRange;
    this._rangeDate.push(startDate);
    this._rangeDate.push(endDate);
  }

  getCurrentEvents() {
    this.resetCalendar();
    this._selectedDefaultDateRange = null;
    this._eventFilter.dateTimeFrom = null;
    this._eventFilter.dateTimeTo = null;
    this.onUpdateEventsByFilter();
  }

  onApplyDateRangeFilter() {
    if (this._rangeDate == undefined || this._rangeDate.length == 0) {
      this.resetCalendar();
    }
    if (this._rangeDate[1] == undefined || this._rangeDate[1] == null) {
      this._rangeDate[1] = this._rangeDate[0];
    }
    this._eventFilter.dateTimeFrom = (this._rangeDate[0].getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this._eventFilter.dateTimeTo = (this._rangeDate[1].getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this.onUpdateEventsByFilter();
    this.defaultDateRangePreviousState = this._selectedDefaultDateRange;
    this.calendar.toggle();
  }

  onSeclectCalendar() {
    this._selectedDefaultDateRange = null;
  }

  onCloseCalendar() {
    if (this._eventFilter.dateTimeFrom == null && this._eventFilter.dateTimeTo == null) {
      this.resetCalendar();
      return;
    }
    if (this.defaultDateRangePreviousState != null) {
      this.setFastDate(this.defaultDateRangePreviousState);
      return;
    }
    this._rangeDate = [];
    this._rangeDate.push(new Date(this._eventFilter.dateTimeFrom * CalendarDefaultValues.defaultOneSecondMilliseconds));
    this._rangeDate.push(new Date(this._eventFilter.dateTimeTo * CalendarDefaultValues.defaultOneSecondMilliseconds));
  }

  showExport() {
    this.exportPopup.onShow(this.initLocationTree(), ProjectEnum.Itx);
  }

  public reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  private resetCalendar() {
    this.defaultDateRangePreviousState = null;
    this._selectedDefaultDateRange = null;
    this._rangeDate = [];
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this._rangeDate.push(today);
    this._rangeDate.push(today);
  }

  private initLocationTree(): TreeNode[] {
    return this.locations.map(i => ({
      label: i.name, key: i.id.toString() + i.name, data: { id: i.id, nodeType: ItxNodeTypesEnum.Location }, expanded: false,
      children: (i.bins != undefined && i.bins.length > 0) ? i.bins.map(b => (
        {
          label: b.name,
          data: { id: b.id, nodeType: ItxNodeTypesEnum.Bin, locationId: i.id },
          key: b.id.toString() + b.name
        })) : []
    }));
  }
}