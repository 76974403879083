import { Component, OnInit } from '@angular/core';
import { CompanyUserViewModel } from '../../../models';
import { CompanyUsersDataSourceService } from '../../../services';

@Component({
  selector: 'greensleeves-company-user-delete-popup',
  templateUrl: './company-user-delete-popup.component.html',
  styles: []
})

export class CompanyUserDeletePopupComponent implements OnInit {
  _isHidden = true;
  _companyUser: CompanyUserViewModel;

  constructor(
    private _companyUserDataSourceService: CompanyUsersDataSourceService,
  ) { }

  ngOnInit() {
  }

  public show(companyUser: CompanyUserViewModel) {
    this._isHidden = false;
    this._companyUser = companyUser;
  }

  async onSubmit() {
    const result = await this._companyUserDataSourceService.delete(this._companyUser.id);
    if (result != null){
      this._isHidden = true;
      this._companyUser = null;
    }
  }

  onCancel() {
    this._isHidden = true;
    this._companyUser = null;
  }
}