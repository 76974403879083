import {
    EquipmentSideEnum,
    SensorPositionEnum,
    SensorTypeEnum,
    EquipmentTypeEnum,
    EquipmentSubTypeEnum
} from "../enums";

export const equipmentSensorMapping = {
    [EquipmentTypeEnum.Silo]: {
        [EquipmentSubTypeEnum.Small]: {
            [SensorTypeEnum.GrainDepth]: {
                [SensorPositionEnum.CenterOfTheEquipmentScheme]: {
                    [EquipmentSideEnum.None]: 'grain-depth'
                }
            }
        },
        [EquipmentSubTypeEnum.Medium]: {
            [SensorTypeEnum.GrainDepth]: {
                [SensorPositionEnum.CenterOfTheEquipmentScheme]: {
                    [EquipmentSideEnum.None]: 'grain-depth'
                }
            }
        },
        [EquipmentSubTypeEnum.Large]: {
            [SensorTypeEnum.GrainDepth]: {
                [SensorPositionEnum.CenterOfTheEquipmentScheme]: {
                    [EquipmentSideEnum.None]: 'grain-depth'
                }
            }
        }
    },
    [EquipmentTypeEnum.Leg]: {
        [EquipmentSubTypeEnum.WithKneeBearing]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'head', 'left'],
                },
                [SensorPositionEnum.Knee]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'knee', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'knee', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'knee', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'knee', 'left'],
                },
                [SensorPositionEnum.Boot]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'boot', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'boot', 'left']
                }
            },
            [SensorTypeEnum.RubBlockTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Boot]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'boot', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'boot', 'left']
                }
            },
            [SensorTypeEnum.Speed]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'speed'
                }
            },
            [SensorTypeEnum.ShaftBearingTemperature]: {
                [SensorPositionEnum.Boot]: {
                    [EquipmentSideEnum.North]: ['shaft-bearing-temperature', 'boot', 'left'],
                    [EquipmentSideEnum.South]: ['shaft-bearing-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.East]: ['shaft-bearing-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.West]: ['shaft-bearing-temperature', 'boot', 'left'],
                }
            },
            [SensorTypeEnum.GearBoxTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['gear-box-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['gear-box-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['gear-box-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['gear-box-temperature', 'head', 'left'],
                }
            },
            [SensorTypeEnum.CurrentDesignLoad]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-design-load'
                }
            },
            [SensorTypeEnum.CurrentAmpDraw]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-amp-draw'
                }
            },
            [SensorTypeEnum.Vibration]: {
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['vibration', 'frame']
                },
                [SensorPositionEnum.Motor]: {
                    [EquipmentSideEnum.None]: ['vibration', 'motor']
                },
            },
        },
        [EquipmentSubTypeEnum.WithoutKneeBearing]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Boot]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'boot', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'boot', 'left']
                }
            },
            [SensorTypeEnum.RubBlockTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Boot]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'boot', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'boot', 'left']
                }
            },
            [SensorTypeEnum.Speed]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'speed'
                }
            },
            [SensorTypeEnum.ShaftBearingTemperature]: {
                [SensorPositionEnum.Boot]: {
                    [EquipmentSideEnum.North]: ['shaft-bearing-temperature', 'boot', 'left'],
                    [EquipmentSideEnum.South]: ['shaft-bearing-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.East]: ['shaft-bearing-temperature', 'boot', 'right'],
                    [EquipmentSideEnum.West]: ['shaft-bearing-temperature', 'boot', 'left'],
                }
            },
            [SensorTypeEnum.GearBoxTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['gear-box-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['gear-box-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['gear-box-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['gear-box-temperature', 'head', 'left'],
                }
            },
            [SensorTypeEnum.CurrentDesignLoad]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-design-load'
                }
            },
            [SensorTypeEnum.CurrentAmpDraw]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-amp-draw'
                }
            },
            [SensorTypeEnum.Vibration]: {
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['vibration', 'frame']
                },
                [SensorPositionEnum.Motor]: {
                    [EquipmentSideEnum.None]: ['vibration', 'motor']
                },
            },
        }
    },
    [EquipmentTypeEnum.Conveyor]: {
        [EquipmentSubTypeEnum.Drag]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Tail]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'tail', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'tail', 'left']
                }
            },
            [SensorTypeEnum.Speed]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'speed'
                }
            },
            [SensorTypeEnum.CurrentDesignLoad]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-design-load'
                }
            },
            [SensorTypeEnum.CurrentAmpDraw]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-amp-draw'
                }
            },
            [SensorTypeEnum.Vibration]: {
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['vibration', 'frame']
                },
                [SensorPositionEnum.Motor]: {
                    [EquipmentSideEnum.None]: ['vibration', 'motor']
                },
            },
        },
        [EquipmentSubTypeEnum.Belt]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Tail]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'tail', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'tail', 'left']
                },
                [SensorPositionEnum.BendHead]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'bend-head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'bend-head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'bend-head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'bend-head', 'left']
                },
                [SensorPositionEnum.BendTail]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'bend-tail', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'bend-tail', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'bend-tail', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'bend-tail', 'left']
                },
                [SensorPositionEnum.SnubHead]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'snub-head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'snub-head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'snub-head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'snub-head', 'left']
                },
                [SensorPositionEnum.SnubTail]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'snub-tail', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'snub-tail', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'snub-tail', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'snub-tail', 'left']
                },
                [SensorPositionEnum.KneeHead]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'knee-head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'knee-head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'knee-head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'knee-head', 'left']
                },
                [SensorPositionEnum.KneeTail]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'knee-tail', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'knee-tail', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'knee-tail', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'knee-tail', 'left']
                },
                [SensorPositionEnum.UpperTripperHead]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'upper-tripper-head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'upper-tripper-head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'upper-tripper-head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'upper-tripper-head', 'left']
                },
                [SensorPositionEnum.UpperTripperTail]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'upper-tripper-tail', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'upper-tripper-tail', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'upper-tripper-tail', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'upper-tripper-tail', 'left']
                },
                [SensorPositionEnum.LowerTripperHead]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'lower-tripper-head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'lower-tripper-head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'lower-tripper-head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'lower-tripper-head', 'left']
                },
                [SensorPositionEnum.LowerTripperTail]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'lower-tripper-tail', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'lower-tripper-tail', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'lower-tripper-tail', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'lower-tripper-tail', 'left']
                }
            },
            [SensorTypeEnum.RubBlockTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Tail]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'tail', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'tail', 'left']
                },
                [SensorPositionEnum.BendHead]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'bend-head', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'bend-head', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'bend-head', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'bend-head', 'left']
                },
                [SensorPositionEnum.BendTail]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'bend-tail', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'bend-tail', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'bend-tail', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'bend-tail', 'left']
                },
                [SensorPositionEnum.SnubHead]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'snub-head', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'snub-head', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'snub-head', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'snub-head', 'left']
                },
                [SensorPositionEnum.SnubTail]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'snub-tail', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'snub-tail', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'snub-tail', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'snub-tail', 'left']
                },
                [SensorPositionEnum.KneeHead]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'knee-head', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'knee-head', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'knee-head', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'knee-head', 'left']
                },
                [SensorPositionEnum.KneeTail]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'knee-tail', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'knee-tail', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'knee-tail', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'knee-tail', 'left']
                }
            },
            [SensorTypeEnum.Speed]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'speed'
                }
            },
            [SensorTypeEnum.CurrentDesignLoad]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-design-load'
                }
            },
            [SensorTypeEnum.CurrentAmpDraw]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-amp-draw'
                }
            },
            [SensorTypeEnum.Vibration]: {
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['vibration', 'frame']
                },
                [SensorPositionEnum.Motor]: {
                    [EquipmentSideEnum.None]: ['vibration', 'motor']
                },
            },
        },
        [EquipmentSubTypeEnum.Screw]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.None]: ['bearing-temperature', 'head']
                },
                [SensorPositionEnum.Tail]: {
                    [EquipmentSideEnum.None]: ['bearing-temperature', 'tail']
                }
            },
            [SensorTypeEnum.Speed]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'speed'
                }
            },
            [SensorTypeEnum.CurrentDesignLoad]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: ['current-design-load']
                }
            },
            [SensorTypeEnum.CurrentAmpDraw]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: ['current-amp-draw']
                }
            },
            [SensorTypeEnum.Vibration]: {
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['vibration', 'frame']
                },
                [SensorPositionEnum.Motor]: {
                    [EquipmentSideEnum.None]: ['vibration', 'motor']
                },
            },
        },
        [EquipmentSubTypeEnum.CoveredBelt]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Tail]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'tail', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'tail', 'left']
                },
            },
            [SensorTypeEnum.BottomRubTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['bottom-rub-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['bottom-rub-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['bottom-rub-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['bottom-rub-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Tail]: {
                    [EquipmentSideEnum.North]: ['bottom-rub-temperature', 'tail', 'left'],
                    [EquipmentSideEnum.South]: ['bottom-rub-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.East]: ['bottom-rub-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.West]: ['bottom-rub-temperature', 'tail', 'left']
                },
            },
            [SensorTypeEnum.RubBlockTemperature]: {
                [SensorPositionEnum.Head]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'head', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'head', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'head', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'head', 'left']
                },
                [SensorPositionEnum.Tail]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'tail', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'tail', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'tail', 'left']
                },
            },
            [SensorTypeEnum.Speed]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'speed'
                }
            },
            [SensorTypeEnum.CurrentDesignLoad]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-design-load'
                }
            },
            [SensorTypeEnum.CurrentAmpDraw]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-amp-draw'
                }
            },
            [SensorTypeEnum.Vibration]: {
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['vibration', 'frame']
                },
                [SensorPositionEnum.Motor]: {
                    [EquipmentSideEnum.None]: ['vibration', 'motor']
                },
            },
        },
    },
    [EquipmentTypeEnum.Tripper]: {
        [EquipmentSubTypeEnum.None]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.Upper]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'upper', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'upper', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'upper', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'upper', 'left']
                },
                [SensorPositionEnum.Lower]: {
                    [EquipmentSideEnum.North]: ['bearing-temperature', 'lower', 'left'],
                    [EquipmentSideEnum.South]: ['bearing-temperature', 'lower', 'right'],
                    [EquipmentSideEnum.East]: ['bearing-temperature', 'lower', 'right'],
                    [EquipmentSideEnum.West]: ['bearing-temperature', 'lower', 'left']
                },
            },
            [SensorTypeEnum.RubBlockTemperature]: {
                [SensorPositionEnum.Top]: {
                    [EquipmentSideEnum.North]: ['rub-block-temperature', 'top', 'left'],
                    [EquipmentSideEnum.South]: ['rub-block-temperature', 'top', 'right'],
                    [EquipmentSideEnum.East]: ['rub-block-temperature', 'top', 'right'],
                    [EquipmentSideEnum.West]: ['rub-block-temperature', 'top', 'left']
                },
            },
        }
    },
    [EquipmentTypeEnum.Fan]: {
        [EquipmentSubTypeEnum.BeltDrive]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.DriveUpper]: {
                    [EquipmentSideEnum.None]: ['bearing-temperature', 'drive-upper']
                },
                [SensorPositionEnum.DriveLower]: {
                    [EquipmentSideEnum.None]: ['bearing-temperature', 'drive-lower']
                },
                [SensorPositionEnum.FrameUpper]: {
                    [EquipmentSideEnum.None]: ['bearing-temperature', 'frame-upper']
                },
                [SensorPositionEnum.FrameLower]: {
                    [EquipmentSideEnum.None]: ['bearing-temperature', 'frame-lower']
                },
            },
            [SensorTypeEnum.CurrentDesignLoad]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-design-load'
                }
            },
            [SensorTypeEnum.CurrentAmpDraw]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-amp-draw'
                }
            },
            [SensorTypeEnum.Speed]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'speed'
                }
            },
            [SensorTypeEnum.Vibration]: {
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['vibration', 'frame']
                },
                [SensorPositionEnum.Motor]: {
                    [EquipmentSideEnum.None]: ['vibration', 'motor']
                },
            },
        },
        [EquipmentSubTypeEnum.DirectDrive]: {
            [SensorTypeEnum.BearingTemperature]: {
                [SensorPositionEnum.Drive]: {
                    [EquipmentSideEnum.None]: ['bearing-temperature', 'drive']
                },
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['bearing-temperature', 'frame']
                },
            },
            [SensorTypeEnum.CurrentDesignLoad]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-design-load'
                }
            },
            [SensorTypeEnum.CurrentAmpDraw]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'current-amp-draw'
                }
            },
            [SensorTypeEnum.Speed]: {
                [SensorPositionEnum.OnePerEquipment]: {
                    [EquipmentSideEnum.None]: 'speed'
                }
            },
            [SensorTypeEnum.Vibration]: {
                [SensorPositionEnum.Frame]: {
                    [EquipmentSideEnum.None]: ['vibration', 'frame']
                },
                [SensorPositionEnum.Motor]: {
                    [EquipmentSideEnum.None]: ['vibration', 'motor']
                },
            },
        },
    },
}

export const equipmentSchemes = {
    [EquipmentTypeEnum.Silo]: {
        [EquipmentSubTypeEnum.Small]: {
            [EquipmentSideEnum.None]: ['silo', 'small'],
            [EquipmentSideEnum.North]: '',
            [EquipmentSideEnum.South]: '',
            [EquipmentSideEnum.East]: '',
            [EquipmentSideEnum.West]: '',
        },
        [EquipmentSubTypeEnum.Medium]: {
            [EquipmentSideEnum.None]: ['silo', 'medium'],
            [EquipmentSideEnum.North]: '',
            [EquipmentSideEnum.South]: '',
            [EquipmentSideEnum.East]: '',
            [EquipmentSideEnum.West]: '',
        },
        [EquipmentSubTypeEnum.Large]: {
            [EquipmentSideEnum.None]: ['silo', 'large'],
            [EquipmentSideEnum.North]: '',
            [EquipmentSideEnum.South]: '',
            [EquipmentSideEnum.East]: '',
            [EquipmentSideEnum.West]: '',
        }
    },
    [EquipmentTypeEnum.Leg]: {
        [EquipmentSubTypeEnum.WithKneeBearing]: {
            [EquipmentSideEnum.None]: '',
            [EquipmentSideEnum.North]: ['leg', 'with-knee-bearing', 'left'],
            [EquipmentSideEnum.South]: ['leg', 'with-knee-bearing', 'right'],
            [EquipmentSideEnum.East]: ['leg', 'with-knee-bearing', 'right'],
            [EquipmentSideEnum.West]: ['leg', 'with-knee-bearing', 'left']
        },
        [EquipmentSubTypeEnum.WithoutKneeBearing]: {
            [EquipmentSideEnum.None]: '',
            [EquipmentSideEnum.North]: ['leg', 'without-knee-bearing', 'left'],
            [EquipmentSideEnum.South]: ['leg', 'without-knee-bearing', 'right'],
            [EquipmentSideEnum.East]: ['leg', 'without-knee-bearing', 'right'],
            [EquipmentSideEnum.West]: ['leg', 'without-knee-bearing', 'left']
        }
    },
    [EquipmentTypeEnum.Conveyor]: {
        [EquipmentSubTypeEnum.Drag]: {
            [EquipmentSideEnum.None]: '',
            [EquipmentSideEnum.North]: ['conveyor', 'drag', 'left'],
            [EquipmentSideEnum.South]: ['conveyor', 'drag', 'right', 'drag-south'],
            [EquipmentSideEnum.East]: ['conveyor', 'drag', 'right'],
            [EquipmentSideEnum.West]: ['conveyor', 'drag', 'left']
        },
        [EquipmentSubTypeEnum.Belt]: {
            [EquipmentSideEnum.None]: '',
            [EquipmentSideEnum.North]: ['conveyor', 'belt', 'left'],
            [EquipmentSideEnum.South]: ['conveyor', 'belt', 'right'],
            [EquipmentSideEnum.East]: ['conveyor', 'belt', 'right'],
            [EquipmentSideEnum.West]: ['conveyor', 'belt', 'left']
        },
        [EquipmentSubTypeEnum.Screw]: {
            [EquipmentSideEnum.None]: ['conveyor', 'screw'],
            [EquipmentSideEnum.North]: '',
            [EquipmentSideEnum.South]: '',
            [EquipmentSideEnum.East]: '',
            [EquipmentSideEnum.West]: '',
        },
        [EquipmentSubTypeEnum.CoveredBelt]: {
            [EquipmentSideEnum.None]: '',
            [EquipmentSideEnum.North]: ['conveyor', 'covered-belt', 'left'],
            [EquipmentSideEnum.South]: ['conveyor', 'covered-belt', 'right'],
            [EquipmentSideEnum.East]: ['conveyor', 'covered-belt', 'right'],
            [EquipmentSideEnum.West]: ['conveyor', 'covered-belt', 'left']
        },
    },
    [EquipmentTypeEnum.Tripper]: {
        [EquipmentSubTypeEnum.None]: {
            [EquipmentSideEnum.None]: '',
            [EquipmentSideEnum.North]: ['tripper', '', 'left'],
            [EquipmentSideEnum.South]: ['tripper', '', 'right'],
            [EquipmentSideEnum.East]: ['tripper', '', 'right'],
            [EquipmentSideEnum.West]: ['tripper', '', 'left']
        }
    },
    [EquipmentTypeEnum.Fan]: {
        [EquipmentSubTypeEnum.BeltDrive]: {
            [EquipmentSideEnum.None]: ['fan', 'belt-drive', ''],
            [EquipmentSideEnum.North]: '',
            [EquipmentSideEnum.South]: '',
            [EquipmentSideEnum.East]: '',
            [EquipmentSideEnum.West]: ''
        },
        [EquipmentSubTypeEnum.DirectDrive]: {
            [EquipmentSideEnum.None]: ['fan', 'direct-drive', ''],
            [EquipmentSideEnum.North]: '',
            [EquipmentSideEnum.South]: '',
            [EquipmentSideEnum.East]: '',
            [EquipmentSideEnum.West]: ''
        },
    }
}

export const SideAB = {
    [EquipmentSideEnum.East]:EquipmentSideEnum.South,
    [EquipmentSideEnum.West]: EquipmentSideEnum.North,
    [EquipmentSideEnum.North]: EquipmentSideEnum.West,
    [EquipmentSideEnum.South]: EquipmentSideEnum.East,
}