export class BtxReportLiveDataFilter {
    public equipmentIds: number[];
    public timeZone: string;
}

export class BtxHistoricalDataReportFilterRequest {
    equipmentId: number;
    sensorIds: number[];
    timeStampFrom: number;
    timeStampTo: number;
    timeZone: string;
}