import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ColumnTypeEnum, UserStatusEnum } from '../../../enums';
import { CompanyUserPostModel, CompanyUserViewModel, UserLocation } from '../../../models';
import { CompanyUsersDataSourceService } from '../../../services';
import { LocalStorageService, UserRoleEnum } from '../../../../common';
import { Subscription } from 'rxjs';
import { CompanyUserEditPopupComponent, CompanyUserDeletePopupComponent } from '../../popups';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'greensleeves-company-users-grid',
  templateUrl: './company-users-grid.component.html'
})

export class CompanyUsersGridComponent implements OnInit, OnDestroy {
  static LOCATION_NAME_KEY = 'locations';
  static SORT_ORDER_DEFAULT = -1;

  @ViewChild(CompanyUserEditPopupComponent, { read: false, static: false })
  _editModal: CompanyUserEditPopupComponent;

  @ViewChild(CompanyUserDeletePopupComponent, { read: false, static: false })
  _deleteModal: CompanyUserDeletePopupComponent;

  _columnTypeEnum = ColumnTypeEnum;
  _userStatus = UserStatusEnum;
  _sortField = CompanyUsersGridComponent.LOCATION_NAME_KEY;
  _sortOrder = CompanyUsersGridComponent.SORT_ORDER_DEFAULT;
  _gridData: CompanyUserViewModel[];
  _locationNameKey = CompanyUsersGridComponent.LOCATION_NAME_KEY;

  _searchPhrase: string = '';
  _searchForm: FormGroup;
  _lastSearchPhrase: string;
  _companyUsersSubscription: Subscription;
  _role: UserRoleEnum;
  _loading: boolean = false;

  get _isCompanyAdmin(): boolean {
    return this._role === UserRoleEnum.CompanyAdmin;
  }

  get _columns() {
    return [
      { header: 'First name', columnType: ColumnTypeEnum.UserFirstName, dataField: 'firstName' },
      { header: 'Last name', columnType: ColumnTypeEnum.UserLastName, dataField: 'lastName' },
      { header: 'Email', dataField: 'email' },
      ...(this._gridData.some(function (el) { return el.arduinoLocations.length > 0 }) ? [{ header: 'BTX Locations', columnType: ColumnTypeEnum.UserLocationNames, dataField: 'arduinoLocationNames' }] : []),
      ...(this._gridData.some(function (el) { return el.raspberryLocations.length > 0 }) ? [{ header: 'KTX Locations', columnType: ColumnTypeEnum.UserLocationNames, dataField: 'raspberryLocationNames' }] : []),
      ...(this._gridData.some(function (el) { return el.itxLocations.length > 0 }) ? [{ header: 'ITX Locations', columnType: ColumnTypeEnum.UserLocationNames, dataField: 'itxLocationsNames' }] : []),
      { header: 'Last login date', columnType: ColumnTypeEnum.Date, dataField: 'lastLoginDate', width: "170" },
      { header: 'Status', columnType: ColumnTypeEnum.Status, dataField: 'status', width: "80" },
      { header: '', columnType: ColumnTypeEnum.Edit, width: "46" },
      { header: '', columnType: ColumnTypeEnum.Delete, width: "46" }
    ];
  }

  constructor(
    private _companyUsersService: CompanyUsersDataSourceService,
    private _localStorageService: LocalStorageService,
    formBuilder: FormBuilder
  ) {
    this._searchForm = formBuilder.group({
      searchPhrase: ['']
    });
  }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;
    this._loading = true;
    this._companyUsersService.get(info ? info.companyId : 0);
    this._companyUsersSubscription = this._companyUsersService.companyUsers$
      .subscribe(data => {
        this._gridData = data;
        this._loading = false;
      });
  }

  ngOnDestroy() {
    this._companyUsersSubscription && this._companyUsersSubscription.unsubscribe();
    this._companyUsersSubscription = null;
  }

  async onClickEdit(companyUser: CompanyUserViewModel) {
    await this._companyUsersService.markCompanyUserForOthersAsInUpdate(companyUser.id);
    this._editModal.show(companyUser);
  }

  onClickDelete(companyUser: CompanyUserViewModel) {
    this._deleteModal.show(companyUser);
  }

  onClickSearch(_searchPhrase: string) {
    this._companyUsersSubscription = this._companyUsersService.companyUsers$
      .subscribe(data => this._gridData = data);

    if (_searchPhrase.length > 0) {
      this._gridData = this._gridData.filter(
        i => i.arduinoLocations.filter(l => l.name.toLowerCase().indexOf(_searchPhrase.toLowerCase()) > -1).length > 0
          || i.raspberryLocations.filter(l => l.name.toLowerCase().indexOf(_searchPhrase.toLowerCase()) > -1).length > 0
          || i.itxLocations.filter(l => l.name.toLowerCase().indexOf(_searchPhrase.toLowerCase()) > -1).length > 0);
    }
  }
}