export class KtxWeeksRangeModel {
    id: number;
    weekFrom: Date;
    weekTo: Date;

    constructor(id: number, weekFrom: Date, weekTo: Date) {
        this.id = id;
        this.weekFrom = weekFrom;
        this.weekTo = weekTo;
      }
}