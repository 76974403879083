import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CheckProjectsGetModel, GetInstalledProjectFirmwareInfo, InstallProjectModel, InstallProjectPostModel, UpdateFirmwareResultModel } from '@models';
import { UpdaterDeviceDatasourceService } from '@services';
import { MessageService } from 'primeng/api';

import { BasePopupBehavior } from '../common';
import { ErrorModel } from './../../../../common';
import { ConfirmationDialogEnum, ProjectEnum, UpdateProjectStatusEnum } from './../../../enums';
import { ConfirmationPopupComponent } from '../confirmation-popup';

@Component({
  selector: 'greensleeves-upload-firmware-install-popup',
  templateUrl: './upload-firmware-install-popup.component.html',
  providers: [MessageService]
})
export class UploadFirmwareInstallPopupComponent extends BasePopupBehavior implements OnInit {
  @ViewChild(ConfirmationPopupComponent, { read: false, static: false })
  private confirmationPopup: ConfirmationPopupComponent;

  _isHidden = true;
  _formGroup: FormGroup;
  _projectName: string = null;
  _locationName: string = null;
  _errorMessage: string = null;
  _isLoading = false;

  _submitted = false;
  _isFileUploaded = false;
  _isError = false;

  get _fileName(): string {
    return this.fileToUpload ? this.fileToUpload.name : '';
  }

  get _isSubmitDisabled(): boolean {
    return this._isLoading;
  }

  private fileToUpload: File;
  private installProject: InstallProjectModel;
  constructor(
    private _updaterDeviceDataSourceService: UpdaterDeviceDatasourceService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private _messageService: MessageService,
  ) { super(); }

  ngOnInit() {
    this._formGroup = this.formBuilder.group({});
  }

  onShow(installModel: InstallProjectModel) {
    this._isHidden = false;
    this.installProject = installModel;
    this._locationName = installModel.locationName;
    this._projectName = ProjectEnum[installModel.project];
    this.cdRef.detectChanges();
  }

  onClickClose() {
    this._isHidden = true;
    this.installProject = null;
    this._submitted = false;
    this._isError = false;
    this._errorMessage = null;
    this.fileToUpload = null;
    this._isLoading = false;
  }

  async onSubmit() {
    this._submitted = true;

    if (!this.fileToUpload) {
      this._errorMessage = 'File is required';
      this._submitted = false;
      return;
    }
    this._isLoading = true;
    const getInstalledProjectInfo = {
      project: this.installProject.project,
      updaterDeviceId: this.installProject.updaterDeviceId,
    } as CheckProjectsGetModel;

    let projectInfo = await this._updaterDeviceDataSourceService.getInstalledProjectFirmwareInformation(getInstalledProjectInfo) as GetInstalledProjectFirmwareInfo;
    if (projectInfo.status == UpdateProjectStatusEnum.Offline) {
      this._messageService.add({ severity: 'error', summary: 'Error!', detail: "Device is offline or updater was not installed.", life: 5000 });
      this._isLoading = false;
      return;
    }

    if (projectInfo.isInstalled) {
      this.confirmationPopup.show(`This project is already installed, with version ${projectInfo.version}.`, ' Would you like to proceed?', 'Submit');
    } else {
      this.installPackage(false);
    }
  }

  async onSelect(onSelectEvent: any) {
    const uploadFile = onSelectEvent.currentFiles[0] as File;

    if (uploadFile.size == 0) {
      this._errorMessage = 'File size should be more than 0.';
    } else if (uploadFile.size >= 30000000) {
      this.fileToUpload = uploadFile;
      this._isError = true;
      this._errorMessage = 'File size should be less than 30 Mb.';
    } else {
      this._errorMessage = null;
      this.fileToUpload = uploadFile;
      this._isFileUploaded = false;
      this._isError = false;
    }
  }

  confirmationResult(confirmationResult: ConfirmationDialogEnum) {
    if (confirmationResult == ConfirmationDialogEnum.Confirmation) {
      this.installPackage(true);
    } else {
      this._isLoading = false;
    }
  }

  private async installPackage(overwriteConfig: boolean) {
    const request = {
      ...this.installProject,
      file: this.fileToUpload,
      overwriteConfig: overwriteConfig,
    } as InstallProjectPostModel;
    var result = await this._updaterDeviceDataSourceService.installProjectFirmware(request);
    if (result instanceof ErrorModel) {
      this._messageService.add({ severity: 'error', summary: 'Error!', detail: result.message, life: 10000 });
    }
    else if (result instanceof UpdateFirmwareResultModel && !result.payLoadSuccess) {
      let location = this._updaterDeviceDataSourceService.locationsUpdaters$.getValue().find(x => x.id == this.installProject.locationId);
      this._messageService.add({ severity: 'error', summary: `${location.name} Firmware update error:`, detail: `${result.payLoadMessage}`, life: 10000 });
    }
    else {
      this.onClickClose();

    }
  }
}
