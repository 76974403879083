export enum AqsFlatDuctingShapeTypeEnum {
  FullRound = 1,
  HalfRound = 2
}


export enum AqsFlatDuctCallMethodsBaseOnEntity {
  OpeningDiameter = 0,
  DuctDiameter = 1,
  NumberFans = 2,
  PerfRun = 3,
  SolidRun = 4,
  CtrCtr = 5,
}

export enum AqsFlatDuctSideNo {
  Near = 0,
  Far = 1,
  Front = 2,
  Rear = 3
}
