import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng';
import { isNumber } from 'util';

import { ReportDatasourceService } from '@services';
import {
  BtxLiveDataEquipmentViewModel,
  BtxLiveDataLocationViewModel,
  BtxReportLiveDataFilter,
} from '@models';
import { ErrorModel } from '../../../../common';

@Component({
  selector: 'greensleeves-btx-live-data-export-report-popup',
  templateUrl: './btx-live-data-export-report-popup.component.html',
})
export class BtxLiveDataExportReportPopupComponent implements OnInit {
  static SELECT_ALL = 'Select All';

  _isHidden = true;
  _isReportRun = false;
  _equipmentNodes: TreeNode[] = [];
  _selectedEquipmentNodes: TreeNode[] = [];
  _locationId: number;

  constructor(private _reportDatasource: ReportDatasourceService) { }

  ngOnInit() {
  }

  public show(
    liveDataLocations: BtxLiveDataLocationViewModel[],
    locationId: number, equipmentId: number,
  ) {
    this._isHidden = false;
    this._locationId = locationId;
    this._selectedEquipmentNodes = [];
    this.initLocationEquipmentNodes(liveDataLocations, equipmentId);
  }

  onCancel() {
    this._isHidden = true;
  }

  async download() {
    this._isReportRun = true;

    const filter: BtxReportLiveDataFilter = new BtxReportLiveDataFilter();

    filter.equipmentIds = this._selectedEquipmentNodes
      .map<number>(eqn => eqn.data)
      .filter(x => isNumber(x));
    filter.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const result = await this._reportDatasource.downloadBtxLiveDataReportFile(
      filter,
    );

    if (!(result instanceof ErrorModel)) {
      const fileName = 'Btx Live Data Report ' + new Date().toLocaleString();
      const url = URL.createObjectURL(result as Blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      this._isHidden = true;
    }

    this._isReportRun = false;
  }

  onNodeSelect(event: any) {
    if (this.isSelectAllNode(event.node)) {
      this.selectAllNodes();
    }
  }

  onNodeUnselect(event: any) {
    if (this.isSelectAllNode(event.node)) {
      this.unselectAllNodes();

      return;
    }

    this._selectedEquipmentNodes = this._selectedEquipmentNodes.filter(
      n => !this.isSelectAllNode(n),
    );
  }

  private initLocationEquipmentNodes(
    liveDataLocations: BtxLiveDataLocationViewModel[], equipmentId: number,
  ) {
    const { SELECT_ALL } = BtxLiveDataExportReportPopupComponent;

    const selectAllNodes: TreeNode = {
      label: SELECT_ALL,
      data: SELECT_ALL,
      key: SELECT_ALL,
    };

    const selectedLocation = liveDataLocations.find(
      location => location.id === this._locationId,
    );

    this._equipmentNodes = [
      selectAllNodes,
      ...selectedLocation.equipments.map<TreeNode>(
        BtxLiveDataEquipmentViewModel.toReportEquipmentTreeNode,
      ),
    ];
    const selectEqipment = this._equipmentNodes.find(x => x.data == equipmentId);
    if (selectEqipment != undefined) {
      this._selectedEquipmentNodes.push(selectEqipment);
    }
  }

  private selectAllNodes() {
    this._selectedEquipmentNodes = [...this._equipmentNodes];
  }

  private unselectAllNodes() {
    this._selectedEquipmentNodes = [];
  }

  private isSelectAllNode(node: TreeNode) {
    const { label, data, key } = node;
    const { SELECT_ALL } = BtxLiveDataExportReportPopupComponent;

    return label === SELECT_ALL && data === SELECT_ALL && key === SELECT_ALL;
  }
}
