import { Component, EventEmitter, OnInit } from '@angular/core';
import { QuoteFlatStorageReportModel, QuoteRoundStorageReportModel, ReportComputationSheetModel } from '@models';
import { AqsReportDatasourceService } from '@services';

import { ErrorModel } from '../../../../common';
import { AqsReportFormatFileLabel } from '../../../constants';
import { AqsSessionTypeEnum, ReportFormatEnum } from '../../../enums';

@Component({
  selector: 'greensleeves-computation-sheet-report-popup',
  templateUrl: './computation-sheet-report-popup.component.html',
  styles: []
})
export class ComputationSheetReportPopupComponent implements OnInit {
  public exportCompleted = new EventEmitter<boolean>();
  private reportComputationSheetModel: ReportComputationSheetModel;
  private quoteRoundStorageReportModel: QuoteRoundStorageReportModel;
  private quoteFlatStorageReportModel: QuoteFlatStorageReportModel;
  private quoteStorageIds: number[];
  private quoteNumbers: string[];

  _session: AqsSessionTypeEnum;
  _sessionTypeEnum = AqsSessionTypeEnum;

  _isHidden = true;
  _formatReport: { label: string, value: number }[] = [];
  _selectedFormat: ReportFormatEnum;
  _isReportRun = false;
  _date: Date;

  constructor(
    private _aqsReportService: AqsReportDatasourceService) { }

  ngOnInit() {
    this._date = new Date();
  }

  async download() {
    this._isReportRun = true;

    let result: any;

    switch (this._session) {
      case AqsSessionTypeEnum.ComputationSheetType:
        this.reportComputationSheetModel.reportFormat = this._selectedFormat;
        this.reportComputationSheetModel.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.reportComputationSheetModel.printDate = this._date;
        result = await this._aqsReportService.downloadReportComputationSheetFile(this.reportComputationSheetModel);
        break;
      case AqsSessionTypeEnum.RoundSessionType:
        this.quoteRoundStorageReportModel.reportFormat = this._selectedFormat;
        this.quoteRoundStorageReportModel.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.quoteRoundStorageReportModel.printDate = this._date;
        result = await this._aqsReportService.downloadReportQuoteRoundStorageFile(this.quoteRoundStorageReportModel);
        break;
      case AqsSessionTypeEnum.FlatSessionType:
        this.quoteFlatStorageReportModel.reportFormat = this._selectedFormat;
        this.quoteFlatStorageReportModel.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.quoteFlatStorageReportModel.printDate = this._date;
        result = await this._aqsReportService.downloadReportQuoteFlatStorageFile(this.quoteFlatStorageReportModel);
        break;
      default:
        let format = this._selectedFormat;
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        result = await this._aqsReportService.downloadReportListOfQuoteFile(
          this.quoteStorageIds,
          format,
          timeZone,
          this._date);
        break;
    }

    if (!(result instanceof ErrorModel)) {
      let fileName = this.generateFileName();
      let url = URL.createObjectURL(result);
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      this.onCancel();
      this._isReportRun = false;
      this.exportCompleted.emit(true);
    }
    else {
      this._isReportRun = false
    }
  }

  public showDownloadComputationSheetReport(reportComputationSheetModel: ReportComputationSheetModel) {
    this.initFormatLabels();
    this.reportComputationSheetModel = reportComputationSheetModel;
    this._session = AqsSessionTypeEnum.ComputationSheetType;
    this._isHidden = false;
  }

  public showDownloadQuoteRoundStorageReport(quoteStorage: QuoteRoundStorageReportModel) {
    this.initFormatLabels();
    this.quoteRoundStorageReportModel = quoteStorage;
    this._session = AqsSessionTypeEnum.RoundSessionType;
    this._isHidden = false;
  }

  public showDownloadQuoteFlatStorageReport(quoteStorage: QuoteFlatStorageReportModel) {
    this.initFormatLabels();
    this.quoteFlatStorageReportModel = quoteStorage;
    this._session = AqsSessionTypeEnum.FlatSessionType;
    this._isHidden = false;
  }

  public showDownloadListOfQuoteReport(quoteStorageIds: number[], quoteNumbers: string[]) {
    this.initFormatLabels();
    this.quoteStorageIds = quoteStorageIds;
    this.quoteNumbers = quoteNumbers;
    this._isHidden = false;
  }

  onCancel() {
    this._date = new Date();
    this._isHidden = true;
  }

  private generateFileName(): string {
    let name: string = 'Quote_';

    switch (this._session) {
      case AqsSessionTypeEnum.ComputationSheetType:
        name += this.reportComputationSheetModel.customerStorageLocation.quoteNumber;
        break;
      case AqsSessionTypeEnum.RoundSessionType:
        name += this.quoteRoundStorageReportModel.coverSheet.quoteNumber;
        break;
      case AqsSessionTypeEnum.FlatSessionType:
        name += this.quoteFlatStorageReportModel.coverSheet.quoteNumber;
        break;
      default:
        let quoteNumbers = [... new Set(this.quoteNumbers)];
        if (quoteNumbers.length == 1) {
          name += quoteNumbers[0];
        } else {
          name = 'Quotes';
          for (let i = 0; i < quoteNumbers.length; i++) {
            name += `_${quoteNumbers[i]}`;
          }
        }
        break;
    }

    return `${name.replace(/\s+/g, '')}-${new Date().toLocaleDateString()}.${AqsReportFormatFileLabel[this._selectedFormat]}`;
  }

  private initFormatLabels() {
    this._formatReport = [];

    for (const [key, value] of Object.entries(AqsReportFormatFileLabel)) {

      let label = value as string;

      this._formatReport.push({ label: label, value: Number(key) })
    }

    this._selectedFormat = this._formatReport[0].value;
  }
}
