import { Component, OnInit, Input, Output, ViewChild, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AqsConfirmPopupComponent } from '@popups';
import { FlatStorageParams } from '@models';
import { FlatStorageConst } from '../../../constants';
import { AQSFlatStorageTypesEnum } from '../../../enums';
import { AqsFlatViewTabComponent } from './aqs-flat-view-tab';

@Component({
  selector: 'greensleeves-aqs-flat-storage-params',
  templateUrl: './aqs-flat-storage-params.component.html',
  styleUrls: []
})

export class AqsFlatStorageParamsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() _storageParams: FlatStorageParams;

  @Output() onChangedFlatConfiguration = new EventEmitter();

  @ViewChild(AqsConfirmPopupComponent, { read: false, static: false })
  private confirmModal: AqsConfirmPopupComponent;

  @ViewChild(AqsFlatViewTabComponent, { read: false, static: false })
  private _drawFlatStorage: AqsFlatViewTabComponent;

  _flatStorageTypes: {label: string, value: AQSFlatStorageTypesEnum} [] = [];
  _flatStorageForm: FormGroup;
  private confirmSubscription: Subscription;
  private oldCalculatedStorageParams: FlatStorageParams;

  //parameters
  _storageType: AQSFlatStorageTypesEnum;
  _width: number;
  _lenght: number;
  _height: number;
  _effectiveDepth: number;
  _buCapacityPile: number;
  _repose?: number;
  _rise?: number;
  _qAllHeight?: number
  _offsetLenght: number;
  _offsetWidth: number;
  _roof: number;


  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit() {
    for (const [key, value] of Object.entries(FlatStorageConst)) {
      this._flatStorageTypes.push({ label: value, value: Number(key)});
    }

    this._storageType = this._storageParams.storageType;
    this._width = this._getRoundToOneDecimalPlaces(this._storageParams.width);
    this._lenght = this._getRoundToOneDecimalPlaces(this._storageParams.lenght);
    this._height = this._getRoundToOneDecimalPlaces(this._storageParams.height);
    this._effectiveDepth = this._getRoundToOneDecimalPlaces(this._storageParams.effectiveDepth);
    this._buCapacityPile = this._getRoundToOneDecimalPlaces(this._storageParams.buCapacityPile);
    this._repose = this._getRoundToOneDecimalPlaces(this._storageParams.repose);
    this._rise = this._getRoundToOneDecimalPlaces(this._storageParams.rise);
    this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight);
    this._offsetLenght = this._getRoundToOneDecimalPlaces(this._storageParams.offsetLenght);
    this._offsetWidth = this._getRoundToOneDecimalPlaces(this._storageParams.offsetWidth);
    this._roof = this._getRoundToOneDecimalPlaces(this._storageParams.roof);

    this.oldCalcualtionsOnInit();
    this.initFrom();
  }

  oldCalcualtionsOnInit() {
    this.oldCalculatedStorageParams = new FlatStorageParams();
    this.oldCalculatedStorageParams.width = this._storageParams.width;
    this.oldCalculatedStorageParams.lenght = this._storageParams.lenght;
    this.oldCalculatedStorageParams.height = this._storageParams.height;
    this.oldCalculatedStorageParams.offsetLenght = this._storageParams.offsetLenght;
    this.oldCalculatedStorageParams.offsetWidth = this._storageParams.offsetWidth;
    this.oldCalculatedStorageParams.roof = this._storageParams.roof;

    this.oldCalculatedStorageParams.getCalculateCapacity();
  }

  ngAfterViewInit() {
    this.confirmSubscription = this.confirmModal.confirm.subscribe(confirm => {
      if (!confirm) {
        this._buCapacityPile = this._getRoundToOneDecimalPlaces(this._storageParams.buCapacityPile);
        this._effectiveDepth = this._getRoundToOneDecimalPlaces(this._storageParams.effectiveDepth);
      } else {
        this._storageParams.buCapacityPile = this._buCapacityPile;
        this._storageParams.effectiveDepth = this._effectiveDepth;
      }
    });
  }

  ngOnDestroy() {
    this.confirmSubscription.unsubscribe();
  }

  onChangeStorageType() {
    if (this._storageType != this._storageParams.storageType){
      this._storageParams.storageType = this._storageType;
    }

    //make changes on parameters when storage type changes
    this._storageParams.onChangeStorageType();
    this._buCapacityPile = this._getRoundToOneDecimalPlaces(this._storageParams.buCapacityPile);
    this._effectiveDepth = this._getRoundToOneDecimalPlaces(this._storageParams.effectiveDepth);
    this.oldCalculatedStorageParams.buCapacityPile = this._storageParams.buCapacityPile;
    this.oldCalculatedStorageParams.effectiveDepth = this._storageParams.effectiveDepth;
    this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight);
    this._repose =  this._getRoundToOneDecimalPlaces(this._storageParams.repose);
    this._rise = this._getRoundToOneDecimalPlaces(this._storageParams.rise);
    this._width =this._getRoundToOneDecimalPlaces(this._storageParams.width);
    this._lenght =this._getRoundToOneDecimalPlaces(this._storageParams.lenght);
    this._height =this._getRoundToOneDecimalPlaces(this._storageParams.height);

    this.drawStorage()
    this.changeFlatConfiguration();
  }

  getFlatStorageType(value: AQSFlatStorageTypesEnum): string {
    return this._flatStorageTypes.find(x => x.value === value).label;
  }

  onChangeWidth() {
    if (this._width == null || this._width <= 1){
      this._width = this._getRoundToOneDecimalPlaces(this._storageParams.width);
    }
    else if (this._width != this._storageParams.width) {
      if (this._width > 999999) {
        this._width = 999999;
      }

      this._storageParams.width = this._width;
      this._storageParams.onChangeWidth();

      if (this._storageType !== AQSFlatStorageTypesEnum.Flat) {
        this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight);
      }

      this.getCalculateCapacity();
      this.drawStorage();
      this.changeFlatConfiguration();
    }
  }

  onChangeLenght() {
    if (this._lenght == null || this._lenght <= 1){
      this._lenght = this._getRoundToOneDecimalPlaces(this._storageParams.lenght);
    }
    else if (this._storageParams.lenght != this._lenght) {
      if (this._lenght > 999999) {
        this._lenght = 999999;
      }

      this._storageParams.lenght = this._lenght;
      this._storageParams.onChangeLenght();

      if (this._storageType !== AQSFlatStorageTypesEnum.Flat) {
        this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight);
      }

      this.getCalculateCapacity();
      this.drawStorage();
      this.changeFlatConfiguration();
    }
  }

  onChangeHeight() {
    if (this._height == null || this._height <= 1){
      this._height = this._getRoundToOneDecimalPlaces(this._storageParams.height);
    }
    else if (this._height != this._storageParams.height) {
      if (this._height > 999999) {
        this._height = 999999;
      }

      //set all height param consider on height change
      if (this._height >= this._storageParams.height){
        this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight + this._height - (+this._storageParams.height));
      }
      else {
        this._qAllHeight -= this._getRoundToOneDecimalPlaces(this._storageParams.height - this._height);
      }

      //capacity will changes and redraw in QAllHeight
      this._storageParams.height = this._height;
      this.onChangeQAllHeight();
      this.drawStorage();
      this.changeFlatConfiguration();
    }
  }

  onChangeBuCapacity() {
    if (this._buCapacityPile == null || this._buCapacityPile <= 1){
      this._buCapacityPile = this._getRoundToOneDecimalPlaces(this._storageParams.buCapacityPile);
    }
    else if (this._buCapacityPile != this._getRoundToOneDecimalPlaces(this._storageParams.buCapacityPile)) {
      //popup
      this.confirmModal.showForConfirmCapacityAndEffDepth(
        this._effectiveDepth,
        this.oldCalculatedStorageParams.effectiveDepth,
        this._buCapacityPile,
        this.oldCalculatedStorageParams.buCapacityPile
      );
      this.changeFlatConfiguration();
    }
  }

  onChangeEffectiveDepth() {
    if (this._effectiveDepth == null || this._effectiveDepth <= 1){
      this._effectiveDepth = this._getRoundToOneDecimalPlaces(this._storageParams.effectiveDepth);
    }
    else if (this._effectiveDepth != this._getRoundToOneDecimalPlaces(this._storageParams.effectiveDepth)) {
      //popup
      this.confirmModal.showForConfirmCapacityAndEffDepth(
        this._effectiveDepth,
        this.oldCalculatedStorageParams.effectiveDepth,
        this._buCapacityPile,
        this.oldCalculatedStorageParams.buCapacityPile
      );
      this.changeFlatConfiguration();
    }
  }

  onChangeRise() {
    if (this._rise == null || this._rise <= 1 || this._rise > 67){
      this._rise = this._getRoundToOneDecimalPlaces(this._storageParams.rise);
    }
    else if (this._rise != this._storageParams.rise) {
      this._storageParams.rise = this._rise;
      this._storageParams.onChangeRise();
      this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight);
      this._repose = this._getRoundToOneDecimalPlaces(this._storageParams.repose);
      this.getCalculateCapacity();
      this.drawStorage();
      this.changeFlatConfiguration();
    }
  }

  onChangeRepose() {
    if (this._repose == null || this._repose <= 1 || this._repose > 79){
      this._repose = this._getRoundToOneDecimalPlaces(this._storageParams.repose)
    }
    else if (this._storageParams.repose != this._repose) {
      this._storageParams.repose = this._repose;
      this._storageParams.onChangeRepose();
      this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight);
      this._rise = this._getRoundToOneDecimalPlaces(this._storageParams.rise);
      this.getCalculateCapacity();
      this.drawStorage();
      this.changeFlatConfiguration();
    }
  }

  onChangeQAllHeight() {
    if (this._qAllHeight == null || this._qAllHeight <= this._height){
      this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight);
    }
    else if (this._qAllHeight != this._storageParams.qAllHeight) {
      this._storageParams.qAllHeight = this._getRoundToOneDecimalPlaces(this._qAllHeight);
      this._storageParams.onChangeQAllHeight();
      this._rise = this._getRoundToOneDecimalPlaces(this._storageParams.rise);
      this._repose = this._getRoundToOneDecimalPlaces(this._storageParams.repose);
      this.getCalculateCapacity();
      this.drawStorage();
      this.changeFlatConfiguration();
    }
  }

  getCalculateCapacity() {
    this._storageParams.getCalculateCapacity();
    this._buCapacityPile = this._getRoundToOneDecimalPlaces(this._storageParams.buCapacityPile);
    this._effectiveDepth = this._getRoundToOneDecimalPlaces(this._storageParams.effectiveDepth);
  }

  getCalculateRoofParams() {
    this._storageParams.getCalculateRoofParams();
    this._qAllHeight = this._getRoundToOneDecimalPlaces(this._storageParams.qAllHeight);
    this._repose = this._getRoundToOneDecimalPlaces(this._storageParams.repose);
    this._rise = this._getRoundToOneDecimalPlaces(this._storageParams.rise);
  }

  private initFrom() {
    this._flatStorageForm = this._formBuilder.group({
      width: new FormControl(100, Validators.compose([
        Validators.required,
        Validators.max(99999),
      ])),
      length: new FormControl(120, Validators.compose([
        Validators.required,
        Validators.max(99999),
      ])),
      height: new FormControl(10, Validators.compose([
        Validators.required,
        Validators.max(99999),
      ])),
    });
  }

  drawStorage() {
    this._drawFlatStorage.clearView();
    this._drawFlatStorage.drawStorage();
  }

  private _getRoundToOneDecimalPlaces(number: number): number {
    return Math.round((number + Number.EPSILON) * 10) / 10;
  }
  private _getRoundToZeroDecimalPlaces(number: number): number {
    return Math.round((number + Number.EPSILON));
  }

  changeFlatConfiguration() {
    this.onChangedFlatConfiguration.emit();
  }
}

