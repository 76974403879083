import { Injectable } from '@angular/core';

import { ErrorModel, HttpService } from '../../../common';
import { ApiRoutes } from '../../constants';
import { QuoteFlatStorageReportModel, QuoteRoundStorageReportModel, ReportComputationSheetModel } from '@models';
import { ReportFormatEnum } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class AqsReportApiService {

  constructor(private _httpService: HttpService) { }

  public async downloadReportComputationSheetFile(model: ReportComputationSheetModel): Promise<string | ErrorModel> {
    try {
      let result = await this._httpService.postArrayBuffer(ApiRoutes.AqsReport.getReportComputationSheet, model);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getComputationSheetReportInBlob(model: ReportComputationSheetModel): Promise<string | ErrorModel> {
    try {
      let result = await this._httpService.post(ApiRoutes.AqsReport.getReportComputationSheetToSend, model)
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadReportQuoteRoundStorageFile(model: QuoteRoundStorageReportModel): Promise<string | ErrorModel> {
    try {
      let result = await this._httpService.postArrayBuffer(ApiRoutes.AqsReport.getReportQuoteRound, model);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getQuoteRoundStorageReportInBlob(model: QuoteRoundStorageReportModel): Promise<string | ErrorModel> {
    try {
      let result = await this._httpService.post(ApiRoutes.AqsReport.getReportRoundStorageToSend, model)
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadReportQuoteFlatStorageFile(model: QuoteFlatStorageReportModel): Promise<string | ErrorModel> {
    try {
      let result = await this._httpService.postArrayBuffer(ApiRoutes.AqsReport.getReportQuoteFlat, model);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getQuoteFlatStorageReportInBlob(model: QuoteFlatStorageReportModel): Promise<string | ErrorModel> {
    try {
      let result = await this._httpService.post(ApiRoutes.AqsReport.getReportFlatStorageToSend, model)
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadReportListOfQuoteFile(
    quoteStoragesIds: number[],
    reportFormat: ReportFormatEnum,
    timeZone: string,
    printDate: Date): Promise<string | ErrorModel> {
    try {
      let result = await this._httpService.postArrayBuffer(
        ApiRoutes.AqsReport.getReportListOfQuote,
        {
          quoteStoragesIds: quoteStoragesIds,
          reportFormat: reportFormat,
          timeZone: timeZone,
          printDate: printDate
        });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getListOFQuoteReportInBlob(
    quoteStoragesIds: number[],
    reportFormat: ReportFormatEnum,
    timeZone: string,
    printDate: Date): Promise<string | ErrorModel> {
    try {
      let result = await this._httpService.post(
        ApiRoutes.AqsReport.getReportListOfQuoteToSend,
        {
          quoteStoragesIds: quoteStoragesIds,
          reportFormat: reportFormat,
          timeZone: timeZone,
          printDate: printDate
        });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }
    console.log(error);
    return null;
  }
}
