import { Component, ElementRef, NgZone, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import PerfectScrollbar from 'perfect-scrollbar';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng';

import { AqsSavedQuoteModel } from '@models';
import { AqsSessionTypeEnum, AqsStorageTypeEnum, AqsStorageTypeStringEnum, ColumnTypeEnum } from '../../enums';
import { AqsQuoteStorageDataSourceService } from '@services';
import { AqsSessionsTypeContants, AqsStorageTypesTreeNodeConstants, MainRoutePaths } from '../../constants';
import { AqsComputationSheetConfirmPopupComponent, AqsNewQuotePopupComponent, ComputationSheetReportPopupComponent, ComputationSheetShareReportPopupComponent } from '@popups';

@Component({
  selector: 'greensleeves-aqs-quotes-grid',
  templateUrl: './aqs-quotes.component.html',
  styleUrls: []
})

export class AqsQuotesTabComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AqsNewQuotePopupComponent, { read: false, static: false })
  _newQuotePopup: AqsNewQuotePopupComponent;

  @ViewChild(ComputationSheetReportPopupComponent, { read: false, static: false })
  private _exportReportModal: ComputationSheetReportPopupComponent;

  @ViewChild(AqsComputationSheetConfirmPopupComponent, { read: false, static: false })
  private _deleteModal: AqsComputationSheetConfirmPopupComponent;

  @ViewChild(ComputationSheetShareReportPopupComponent, { read: false, static: false })
  private _shareReportModal: ComputationSheetShareReportPopupComponent;

  static QUOTE_NUMBER_KEY = 'quoteNumber';
  static CUSTOMER_NAME_KEY = 'customerName';
  static COMPANY_NAME_KEY = 'customerName';
  static CREATING_DATE_KEY = 'createdDate';
  static SORT_ORDER_DEFAULT = -1;

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = AqsQuotesTabComponent.CREATING_DATE_KEY;
  _sortOrder = AqsQuotesTabComponent.SORT_ORDER_DEFAULT;
  _gridData: AqsSavedQuoteModel[];
  _savedData: AqsSavedQuoteModel[] = [];
  _companyNameKey = AqsQuotesTabComponent.COMPANY_NAME_KEY;
  _quoteNumberKey = AqsQuotesTabComponent.QUOTE_NUMBER_KEY;
  _customerNameKey = AqsQuotesTabComponent.CUSTOMER_NAME_KEY;
  _searchColumns = [this._companyNameKey, this._quoteNumberKey, this._customerNameKey];
  _searchPhrase: string = '';
  _initializeDeviceKey = 'isInitialized';
  _storageTypes: TreeNode[] = [];
  _nodeSelected: TreeNode[];
  _filterCounter: number;
  _isOpenFilterList: boolean = false;
  _storageTypesToFilter: AqsStorageTypeEnum[] = [];
  _sessionsImages = AqsSessionsTypeContants;
  _storageTypesNode = AqsStorageTypesTreeNodeConstants;
  _isSelectedAny: boolean = false;
  _selectedSavedQuotes: AqsSavedQuoteModel[] = [];

  _columnsView = [
    { header: 'Selected', columnType: ColumnTypeEnum.QuoteSelected, dataField: 'isSelected' },
    { header: 'Quote number', columnType: ColumnTypeEnum.QuoteText, dataField: AqsQuotesTabComponent.QUOTE_NUMBER_KEY },
    { header: 'Creation date', columnType: ColumnTypeEnum.Date, dataField: AqsQuotesTabComponent.CREATING_DATE_KEY },
    { header: 'Session type', columnType: ColumnTypeEnum.QuoteSessionType, dataField: 'sessionType' },
    { header: 'Storage type', columnType: ColumnTypeEnum.QuoteStorageType, dataField: 'storageType' },
    { header: 'Customer name', columnType: ColumnTypeEnum.CustomerName, dataField: 'customerName' },
    { header: 'Company name', columnType: ColumnTypeEnum.CompanyName, dataField: 'companyName' },
    { header: '', columnType: ColumnTypeEnum.Edit, width: 50 },
    { header: '', columnType: ColumnTypeEnum.Delete, width: 50 },
  ];

  private subscription: Subscription[] = [];
  _searchForm: FormGroup;
  _lastSearchPhrase: string;

  _initLoaders: { [key: number]: boolean } = {};

  private titleDeletePopup = "Are you sure?";
  private subtitleDeletePopup = "Do you really want to delete this quote?";

  constructor(
    private _quotesDataSourceService: AqsQuoteStorageDataSourceService,
    formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }

  ngOnInit() {
    let sub = this._quotesDataSourceService.quotes$.subscribe(items => {
      this._gridData = items;
    })
    this._quotesDataSourceService.getAllSavedQuotes();
    this.fillStorageTypesNodes();
    this.subscription.push(sub);
  }

  ngAfterViewInit() {
    let exportCompleted = this._exportReportModal.exportCompleted.subscribe(item => {
      if (item) {
        this.unsectedQuotes();
      }
    })

    let shareCompleted = this._shareReportModal.shareCompleted.subscribe(item => {
      if (item) {
        this.unsectedQuotes();
      }
    })
    this.subscription.push(exportCompleted);
    this.subscription.push(shareCompleted);
  }

  ngOnDestroy() {
    this.subscription && this.subscription.forEach(subs => subs.unsubscribe());
  }

  createNewQuote() {
    this._newQuotePopup.showConfirmPopup();
  }

  async onEdit(quote: AqsSavedQuoteModel) {
    if (quote.sessionType == AqsSessionTypeEnum.FlatSessionType) {
      this._router.navigate([`../${MainRoutePaths.AQS_FLAT_STORAGE}`], { queryParams: { 'id': quote.quoteId }, relativeTo: this._route });
    }
    else if (quote.sessionType == AqsSessionTypeEnum.RoundSessionType) {
      this._router.navigate([`../${MainRoutePaths.AQS_ROUND_STORAGE}`], { queryParams: { 'id': quote.quoteId }, relativeTo: this._route });
    }
    else this._router.navigate([`../${MainRoutePaths.AQS_COMPUTATION_SHEET}`], { queryParams: { 'id': quote.quoteId }, relativeTo: this._route });
  }

  async onDelete(quote: AqsSavedQuoteModel) {
    this._deleteModal.showConfirmPopup(this.titleDeletePopup, this.subtitleDeletePopup, quote.quoteId);
  }

  async deleteQuote(quoteId: number) {
    if (quoteId) {
      const result = await this._quotesDataSourceService.deleteQuoteStorage(quoteId);
      if (result == true) {
        if (this._savedData.length >= this._gridData.length) {
          this._savedData = this._savedData.filter(q => q.quoteId != quoteId);
        }

        this._gridData = this._gridData.filter(q => q.quoteId != quoteId);
      }
      else {
        console.log(result);
      }
    }
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
    if (this._savedData.length < this._gridData.length) this._savedData = this._gridData;
    else this._gridData = this._savedData;

    this._gridData = this._gridData.filter(quote =>
      quote.companyName.includes(this._lastSearchPhrase) ||
      quote.quoteNumber.includes(this._lastSearchPhrase) ||
      quote.customerName.includes(this._lastSearchPhrase)
    );
    if (this._storageTypesToFilter.length > 0) {
      this._gridData = this._gridData.filter(quote =>
        this._storageTypesToFilter.includes(quote.storageType)
      );
    }
  }

  convertStorageTypeToStringValue(storageType: AqsStorageTypeEnum): AqsStorageTypeStringEnum {
    switch (storageType) {
      case AqsStorageTypeEnum.Conical:
        return AqsStorageTypeStringEnum.Conical;
      case AqsStorageTypeEnum.Flat:
        return AqsStorageTypeStringEnum.Flat;
      case AqsStorageTypeEnum.Peaked:
        return AqsStorageTypeStringEnum.Peaked;
      case AqsStorageTypeEnum.SideDraw:
        return AqsStorageTypeStringEnum.SideDraw;
      case AqsStorageTypeEnum.SlopedPeak:
        return AqsStorageTypeStringEnum.SlopedPeak;
      case AqsStorageTypeEnum.TruncatedPeak:
        return AqsStorageTypeStringEnum.TruncatedPeak;
      default:
        return AqsStorageTypeStringEnum.None;
    }
  }

  private fillStorageTypesNodes() {
    this._storageTypes.push(this._storageTypesNode[AqsSessionTypeEnum.RoundSessionType]);
    this._storageTypes.push(this._storageTypesNode[AqsSessionTypeEnum.FlatSessionType]);
    this._storageTypes.push(this._storageTypesNode[AqsSessionTypeEnum.ComputationSheetType]);
  }

  openFilterList() {
    this._isOpenFilterList = !this._isOpenFilterList;
  }

  onClickStorageTypeFilter() {
    this._filterCounter = 0;
    this._storageTypesToFilter = [];
    this._nodeSelected.forEach(i => {
      if (!i.children) {
        this._storageTypesToFilter.push(i.data);
      }
    });
    this._filterCounter = this._storageTypesToFilter.length;
    this.onUpdateLastSearch();
  }

  onChangeSelectedQuotes(quote: AqsSavedQuoteModel) {
    if (this._selectedSavedQuotes.includes(quote)) {
      this._selectedSavedQuotes.forEach((q, index) => {
        if (q.quoteId == quote.quoteId) this._selectedSavedQuotes.splice(index, 1);
      })
    } else {
      this._selectedSavedQuotes.push(quote);
    }

    this._isSelectedAny = this._selectedSavedQuotes.length > 0;
  }

  onClickExportReport() {
    this._exportReportModal.showDownloadListOfQuoteReport(
      this._selectedSavedQuotes.map(x => x.quoteId),
      this._selectedSavedQuotes.map(x => x.quoteNumber));
  }

  onClickShareReport() {
    this._shareReportModal.showDownloadListOfQuoteReport(
      this._selectedSavedQuotes.map(x => x.quoteId),
      this._selectedSavedQuotes.map(x => x.quoteNumber));
  }

  onRedirectNewQuote(sessionType: AqsSessionTypeEnum) {
    if (sessionType == AqsSessionTypeEnum.RoundSessionType) this._router.navigate([`../${MainRoutePaths.AQS_ROUND_STORAGE}`], { relativeTo: this._route });
    else if (sessionType == AqsSessionTypeEnum.FlatSessionType) this._router.navigate([`../${MainRoutePaths.AQS_FLAT_STORAGE}`], { relativeTo: this._route });
    else this._router.navigate([`../${MainRoutePaths.AQS_COMPUTATION_SHEET}`], { relativeTo: this._route });
  }

  private unsectedQuotes() {
    this._gridData.forEach(element => {
      element.isSelected = false;
    });
    this._selectedSavedQuotes = [];
    this._isSelectedAny = false;
  }
}
