import { Component, EventEmitter, OnInit, Output } from '@angular/core';


import { BasePopupBehavior } from '../../common';

@Component({
  selector: 'greensleeves-upload-configuration-close-confirmation-popup',
  templateUrl: './upload-configuration-close-confirmation-popup.component.html'
})
export class UploadConfigurationCloseConfirmationPopupComponent extends BasePopupBehavior implements OnInit {
  _isHidden = true;

  @Output('closeConfirmation')
  closeConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { 
    super();
  }
  ngOnInit() {
  }

  onCancel() {
    this._isHidden = true;
    this.closeConfirmation.emit(false);
  }

  async onSubmit() {
    this._isHidden = true;
    this.closeConfirmation.emit(true);
  }

  public show() {
    this._isHidden = false;
  }
}
