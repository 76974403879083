import { Component, OnInit, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'greensleeves-success-operation-popup',
  templateUrl: './success-operation-popup.component.html',
  styles: []
})
export class SuccessOperationPopupComponent implements OnInit {
  @Input()
  primaryButtonText: string

  _isHidden = true;
  _infoText: string;

  public onPrimaryClicked = new EventEmitter<number>();

  private entityId: number;

  constructor(
  ) { }

  ngOnInit() {
  }

  public show(infoText: string, entityId: number = null) {
    this._infoText = infoText;
    this.entityId = entityId;
    this._isHidden = false;
  }

  onClickCancel() {
    this._isHidden = true;
  }

  onClickPrimary() {
    this._isHidden = true;
    this.onPrimaryClicked.emit(this.entityId);
  }
}
