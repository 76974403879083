import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserStatusEnum } from '../../enums';
import { SalesUserGetModel, SalesUserViewModel, SalesUserPostModel, ServiceUserInviteModel } from '../../models';
import { ApiRoutes, ErrorModel, HttpService } from './../../../common';

@Injectable({
  providedIn: 'root'
})
export class SalesUsersApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router) { }

  public async loadAll(): Promise<SalesUserViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const data = await this._httpService.get(ApiRoutes.Account.getAllSalesUser) as SalesUserGetModel[];
      if (data) {
        const viewModels = data.map(user => SalesUserGetModel.toViewModel(user));
        return viewModels;
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async edit(salesUser: SalesUserPostModel): Promise<SalesUserViewModel | ErrorModel> {
    try {
      const getModel = await this._httpService.post(ApiRoutes.Account.editSalesUser, salesUser) as SalesUserViewModel;
      if (getModel != null) {
        const viewModel = SalesUserGetModel.toViewModel(getModel);
        return Promise.resolve(viewModel);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async invite(salesUser: ServiceUserInviteModel): Promise<SalesUserViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.addSalesUser, salesUser) as SalesUserGetModel;
      const viewModel = SalesUserGetModel.toViewModel(result);
      return Promise.resolve(viewModel);
    } catch (error) {
      return this.processError(error);
    }
  }

  
  public async delete(serviceUserId: number) : Promise<number | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.deleteUser, { id: serviceUserId })
      if (result){
      return Promise.resolve(serviceUserId);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  private getFakeData(): SalesUserViewModel[] {
    const data = [
      { id: 1, firstName: 'firstName', lastName: 'lastName', email: 'email', lastLoginDate: new Date, status: UserStatusEnum.Unlocked },
      { id: 2, firstName: 'firstName2', lastName: 'lastName2', email: 'email2', lastLoginDate: new Date, status: UserStatusEnum.Locked }
    ] as SalesUserViewModel[];
    return data;
  }
}

