export enum ColumnTypeEnum {
    LocationName,
    IPAddress,
    Port,
    Company,
    ServiceEmail,
    ServicePhone,
    EmergencyPhone,
    SitePhone,
    SiteEmail,
    Equipment,
    DownloadConnectionFile,
    InitializeDevice,
    Edit,
    EditKTX,
    EditBTX,
    Add,
    Delete,
    Date,
    Status,
    UserFirstName,
    UserLastName,
    UserLocationNames,
    LocationAddress,
    Project,
    AcquisitionRate,
    P1AMMac,
    P1AMIp,
    Bins,
    BinName,
    Height,
    Depth,
    Width,
    Type,
    Details,
    Cable,
    Data,
    Time,
    TC,
    CableName,
    CableID,
    Angle,
    Radius,
    SensorsAmount,
    MUXOrDevice,
    Number,
    From,
    To,
    Color,
    TemperatureRanges,
    UploadConfigFile,
    binWithinUnitName,
    StorageIcon,
    StorageName,
    StorageType,
    Capacity,
    CapacityUnit,
    StoredMaterial,
    NumberOfRadars,
    TiltAngle,
    Radar,
    AlertTitle,
    AlertBody,
    AlertIcon,
    Hardware,
    RadarLocation,
    RadarDistance,
    HeadSpace,
    Counter,
    ComodityName,
    StaticPressure,
    TargetAitflow,
    Fans,
    QuoteSelected,
    QuoteText,
    QuoteSessionType,
    QuoteStorageType,
    CustomerName,
    CompanyName,
    PileShape,
    FileName,
    Sv,
    Csv,
    SensorIndex,
    SensorPosition,
    SensorSide,
    SensorValue,
    Schedule,
    Expand,
    Updaters,
    UpdaterName,
    CurrentVersion,
    Update,
    History,
}
