import { Component, OnInit, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api/treenode';
import { Calendar } from 'primeng/calendar';

import { ErrorModel } from '../../../../common';
import { BtxEventTypesLabel, CalendarDefaultValues, EnglishDateLocale, ItxEventTypesLabel, KtxEventTypesLabel } from './../../../constants';
import { BtxNotificationFilterNodeTypeEnum, ItxNodeTypesEnum, KtxNotificationFilterNodeTypeEnum, ProjectEnum } from './../../../enums';
import { NotificationReportPostModel } from './../../../models';
import { ReportDatasourceService } from './../../../services';

@Component({
  selector: 'greensleeves-export-notification-popup',
  templateUrl: './export-notification-popup.component.html',
  styles: []
})
export class ExportNotificationPopupComponent implements OnInit {
  static SELECT_ALL = 'Select All';
  @ViewChild('calendar', { static: false })
  private calendar: Calendar;

  _isHidden = true;
  _dataLocale: EnglishDateLocale = new EnglishDateLocale();
  _alertTypeOptions: { label: string, value: number }[] = [];
  _locationNodes: TreeNode[];
  _locationNodesSelected: TreeNode[];
  _loading = false;
  _dateRange: Date[];
  _eventTypes: number[];
  private project: ProjectEnum;

  get getDataPickterYearRange(): string {
    const dateNow = new Date();
    return `${dateNow.getFullYear() - 3}:${dateNow.getFullYear()}`;
  }

  get popupHeader(): string {
    return this.project==ProjectEnum.Itx?'Export alerts':'Export notifications';
  }

  constructor(
    private _reportDataSource: ReportDatasourceService,
  ) { }

  ngOnInit() {
  }

  onShow(locationNodes: TreeNode[], project: ProjectEnum) {
    this.project = project;
    this._locationNodesSelected = [];
    this.initialNode(locationNodes);
    this.initValues();
    this._isHidden = false;
  }

  onClose() {
    this._isHidden = true;
  }

  onApplyDateRangeFilter() {
    this.checkCalendar();
    this.calendar.toggle();
  }

  onCloseCalendar() {
    this.checkCalendar();
  }

  private checkCalendar(): boolean {
    if (this._dateRange == undefined || this._dateRange.length == 0) {
      this.resetCalendar();
      return false;
    }
    if (this._dateRange[1] == undefined || this._dateRange[1] == null) {
      let x = this._dateRange.map(x => x);
      x[1] = this._dateRange[0];
      this._dateRange = x.map(x => x);
    }
    return true
  }

  async onExport() {
    if (!this.checkCalendar()) return;
    if (!this._locationNodesSelected || this._locationNodesSelected.length === 0) return;
    this._loading = true;
    let locationIds: number[] = [];
    let equipmentIds: number[] = [];
    this._locationNodesSelected.forEach(i => {
      switch (this.project) {
        case ProjectEnum.Btx:
          switch (i.data.nodeType) {
            case BtxNotificationFilterNodeTypeEnum.Equipment:
              equipmentIds.push(i.data.id);
              if (i.data && i.data.locationId) locationIds.push(i.data.locationId);
              break;
          }
          break;
        case ProjectEnum.Ktx:
          switch (i.data.nodeType) {
            case KtxNotificationFilterNodeTypeEnum.Bin:
              equipmentIds.push(i.data.id);
              if (i.data && i.data.locationId) locationIds.push(i.data.locationId);
              break;
          }
          break;
        case ProjectEnum.Itx:
          switch (i.data.nodeType) {
            case ItxNodeTypesEnum.Bin:
              equipmentIds.push(i.data.id);
              if (i.data && i.data.locationId) locationIds.push(i.data.locationId);
              break;
          }
          break;
      }
    });

    const request = new NotificationReportPostModel();
    request.locationIds = [...new Set(locationIds)];
    request.equipmentIds = equipmentIds;
    request.eventTypes = this._eventTypes;
    request.dateTimeFromSeconds = this._dateRange[0].getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds;
    request.dateTimeToSeconds = this._dateRange[1].getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds;
    request.project = this.project;
    request.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let result = await this._reportDataSource.downloadNotificationReport(request);
    if (!(result instanceof ErrorModel)) {
      let fileName = `${ProjectEnum[this.project]}NotificationReport_${this._dateRange[0].toLocaleDateString()}-${this._dateRange[1].toLocaleDateString()}.csv`;
      const url = URL.createObjectURL(result as Blob);
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      this.onClose();
    }
    this._loading = false;
  }

  private initValues() {
    this.initAlerTypes();
    this.resetCalendar();
    this._eventTypes = [];
  }

  private resetCalendar() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this._dateRange = [today, today];
  }

  private initAlerTypes() {
    this._alertTypeOptions = [];
    switch (this.project) {
      case ProjectEnum.Btx:
        for (const [key, value] of Object.entries(BtxEventTypesLabel)) {
          this._alertTypeOptions.push({ label: value, value: Number(key) });
        }
        break;
      case ProjectEnum.Ktx:
        for (const [key, value] of Object.entries(KtxEventTypesLabel)) {
          this._alertTypeOptions.push({ label: value, value: Number(key) });
        }
        break;
      case ProjectEnum.Itx:
        for (const [key, value] of Object.entries(ItxEventTypesLabel)) {
          this._alertTypeOptions.push({ label: value, value: Number(key) });
        }
        break;
    }
  }

  private initialNode(locationNodes: TreeNode[]) {
    const { SELECT_ALL } = ExportNotificationPopupComponent;

    const selectAllNodes: TreeNode = {
      label: SELECT_ALL,
      data: SELECT_ALL,
      key: SELECT_ALL,
    };

    this._locationNodes = [];
    this._locationNodes = [selectAllNodes, ...locationNodes];
    this.selectedAllNodes();
  }

  onNodeSelect(event: any) {
    if (this.isSelectAllNode(event.node)) {
      this.selectedAllNodes();
    }
  }

  onNodeUnselect(event: any) {
    if (this.isSelectAllNode(event.node)) {
      this.unselectedAllNodes();
      return;
    }

    this._locationNodesSelected = this._locationNodesSelected.filter(n => !this.isSelectAllNode(n))
  }

  private selectedAllNodes() {
    const parentsNodes: TreeNode[] = [...this._locationNodes];
    let childrenNodes: TreeNode[] = [];

    this._locationNodes.forEach(x => {
      if (x.children && x.children.length > 0) {
        this.collectChildrenNodes(childrenNodes, x.children);
      }
    });

    this._locationNodesSelected = [...parentsNodes, ...childrenNodes];
  }

  private collectChildrenNodes(collector: TreeNode[], childrenNode: TreeNode[]) {
    childrenNode.forEach(x => {
      collector.push(x);
      if (x.children && x.children.length > 0) {
        this.collectChildrenNodes(collector, x.children);
      }
    });
  }


  private unselectedAllNodes() {
    this._locationNodesSelected = [];
  }

  private isSelectAllNode(node: TreeNode) {
    const { label, data, key } = node;
    const { SELECT_ALL } = ExportNotificationPopupComponent;

    return label === SELECT_ALL && data === SELECT_ALL && key === SELECT_ALL;
  }

  private getLocationId(locationIds: number[], node: TreeNode, locationNum: number) {
    let parent = node.parent;
    if (parent && parent.data.nodeType == locationNum) {
      locationIds.push(parent.data.id);
      return;
    } else if (!parent) return;
    this.getLocationId(locationIds, parent, locationNum);
  }
}
