import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';

import {
  AqsAirflowFanTypeViewModel, AqsAirflowFanViewModel, AqsAirflowViewModel,
  AqsCommoditiesViewModel, AqsRoundStorageWiewModel, FlatStorageParams, IncludeFlowOption
} from '@models';
import { AqsAirflowLevelLabel, AqsChartFanTypeNote } from './../../../../constants';
import { AqsAirflowFanTypeEnum, AqsAirflowLevelEnum, AqsFlushDuctDesignEnum, AqsSessionTypeEnum, ColumnTypeEnum } from './../../../../enums';
import { AqsAirflowDatasourceService } from './../../../../services';

@Component({
  selector: 'greensleeves-aqs-target-airflow',
  templateUrl: './aqs-target-airflow.component.html',
  styles: []
})
export class AqsTargetAirflowComponent implements OnInit, OnDestroy {
  @Input() commodities: AqsCommoditiesViewModel[];
  @Input() sessionType: AqsSessionTypeEnum;
  @Input() storageConfiguration: any;
  @Input() airflowConfiguration: AqsAirflowViewModel;
  @Input() includeFlowOption: IncludeFlowOption;
  @Output() onChangedAirflowModels = new EventEmitter();
  @Output() onChangedFlowOptions = new EventEmitter();

  @ViewChild('chart', { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;


  _tandem: number;
  _airflowTableData: { commodityId: number, commodityName: string, staticPressure: number, bupercfm: number, a: number, b: number, cfmperbu: number }[];
  _columnTypeEnum = ColumnTypeEnum;
  _sessionTypes = AqsSessionTypeEnum;
  _fanTypes = AqsAirflowFanTypeEnum;
  _levelOptions: { label: string, value: AqsAirflowLevelEnum }[];
  _fanTypeOptions: { label: string, value: AqsAirflowFanTypeEnum }[];
  _fanOptions: { label: string, value: { fanId: number, fanName: string, modelName: string } }[];
  _isMadeFanPlot = false;
  _isFansReadyGot = false;
  _isShowFlowOptions = false;

  _ductDesign: boolean = false;
  _flushFloorDesign: boolean = false;
  _designEnum = AqsFlushDuctDesignEnum;

  _columnsView = [
    { header: 'Part #', columnType: ColumnTypeEnum.Counter, },
    { header: 'Commodity', columnType: ColumnTypeEnum.ComodityName, dataField: 'commodityName' },
    { header: 'Static pressure (IN.)', columnType: ColumnTypeEnum.StaticPressure, dataField: 'staticPressure' },
    { header: 'Target airflow (CFM/BU)', columnType: ColumnTypeEnum.TargetAitflow, dataField: 'bupercfm' },
    { header: 'FAN', columnType: ColumnTypeEnum.Fans, dataField: 'cfmperbu' },
  ]

  get roundCapacity() {
    return (Math.round(this.capacity * 10) / 10).toLocaleString('en-Us');
  }

  get roundEffDepth() {
    return (Math.round(this.effDepth * 10) / 10).toLocaleString('en-Us');
  }

  private effDepth: number;
  private capacity: number;
  private airflowSubscription: Subscription;
  private fanTypesWithFans: AqsAirflowFanTypeViewModel[];
  private ctx: CanvasRenderingContext2D;
  private chart: Chart;
  private fanCurveColor: string = 'rgba(54, 162, 235)';
  private fanSelectedColor: string = 'rgba(41, 118, 169, 1)';
  private materialCurveColor: string[] = ['rgb(75, 192, 192)', 'rgb(255, 127, 0)', 'rgb(255, 255, 0)', 'rgb(0, 255, 0)', 'rgb(143, 0, 255)', 'rgb(255, 0,255)'];
  private fanCurveBorderWidth: number = 1;
  private fanSelectedBorderWidth: number = 3;
  private materialCurveBorderWidth: number = 2;
  private errorMargin: number = .005;
  private fanDash: number[] = [5, 5];
  private numberOfFans: number;

  constructor(
    private _aqsAirflowService: AqsAirflowDatasourceService,
  ) { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.createEmptyChart();
    this._levelOptions = [];
    for (const [key, value] of Object.entries(AqsAirflowLevelLabel)) {
      this._levelOptions.push({ label: value, value: Number(key) });
    }

    this.airflowSubscription = this._aqsAirflowService.aqsFanTypesWithFans$.subscribe((item) => {
      this._fanTypeOptions = [];
      item.forEach(ft => {
        this._fanTypeOptions.push({ label: ft.name, value: ft.type });
      });
      this.fanTypesWithFans = item;
      this._isFansReadyGot = true;
    });

    if (this.sessionType == AqsSessionTypeEnum.RoundSessionType) {
      this.capacity = (this.storageConfiguration as AqsRoundStorageWiewModel).capacity;
      this.effDepth = (this.storageConfiguration as AqsRoundStorageWiewModel).effDepth;
      this.numberOfFans = 20;
    } else if (this.sessionType == AqsSessionTypeEnum.FlatSessionType) {
      this.capacity = (this.storageConfiguration as FlatStorageParams).buCapacityPile;
      this.effDepth = (this.storageConfiguration as FlatStorageParams).effectiveDepth;
      this.numberOfFans = 1001;
    }
    if (this.airflowConfiguration.airflowData.length == 0) {
      this._tandem = 1;
      this.airflowConfiguration.level = AqsAirflowLevelEnum.Normal;//1
      this.airflowConfiguration.packingFactor = 1.5;
      this.airflowConfiguration.systemStep = 0;
      this.calculateNewAirflowParametersWithRecommendation();
    }
    else {
      this.calculateExistAirflowParameters();
      this.generateChart();
      this.setUpExistIncludeFlowOptions();
    }
  }

  ngOnDestroy() {
    this.airflowSubscription.unsubscribe();
  }

  onChangeBupercfm(control: any, rowData: { commodityId: number, commodityName: string, staticPressure: number, bupercfm: number, a: number, b: number }) {
    control.valueAsNumber = control.valueAsNumber | 0;
    if (control.valueAsNumber >= 1 && control.valueAsNumber <= 30) {
      rowData.bupercfm = control.valueAsNumber;
      let index = this.airflowConfiguration.airflowData.findIndex(d => d.commodityId == rowData.commodityId);
      if (index == -1) {
        this.airflowConfiguration.airflowData.push({ id: 0, commodityId: rowData.commodityId, bupercfm: rowData.bupercfm, actbupercfm: -1, actpress: -1, kcfm: -1 });
      }
      else {
        this.airflowConfiguration.airflowData[index].bupercfm = control.valueAsNumber;
      }
      rowData.staticPressure = this.calculateStaticPressure(rowData.a, rowData.b, rowData.bupercfm);
      this._tandem = 1;
      this.airflowConfiguration.level = AqsAirflowLevelEnum.Normal;//1
      this.clearFansAndcfmperbu();
      this.clearChart();
      this.recalculateAirflow()
      this.calculateRecommendTandemLevel();
      this.onChangedAirflowModels.emit();
    }
    else {
      control.value = rowData.bupercfm;
    }
  }

  onChangePackingFactor(pakingFactorControl) {
    if (pakingFactorControl.valueAsNumber >= 0.7 && pakingFactorControl.valueAsNumber <= 2.5) {
      this.airflowConfiguration.packingFactor = pakingFactorControl.valueAsNumber;
      this.clearFansAndcfmperbu();
      this.clearChart();
      this.recalculateAirflow();
      this.calculateRecommendTandemLevel()
      this.onChangedAirflowModels.emit();
    }
    else {
      pakingFactorControl.value = this.airflowConfiguration.packingFactor;
    }
  }

  onChangeSystemStep(sysStepControl) {
    sysStepControl.valueAsNumber = sysStepControl.valueAsNumber | 0;
    if (sysStepControl.valueAsNumber >= 0 && sysStepControl.valueAsNumber <= 10) {
      this.airflowConfiguration.systemStep = sysStepControl.valueAsNumber;
      this.clearFansAndcfmperbu();
      this.clearChart();
      this.recalculateAirflow();
      this.calculateRecommendTandemLevel()
      this.onChangedAirflowModels.emit();
    }
    else {
      sysStepControl.value = this.airflowConfiguration.systemStep;
    }
  }

  onChangeLevel() {
    this._tandem = this.airflowConfiguration.level == AqsAirflowLevelEnum.Tandem || this.airflowConfiguration.level == AqsAirflowLevelEnum.PushPull ? 2 : 1;
    this.recalculateAirflow();
    this.calculateRecommendFanType();
    this.clearFansAndcfmperbu();
    this.clearChart();
    this.onChangedAirflowModels.emit();
  }

  onChangeNumberFuns(numberFunsControl) {
    numberFunsControl.valueAsNumber = numberFunsControl.valueAsNumber | 0;
    if (numberFunsControl.valueAsNumber > 0 && numberFunsControl.valueAsNumber < this.numberOfFans) {
      this.airflowConfiguration.numberfans = numberFunsControl.valueAsNumber;
      this.clearFansAndcfmperbu();
      this.clearChart();
      this.onChangedAirflowModels.emit();
    }
    else {
      numberFunsControl.value = this.airflowConfiguration.numberfans;
    }
  }

  onSelectedFan() {
    let index = this._fanOptions.indexOf(this._fanOptions.find(f => f.value.fanId == this.airflowConfiguration.selectedFan.fanId));
    if (index != -1) {
      let fansLenght = this.fanTypesWithFans.find(ft => ft.type == this.airflowConfiguration.fanType).fans.length;
      this.chart.data.datasets.map((dataset, index) => {
        if (index < fansLenght) {
          dataset.borderColor = this.fanCurveColor;
          dataset.borderWidth = this.fanCurveBorderWidth;
        }
      });
      this.chart.data.datasets[index].borderColor = this.fanSelectedColor;
      this.chart.data.datasets[index].borderWidth = this.fanSelectedBorderWidth;
      this.chart.update();
    }
    this.findInterception();
    this.onChangedAirflowModels.emit();
  }

  onChangeFanType() {
    this.clearFansAndcfmperbu();
    this.clearChart();
    this.onChangedAirflowModels.emit();
  }

  generateChart() {
    this.clearChart();
    let fanTypeWithFan = this.fanTypesWithFans.find(ft => ft.type == this.airflowConfiguration.fanType);
    let calculateData = this.makeFansAndMaterialsDataForChart(fanTypeWithFan.fans);
    let signFanCurvePlugin = {
      afterDatasetsDraw: (instance) => {
        let previousX: number[] = [0];
        let x: number;
        let y: number;
        let fontSize = 16;
        let ctx = instance.chart.ctx as CanvasRenderingContext2D;
        ctx.font = `${fontSize}px serif`;
        ctx.fillStyle = 'black';
        for (let i = 0; i < fanTypeWithFan.fans.length; i++) {
          var meta = instance.getDatasetMeta(i)
          let cantBeSign: boolean = false;
          for (let j = 0; j < meta.data.length; j++) {
            x = meta.data[j]._model.x;
            y = meta.data[j]._model.y;
            previousX.forEach(item => {
              if (Math.abs(item - x) < 10) {
                cantBeSign = true;
              }
            });
            if (cantBeSign) {
              cantBeSign = false;
            } else {
              break;
            }
          }

          this.ctx.beginPath();
          if (fanTypeWithFan.fans[i].id == this.airflowConfiguration.selectedFan.fanId) {
            this.ctx.strokeStyle = this.fanSelectedColor;
            this.ctx.lineWidth = this.fanSelectedBorderWidth;
          }
          this.ctx.arc(x, y, 2, 0, 2 * Math.PI, false);
          this.ctx.stroke();
          this.ctx.strokeStyle = this.fanCurveColor;
          this.ctx.lineWidth = this.fanCurveBorderWidth;
          ctx.save();
          ctx.translate(x, y - 2);
          ctx.rotate((-75 * Math.PI / 180));
          ctx.fillText(fanTypeWithFan.fans[i].name, 0, 0);
          ctx.restore();
          previousX.push(x);
        }

        let dash = this.airflowConfiguration.fanType != AqsAirflowFanTypeEnum.Centrifugal60HZAF ? this.fanDash : [];
        x = instance.width / 4;
        y = instance.height - 10;
        ctx.beginPath();
        y -= fontSize / 4;
        ctx.moveTo(x, y);
        x += 40;
        ctx.setLineDash(dash);
        ctx.lineTo(x, y);
        ctx.fillText(AqsChartFanTypeNote[this.airflowConfiguration.fanType].leftNote, x, y);
        ctx.stroke();
        if (this.airflowConfiguration.fanType != AqsAirflowFanTypeEnum.Centrifugal60HZAF) {
          ctx.beginPath();
          x = instance.width / 2 + 20;
          ctx.moveTo(x, y);
          x += 40;
          ctx.setLineDash([]);
          ctx.lineTo(x, y);
          ctx.fillText(AqsChartFanTypeNote[this.airflowConfiguration.fanType].rightNote, x, y);
          ctx.stroke();
        }
      }
    };

    this.chart = new Chart(this.ctx, {
      type: 'scatter',
      data: calculateData.data,
      plugins: [signFanCurvePlugin],
      options: {
        layout: {
          padding: {
            bottom: 30,
          },
        },
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              max: calculateData.maxX,
              min: 0,
              stepSize: 1
            },
            scaleLabel: {
              display: true,
              labelString: "Airflow in thousands of CFM's",
            }
          }],
          yAxes: [{
            ticks: {
              max: calculateData.maxY,
              min: 0,
              stepSize: 1
            },
            scaleLabel: {
              display: true,
              labelString: 'Static Pressure',
            }
          }],
        },
        elements: {
          point: {
            radius: 0
          }
        },
        legend: {
          labels: {
            filter: (item, chart) => {
              return item.text != undefined;
            },
          },
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          mode: null
        },
      },
    });
    this.fillFanOptionsAccordingToFanType();
  }

  setUpExistIncludeFlowOptions() {
    if (!this._airflowTableData.some(d => d.cfmperbu == -1 || d.cfmperbu == null)) {
      if (this.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.FlushFloor) {
        this._ductDesign = false;
        this._flushFloorDesign = true;
      } else if (this.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.Duct) {
        this._ductDesign = true;
        this._flushFloorDesign = false;
      }
      this._isShowFlowOptions = true;
    }
  }

  canBeExhauster(): boolean {
    if (this.airflowConfiguration.fanType == AqsAirflowFanTypeEnum.Centrifugal50HZ) {
      return false;
    }

    if (this.airflowConfiguration.level == AqsAirflowLevelEnum.PushPull || this.airflowConfiguration.level == AqsAirflowLevelEnum.RoofPull) {
      return false;
    }
    return true;
  }

  roundCheckFlushFloor(): boolean {
    let numberfans = this.airflowConfiguration.numberfans;
    return (numberfans == 1 || numberfans == 2 || numberfans == 4 || numberfans == 6) && (this.storageConfiguration as AqsRoundStorageWiewModel).hopperPitch == 0;
  }

  changedFlowOptions() {
    this.onChangedFlowOptions.emit();
  }

  onChangeFlushDuctOption(design: AqsFlushDuctDesignEnum) {
    switch (design) {
      case AqsFlushDuctDesignEnum.FlushFloor:
        if (this.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.FlushFloor) {
          this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.None;
        }
        else if (this.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.None) {
          this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.FlushFloor;
        }
        else {
          this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.FlushFloor;
          this._ductDesign = false;
        }
        break;
      default:
        if (this.sessionType == AqsSessionTypeEnum.FlatSessionType || !this.roundCheckFlushFloor()) {
          if (this.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.Duct) {
            this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.None;
          }
          else {
            this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.Duct;
          }
        }
        else {
          if (this.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.Duct) {
            this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.None;
          }
          else if (this.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.None) {
            this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.Duct;
          }
          else {
            this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.Duct;
            this._flushFloorDesign = false;
          }
        }
        break;
    }
    this.changedFlowOptions();
  }

  private calculateExistAirflowParameters() {
    this._tandem = this.airflowConfiguration.level == AqsAirflowLevelEnum.Normal || this.airflowConfiguration.level == AqsAirflowLevelEnum.RoofPull ? 1 : 2;
    this._airflowTableData = [];
    this.commodities.forEach(item => {
      let bupercfm: number;
      let cfmperbu: number;
      let index = this.airflowConfiguration.airflowData.findIndex(d => d.commodityId == item.id);
      if (index == -1) {
        bupercfm = 10;
        cfmperbu = null;
        this.airflowConfiguration.airflowData.push({ id: 0, commodityId: item.id, bupercfm: bupercfm, actbupercfm: -1, actpress: -1, kcfm: -1 });
      } else {
        bupercfm = this.airflowConfiguration.airflowData[index].bupercfm;
        if (this.airflowConfiguration.selectedFan.fanId == null && this.airflowConfiguration.selectedFan.fanId == undefined) {
          cfmperbu = null;
        } else {
          cfmperbu = Math.round(this.airflowConfiguration.airflowData[index].actbupercfm * 10) / 10;
        }
      }
      let staticPressure = this.calculateStaticPressure(item.aa, item.bb, bupercfm);
      this._airflowTableData.push({ commodityId: item.id, commodityName: item.name, staticPressure: staticPressure, bupercfm: bupercfm, a: item.aa, b: item.bb, cfmperbu: cfmperbu });
    });
  }

  private calculateNewAirflowParametersWithRecommendation() {
    this._airflowTableData = [];
    this.commodities.forEach(item => {
      let bupercfm = 10;
      let staticPressure = this.calculateStaticPressure(item.aa, item.bb, bupercfm);
      this._airflowTableData.push({ commodityId: item.id, commodityName: item.name, staticPressure: staticPressure, bupercfm: bupercfm, a: item.aa, b: item.bb, cfmperbu: null });
      this.airflowConfiguration.airflowData.push({ id: 0, commodityId: item.id, bupercfm: bupercfm, actbupercfm: -1, actpress: -1, kcfm: -1 });
    });
    this.calculateRecommendTandemLevel();
    this.calculateRecommendFanType();
  }

  private calculateStaticPressure(a: number, b: number, bupercfm: number): number {
    return Math.floor(((a * Math.pow(this.effDepth / bupercfm * .8036, 2)) / Math.log(1 + b * (this.effDepth / bupercfm * .8036)) * this.effDepth * this.airflowConfiguration.packingFactor / this._tandem * 1000) + this.airflowConfiguration.systemStep * 1000 + .49) / 1000;
    //INT(((A(I) * (EFFDEPTH / BUPERCFM(I) * .8036) ^ 2) / LOG(1 + B(I) * (EFFDEPTH / BUPERCFM(I) * .8036)) * EFFDEPTH * PACKINGFACTOR / TANDEM * 1000) + .49) / 1000
  }

  private calculateRecommendTandemLevel() {
    if (this._airflowTableData.some(s => s.staticPressure > 20)) {
      this._tandem = 2;
      this.airflowConfiguration.level = AqsAirflowLevelEnum.Tandem;//3
      this.recalculateAirflow();
      this.calculateRecommendFanType();
    }

  }

  private recalculateAirflow() {
    this._airflowTableData.forEach(item => {
      item.staticPressure = this.calculateStaticPressure(item.a, item.b, item.bupercfm);
    });
  }

  private calculateRecommendFanType() {
    let maxStPress = Math.max(...this._airflowTableData.map(a => a.staticPressure));
    this.airflowConfiguration.fanType = maxStPress <= 4 ? AqsAirflowFanTypeEnum.Axial : AqsAirflowFanTypeEnum.Centrifugal60HZ;
    if (this.sessionType == AqsSessionTypeEnum.RoundSessionType) {
      let storage = this.storageConfiguration as AqsRoundStorageWiewModel;
      this.airflowConfiguration.numberfans = storage.hopperPitch == 0 ? 1 : Math.round((storage.diameter * Math.PI) / storage.eaveHeight + 0.5);
    }
    else {
      let storage = this.storageConfiguration as FlatStorageParams;
      this.airflowConfiguration.numberfans = (storage.lenght / (storage.height + storage.roof / 2)) | 0;
      // NUMBERFANS = PILEHEIGHT + PILEROOF / 2
      // NUMBERFANS = INT(PILELENGTH / NUMBERFANS)
      if (this.airflowConfiguration.numberfans <= 0) this.airflowConfiguration.numberfans = 1;
      // IF NUMBERFANS <= 0 THEN NUMBERFANS = 1
      if (storage.width > 120) this.airflowConfiguration.numberfans *= 2;
      // IF PILEWIDTH > 120 THEN NUMBERFANS = NUMBERFANS * 2
    }
  }

  private fillFanOptionsAccordingToFanType() {
    this._isMadeFanPlot = true;
    this._fanOptions = [];
    let fans = this.fanTypesWithFans.find(ft => ft.type == this.airflowConfiguration.fanType);
    if (fans !== undefined) {
      this._fanOptions = fans.fans.map(f => { return { label: `${f.modelName} ${f.name}`, value: { fanId: f.id, fanName: f.name, modelName: f.modelName } } });
    }
  }

  private makeFansAndMaterialsDataForChart(fans: AqsAirflowFanViewModel[]) {
    let maxX = 0;
    let maxY = 0;
    let datasets: any[] = [];
    let fanTypeDivider = this.airflowConfiguration.fanType == AqsAirflowFanTypeEnum.Axial ? 2 : 1;
    fans.forEach(item => {
      let mx = Math.max(...item.fanPoints.map(x => x.x));
      let my = Math.max(...item.fanPoints.map(y => y.y / fanTypeDivider));
      if (maxX < mx) maxX = mx;
      if (maxY < my) maxY = my;
      let borderDash = this.curveFanDotStyle(item.name);
      let borderColor = this.airflowConfiguration.selectedFan != null && this.airflowConfiguration.selectedFan.fanId == item.id ? this.fanSelectedColor : this.fanCurveColor;
      let border = this.airflowConfiguration.selectedFan != null && this.airflowConfiguration.selectedFan.fanId == item.id ? this.fanSelectedBorderWidth : this.fanCurveBorderWidth;
      datasets.push({
        data: item.fanPoints.map((i) => { return { x: i.x, y: i.y / fanTypeDivider } }), fill: false, borderColor: borderColor, showLine: true, borderWidth: border, borderDash: borderDash,
      });
    });

    maxX = maxX | 0;
    maxY = maxY | 0;
    maxX += 1;
    maxY += 1;
    if (maxY < 10) maxY = 10;
    if (maxX < 20) maxX = 20;
    datasets.push(...this.calculateMaterialCurve());
    let calculateData = {
      data: {
        datasets: datasets
      },
      maxX: maxX,
      maxY: maxY,
    }
    return calculateData;
  }

  private curveFanDotStyle(fanName: string) {
    let fanNameNumber = parseFloat(fanName);
    switch (this.airflowConfiguration.fanType) {
      case AqsAirflowFanTypeEnum.Axial:
        if (!isNaN(fanNameNumber) && (fanNameNumber == 1401.52 || fanNameNumber == 1803.2)) {
          return this.fanDash;
        }
        break;
      case AqsAirflowFanTypeEnum.Centrifugal60HZ:
        if (!isNaN(fanNameNumber) && fanNameNumber > 2700) {
          return this.fanDash;
        }
      case AqsAirflowFanTypeEnum.Centrifugal60HZAF:
      case AqsAirflowFanTypeEnum.Centrifugal50HZ:
        if (!isNaN(fanNameNumber) && (fanNameNumber == 2205 || fanNameNumber == 2003)) {
          return this.fanDash;
        }
        break;
    }
    return [];
  }

  private calculateMaterialCurve(): any {
    let datasets: any[] = [];
    this.commodities.forEach((item, index) => {
      let point: { x: number, y: number }[] = [];
      let x1: number;
      let x2: number;
      let y1: number;
      let y2: number;
      let calcCoefficientWay = 0;
      y1 = this.calculateMaterialCurveY(item.aa, item.bb, .1);
      x1 = this.calculateMaterialCurveX(.1);
      y2 = this.calculateMaterialCurveY(item.aa, item.bb, .2);
      x2 = this.calculateMaterialCurveX(.2);
      if (y1 == Infinity || y2 == Infinity || x1 == Infinity || x2 == Infinity) {
        y1 = this.calculateMaterialCurveY(item.aa, item.bb, 1);
        x1 = this.calculateMaterialCurveX(1);
        y2 = this.calculateMaterialCurveY(item.aa, item.bb, 1.1);
        x2 = this.calculateMaterialCurveX(1.1);
        if (y1 == Infinity || y2 == Infinity || x1 == Infinity || x2 == Infinity) {
          y1 = this.calculateMaterialCurveY(item.aa, item.bb, 2.5);
          x1 = this.calculateMaterialCurveX(2.5);
          y2 = this.calculateMaterialCurveY(item.aa, item.bb, 2.8);
          x2 = this.calculateMaterialCurveX(2.8);
          if (y1 == Infinity || y2 == Infinity || x1 == Infinity || x2 == Infinity) {
            y1 = this.calculateMaterialCurveY(item.aa, item.bb, 10);
            x1 = this.calculateMaterialCurveX(10);
            y2 = this.calculateMaterialCurveY(item.aa, item.bb, 12);
            x2 = this.calculateMaterialCurveX(12);
            calcCoefficientWay = 3;
          }
          else calcCoefficientWay = 2;
        }
        else calcCoefficientWay = 1;
      }

      point.push({ x: x1, y: y1 });
      point.push({ x: x2, y: y2 });
      switch (calcCoefficientWay) {
        case 0:
          this.fillXYMaterialCurve(point, 0.2, 1.1, 0.1, item.aa, item.bb);
        case 1:
          this.fillXYMaterialCurve(point, 1.2, 2, 0.2, item.aa, item.bb);
        case 2:
          this.fillXYMaterialCurve(point, 3, 10, 1, item.aa, item.bb);
        case 3:
          this.fillXYMaterialCurve(point, 13, 30, 4, item.aa, item.bb);
        default:
          // this.fillXYMaterialCurve(point, 50, 2000, 200, item.aa, item.bb);
          let pos = 50;
          let step = 200;
          do {
            y1 = this.calculateMaterialCurveY(item.aa, item.bb, pos);
            x1 = this.calculateMaterialCurveX(pos);
            if (x1 !== Infinity && y1 !== Infinity) {
              point.push({ x: x1, y: y1 });
            }
            pos += step;
            if (point.length > 10000) break;
          } while (x1 > 0.1 || y1 > 0.1);
          break;
      }
      datasets.push({ label: item.name, data: point, fill: false, borderColor: this.materialCurveColor[index], showLine: true, borderWidth: this.materialCurveBorderWidth })
    });
    return datasets;
  }

  private calculateMaterialCurveY(a: number, b: number, coefficient: number): number {
    return ((a * Math.pow((this.effDepth / coefficient) * .8036, 2)) / Math.log(1 + b * ((this.effDepth / coefficient) * .8036))) * this.effDepth * this.airflowConfiguration.packingFactor / this._tandem + this.airflowConfiguration.systemStep;
    //PT1Y = YORGIN - ((A(I) * ((EFFDEPTH / .1) * .8036) ^ 2) / LOG(1 + B(I) * ((EFFDEPTH / .1) * .8036))) * YSCALE * EFFDEPTH * PACKINGFACTOR / TANDEM - YSCALE * SYSTEMSTEP
  }

  private calculateMaterialCurveX(coefficient: number): number {
    return this.capacity / coefficient / this.airflowConfiguration.numberfans / 1000;
    //PT1X = XORGIN + XSCALE * ((CAPACITY / .1) / NUMBERFANS) / 1000
  }

  private fillXYMaterialCurve(point, start: number, end: number, step: number, a: number, b: number) {
    let x: number;
    let y: number;
    for (let i = start; i <= end; i += step) {
      y = this.calculateMaterialCurveY(a, b, i);
      x = this.calculateMaterialCurveX(i);
      if (x !== Infinity && y !== Infinity) {
        point.push({ x: x, y: y });
      }
    }
  }

  private clearChart() {
    if (this.chart !== undefined) this.chart.destroy();
    this.createEmptyChart();
    this._isMadeFanPlot = false;
  }

  private clearFansAndcfmperbu() {
    this._fanOptions = [];
    this.airflowConfiguration.selectedFan.fanId = null;
    this.airflowConfiguration.selectedFan.fanName = null;
    this.airflowConfiguration.selectedFan.modelName = null;
    this._airflowTableData.forEach(item => item.cfmperbu = null);
    this.airflowConfiguration.airflowData.forEach(item => {
      item.actbupercfm = -1;
      item.actpress = -1;
      item.kcfm = -1;
    });
    this.showFlowOptions();
  }

  private createEmptyChart() {
    this.chart = new Chart(this.ctx, {
      type: 'scatter',
      data: null,
      options: {
        scales: {
          xAxes: [{
            ticks: {
              max: 20,
              min: 0,
              stepSize: 1
            },
            scaleLabel: {
              display: true,
              labelString: "Airflow in thousands of cfm's",
            }
          }],
          yAxes: [{
            ticks: {
              max: 10,
              min: 0,
              stepSize: 1
            },
            scaleLabel: {
              display: true,
              labelString: 'Static Pressure',
            }
          }],
        },
      }
    });
  }

  private findInterception() {
    let fan = this.fanTypesWithFans.find(ft => ft.type == this.airflowConfiguration.fanType)
      .fans.find(f => f.id == this.airflowConfiguration.selectedFan.fanId);
    let fanScale = this.airflowConfiguration.fanType == AqsAirflowFanTypeEnum.Axial ? 2 : 1;
    this._airflowTableData.forEach(item => {
      let index = this.airflowConfiguration.airflowData.findIndex(d => d.commodityId == item.commodityId);
      let isFoundInterception = false;
      let pt3x = fan.fanPoints[0].x;
      let pt3y = 0;
      let pt2x = 0;
      let pt2y = 0;
      let lowStaticPress = this.calculateInterceptionPress(item.a, item.b, pt3x);
      let hightStaticPress = 0;
      for (let i = 1; i < fan.fanPoints.length; i++) {
        let lastCyrcle = false;
        pt2x = fan.fanPoints[i].x;
        pt2y = fan.fanPoints[i].y;
        if (i == fan.fanPoints.length - 1) {
          pt2x = fan.fanPoints[i].x;
          pt2y = fan.fanPoints[i].y;
          lastCyrcle = true;
        }

        hightStaticPress = this.calculateInterceptionPress(item.a, item.b, pt2x);

        if (isNaN(hightStaticPress)) {
          break;
        }

        if (pt2y / fanScale < hightStaticPress) {
          if (lastCyrcle) {
            break;
          }
          lowStaticPress = hightStaticPress;
          pt3y = pt2y;
          pt3x = pt2x;
        } else {
          isFoundInterception = true;
          break;
        }
      }

      if (isFoundInterception) {
        let slope = ((pt2y - pt3y) / fanScale) / (pt2x - pt3x);
        //SLOPE = ((PT2Y - PT3Y) / FANSCALE) / (PT2X - PT3X)
        let yintercept = pt2y / fanScale - slope * pt2x;
        //YINTERCEPT = (PT2Y / FANSCALE) - SLOPE * PT2X
        let pt4x = pt2x;
        //PT4X = PT2X:
        let pt4y = pt2y;
        //PT4Y = PT2Y:
        let midStaticPress = hightStaticPress;
        //MIDSTPRESS = HIGHSTPRESS
        while (Math.abs(midStaticPress - pt4y / fanScale) > this.errorMargin) {
          //WHILE ABS(MIDSTPRESS - (PT4Y / FANSCALE)) > ERRORMARGIN
          pt4y = (pt2y + pt3y) / 2;
          pt4x = (pt4y / fanScale - yintercept) / slope;
          midStaticPress = this.calculateInterceptionPress(item.a, item.b, pt4x);
          if (midStaticPress > pt4y / fanScale) {
            pt3x = pt4x;
            pt3y = pt4y;
            //PT3X = PT4X: PT3Y = PT4Y
          } else {
            pt2x = pt4x;
            pt2y = pt4y;
            //PT2X = PT4X: PT2Y = PT4Y
          }
        }
        this.airflowConfiguration.airflowData[index].actbupercfm = this.capacity / (pt4x * this.airflowConfiguration.numberfans * 1000);
        //CAPACITY / (PT4X * NUMBERFANS * 1000)
        this.airflowConfiguration.airflowData[index].kcfm = pt4x;
        //KCFM(I) = PT4X
        this.airflowConfiguration.airflowData[index].actpress = midStaticPress;
        //ACTPRESS(I) = MIDSTPRESS
        item.cfmperbu = Math.round(this.airflowConfiguration.airflowData[index].actbupercfm * 10) / 10;
        //BUPERCFM = INT(ACTBUPERCFM(I) * 10 + .5)
      } else {
        item.cfmperbu = -1;
        this.airflowConfiguration.airflowData[index].actbupercfm = -1;
        this.airflowConfiguration.airflowData[index].kcfm = -1;
        this.airflowConfiguration.airflowData[index].actpress = -1;
      }
    });
    this.showFlowOptions();
  }

  private calculateInterceptionPress(a: number, b: number, coefficient: number): number {
    return ((a * (Math.pow(this.effDepth / (this.capacity / (coefficient * this.airflowConfiguration.numberfans * 1000)) * .8036, 2))) /
      Math.log(1 + b * ((this.effDepth / (this.capacity / (coefficient * this.airflowConfiguration.numberfans * 1000))) * .8036))) * this.effDepth *
      this.airflowConfiguration.packingFactor / this._tandem + this.airflowConfiguration.systemStep;
    //LOWSTPRESS = ((A(I) * ((EFFDEPTH / (CAPACITY / (PT3X * NUMBERFANS * 1000))) * .8036) ^ 2) / LOG(1 + B(I) * ((EFFDEPTH / (CAPACITY / (PT3X * NUMBERFANS * 1000))) * .8036))) * EFFDEPTH * PACKINGFACTOR / TANDEM + SYSTEMSTEP; systemstep for flat

  }

  private showFlowOptions() {
    this.includeFlowOption.includeExhauster = this.canBeExhauster();
    if (this.sessionType == AqsSessionTypeEnum.RoundSessionType) {
      this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.FlushFloor;
      this._flushFloorDesign = true;
      this._ductDesign = false;
    }

    if (this.sessionType == AqsSessionTypeEnum.FlatSessionType || !this.roundCheckFlushFloor()) {
      this.includeFlowOption.includeDesign = AqsFlushDuctDesignEnum.Duct;
      this._ductDesign = true;
      this._flushFloorDesign = false;
    }

    if (this.sessionType == AqsSessionTypeEnum.FlatSessionType) {
      this.includeFlowOption.includeExhauster = false;
    }

    this._isShowFlowOptions = !this._airflowTableData.some(d => d.cfmperbu == -1 || d.cfmperbu == null);
  }
}
