import { BtxEventTypeEnum, EmailServiceScheduleEnum, ItxEventTypeEnum, KtxEventTypeEnum } from "../enums";
import { UserLocation } from "./company-users.model";

export class EmailServiceViewModel {
    public id: number;
    public emails: Array<string>;
    public btxLocations: Array<UserLocation>;
    public btxLocationNames: string[];
    public ktxLocations: Array<UserLocation>;
    public ktxLocationNames: string[];
    public itxLocations: Array<UserLocation>;
    public itxLocationNames: string[];

    public scheduleType: EmailServiceScheduleEnum;
    public scheduleTime: number;
    public btxEventTypes: Array<BtxEventTypeEnum>
    public ktxEventTypes: Array<KtxEventTypeEnum>
    public itxEventTypes: Array<ItxEventTypeEnum>
    public companyId: number;
}



export class EmailServiceGetModel {
    public id: number;
    public emails: string;
    public btxLocations: Array<UserLocation>;
    public ktxLocations: Array<UserLocation>;
    public itxLocations: Array<UserLocation>;
    public scheduleType: EmailServiceScheduleEnum;
    public scheduleTime: number;
    public btxEventTypes: Array<BtxEventTypeEnum>;
    public ktxEventTypes: Array<KtxEventTypeEnum>;
    public itxEventTypes: Array<ItxEventTypeEnum>;
    public companyId: number;

    public static toViewModel(getModel: EmailServiceGetModel): EmailServiceViewModel {
        const viewModel = new EmailServiceViewModel();
        viewModel.id = getModel.id;
        viewModel.btxLocations = getModel.btxLocations;
        viewModel.ktxLocations = getModel.ktxLocations;
        viewModel.itxLocations = getModel.itxLocations;
        viewModel.emails = JSON.parse(getModel.emails);
        viewModel.companyId = getModel.companyId;
        viewModel.scheduleTime = getModel.scheduleTime;
        viewModel.scheduleType = getModel.scheduleType;
        viewModel.btxEventTypes = getModel.btxEventTypes;
        viewModel.ktxEventTypes = getModel.ktxEventTypes;
        viewModel.itxEventTypes = getModel.itxEventTypes;

        viewModel.btxLocationNames = viewModel.btxLocations.map(l => l.name);
        viewModel.ktxLocationNames = viewModel.ktxLocations.map(l => l.name);
        viewModel.itxLocationNames = viewModel.itxLocations.map(l => l.name);

        return viewModel;
    }
}

export class EmailServicePostModel {
    public id: number;
    public emails: string;
    public btxLocations: Array<number>;
    public ktxLocations: Array<number>;
    public itxLocations: Array<number>;
    public scheduleType: EmailServiceScheduleEnum;
    public scheduleTime: number;
    public btxEventTypes: Array<BtxEventTypeEnum>;
    public ktxEventTypes: Array<KtxEventTypeEnum>;
    public itxEventTypes: Array<ItxEventTypeEnum>;
    public companyId: number;
    public timeZone: string;
}
