import { Component } from '@angular/core';

import { ItxBinDataSourceService } from '../../../services';
import { ItxBinViewModel } from './../../../models';

@Component({
  selector: 'greensleeves-itx-bin-delete-popup',
  templateUrl: './itx-bin-delete-popup.component.html',
  styles: []
})
export class ItxBinDeletePopupComponent {
  _isHidden = true;
  private itxBin: ItxBinViewModel;
  constructor(
    private _itxBinDataService: ItxBinDataSourceService
  ) { }

  public show(itxBin: ItxBinViewModel) {
    this._isHidden = false;
    this.itxBin = itxBin;
  }

  async onSubmit() {
    const result = await this._itxBinDataService.delete(this.itxBin);
    if (result) {
      this._isHidden = true;
      this.itxBin = undefined;
    }
  }

  onCancel() {
    this.itxBin = undefined;
    this._isHidden = true;
  }

}
