import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { SensorViewModel } from '../../../../models';
import { EquipmentSubTypeEnum, EquipmentTypeEnum, SensorTypeEnum, SensorPositionEnum, EquipmentSideEnum } from '../../../../enums';
import { equipmentSensorMapping } from 'src/app/main/constants/equipment-sensor-mapping.constants';
import { sensorPositionLabels, sensorTypeLabels, equipmentWestEastSideLabels, equipmentNorthSouthSideLabels } from 'src/app/main/constants';
import { bitOptions, disabledBitOptions } from 'src/app/main/constants/equipment-options';
import { digitsValidator } from 'src/app/common/validators';
import { ModbussAddress, TemplateFunctions } from '../../../common';

@Component({
  selector: 'greensleeves-edit-sensor-popup',
  templateUrl: './edit-sensor-popup.component.html',
  styles: []
})
export class EditSensorPopupComponent implements OnInit, OnChanges {
  _isHidden = true;

  @Input()
  type: EquipmentSubTypeEnum;

  @Input()
  equipment: EquipmentTypeEnum;

  @Input()
  uniqueValidator: (sensor: SensorViewModel) => boolean;

  @Input()
  uniqueAddressesValidator: (sensor: SensorViewModel) => ModbussAddress[];

  _sensor: SensorViewModel;

  @Output()
  sensorChange: EventEmitter<SensorViewModel> = new EventEmitter<SensorViewModel>();

  _submitted = false;
  _sensorForm: FormGroup;

  _sensorType = 'sensorType';
  _sensorPosition = 'sensorPosition';
  _equipmentSide = 'equipmentSide';
  _generalAddressErrorText = 'Address already in use';
  _generalBitErrorText = 'Bit already in use';

  _editableSensorTypes: { [key: number]: Array<{}> } = {};
  _editableSensorPositions: { [key: number]: Array<{}> } = {};
  _editableEquipmentSides: { [key: number]: Array<{}> } = {};

  _sensorTypes: Array<{ label: string, value: number }> = [];
  _sensorPositions: Array<{ label: string, value: number }> = [];
  _equipmentSides: Array<{ label: string, value: number }> = [];

  private disableBitOptions = disabledBitOptions;
  private enabledBitOptions = bitOptions;
  _bitOptions = bitOptions;
  _rateOfChangeBitOptions = bitOptions;

  get _allowedSensorTypes() {
    return this.toDropdownOptions(equipmentSensorMapping[this.equipment][this.type], this._sensorType);
  }

  get _allowedSensorPositions() {
    return this.getAllowedSensorPositions(this._sensorForm.get('type').value);
  }

  get _allowedSides() {
    return this.getAllowedSides(this._sensorForm.get('type').value, this._sensorForm.get('position').value);
  }

  constructor(
    _formBuilder: FormBuilder
  ) {
    this._sensorForm = _formBuilder.group({
      type: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      position: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      side: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      modbusAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      warningAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      warningAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ])
      ],
      alarmAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      alarmAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ])
      ],
      rateOfChangeAlarmAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      rateOfChangeAlarmAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ])
      ],
      rateOfChangeWarningAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      rateOfChangeWarningAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ])
      ]
    })
  }

  ngOnInit() {
    this.updateFormOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateFormOptions();
  }

  onSubmit() {
    this._submitted = true;
    if (this._sensorForm.hasError('unique')) {
      delete this._sensorForm.errors.unique;
      this._sensorForm.updateValueAndValidity();
    }

    if (this._sensorForm.invalid) {
      return;
    }

    const newSensor = SensorViewModel.getViewModel(this._sensorForm.value);
    if (this._sensor.type == newSensor.type) {
      newSensor.id = this._sensor.id;
    }

    if (!this.uniqueValidator(newSensor)) {
      this._sensorForm.setErrors({
        unique: 'Sensor with such type, position and side is already set'
      });
      return;
    }

    const notUniqueAddresses = this.uniqueAddressesValidator(newSensor);
    if (notUniqueAddresses && notUniqueAddresses.length > 0) {
      TemplateFunctions.markAddressControllsAsNotUnique(this._sensorForm, notUniqueAddresses);
      return;
    }

    this.sensorChange.emit(newSensor);
    this._isHidden = true;
    this._submitted = false;
    this._sensorForm.reset();
  }

  getAllowedSensorPositions(sensorType: SensorTypeEnum) {
    return this.toDropdownOptions((sensorType || sensorType === 0) && equipmentSensorMapping[this.equipment][this.type][sensorType] || null, this._sensorPosition);
  }

  getAllowedSides(sensorType: SensorTypeEnum, position: SensorPositionEnum) {
    let allowedSides = this.toDropdownOptions((sensorType || sensorType === 0) && (position || position === 0) && equipmentSensorMapping[this.equipment][this.type][sensorType][position] || null, this._equipmentSide);
    return allowedSides.filter(side => side.label);
  }

  private toDropdownOptions(map: Object, type: string) {
    if (!map) {
      return [];
    }

    let labels;
    switch (type) {
      case this._sensorType:
        labels = sensorTypeLabels;
        break;
      case this._sensorPosition:
        labels = sensorPositionLabels;
        break;
      case this._equipmentSide:
        if (this._sensor.side as EquipmentSideEnum === EquipmentSideEnum.East || this._sensor.side as EquipmentSideEnum === EquipmentSideEnum.West) {
          labels = equipmentWestEastSideLabels;
        } else {
          labels = equipmentNorthSouthSideLabels;
        }
        break;
      default:
        return [];
    }

    return Object.keys(map).map(k => {
      return {
        label: labels[k],
        value: Number.parseInt(k)
      };
    });
  }

  updateFormOptions() {
    this._sensorTypes = this._allowedSensorTypes;
    this._sensorPositions = this._allowedSensorPositions;
    this._equipmentSides = this._allowedSides;
  }

  public show(sensor: SensorViewModel) {
    this._sensor = sensor;
    const {
      type,
      position,
      side,
      modbusAddress,
      warningAddress,
      warningAddressBit,
      alarmAddress,
      alarmAddressBit,
      rateOfChangeWarningAddress,
      rateOfChangeWarningAddressBit,
      rateOfChangeAlarmAddress,
      rateOfChangeAlarmAddressBit,
    } = sensor;
    this._sensorForm.setValue({
      type,
      position,
      side,
      modbusAddress: isNaN(modbusAddress) ? null : modbusAddress,
      warningAddress,
      warningAddressBit,
      alarmAddress,
      alarmAddressBit,
      rateOfChangeWarningAddress,
      rateOfChangeWarningAddressBit,
      rateOfChangeAlarmAddress,
      rateOfChangeAlarmAddressBit,
    });
    this.onSelectSensorType(true);
    this._isHidden = false;
  }

  onClickClose() {
    this._isHidden = true;
    this._submitted = false;
    this._sensorForm.reset();
  }

  hasValue(control: FormControl) {
    return control.value || control.value === 0;
  }

  onSelectSensorType(load:boolean=false) {
    this.updateFormOptions();
    switch (this._sensorForm.controls.type.value) {
      case SensorTypeEnum.Speed:
      case SensorTypeEnum.CurrentAmpDraw:
      case SensorTypeEnum.CurrentDesignLoad:
        this._sensorForm.controls.position.setValue(SensorPositionEnum.OnePerEquipment);
        this.updateFormOptions();
        this._sensorForm.controls.side.setValue(EquipmentSideEnum.None);
        break;
    }

    switch (this._sensorForm.controls.type.value) {
      case SensorTypeEnum.Vibration:
      case SensorTypeEnum.CurrentAmpDraw:
      case SensorTypeEnum.CurrentDesignLoad:
        this._sensorForm.controls.rateOfChangeWarningAddress.reset();
        this._sensorForm.controls.rateOfChangeWarningAddress.clearValidators();
        this._sensorForm.controls.rateOfChangeWarningAddress.disable();
        this._sensorForm.controls.rateOfChangeWarningAddress.setValue('-');
        this._sensorForm.controls.rateOfChangeWarningAddressBit.reset();
        this._sensorForm.controls.rateOfChangeWarningAddressBit.clearValidators();
        this._sensorForm.controls.rateOfChangeWarningAddressBit.setValue(null);
        this._sensorForm.controls.rateOfChangeWarningAddressBit.disable();
        this._sensorForm.controls.rateOfChangeAlarmAddress.reset();
        this._sensorForm.controls.rateOfChangeAlarmAddress.clearValidators();
        this._sensorForm.controls.rateOfChangeAlarmAddress.setValue("-");
        this._sensorForm.controls.rateOfChangeAlarmAddress.disable();
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.reset();
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.clearValidators();
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.setValue(null);
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.disable();
        this._rateOfChangeBitOptions = disabledBitOptions;
        break;
      default:
        this._sensorForm.controls.rateOfChangeWarningAddress.setValidators(Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ]));
        this._sensorForm.controls.rateOfChangeWarningAddress.enable();
        this._sensorForm.controls.rateOfChangeWarningAddressBit.setValidators(Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ]));
        this._sensorForm.controls.rateOfChangeWarningAddressBit.enable();
        this._sensorForm.controls.rateOfChangeAlarmAddress.setValidators(Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ]));
        this._sensorForm.controls.rateOfChangeAlarmAddress.enable();
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.setValidators(Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ]));
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.enable();
        this._rateOfChangeBitOptions = this.enabledBitOptions;
        if(!load){
          this._sensorForm.controls.rateOfChangeAlarmAddress.setValue(null);
          this._sensorForm.controls.rateOfChangeWarningAddress.setValue(null);
        }
        break;
    }
  }
}
