import { vendorRoutes } from './../../main.routes';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TreeNode } from 'primeng';

import { ItxExportReportPopupComponent, ItxLiveDataSettingPopupComponent, ItxStorageRadarsPopupComponent, ItxQuickEditPopupComponent, TutorialPopupComponent } from './../popups';
import { StorageBinTypesIcons, StorageBinTypesClasses, CapacityUnitLabel, TemperatureMeasureLabel, SizeMeasureLabel, SortParametersLabel } from "../../constants";
import { ItxBinDataSourceService, RoleService } from '../../services';
import { ConvertDataFunctions, LocalStorageService, UserRoleEnum } from '../../../common';
import { CapacityUnitEnum, TemperatureMeasureEnum, ItxBinTypeEnum, SizeMeasureEnum, ItxStorageSortingEnum, TutorialMenuEnum } from '../../enums';
import {
  FullnessRangeViewModel, ItxBinStaticMembersResponse,
  ItxStaticScemeData, PeriodToGetliveDataForLocations, UserPreferences, ItxBinLiveDataViewModel, ItxSchemeLiveData, ItxBinFilterParameters, ItxBinNameModel
} from './../../models';


@Component({
  selector: 'greensleeves-itx-live-data-tab',
  templateUrl: './itx-live-data-tab.component.html',
  styles: []
})

export class ItxLiveDataTabComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(ItxLiveDataSettingPopupComponent, { read: false, static: false })
  private _settingLiveDataModal: ItxLiveDataSettingPopupComponent;
  @ViewChild(ItxStorageRadarsPopupComponent, { read: false, static: false })
  private _listOfRadarsModal: ItxStorageRadarsPopupComponent;
  @ViewChild(ItxExportReportPopupComponent, { read: false, static: false })
  private _exportReportModal: ItxExportReportPopupComponent;
  @ViewChild(ItxQuickEditPopupComponent, { read: false, static: false })
  private quickEditStorage: ItxQuickEditPopupComponent;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  private static readonly storagesOnPage: number = 6;
  private static readonly ONE_MINUTE_MILLISECONDS: number = 60000;

  private subscriptions: Subscription[] = [];
  capacityUnit: { label: string, value: CapacityUnitEnum }[] = [];
  temperatureUnit: { label: string, value: TemperatureMeasureEnum }[] = [];
  sizeUnit: { label: string, value: SizeMeasureEnum }[] = [];
  userPreferences: UserPreferences;
  filterParameters: ItxBinFilterParameters = new ItxBinFilterParameters();
  pageNumber: number = 1;
  locationIdsToBeUpdatedAutomatically: number[] = [];
  availableBins: ItxBinNameModel[] = [];

  _filterCounter: number;
  _isReadyForExport: boolean;
  _sortParams: { name: string, value: ItxStorageSortingEnum }[] = [];
  _storageIcons: { name: SafeHtml, value: ItxBinTypeEnum }[] = [];
  storagesCount: number;
  _periodToGetLiveData: PeriodToGetliveDataForLocations[];
  _showShowMore: boolean = true;
  _isOpenFilterList: boolean = false;
  _searchForm: FormGroup;
  lastSearchPhrase: string = '';
  _selectedSortParam: { name: string, value: ItxStorageSortingEnum } = { name: '', value: ItxStorageSortingEnum.Alphabetically };
  _binIdsToDisplay: number[];
  _staticStorageMembers: ItxBinStaticMembersResponse[];
  _itxCompanies: TreeNode[] = [];
  _nodeSelected: TreeNode[];
  _storageTypesIcons: any = StorageBinTypesIcons;
  _storageTypesClasses: any = StorageBinTypesClasses;
  _storages: ItxBinLiveDataViewModel[] = [];
  _hasRadarError: boolean = false;
  _role: UserRoleEnum;
  _converFun: ConvertDataFunctions;

  get _canManage(): boolean {
    return this._role === UserRoleEnum.ServiceUser || this._role === UserRoleEnum.CompanyAdmin;
  }

  get isTutorialUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser)|| this._roleService.userHasRole(UserRoleEnum.Vendor);
  }

  constructor(
    private _itxBinDataService: ItxBinDataSourceService,
    formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private _localStorageService: LocalStorageService,
    private _roleService: RoleService,
  ) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }
  ngOnDestroy(): void {
    this.clearSubscriptions();
    this._itxBinDataService.itxBinLiveData$.next([]);
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = null;
  }

  private setUnitLabels() {
    for (const [key, value] of Object.entries(SortParametersLabel)) {
      this._sortParams.push({ name: value, value: Number(key) });
    }

    for (const [key, value] of Object.entries(CapacityUnitLabel)) {
      this.capacityUnit.push({ label: value, value: Number(key) });
    }
    for (const [key, value] of Object.entries(TemperatureMeasureLabel)) {
      this.temperatureUnit.push({ label: value, value: Number(key) });
    }
    for (const [key, value] of Object.entries(SizeMeasureLabel)) {
      this.sizeUnit.push({ label: value, value: Number(key) });
    }
    for (const [key, value] of Object.entries(StorageBinTypesIcons)) {
      this._storageIcons.push({ name: this._sanitizer.bypassSecurityTrustUrl(value), value: Number(key) })
    }
  }

  private getMinutesForFirstUpdate(timestampMinutes: number, acquisitionRateMinutes: number): number {
    let currentMinute = new Date().getMinutes();
    let timeDelta = currentMinute > timestampMinutes ? (currentMinute - timestampMinutes + 1) : (60 + currentMinute - timestampMinutes + 1);
    let minutesBeforeNextUpdate = acquisitionRateMinutes - timeDelta;
    return minutesBeforeNextUpdate;
  }

  private setSubscriptions() {
    let dataSubscription = this._itxBinDataService.itxBinLiveData$.subscribe(items => {
      if (items.length > 0)
        items.forEach(nD => {
          let bin = this._storages.find(s => s.id == nD.id);
          if (bin != undefined) {
            bin.colorHex = this.getColorHex(this._staticStorageMembers.find(stat => stat.locationId == bin.locationId).fullnessRangeResponses, nD.binPercentFullness);
            bin.binPercentFullness = nD.binPercentFullness;
            bin.binFullnessCapacity = nD.binFullnessCapacity;
            bin.binTemperature = nD.binTemperature;
            bin.binHeadSpace = nD.binHeadSpace;
            bin.hasRadarError = nD.hasRadarError;
            bin.radars = nD.radars;
            bin.calculatedUsedCapacity = nD.calculatedUsedCapacity;
            bin.calculatedAvailableCapacity = nD.calculatedAvailableCapacity;
            bin.calculatedErrorBound = nD.calculatedErrorBound;
            bin.timestamp = nD.timestamp;
            let timestamp = new Date(nD.timestamp);
            if (this.locationIdsToBeUpdatedAutomatically.indexOf(bin.locationId) == -1) {
              let acquisitionRate = this._periodToGetLiveData.find(l => l.locationId == bin.locationId).acquisitionRate;
              let timeDelta = this.getMinutesForFirstUpdate(timestamp.getMinutes(), acquisitionRate);
              this.locationIdsToBeUpdatedAutomatically.push(bin.locationId);
              this.setFirstTimer(bin.locationId, timeDelta);
            }
          }
        });
    });
    this.subscriptions.push(dataSubscription);

    let userPreferenceChanged = this._itxBinDataService.userPreference$.subscribe(item => {
      this.userPreferences = item;
      this._storages.forEach(storage => {
        storage.sizeUnit = this.userPreferences.sizeMeasure;
        storage.capacityUnit = this.getCapacityUnitAccordingToUserPreferences(storage.binType);
        storage.temperatureUnit = this.userPreferences.temperatureMeasure;
      });
    })
    this.subscriptions.push(userPreferenceChanged);
  }

  ngAfterViewInit() {
    let updateSub = this.quickEditStorage.needToUpdateLiveData.subscribe((newBin) => {
      if (this._itxCompanies && this._itxCompanies.length > 0) {
        this._itxCompanies.some((i) => {
          if (i.children) {
            return i.children.some((c) => {
              if (c.children) {
                return c.children.some(b => {
                  if (b.data == newBin.id) {
                    b.label = newBin.name;
                    return true;
                  }
                });
              }
            });
          }
        });
      }
      this.RefreshLiveData();
    });
    this.subscriptions.push(updateSub);
  }

  private setDefaultValues(scheme: ItxStaticScemeData) {
    this._staticStorageMembers = scheme.locationsLiveData;
    let locationsLiveDataGroupedByCompanyId = this.groupByCompanyAndPopulateAvailableBins(this._staticStorageMembers);
    for (const key in locationsLiveDataGroupedByCompanyId) {
      this._itxCompanies.push({
        label: locationsLiveDataGroupedByCompanyId[key][0].companyName,
        key: locationsLiveDataGroupedByCompanyId[key][0].companyId.toString() +
          locationsLiveDataGroupedByCompanyId[key][0].companyName,
        data: locationsLiveDataGroupedByCompanyId[key][0].companyId,
        expanded: true,
        children: locationsLiveDataGroupedByCompanyId[key].map(
          (c: { locationName: any; locationId: { toString: () => any; }; bins: any[]; }) => ({
            label: c.locationName,
            key: c.locationId.toString() + c.locationName,
            data: c.locationId,
            expanded: false,
            children: (c.bins != undefined && c.bins.length > 0)
              ? c.bins.map(b => ({ label: b.name, data: b.id, key: b.id.toString() + b.name }))
              : []
          }))
      }
      );
    }

    this.userPreferences = scheme.userMeasurePreferences;
    this._periodToGetLiveData = scheme.periodToGetDataForLocations;


  }

  groupByCompanyAndPopulateAvailableBins(array: ItxBinStaticMembersResponse[]) {
    this.availableBins = [];
    array.forEach(b => {
      this.availableBins.push(...b.bins)
    })
    return array.reduce((r, a) => {
      r[a.companyId] = r[a.companyId] || [];
      r[a.companyId].push(a);
      return r;
    }, Object.create(null));
  }

  async onClickCompanyFilter() {
    this._filterCounter = 0;
    this._isReadyForExport = false;
    this.filterParameters.storageIdsToFilter = [];
    this._nodeSelected.forEach(i => {
      if (!i.children) {
        this.filterParameters.storageIdsToFilter.push(i.data);
      }
    });
    this._filterCounter = this.filterParameters.storageIdsToFilter.length;
    await this.RefreshLiveData();
  }

  private setFirstTimer(locationId: number, minutes: number) {
    let milistcondsToUpdate = minutes * ItxLiveDataTabComponent.ONE_MINUTE_MILLISECONDS;
    setTimeout(() => {
      this.updateLiveData(locationId);
      this.setPeriodicallyUpdateTimer(locationId);
    }, milistcondsToUpdate);
  }

  private setPeriodicallyUpdateTimer(locationId: number) {
    let interval = this._periodToGetLiveData.find(x => x.locationId == locationId).acquisitionRate * ItxLiveDataTabComponent.ONE_MINUTE_MILLISECONDS;
    setInterval(() =>
      this.updateLiveData(locationId), interval);
  }

  async ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;
    this.setUnitLabels();
    this.setSubscriptions();

    const staticScheme = await this._itxBinDataService.getStaticItxBinSchemeData();
    if (staticScheme) {
      this.setDefaultValues(staticScheme as ItxStaticScemeData);
    }
    await this.updateSchemeOfStoragesToDisplay(this.pageNumber);
    this._binIdsToDisplay = this._storages.map(s => s.id);
    await this._itxBinDataService.getDataForLiveData(this._binIdsToDisplay);
  }

  private getCapacityUnitAccordingToUserPreferences(binType: ItxBinTypeEnum): CapacityUnitEnum {
    switch (binType) {
      case ItxBinTypeEnum.LiquidStorageConical:
      case ItxBinTypeEnum.LiquidStorageFlat:
      case ItxBinTypeEnum.LiquidStorageSloped:
        return this.userPreferences.capacityLiquidUnit;
      default:
        return this.userPreferences.capacityLoosUnit;
    }
  }

  getSizeLabelToDisplay(sizeUnit: SizeMeasureEnum): string {
    return SizeMeasureLabel[sizeUnit];
  }

  getCapacityLabelToDisplay(capacityUnit: CapacityUnitEnum): string {
    return CapacityUnitLabel[capacityUnit];
  }

  getTemperatureLabelToDisplay(temperatureUnit: TemperatureMeasureEnum): string {
    return this.temperatureUnit.find(x => x.value == temperatureUnit).label;
  }

  getTemperatureValue(value: number, temperaureUnit: TemperatureMeasureEnum): number {
    let result: number;
    if (temperaureUnit == TemperatureMeasureEnum.Celsius) {
      result = ConvertDataFunctions.fahrenheitToCelsius(value);
    }
    else {
      result = value;
    }
    return result;
  }

  getSizeValue(value: number, sizeUnit: SizeMeasureEnum): number {
    let result = ConvertDataFunctions.convertSizeFromFt(value, sizeUnit);
    return result;
  }

  roundAndUsString(num: number, digits: number): string {
    return ConvertDataFunctions.roundAndUsString(num, digits);
  }

  getCapacityValue(value: number, capacityUnit: CapacityUnitEnum, binType: ItxBinTypeEnum): number {
    let result: number;
    switch (binType) {
      case ItxBinTypeEnum.LiquidStorageConical:
      case ItxBinTypeEnum.LiquidStorageFlat:
      case ItxBinTypeEnum.LiquidStorageSloped:
        result = ConvertDataFunctions.convertFromGal(value, capacityUnit);
        break;
      default:
        result = ConvertDataFunctions.convertFromFt3(value, capacityUnit);
    }
    return result;
  }

  private getColorHex(fullnessRanges: FullnessRangeViewModel[], fullnessPercent: number): string {
    let colorHex: string;
    fullnessRanges.forEach(f => {
      if (fullnessPercent >= f.from && fullnessPercent <= f.to) {
        colorHex = f.colorHex;
      }
    })
    return colorHex;
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  openFilterList() {
    this._isOpenFilterList = !this._isOpenFilterList;
  }

  async onChangeSortedParam(option) {
    this._isReadyForExport = false;
    this._selectedSortParam = option.value;
    await this.RefreshLiveData();
  }


  getStorageImage(bintype: string): SafeHtml {
    return this._storageIcons[bintype].name;
  }

  getStorageColor(storageFull: number, rangeColor: string) {
    const gradientSize = 100 - storageFull - 10;
    const gradientBackground = `linear-gradient(to bottom, #ffffff ${gradientSize}%, ${rangeColor})`;
    return gradientBackground;
  }

  onClickSetting() {
    this._settingLiveDataModal.show(this.userPreferences);
  }

  onClickRadar(storage: ItxBinLiveDataViewModel) {
    this._listOfRadarsModal.show(storage);
  }

  async onUpdateLastSearch() {
    this.filterParameters.searchBinByName = this._searchForm.controls.searchPhrase.value;
    this._isReadyForExport = false;
    await this.RefreshLiveData();
  }

  onClickExportReport() {
    let searchedBins: ItxBinNameModel[] = [];
    let binIds: number[] = [];
    if (this.filterParameters.searchBinByName && this.filterParameters.searchBinByName != '') {
      searchedBins.push(...this.availableBins.filter(b => b.name.toUpperCase().includes(this.filterParameters.searchBinByName.toUpperCase()) || b.material.toUpperCase().includes(this.filterParameters.searchBinByName.toUpperCase())));
      if (this.filterParameters.storageIdsToFilter.length > 0) {
        binIds.push(...searchedBins.filter(bin => this.filterParameters.storageIdsToFilter.includes(bin.id)).map(x => x.id));
      } else {
        binIds.push(...searchedBins.map(b => b.id));
      }

    } else {
      if (this.filterParameters.storageIdsToFilter.length > 0) {
        binIds = this.filterParameters.storageIdsToFilter;
      }
      else {
        binIds = this.availableBins.map(b => b.id);
      }
    }
    this._exportReportModal.showDownloadLiveDataReport(binIds, this._selectedSortParam.value);
  }

  async RefreshLiveData() {
    this.pageNumber = 1;
    this._storages = [];

    await this.updateSchemeOfStoragesToDisplay(this.pageNumber)

    let storageIdsToGetData = this._storages.map(x => x.id);
    if (this._selectedSortParam.value == ItxStorageSortingEnum.Material || this._selectedSortParam.value == ItxStorageSortingEnum.AlphabeticallyDesc || this._selectedSortParam.value == ItxStorageSortingEnum.Alphabetically)

      await this._itxBinDataService.getDataForLiveData(storageIdsToGetData);
  }

  async onClickShowMore() {
    await this.updateSchemeOfStoragesToDisplay(++this.pageNumber);
    let startNumber: number = (this.pageNumber - 1) * ItxLiveDataTabComponent.storagesOnPage;
    let endNumber: number = (this.pageNumber - 1) * ItxLiveDataTabComponent.storagesOnPage + ItxLiveDataTabComponent.storagesOnPage;

    let storageIdsToGetData = this._storages.slice(startNumber, endNumber).map(x => x.id);

    await this._itxBinDataService.getDataForLiveData(storageIdsToGetData);
  }

  async onClickQuickEditStorage(storage: ItxBinLiveDataViewModel) {
    this.quickEditStorage.show({ id: storage.id, material: storage.material, name: storage.name });
  }



  private checkIfShowMore() {
    if (this._storages.length == this.storagesCount) {
      this._showShowMore = false;
    } else {
      this._showShowMore = true;
    }
  }

  private async updateSchemeOfStoragesToDisplay(pageNumber: number) {
    this.pageNumber = pageNumber;
    let items = await this._itxBinDataService.getSchemeForLiveData(this.filterParameters,
      this.pageNumber,
      this._selectedSortParam.value);
    if (items) {
      let liveDataViewModels = (items as unknown as ItxSchemeLiveData).itxBinSchemeResponses as ItxBinLiveDataViewModel[];
      liveDataViewModels.forEach(storage => {
        if (this.userPreferences.id != 0) {
          storage.sizeUnit = this.userPreferences.sizeMeasure;
          storage.capacityUnit = this.getCapacityUnitAccordingToUserPreferences(storage.binType);
          storage.temperatureUnit = this.userPreferences.temperatureMeasure;
        } else {
          storage.temperatureUnit = TemperatureMeasureEnum.Fahrenheit;
        }
        if (this._selectedSortParam.value == ItxStorageSortingEnum.Capacity || this._selectedSortParam.value == ItxStorageSortingEnum.CapacityDesc || this._selectedSortParam.value == ItxStorageSortingEnum.Percentage || this._selectedSortParam.value == ItxStorageSortingEnum.PercentageDesc) {
          storage.colorHex = this.getColorHex(this._staticStorageMembers.find(stat => stat.locationId == storage.locationId).fullnessRangeResponses, storage.binPercentFullness);
          if (this.locationIdsToBeUpdatedAutomatically.indexOf(storage.locationId) == -1) {
            let acquisitionRate = this._periodToGetLiveData.find(l => l.locationId == storage.locationId).acquisitionRate;
            let timestamp = new Date(storage.timestamp);
            let timeDelta = this.getMinutesForFirstUpdate(timestamp.getMinutes(), acquisitionRate);
            this.locationIdsToBeUpdatedAutomatically.push(storage.locationId);
            this.setFirstTimer(storage.locationId, timeDelta);
          }
        }
      });

      this._storages.push(...liveDataViewModels);
      if (this._storages.length > 0) {
        this._isReadyForExport = true;
      }
      this.storagesCount = (items as ItxSchemeLiveData).totalFilteredCount;
    }
    this.checkIfShowMore();
  }

  private async updateLiveData(locationId: number) {
    let toUpdate: number[] = [];
   this._storages.forEach(s => {
      if (locationId == s.locationId) {
        toUpdate.push(s.id);
      }
    })
    if (toUpdate.length > 0) {
      await this._itxBinDataService.getDataForLiveData(toUpdate);
    }
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.ItxLiveData);
  }
}
