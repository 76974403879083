import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ComputationSheetAddEditModel, ComputationSheetResponseModel, AqsSavedQuoteModel, QuoteFlatStorageViewModel, QuoteRoundStorageViewModel, BindingComputationSheets } from '../../models';
import { AqsQuoteStorageApiService } from '../http';
import { ErrorModel } from '../../../common';

@Injectable({
  providedIn: 'root'
})
export class AqsQuoteStorageDataSourceService {
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  public quotes$: BehaviorSubject<AqsSavedQuoteModel[]> = new BehaviorSubject([]);
  public bindingComputationSheets$: BehaviorSubject<BindingComputationSheets[]> = new BehaviorSubject([]);

  constructor(private _aqsQuoteStorageApi: AqsQuoteStorageApiService) { }

  public async getQuoteFlatStorage(id: number): Promise<QuoteFlatStorageViewModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.getFlatById(id);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async getRoundFlatStorage(id: number): Promise<QuoteRoundStorageViewModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.getRoundById(id);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async editFlatStorage(quoteFlatStorage: QuoteFlatStorageViewModel): Promise<QuoteFlatStorageViewModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.editQuoteFlatStorage(quoteFlatStorage);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async editRoundStorage(quoteRoundStorage: QuoteRoundStorageViewModel): Promise<QuoteRoundStorageViewModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.editQuoteRoundStorage(quoteRoundStorage);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async addQuoteFlatStorage(quoteFlatStorage: QuoteFlatStorageViewModel): Promise<QuoteFlatStorageViewModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.addQuoteFlatStorage(quoteFlatStorage);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async addQuoteRoundStorage(quoteRoundStorage: QuoteRoundStorageViewModel): Promise<QuoteRoundStorageViewModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.addQuoteRoundStorage(quoteRoundStorage);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async deleteQuoteStorage(quoteId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.deleteQuoteStorage(quoteId);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async getAllSavedQuotes() {
    try {
      const result = await this._aqsQuoteStorageApi.GetAllQuotes();
      this.quotes$.next(result as AqsSavedQuoteModel[]);
    } catch (error) {
      if (error) {
        this.errors$.next(error);
      }
    }
  }

  public async addComputationSheet(computationSheet: ComputationSheetAddEditModel): Promise<ComputationSheetResponseModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.addComputationSheet(computationSheet);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async editComputationSheet(computationSheet: ComputationSheetAddEditModel): Promise<ComputationSheetResponseModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.editComputationSheet(computationSheet);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async getComputationSheetById(id: number): Promise<ComputationSheetResponseModel | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.getComputationSheetById(id);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async getAllComputationSheetForBinding(): Promise<BindingComputationSheets[] | ErrorModel> {
    try {
      const result = await this._aqsQuoteStorageApi.getAllComputationSheetForBinding();
      if(result){
        this.bindingComputationSheets$.next(result as BindingComputationSheets[]);
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

}
