export enum ItxBinTypeEnum{
    BinFlat = 0,
    BinConical = 1,
    BinSloped = 2,

    SiloFlat = 3,
    SiloConical = 4,
    SiloSloped = 5,

    FlatStorageLevel = 6,
    FlatStorageConical = 7,
    FlatStorageSloped = 8,

    LiquidStorageFlat = 9,
    LiquidStorageConical = 10,
    LiquidStorageSloped = 11,
}

export enum SizeMeasureEnum{
    Ft = 0,
    M = 1,
    In = 2,
}


export enum CapacityUnitEnum{
    Ft_3 = 0,
    M_3 = 1,
    Bsh = 2,
    L = 3,
    HL = 4,
    KL = 5,
    Gal = 6,
    Bbl = 7,
}

export enum TemperatureMeasureEnum{
    Celsius = 0,
    Fahrenheit = 1,
}

export enum ItxStorageSortingEnum{
    Alphabetically = 1,
    AlphabeticallyDesc = 2,
    Percentage = 3,
    PercentageDesc = 4,
    Capacity = 5,
    CapacityDesc = 6 ,
    Material = 7,
}