import { AqsDuctTypeEnum } from "../enums";
import { AqsRoundDuctDesignType } from "../enums";

export class AqsRoundStorageDuctDesignModel {
    id: number;
    ductsPerFan: number;
    degreesBetweenFans: number;
    degreesBetweenDucts: number;
    distanceBetweenDuctsFt: number;
    distanceBetweenDuctsIn: number;
    openingDiameter: number;
    ductDiameter: number;
    solidDuctLengthFt: number;
    solidDuctLengthIn: number;
    perforatedDuctLengthFt: number;
    perforatedDuctLengthIn: number;
    perforatedCDuctLengthFt: number;
    perforatedCDuctLengthIn: number;
    ductType: AqsDuctTypeEnum;

    constructor() {
        this.ductType = AqsDuctTypeEnum.FullRoundDuct;
    }
}

export class AqsRoundStorageDuctDesignViewModel {
    roundStorageDiameter: number;
    numberOfFans: number;

    numberOfDucts: number;
    ductsPerFan: number;

    degreesBetweenFans: number;
    degreesBetweenDucts: number;

    distanceBetweenDuctsFt: number;
    distanceBetweenDuctsIn: number;

    openingDiameter: number;
    ductDiameter: number;

    solidDuctLengthFt: number;
    solidDuctLengthIn: number;

    perforatedDuctLengthFt: number;
    perforatedDuctLengthIn: number;

    perforatedCDuctLengthFt: number;
    perforatedCDuctLengthIn: number;

    solidDuctLengthNoSingle: number = null;
    letterSolidDuctLengthNoSingle: string;

    solidDuctCLengthNoSingle: number = null;
    letterSolidDuctCLengthNoSingle: string;

    ductType: AqsDuctTypeEnum;

    maxVelocities: Velocities;
    actVelocities: Velocities;

    roundDuctDesignType: AqsRoundDuctDesignType

    hopperPitch: number;
    perfDMax: number;
    perfCMax: number;

    outletDiameter: number;

    innerDegree: number;
    innerFanDegree: number;
    innerDuctDegree: number;
    outerDegree: number;
    coneHeight: number;
    steamwall: number;
    ledge: number;

    constructor() {
        this.maxVelocities = new Velocities();
        this.actVelocities = new Velocities();
        this.ductType = AqsDuctTypeEnum.FullRoundDuct;
    }
}

export class Velocities {
    crossSec: number;
    surface: number;
}