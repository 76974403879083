export enum EquipmentTypeEnum {
    Leg = 0,
    Conveyor = 1,
    Silo = 2,
    Tripper = 3,
    Fan = 4,
}

export enum EquipmentSideEnum {
    None = 0,
    East = 1,
    West = 2,
    North = 3,
    South = 4,
}

export enum EquipmentSubTypeEnum {
    Small = 0,
    Medium = 1,
    Large = 2,
    WithKneeBearing = 3,
    WithoutKneeBearing = 4,
    Drag = 5,
    Belt = 6,
    Screw = 7,
    CoveredBelt = 8,
    None = 9,
    BeltDrive = 10,
    DirectDrive = 11,
}

export enum SensorTypeEnum {
    GrainDepth = 0,
    BearingTemperature = 1,
    RubBlockTemperature = 2,
    Speed = 3,
    GearBoxTemperature = 4,
    ShaftBearingTemperature = 5,
    BottomRubTemperature = 6,
    CurrentAmpDraw = 7,
    CurrentDesignLoad = 8,
    Vibration = 9,
}

export enum MeasureEnum {
    Temperature = 0,
    Resistance = 1,
}

export enum SensorPositionEnum {
    CenterOfTheEquipmentScheme = 0,
    Head = 1,
    Knee = 2,
    Boot = 3,
    OnePerEquipment = 4,
    Tail = 5,
    BendHead = 6,
    BendTail = 7,
    SnubHead = 8,
    SnubTail = 9,
    KneeHead = 10,
    KneeTail = 11,
    UpperTripperHead = 12,
    UpperTripperTail = 13,
    LowerTripperHead = 14,
    LowerTripperTail = 15,
    Upper = 16,
    Lower = 17,
    Top = 18,
    Motor = 19,
    Frame = 20,
    Drive = 21,
    FrameUpper = 22,
    FrameLower = 23,
    DriveUpper = 24,
    DriveLower = 25,
}

export enum SensorValuesStatusEnum {
    Norm = 0,
    Warning = 1,
    Alarm = 2,
    Error = 3,
}
export enum PLCDeviceEnum {
    Arduino = 0,
    Raspberry = 1,
}

export enum SensorSideEnum {
    None = 0,
    East = 1,
    West = 2,
    North = 3,
    South = 4,
}