import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyViewModel, GetLocationUpdatersFilterModel, InstallProjectModel, LocationDevicesViewModel, LocationUpdaterViewModel, ProjectUpdaterDeviceViewModel, UpdateDeviceViewModel, UpdateFirmwareTriggerModel } from '@models';
import { FirmwareManagementPopupComponent, UpdateVersionsManagementPopupComponent, DeviceCommunicationPopupComponent, UpdateFirmwarePopupComponent, UpdaterAddEditPopupComponent, UpdaterDeletePopupComponent, UploadFirmwareInstallPopupComponent } from '@popups';
import PerfectScrollbar from 'perfect-scrollbar';
import { interval, Subscription } from 'rxjs';

import { ColumnTypeEnum, ProjectEnum, UpdateProjectStateEnum } from '../../enums';
import { CompaniesDataSourceService, UpdaterDeviceDatasourceService } from '../../services';

@Component({
  selector: 'greensleeves-device-update-management-tab',
  templateUrl: './device-update-management-tab.component.html',
})
export class DeviceUpdateManagementTabComponent implements OnInit, OnDestroy, AfterViewInit {
  private static readonly STATE_UPDATES_RATE: number = 10000;
  @ViewChild(UpdateVersionsManagementPopupComponent, { read: false, static: false })
  private versionComponent: UpdateVersionsManagementPopupComponent;

  @ViewChild(UpdaterDeletePopupComponent, { read: false, static: false })
  private deleteComponent: UpdaterDeletePopupComponent;

  @ViewChild(UpdaterAddEditPopupComponent, { read: false, static: true })
  private addEditComponent: UpdaterAddEditPopupComponent;

  @ViewChild(FirmwareManagementPopupComponent, { read: false, static: true })
  private firmwareComponent: FirmwareManagementPopupComponent;

  @ViewChild(UpdateFirmwarePopupComponent, { read: false, static: true })
  private updateFirmwarePopup: UpdateFirmwarePopupComponent;

  @ViewChild(DeviceCommunicationPopupComponent, { read: false, static: true })
  private deviceLogsPopup: DeviceCommunicationPopupComponent;

  @ViewChild(UploadFirmwareInstallPopupComponent, { read: false, static: true })
  private installProjectPopup: UploadFirmwareInstallPopupComponent;

  static LOCATION_NAME_KEY = 'name';
  static COMPANY_NAME_KEY = 'companyName';

  _ps: PerfectScrollbar;
  _sortOrder = 1;
  _sortField = DeviceUpdateManagementTabComponent.COMPANY_NAME_KEY;
  _loading: boolean = false;
  _gridData: LocationUpdaterViewModel[] = [];
  _updateState = UpdateProjectStateEnum;
  _locationNameKey = DeviceUpdateManagementTabComponent.LOCATION_NAME_KEY;
  _companyNameKey = DeviceUpdateManagementTabComponent.COMPANY_NAME_KEY;
  _columnTypeEnum = ColumnTypeEnum;
  _projectEnum: ProjectEnum;
  _expandedLocationRows = {};
  _expandedUpdaterRows = {};
  _isOpenFilterList: boolean = false;
  _projectsOptions: { label: string, value: ProjectEnum }[] = [
    { label: ProjectEnum[ProjectEnum.Btx], value: ProjectEnum.Btx },
    { label: ProjectEnum[ProjectEnum.Ktx], value: ProjectEnum.Ktx },
    { label: ProjectEnum[ProjectEnum.Itx], value: ProjectEnum.Itx },
  ];
  _projectsSelected: ProjectEnum[] = [];

  private checkStateUpdates: UpdateFirmwareTriggerModel[] = [];
  private subscriptions: Subscription[] = [];
  private intervalSubscription: Subscription = null;

  _searchForm: FormGroup;
  _lastSearchPhrase: string;
  _companiesOptions: { label: string, value: number }[] = [];
  _companiesSelected: number[] = [];

  get _locationColumns() {
    return [
      { header: 'Company', columnType: ColumnTypeEnum.CompanyName, dataField: DeviceUpdateManagementTabComponent.COMPANY_NAME_KEY },
      { header: 'Location name', columnType: ColumnTypeEnum.LocationName, dataField: DeviceUpdateManagementTabComponent.LOCATION_NAME_KEY },
      { header: 'Projects', columnType: ColumnTypeEnum.Project, dataField: 'projectsAvailable' },
      { header: 'Projects with updaters', columnType: ColumnTypeEnum.Updaters, dataField: 'projectUpdaterAvailable' },
      { header: '', columnType: ColumnTypeEnum.Add, width: 46 },
      { header: '', columnType: ColumnTypeEnum.Expand, width: 46 },
    ];
  }

  get _updaterColumns() {
    return [
      { header: 'Updater name', columnType: ColumnTypeEnum.UpdaterName },
      { header: 'Current version', columnType: ColumnTypeEnum.CurrentVersion },
      { header: 'Last updated', columnType: ColumnTypeEnum.Date },
      { header: '', columnType: ColumnTypeEnum.Update, with: 46 },
      { header: '', columnType: ColumnTypeEnum.InitializeDevice, width: 46 },
      { header: '', columnType: ColumnTypeEnum.DownloadConnectionFile, width: 46 },
      { header: '', columnType: ColumnTypeEnum.UploadConfigFile, width: 46 },
      { header: '', columnType: ColumnTypeEnum.History, width: 46 },
      { header: '', columnType: ColumnTypeEnum.Edit, width: 46 },
      { header: '', columnType: ColumnTypeEnum.Delete, width: 46 },
      { header: '', columnType: ColumnTypeEnum.Expand, width: 46 },
    ];
  }

  get _updaterProjectsColumns() {
    return [
      { header: 'Project name', columnType: ColumnTypeEnum.UpdaterName },
      { header: 'Current version', columnType: ColumnTypeEnum.CurrentVersion },
      { header: 'Last updated', columnType: ColumnTypeEnum.Date },
      { header: '', columnType: ColumnTypeEnum.Update, with: 46 },
      { header: '', columnType: ColumnTypeEnum.UploadConfigFile, width: 46 },
      { header: '', columnType: ColumnTypeEnum.History, width: 46 },
    ];
  }

  constructor(
    private _updateDeviceDataService: UpdaterDeviceDatasourceService,
    private _companiesService: CompaniesDataSourceService,
    formBuilder: FormBuilder) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }
  async ngAfterViewInit() {
    var result = await this._companiesService.getCompaniesForUpdaterFilter() as CompanyViewModel[];
    this._companiesOptions = [];
    if (result && result.length > 0) {
      result.forEach(i => {
        this._companiesOptions.push({ label: i.name, value: i.id });
      })
    }
  }

  ngOnInit() {
    this._loading = true;
    let sub = this._updateDeviceDataService.locationsUpdaters$.subscribe(items => {
      this._gridData = items;
      this.subscribeUpdaters(items);
      this._loading = false;
    });

    this.getLocationsByFilter();
    this._companiesService.get();
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions && this.subscriptions.forEach(x => x.unsubscribe);
    this.intervalSubscription && this.intervalSubscription.unsubscribe();
  }

  projectsAvailable(locationDevices: LocationDevicesViewModel[]): string {
    let projects = locationDevices.map(x => ProjectEnum[x.project]);
    return projects.filter(x => x).join(', ');
  }

  projectUpdaterDevices(updaters: UpdateDeviceViewModel[]): string {
    let projectsUpdaters: string[] = [];
    if (updaters != null && updaters.length != 0) {
      updaters.forEach(x =>
        projectsUpdaters.push(...x.projectDevices.map(d => ProjectEnum[d.project]))
      );
      return projectsUpdaters.filter(x => x).join(', ');
    }

    return '-';
  }

  async onClickFirmwareManagement() {
    await this.firmwareComponent.onShow();
  }

  onClickAdd(location: LocationUpdaterViewModel) {
    this.addEditComponent.showAdd(location);
  }

  async onClickInitializeDevice(updater: UpdateDeviceViewModel) {
    updater.isDeviceInInitialization = true;
    updater.isInitialized = await this._updateDeviceDataService.initializeDeviceConfiguration(updater.id) as boolean;
    updater.isDeviceInInitialization = false;
  }

  async onClickDownloadConnection(updater: UpdateDeviceViewModel, location: LocationUpdaterViewModel) {
    this.deviceLogsPopup.onShowUpdaterDevice({ deviceId: updater.id, locationId: location.id, locationName: location.name, project: ProjectEnum.Updater },
      updater.projectsAvailable);
  }

  onClickEditDevice(updater: UpdateDeviceViewModel, location: LocationUpdaterViewModel) {
    this.addEditComponent.showEdit(updater, location);
  }

  async onClickDeleteDevice(updaterId: string, locationId: number) {
    this.deleteComponent.show(updaterId, locationId);
  }

  onDeleted(locatioId: number) {
    let location = this._gridData.find(x => x.id == locatioId);
    if (location) {
      if (location.updaterDevices.length == 0) {
        this._expandedLocationRows[locatioId] = false;
      }
    }
  }

  onEdit(editUpdater: UpdateDeviceViewModel) {
    let location = this._gridData.find(x => x.id == editUpdater.locationId);
    if (location) {
      var updater = location.updaterDevices.find(x => x.id == editUpdater.id);
      if (updater.projectDevices.length < 2) {
        this._expandedUpdaterRows[updater.id] = false;
      }
    }
  }

  async onClickDeviceHistory(device: UpdateDeviceViewModel, location: LocationUpdaterViewModel) {
    await this.versionComponent.onShow(device.projectDevices[0].deviceId, location.id, location.name, ProjectEnum[device.projectDevices[0].project]);
  }

  async onClickDeviceHistoryForSeveralProjects(device: ProjectUpdaterDeviceViewModel, location: LocationUpdaterViewModel) {
    await this.versionComponent.onShow(device.deviceId, location.id, location.name, ProjectEnum[device.project]);
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value.toLowerCase();

    this._gridData = this._updateDeviceDataService.locationsUpdaters$.getValue().filter(item => {
      const locationMatches = item[this._locationNameKey] && item[this._locationNameKey].toLowerCase().includes(this._lastSearchPhrase);
      const companyMatches = item[this._companyNameKey] && item[this._companyNameKey].toLowerCase().includes(this._lastSearchPhrase);
      return locationMatches || companyMatches;
    });
  }

  onClickFirmwareUpdate() {
    this.updateFirmwarePopup.show();
  }

  getUpdaterName(updater: UpdateDeviceViewModel): string {
    if (updater.projectDevices != null && updater.projectsAvailable != 0) {
      if (updater.projectDevices.length == 1) {
        return ProjectEnum[updater.projectDevices[0].project];
      } else {
        let projects = updater.projectDevices.map(x => ProjectEnum[x.project])
        return projects.filter(x => x).join(', ');
      }
    }
    return "Unknown";
  }

  getCurrentVersion(projectDevice: ProjectUpdaterDeviceViewModel): string {
    if (projectDevice != null && projectDevice.currentVersion != null && projectDevice.currentVersion.trim().length !== 0) {
      return projectDevice.currentVersion;
    }
    return '-';
  }

  getProjectString(project: ProjectEnum): string {
    return ProjectEnum[project];
  }

  async getLocationsByFilter() {
    this._loading = true;
    let request = {
      companies: this._companiesSelected,
      projects: this._projectsSelected,
    } as GetLocationUpdatersFilterModel;
    this._updateDeviceDataService.getByFilter(request);
  }

  canBeAddedUpdater(location: LocationUpdaterViewModel): boolean {
    let locationDevices = location.locationDevices.length;
    let updaterProjects: ProjectUpdaterDeviceViewModel[] = []
    location.updaterDevices.forEach(x => {
      updaterProjects.push(...x.projectDevices);
    });

    return locationDevices != updaterProjects.length;
  }

  subscribeUpdaters(locations: LocationUpdaterViewModel[]) {
    this.checkStateUpdates = [];
    locations.forEach(l => {
      if (l.updaterDevices != null && l.updaterDevices.length > 0) {
        l.updaterDevices.forEach(ud => {
          ud.projectDevices.forEach(d => {
            if (d.state == this._updateState.Updating || d.state == this._updateState.Starting) {
              this.checkStateUpdates.push({ locationId: l.id, projectDeviceId: d.deviceId, updaterDeviceId: ud.id } as UpdateFirmwareTriggerModel);
            }
          });
        })
      }
    });
    if (this.checkStateUpdates.length > 0) {
      if (this.intervalSubscription == null)
        this.intervalSubscription = interval(DeviceUpdateManagementTabComponent.STATE_UPDATES_RATE).subscribe(() => {
          if (this.checkStateUpdates.length > 0) {
            this._updateDeviceDataService.getUpdaterStates(this.checkStateUpdates);
          }
        });
    } else {
      if (this.intervalSubscription != null) {
        this.intervalSubscription.unsubscribe();
        this.intervalSubscription = null;
      }
    }
  }

  getFilterCount() {
    return this._projectsSelected.length + this._companiesSelected.length;
  }

  openFilterList() {
    this._isOpenFilterList = !this._isOpenFilterList;
  }

  isUpdatingState(state: UpdateProjectStateEnum): boolean {
    return state == this._updateState.Updating || state == this._updateState.Starting;
  }

  geLastUpdateDate(projectDevice: ProjectUpdaterDeviceViewModel): number {
    if (projectDevice != null && projectDevice.lastUpdate != null) {
      return projectDevice.lastUpdate * 1000;
    }
    return null;
  }

  hideEditButton(location: LocationUpdaterViewModel, updater: UpdateDeviceViewModel): boolean {
    if (location.updaterDevices.length === location.locationDevices.length) {
      return true;
    } else if (updater.projectDevices.length === 1 && location.updaterDevices.length === 2) {
      const secondUpdater = location.updaterDevices.find(u => u.id !== updater.id);
      if (secondUpdater !== null && secondUpdater.projectDevices.length === 2) {
        return true;
      }
    }
    return false;
  }

  installFirmware(locationId: number, updaterId: string, projectDeviceId: string, project: ProjectEnum) {
    let installProject = new InstallProjectModel();
    installProject.locationId = locationId;
    installProject.projectDeviceId = projectDeviceId;
    installProject.updaterDeviceId = updaterId;
    installProject.project = project;
    installProject.locationName = this._updateDeviceDataService.locationsUpdaters$.getValue().find(x => x.id == locationId).name;

    this.installProjectPopup.onShow(installProject);
  }
}