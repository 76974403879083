import { Component } from '@angular/core';
import { SensorViewModel } from '../../../../models';
import { equipmentSideLabels, sensorPositionLabels, sensorTypeLabels } from 'src/app/main/constants';

@Component({
  selector: 'greensleeves-view-sensor-popup',
  templateUrl: './view-sensor-popup.component.html',
  styles: []
})
export class ViewSensorPopupComponent {
  _isHidden = true;
  _sensor: SensorViewModel;

  get _typeLabel() {
    return sensorTypeLabels[this._sensor.type];
  }

  get _positionLabel() {
    return sensorPositionLabels[this._sensor.position];
  }

  get _sideLabel() {
    return equipmentSideLabels[this._sensor.side];
  }

  public show(sensor: SensorViewModel) {
    this._sensor = sensor;
    this._isHidden = false;
  }

  onClickClose() {
    this._isHidden = true;
    this._sensor = null;
  }
  getRateOfChangeLabel(value): string {
    return value == null ? '-' : value;
  }
}