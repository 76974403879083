import { Component, OnInit } from '@angular/core';

import { CompanyAdminViewModel } from '../../../models';
import {  CompanyAdminsDataSourceService } from '../../../services';


@Component({
  selector: 'greensleeves-company-admin-delete-popup',
  templateUrl: './company-admin-delete-popup.component.html',
  styles: []
})

export class CompanyAdminDeletePopupComponent implements OnInit {
  _isHidden = true;
  _companyAdmin: CompanyAdminViewModel;
  constructor(
    private _companyAdminsDataSourceService: CompanyAdminsDataSourceService,
    ) { }

  ngOnInit() {
  }
  
  public show(companyAdmin: CompanyAdminViewModel){
     this._isHidden = false;
     this._companyAdmin = companyAdmin;
  }

  async onSubmit() {
    const result = await this._companyAdminsDataSourceService.delete(this._companyAdmin.id, this._companyAdmin.companyId);
    if (result != null){
      this._isHidden = true;
      this._companyAdmin = null;
    }
  }

  onCancel(){
    this._isHidden = true;
    this._companyAdmin = null;
  }
}
