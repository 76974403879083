import { SensorValuesStatusEnum } from "../enums";

export class HistoricalDataTelemetryResponse {
    sensorId: number;
    telemetries: TelemetryDomain[];
    firstTelemetryTimeStamp: number;
    lastTelemetryTimeStamp: number;
}

export class TelemetryDomain {
    id: number;
    value: number;
    status: SensorValuesStatusEnum;
    timestamp: number;
}

export class HistoricalDataFilter {
    equipmentId: number;
    sensorIds: number[];
    timeStampFrom: number;
    timeStampTo: number;
}