import { Component, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { BinSchemeViewModel } from '@models';

@Component({
  selector: 'greensleeves-ktx-unit-fullscreen-popup',
  templateUrl: './ktx-unit-fullscreen-popup.component.html',
})
export class KtxUnitFullscreenPopupComponent implements OnInit {
  static NAME_FIELD = 'binWithinUnitName';
  static HEIGHT_FIELD = 'height';
  static DEPTH_FIELD = 'depth';
  static WIDTH_FIELD = 'width';
  static TYPE_FIELD = 'binType';
  static WINDOW_FUNCTION_GET_UNIT_NAME = 'getUnit';
  static WINDOW_FUNCTION_BIN_DETAILS_CLICK_NAME = 'binDetailsClick';
  static WINDOW_FUNCTION_SHOW_SCHEME_NAME = 'showScheme';

  _isSchemeHidden = true;
  _isHidden = true;
  _isSchemeLoading = true;
  _subscriptions: Subscription[] = [];
  _currentUnit: BinSchemeViewModel;

  onDetailsClick = new EventEmitter<BinSchemeViewModel>();
  onUnitDetailsClick$ = new BehaviorSubject<number>(0);
  scheme$ = new BehaviorSubject<BinSchemeViewModel>(null);
  showScheme$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  ngOnInit() {
    this._currentUnit = new BinSchemeViewModel();
    window[KtxUnitFullscreenPopupComponent.WINDOW_FUNCTION_GET_UNIT_NAME] =
      () => {
        return this.scheme$;
      };

    window[
      KtxUnitFullscreenPopupComponent.WINDOW_FUNCTION_BIN_DETAILS_CLICK_NAME
    ] = () => {
      return this.onUnitDetailsClick$;
    };

    window[KtxUnitFullscreenPopupComponent.WINDOW_FUNCTION_SHOW_SCHEME_NAME] =
      () => {
        return this.showScheme$;
      };

    const binDetailsSubscriptions = this.onUnitDetailsClick$.subscribe(
      (id: number) => {
        if (id) {
          const bin = this._currentUnit.bins.find((x) => x.id === id);
          if (!bin.isDecorBin) {
            this.onClickDetails(bin);
          }
        }
      }
    );

    this._subscriptions.push(binDetailsSubscriptions);
  }

  onClose() {
    this._currentUnit = new BinSchemeViewModel();
    this.showScheme$.next(false);
  }

  onClickDetails(scheme: BinSchemeViewModel) {
    this.onClose();
    this._isHidden = true;
    this.onDetailsClick.emit(scheme);
  }

  public show(unit: BinSchemeViewModel) {
    this._isHidden = false;
    unit.bins.forEach((bin) => (bin.binWithinUnitName = +bin.name));
    this._currentUnit = unit;
    this.scheme$.next(unit);
    this.showScheme$.next(true);
  }

  onIframeLoad() {
    this._isSchemeLoading = false;
  }
}
