import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'greensleeves-upload-cancellation-popup',
  templateUrl: './upload-cancellation-popup.component.html',
  styles: []
})
export class UploadCancellationPopupComponent implements OnInit {
  @Output()
  onClose = new EventEmitter<number>();

  _equipmentIndex: number;
  _isHidden = true;

  _submitted = false;
  _error: string;

  constructor(
  ) { }

  ngOnInit() {
  }

  public show() {
    this._isHidden = false;
  }

  async onSubmit() {
    this._error = null;
    this._isHidden = true;
    this.onClose.next();
  }

  onClickCancel() {
    this._error = null;
    this._isHidden = true;
  }
}