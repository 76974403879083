import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { _isNumberValue } from '@angular/cdk/coercion';

import { AqsAirFlowDataViewModel, AqsFlatDuctModel, AqsFlatDuctSideParamsModel, AqsSelectedFanModel, FlatStorageParams } from '@models';
import { AqsAirflowFanTypeEnum, AqsFlatDuctingShapeTypeEnum, AqsFlatDuctCallMethodsBaseOnEntity, AqsFlatDuctSideNo } from '../../../enums';
import { AqsFlatDuctingShapesConstant } from '../../../constants';
import { AqsFlatDuctViewTabComponent } from './aqs-flat-duct-view-tab';

@Component({
  selector: 'greensleeves-aqs-flat-duct-design',
  templateUrl: './aqs-flat-duct-design.component.html',
  styleUrls: []
})
export class AqsFlatDuctDesignComponent implements OnInit, AfterViewInit {
  @Output() onChangedFlatDuctModel = new EventEmitter();

  @Input() _flatDuctModel: AqsFlatDuctModel;
  @Input() _flatStorage: FlatStorageParams;
  @Input() airflowData: AqsAirFlowDataViewModel[];
  @Input() numberFans: number;
  @Input() fanTypeModel: AqsAirflowFanTypeEnum;
  @Input() selectedFan: AqsSelectedFanModel;

  @ViewChild(AqsFlatDuctViewTabComponent, { read: false, static: false })
  private _drawDuct: AqsFlatDuctViewTabComponent;

  private oldFlatDuctModel: AqsFlatDuctModel;

  _ductDiameter: string;
  _openingDiameter: string;
  _nearCtrCtr: string;
  _farCtrCtr: string;
  _frontCtrCtr: string;
  _rearCtrCtr: string;
  _nearPerf: string;
  _farPerf: string;
  _frontPerf: string;
  _rearPerf: string;
  _nearSolid: string;
  _farSolid: string;
  _frontSolid: string;
  _rearSolid: string;
  _flatDuctShapes: { label: string, value: AqsFlatDuctingShapeTypeEnum }[] = [];
  _ductTable: AqsFlatDuctSideParamsModel[];
  oldDuctTable: AqsFlatDuctSideParamsModel[];
  largest: number; //LARGEST
  lowerLarge: number; //LOWERLARGE
  crossSec: number[][]; //CROSSSEC
  cfrfr: number[][]; //CFRFR
  cfrhr: number[][]; //CFRHR
  _maxCrossSectionalVelocity: number; //MCSV
  _maxSurfaceVelocity: number; //MSV
  _actualCrossSectionalVelocity: number; //CSV
  _actualSurfaceVelocity: number; //SURFACE
  recPerf: number[] = [0,0,0,0]; //RECPERF
  surface: number;

  constructor() { }

  ngOnInit() {
    for (const [key, value] of Object.entries(AqsFlatDuctingShapesConstant)) {
      this._flatDuctShapes.push({ label: value, value: Number(key)});
    }

    this.oldFlatDuctModel = new AqsFlatDuctModel();
    this.crossSec = [
      [-1,24,30,36,40,48,60,72,84,96,120],
      [7.25,.9063,1.2083,1.5104,1.7118,2.1146,2.7188,3.3229,3.9271,4.5313,5.7396],
      [11.25,1.4063,1.8750,2.3438,2.6563,3.2813,4.2188,5.1563,6.0938,7.0313,8.9063]
    ];
    this.cfrfr = [
      [12,2.566],
      [14,2.9],
      [16,3.3],
      [18,3.9],
      [20,4.233],
      [24,5.2],
      [30,6.4],
      [36,7.666]
    ];
    this.cfrhr = [
      [15,1.963],
      [19,2.5],
      [25.5,3.338],
      [30,3.927],
      [36,4.712],
      [38,5],
      [42,5.5],
      [51,6.67]
    ];
  }

   ngAfterViewInit() {
    this.fillDuctTable();
    this.onCalculateLargestAndLowerLarge();
    setTimeout(() => {
      if ((this._flatDuctModel.id === undefined || this._flatDuctModel.id == 0) && this._checkForEmptyUserFieldsDiameters()) {
        this.onCalculateRecommendAll();
        this.onConvertRecommendInputAll();
      } else {
        this._copyAllDataToOldModel();
        this.onCalculateAllSaved();
        this._copyAllFromOldModel();
        this.onConvertInputAll();
      }
    });
   }

   private _checkForEmptyUserFieldsDiameters(): boolean {
    return (
      (this._flatDuctModel.ductDiameter == undefined || this._flatDuctModel.ductDiameter == 0) &&
      (this._flatDuctModel.openingDiameter == undefined || this._flatDuctModel.openingDiameter == 0) &&
      this.oldFlatDuctModel.ductDiameter == undefined &&
      this.oldFlatDuctModel.openingDiameter == undefined
    )
   }

  private _copyAllFromOldModel() {
    this._flatDuctModel.ductDiameter = this.oldFlatDuctModel.ductDiameter;
    this._flatDuctModel.ductType = this.oldFlatDuctModel.ductType;
    this._flatDuctModel.openingDiameter = this.oldFlatDuctModel.openingDiameter;

    this._copyAllDataAqsFlatDuctSideType(this.oldFlatDuctModel.far, this._flatDuctModel.far);
    this._copyAllDataAqsFlatDuctSideType(this.oldFlatDuctModel.near, this._flatDuctModel.near);
    this._copyAllDataAqsFlatDuctSideType(this.oldFlatDuctModel.front, this._flatDuctModel.front);
    this._copyAllDataAqsFlatDuctSideType(this.oldFlatDuctModel.rear, this._flatDuctModel.rear);
  }

  //FDUCT 1030
  onCalculateLargestAndLowerLarge() {
    let j: number;
    for (j = 0; j < this.airflowData.length; j++) {
      if (this.airflowData[j].kcfm !== -1) {
        this.largest = j;
        break;
      }
    }

    for (let i = j + 1; i < this.airflowData.length; i++) {
      if (this.airflowData[i].kcfm > this.airflowData[this.largest].kcfm) {
        this.largest = i;
      }
    }

    this.lowerLarge = this.airflowData[this.largest].kcfm * this.numberFans * 1000;
  }

  //#region FDUCT GOSUB 8000:SCREEN 2:CLS 'recommendations

  onCalculateRecommendAll() {
    this.fillDuctTable();
    this.onCalculateRecommendDuctDia();
    this.onCalculateRecommendNumberFansEachSide();
    this.onCalculateRecommendCenterToCenter();
    this.onCalculateRecommendPerf();
    this.findDistanceToBeginDucts();
    this.findIdealAndMaxDuctLenghts();
    this.onCalculateRecommendInchesOfPerf();
    this.onCalculateRecommendSolid();
    this.onCalculateVelocities();
  }

  onCalculateAllSaved() {
    this.fillDuctTable();
    this.onCalculateRecommendDuctDia();
    this.onCalculateDuctDiaFromUserValues();
    this.onCalculateRecommendNumberFansEachSide();
    this.onCalculateRecommendCenterToCenter();
    this.onCalculateRecommendPerf();
    this.findDistanceToBeginDucts();
    this.findIdealAndMaxDuctLenghts();
    this.onCalculateRecommendInchesOfPerf();
    this.onCalculateRecommendSolid();
    this.calculateVelocitiesFromSavedmodel();
  }

  //FDUCT 8020 'recommend ductdia *********
  onCalculateRecommendDuctDia() {
    if (this.fanTypeModel === AqsAirflowFanTypeEnum.Axial) {
      this._maxCrossSectionalVelocity = 3000;
      this._maxSurfaceVelocity = 40;
    } else {
      this._maxCrossSectionalVelocity = 3500;
      this._maxSurfaceVelocity = 45;
    }

    if (
      this.fanTypeModel === AqsAirflowFanTypeEnum.Centrifugal60HZ &&
      this.selectedFan.fanName !== '2003' &&
      this.selectedFan.fanName !== '2205' &&
      this.selectedFan.fanName !== '2250'
      ) {
        this._maxCrossSectionalVelocity = 4000;
        this._maxSurfaceVelocity = 50;
    }

    let minimalCSA = this.lowerLarge / (this._maxCrossSectionalVelocity * this.numberFans);
    this.surface = 0
    if (this._flatDuctModel.ductType === AqsFlatDuctingShapeTypeEnum.HalfRound) {
      let firstIf = false;
      for (let i = 0; i <= 7; i++) {
        if ((Math.pow((this.cfrhr[i][0] / 24), 2) * Math.PI / 2) >= minimalCSA) {
          this._flatDuctModel.ductDiameter = this.cfrhr[i][0];
          this.surface = this.cfrhr[i][1];
          firstIf = true;
          break;
        }
      }
      if (!firstIf) {
        this._flatDuctModel.ductDiameter = this.cfrhr[7][0];
        this.surface = this.cfrhr[7][0];
      }
    } else {
      let secondif = false;
      for (let i = 0; i <= 7; i++) {
        if ((Math.pow((this.cfrfr[i][0] / 24), 2) * Math.PI) >= minimalCSA) {
          this._flatDuctModel.ductDiameter = this.cfrfr[i][0];
          this.surface = this.cfrfr[i][1];
          secondif = true;
          break;
        }
      }
      if (!secondif) {
        this._flatDuctModel.ductDiameter = this.cfrfr[7][0];
        this.surface = this.cfrfr[7][1];
      }
    }

    this._flatDuctModel.openingDiameter = this._flatDuctModel.ductDiameter; //'RECOMMEND OPEN DIA **********
    this._ductDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.ductDiameter);
    this._openingDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.openingDiameter);
  }

  onCalculateDuctDiaFromUserValues() {
      if (this.oldFlatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.FullRound) {
        for (let index = 0; index < this.cfrfr.length; index++) {
          if (this.cfrfr[index][0] == this.oldFlatDuctModel.ductDiameter) {
            this.surface = this.cfrfr[index][1];
            break;
          }
        }
      } else {
        for (let index = 0; index < this.cfrhr.length; index++) {
          if (this.cfrhr[index][0] == this.oldFlatDuctModel.ductDiameter) {
            this.surface = this.cfrhr[index][1];
            break;
          }
        }
      }
  }

  //FDUCT 8111 'RECOMMEND NUMBER FANS EA SIDE **********
  onCalculateRecommendNumberFansEachSide() {
    for (let k = 0; k <= 3; k++) {
      this._ductTable[k].numberFansPerSide = 0;
      this._saveSideParamArrayToType(this._ductTable[k]);
    }

    if (this._flatStorage.width > 120) {
      this._flatDuctModel.far.numberFansPerSide = Math.floor(this.numberFans / 2);
      this._flatDuctModel.near.numberFansPerSide = this.numberFans - this._flatDuctModel.far.numberFansPerSide;
    } else {
      this._flatDuctModel.near.numberFansPerSide = this.numberFans;
    }
  }

  //FDUCT 8114 'RECOMMEND CENTER TO CENTER ***************
  onCalculateRecommendCenterToCenter() {
    for (let k = 0; k <= 3; k++) {
      this._ductTable[k].ctroctr = 0;
      this._saveSideParamArrayToType(this._ductTable[k]);
    }

    for (let k = 0; k <= 3; k++) {
      if (this._ductTable[k].numberFansPerSide != 1 && this._ductTable[k].numberFansPerSide != 0) {
        if (k < 2) {
          this._ductTable[k].ctroctr = this._flatStorage.lenght * 12 / this._ductTable[k].numberFansPerSide;
        } else {
          this._ductTable[k].ctroctr = this._flatStorage.width * 12 / this._ductTable[k].numberFansPerSide;
        }
        this._saveSideParamArrayToType(this._ductTable[k]);
      }
    }

    for (let k = 0; k <= 3; k++) {
      this._saveSideParamArrayToType(this._ductTable[k]);
    }

    this.saveOutputCtrValues();
  }

  //FDUCT 8126 'recommend perf/run ***************
  onCalculateRecommendPerf() {
    this._actualSurfaceVelocity = this.lowerLarge / (Math.pow((this._flatDuctModel.openingDiameter / 24), 2) * Math.PI * this.numberFans);
    let recPerf = ((this.lowerLarge / this.numberFans) / this._maxSurfaceVelocity / this.surface)  * 12;
    let temp = recPerf;
    recPerf = Math.floor(Math.floor(recPerf / 24) * 24 + 0.5);
    if (recPerf < temp) {
      recPerf += 24;
    }

    for ( let i = 0; i <= 3; i++) {
      this.recPerf[i] = recPerf;
    }
  }

  //FDUCT 8162 'find distances to begin ducts
  findDistanceToBeginDucts() {
    let sideParametr = 0;
    for (let sideno = 0; sideno <= 3; sideno++) {
      if (sideno < 2) {
        sideParametr = this._flatStorage.lenght
      } else {
        sideParametr = this._flatStorage.width
      }

      this._ductTable[sideno].distanceFromEdge =
      sideParametr * 6
      - (this._ductTable[sideno].numberFansPerSide - 1)  * this._ductTable[sideno].ctroctr / 2;
    }
  }

  //FDUCT 8167 'find ideal & maximum duct lengths
  findIdealAndMaxDuctLenghts() {
    let intersect = 0;
    let max = 0;
    let ideal = 0;

    if (
      this._flatDuctModel.near.numberFansPerSide + this._flatDuctModel.far.numberFansPerSide != 0 &&
      this._flatDuctModel.front.numberFansPerSide + this._flatDuctModel.rear.numberFansPerSide != 0) {
        intersect = 1;
    }

    let union = [0,0];

    for (let sideno = 0; sideno <= 2; sideno+=2) {
      let isUnionEqualsOne = false;

      for (let i = 0; i < this._ductTable[sideno].numberFansPerSide; i++) {
        for (let j = 0; j < this._ductTable[sideno + 1].numberFansPerSide; j++) {
          if (Math.abs(
              (this._ductTable[sideno].distanceFromEdge + i * this._ductTable[sideno].ctroctr)
              - (this._ductTable[sideno + 1].distanceFromEdge + j * this._ductTable[sideno + 1].ctroctr))
              < this._flatDuctModel.ductDiameter) {
                union[sideno / 2] = 1;
                isUnionEqualsOne = true;
            }

          if (isUnionEqualsOne) {
            break;
          }
        }

        if (isUnionEqualsOne) {
          break;
        }
      }
    }

    for (let sideno = 0; sideno <= 3; sideno++) {
      let distance = this._flatStorage.lenght;
      if (sideno < 2) {
        distance = this._flatStorage.width
      }

      if (union[Math.floor(sideno / 2)] == 1) {
        max = distance * 6;
        ideal = distance * 6;
      } else {
        max = distance * 12;
        ideal = (distance * 12 - this.recPerf[sideno]) / 2 + this.recPerf[sideno];
      }

      max = Math.floor(Math.floor(max / 24) * 24 + 0.5);
      ideal = Math.floor(Math.floor(ideal / 24) * 24 + 0.5);

      this._ductTable[sideno].maxDuctLenght = max;
      this._ductTable[sideno].idealDuctLenght = ideal;
    }

    if (intersect == 1) {
      if (this._flatDuctModel.near.numberFansPerSide == 0) {
        max = this._flatDuctModel.far.distanceFromEdge - this._flatDuctModel.ductDiameter / 2;
      }
      else if (this._flatDuctModel.far.numberFansPerSide == 0) {
        max = this._flatDuctModel.near.distanceFromEdge - this._flatDuctModel.ductDiameter / 2;
      }
      else if (this._flatDuctModel.near.distanceFromEdge < this._flatDuctModel.far.distanceFromEdge) {
        max = this._flatDuctModel.near.distanceFromEdge - this._flatDuctModel.ductDiameter / 2;
      }
      else {
        max = this._flatDuctModel.far.distanceFromEdge - this._flatDuctModel.ductDiameter / 2;
      }
    }

    max = Math.floor(Math.floor(max / 24) * 24 + 0.5);

    this._flatDuctModel.front.maxDuctLenght = max;
    this._flatDuctModel.rear.maxDuctLenght = max;
  }

  //FDUCT 8192
  onCalculateRecommendInchesOfPerf() {
    for (let sideno = 0; sideno <= 3; sideno++) {
      if (this._ductTable[sideno].maxDuctLenght >= this.recPerf[sideno]) {
        this._ductTable[sideno].perfPerRun = this.recPerf[sideno]
      }
      else  if (this._ductTable[sideno].maxDuctLenght < this.recPerf[sideno]) {
        this._ductTable[sideno].perfPerRun = this._ductTable[sideno].maxDuctLenght;
      }
      else {
        this._ductTable[sideno].perfPerRun = 0;
      }
    }

    this.saveOutputPerfValues();
  }

  //FDUCT 8190 'recommend inches of perf ************
  onCalculateRecommendSolid() {
    for (let sideno = 0; sideno <= 3; sideno++) {
      this._ductTable[sideno].solidPerRun = this._ductTable[sideno].idealDuctLenght - this._ductTable[sideno].perfPerRun;

      if (this._ductTable[sideno].solidPerRun + this._ductTable[sideno].perfPerRun > this._ductTable[sideno].maxDuctLenght) {
        this._ductTable[sideno].solidPerRun = this._ductTable[sideno].maxDuctLenght - this._ductTable[sideno].perfPerRun;
      }
    }

    this.saveOutputSolidValues();
  }

  //#endregion FDUCT GOSUB 8000:SCREEN 2:CLS 'recommendations

  //#region FDUCT GOSUB 7000: 'VELOCITIES

  //7000 'velocities **********************************************************
  onCalculateVelocities() {
    if (
    this._flatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.HalfRound &&
    Math.PI * Math.pow(this._flatDuctModel.openingDiameter, 2) <= Math.PI / 2 * Math.pow(this._flatDuctModel.ductDiameter, 2)
    ) {
      this._actualCrossSectionalVelocity = this.lowerLarge / (Math.pow(this._flatDuctModel.openingDiameter / 24, 2) * Math.PI * this.numberFans);
      //7060 IF DUCTTYPE=2 THEN IF PI*OPENDIA^2<=(PI/2*DUCTDIA^2) THEN CSV=LOWERLARGE/((OPENDIA/24)^2*PI*NUMBERFANS):GOTO 7100
    }
    else if (
    this._flatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.FullRound &&
    this._flatDuctModel.openingDiameter <= this._flatDuctModel.ductDiameter
    ) {
      this._actualCrossSectionalVelocity = this.lowerLarge / (Math.pow(this._flatDuctModel.openingDiameter / 24, 2) * Math.PI * this.numberFans);
      //7070 IF DUCTTYPE=1 THEN IF OPENDIA<=DUCTDIA THEN CSV=LOWERLARGE/((OPENDIA/24)^2*PI*NUMBERFANS):GOTO 7100
    }
    else if (this._flatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.FullRound) {
      this._actualCrossSectionalVelocity = this.lowerLarge / (Math.pow(this._flatDuctModel.ductDiameter / 24, 2) * Math.PI * this.numberFans);
      //7080 IF DUCTTYPE=1 THEN CSV=LOWERLARGE/((DUCTDIA/24)^2*PI*NUMBERFANS):GOTO 7100
    }
    else if (this._flatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.HalfRound) {
      this._actualCrossSectionalVelocity = this.lowerLarge / (Math.pow(this._flatDuctModel.ductDiameter / 24, 2) * Math.PI / 2 * this.numberFans);
      //7090 IF DUCTTYPE=2 THEN CSV=LOWERLARGE/((DUCTDIA/24)^2*(PI/2)*NUMBERFANS)
    }

    this._actualCrossSectionalVelocity = Math.floor(this._actualCrossSectionalVelocity + .5);
    //7100 CSV=INT(CSV+.5)

    this._actualSurfaceVelocity = (this.lowerLarge / this.numberFans) / this.surface;
    //SV=(LOWERLARGE/NUMBERFANS)/SURFACE

    let ftPerforated = 0;
    for (let i = 0; i <= 3; i++) {
      if (
      this._ductTable[i].numberFansPerSide != 0
      &&
      (this._ductTable[i].perfPerRun / 12  < ftPerforated
      || ftPerforated == 0)
      ) {
        ftPerforated = this._ductTable[i].perfPerRun / 12;
        //7117 IF DUCT(PERFPERRUN,I)/12<FTPERFORATED OR FTPERFORATED=0 THEN FTPERFORATED=DUCT(PERFPERRUN,I)/12
      }
    }

    this._actualSurfaceVelocity = Math.floor(this._actualSurfaceVelocity / ftPerforated * 10 + .5) / 10;
    //7120 SV=INT(SV/FTPERFORATED*10+.5)/10
  }
  //#endregion FDUCT GOSUB 7000: 'VELOCITIES

  //#region input logic **********************************************************

  onConvertRecommendInputAll() {
    this._ductDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.ductDiameter);
    this._openingDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.openingDiameter);
    this.onChangeNearFans();
    this.onChangeFarFans();
    this.onChangeFrontFans();
    this.onChangeRearFans();

    this.saveOutputCtrValues();
    this.saveOutputPerfValues();
    this.saveOutputSolidValues();
  }

  onConvertInputAll() {
    this._ductDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.ductDiameter);
    this._openingDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.openingDiameter);
    this._copyFromNewModelToOldNumberFans();
    this.saveOutputCtrValues();
    this.saveOutputPerfValues();
    this.saveOutputSolidValues();
    this.drawDuct();
  }

  private saveOutputCtrValues() {
    this._nearCtrCtr = this.convertInchesToCtrFormat(
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.near.ctroctr),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.near.numberFansPerSide)
    );
    this._farCtrCtr = this.convertInchesToCtrFormat(
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.far.ctroctr),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.far.numberFansPerSide)
    );
    this._frontCtrCtr = this.convertInchesToCtrFormat(
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.front.ctroctr),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.front.numberFansPerSide)
    );
    this._rearCtrCtr = this.convertInchesToCtrFormat(
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.rear.ctroctr),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.rear.numberFansPerSide)
    );
  }

  private saveOutputPerfValues() {
    this._nearPerf = this._convertNumberToRunFormat(
      this._getRoundToZeroDecimalPlaces(this._convertInchesToFeet(this._flatDuctModel.near.perfPerRun)),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.near.numberFansPerSide)
    );
    this._farPerf = this._convertNumberToRunFormat(
      this._getRoundToZeroDecimalPlaces(this._convertInchesToFeet(this._flatDuctModel.far.perfPerRun)),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.far.numberFansPerSide)
    );
    this._frontPerf = this._convertNumberToRunFormat(
      this._getRoundToZeroDecimalPlaces(this._convertInchesToFeet(this._flatDuctModel.front.perfPerRun)),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.front.numberFansPerSide)
    );
    this._rearPerf = this._convertNumberToRunFormat(
      this._getRoundToZeroDecimalPlaces(this._convertInchesToFeet(this._flatDuctModel.rear.perfPerRun)),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.rear.numberFansPerSide)
    );
  }

  private saveOutputSolidValues() {
    this._nearSolid = this._convertNumberToRunFormat(
      this._getRoundToZeroDecimalPlaces(this._convertInchesToFeet(this._flatDuctModel.near.solidPerRun)),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.near.numberFansPerSide)
    );
    this._farSolid = this._convertNumberToRunFormat(
      this._getRoundToZeroDecimalPlaces(this._convertInchesToFeet(this._flatDuctModel.far.solidPerRun)),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.far.numberFansPerSide)
    );
    this._frontSolid = this._convertNumberToRunFormat(
      this._getRoundToZeroDecimalPlaces(this._convertInchesToFeet(this._flatDuctModel.front.solidPerRun)),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.front.numberFansPerSide)
    );
    this._rearSolid = this._convertNumberToRunFormat(
      this._getRoundToZeroDecimalPlaces(this._convertInchesToFeet(this._flatDuctModel.rear.solidPerRun)),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.rear.numberFansPerSide)
    );
  }

  private onChangeEntityCallNeededMethods(entityType: AqsFlatDuctCallMethodsBaseOnEntity) {
    switch (entityType) {
      case AqsFlatDuctCallMethodsBaseOnEntity.DuctDiameter:
        this.onCalculateRecommendNumberFansEachSide();
        this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.NumberFans);
        break;
      case AqsFlatDuctCallMethodsBaseOnEntity.NumberFans:
        this.onCalculateRecommendCenterToCenter();
        this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.CtrCtr);
        break;
      case AqsFlatDuctCallMethodsBaseOnEntity.CtrCtr:
        this.onCalculateRecommendPerf();
        this.findDistanceToBeginDucts();
        this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.PerfRun);
        break;
      case AqsFlatDuctCallMethodsBaseOnEntity.PerfRun:
        this.findIdealAndMaxDuctLenghts();
        this.onCalculateRecommendInchesOfPerf();
        this.onCalculateRecommendSolid();
        this.onCalculateVelocities();
        break;
    }
  }

  private fillDuctTableOld() {
    this.oldDuctTable = [];
    this.oldDuctTable.push(this.oldFlatDuctModel.near);
    this.oldDuctTable.push(this.oldFlatDuctModel.far);
    this.oldDuctTable.push(this.oldFlatDuctModel.front);
    this.oldDuctTable.push(this.oldFlatDuctModel.rear);
  }

  private fillDuctTable() {
    this._ductTable = [];
    this._ductTable.push(this._flatDuctModel.near);
    this._ductTable.push(this._flatDuctModel.far);
    this._ductTable.push(this._flatDuctModel.front);
    this._ductTable.push(this._flatDuctModel.rear);
  }

  private drawDuct() {
    this._drawDuct.clearView();
    this._drawDuct.drawDuct();
  }

  onChangeDuctType() {
    this.onCalculateRecommendDuctDia();
    this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.DuctDiameter);
    this.drawDuct();
  }

  //30213 'DUCT DIA ALTERED *********
  onChangeDuctDiameter() {
    if (this._ductDiameter === undefined || this._ductDiameter == '') {
      this._ductDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.ductDiameter);
    }

    let ductDiaStringNumber = this._ductDiameter.replace(/[^0-9.]/g, '');
    if (ductDiaStringNumber === undefined || ductDiaStringNumber == '') {
      this._ductDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.ductDiameter);
    }

    let integerValue = ductDiaStringNumber.split('.')[0];
    let fractionalValue = ductDiaStringNumber.split('.')[1];
    if (integerValue !== undefined && integerValue !== '') {
      if (fractionalValue !== undefined && fractionalValue != '') {
        fractionalValue = fractionalValue.replace(/[^0-9]/g,'');
        ductDiaStringNumber = integerValue + '.' + fractionalValue;
      } else {
        ductDiaStringNumber = integerValue;
      }
    } else {
      ductDiaStringNumber = '0';
    }

    this._flatDuctModel.ductDiameter = Number(ductDiaStringNumber);
    if (this._flatDuctModel.ductType === AqsFlatDuctingShapeTypeEnum.FullRound) {
      let checkDuctDia = false;
      let i: number;
      for (i = 0; i <= 7; i++) {
        if (this.cfrfr[i][0] >= this._flatDuctModel.ductDiameter) {
          this._flatDuctModel.ductDiameter = this.cfrfr[i][0];
          this.surface = this.cfrfr[i][1];
          checkDuctDia = true;
          break;
        }
      }

      i = 7;
      if (!checkDuctDia) {
        this._flatDuctModel.ductDiameter = this.cfrfr[i][0];
        this.surface = this.cfrfr[i][1];
      }
    }
    else {
      let checkDuctDia = false;
      let i: number;
      for (i = 0; i <= 7; i++) {
        if (this.cfrhr[i][0] >= this._flatDuctModel.ductDiameter) {
          this._flatDuctModel.ductDiameter = this.cfrhr[i][0];
          this.surface = this.cfrhr[i][1];
          checkDuctDia = true;
          break;
        }
      }

      i = 7;
      if (!checkDuctDia) {
        this._flatDuctModel.ductDiameter = this.cfrhr[i][0];
        this.surface = this.cfrhr[i][1];
      }
    }

    this.changeDuctDiametr();

    //logic for changes
    this._flatDuctModel.openingDiameter = this._flatDuctModel.ductDiameter;
    this.changeOpeningDiameter();
    this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.DuctDiameter);
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  private changeDuctDiametr() {
    this.oldFlatDuctModel.ductDiameter = this._flatDuctModel.ductDiameter;
    this._ductDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.ductDiameter);
  }

  //30250 'OPENDIA ALTERED ***********
  onChangeOpeningDiameter() {
    if (this._openingDiameter === undefined || this._openingDiameter === '') {
      this._openingDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.openingDiameter);
    }

    let openDiaStringNumber = this._openingDiameter.replace(/[^0-9.]/g, '');
    if (openDiaStringNumber === undefined || openDiaStringNumber == '') {
      this._openingDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.openingDiameter);
    }

    let integerValue = openDiaStringNumber.split('.')[0];
    let fractionalValue = openDiaStringNumber.split('.')[1];
    if (integerValue !== undefined && integerValue !== '') {
      if (fractionalValue !== undefined && fractionalValue != '') {
        fractionalValue = fractionalValue.replace(/[^0-9]/g,'');
        openDiaStringNumber = integerValue + '.' + fractionalValue;
      } else {
        openDiaStringNumber = integerValue;
      }
    } else {
      openDiaStringNumber = '0';
    }

    this._flatDuctModel.openingDiameter = Number(openDiaStringNumber);

    if (this._flatDuctModel.openingDiameter < 6 || this._flatDuctModel.openingDiameter > 80){
      this._flatDuctModel.openingDiameter = this.oldFlatDuctModel.openingDiameter;
    }

    this.changeOpeningDiameter();
    //logic for changes
    this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.DuctDiameter);
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  private changeOpeningDiameter() {
    this.oldFlatDuctModel.openingDiameter = this._flatDuctModel.openingDiameter;
    this._openingDiameter = this._convertToDoubleQuoteString(this._flatDuctModel.openingDiameter);
  }

  //30301 '# FANS EA SIDE ALTERED ************
  onChangeNearFans() {
    if (
    this._flatDuctModel.near.numberFansPerSide < 0
    || this._flatDuctModel.near.numberFansPerSide > this.numberFans
    || this._flatDuctModel.near.numberFansPerSide == this.oldFlatDuctModel.near.numberFansPerSide
    ) {
      this._flatDuctModel.near.numberFansPerSide = this.oldFlatDuctModel.near.numberFansPerSide;
    } else {
      let original = this.oldFlatDuctModel.near.numberFansPerSide;
      let temp = this._flatDuctModel.near.numberFansPerSide;
      let makeup = this.numberFans - this._flatDuctModel.near.numberFansPerSide;

      for (let i = 1; i <= 3; i++) {
        this._ductTable[i].numberFansPerSide = 0;
        this._saveSideParamArrayToType(this._ductTable[i]);
      }

      if (makeup != 0) {
        if (temp <= makeup) {
          this._flatDuctModel.far.numberFansPerSide = temp;
          makeup -= temp;
        } else {
          this._flatDuctModel.far.numberFansPerSide = makeup;
          makeup = 0;
        }

        if (makeup != 0) {
          temp = Math.round(makeup / 2);
          this._flatDuctModel.front.numberFansPerSide = temp;
          makeup -= temp;

          if (makeup != 0) {
            this._flatDuctModel.rear.numberFansPerSide = makeup;
          }
        }
      }

      this._copyFromNewModelToOldNumberFans();

      //logic for changes
      this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.NumberFans);
      this.drawDuct();
      this.changeFlatDuctModel();
    }
  }

  //30320 IF SIDENO<>FARSIDE THEN 30340
  onChangeFarFans() {
    if (
      this._flatDuctModel.far.numberFansPerSide < 0
      || this._flatDuctModel.far.numberFansPerSide > this.numberFans
      || this._flatDuctModel.far.numberFansPerSide == this.oldFlatDuctModel.far.numberFansPerSide
      ) {
        this._flatDuctModel.far.numberFansPerSide = this.oldFlatDuctModel.far.numberFansPerSide;
      } else {
      let original = this.oldFlatDuctModel.far.numberFansPerSide;
      let temp = this._flatDuctModel.far.numberFansPerSide;
      let makeup = this.numberFans - this._flatDuctModel.far.numberFansPerSide;

      for (let i = 2; i <= 3; i++) {
        this._ductTable[i].numberFansPerSide = 0;
        this._saveSideParamArrayToType(this._ductTable[i]);
      }

      if (makeup < this._flatDuctModel.near.numberFansPerSide) {
        this._flatDuctModel.near.numberFansPerSide = makeup;
      } else {
        makeup -= this._flatDuctModel.near.numberFansPerSide;
        temp = Math.round(makeup / 2);
        this._flatDuctModel.front.numberFansPerSide = temp;
        makeup -= temp;

        if (makeup != 0) {
          this._flatDuctModel.rear.numberFansPerSide = makeup;
        }
      }
      this._copyFromNewModelToOldNumberFans();

      //logic for changes
      this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.NumberFans);
      this.drawDuct();
      this.changeFlatDuctModel();
    }
  }

  //30340 IF SIDENO<>FRONTSIDE THEN 30360
  onChangeFrontFans() {
    if (
      this._flatDuctModel.front.numberFansPerSide < 0
      || this._flatDuctModel.front.numberFansPerSide > this.numberFans
      || this._flatDuctModel.front.numberFansPerSide == this.oldFlatDuctModel.front.numberFansPerSide
      ) {
        this._flatDuctModel.front.numberFansPerSide = this.oldFlatDuctModel.front.numberFansPerSide;
      } else {
      let original = this.oldFlatDuctModel.front.numberFansPerSide;
      let temp = this._flatDuctModel.front.numberFansPerSide;
      let makeup = this.numberFans - this._flatDuctModel.front.numberFansPerSide;

      this._flatDuctModel.front.numberFansPerSide = temp;
      this._flatDuctModel.rear.numberFansPerSide = 0;

      if (makeup >= this._flatDuctModel.near.numberFansPerSide + this._flatDuctModel.far.numberFansPerSide) {
        makeup -= this._flatDuctModel.near.numberFansPerSide + this._flatDuctModel.far.numberFansPerSide
        this._flatDuctModel.rear.numberFansPerSide = makeup;
      } else {
        for (let i = 0; i <= 1; i++) {
          this._ductTable[i].numberFansPerSide = 0;
          this._saveSideParamArrayToType(this._ductTable[i]);
        }

        if (makeup != 0) {
          if (temp <= makeup) {
            this._flatDuctModel.rear.numberFansPerSide = temp;
            makeup -= temp;
          } else {
            this._flatDuctModel.rear.numberFansPerSide = makeup;
            makeup = 0;
          }

          if (makeup != 0) {
            temp = Math.round(makeup / 2);
            this._flatDuctModel.near.numberFansPerSide = temp;
            makeup = makeup - temp;

            if (makeup != 0) {
              this._flatDuctModel.far.numberFansPerSide = makeup;
            }
          }
        }
      }

      this._copyFromNewModelToOldNumberFans();

      //logic for changes
      this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.NumberFans);
      this.drawDuct();
      this.changeFlatDuctModel();
    }
  }

  //30360 'UPDATE REARSIDE
  onChangeRearFans() {
    if (
      this._flatDuctModel.rear.numberFansPerSide < 0
      || this._flatDuctModel.rear.numberFansPerSide > this.numberFans
      || this._flatDuctModel.rear.numberFansPerSide == this.oldFlatDuctModel.rear.numberFansPerSide
      ) {
        this._flatDuctModel.rear.numberFansPerSide = this.oldFlatDuctModel.rear.numberFansPerSide;
      } else {
      let original = this.oldFlatDuctModel.rear.numberFansPerSide;
      let temp = this._flatDuctModel.rear.numberFansPerSide;
      let makeup = this.numberFans - this._flatDuctModel.rear.numberFansPerSide;

      for (let i = 0; i <= 2; i++) {
        this._ductTable[i].numberFansPerSide = 0;
      }

      if (makeup != 0) {
        if (temp <= makeup) {
          this._flatDuctModel.rear.numberFansPerSide = temp;
          makeup -= temp;
        } else {
          this._flatDuctModel.rear.numberFansPerSide = makeup;
          makeup = 0;
        }

        if (makeup != 0) {
          temp = Math.round(makeup / 2);
          this._flatDuctModel.near.numberFansPerSide = temp;
          makeup -= temp;

          if (makeup != 0) {
            this._flatDuctModel.near.numberFansPerSide = makeup;
          }
        }
      }
      this._copyFromNewModelToOldNumberFans();

      //logic for changes
      this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.NumberFans);
      this.drawDuct();
      this.changeFlatDuctModel();
    }
  }

  //30401 'CTR TO CTR ALTERED************
  private onChangeAnyCtr(stringValue: string, sideParams: AqsFlatDuctSideParamsModel, oldSideParams: AqsFlatDuctSideParamsModel, sideNo: AqsFlatDuctSideNo) {
    if (sideParams.numberFansPerSide > 1) {
      let maxCtrtOctr = 0;
      if (sideNo < 2) {
        maxCtrtOctr = this._flatStorage.lenght * 12;
      } else {
        maxCtrtOctr = this._flatStorage.width * 12;
      }

      maxCtrtOctr = (maxCtrtOctr - this._flatDuctModel.ductDiameter) / (sideParams.numberFansPerSide - 1);
      let minCtrtOctr = this._flatDuctModel.ductDiameter;
      stringValue = stringValue.replace(/[^0-9.-]/g, '');
      let feetValue = stringValue.split('-')[0];
      let feetValueMainPart = feetValue.split('.')[0];
      let feetValueSecondPart = feetValue.split('.')[1];
      let inchesValue = stringValue.split('-')[1];
      if (inchesValue === undefined || inchesValue === '') {
        inchesValue = '0';
      }

      inchesValue = inchesValue.split('-')[0];
      if (inchesValue === undefined || inchesValue === '') {
        inchesValue = '0';
      }

      let inchesNumberValue = Math.floor(Number(inchesValue) + 0.5);
      let inches = 0;
      if (feetValueMainPart !== undefined) {
        inches = this._convertFeetToInches(Number(feetValueMainPart));

        if (feetValueSecondPart !== undefined && feetValueSecondPart !== '') {
          inchesNumberValue += Math.floor(Number('0.' + feetValueSecondPart) * 12 + 0.5);
        }

        inches += inchesNumberValue;
      }

      if (inches >= minCtrtOctr && inches <= maxCtrtOctr && inches !== sideParams.ctroctr) {
        sideParams.ctroctr = inches;
        oldSideParams.ctroctr = sideParams.ctroctr;
      }
    }
  }

  //19000 'FTINCHES$ TO INCHES ***************************************************
  convertInchesToCtrFormat(inchesCtr: number, numberFans: number):string {
    if (inchesCtr === 0 || numberFans == 0) {
      return 'N/A';
    }

    let feetCtrFirtsPartNumber = Math.floor(this._convertInchesToFeet(inchesCtr));
    let inchesCtrSecondPartNumber = inchesCtr % 12;
    if (inchesCtrSecondPartNumber < 10) {
      return feetCtrFirtsPartNumber.toString() + '” - 0' + inchesCtrSecondPartNumber.toString() + '’';
    }
    else if (inchesCtrSecondPartNumber >= 12) {
      feetCtrFirtsPartNumber += Math.floor(inchesCtrSecondPartNumber / 12);
      inchesCtrSecondPartNumber %= 12;
    }

    return feetCtrFirtsPartNumber.toString() + '” - ' + inchesCtrSecondPartNumber.toString() + '’';
  }

  onChangeNearCtr() {
    this.onChangeAnyCtr(this._nearCtrCtr, this._flatDuctModel.near, this.oldFlatDuctModel.near, AqsFlatDuctSideNo.Near);
    this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.CtrCtr);
    this._nearCtrCtr = this.convertInchesToCtrFormat(
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.near.ctroctr),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.near.numberFansPerSide)
    );
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  onChangeFarCtr() {
    this.onChangeAnyCtr(this._farCtrCtr, this._flatDuctModel.far, this.oldFlatDuctModel.far, AqsFlatDuctSideNo.Far);
    this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.CtrCtr);
    this._farCtrCtr = this.convertInchesToCtrFormat(
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.far.ctroctr),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.far.numberFansPerSide)
    );
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  onChangeFrontCtr() {
    this.onChangeAnyCtr(this._frontCtrCtr, this._flatDuctModel.front, this.oldFlatDuctModel.front, AqsFlatDuctSideNo.Front);
    this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.CtrCtr);
    this._frontCtrCtr = this.convertInchesToCtrFormat(
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.front.ctroctr),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.front.numberFansPerSide)
    );
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  onChangeRearCtr() {
    this.onChangeAnyCtr(this._rearCtrCtr, this._flatDuctModel.rear, this.oldFlatDuctModel.rear, AqsFlatDuctSideNo.Rear);
    this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.CtrCtr);
    this._rearCtrCtr = this.convertInchesToCtrFormat(
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.rear.ctroctr),
      this._getRoundToZeroDecimalPlaces(this._flatDuctModel.rear.numberFansPerSide)
    );
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  //30501 'PERFORATED PER RUN ALTERED********
  onChangeNearPerf() {
    if (this._nearPerf !== undefined && this._nearPerf !== '') {
      let stringNumberValue = this._nearPerf.replace(/[^0-9.]/g, '');
      stringNumberValue = stringNumberValue.split('.')[0];
      if (stringNumberValue !== undefined && stringNumberValue !== '') {
        this._flatDuctModel.near.perfPerRun = Number(stringNumberValue);
        this._flatDuctModel.near.perfPerRun = this._convertFeetToInches(this._flatDuctModel.near.perfPerRun);
        this._flatDuctModel.near.perfPerRun = Math.floor(Math.floor(this._flatDuctModel.near.perfPerRun / 24) * 24 + .5);

        if (this._flatDuctModel.near.perfPerRun <= 0) {
          this._flatDuctModel.near.perfPerRun = this.oldFlatDuctModel.near.perfPerRun;
        }
        else if (this._flatDuctModel.near.perfPerRun !== this.oldFlatDuctModel.near.perfPerRun) {
          if (this._flatDuctModel.near.perfPerRun > this._flatDuctModel.near.maxDuctLenght) {
            this._flatDuctModel.near.perfPerRun = this._flatDuctModel.near.maxDuctLenght;
          }

          this.recPerf[0] = this._flatDuctModel.near.perfPerRun;

          //logic for methods to recalculate
          this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.PerfRun);
        }
      }
    }

    this._nearPerf = this._convertNumberToRunFormat(this._convertInchesToFeet(this._flatDuctModel.near.perfPerRun), this._flatDuctModel.near.numberFansPerSide);
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  //30501 'PERFORATED PER RUN ALTERED********
  onChangeFarPerf() {
    if (this._farPerf !== undefined && this._farPerf !== '') {
      let stringNumberValue = this._farPerf.replace(/[^0-9.]/g, '');
      stringNumberValue = stringNumberValue.split('.')[0];
      if (stringNumberValue !== undefined && stringNumberValue !== '') {
        this._flatDuctModel.far.perfPerRun = Number(stringNumberValue);
        this._flatDuctModel.far.perfPerRun = this._convertFeetToInches(this._flatDuctModel.far.perfPerRun);
        this._flatDuctModel.far.perfPerRun = Math.floor(Math.floor(this._flatDuctModel.far.perfPerRun / 24) * 24 + .5);

        if (this._flatDuctModel.far.perfPerRun <= 0) {
          this._flatDuctModel.far.perfPerRun = this.oldFlatDuctModel.far.perfPerRun;
        }
        else if (this._flatDuctModel.far.perfPerRun !== this.oldFlatDuctModel.far.perfPerRun) {
          if (this._flatDuctModel.far.perfPerRun > this._flatDuctModel.far.maxDuctLenght) {
            this._flatDuctModel.far.perfPerRun = this._flatDuctModel.far.maxDuctLenght;
          }

          this.recPerf[1] = this._flatDuctModel.far.perfPerRun;

          //logic for methods to recalculate
          this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.PerfRun);
        }
      }
    }

    this._farPerf = this._convertNumberToRunFormat(this._convertInchesToFeet(this._flatDuctModel.far.perfPerRun), this._flatDuctModel.far.numberFansPerSide);
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  //30501 'PERFORATED PER RUN ALTERED********
  onChangeFrontPerf() {
    if (this._frontPerf !== undefined && this._frontPerf !== '') {
      let stringNumberValue = this._frontPerf.replace(/[^0-9.]/g, '');
      stringNumberValue = stringNumberValue.split('.')[0];
      if (stringNumberValue !== undefined && stringNumberValue !== '') {
        this._flatDuctModel.front.perfPerRun = Number(stringNumberValue);
        this._flatDuctModel.front.perfPerRun = this._convertFeetToInches(this._flatDuctModel.front.perfPerRun);
        this._flatDuctModel.front.perfPerRun = Math.floor(Math.floor(this._flatDuctModel.front.perfPerRun / 24) * 24 + .5);

        if (this._flatDuctModel.front.perfPerRun <= 0) {
          this._flatDuctModel.front.perfPerRun = this.oldFlatDuctModel.front.perfPerRun;
        }
        else if (this._flatDuctModel.front.perfPerRun !== this.oldFlatDuctModel.front.perfPerRun) {
          if (this._flatDuctModel.front.perfPerRun > this._flatDuctModel.front.maxDuctLenght) {
            this._flatDuctModel.front.perfPerRun = this._flatDuctModel.front.maxDuctLenght;
          }

          this.recPerf[2] = this._flatDuctModel.front.perfPerRun;

          //logic for methods to recalculate
          this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.PerfRun);
        }
      }
    }

    this._frontPerf = this._convertNumberToRunFormat(this._convertInchesToFeet(this._flatDuctModel.front.perfPerRun), this._flatDuctModel.front.numberFansPerSide);
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  //30501 'PERFORATED PER RUN ALTERED********
  onChangeRearPerf() {
    if (this._rearPerf !== undefined && this._rearPerf !== '') {
      let stringNumberValue = this._rearPerf.replace(/[^0-9.]/g, '');
      stringNumberValue = stringNumberValue.split('.')[0];
      if (stringNumberValue !== undefined && stringNumberValue !== '') {
        this._flatDuctModel.rear.perfPerRun = Number(stringNumberValue);
        this._flatDuctModel.rear.perfPerRun = this._convertFeetToInches(this._flatDuctModel.rear.perfPerRun);
        this._flatDuctModel.rear.perfPerRun = Math.floor(Math.floor(this._flatDuctModel.rear.perfPerRun / 24) * 24 + .5);

        if (this._flatDuctModel.rear.perfPerRun <= 0) {
          this._flatDuctModel.rear.perfPerRun = this.oldFlatDuctModel.rear.perfPerRun;
        }
        else if (this._flatDuctModel.rear.perfPerRun !== this.oldFlatDuctModel.rear.perfPerRun) {
          if (this._flatDuctModel.rear.perfPerRun > this._flatDuctModel.rear.maxDuctLenght) {
            this._flatDuctModel.rear.perfPerRun = this._flatDuctModel.rear.maxDuctLenght;
          }

          this.recPerf[3] = this._flatDuctModel.rear.perfPerRun;

          //logic for methods to recalculate
          this.onChangeEntityCallNeededMethods(AqsFlatDuctCallMethodsBaseOnEntity.PerfRun);
        }
      }
    }

    this._rearPerf = this._convertNumberToRunFormat(this._convertInchesToFeet(this._flatDuctModel.rear.perfPerRun), this._flatDuctModel.rear.numberFansPerSide);
    this.drawDuct();
    this.changeFlatDuctModel();
  }

  //30600 'SOLID PER RUN ALTERED***********
  onChangeNearSolid() {
     if (this._flatDuctModel.near.numberFansPerSide !== 0 && this._nearSolid !== undefined && this._nearSolid !== '') {
      let stringNumberValue = this._nearSolid.replace(/[^0-9.]/g, '');
      stringNumberValue = stringNumberValue.split('.')[0];
      if (stringNumberValue !== undefined && stringNumberValue !== '') {
        this._flatDuctModel.near.solidPerRun = Number(stringNumberValue);
        this._flatDuctModel.near.solidPerRun = this._convertFeetToInches(this._flatDuctModel.near.solidPerRun);
        this._flatDuctModel.near.solidPerRun = Math.floor(Math.floor(this._flatDuctModel.near.solidPerRun / 24) * 24 + .5);

        if (
          this._flatDuctModel.near.solidPerRun <= 0 ||
          this._flatDuctModel.near.solidPerRun === this.oldFlatDuctModel.near.solidPerRun ||
          this._flatDuctModel.near.solidPerRun > this._flatDuctModel.near.maxDuctLenght - 16) {
            this._flatDuctModel.near.solidPerRun = this.oldFlatDuctModel.near.solidPerRun;
        } else {
          this.oldFlatDuctModel.near.solidPerRun = this._flatDuctModel.near.solidPerRun;

          if (this._flatDuctModel.near.solidPerRun + this._flatDuctModel.near.perfPerRun > this._flatDuctModel.near.maxDuctLenght) {
            this._flatDuctModel.near.perfPerRun = this._flatDuctModel.near.maxDuctLenght - this._flatDuctModel.near.solidPerRun;
          }
        }
      }
     }

     this._nearSolid = this._convertNumberToRunFormat(this._convertInchesToFeet(this._flatDuctModel.near.solidPerRun), this._flatDuctModel.near.numberFansPerSide);
     this.drawDuct();
     this.changeFlatDuctModel();
  }

  //30600 'SOLID PER RUN ALTERED***********
  onChangeFarSolid() {
    if (this._flatDuctModel.far.numberFansPerSide !== 0 && this._farSolid !== undefined && this._farSolid !== '') {
      let stringNumberValue = this._farSolid.replace(/[^0-9.]/g, '');
      stringNumberValue = stringNumberValue.split('.')[0];
      if (stringNumberValue !== undefined && stringNumberValue !== '') {
        this._flatDuctModel.far.solidPerRun = Number(stringNumberValue);
        this._flatDuctModel.far.solidPerRun = this._convertFeetToInches(this._flatDuctModel.far.solidPerRun);
        this._flatDuctModel.far.solidPerRun = Math.floor(Math.floor(this._flatDuctModel.far.solidPerRun / 24) * 24 + .5);

        if (
          this._flatDuctModel.far.solidPerRun <= 0 ||
          this._flatDuctModel.far.solidPerRun === this.oldFlatDuctModel.far.solidPerRun ||
          this._flatDuctModel.far.solidPerRun > this._flatDuctModel.far.maxDuctLenght - 16) {
            this._flatDuctModel.far.solidPerRun = this.oldFlatDuctModel.far.solidPerRun;
        } else {
          this.oldFlatDuctModel.far.solidPerRun = this._flatDuctModel.far.solidPerRun;

          if (this._flatDuctModel.far.solidPerRun + this._flatDuctModel.far.perfPerRun > this._flatDuctModel.far.maxDuctLenght) {
            this._flatDuctModel.far.perfPerRun = this._flatDuctModel.far.maxDuctLenght - this._flatDuctModel.far.solidPerRun;
          }
        }
      }
     }

     this._farSolid = this._convertNumberToRunFormat(this._convertInchesToFeet(this._flatDuctModel.far.solidPerRun), this._flatDuctModel.far.numberFansPerSide);
     this.drawDuct();
     this.changeFlatDuctModel();
  }

  //30600 'SOLID PER RUN ALTERED***********
  onChangeFrontSolid() {
    if (this._flatDuctModel.front.numberFansPerSide !== 0 && this._frontSolid !== undefined && this._frontSolid !== '') {
      let stringNumberValue = this._frontSolid.replace(/[^0-9.]/g, '');
      stringNumberValue = stringNumberValue.split('.')[0];
      if (stringNumberValue !== undefined && stringNumberValue !== '') {
        this._flatDuctModel.front.solidPerRun = Number(stringNumberValue);
        this._flatDuctModel.front.solidPerRun = this._convertFeetToInches(this._flatDuctModel.front.solidPerRun);
        this._flatDuctModel.front.solidPerRun = Math.floor(Math.floor(this._flatDuctModel.front.solidPerRun / 24) * 24 + .5);

        if (
          this._flatDuctModel.front.solidPerRun <= 0 ||
          this._flatDuctModel.front.solidPerRun === this.oldFlatDuctModel.front.solidPerRun ||
          this._flatDuctModel.front.solidPerRun > this._flatDuctModel.front.maxDuctLenght - 16) {
            this._flatDuctModel.front.solidPerRun = this.oldFlatDuctModel.front.solidPerRun;
        } else {
          this.oldFlatDuctModel.front.solidPerRun = this._flatDuctModel.front.solidPerRun;

          if (this._flatDuctModel.front.solidPerRun + this._flatDuctModel.front.perfPerRun > this._flatDuctModel.front.maxDuctLenght) {
            this._flatDuctModel.front.perfPerRun = this._flatDuctModel.front.maxDuctLenght - this._flatDuctModel.front.solidPerRun;
          }
        }
      }
     }

     this._frontSolid = this._convertNumberToRunFormat(this._convertInchesToFeet(this._flatDuctModel.front.solidPerRun), this._flatDuctModel.front.numberFansPerSide);
     this.drawDuct();
     this.changeFlatDuctModel();
  }

  //30600 'SOLID PER RUN ALTERED***********
  onChangeRearSolid() {
    if (this._flatDuctModel.rear.numberFansPerSide !== 0 && this._rearSolid !== undefined && this._rearSolid !== '') {
      let stringNumberValue = this._rearSolid.replace(/[^0-9.]/g, '');
      stringNumberValue = stringNumberValue.split('.')[0];
      if (stringNumberValue !== undefined && stringNumberValue !== '') {
        this._flatDuctModel.rear.solidPerRun = Number(stringNumberValue);
        this._flatDuctModel.rear.solidPerRun = this._convertFeetToInches(this._flatDuctModel.rear.solidPerRun);
        this._flatDuctModel.rear.solidPerRun = Math.floor(Math.floor(this._flatDuctModel.rear.solidPerRun / 24) * 24 + .5);

        if (
          this._flatDuctModel.rear.solidPerRun <= 0 ||
          this._flatDuctModel.rear.solidPerRun === this.oldFlatDuctModel.rear.solidPerRun ||
          this._flatDuctModel.rear.solidPerRun > this._flatDuctModel.rear.maxDuctLenght - 16) {
            this._flatDuctModel.rear.solidPerRun = this.oldFlatDuctModel.rear.solidPerRun;
        } else {
          this.oldFlatDuctModel.rear.solidPerRun = this._flatDuctModel.rear.solidPerRun;

          if (this._flatDuctModel.rear.solidPerRun + this._flatDuctModel.rear.perfPerRun > this._flatDuctModel.rear.maxDuctLenght) {
            this._flatDuctModel.rear.perfPerRun = this._flatDuctModel.rear.maxDuctLenght - this._flatDuctModel.rear.solidPerRun;
          }
        }
      }
     }

     this._rearSolid = this._convertNumberToRunFormat(this._convertInchesToFeet(this._flatDuctModel.rear.solidPerRun), this._flatDuctModel.rear.numberFansPerSide);
     this.drawDuct();
     this.changeFlatDuctModel();
  }
  //#endregion

  //#region helpers methods

  private _convertNumberToRunFormat(value: number, numberFans: number):string {
    if (value == undefined || value == 0 || numberFans == undefined || numberFans == 0) {
      return 'N/A'
    }

    return value.toString() + '’';
  }

  private _convertToDoubleQuoteString(numberValue: number):string {
    return numberValue.toString() + '”';
  }

  private _convertInchesToFeet(inch: number):number {
    return Math.floor(this._getRoundToZeroDecimalPlaces(inch)  + .5) / 12;
  }

  private _convertFeetToInches(feet: number):number {
    return feet * 12;
  }

  private _saveSideParamArrayToType(sideParams: AqsFlatDuctSideParamsModel) {
    switch (sideParams.name){
      case 'Near':
        this._flatDuctModel.near = sideParams;
        break;
      case 'Far':
        this._flatDuctModel.far = sideParams;
        break;
      case 'Front':
        this._flatDuctModel.front = sideParams;
        break;
      case 'Rear':
        this._flatDuctModel.rear = sideParams;
        break;
    }
  }

  numberOnly(event) {
    if (!_isNumberValue(event.key) && event.key !== '.' && event.key !== 'Enter') {
      event.preventDefault();
    }
  }

  numberAndDefisOnly(event) {
    if (!_isNumberValue(event.key) && event.key !== '.' && event.key !== 'Enter' && event.key !== '-') {
      event.preventDefault();
    }
  }

  private _copyAllDataAqsFlatDuctSideType(side: AqsFlatDuctSideParamsModel, cloneSide: AqsFlatDuctSideParamsModel) {
    cloneSide.ctroctr = side.ctroctr;
    cloneSide.distanceFromEdge = side.distanceFromEdge;
    cloneSide.idealDuctLenght = side.idealDuctLenght;
    cloneSide.maxDuctLenght = side.maxDuctLenght;
    cloneSide.numberFansPerSide = side.numberFansPerSide;
    cloneSide.perfPerRun = side.perfPerRun;
    cloneSide.solidPerRun = side.solidPerRun;
  }

  private _copyAllDataToOldModel() {
    this.oldFlatDuctModel.ductDiameter = this._flatDuctModel.ductDiameter;
    this.oldFlatDuctModel.ductType = this._flatDuctModel.ductType;
    this.oldFlatDuctModel.openingDiameter = this._flatDuctModel.openingDiameter;

    this._copyAllDataAqsFlatDuctSideType(this._flatDuctModel.far, this.oldFlatDuctModel.far);
    this._copyAllDataAqsFlatDuctSideType(this._flatDuctModel.near, this.oldFlatDuctModel.near);
    this._copyAllDataAqsFlatDuctSideType(this._flatDuctModel.front, this.oldFlatDuctModel.front);
    this._copyAllDataAqsFlatDuctSideType(this._flatDuctModel.rear, this.oldFlatDuctModel.rear);
  }

  private _copyFromNewModelToOldNumberFans() {
    this.oldFlatDuctModel.near.numberFansPerSide = this._flatDuctModel.near.numberFansPerSide;
    this.oldFlatDuctModel.far.numberFansPerSide = this._flatDuctModel.far.numberFansPerSide;
    this.oldFlatDuctModel.front.numberFansPerSide = this._flatDuctModel.front.numberFansPerSide;
    this.oldFlatDuctModel.rear.numberFansPerSide = this._flatDuctModel.rear.numberFansPerSide;
  }

  private _getRoundToZeroDecimalPlaces(number: number): number {
    return Math.round((number + Number.EPSILON) * 1) / 1;
  }
  //#endregion

  changeFlatDuctModel() {
    this.onChangedFlatDuctModel.emit();
  }

  calculateVelocitiesFromSavedmodel() {
    if (
      this.oldFlatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.HalfRound &&
      Math.PI * Math.pow(this.oldFlatDuctModel.openingDiameter, 2) <= Math.PI / 2 * Math.pow(this.oldFlatDuctModel.ductDiameter, 2)
      ) {
        this._actualCrossSectionalVelocity = this.lowerLarge / (Math.pow(this.oldFlatDuctModel.openingDiameter / 24, 2) * Math.PI * this.numberFans);
        //7060 IF DUCTTYPE=2 THEN IF PI*OPENDIA^2<=(PI/2*DUCTDIA^2) THEN CSV=LOWERLARGE/((OPENDIA/24)^2*PI*NUMBERFANS):GOTO 7100
      }
      else if (
      this.oldFlatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.FullRound &&
      this.oldFlatDuctModel.openingDiameter <= this.oldFlatDuctModel.ductDiameter
      ) {
        this._actualCrossSectionalVelocity = this.lowerLarge / (Math.pow(this.oldFlatDuctModel.openingDiameter / 24, 2) * Math.PI * this.numberFans);
        //7070 IF DUCTTYPE=1 THEN IF OPENDIA<=DUCTDIA THEN CSV=LOWERLARGE/((OPENDIA/24)^2*PI*NUMBERFANS):GOTO 7100
      }
      else if (this.oldFlatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.FullRound) {
        this._actualCrossSectionalVelocity = this.lowerLarge / (Math.pow(this.oldFlatDuctModel.ductDiameter / 24, 2) * Math.PI * this.numberFans);
        //7080 IF DUCTTYPE=1 THEN CSV=LOWERLARGE/((DUCTDIA/24)^2*PI*NUMBERFANS):GOTO 7100
      }
      else if (this.oldFlatDuctModel.ductType == AqsFlatDuctingShapeTypeEnum.HalfRound) {
        this._actualCrossSectionalVelocity = this.lowerLarge / (Math.pow(this.oldFlatDuctModel.ductDiameter / 24, 2) * Math.PI / 2 * this.numberFans);
        //7090 IF DUCTTYPE=2 THEN CSV=LOWERLARGE/((DUCTDIA/24)^2*(PI/2)*NUMBERFANS)
      }

      this._actualCrossSectionalVelocity = Math.floor(this._actualCrossSectionalVelocity + .5);
      //7100 CSV=INT(CSV+.5)

      this._actualSurfaceVelocity = (this.lowerLarge / this.numberFans) / this.surface;
      //SV=(LOWERLARGE/NUMBERFANS)/SURFACE

      this.fillDuctTableOld();
      let ftPerforated = 0;
      for (let i = 0; i <= 3; i++) {
        if (
        this.oldDuctTable[i].numberFansPerSide != 0
        &&
        (this.oldDuctTable[i].perfPerRun / 12  < ftPerforated
        || ftPerforated == 0)
        ) {
          ftPerforated = this.oldDuctTable[i].perfPerRun / 12;
          //7117 IF DUCT(PERFPERRUN,I)/12<FTPERFORATED OR FTPERFORATED=0 THEN FTPERFORATED=DUCT(PERFPERRUN,I)/12
        }
      }

      this._actualSurfaceVelocity = Math.floor(this._actualSurfaceVelocity / ftPerforated * 10 + .5) / 10;
      //7120 SV=INT(SV/FTPERFORATED*10+.5)/10

      this.fillDuctTable();
  }
}
