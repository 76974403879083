import { UserRoleEnum } from '../../common';
import { ProjectEnum } from '../enums';

export class MenuItem {
  route: string;
  label: string;
  icon?: string;
  isDisabled?: boolean;
  isExpanded?: boolean;
  children?: Array<MenuItem>;
  project?: ProjectEnum;
}

export class NavigationUser {
  role: UserRoleEnum;
  projects: number | null;
}

export class NavigationItemInfo {
  label: string;
  icon?: string;
  childrenRoutes?: Array<string>;
  dependentRoutes?: Array<string>;
}

export class RoleRouteInfo {
  route: string;
  project?: ProjectEnum;
  isDisabled?: boolean;
}

export interface RouteToRoleMapInterface {
  [key: string]: UserRoleEnum;
}

export interface NavigationItemsInfoInterface {
  [key: string]: NavigationItemInfo;
}

export interface RoutesForRolesInterface {
  [key: string]: Array<RoleRouteInfo>;
}
