import { UserRoleEnum } from "src/app/common";
import { ProjectEnum, TutorialMenuEnum, TutorialParagraphEnum } from "../enums";

export const TutorialMenuLabels = {
    [TutorialMenuEnum.UserManagement]: 'User Management',
    [TutorialMenuEnum.EmailManagement]: 'Email Management',
    [TutorialMenuEnum.Locations]: 'Locations',
    [TutorialMenuEnum.BtxLiveData]: 'BTX Live Data',
    [TutorialMenuEnum.BtxHistoricalData]: 'BTX Historical Data',
    [TutorialMenuEnum.BtxNotifications]: 'BTX Notifications',
    [TutorialMenuEnum.KtxCurrentData]: 'KTX Current Data',
    [TutorialMenuEnum.KtxNotifications]: 'KTX Notifications',
    [TutorialMenuEnum.KtxHistoricalData]: 'KTX Historical Data',
    [TutorialMenuEnum.ItxLiveData]: 'ITX Live Data',
    [TutorialMenuEnum.ItxReports]: 'ITX Reports',
    [TutorialMenuEnum.ItxAlerts]: 'ITX Alerts',
}

export const UsersTutorialMenu = {
    [UserRoleEnum.CompanyAdmin]: {
        [ProjectEnum.None]: {
            [TutorialMenuEnum.UserManagement]: TutorialMenuLabels[TutorialMenuEnum.UserManagement],
            [TutorialMenuEnum.EmailManagement]: TutorialMenuLabels[TutorialMenuEnum.EmailManagement],
            [TutorialMenuEnum.Locations]: TutorialMenuLabels[TutorialMenuEnum.Locations],
        },
        [ProjectEnum.Btx]: {
            [TutorialMenuEnum.BtxLiveData]: TutorialMenuLabels[TutorialMenuEnum.BtxLiveData],
            [TutorialMenuEnum.BtxHistoricalData]: TutorialMenuLabels[TutorialMenuEnum.BtxHistoricalData],
            [TutorialMenuEnum.BtxNotifications]: TutorialMenuLabels[TutorialMenuEnum.BtxNotifications],
        },
        [ProjectEnum.Ktx]: {
            [TutorialMenuEnum.KtxCurrentData]: TutorialMenuLabels[TutorialMenuEnum.KtxCurrentData],
            [TutorialMenuEnum.KtxHistoricalData]: TutorialMenuLabels[TutorialMenuEnum.KtxHistoricalData],
            [TutorialMenuEnum.KtxNotifications]: TutorialMenuLabels[TutorialMenuEnum.KtxNotifications],
        },
        [ProjectEnum.Itx]: {
            [TutorialMenuEnum.ItxLiveData]: TutorialMenuLabels[TutorialMenuEnum.ItxLiveData],
            [TutorialMenuEnum.ItxAlerts]: TutorialMenuLabels[TutorialMenuEnum.ItxAlerts],
            [TutorialMenuEnum.ItxReports]: TutorialMenuLabels[TutorialMenuEnum.ItxReports],
        },
    },
    [UserRoleEnum.CompanyUser]: {
        [ProjectEnum.Btx]: {
            [TutorialMenuEnum.BtxLiveData]: TutorialMenuLabels[TutorialMenuEnum.BtxLiveData],
            [TutorialMenuEnum.BtxHistoricalData]: TutorialMenuLabels[TutorialMenuEnum.BtxHistoricalData],
            [TutorialMenuEnum.BtxNotifications]: TutorialMenuLabels[TutorialMenuEnum.BtxNotifications],
        },
        [ProjectEnum.Ktx]: {
            [TutorialMenuEnum.KtxCurrentData]: TutorialMenuLabels[TutorialMenuEnum.KtxCurrentData],
            [TutorialMenuEnum.KtxHistoricalData]: TutorialMenuLabels[TutorialMenuEnum.KtxHistoricalData],
            [TutorialMenuEnum.KtxNotifications]: TutorialMenuLabels[TutorialMenuEnum.KtxNotifications],
        },
        [ProjectEnum.Itx]: {
            [TutorialMenuEnum.ItxLiveData]: TutorialMenuLabels[TutorialMenuEnum.ItxLiveData],
            [TutorialMenuEnum.ItxAlerts]: TutorialMenuLabels[TutorialMenuEnum.ItxAlerts],
            [TutorialMenuEnum.ItxReports]: TutorialMenuLabels[TutorialMenuEnum.ItxReports],
        },
    },
    [UserRoleEnum.Vendor]: {
        [ProjectEnum.Itx]: {
            [TutorialMenuEnum.ItxLiveData]: TutorialMenuLabels[TutorialMenuEnum.ItxLiveData],
            [TutorialMenuEnum.ItxReports]: TutorialMenuLabels[TutorialMenuEnum.ItxReports],
        },
    },
}

export const TutorialParagraphLabels={
    [TutorialParagraphEnum.AddCompanyAdmin]:'Add a new company admin',
    [TutorialParagraphEnum.AddCompanyUser]:'Add a new company user',
    [TutorialParagraphEnum.AddVendor]:'Add a new vendor',
    [TutorialParagraphEnum.ViewLocationData]:'View location data',
}
