import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from './../../../common';
import { AqsCommoditiesApiService } from './../http';
import { AqsCommoditiesViewModel } from '@models';

@Injectable({
  providedIn: 'root'
})
export class AqsCommoditiesDatasourceService {
  public commodities$: BehaviorSubject<AqsCommoditiesViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(private _aqsCommoditiesApi: AqsCommoditiesApiService) { }

  public async getAll() {
    try {
      let commodities = this.commodities$.getValue();
      if (!commodities || commodities && commodities.length == 0) {
        const data = await this._aqsCommoditiesApi.getAll() as AqsCommoditiesViewModel[];
        if (data) {
          this.commodities$.next(data);
          this.errors$.next(null);
        }
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }
}
