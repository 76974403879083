import { ProjectEnum, UpdateProjectStateEnum, UpdateProjectStatusEnum } from "../../enums";

export class LocationUpdaterViewModel {
    id: number;
    name: string;
    companyName: string;
    projectsAvailable: number;
    projectUpdaterAvailable: number;
    updaterDevices: UpdateDeviceViewModel[];
    locationDevices: LocationDevicesViewModel[];
}

export class LocationDevicesViewModel {
    deviceId: string;
    project: ProjectEnum;
    isInitialized: boolean;
}

export class UpdateDeviceViewModel {
    id: string;
    locationId: number;
    projectsAvailable: number;
    isInitialized: boolean;
    isDeviceInInitialization: boolean = false;
    projectDevices: ProjectUpdaterDeviceViewModel[];
}

export class ProjectUpdaterDeviceViewModel {
    deviceId: string;
    project: ProjectEnum;
    currentVersion: string;
    lastUpdate: number;
    state: UpdateProjectStateEnum;
    errorMessage: string;
}

export class ProjectFirmwareViewModel {
    name: string;
    lastModified: Date;
    createdOn: Date;
    size: number;
    sizeLabel: string
}

export class ProjectFirmwareUpdateViewModel {
    isSelected: boolean;
    locationId: number
    locationName: string;
    project: string;
    currentVersion: string;
    lastUpdated: number;
    updaterDeviceId: string;
    projectDeviceId: string;
    sendCommand: boolean = false;
    state: UpdateProjectStateEnum;
    errorMessage: string;
}

export class UpdateFirmwarePostModel {
    project: ProjectEnum;
    fileName: string;
    fileFolder: string;
    updaterDeviceId: string;
    locationId: number;
    projectDeviceId: string;
}

export class CancelUpdateFirmwarePostModel {
    updaterDeviceId: string;
    locationId: number;
    projectDeviceId: string;
}

export class UpdateFirmwareResultModel {
    updaterDeviceId: string;
    locationId: number;
    projectDeviceId: string;
    status: UpdateProjectStatusEnum;
    payLoadMessage: string;
    payLoadSuccess: boolean;
}

export class UpdateFirmwaresPostModel {
    project: ProjectEnum;
    fileName: string;
    fileFolder: string;
    devices: UpdateFirmwaresDevicePostModel[];
}

export class UpdateFirmwaresDevicePostModel {
    updaterDeviceId: string;
    locationId: number;
    projectDeviceId: string;
}

export class CancelUpdateModel {
    status: UpdateProjectStatusEnum;
    payLoadMessage: string;
    payLoadSuccess: boolean;
}

export class VersionHistoryResponseModel {
    realUpdateTime: Date;
    currentVersion: string;
    targetVersion: string;
    status: string;
    lastErrorMessage: string;
}

export class UpdateFirmwareTriggerModel {
    updaterDeviceId: string;
    locationId: number;
    projectDeviceId: string;
}

export class UpdateProjectDeviceState {
    currentVersion: string;
    projectDeviceId: string;
    state: UpdateProjectStateEnum;
    errorMessage: string;
    lastUpdate: number;
}

export class GetLocationUpdatersFilterModel {
    projects: ProjectEnum[];
    companies: number[];
}

export class InstallProjectModel {
    project: ProjectEnum;
    locationId: number;
    locationName: string;
    projectDeviceId: string;
    updaterDeviceId: string;
}

export class InstallProjectPostModel {
    file: File;
    project: ProjectEnum;
    locationId: number;
    projectDeviceId: string;
    updaterDeviceId: string;
    overwriteConfig: boolean;
}

export class CheckProjectsGetModel {
    updaterDeviceId: string;
    project: ProjectEnum;
}

export class GetInstalledProjectFirmwareInfo {
    status: UpdateProjectStatusEnum;
    version: string;
    isInstalled: boolean;
}