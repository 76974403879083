import { Component, OnInit, OnDestroy, Input, ElementRef, NgZone, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { BtxLocationEquipmentConfiguration, BtxLocationSensorConfiguration, EquipmentViewModel, SensorViewModel } from '../../../../models';
import { EquipmentSubTypeEnum, SensorTypeEnum, SensorPositionEnum, EquipmentSideEnum } from '../../../../enums';
import { equipmentSensorMapping } from '../../../../constants';
import { equipmentSideLabels, sensorPositionLabels, sensorTypeLabels, equipmentNorthSouthSideLabels, equipmentWestEastSideLabels, equipmentTypeLabels, equipmentSubTypeLabels } from 'src/app/main/constants';
import PerfectScrollbar from 'perfect-scrollbar';
import { bitOptions } from '../../../../constants';
import { digitsValidator } from '../../../../../common/validators';
import { ModbussAddress } from '../../../common';

export class DropdownModel {
  label: string;
  value: number;
}

@Component({
  selector: 'greensleeves-upload-equipment-scheme-editor',
  templateUrl: './upload-equipment-scheme.component.html',
  styles: []
})

export class UploadEquipmentSchemeEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  type: EquipmentSubTypeEnum;

  @Input()
  equipment: BtxLocationEquipmentConfiguration;

  @Input()
  maxSensorIndex: number;

  @Input()
  uniqueValidator: (sensor: SensorViewModel, equipment: EquipmentViewModel) => boolean;

  @Input()
  validateEquipmentSensorsTypePositionAndSide: () => void;

  @Input()
  validateEquipmentAndSensors: () => void

  @Input()
  uniqueSensorAddressesValidator: (sensor: SensorViewModel) => ModbussAddress[];

  @Input()
  uniqueEquipmentAddressesValidator:(equipment: EquipmentViewModel) => ModbussAddress[];

  @Output()
  onEnableUploadButton = new EventEmitter<void>();

  @Output()
  onDisableUploadButton = new EventEmitter<void>();

  _edit = 'edit';
  _delete = 'delete';
  _sensorType = 'sensorType';
  _sensorPosition = 'sensorPosition';
  _equipmentSide = 'equipmentSide';
  _generalAddressErrorText = 'Address already in use';
  _generalBitErrorText = 'Bit already in use';
  // _sensorWithErrorBackgroundColor = '#eedc9a';

  _equipmentForm: FormGroup;
  _sensorIdInEdit: number = -99;

  _selectedSensorIndex: number;
  _allowedSensorTypes: any;
  _allowedSensorPositions: any;
  _allowedSensorSides: any;

  _isEastWestAppropriateSensorSides: boolean;
  _isEditMode: boolean = false;
  _isAddMode: boolean = false;

  _sensorTypes: Array<DropdownModel> = [];
  _sensorPositions: Array<DropdownModel> = [];
  _equipmentSides: Array<DropdownModel> = [];
  _equipmentTypes: Array<DropdownModel>;
  _equipmentSubtypes: Array<DropdownModel> = [];
  _bitOptions = bitOptions;
  clonedSensors: { [s: string]: BtxLocationSensorConfiguration; } = {};

  _columns = [
    { type: 'type', header: 'Type' },
    { type: 'position', header: 'Position' },
    { type: 'side', header: 'Side' },
    { type: 'modbusAddress', header: 'Modbus address' },
    { type: 'warningAddress', header: 'Warning address' },
    { type: 'warningAddressBit', header: 'Bit' },
    { type: 'alarmAddress', header: 'Alarm address' },
    { type: 'alarmAddressBit', header: 'Bit' },
    { type: 'rateOfChangeWarningAddress', header: 'Rate of change warning' },
    { type: 'rateOfChangeWarningAddressBit', header: 'Bit' },
    { type: 'rateOfChangeAlarmAddress', header: 'Rate of change alarm' },
    { type: 'rateOfChangeAlarmAddressBit', header: 'Bit' },
  ];

  _ps: PerfectScrollbar;
  _psConfig = {
    wheelSpeed: 2,
    wheelPropagation: true,
    minScrollbarLength: 20
  };

  getAllowedSensorTypes() {
    var result = this.toDropdownOptions(equipmentSensorMapping[this.equipment.type][this.equipment.subType], this._sensorType);
    return result;
  }

  _sensorTypeLabel(type: SensorTypeEnum) {
    return sensorTypeLabels[type];
  }

  _sensorPositionLabel(position: SensorPositionEnum) {
    return sensorPositionLabels[position];
  }

  _sensorSideLabel(side: EquipmentSideEnum) {
    return equipmentSideLabels[side];
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _el: ElementRef,
    private _zone: NgZone
  ) {

    this._equipmentTypes = Object.keys(equipmentSensorMapping).map(k => ({ label: equipmentTypeLabels[k], value: Number.parseInt(k) }))

    this._equipmentForm = this._formBuilder.group({
      type: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      subType: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      totalRunHoursFirstRegisterAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65535)
        ])
      ],
      totalRunHoursSecondRegisterAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65535)
        ])
      ],
      timeTillMaintenanceAddress: [
        null,
      ],
      shutDownAlarmAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65535)
        ])
      ],
      shutDownAlarmAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(16)
        ])
      ],
      maintenanceRequiredWarningAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65535)
        ])
      ],
      maintenanceRequiredWarningAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(16)
        ])
      ]
    });
  }

  ngOnInit() {
    this.reinitScrollBar(0);
    this.setFormValues(this.equipment);
    this.validateEquipmentAndSensors();

    this._equipmentForm.valueChanges.subscribe(() =>{
      this.equipment.type = this._equipmentForm.controls.type.value;
      this.equipment.subType = this._equipmentForm.controls.subType.value;
      this.equipment.name = this._equipmentForm.controls.name.value;
      this.equipment.totalRunHoursFirstRegisterAddress = this._equipmentForm.controls.totalRunHoursFirstRegisterAddress.value;
      this.equipment.totalRunHoursSecondRegisterAddress = this._equipmentForm.controls.totalRunHoursSecondRegisterAddress.value;
      this.equipment.timeTillMaintenanceAddress = this._equipmentForm.controls.timeTillMaintenanceAddress.value;
      this.equipment.shutDownAlarmAddress = this._equipmentForm.controls.shutDownAlarmAddress.value;
      this.equipment.shutDownAlarmAddressBit = this._equipmentForm.controls.shutDownAlarmAddressBit.value;
      this.equipment.maintenanceRequiredWarningAddress = this._equipmentForm.controls.maintenanceRequiredWarningAddress.value;
      this.equipment.maintenanceRequiredWarningAddressBit = this._equipmentForm.controls.maintenanceRequiredWarningAddressBit.value;
      this.validateEquipmentAndSensors();
    });

    this._equipmentSubtypes = ((this.equipment.type || this.equipment.type === 0) ? Object.keys(equipmentSensorMapping[this.equipment.type]) : [])
      .map(k => ({ label: equipmentSubTypeLabels[k], value: Number.parseInt(k) }));
  }

  ngOnDestroy() {
    this._ps && this._ps.destroy();
    this._ps = null;
  }

  ngAfterViewInit() {
    this.ValidateSensorSides();
    this.reinitScrollBar(0);
  }

  onTypeChanged() {
    const equipmentType = this._equipmentForm.controls.type.value;
    this._equipmentForm.controls.subType.setValue(null);
    this.equipment.type = equipmentType;
    this.equipment.subType = null;
    this._equipmentSubtypes = ((equipmentType || equipmentType === 0) ? Object.keys(equipmentSensorMapping[equipmentType]) : [])
      .map(k => ({ label: equipmentSubTypeLabels[k], value: Number.parseInt(k) }));
  }

  onSubTypeChanged() {
    const equipmentSubType = this._equipmentForm.controls.subType.value;
    this.equipment.subType = equipmentSubType;
    this.validateEquipmentSensorsTypePositionAndSide();
  }

  getAllowedSensorPositions(sensor: BtxLocationSensorConfiguration) {
    var result = this.toDropdownOptions((sensor.type || sensor.type === 0) && equipmentSensorMapping[this.equipment.type][this.type][sensor.type] || null, this._sensorPosition);
    return result;
  }

  getAllowedSides(sensor: BtxLocationSensorConfiguration) {
    let allowedSides = this.toDropdownOptions((sensor.type || sensor.type === 0) && (sensor.position || sensor.position === 0) && equipmentSensorMapping[this.equipment.type][this.equipment.subType][sensor.type][sensor.position] || null, this._equipmentSide);
    let result = allowedSides.filter(side => side.label);
    if(this._isEastWestAppropriateSensorSides && this.equipment.sensors.length > 1){
      result = result.filter(x => x.value == EquipmentSideEnum.East || x.value == EquipmentSideEnum.West)
    } else if(this.equipment.sensors.length > 1) {
      result = result.filter(x => x.value == EquipmentSideEnum.North || x.value == EquipmentSideEnum.South)
    }

    if(sensor.position != null && result && result.length == 0) {
      result.push({label: '-', value: EquipmentSideEnum.None})
    }
    return result;
  }

  ValidateSensorSides() {
    if (this.equipment && this.equipment.sensors && this.equipment.sensors.length > 0) {
      let sensorSides = this.equipment.sensors.map(sensor => {
        return sensor.side
      });

      let countOfEastWestSides = sensorSides.filter(side => side == EquipmentSideEnum.East || side == EquipmentSideEnum.West).length;
      let countOfNorthSouthSides = sensorSides.filter(side => side == EquipmentSideEnum.North || side == EquipmentSideEnum.South).length;
      this._isEastWestAppropriateSensorSides = countOfEastWestSides >= countOfNorthSouthSides;
      this.equipment.sensors.forEach(sensor => {
        if (this._isEastWestAppropriateSensorSides) {
          if (sensor.side == EquipmentSideEnum.North || sensor.side == EquipmentSideEnum.South) {
            sensor.side = null;
          }
        } else {
          if (sensor.side == EquipmentSideEnum.East || sensor.side == EquipmentSideEnum.West) {
            sensor.side = null;
          }
        }
      });
    }
  }

  isPropertyHasValue(value: number, sensor: BtxLocationSensorConfiguration = null) {
    if (sensor != null && (sensor.type == SensorTypeEnum.Vibration
      || sensor.type == SensorTypeEnum.CurrentDesignLoad
      || sensor.type == SensorTypeEnum.CurrentAmpDraw)) {
      return true;
    }

    return value != null;
  }

  isPropertyAddressValid(value: number, sensor: BtxLocationSensorConfiguration = null){
    if (sensor != null && (sensor.type == SensorTypeEnum.Vibration
      || sensor.type == SensorTypeEnum.CurrentDesignLoad
      || sensor.type == SensorTypeEnum.CurrentAmpDraw)) {
      return true;
    }

    return value > 0 && value < 65536;
  }

  isPropertyBitValid(value: number, sensor: BtxLocationSensorConfiguration = null) {
    if (sensor != null && (sensor.type == SensorTypeEnum.Vibration
      || sensor.type == SensorTypeEnum.CurrentDesignLoad
      || sensor.type == SensorTypeEnum.CurrentAmpDraw)) {
      return true;
    }

    return value > 0 && value <= 16;
  }

  isSensorNotUniq(sensor: BtxLocationSensorConfiguration): boolean {
    var result = this.uniqueValidator(SensorViewModel.toViewModel(sensor), EquipmentViewModel.toViewModel(this.equipment));
    return result && sensor.index !== this._sensorIdInEdit;
  }

  isSensorPropertyNotUniq(sensor: BtxLocationSensorConfiguration, propName: string): boolean {
    var result = sensor.validation && sensor.validation.some(v => v.Name === propName);
    return result && sensor.index !== this._sensorIdInEdit;
  }

  isEquipmentPropertyNotUniq(equipment: BtxLocationEquipmentConfiguration, propName: string): boolean {
    var result = equipment.validation && equipment.validation.some(v => v.Name === propName);
    return result;
  }

  isSensorHasDublicates(sensor: BtxLocationSensorConfiguration): boolean {
    if (sensor != null) {
      var isSensorHasDublicates = this.uniqueValidator(SensorViewModel.toViewModel(sensor), EquipmentViewModel.toViewModel(this.equipment));
      return isSensorHasDublicates && sensor.index !== this._sensorIdInEdit;
    }

    return false;
  }

  isNeedToDisabledInput(type: SensorTypeEnum) {
    return type == SensorTypeEnum.CurrentAmpDraw || type == SensorTypeEnum.CurrentDesignLoad || type == SensorTypeEnum.Vibration;
  }

  private toDropdownOptions(map: Object, type: string) {
    if (!map) {
      return [];
    }

    let labels;
    switch (type) {
      case this._sensorType:
        labels = sensorTypeLabels;
        break;
      case this._sensorPosition:
        labels = sensorPositionLabels;
        break;
      case this._equipmentSide:
        if (this.equipment.sensors.length === 0) {
        labels = equipmentSideLabels;
        } else if (this.equipment.sensors[0].side as EquipmentSideEnum === EquipmentSideEnum.East || this.equipment.sensors[0].side as EquipmentSideEnum === EquipmentSideEnum.West) {
          labels = equipmentWestEastSideLabels;
        } else if (this.equipment.sensors[0].side as EquipmentSideEnum === EquipmentSideEnum.South || this.equipment.sensors[0].side as EquipmentSideEnum === EquipmentSideEnum.North){
          labels = equipmentNorthSouthSideLabels;
        } else {
          labels = equipmentSideLabels;
        }
        break;
      default:
        return [];
    }
    
    return Object.keys(map).map(k => {
      return {
        label: labels[k],
        value: Number.parseInt(k)
      };
    });
  }

  onSelectSensorType(sensor: BtxLocationSensorConfiguration) {
    sensor.position = null;
    sensor.side = null;
    this._allowedSensorPositions = this.getAllowedSensorPositions(sensor);

    if(this._allowedSensorPositions.length == 1) {
      sensor.position = this._allowedSensorPositions[0].value;
    }

    this._allowedSensorSides = this.getAllowedSides(sensor);
    if(this._allowedSensorSides.length == 1) {
      sensor.side = this._allowedSensorSides[0].value;
    }
  }

  onSelectSensorPosition(sensor: BtxLocationSensorConfiguration) {
    sensor.side = null;
    this._allowedSensorSides = this.getAllowedSides(sensor);
    if(this._allowedSensorSides.length == 1) {
      sensor.side = this._allowedSensorSides[0].value;
    }
  }

  onRowEditInit(sensor: BtxLocationSensorConfiguration) {
    this._isEditMode = true;
    this._sensorIdInEdit = sensor.index;
    this.clonedSensors[sensor.index] = { ...sensor };
    this._allowedSensorTypes = this.getAllowedSensorTypes();
    this._allowedSensorPositions = this.getAllowedSensorPositions(sensor);
    this._allowedSensorSides = this.getAllowedSides(sensor);
    this.onDisableUploadButton.emit();
  }

  onRowEditSave(sensor: BtxLocationSensorConfiguration) {
    this._sensorIdInEdit = -999;
    delete this.clonedSensors[sensor.index];
    if (sensor.type == SensorTypeEnum.CurrentAmpDraw
      || sensor.type == SensorTypeEnum.CurrentDesignLoad
      || sensor.type == SensorTypeEnum.Vibration) {
      sensor.rateOfChangeAlarmAddress = null;
      sensor.rateOfChangeAlarmAddressBit = null;
      sensor.rateOfChangeWarningAddress = null;
      sensor.rateOfChangeWarningAddressBit = null;
    }

    this.validateEquipmentAndSensors();
    this._isEditMode = false;
    this._isAddMode = false;
    this.ValidateSensorSides();
  }

  onRowEditCancel(sensor: BtxLocationSensorConfiguration, index: number) {
    this._sensorIdInEdit = -999;
    if (this.maxSensorIndex == sensor.index && this._isAddMode) {
      this.equipment.sensors = this.equipment.sensors.filter(s => s.index != sensor.index);
    } else {
      this.equipment.sensors[index] = this.clonedSensors[sensor.index];
    }
    delete this.clonedSensors[sensor.index];
    this._isEditMode = false;
    this._isAddMode = false;
    this.ValidateSensorSides();
    this.validateEquipmentAndSensors();
  }

  onDeleteSensor(sensor: BtxLocationSensorConfiguration) {
    this.equipment.sensors = this.equipment.sensors.filter(s => s.index != sensor.index);
    this.ValidateSensorSides();
    this.validateEquipmentAndSensors();
  }

  newSensorRow() {
    if(this._isEditMode || this._isAddMode) {
      return;
    }

    this._isAddMode = true;
    var newSensor = new BtxLocationSensorConfiguration();
    this.maxSensorIndex++;
    newSensor.index = this.maxSensorIndex;
    this._sensorIdInEdit = newSensor.index;
    if(!this.equipment.sensors || this.equipment.sensors.length == 0) {
      this.equipment.sensors = [];
    }

    this.equipment.sensors.push(newSensor);
    this.clonedSensors[newSensor.index] = { ...newSensor };
    let buttonIdToEdit = "btn" + newSensor.index;
    setTimeout(() => {
      document.getElementById(buttonIdToEdit).click();
      this._allowedSensorTypes = this.getAllowedSensorTypes();
    }, 10);
    return newSensor;
  }

  updateEditableOptions() {
    this._updateScroll();
  }

  reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.scroll-container .ui-table-scrollable-body');
          if (!el) {
            return;
          }
  
          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })      
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  _updateScroll() {
    this._ps && this._ps.update();
  }

  hasValue(control: FormControl) {
    return control.value || control.value === 0;
  }

  private setFormValues(equipment: BtxLocationEquipmentConfiguration) {
    this._equipmentForm.reset();
    if (equipment) {
      const {
        type,
        subType,
        name,
        totalRunHoursFirstRegisterAddress,
        totalRunHoursSecondRegisterAddress,
        timeTillMaintenanceAddress,
        shutDownAlarmAddress,
        shutDownAlarmAddressBit,
        maintenanceRequiredWarningAddress,
        maintenanceRequiredWarningAddressBit
      } = equipment;
      this._equipmentForm.setValue({
        type,
        subType,
        name,
        totalRunHoursFirstRegisterAddress,
        totalRunHoursSecondRegisterAddress,
        timeTillMaintenanceAddress,
        shutDownAlarmAddress,
        shutDownAlarmAddressBit,
        maintenanceRequiredWarningAddress,
        maintenanceRequiredWarningAddressBit
      });
    }
  }
}
