import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import { AqsReportApiService } from '../http';
import { AqsReportFormatFileLabel } from '../../constants';
import { QuoteFlatStorageReportModel, QuoteRoundStorageReportModel, ReportComputationSheetModel } from '@models';
import { ReportFormatEnum } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class AqsReportDatasourceService {
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(private _aqsReportApi: AqsReportApiService) { }

  public async downloadReportComputationSheetFile(model: ReportComputationSheetModel): Promise<Blob | ErrorModel> {
    try {
      const result = await this._aqsReportApi.downloadReportComputationSheetFile(model);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${AqsReportFormatFileLabel[model.reportFormat]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getComputationSheetReportInBlob(model: ReportComputationSheetModel): Promise<string | ErrorModel> {
    try {
      const result = await this._aqsReportApi.getComputationSheetReportInBlob(model);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async downloadReportQuoteRoundStorageFile(model: QuoteRoundStorageReportModel): Promise<Blob | ErrorModel> {
    try {
      const result = await this._aqsReportApi.downloadReportQuoteRoundStorageFile(model);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${AqsReportFormatFileLabel[model.reportFormat]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getQuoteRoundStorageReportInBlob(model: QuoteRoundStorageReportModel): Promise<string | ErrorModel> {
    try {
      const result = await this._aqsReportApi.getQuoteRoundStorageReportInBlob(model);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async downloadReportQuoteFlatStorageFile(model: QuoteFlatStorageReportModel): Promise<Blob | ErrorModel> {
    try {
      const result = await this._aqsReportApi.downloadReportQuoteFlatStorageFile(model);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${AqsReportFormatFileLabel[model.reportFormat]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getQuoteFlatStorageReportInBlob(model: QuoteFlatStorageReportModel): Promise<string | ErrorModel> {
    try {
      const result = await this._aqsReportApi.getQuoteFlatStorageReportInBlob(model);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async downloadReportListOfQuoteFile(
    quoteStoragesIds: number[],
    reportFormat: ReportFormatEnum,
    timeZone: string,
    printDate: Date): Promise<Blob | ErrorModel> {
    try {
      const result = await this._aqsReportApi.downloadReportListOfQuoteFile(
        quoteStoragesIds,
        reportFormat,
        timeZone,
        printDate);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${AqsReportFormatFileLabel[reportFormat]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getListOfQuoteReportInBlob(
    quoteStoragesIds: number[],
    reportFormat: ReportFormatEnum,
    timeZone: string,
    printDate: Date): Promise<string | ErrorModel> {
    try {
      const result = await this._aqsReportApi.getListOFQuoteReportInBlob(
        quoteStoragesIds,
        reportFormat,
        timeZone,
        printDate);
      return Promise.resolve(result);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }
}
