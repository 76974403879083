import { LocationBtxGetModel } from "./location-btx.model";
import { LocationItxGetModel } from "./location-itx.model";
import { LocationKtxGetModel } from "./location-ktx.model";

export class BtxKtxItxLocationsViewModel {
    public btxLocations: LocationBtxGetModel[];
    public ktxLocations: LocationKtxGetModel[];
    public itxLocations: LocationItxGetModel[];
}

export class BtxKtxItxLocationsGetModel {
    public btxLocations: LocationBtxGetModel[];
    public ktxLocations: LocationKtxGetModel[];
    public itxLocations: LocationItxGetModel[];

    public static toViewModel(getModel: BtxKtxItxLocationsGetModel) {
        const viewModel = new BtxKtxItxLocationsViewModel();
        viewModel.btxLocations = getModel.btxLocations;
        viewModel.ktxLocations = getModel.ktxLocations;
        viewModel.itxLocations = getModel.itxLocations;

        return viewModel;
    }
}