import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FlatStorageParams } from '@models';
import { AQSFlatStorageTypesEnum } from '../../../../enums';

@Component({
  selector: 'greensleeves-aqs-flat-view-tab',
  templateUrl: './aqs-flat-view-tab.component.html',
  styleUrls: []
})
export class AqsFlatViewTabComponent implements OnInit {
  @ViewChild('viewCanvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @Input() _storageParams: FlatStorageParams;

  private ctx: CanvasRenderingContext2D;
  constructor() { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.drawStorage();
  }

  private getCalculateCoef(): number {
    let biggestSide = this._storageParams.width;
    if (biggestSide < this._storageParams.lenght) biggestSide = this._storageParams.lenght;
    if (biggestSide < this._storageParams.height) biggestSide = this._storageParams.height;

    if (this._storageParams.height == biggestSide) {
      if (this._storageParams.lenght / 5 >= this._storageParams.width || this._storageParams.width / 5 >= this._storageParams.lenght) {
        return this._storageParams.lenght > this._storageParams.width ? this.ctx.canvas.width / (2 * biggestSide + 2 * this._storageParams.lenght) : this.ctx.canvas.width / (2 * biggestSide + 2 * this._storageParams.width);
      }
      return this.ctx.canvas.width / (2 * biggestSide + this._storageParams.lenght + this._storageParams.width);
    }
    else return this.ctx.canvas.width / (biggestSide + this._storageParams.lenght + this._storageParams.width);
  }

  clearView() {
    this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
  }

  drawStorage() {
    this.clearView();

    let k = this.getCalculateCoef();
    if (
      this._storageParams.width < this._storageParams.lenght + this._storageParams.lenght/5
      && this._storageParams.width > this._storageParams.lenght - this._storageParams.lenght/5) {
      k = k * 2 / 3;
    }
    let x = (this.ctx.canvas.width * 3) / 7;
    let y = (this.ctx.canvas.height * 6) / 9;
    let wx = this._storageParams.width * k;
    let wy = this._storageParams.lenght * k;
    let h = this._storageParams.height * k;
    let roofY = (this._storageParams.qAllHeight - this._storageParams.height) * k;
    let kl = wy / wx;

    if (wx > wy) {
      x += wx * 0.3;
    }


    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x - wx, y - wx * 0.5);
    this.ctx.lineTo(x - wx, y - h - wx * 0.5);
    this.ctx.lineTo(x, y - h);
    this.ctx.closePath();
    this.ctx.strokeStyle = "#000000";
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x + wy, y - wy * 0.5);
    this.ctx.lineTo(x + wy, y - h - wy * 0.5);
    this.ctx.lineTo(x, y - h);
    this.ctx.closePath();
    this.ctx.strokeStyle = "#000000";
    this.ctx.stroke();

    if (this._storageParams.storageType == AQSFlatStorageTypesEnum.Flat) {
      this.ctx.beginPath();
      this.ctx.moveTo(x, y - h);
      this.ctx.lineTo(x - wx, y - h - wx * 0.5);
      this.ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
      this.ctx.lineTo(x + wy, y - h - wy * 0.5);
      this.ctx.closePath();
      this.ctx.strokeStyle = "#000000";
      this.ctx.stroke();
    }

    if (this._storageParams.storageType == AQSFlatStorageTypesEnum.Peaked) {
      this.ctx.beginPath();
      this.ctx.moveTo(x, y - h);
      this.ctx.lineTo(x - wx, y - h - wx * 0.5);
      this.ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));

      this.ctx.moveTo(x, y - h);
      this.ctx.lineTo(x - wx / 2, y - h - wx / 4 - roofY);
      this.ctx.lineTo(x - wx, y - h - wx * 0.5);
      this.ctx.moveTo(x - wx / 2, y - h - wx / 4 - roofY);
      this.ctx.lineTo(x - wx / 2 + wy, y - h - wx / 4 - wy / 2 - roofY);
      this.ctx.lineTo(x + wy, y - h - wy * 0.5);
      this.ctx.moveTo(x - wx / 2 + wy, y - h - wx / 4 - wy / 2 - roofY);
      this.ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));

      this.ctx.stroke();
    }

    if (this._storageParams.storageType == AQSFlatStorageTypesEnum.Sloped) {
      this.ctx.beginPath();
      this.ctx.moveTo(x, y - h);
      this.ctx.lineTo(x - wx, y - h - wx * 0.5);
      this.ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
      this.ctx.lineTo(x + wy, y - h - wy * 0.5);

      this.ctx.moveTo(x, y - h);
      this.ctx.lineTo(x - wx / 2 + wy / 4, y - h - wx / 4 - wy / 8 - roofY);
      this.ctx.lineTo(x - wx, y - h - wx * 0.5);
      this.ctx.moveTo(x - wx / 2 + wy / 4, y - h - wx / 4 - wy / 8 - roofY);
      this.ctx.lineTo(x + (wy * 3) / 4 - wx / 2, y - h - (wy * 3) / 8 - wx / 4 - roofY);
      this.ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
      this.ctx.moveTo(x + (wy * 3) / 4 - wx / 2, y - h - (wy * 3) / 8 - wx / 4 - roofY);
      this.ctx.lineTo(x + wy, y - h - wy * 0.5);

      this.ctx.stroke();
    }

    if (this._storageParams.storageType == AQSFlatStorageTypesEnum.Truncated) {
      this.ctx.beginPath();
      this.ctx.moveTo(x, y - h);
      this.ctx.lineTo(x - wx, y - h - wx * 0.5);
      this.ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
      this.ctx.lineTo(x + wy, y - h - wy * 0.5);

      this.ctx.moveTo(x, y - h);
      this.ctx.lineTo(x + wy / 4 - wx / 4, y - h - wy / 8 - wx / 8 - roofY);
      this.ctx.moveTo(x - wx, y - h - wx * 0.5);
      this.ctx.lineTo(x - (wx * 3) / 4 + wy / 4, y - h - (wx * 3) / 8 - wy / 8 - roofY);
      this.ctx.lineTo(x + wy / 4 - wx / 4, y - h - wy / 8 - wx / 8 - roofY);
      this.ctx.moveTo(x - (wx * 3) / 4 + wy / 4, y - h - (wx * 3) / 8 - wy / 8 - roofY);
      this.ctx.lineTo(x - (wx * 3) / 4 + (wy * 3) / 4, y - h - (wx * 3) / 8 - (wy * 3) / 8 - roofY);
      this.ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
      this.ctx.moveTo(x - (wx * 3) / 4 + (wy * 3) / 4, y - h - (wx * 3) / 8 - (wy * 3) / 8 - roofY);
      this.ctx.lineTo(x + (wy * 3) / 4 - wx / 4, y - h - (wy * 3) / 8 - wx / 8 - roofY);
      this.ctx.lineTo(x + wy, y - h - wy * 0.5);
      this.ctx.moveTo(x + (wy * 3) / 4 - wx / 4, y - h - (wy * 3) / 8 - wx / 8 - roofY);
      this.ctx.lineTo(x + wy / 4 - wx / 4, y - h - wy / 8 - wx / 8 - roofY);

      this.ctx.stroke();
    }
  }

}
