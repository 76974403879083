import addNewCompanyAdminButton from '../../../../../../img/tutorial/add-new-company-admin-button.png';
import addNewCompanyAdminPopup from '../../../../../../img/tutorial/add-new-company-admin-popup.png';
import addNewCompanyUserButton from '../../../../../../img/tutorial/add-new-company-user-button.png';
import addNewCompanyUserPopup from '../../../../../../img/tutorial/add-new-company-user-popup.png';
import addNewVendorButton from '../../../../../../img/tutorial/add-new-Vendor-button.png';
import addNewVendorPopup from '../../../../../../img/tutorial/add-new-vendor-popup.png';

import emailServiceOverview from '../../../../../../img/tutorial/email-service-overview.png';
import emailServiceOverviewExpand from '../../../../../../img/tutorial/email-service-overview-expand.png';
import emailServiceAddButton from '../../../../../../img/tutorial/email-service-add-button.png';
import emailServiceEnterEmails from '../../../../../../img/tutorial/email-service-enter-emails.png';
import emailServiceAlertTypes from '../../../../../../img/tutorial/email-service-alert-types.png';
import emailServiceSchedule from '../../../../../../img/tutorial/email-service-schedule.png';

import locationManagement from '../../../../../../img/tutorial/location-management.png';

import btxLiveDataLocationsView from '../../../../../../img/tutorial/btx-live-data-locations-view.png';
import btxLiveDataEquipmentView from '../../../../../../img/tutorial/btx-live-data-equipment-view.png';
import btxLiveDataEquipmentAlerts from '../../../../../../img/tutorial/btx-live-data-equipment-alerts.png';
import btxLiveDataSearchControl from '../../../../../../img/tutorial/btx-live-data-search-control.png';
import btxLiveDataSearchEnter from '../../../../../../img/tutorial/btx-live-data-search-enter.png';
import btxLiveDataFilter from '../../../../../../img/tutorial/btx-live-data-filter.png';
import btxLiveDataEquipmentViewButton from '../../../../../../img/tutorial/btx-live-data-equipment-view-button.png';
import btxLiveDataEquipmentSensorsTable from '../../../../../../img/tutorial/btx-live-data-equipment-sensors-table.png';
import btxLiveDataEquipmentSchema from '../../../../../../img/tutorial/btx-live-equipment-schema.png';
import btxLiveDataEquipmentDropdown from '../../../../../../img/tutorial/btx-live-equipment-dropdown.png';
import btxLiveDataEquipmentExportButton from '../../../../../../img/tutorial/btx-live-data-equipment-export-button.png';
import btxLiveDataExportPopupEquipmentTree from '../../../../../../img/tutorial/btx-live-data-export-popup-equipments-tree.png';

import btxHistoricalDateRangeSetup from '../../../../../../img/tutorial/btx-historical-date-range-setup.png';
import btxHistoricalEquipmentSetup from '../../../../../../img/tutorial/btx-historical-equipment-setup.png';
import btxHistoricalGenerateReportButton from '../../../../../../img/tutorial/btx-historical-generate-report-button.png';
import btxHistoricalPlot from '../../../../../../img/tutorial/btx-historical-plot.png';
import btxHistoricalExportButton from '../../../../../../img/tutorial/btx-historical-export-button.png';

import btxNotificationActiveButton from '../../../../../../img/tutorial/btx-notification-active-button.png';
import btxNotificationActiveInfoButton from '../../../../../../img/tutorial/btx-notification-active-info-button.png';
import btxNotificationActiveInfoPopup from '../../../../../../img/tutorial/btx-notification-active-alert-info-popup.png';
import btxNotificationDateSetup from '../../../../../../img/tutorial/btx-notification-date-setup.png';
import btxNotificationHistoryAlerts from '../../../../../../img/tutorial/btx-notification-history-alerts.png';
import btxNotificationFilters from '../../../../../../img/tutorial/btx-notification-filters.png';
import btxNotificationExportButton from '../../../../../../img/tutorial/btx-notification-export-button.png';
import btxNotificationExportDate from '../../../../../../img/tutorial/btx-notification-export-date.png';
import btxNotificationExportAlertType from '../../../../../../img/tutorial/btx-notification-export-alert-type.png';
import btxNotificationExportEquipment from '../../../../../../img/tutorial/btx-notification-export-equipment.png';

import ktxCurrentDataLocationDropdownAdmin from '../../../../../../img/tutorial/ktx-current-data-location-dropdown-admin.png';
import ktxCurrentDataLocationDropdownUser from '../../../../../../img/tutorial/ktx-current-data-location-dropdown-user.png';
import ktxCurrentDataLocationSelectSchemaAdmin from '../../../../../../img/tutorial/ktx-current-data-location-select-schema-admin.png';
import ktxCurrentDataLocationSelectSchemaUser from '../../../../../../img/tutorial/ktx-current-data-location-select-schema-user.png';
import ktxCurrentDataLocationSelectMenuAdmin from '../../../../../../img/tutorial/ktx-current-data-location-select-menu-admin.png';
import ktxCurrentDataLocationSelectMenuUser from '../../../../../../img/tutorial/ktx-current-data-location-select-menu-user.png';
import ktxCurrentDataBinDetailsMeasure from '../../../../../../img/tutorial/ktx-current-data-bin-details-measure.png';
import ktxCurrentDataBinDetailsUnitButton from '../../../../../../img/tutorial/ktx-current-data-bin-details-unit-button.png';
import ktxCurrentDataBinDetailsReadButton from '../../../../../../img/tutorial/ktx-current-data-bin-details-read-button.png';
import ktxCurrentDataBinDetailsBinDropdown from '../../../../../../img/tutorial/ktx-current-data-bin-details-bin-dropdown.png';
import ktxCurrentDataBinDetailsTemperatureLegeng from '../../../../../../img/tutorial/ktx-current-data-bin-details-temperature-legend.png';

import ktxCurrentDataSceduleButton from '../../../../../../img/tutorial/ktx-current-data-scedule-button.png';
import ktxCurrentDataScedulePopup from '../../../../../../img/tutorial/ktx-current-data-scedule-popup.png';

import ktxCurrentDataTemperatureButton from '../../../../../../img/tutorial/ktx-current-data-temperature-button.png';
import ktxCurrentDataTemperaturePopup from '../../../../../../img/tutorial/ktx-current-data-temperature-popup.png';

import ktxCurrentDataLocationExportButtonAdmin from '../../../../../../img/tutorial/ktx-current-data-location-export-button-admin.png';
import ktxCurrentDataLocationExportButtonUser from '../../../../../../img/tutorial/ktx-current-data-location-export-button-user.png';
import ktxCurrentDataBinExportButton from '../../../../../../img/tutorial/ktx-current-data-bin-export-button.png';
import ktxCurrentDataExportPopupBinTree from '../../../../../../img/tutorial/ktx-current-data-export-popup-bin-tree.png';
import ktxCurrentDataExportPopupDownloadButton from '../../../../../../img/tutorial/ktx-current-data-export-popup-download-button.png';

import ktxHistoricalDataDateDropdown from '../../../../../../img/tutorial/ktx-historical-data-date-dropdown.png';
import ktxHistoricalDataSpecificDateBar from '../../../../../../img/tutorial/ktx-historical-data-specific-date-bar.png';
import ktxHistoricalDataTemperatureDropdown from '../../../../../../img/tutorial/ktx-historical-data-temperature-dropdown.png';
import ktxHistoricalDataStorageDropdown from '../../../../../../img/tutorial/ktx-historical-data-storage-dropdown.png';
import ktxHistoricalDataExportButton from '../../../../../../img/tutorial/ktx-historical-data-export-button.png';

import ktxNotificationActiveButton from '../../../../../../img/tutorial/ktx-notification-active-button.png';
import ktxNotificationActiveInfoButton from '../../../../../../img/tutorial/ktx-notification-active-info-button.png';
import ktxNotificationActiveInfoBinDetails from '../../../../../../img/tutorial/ktx-notification-active-info-bin-details.png';
import ktxNotificationDateSetup from '../../../../../../img/tutorial/ktx-notification-date-setup.png';
import ktxNotificationHistoryAlerts from '../../../../../../img/tutorial/ktx-notification-history-alerts.png';
import ktxNotificationFilters from '../../../../../../img/tutorial/ktx-notification-filters.png';
import ktxNotificationExportButton from '../../../../../../img/tutorial/ktx-notification-export-button.png';
import ktxNotificationExportDate from '../../../../../../img/tutorial/ktx-notification-export-date.png';
import ktxNotificationExportAlertType from '../../../../../../img/tutorial/ktx-notification-export-alert-type.png';
import ktxNotificationExportBins from '../../../../../../img/tutorial/ktx-notification-export-bins.png';

import itxLiveDataStorageViewAdmin from '../../../../../../img/tutorial/itx-live-data-storage-view-admin.png';
import itxLiveDataStorageViewUser from '../../../../../../img/tutorial/itx-live-data-storage-view-user.png';

import itxLiveDataProfileButtonAdmin from '../../../../../../img/tutorial/itx-live-data-profile-button-admin.png';
import itxLiveDataProfilePopupAdmin from '../../../../../../img/tutorial/itx-live-data-profile-popup-admin.png';
import itxLiveDataProfileButtonUser from '../../../../../../img/tutorial/itx-live-data-profile-button-user.png';
import itxLiveDataProfilePopupUser from '../../../../../../img/tutorial/itx-live-data-profile-popup-user.png';

import itxLiveDataStorageEditButton from '../../../../../../img/tutorial/itx-live-data-storage-edit-button.png';
import itxLiveDataStorageEditPopup from '../../../../../../img/tutorial/itx-live-data-storage-edit-popup.png';

import itxLiveDataRadarButtonAdmin from '../../../../../../img/tutorial/itx-live-data-radar-button-admin.png';
import itxLiveDataRadarButtonUser from '../../../../../../img/tutorial/itx-live-data-radar-button-user.png';
import itxLiveDataRadarPopupAdmin from '../../../../../../img/tutorial/itx-live-data-radar-popup-admin.png';
import itxLiveDataRadarPopupUser from '../../../../../../img/tutorial/itx-live-data-radar-popup-user.png';

import itxLiveDataSearchAdmin from '../../../../../../img/tutorial/itx-live-data-search-admin.png';
import itxLiveDataSearchUser from '../../../../../../img/tutorial/itx-live-data-search-user.png';
import itxLiveDataFilterAdmin from '../../../../../../img/tutorial/itx-live-data-filter-admin.png';
import itxLiveDataFilterUser from '../../../../../../img/tutorial/itx-live-data-filter-user.png';
import itxLiveDataSortAdmin from '../../../../../../img/tutorial/itx-live-data-sort-admin.png';
import itxLiveDataSortUser from '../../../../../../img/tutorial/itx-live-data-sort-user.png';

import itxLiveDataExportButtonAdmin from '../../../../../../img/tutorial/itx-live-data-export-button-admin.png';
import itxLiveDataExportButtonUser from '../../../../../../img/tutorial/itx-live-data-export-button-user.png';
import itxLiveDataExportPopupAdmin from '../../../../../../img/tutorial/itx-live-data-export-popup-admin.png';
import itxLiveDataExportPopupUser from '../../../../../../img/tutorial/itx-live-data-export-popup-user.png';

import itxNotificationActiveAlertButton from '../../../../../../img/tutorial/itx-notification-active-alert-button.png';
import itxNotificationActiveInfoButton from '../../../../../../img/tutorial/itx-notification-active-info-button.png';
import itxNotificationActiveInfoRadar from '../../../../../../img/tutorial/itx-notification-active-info-radar.png';
import itxNotificationDateSetup from '../../../../../../img/tutorial/itx-notification-date-setup.png';
import itxNotificationHistoricalAlertView from '../../../../../../img/tutorial/itx-notification-historical-alert-view.png';
import itxNotificationFilters from '../../../../../../img/tutorial/itx-notification-filters.png';
import itxNotificationExportButton from '../../../../../../img/tutorial/itx-notification-export-button.png';
import itxNotificationExportDate from '../../../../../../img/tutorial/itx-notification-export-date.png';
import itxNotificationExportAlertType from '../../../../../../img/tutorial/itx-notification-export-alert-type.png';
import itxNotificationExportBins from '../../../../../../img/tutorial/itx-notification-export-bins.png';

import itxReportDateSetup from '../../../../../../img/tutorial/itx-report-date-setup.png';
import itxReportLocationSetup from '../../../../../../img/tutorial/itx-report-location-setup.png';
import itxReportGenerateButton from '../../../../../../img/tutorial/itx-report-generate-button.png';
import itxReportPlot from '../../../../../../img/tutorial/itx-report-plot.png';

import itxReportExportButton from '../../../../../../img/tutorial/itx-report-export-button.png';
import itxReportExportPopup from '../../../../../../img/tutorial/itx-report-export-popup.png';


export const TutorialImages = {
  ADD_NEW_COMPANY_ADMIN_BUTTON: addNewCompanyAdminButton,
  ADD_NEW_COMPANY_ADMIN_POPUP: addNewCompanyAdminPopup,
  ADD_NEW_COMPANY_USER_BUTTON: addNewCompanyUserButton,
  ADD_NEW_COMPANY_USER_POPUP: addNewCompanyUserPopup,
  ADD_NEW_VENDOR_BUTTON: addNewVendorButton,
  ADD_NEW_VENDOR_POPUP: addNewVendorPopup,

  EMAIL_SERVICE_OVERVIEW: emailServiceOverview,
  EMAIL_SERVICE_OVERVIEW_EXPAND: emailServiceOverviewExpand,
  EMAIL_SERVICE_ADD_BUTTON: emailServiceAddButton,
  EMAIL_SERVICE_ENTER_EMAILS: emailServiceEnterEmails,
  EMAIL_SERVICE_ALERT_TYPES: emailServiceAlertTypes,
  EMAIL_SERVICE_SCHEDULE: emailServiceSchedule,

  LOCATION_MANAGEMENT: locationManagement,

  BTX_LIVE_DATA_LOCATIONS_VIEW: btxLiveDataLocationsView,
  BTX_LIVE_DATA_EQUIPMENT_VIEW: btxLiveDataEquipmentView,
  BTX_LIVE_DATA_EQUIPMENT_ALERTS: btxLiveDataEquipmentAlerts,
  BTX_LIVE_DATA_SEARCH_CONTROL: btxLiveDataSearchControl,
  BTX_LIVE_DATA_SEARCH_ENTER: btxLiveDataSearchEnter,
  BTX_LIVE_DATA_FILTER: btxLiveDataFilter,
  BTX_LIVE_DATA_EQUIPMENT_VIEW_BUTTON: btxLiveDataEquipmentViewButton,
  BTX_LIVE_DATA_EQUIPMENT_SENSORS_TABLE: btxLiveDataEquipmentSensorsTable,
  BTX_LIVE_DATA_EQUIPMENT_SCHEMA: btxLiveDataEquipmentSchema,
  BTX_LIVE_DATA_EQUIPMENT_DROPDOWN: btxLiveDataEquipmentDropdown,
  BTX_LIVE_DATA_EQUIPMENT_EXPORT_BUTTON: btxLiveDataEquipmentExportButton,
  BTX_LIVE_DATA_EXPORT_POPUP_EQUIPMENT_TREE: btxLiveDataExportPopupEquipmentTree,

  BTX_HISTORICAL_DATE_RANGE_SETUP: btxHistoricalDateRangeSetup,
  BTX_HISTORICAL_EQUIPMENT_SETUP: btxHistoricalEquipmentSetup,
  BTX_HISTORICAL_GENERATE_REPORT_BUTTON: btxHistoricalGenerateReportButton,
  BTX_HISTORICAL_PLOT: btxHistoricalPlot,
  BTX_HISTORICAL_EXPORT_BUTTON: btxHistoricalExportButton,

  BTX_NOTIFICATION_ACTIVE_BUTTON: btxNotificationActiveButton,
  BTX_NOTIFICATION_ACTIVE_INFO_BUTTON: btxNotificationActiveInfoButton,
  BTX_NOTIFICATION_ACTIVE_INFO_POPUP: btxNotificationActiveInfoPopup,
  BTX_NOTIFICATION_DATE_SETUP: btxNotificationDateSetup,
  BTX_NOTIFICATION_HISTORY_ALERTS: btxNotificationHistoryAlerts,
  BTX_NOTIFICATION_FILTERS: btxNotificationFilters,
  BTX_NOTIFICATION_EXPORT_BUTTON: btxNotificationExportButton,
  BTX_NOTIFICATION_EXPORT_DATE: btxNotificationExportDate,
  BTX_NOTIFICATION_EXPORT_ALERT_TYPE: btxNotificationExportAlertType,
  BTX_NOTIFICATION_EXPORT_EQUIPMENT: btxNotificationExportEquipment,

  KTX_CURRENT_DATA_LOCATION_DROPDOWN_ADMIN: ktxCurrentDataLocationDropdownAdmin,
  KTX_CURRENT_DATA_LOCATION_DROPDOWN_USER: ktxCurrentDataLocationDropdownUser,
  KTX_CURRENT_DATA_LOCATION_SELECT_SCHEMA_ADMIN: ktxCurrentDataLocationSelectSchemaAdmin,
  KTX_CURRENT_DATA_LOCATION_SELECT_SCHEMA_USER: ktxCurrentDataLocationSelectSchemaUser,
  KTX_CURRENT_DATA_LOCATION_SELECT_MENU_ADMIN: ktxCurrentDataLocationSelectMenuAdmin,
  KTX_CURRENT_DATA_LOCATION_SELECT_MENU_USER: ktxCurrentDataLocationSelectMenuUser,
  KTX_CURRENT_DATA_BIN_DETAILS_MEASURE: ktxCurrentDataBinDetailsMeasure,
  KTX_CURRENT_DATA_BIN_DETAILS_UNIT_BUTTON: ktxCurrentDataBinDetailsUnitButton,
  KTX_CURRENT_DATA_BIN_DETAILS_READ_BUTTON: ktxCurrentDataBinDetailsReadButton,
  KTX_CURRENT_DATA_BIN_DETAILS_BIN_DROPDOWN: ktxCurrentDataBinDetailsBinDropdown,
  KTX_CURRENT_DATA_BIN_DETAILS_TEMPERATURE_LEGEND: ktxCurrentDataBinDetailsTemperatureLegeng,

  KTX_CURRENT_DATA_SCEDULE_BUTTON: ktxCurrentDataSceduleButton,
  KTX_CURRENT_DATA_SCEDULE_POPUP: ktxCurrentDataScedulePopup,

  KTX_CURRENT_DATA_TEMPERATURE_BUTTON: ktxCurrentDataTemperatureButton,
  KTX_CURRENT_DATA_TEMPERATURE_POPUP: ktxCurrentDataTemperaturePopup,

  KTX_CURRENT_DATA_LOCATION_EXPORT_BUTTON_ADMIN: ktxCurrentDataLocationExportButtonAdmin,
  KTX_CURRENT_DATA_LOCATION_EXPORT_BUTTON_USER: ktxCurrentDataLocationExportButtonUser,
  KTX_CURRENT_DATA_BIN_EXPORT_BUTTON: ktxCurrentDataBinExportButton,
  KTX_CURRENT_DATA_EXPORT_POPUP_BIN_TREE: ktxCurrentDataExportPopupBinTree,
  KTX_CURRENT_DATA_EXPORT_POPUP_DOWNLOAD_BUTTON: ktxCurrentDataExportPopupDownloadButton,

  KTX_HISTORICAL_DATA_DATE_DROPDOWN: ktxHistoricalDataDateDropdown,
  KTX_HISTORICAL_DATA_SPECIFIC_DATE_BAR: ktxHistoricalDataSpecificDateBar,
  KTX_HISTORICAL_DATA_TEMPERATURE_DROPDOWN: ktxHistoricalDataTemperatureDropdown,
  KTX_HISTORICAL_DATA_STORAGE_DROPDOWN: ktxHistoricalDataStorageDropdown,
  KTX_HISTORICAL_DATA_EXPORT_BUTTON: ktxHistoricalDataExportButton,

  KTX_NOTIFICATION_ACTIVE_BUTTON: ktxNotificationActiveButton,
  KTX_NOTIFICATION_ACTIVE_INFO_BUTTON: ktxNotificationActiveInfoButton,
  KTX_NOTIFICATION_ACTIVE_INFO_BIN_DETAILS: ktxNotificationActiveInfoBinDetails,
  KTX_NOTIFICATION_DATE_SETUP: ktxNotificationDateSetup,
  KTX_NOTIFICATION_HISTORY_ALERTS: ktxNotificationHistoryAlerts,
  KTX_NOTIFICATION_FILTERS: ktxNotificationFilters,
  KTX_NOTIFICATION_EXPORT_BUTTON: ktxNotificationExportButton,
  KTX_NOTIFICATION_EXPORT_DATE: ktxNotificationExportDate,
  KTX_NOTIFICATION_EXPORT_ALERT_TYPE: ktxNotificationExportAlertType,
  KTX_NOTIFICATION_EXPORT_BINS: ktxNotificationExportBins,

  ITX_LIVE_DATA_STORAGE_VIEW_ADMIN: itxLiveDataStorageViewAdmin,
  ITX_LIVE_DATA_STORAGE_VIEW_USER: itxLiveDataStorageViewUser,

  ITX_LIVE_DATA_PROFILE_BUTTON_ADMIN: itxLiveDataProfileButtonAdmin,
  ITX_LIVE_DATA_PROFILE_POPUP_ADMIN: itxLiveDataProfilePopupAdmin,
  ITX_LIVE_DATA_PROFILE_BUTTON_USER: itxLiveDataProfileButtonUser,
  ITX_LIVE_DATA_PROFILE_POPUP_USER: itxLiveDataProfilePopupUser,

  ITX_LIVE_DATA_STORAGE_EDIT_BUTTON: itxLiveDataStorageEditButton,
  ITX_LIVE_DATA_STORAGE_EDIT_POPUP: itxLiveDataStorageEditPopup,

  ITX_LIVE_DATA_RADAR_BUTTON_ADMIN: itxLiveDataRadarButtonAdmin,
  ITX_LIVE_DATA_RADAR_BUTTON_USER: itxLiveDataRadarButtonUser,
  ITX_LIVE_DATA_RADAR_POPUP_ADMIN: itxLiveDataRadarPopupAdmin,
  ITX_LIVE_DATA_RADAR_POPUP_USER: itxLiveDataRadarPopupUser,

  ITX_LIVE_DATA_SEARCH_ADMIN: itxLiveDataSearchAdmin,
  ITX_LIVE_DATA_SEARCH_USER: itxLiveDataSearchUser,
  ITX_LIVE_DATA_FILTER_ADMIN: itxLiveDataFilterAdmin,
  ITX_LIVE_DATA_FILTER_USER: itxLiveDataFilterUser,
  ITX_LIVE_DATA_SORT_ADMIN: itxLiveDataSortAdmin,
  ITX_LIVE_DATA_SORT_USER: itxLiveDataSortUser,

  ITX_LIVE_DATA_EXPORT_BUTTON_ADMIN: itxLiveDataExportButtonAdmin,
  ITX_LIVE_DATA_EXPORT_BUTTON_USER: itxLiveDataExportButtonUser,
  ITX_LIVE_DATA_EXPORT_POPUP_ADMIN: itxLiveDataExportPopupAdmin,
  ITX_LIVE_DATA_EXPORT_POPUP_USER: itxLiveDataExportPopupUser,

  ITX_NOTIFICATION_ACTIVE_ALERT_BUTTON: itxNotificationActiveAlertButton,
  ITX_NOTIFICATION_ACTIVE_INFO_BUTTON: itxNotificationActiveInfoButton,
  ITX_NOTIFICATION_ACTIVE_INFO_RADAR: itxNotificationActiveInfoRadar,
  ITX_NOTIFICATION_DATE_SETUP: itxNotificationDateSetup,
  ITX_NOTIFICATION_HISTORICAL_ALERT_VIEW: itxNotificationHistoricalAlertView,
  ITX_NOTIFICATION_FILTERS: itxNotificationFilters,
  ITX_NOTIFICATION_EXPORT_BUTTON: itxNotificationExportButton,
  ITX_NOTIFICATION_EXPORT_DATE: itxNotificationExportDate,
  ITX_NOTIFICATION_EXPORT_ALERT_TYPE: itxNotificationExportAlertType,
  ITX_NOTIFICATION_EXPORT_BINS: itxNotificationExportBins,

  ITX_REPORT_DATE_SETUP: itxReportDateSetup,
  ITX_REPORT_LOCATION_SETUP: itxReportLocationSetup,
  ITX_REPORT_GENERATE_BUTTON: itxReportGenerateButton,
  ITX_REPORT_PLOT: itxReportPlot,

  ITX_REPORT_EXPORT_BUTTON: itxReportExportButton,
  ITX_REPORT_EXPORT_POPUP: itxReportExportPopup,


}
