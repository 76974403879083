import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UpdaterDeviceDatasourceService } from '@services';
import { ErrorModel } from 'src/app/common';

@Component({
  selector: 'greensleeves-updater-delete-popup',
  templateUrl: './updater-delete-popup.component.html',
  styleUrls: []
})
export class UpdaterDeletePopupComponent implements OnInit {
  @Output() onDeleteUpdater = new EventEmitter<number>();
  _isHidden = true;
  _isSubmitted = false;

  _updaterId: string;
  _locationId: number;
  _error: string;


  constructor(
    private _updaterDeviceDatasourceService: UpdaterDeviceDatasourceService
  ) { }

  ngOnInit() {
  }

  public show(updaterId: string, locationId: number){
    this._updaterId = updaterId;
    this._locationId = locationId;
    this._isHidden = false;
  }

  async onSubmit(){
    if (this._updaterId && this._locationId && !this._isSubmitted){
      this._isSubmitted = true;
      const result = await this._updaterDeviceDatasourceService.delete(this._updaterId, this._locationId);

      this._isSubmitted = false;
      if (result === true){
        this.onDeleteUpdater.emit(this._locationId);
        this.onClickCancel();
      } else if (result === false) {
        this._error = 'Unknown error occured';
      } else {
        const errorModel = result as ErrorModel;
        if (errorModel) {
          this._error = errorModel.message;
        }
      }
    }
  }

  onClickCancel(){
    this._isHidden = true;
    this._error = null;
    this._updaterId = null;
  }
}
