import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RoutePaths, PasswordUpdateModel, AccountService } from '../common';

@Component({
  selector: 'greensleeves-password-update',
  templateUrl: './password-update.component.html'
})

export class PasswordUpdateComponent implements OnInit {
  public token: string;
  _passwordUpdateFormGroup: FormGroup;
  _isSubmit = false;
  _isPasswordsMatch: boolean = false;
  _isShowPassword: boolean;
  _isShowPasswordConfirm: boolean;
  _privacyLink = RoutePaths.PRIVACY;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _accountService: AccountService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this._passwordUpdateFormGroup = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50)])],
      confirm: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50)])]
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
  });
  }

  get f() { return this._passwordUpdateFormGroup.controls; }

  onSubmit() {
    this._isSubmit = true;
    this._isPasswordsMatch = false;

    const password = this.f.password.value.trim();
    const confirmPassword = this.f.confirm.value.trim();

    if (this._passwordUpdateFormGroup.invalid) {
      return;
    }

    if (password != confirmPassword) {
      this._isPasswordsMatch = true;
      return;
    }
    else{
      const passwordUpdateModel = new PasswordUpdateModel(this.token, password, confirmPassword);
      const result = this._accountService.passwordUpdate(passwordUpdateModel);
      if (result != null)
      {
        this.router.navigateByUrl(RoutePaths.SIGN_IN);
      }
    }
  }

  onSwitchShowHidePassword(){
    this._isShowPassword = !this._isShowPassword;
  }

  onSwitchShowHidePasswordConfirmation(){
    this._isShowPasswordConfirm = !this._isShowPasswordConfirm;
  }
}
