import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DeviceLogInfoViewModel } from '../../models';
import { DeviceLogApiService } from '../http';
import { ErrorModel } from './../../../common';

@Injectable({
  providedIn: 'root'
})
export class DeviceLogDatasourceService {
  public deviceLogInfo$: BehaviorSubject<DeviceLogInfoViewModel> = new BehaviorSubject(new DeviceLogInfoViewModel());
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(private _deiveLogApi: DeviceLogApiService) { }

  public async getLogsInfoList(deiviceId: string) {
    try {
      let result = await this._deiveLogApi.getLogsInfoList(deiviceId) as DeviceLogInfoViewModel;
      this.deviceLogInfo$.next(result);
      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async downloadLogFile(deiviceId: string, fileName: string): Promise<Blob | ErrorModel> {
    try {
      const result = await this._deiveLogApi.downloadLogFile(deiviceId, fileName);
      return Promise.resolve(new Blob([result as BinaryType], { type: "application/txt" }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadLogFiles(deiviceId: string, fileNames: string[]): Promise<Blob | ErrorModel> {
    try {
      const result = await this._deiveLogApi.downloadLogFiles(deiviceId, fileNames);
      return Promise.resolve(new Blob([result as BinaryType], { type: "application/zip" }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async addLogLinkToDeviceTwin(deviceId: string): Promise<boolean> {
    try {
      const result = await this._deiveLogApi.addLogLinkToDeviceTwin(deviceId);
      return result as boolean;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return false;
      } else {
        this.errors$.next(error);
        return false;
      }
    }
  }
}
