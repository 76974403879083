import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'greensleeves-aqs-confirm-popup',
  templateUrl: './aqs-confirm-popup.component.html',
  styles: []
})
export class AqsConfirmPopupComponent implements OnInit {
  _isHidden = true;
  _isConfirmCapacityAndEffDepth = true;
  _confirmEffDepthMessage: string = '';
  _confirmCapacityMessage: string = '';
  confirm = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  showForConfirmCapacityAndEffDepth(newEffDepth: number, oldEffDepth: number, newCapacity: number, oldCapacity: number) {
    this.setDialogMessages(newEffDepth, oldEffDepth, newCapacity, oldCapacity);
    this._isHidden = false;
    this._isConfirmCapacityAndEffDepth = true;
  }
  onClose() {
    this.onCancel();
  }

  onCancel() {
    this.confirm.emit(false);
    this._isHidden = true;
  }

  onSubmit() {
    this.confirm.emit(true);
    this._isHidden = true;
  }

  private setDialogMessages(newEffDepth: number, oldEffDepth: number, newCapacity: number, oldCapacity: number) {
    this._confirmCapacityMessage = `Computer Calculated Tank Capacity is <span class="text-bold">${oldCapacity} Bushels</span>`;
    this._confirmEffDepthMessage = `Computer Calculated Effective Grain Depth is <span class="text-bold">${oldEffDepth} Feet</span>`;
    if (newCapacity != oldCapacity) {
      let diffpersent = Math.round(Math.abs(oldCapacity - newCapacity) * 100 / oldCapacity * 10) / 10;
      //INT(ABS((CAPACITY - UCAPACITY) * 100) / CAPACITY * 10 + .5) / 10) + "%"
      this._confirmCapacityMessage += '<br>'
        + `User Modified Tank Capacity is <span class="text-bold">${newCapacity} Bushels</span><br> `
        + `Difference is <span class="text-bold">${diffpersent}%</span>`;
    }
    if (newEffDepth != oldEffDepth) {
      let diffpersent = Math.round(Math.abs(oldEffDepth - newEffDepth) * 100 / oldEffDepth * 10) / 10;
      //INT(ABS((EFFDEPTH - UEFFDEPTH) * 100) / EFFDEPTH * 10 + .5) / 10)
      this._confirmEffDepthMessage += '<br>'
        + `User Effective Grain Depth is <span class="text-bold">${newEffDepth} Feet</span><br> `
        + `Difference is <span class="text-bold">${diffpersent}%</span>`;
    }
  }
}
