import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Chart } from 'chart.js';
import { Calendar } from 'primeng/calendar';

import { ErrorCodeEnum, ErrorModel, UserRoleEnum } from '../../../common';
import { CalendarDefaultValues, CapacityUnitLabel, CapacityUnitLiquidLabel, CapacityUnitLoosLabel, DefaultDateRangeLabel, EnglishDateLocale, ItxReportRadarReadingUnitLabel, ItxReportReadingUnitLabel, SizeMeasureLabel, TemperatureMeasureLabel } from "../../constants";
import { CapacityUnitEnum, DefaultDateRangeEnum, ItxBinTypeEnum, ItxReportsReadingUnitEnum, SizeMeasureEnum, TemperatureMeasureEnum, TutorialMenuEnum } from '../../enums';
import { ItxBinDataSourceService, ReportDatasourceService, RoleService } from '../../services';
import { ItxLocationReportFilter, ItxTelemetry, ItxTelemetryDataResponse, ReportDataFilterPost, ReportHistoricalDataResponse } from './../../models';
import { ItxExportReportPopupComponent, TutorialPopupComponent } from './../popups';

Chart.defaults.multicolorLine = Chart.defaults.line;
Chart.controllers.multicolorLine = Chart.controllers.line.extend({
  draw: function (ease) {
    let startIndex = 0,
      meta = this.getMeta(),
      points = meta.data || [],
      area = this.chart.chartArea,
      originalDatasets = meta.dataset._children;

    function _setLine(newColor, borderDash, meta) {
      meta.dataset._view.borderColor = newColor;
      meta.dataset._view.borderDash = borderDash;
    }

    let segmentColor = 'rgba(255,0,0,1)'
    let nanIndex: number[] = [];
    let mainColor: string;
    points.forEach(x => {
      if (x._view.skip) {
        nanIndex.push(x._index);
        mainColor = x._view.borderColor;
      }
    });

    nanIndex.forEach(x => {
      _setLine(mainColor, [], meta);
      meta.dataset._children = originalDatasets.slice(startIndex, x);
      meta.dataset.draw();
      startIndex = x - 1;

      _setLine(segmentColor, [3, 3], meta);
      meta.dataset._children = originalDatasets.slice(startIndex, x + 2);
      meta.dataset.draw();
      startIndex = x + 1;
    });

    if (nanIndex.length > 0) {
      _setLine(mainColor, [], meta);
    }
    meta.dataset._children = originalDatasets.slice(startIndex);
    meta.dataset.draw();
    meta.dataset._children = originalDatasets;

    points.forEach(function (point) {
      point.draw(area);
    });
  }
});

@Component({
  selector: 'greensleeves-itx-reports-tab',
  templateUrl: './itx-reports-tab.component.html',
  styles: []
})

export class ItxReportsTabComponent implements OnInit {

  private readonly OneMinuteSeconds: number = 60;
  private readonly NoDataRateInMinute: number = 65;

  @ViewChild(ItxExportReportPopupComponent, { read: false, static: false })
  private _exportReportModal: ItxExportReportPopupComponent;

  @ViewChild("calendar", { static: false })
  private calendar: Calendar;

  @ViewChild('chartReport', { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  private ctx: CanvasRenderingContext2D;
  private chart: Chart;
  private defaultDateRangePreviousState: DefaultDateRangeEnum = null;

  options: any;
  value: Date;
  startDate: Date;
  endDate: Date;
  _dataLocale: EnglishDateLocale = new EnglishDateLocale();
  _reportFilter: ReportDataFilterPost = new ReportDataFilterPost();
  _selectedDefaultDateRange: DefaultDateRangeEnum = null;
  _isAvgValues = false;

  _reportPeriod: FormGroup;
  _rangeDate: Date[] = [];
  _dateRange: { label: string, value: DefaultDateRangeEnum }[] = [];
  _selectedDateRange: { label: string, value: DefaultDateRangeEnum } = { label: "Day", value: DefaultDateRangeEnum.Day };
  _selectReportData: FormGroup;

  _locationFilter: {
    label: string, value: {
      bins: {
        label: string, value: {
          label: string, id: number, type: ItxBinTypeEnum, locationId: number
          radars: {
            id: number,
            radarId: number,
            radarAddress: number,
          }[]
        }
      }[]
    }
  }[] = [];

  _selectedLocationFilter: {
    bins: {
      label: string, value: {
        label: string, id: number, type: ItxBinTypeEnum, locationId: number,
        radars: {
          id: number,
          radarId: number,
          radarAddress: number,
        }[]
      }
    }[]
  };

  _storageFilter: {
    label: string, value: {
      label: string, id: number, type: ItxBinTypeEnum, locationId: number,
      radars: {
        id: number,
        radarId: number,
        radarAddress: number,
      }[]
    }
  }[];

  _selectedStorageFilter: {
    label: string, id: number, type: ItxBinTypeEnum, locationId: number,
    radars: {
      id: number,
      radarId: number,
      radarAddress: number,
    }[]
  };

  _readingUnit: { label: string, value: { label: string, value: ItxReportsReadingUnitEnum } }[] = [];
  _selectedReadingUnit: { label: string, value: ItxReportsReadingUnitEnum };

  _measureUnit: { label: string, value: { label: string, value: number } }[] = []
  _selectedMeasure: { label: string, value: number };

  _radarReadingUnit: { label: string, value: { label: string, value: ItxReportsReadingUnitEnum } }[] = [];
  _selectedRadarReadingUnit: { label: string, value: ItxReportsReadingUnitEnum };

  sizeUnit: { label: string, value: { label: string, value: SizeMeasureEnum } }[] = [];
  capacityUnitLoos: { label: string, value: { label: string, value: CapacityUnitEnum } }[] = [];
  capacityUnitLiquid: { label: string, value: { label: string, value: CapacityUnitEnum } }[] = [];
  temperatureUnit: { label: string, value: { label: string, value: TemperatureMeasureEnum } }[] = [];
  itxBinIds: number[] = [];
  capacityUnit: { label: string, value: CapacityUnitEnum }[] = [];
  isReportGenerated: boolean;
  _isReportRun = false;
  _isReportEmpty: boolean;
  _itxReportReadingUnit = ItxReportsReadingUnitEnum;
  _isCollapseSidebar: boolean = false;

  collapseSidebar() {
    this._isCollapseSidebar = true;
  };

  expandSidebar() {
    this._isCollapseSidebar = false;
  }

  private chartColors: string[] = [
    'rgba(54, 162, 235, 1)', 'rgba(54, 235, 93, 1)', 'rgba(191, 54, 235, 1)', 'rgba(6, 6, 6, 1)', 'rgba(139,69,19,1)', 'rgba(112,128,144, 1)', 'rgba(245, 40, 145, 1)'
  ];

  get _startDate() {
    return this._rangeDate[0];
  }
  get _endDate() {
    return this._rangeDate[1] == null ? this._rangeDate[0] : this._rangeDate[1];
  }

  get isTutorialUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser) || this._roleService.userHasRole(UserRoleEnum.Vendor);
  }

  private readingUnitForBinOrSiloType: { label: string, value: { label: string, value: ItxReportsReadingUnitEnum } }[] = [];
  private readingUnitIfNoBinOrSiloType: { label: string, value: { label: string, value: ItxReportsReadingUnitEnum } }[] = [];

  constructor(
    private _itxBinDataService: ItxBinDataSourceService,
    private _reportDataService: ReportDatasourceService,
    private _formBuilder: FormBuilder,
    private _roleService: RoleService,
  ) {

    this._selectReportData = this._formBuilder.group({
      reportType: null,
      location: null,
      storage: null,
      readingUnit: null,
      radar: null,
      radarReadingUnit: null,
      measurement: null,
    })
  }

  async ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.setSelectParameters();
    this.resetCalendar();
    this._reportPeriod = this._formBuilder.group({
      selectedPeriod: [this._rangeDate],
      dateRange: [],
      startPeriod: [this._startDate],
      endOfPeriod: [this._endDate],
    });
    this.setDefaultFilterValues();
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this._reportFilter.dateFrom = (today.getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this._reportFilter.dateTo = (today.getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this.createEmptyChart();
  }

  private setSelectParameters() {
    for (const [key, value] of Object.entries(SizeMeasureLabel)) {
      this.sizeUnit.push({ label: value, value: { label: value, value: Number(key) } });
    }

    for (const [key, value] of Object.entries(ItxReportReadingUnitLabel)) {
      this.readingUnitForBinOrSiloType.push({ label: value, value: { label: value, value: Number(key) } })
      if (Number(key) != ItxReportsReadingUnitEnum.CalcUsedCapacity && Number(key) != ItxReportsReadingUnitEnum.CalcAvailableCapacity) {
        this.readingUnitIfNoBinOrSiloType.push({ label: value, value: { label: value, value: Number(key) } })
      }
    }

    for (const [key, value] of Object.entries(ItxReportRadarReadingUnitLabel)) {
      this._radarReadingUnit.push({ label: value, value: { label: value, value: Number(key) } })
    }

    for (const [key, value] of Object.entries(DefaultDateRangeLabel)) {
      this._dateRange.push({ label: value, value: Number(key) })
    }

    for (const [key, value] of Object.entries(CapacityUnitLiquidLabel)) {
      this.capacityUnitLiquid.push({ label: value, value: { label: value, value: Number(key) } });
    }

    for (const [key, value] of Object.entries(CapacityUnitLoosLabel)) {
      this.capacityUnitLoos.push({ label: value, value: { label: value, value: Number(key) } });
    }

    this.temperatureUnit.push({ label: TemperatureMeasureLabel[1], value: { label: TemperatureMeasureLabel[1], value: TemperatureMeasureEnum.Fahrenheit } });
    this.temperatureUnit.push({ label: TemperatureMeasureLabel[0], value: { label: TemperatureMeasureLabel[0], value: TemperatureMeasureEnum.Celsius } });

    for (const [key, value] of Object.entries(CapacityUnitLabel)) {
      this.capacityUnit.push({ label: value, value: Number(key) });
    }
  }

  private async setDefaultFilterValues() {
    let locations = await this._itxBinDataService.getLocationReportFilters() as ItxLocationReportFilter[];
    if (locations && locations.length > 0) {
      locations.forEach(x => {
        if (x.itxBins.length > 0) {
          this._locationFilter.push({
            label: x.locationName, value: {
              bins: x.itxBins.map(b => ({
                label: b.name,
                value: {
                  label: b.name, id: b.id, type: b.binType, locationId: x.locationId,
                  radars: b.radars.map(r => ({
                    id: r.id, radarId: r.radarId, radarAddress: r.radarIdAddress
                  })),
                }
              }))
            }
          });
        }
      });
      this._selectedLocationFilter = this._locationFilter[0].value;
      this._storageFilter = this._selectedLocationFilter.bins;
      this._selectedStorageFilter = this._storageFilter[0].value;

      this.fillReadingUnit(this._selectedStorageFilter.type);

      this._selectedReadingUnit = this._readingUnit[0].value;
      if (this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageConical ||
        this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageFlat ||
        this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageSloped) {
        this._measureUnit.push(...this.capacityUnitLiquid)
      } else {
        this._measureUnit.push(...this.capacityUnitLoos);
      }
      this._selectedMeasure = this._measureUnit[0].value
      this.setReportDataFilter();
    }
  }

  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  onChangeSelectedLocation() {
    this.isReportGenerated = false;
    this._selectedStorageFilter = undefined;
    this._measureUnit = [];
    this._selectedReadingUnit = undefined;
    this._selectedRadarReadingUnit = undefined;
    this._storageFilter = this._selectedLocationFilter.bins;
    this._selectedMeasure = undefined;
    this._isReportEmpty = false;
  }

  onChangeSelectedStorage() {
    this.isReportGenerated = false;
    this._selectedReadingUnit = undefined;
    this.fillReadingUnit(this._selectedStorageFilter.type);
    this._measureUnit = [];
    this._selectedMeasure = undefined;
    this._isReportEmpty = false;
    this._selectedRadarReadingUnit = undefined;
  }

  onChangeSelectedReadingUnit() {
    this.isReportGenerated = false;
    this._measureUnit = [];
    this._selectedMeasure = undefined;
    this._selectedRadarReadingUnit = undefined;
    switch (this._selectedReadingUnit.value) {
      case ItxReportsReadingUnitEnum.Temperature:
        this._measureUnit.push(...this.temperatureUnit);
        break;
      case ItxReportsReadingUnitEnum.HeadSpace:
      case ItxReportsReadingUnitEnum.FillHeight:
        this._measureUnit.push(...this.sizeUnit);
        break;
      case ItxReportsReadingUnitEnum.CalcAvailableCapacity:
      case ItxReportsReadingUnitEnum.CalcUsedCapacity:
      case ItxReportsReadingUnitEnum.AvailableCapacity:
      case ItxReportsReadingUnitEnum.UsedCapacity:
        if (this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageConical ||
          this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageFlat ||
          this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageSloped) {
          this._measureUnit.push(...this.capacityUnitLiquid);
        } else {
          this._measureUnit.push(...this.capacityUnitLoos);
        }
        break;
      case ItxReportsReadingUnitEnum.PercentFull:
        this._measureUnit.push({ label: '%', value: { label: '%', value: 0 } });
        this._selectedMeasure = { label: '%', value: 0 };
        break;
    }
  }

  onChangeSelectedRadarReadingUnit() {
    this.isReportGenerated = false;
    this._selectedMeasure = undefined;
    this._selectedReadingUnit = undefined;
    this._measureUnit = [];
    switch (this._selectedRadarReadingUnit.value) {
      case ItxReportsReadingUnitEnum.HeadSpace:
        this._measureUnit.push(...this.sizeUnit);
        break;
      case ItxReportsReadingUnitEnum.Temperature:
        this._measureUnit.push(...this.temperatureUnit);
        break;
      case ItxReportsReadingUnitEnum.PercentFull:
        this._measureUnit.push({ label: '%', value: { label: '%', value: 0 } });
        this._selectedMeasure = { label: '%', value: 0 };
        break;
      case ItxReportsReadingUnitEnum.UsedCapacity:
        if (this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageConical ||
          this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageFlat ||
          this._selectedStorageFilter.type == ItxBinTypeEnum.LiquidStorageSloped) {
          this._measureUnit.push(...this.capacityUnitLiquid);
        } else {
          this._measureUnit.push(...this.capacityUnitLoos);
        }
        break;
    }
  }

  onChangeSelectedMeasure() {
    this.isReportGenerated = false;
    //this.createunitLabel();
  }

  onChangeSelectedDateValue(option) {
    this.isReportGenerated = false;
    this._dateRange = option.value;
  }

  onSeclectCalendar() {
    this._selectedDefaultDateRange = null;
  }

  async onGenerateReport() {
    this._isReportRun = true;
    this.setReportDataFilter();
    let result = await this._reportDataService.getItxReportHistoricalDataByFilter(this._reportFilter);
    this.clearChart();
    if (result) {
      if (result instanceof ErrorModel && (result.code == ErrorCodeEnum.ItxStorageNotFound || result.code == ErrorCodeEnum.LocationNotFound)) {
        this._isReportEmpty = true;
        this.isReportGenerated = false;
      }
      else {
        this._isReportEmpty = false;
        this.isReportGenerated = true;
        let data = result as ReportHistoricalDataResponse;
        this._isAvgValues = data.isAverageValues;
        let chartDatasets = this.createDataSets(data.reportData);
        if (chartDatasets) {
          this.chart = new Chart(this.ctx, {
            type: 'multicolorLine',
            plugins: [this.verticalHoverPlugin],
            data: {
              datasets: chartDatasets
            },
            options: {
              scales: {
                xAxes: [{
                  type: "time",
                  time: this.scalesChart(data.reportData),
                  bounds: 'ticks',
                  ticks: {
                    major: {
                      enabled: true,
                      fontStyle: 'bold',
                    },
                  },
                }],
              },
              tooltips: {
                mode: "index",
                intersect: false,
                callbacks: {
                  label: function (tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                      label += ': ';
                    }
                    label += new Intl.NumberFormat('en-US').format(tooltipItem.yLabel);
                    return label;
                  }
                },
              },
              elements: {
                line: {
                  tension: 0
                }
              },
              hover: {
                intersect: false
              },
            }
          });
        }
        else {
          this.createEmptyChart();
          this._isReportEmpty = true;
          this.isReportGenerated = false;
        }
      }
    }
    else {
      this.createEmptyChart();
      this._isReportEmpty = true;
      this.isReportGenerated = false;
    }
    this._isReportRun = false;
  }

  onApplyDateRangeFilter() {
    this.isReportGenerated = false;
    if (this._rangeDate == undefined || this._rangeDate.length == 0) {
      this.resetCalendar();
    }
    if (this._rangeDate[1] == undefined || this._rangeDate[1] == null) {
      this._rangeDate[1] = this._rangeDate[0];
    }
    this._reportFilter.dateFrom = (this._rangeDate[0].getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this._reportFilter.dateTo = (this._rangeDate[1].getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this.defaultDateRangePreviousState = this._selectedDefaultDateRange;
    this.calendar.toggle();
  }

  onCloseCalendar() {
    if (this._reportFilter.dateFrom == null && this._reportFilter.dateTo == null) {
      this.resetCalendar();
      return;
    }
    if (this.defaultDateRangePreviousState != null) {
      this.setDefaultDate(this.defaultDateRangePreviousState);
      return;
    }
    this._rangeDate = [];
    this._rangeDate.push(new Date(this._reportFilter.dateFrom * CalendarDefaultValues.defaultOneSecondMilliseconds));
    this._rangeDate.push(new Date(this._reportFilter.dateTo * CalendarDefaultValues.defaultOneSecondMilliseconds));
  }

  setDefaultDate(defaultDateRange: DefaultDateRangeEnum) {
    let endDate = new Date();
    let startDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    this._rangeDate = [];
    switch (defaultDateRange) {
      case DefaultDateRangeEnum.Week:
        startDate.setDate(startDate.getDate() - CalendarDefaultValues.defaultOneWeekDays);
        break;
      case DefaultDateRangeEnum.Month:
        startDate.setDate(startDate.getDate() - CalendarDefaultValues.defaultOneMonthDays);
        break;
    }
    this._selectedDefaultDateRange = defaultDateRange;
    this._rangeDate.push(startDate);
    this._rangeDate.push(endDate);
    this._selectedDateRange = this._dateRange.find(x => x.value == defaultDateRange);
  }

  onClickExportReport() {
    var reportFilter = new ReportDataFilterPost();
    reportFilter.dateFrom = this._reportFilter.dateFrom;
    reportFilter.dateTo = this._reportFilter.dateTo;
    reportFilter.itxBinId = this._reportFilter.itxBinId;
    reportFilter.locationId = this._reportFilter.locationId;
    reportFilter.reportsReadingUnit = this._reportFilter.reportsReadingUnit;
    reportFilter.reportsRadarReadingUnit = this._reportFilter.reportsRadarReadingUnit;
    reportFilter.unit = this._reportFilter.unit;
    reportFilter.radarIds = null;
    if (this._reportFilter.reportsRadarReadingUnit != null) {
      for (var i = 0; i < this.chart.data.datasets.length; i++) {
        if (this.chart.isDatasetVisible(i)) {
          if (reportFilter.radarIds == null) {
            reportFilter.radarIds = [];
          }

          if (i < this._reportFilter.radarIds.length) {
            reportFilter.radarIds.push(this._reportFilter.radarIds[i]);
          }
        }
      }

      if (reportFilter.radarIds == null) reportFilter.radarIds = this._reportFilter.radarIds;
    }

    this._exportReportModal.showDownloadHistoricalReport(reportFilter, this._selectedStorageFilter.label);
  }

  private resetCalendar() {
    this.defaultDateRangePreviousState = null;
    this._selectedDefaultDateRange = null;
    this._rangeDate = [];
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this._rangeDate.push(today);
    this._rangeDate.push(today);
  }

  private setReportDataFilter() {
    this._reportFilter.locationId = this._selectedStorageFilter.locationId;
    this._reportFilter.itxBinId = this._selectedStorageFilter.id;
    this._reportFilter.reportsReadingUnit = this._selectedReadingUnit != undefined ? this._selectedReadingUnit.value : null;
    this._reportFilter.reportsRadarReadingUnit = this._selectedRadarReadingUnit != undefined ? this._selectedRadarReadingUnit.value : null;
    this._reportFilter.radarIds = this._selectedRadarReadingUnit != undefined ? this._selectedStorageFilter.radars.map(r => r.radarId) : null;
    this._reportFilter.unit = this._selectedMeasure.value;
  }

  private fillReadingUnit(binType: ItxBinTypeEnum) {
    let isSiloOrBin = binType == ItxBinTypeEnum.BinConical || binType == ItxBinTypeEnum.BinFlat || binType == ItxBinTypeEnum.BinSloped
      || binType == ItxBinTypeEnum.SiloConical || binType == ItxBinTypeEnum.SiloFlat || binType == ItxBinTypeEnum.SiloSloped;

    isSiloOrBin ? this._readingUnit = this.readingUnitForBinOrSiloType : this._readingUnit = this.readingUnitIfNoBinOrSiloType;
  }

  private clearChart() {
    if (this.chart !== undefined) this.chart.destroy();
  }

  private createEmptyChart() {
    this.clearChart();
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this.chart = new Chart(this.ctx, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        scales: {
          xAxes: [{
            type: "time",
            time: {
              unit: "hour",
              displayFormats: {
                hour: 'HH:mm',
              }
            },
            ticks: {
              min: new Date().setHours(0, 0, 0, 0),
              max: new Date().setHours(24, 0, 0, 0),
            },
          }],
          yAxes: [{
            ticks: {
              max: 100,
              min: 0,
            }
          }]
        },
        elements: {
          line: {
            tension: 0
          }
        },
      }
    });
  }

  unitLabel() {
    let unitLabel: string;
    if (this._selectedReadingUnit != undefined) {
      unitLabel = (this._selectedReadingUnit ? this._selectedReadingUnit.label : '') + (this._selectedMeasure ? ` (${this._selectedMeasure.label})` : '');
    }
    else if (this._selectedRadarReadingUnit != undefined) {
      unitLabel = 'Radars'
        + (this._selectedRadarReadingUnit ? `: ${this._selectedRadarReadingUnit.label}` : '')
        + (this._selectedMeasure ? ` (${this._selectedMeasure.label})` : '');
    }
    else {
      unitLabel = '';
    }
    return unitLabel;
  }

  private scalesChart(telemetry: ItxTelemetryDataResponse[]) {
    let dates: number[] = [];
    telemetry.forEach(x => {
      dates.push(Math.min(...x.reportDataTelemetry.map(i => i.timestamp)));
      dates.push(Math.max(...x.reportDataTelemetry.map(i => i.timestamp)));
    })
    let minDate = Math.min(...dates);
    let maxDate = Math.max(...dates);
    let diffDays = ((maxDate - minDate) / (3600 * 24)) | 0;
    if (diffDays <= 21) {
      return {
        unit: 'hour',
        displayFormats: {
          hour: 'HH:mm',
          day: 'MM.DD.YYYY',
          month: 'MMM,YY',
          year: 'YYYY',
        },
        tooltipFormat: 'MMM DD, YYYY, HH:mm',
      }
    }

    if (diffDays <= 180) {
      return {
        unit: 'day',
        displayFormats: {
          hour: 'HH:mm',
          day: 'DD',
          month: 'MMM YYYY',
          year: 'YYYY'
        },
        tooltipFormat: 'MMM DD, YYYY, HH:mm',
      }
    }

    return {
      unit: 'month',
      displayFormats: {
        hour: 'HH:mm',
        day: 'DD',
        month: 'MMM',
        year: 'YYYY'
      },
      tooltipFormat: 'MMM DD, YYYY, HH:mm',
    }
  }

  private createDataSets(telemetry: ItxTelemetryDataResponse[]): any {
    if (!telemetry || telemetry.length == 0) {
      return null;
    }

    let dataset: any[] = [];
    telemetry.forEach((t, index) => {
      if (index == 0 && (!t.reportDataTelemetry || t.reportDataTelemetry.length == 0)) {
        return null;
      }
      let label: string;

      if (this._selectedReadingUnit != undefined) {
        label = 'Actual Data'
      } else {
        if (this._selectedStorageFilter.radars && this._selectedStorageFilter.radars.length > index) {
          label = `RadarId: ${t.radarId} `;
        }
        else {
          label = 'Unknow';
        }
      }

      dataset.push({
        label: label,
        data: this.getSerisesPoints(t.reportDataTelemetry),
        fill: false,
        borderColor: index < this.chartColors.length ? this.chartColors[index] : this.random_rgba(),
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: true,
        //showLine:true,
      });

    });

    return dataset;
  }

  private getSerisesPoints(telemetry: ItxTelemetry[]) {
    let delta = this.NoDataRateInMinute * this.OneMinuteSeconds;
    let points: { x: Date, y: number }[] = [];
    let previosDt: number = Math.min(...telemetry.map(x => x.timestamp));
    telemetry.forEach(pt => {
      let diff: number = pt.timestamp - previosDt;
      if (diff > delta) {
        points.push({ x: new Date((pt.timestamp - diff / 2) * CalendarDefaultValues.defaultOneSecondMilliseconds), y: null });
      }
      points.push({ x: new Date(pt.timestamp * CalendarDefaultValues.defaultOneSecondMilliseconds), y: pt.value });
      previosDt = pt.timestamp;
    });
    return points;
  }

  private random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ', 1)';
  }

  verticalHoverPlugin = {
    afterDraw: chart => {
      if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
        let activePoint = this.chart.tooltip._active[0];
        let ctx = this.chart.ctx;
        let x = activePoint.tooltipPosition().x;
        let topY = this.chart.legend.bottom;
        let bottomY = this.chart.chartArea.bottom;

        // Set line opts
        ctx.save();
        ctx.lineWidth = 1;
        ctx.setLineDash([3, 3]);
        ctx.strokeStyle = '#FF4949';

        // draw vertical line
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.stroke();
        ctx.restore();
      }
    }
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.ItxReports);
  }
}
