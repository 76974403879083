export const RoutePaths = {
  MAIN_ADMIN: 'admin',
  MAIN_USER: 'user',
  SIGN_IN: 'sign-in',
  PASSWORD_RECOVER: 'password-recover',
  PASSWORD_UPDATE: 'password-update',
  COMPLETE_REGISTRATION: 'complete-registration',
  PRIVACY: 'privacy',
  COMPANY_USER: 'company-user',
  VENDOR: 'vendor',
  SALES_USER:'aqs'
};
