import { AqsAirflowFanTypeEnum, AqsAirflowLevelEnum, AqsFlushDuctDesignEnum } from '../../enums';

export class AqsAirflowViewModel {
    id: number;
    airflowData: AqsAirFlowDataViewModel[];
    level: AqsAirflowLevelEnum;
    packingFactor: number;
    numberfans: number;
    systemStep: number;
    fanType: AqsAirflowFanTypeEnum;
    selectedFan: AqsSelectedFanModel;

    constructor() {
        this.id = 0;
        this.airflowData = [];
        this.selectedFan = new AqsSelectedFanModel();
    }

    clearModel() {
        this.airflowData = [];
        this.level = null;
        this.packingFactor = null;
        this.numberfans = null;
        this.systemStep = 0;
        this.fanType = null;
        this.selectedFan.fanId = null;
        this.selectedFan.fanName = null;
    }
}

export class AqsSelectedFanModel {
    fanId: number;
    fanName: string;
    modelName: string;

    clearModel() {
        this.fanId = null;
        this.fanName = null;
        this.modelName=null;
    }
}

export class AqsAirFlowDataViewModel {
    id: number;
    commodityId: number;
    bupercfm: number;
    actpress: number;
    actbupercfm: number;
    kcfm: number;
}

export class AqsAirflowFanTypeViewModel {
    id: number;
    name: string;
    type: AqsAirflowFanTypeEnum;
    fans: AqsAirflowFanViewModel[];
}

export class AqsAirflowFanViewModel {
    id: number;
    name: string;
    fanPoints: AqsAirflowFanPointModel[];
    modelName: string;
}

export class AqsAirflowFanPointModel {
    x: number;
    y: number;
}

export class IncludeFlowOption {
    id: number;
    includeExhauster: boolean;
    includeDesign: AqsFlushDuctDesignEnum;
    constructor() {
        this.id = 0;
    }
}
