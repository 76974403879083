import { Component, OnDestroy, OnInit } from '@angular/core';
import { BtxEquipmentWithTelemetryViewModel, BtxSensorWithTelemetryViewModel, EquipmentViewModel } from '@models';
import { EquipmentDataSourceService } from '@services';
import { Subscription } from 'rxjs';

import { equipmentSideLabels, sensorPositionLabels, sensorTypeLabels } from '../../../constants';

@Component({
  selector: 'greensleeves-btx-equipment-details-popup',
  templateUrl: './btx-equipment-details-popup.component.html',
  styles: []
})
export class BtxEquipmentDetailsPopupComponent implements OnInit, OnDestroy {
  _isHidden: boolean = true;
  _loading: boolean = false;
  _equipmentWithTelemetry: BtxEquipmentWithTelemetryViewModel;
  _equipmentName: string;
  private subscription: Subscription;

  get _sensors() {
    if (!this._equipmentWithTelemetry) {
      return [];
    }

    return this._equipmentWithTelemetry.sensors;
  }

  get _popupHeader(): string {
    return this._equipmentName ? this._equipmentName + ' Details' : 'Loading...'
  }

  constructor(
    private _equipmentService: EquipmentDataSourceService,
  ) { }

  ngOnInit() {
    this.subscription =
      this._equipmentService.equipmentWithTelemetry$.subscribe(
        equipmentWithTelemetry => {
          if (!equipmentWithTelemetry) {
            return;
          }
          this._equipmentName = equipmentWithTelemetry.name;
          this.setUpEquipment(equipmentWithTelemetry);
          this._loading = false;
        },
      );
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  public onCancel() {
    this._isHidden = true;
    this._equipmentName = null
    this._equipmentWithTelemetry = null;
    this._equipmentService.equipmentWithTelemetry$.next(null);
  }

  public async ShowWithLoad(equipmentId: number) {
    this._isHidden = false;
    this._loading = true;
    this._equipmentService.getEquipmentWithTelemetry(equipmentId);
  }

  setUpEquipment(equipmentWithTelemetry: BtxEquipmentWithTelemetryViewModel) {
    const equipmentWithSortedSensors: BtxEquipmentWithTelemetryViewModel = {
      ...equipmentWithTelemetry,
      sensors: equipmentWithTelemetry.sensors
        .sort(this.compareSensorsWithTelemetryByPosition)
        .map((s, index) => ({ ...s, index: index + 1 })),
    };

    this._equipmentWithTelemetry = equipmentWithSortedSensors;
  }

  compareSensorsWithTelemetryByPosition(
    a: BtxSensorWithTelemetryViewModel,
    b: BtxSensorWithTelemetryViewModel,
  ) {
    const aString = `${sensorTypeLabels[a.type.id]}${sensorPositionLabels[a.position.id]
      }${equipmentSideLabels[a.side.id]}`;
    const bString = `${sensorTypeLabels[b.type.id]}${sensorPositionLabels[b.position.id]
      }${equipmentSideLabels[b.side.id]}`;

    if (aString < bString) {
      return -1;
    }

    if (aString > bString) {
      return 1;
    }

    return 0;
  }
}
