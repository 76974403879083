import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { TreeModule } from 'primeng/tree';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AccordionModule } from 'primeng/accordion';
import { InputNumberModule } from 'primeng/inputnumber';
import { NgxMaskModule } from 'ngx-mask';
import { ColorPickerModule } from 'primeng/colorpicker';
import { SpinnerModule } from 'primeng/spinner';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RoutePaths, UserRoleEnum } from '../common';
import { MainComponent } from './main.component';
import { AuthGuard } from './auth.guard';
import { AccessTokenFactory } from './access-token.factory';
import { InputPriceFormatModule } from 'angular-input-price-format';
import { ChipsModule } from 'primeng/chips';
import {
  LocationsTabComponent,
  LocationsGridComponent,
  NavigationBarComponent,
  HeaderComponent,
  CompaniesGridComponent,
  CompaniesTabComponent,
  CompaniesDeletePopupComponent,
  CompanyAdminsGridComponent,
  CompanyAdminsTabComponent,
  EquipmentSensorDeletePopupComponent,
  CompanyUsersGridComponent,
  CompanyUsersTabComponent,
  BasePopupComponent,
  CompanyAdminAddPopupComponent,
  EquipmentPopupComponent,
  EquipmentSchemeEditorComponent,
  EditSensorPopupComponent,
  ViewSensorPopupComponent,
  AddEditEquipmentPopupComponent,
  UploadAddEditEquipmentPopupComponent,
  ServiceUserAddPopupComponent,
  ServiceUserEditPopupComponent,
  ServiceUsersGridComponent,
  ServiceUsersTabComponent,
  ServiceUserDeletePopupComponent,
  CompaniesAddEditPopupComponent,
  LocationDeletePopupComponent,
  LocationBtxKtxDeletePopupComponent,
  LocationEditPopupComponent,
  BtxLocationEditPopupComponent,
  CompanyAdminDeletePopupComponent,
  CompanyAdminEditPopupComponent,
  CompanyUserEditPopupComponent,
  CompanyUserDeletePopupComponent,
  CompanyUserAddPopupComponent,
  EquipmentMenuComponent,
  BtxTabComponent,
  BtxGridComponent,
  KtxTabComponent,
  KtxGridComponent,
  KtxLocationAddEditPopupComponent,
  KtxLocationBinsPopupComponent,
  KtxBinDetailsPopupComponent,
  KtxListCablesPopupComponent,
  LocationChangedPopupComponent,
  KtxTemperatureRangesPopupComponent,
  UploadConfigurationFilePopupComponent,
  UploadBtxLocationConfigurationFilePopupComponent,
  SuccessOperationPopupComponent,
  KtxUnitDetailsPopupComponent,
  KtxRangeDeletePopupComponent,
  EquipmentViewDataPopupComponent,
  UploadConfigurationCloseConfirmationPopupComponent,
  ItxTabComponent,
  ITXGridComponent,
  VendorsTabComponent,
  ItxBinsTabComponent,
  ItxAlertTabComponent,
  VendorsGridComponent,
  VendorDeletePopupComponent,
  ItxAddEditPopupComponent,
  ItxBinGridComponent,
  VendorAddPopupComponent,
  VendorEditPopupComponent,
  ItxManageColorPopupComponent,
  ItxRangeDeletePopupComponent,
  ItxBinAddEditPopupComponent,
  ItxBinDeletePopupComponent,
  ItxListAddRadarPopupComponent,
  ItxEditRadarPopupComponent,
  ItxDeleteRadarPopupComponent,
  ItxExportReportPopupComponent,
  ItxLiveDataTabComponent,
  ItxLiveDataSettingPopupComponent,
  ItxStorageRadarsPopupComponent,
  ItxReportsTabComponent,
  UserProfilePopupComponent,
  ItxQuickEditPopupComponent,
  AqsRoundStorageTabComponent,
  AqsFlatStorageTabComponent,
  AqsComputationSheetTabComponent,
  SalesUsersTabComponent,
  AqsRoundConfigurationTabComponent,
  AqsConfirmPopupComponent,
  AqsRoundViewTabComponent,
  ComputationSheetTableComponent,
  AqsCommoditiesComponent,
  ComputationSheetReportPopupComponent,
  AqsFlatViewTabComponent,
  AqsFlatStorageParamsComponent,
  ComputationSheetShareReportPopupComponent,
  AqsComputationSheetConfirmPopupComponent,
  AqsCoverSheetComponent,
  AqsTargetAirflowComponent,
  AqsRoundDuctDesignComponent,
  AqsRoundDuctDesignViewTabComponent,
  AqsExhausterRecommendationsComponent,
  AqsComputationSheetResavePopupComponent,
  AqsFlatDuctDesignComponent,
  AqsRoundFlushDesignTabComponent,
  AqsRoundFlushDesignDoubleHComponent,
  AqsRoundFlushDesignDoubleTwoRunsComponent,
  AqsRoundFlushDesignDoubleTComponent,
  AqsRoundFlushDesignFourLaneSpecialComponent,
  AqsRoundFlushDesignSixLaneSpecialComponent,
  AqsRoundFlushDesignDoubleTSpecialComponent,
  AqsFlatDuctViewTabComponent,
  AqsQuotesTabComponent,
  AqsNewQuotePopupComponent,
  KtxConnectionPopupComponent,
  DeviceCommunicationPopupComponent,
  KtxCurrentDataTabComponent,
  BtxLiveDataTabComponent,
  BtxHistoricalDataTabComponent,
  BtxNotificationsTabComponent,
  KtxHistoricalDataTabComponent,
  KtxNotificationsTabComponent,
  EmailManagementTabComponent,
  KtxBinDetailsTabComponent,
  KtxUnitFullscreenPopupComponent,
  KtxTemperatureRangesComponent,
  KtxCurrentDataExportReportPopupComponent,
  BtxLiveDataEquipmentViewTabComponent,
  BtxEquipmentSensorsGridComponent,
  BtxEquipmentSchemaComponent,
  EmailServiceAddEditPopupComponent,
  EmailServiceDeletePopupComponent,
  BtxLiveDataExportReportPopupComponent,
  UploadCancellationPopupComponent,
  UploadEquipmentMenuComponent,
  UploadEquipmentPopupComponent,
  DeleteEquipmentConfirmationPopupComponent,
  UploadEquipmentViewDataPopupComponent,
  UploadEquipmentSensorDeletePopupComponent,
  UploadSuccessPopupComponent,
  UploadEquipmentSchemeEditorComponent,
  TutorialPopupComponent,
  TutorialComponent,
  ExportNotificationPopupComponent,
  BtxEquipmentDetailsPopupComponent,
  DeviceUpdateManagementTabComponent,
  UpdateFirmwarePopupComponent,
  UpdaterDeletePopupComponent,
  UpdaterAddEditPopupComponent,
  FirmwareManagementPopupComponent,
  UploadFirmwarePopupComponent,
  UploadFirmwareRebasePopupComponent,
  UpdateVersionsManagementPopupComponent,
  ConfirmationPopupComponent,
  UploadFirmwareInstallPopupComponent,
} from './components';
import {
  LocationsDataSourceService,
  LocationsBtxDataSourceService,
  LocationsApiService,
  CompaniesDataSourceService,
  CompaniesApiService,
  CompanyAdminsApiService,
  CompanyUsersApiService,
  CompanyUsersDataSourceService,
  CompanyAdminsDataSourceService,
  EquipmentApiService,
  ServiceUsersDataSourceService,
  ServiceUsersApiService,
  HubService,
  LocationsBtxApiService,
  LocationsKtxDataSourceService,
  LocationsKtxApiService,
  BinApiService,
  LocationsItxDatasourceService,
  LocationsItxApiService,
  VendorDataSourceService,
  VendorApiService,
  ItxBinApiService,
  ItxBinDataSourceService,
  EventDatasourceService,
  EventApiService,
  AccountApiService,
  AccountDatasourceService,
  SalesUsersApiService,
  SalesUsersDatasourceService,
  AqsCommoditiesDatasourceService,
  AqsCommoditiesApiService,
  AqsReportApiService,
  AqsReportDatasourceService,
  AqsAirflowDatasourceService,
  AqsAirflowApiService,
  AqsQuoteStorageApiService,
  AqsQuoteStorageDataSourceService,
  DeviceLogApiService,
  DeviceLogDatasourceService,
  RoleService,
  EquipmentDataSourceService,
  EmailServiceApiService,
  EmailServiceDataSourceService,
} from './services';
import {
  TouchOnFocusDirective,
  ClickOutsideDirective,
  IpMaskFixDirective,
} from './directives';
import { SafePipe } from './pipes';
import {
  companyAdminRoutes,
  companyUserRoutes,
  salesUserRoutes,
  serviceUserRoutes,
  vendorRoutes,
} from './main.routes';

@NgModule({
  declarations: [
    MainComponent,
    LocationsTabComponent,
    LocationsGridComponent,
    NavigationBarComponent,
    HeaderComponent,
    CompaniesGridComponent,
    CompaniesTabComponent,
    CompanyAdminsGridComponent,
    CompanyAdminsTabComponent,
    CompanyUsersGridComponent,
    CompanyUsersTabComponent,
    ServiceUsersTabComponent,
    ServiceUsersGridComponent,
    BasePopupComponent,
    LocationDeletePopupComponent,
    LocationEditPopupComponent,
    BtxLocationEditPopupComponent,
    CompaniesAddEditPopupComponent,
    CompaniesDeletePopupComponent,
    CompanyAdminDeletePopupComponent,
    CompanyAdminEditPopupComponent,
    CompanyAdminAddPopupComponent,
    CompanyUserEditPopupComponent,
    CompanyUserDeletePopupComponent,
    CompanyUserAddPopupComponent,
    ServiceUserAddPopupComponent,
    ServiceUserEditPopupComponent,
    ServiceUserDeletePopupComponent,
    DeleteEquipmentConfirmationPopupComponent,
    UploadSuccessPopupComponent,
    EquipmentPopupComponent,
    EquipmentSchemeEditorComponent,
    EditSensorPopupComponent,
    EquipmentViewDataPopupComponent,
    ViewSensorPopupComponent,
    AddEditEquipmentPopupComponent,
    UploadAddEditEquipmentPopupComponent,
    EquipmentSensorDeletePopupComponent,
    EquipmentMenuComponent,
    UploadEquipmentPopupComponent,
    UploadEquipmentSchemeEditorComponent,
    UploadEquipmentViewDataPopupComponent,
    UploadEquipmentSensorDeletePopupComponent,
    UploadCancellationPopupComponent,
    UploadEquipmentMenuComponent,
    TouchOnFocusDirective,
    ClickOutsideDirective,
    IpMaskFixDirective,
    LocationChangedPopupComponent,
    BtxGridComponent,
    BtxTabComponent,
    KtxTabComponent,
    KtxGridComponent,
    KtxLocationAddEditPopupComponent,
    LocationBtxKtxDeletePopupComponent,
    SafePipe,
    KtxLocationBinsPopupComponent,
    KtxBinDetailsPopupComponent,
    KtxListCablesPopupComponent,
    KtxTemperatureRangesPopupComponent,
    UploadConfigurationFilePopupComponent,
    UploadBtxLocationConfigurationFilePopupComponent,
    UploadConfigurationCloseConfirmationPopupComponent,
    SuccessOperationPopupComponent,
    KtxUnitDetailsPopupComponent,
    KtxRangeDeletePopupComponent,
    ItxTabComponent,
    VendorsTabComponent,
    ITXGridComponent,
    ItxBinsTabComponent,
    ItxReportsTabComponent,
    ItxAlertTabComponent,
    VendorsGridComponent,
    VendorDeletePopupComponent,
    ItxAddEditPopupComponent,
    ItxBinGridComponent,
    VendorAddPopupComponent,
    VendorEditPopupComponent,
    VendorAddPopupComponent,
    ItxManageColorPopupComponent,
    ItxRangeDeletePopupComponent,
    ItxBinAddEditPopupComponent,
    ItxBinDeletePopupComponent,
    ItxListAddRadarPopupComponent,
    ItxEditRadarPopupComponent,
    ItxDeleteRadarPopupComponent,
    ItxExportReportPopupComponent,
    ItxLiveDataTabComponent,
    ItxLiveDataSettingPopupComponent,
    ItxStorageRadarsPopupComponent,
    UserProfilePopupComponent,
    ItxQuickEditPopupComponent,
    AqsRoundStorageTabComponent,
    AqsFlatStorageTabComponent,
    AqsComputationSheetTabComponent,
    SalesUsersTabComponent,
    ComputationSheetTableComponent,
    AqsRoundConfigurationTabComponent,
    AqsConfirmPopupComponent,
    AqsRoundViewTabComponent,
    AqsFlatStorageParamsComponent,
    AqsCommoditiesComponent,
    ComputationSheetReportPopupComponent,
    AqsFlatViewTabComponent,
    ComputationSheetShareReportPopupComponent,
    AqsComputationSheetConfirmPopupComponent,
    AqsCoverSheetComponent,
    AqsTargetAirflowComponent,
    AqsRoundDuctDesignComponent,
    AqsExhausterRecommendationsComponent,
    AqsComputationSheetResavePopupComponent,
    AqsRoundDuctDesignViewTabComponent,
    AqsFlatDuctDesignComponent,
    AqsRoundFlushDesignTabComponent,
    AqsRoundFlushDesignDoubleHComponent,
    AqsRoundFlushDesignDoubleTwoRunsComponent,
    AqsRoundFlushDesignDoubleTComponent,
    AqsRoundFlushDesignFourLaneSpecialComponent,
    AqsRoundFlushDesignSixLaneSpecialComponent,
    AqsRoundFlushDesignDoubleTSpecialComponent,
    AqsFlatDuctViewTabComponent,
    AqsQuotesTabComponent,
    AqsNewQuotePopupComponent,
    KtxConnectionPopupComponent,
    DeviceCommunicationPopupComponent,
    KtxCurrentDataTabComponent,
    BtxLiveDataTabComponent,
    BtxHistoricalDataTabComponent,
    BtxNotificationsTabComponent,
    KtxHistoricalDataTabComponent,
    KtxNotificationsTabComponent,
    EmailManagementTabComponent,
    KtxBinDetailsTabComponent,
    KtxUnitFullscreenPopupComponent,
    KtxTemperatureRangesComponent,
    KtxCurrentDataExportReportPopupComponent,
    BtxLiveDataEquipmentViewTabComponent,
    BtxEquipmentSensorsGridComponent,
    BtxEquipmentSchemaComponent,
    EmailServiceAddEditPopupComponent,
    EmailServiceDeletePopupComponent,
    BtxLiveDataExportReportPopupComponent,
    TutorialPopupComponent,
    TutorialComponent,
    ExportNotificationPopupComponent,
    BtxEquipmentDetailsPopupComponent,
    DeviceUpdateManagementTabComponent,
    UpdaterDeletePopupComponent,
    UpdaterAddEditPopupComponent,
    FirmwareManagementPopupComponent,
    UploadFirmwarePopupComponent,
    UploadFirmwareRebasePopupComponent,
    UpdateFirmwarePopupComponent,
    UpdaterDeletePopupComponent,
    UpdaterAddEditPopupComponent,
    UploadFirmwareRebasePopupComponent,
    UpdateVersionsManagementPopupComponent,
    ConfirmationPopupComponent,
    UploadFirmwareInstallPopupComponent,
  ],
  imports: [
    FormsModule,
    AngularCommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    InputNumberModule,
    ListboxModule,
    TreeModule,
    CalendarModule,
    ToastModule,
    RadioButtonModule,
    MenuModule,
    CheckboxModule,
    OverlayPanelModule,
    AccordionModule,
    SpinnerModule,
    MessagesModule,
    InputTextareaModule,
    InputSwitchModule,
    MessageModule,
    InputNumberModule,
    ColorPickerModule,
    ProgressSpinnerModule,
    FileUploadModule,
    InputPriceFormatModule,
    ChipsModule,
    NgxMaskModule.forRoot(),
    RouterModule.forChild([
      {
        path: RoutePaths.MAIN_ADMIN,
        component: MainComponent,
        canActivate: [AuthGuard],
        data: { expectedRole: UserRoleEnum.CompanyAdmin },
        children: companyAdminRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: RoutePaths.MAIN_USER,
        component: MainComponent,
        canActivate: [AuthGuard],
        data: { expectedRole: UserRoleEnum.ServiceUser },
        children: serviceUserRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: RoutePaths.VENDOR,
        component: MainComponent,
        canActivate: [AuthGuard],
        data: { expectedRole: UserRoleEnum.Vendor },
        children: vendorRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: RoutePaths.COMPANY_USER,
        component: MainComponent,
        canActivate: [AuthGuard],
        data: { expectedRole: UserRoleEnum.CompanyUser },
        children: companyUserRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: RoutePaths.SALES_USER,
        component: MainComponent,
        canActivate: [AuthGuard],
        data: { expectedRole: UserRoleEnum.SalesUser },
        children: salesUserRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: 'back-door',
        component: MainComponent,
        children: serviceUserRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: 'back-door/admin',
        component: MainComponent,
        children: companyAdminRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: 'back-door/company-user',
        component: MainComponent,
        children: companyUserRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: 'back-door/user',
        component: MainComponent,
        children: serviceUserRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: 'back-door/vendor',
        component: MainComponent,
        children: vendorRoutes,
      },
    ]),
    RouterModule.forChild([
      {
        path: 'back-door/aqs',
        component: MainComponent,
        children: salesUserRoutes,
      },
    ]),
  ],

  providers: [
    AuthGuard,
    LocationsDataSourceService,
    LocationsBtxDataSourceService,
    LocationsBtxApiService,
    LocationsKtxDataSourceService,
    LocationsKtxApiService,
    LocationsApiService,
    CompaniesApiService,
    CompaniesDataSourceService,
    CompanyAdminsApiService,
    CompanyAdminsDataSourceService,
    CompanyUsersApiService,
    CompanyUsersDataSourceService,
    ServiceUsersApiService,
    ServiceUsersDataSourceService,
    EquipmentApiService,
    HubService,
    AccessTokenFactory,
    VendorApiService,
    VendorDataSourceService,
    LocationsItxDatasourceService,
    LocationsItxApiService,
    ItxBinApiService,
    ItxBinDataSourceService,
    BinApiService,
    EventDatasourceService,
    EventApiService,
    AccountApiService,
    SalesUsersApiService,
    SalesUsersDatasourceService,
    AccountDatasourceService,
    AqsCommoditiesDatasourceService,
    AqsCommoditiesApiService,
    AqsReportDatasourceService,
    AqsReportApiService,
    AqsAirflowDatasourceService,
    AqsAirflowApiService,
    AqsQuoteStorageApiService,
    AqsQuoteStorageDataSourceService,
    DeviceLogApiService,
    DeviceLogDatasourceService,
    EmailServiceApiService,
    EmailServiceDataSourceService,
    RoleService,
    EquipmentDataSourceService,
  ],
})
export class MainModule { }
