import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AqsAirflowFanTypeViewModel } from '../../models';
import { ErrorModel, HttpService } from './../../../common';
import { ApiRoutes } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AqsAirflowApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router,) { }

  public async getAllFanTypesWithFans(): Promise<AqsAirflowFanTypeViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const result = await this._httpService.get(ApiRoutes.Airflow.getAllFanTypesWithFans);
      return result
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  private getFakeData(): AqsAirflowFanTypeViewModel[] {
    let fans = [
      {
        "id": 1, "name": "Axial", "type": 1,
        "fans": [
          { "id": 1, "name": "1207.5","modelName":"C", "fanPoints": [{ "x": 1.87, "y": 0 }, { "x": 1.51, "y": 1 }, { "x": 1.15, "y": 2 }, { "x": 0.775, "y": 3 }, { "x": 0.555, "y": 4 }] },
          { "id": 25, "name": "1401","modelName":"C", "fanPoints": [{ "x": 2.98, "y": 0 }, { "x": 2.66, "y": 1 }, { "x": 2.34, "y": 2 }, { "x": 1.85, "y": 3 }, { "x": 1.275, "y": 4 }, { "x": 0.84, "y": 5 }] },
          { "id": 26, "name": "1401.52","modelName":"C", "fanPoints": [{ "x": 3.15, "y": 0 }, { "x": 3.05, "y": 1 }, { "x": 2.95, "y": 2 }, { "x": 2.8, "y": 3 }, { "x": 2.64, "y": 4 }, { "x": 2.36, "y": 5 }, { "x": 2.15, "y": 6 }, { "x": 1.84, "y": 7 }, { "x": 1.725, "y": 8 }, { "x": 1.43, "y": 9 }, { "x": 1.175, "y": 10 }, { "x": 0.95, "y": 11 }, { "x": 0.8, "y": 12 }, { "x": 0.58, "y": 13 }] }
        ]
      },
      {
        "id": 2, "name": "Centrifugal 60 Hz", "type": 2,
        "fans": [
          { "id": 35, "name": "2003","modelName":"C", "fanPoints": [{ "x": 5.417, "y": 0 }, { "x": 4.833, "y": 1 }, { "x": 4.167, "y": 2 }, { "x": 3.283, "y": 3 }, { "x": 2.3, "y": 4 }, { "x": 1.22254, "y": 4.4752 }] },
          { "id": 45, "name": "2005","modelName":"C", "fanPoints": [{ "x": 1.9, "y": 0 }, { "x": 1.809528, "y":1 }, { "x": 1.720782, "y": 2 }, { "x": 1.644008, "y": 3 }, { "x": 1.559426, "y": 4 }, { "x": 1.479822, "y": 5 }, { "x": 1.402866, "y": 6 }, { "x": 1.320808, "y": 7 }, { "x": 1.23797, "y": 8 }, { "x": 1.151588, "y": 9 }, { "x": 1.039806, "y": 10 }, { "x": 0.948, "y": 10.723 }] },
          { "id": 37, "name": "2010","modelName":"C", "fanPoints": [{ "x": 5.721, "y": 0 }, { "x": 5.55, "y": 1 }, { "x": 5.4, "y": 2 }, { "x": 5.3, "y":3 }, { "x": 5.167, "y": 4 }, { "x": 4.967, "y": 5 }, { "x": 4.733, "y": 6 }, { "x": 4.533, "y":7 }, { "x": 4.367, "y": 8 }, { "x": 4.167, "y": 9 }, { "x": 3.933, "y": 10 }, { "x": 3.7, "y": 11 }, { "x": 3.417, "y": 12 }, { "x": 3.167, "y": 13 }, { "x": 2.833, "y": 14 }, { "x": 2.467, "y": 15 }, { "x": 2.152, "y": 15.772 }] },
          { "id": 6, "name": "3040","modelName":"C", "fanPoints": [{ "x": 29, "y": 0 }, { "x": 27.5, "y": 1 }, { "x": 26, "y": 2 }, { "x": 25.3, "y": 3 }, { "x": 24.2, "y": 4 }, { "x": 23.5, "y": 5 }, { "x": 22.5, "y": 6 }, { "x": 21.5, "y": 7 }, { "x": 20.2, "y": 8 }, { "x": 18.5, "y": 9 }, { "x": 15.7, "y": 10 }] }
        ]
      },
      {
        "id": 3, "name": "Centrifugal 60 Hz A.F.", "type": 3,
        "fans": [
          { "id": 8, "name": "2710","modelName":"C", "fanPoints": [{ "x": 12.6, "y": 0 }, { "x": 11.96, "y": 1 }, { "x": 11.23, "y": 2 }, { "x": 10.43, "y": 3 }, { "x": 9.59, "y": 4 }, { "x": 8.75, "y": 5 }, { "x": 7.88, "y": 6 }, { "x": 6.75, "y": 7 }] },
          { "id": 9, "name": "2715","modelName":"C", "fanPoints": [{ "x": 18.1, "y": 0 }, { "x": 17.045, "y": 1 }, { "x": 15.915, "y": 2 }, { "x": 14.79, "y": 3 }, { "x": 13.77, "y": 4 }, { "x": 12.76, "y": 5 }, { "x": 11.56, "y": 6 }, { "x": 10.52, "y": 7 }] },
          { "id": 7, "name": "2775","modelName":"C", "fanPoints": [{ "x": 10.1, "y": 0 }, { "x": 9.59, "y": 1 }, { "x": 9.02, "y": 2 }, { "x": 8.34, "y": 3 }, { "x": 7.51, "y": 4 }, { "x": 6.77, "y": 5 }, { "x": 6.06, "y": 6 }, { "x": 5.09, "y": 7 }] }
        ]
      },
      {
        "id": 4, "name": "Centrifugal 50 Hz", "type": 4,
        "fans": [
          { "id": 10, "name": "2003","modelName":"C", "fanPoints": [{ "x": 4.639, "y": 0 }, { "x": 3.736, "y": 1 }, { "x": 2.833, "y": 2 }, { "x": 1.333, "y": 3 }] },
          { "id": 11, "name": "2005","modelName":"C", "fanPoints": [{ "x": 1.567, "y": 0 }, { "x": 1.467, "y": 1 }, { "x": 1.367, "y": 2 }, { "x": 1.3, "y": 3 }, { "x": 1.167, "y": 4 }, { "x": 1.083, "y": 5 }, { "x": 1, "y": 6 }, { "x": 0.85, "y": 7 }] },
          { "id": 13, "name": "2010","modelName":"C", "fanPoints": [{ "x": 4.717, "y": 0 }, { "x": 4.567, "y": 1 }, { "x": 4.417, "y": 2 }, { "x": 4.267, "y": 3 }, { "x": 3.967, "y": 4 }, { "x": 3.75, "y": 5 }, { "x": 3.55, "y": 6 }, { "x": 3.267, "y": 7 }, { "x": 2.967, "y": 8 }, { "x": 2.65, "y": 9 }, { "x": 2.267, "y": 10 }] }
        ]
      }
    ];
    return fans as AqsAirflowFanTypeViewModel[];
  }
}
