import { _isNumberValue } from '@angular/cdk/coercion';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { PartInfoModel } from '@models';
import { AqsComputationSheetConfirmPopupComponent } from '@popups';

@Component({
  selector: 'greensleeves-computation-sheet-table',
  templateUrl: './computation-sheet-table.component.html',
  styleUrls: [],
})

export class ComputationSheetTableComponent implements OnInit, AfterContentChecked {
  @Input() _tableName: string;
  @Input() _table: PartInfoModel[] = [];
  @Input() _isSubmited: boolean;
  @Output() _isChangedTable = new EventEmitter<boolean>()

  private regexIntOnly = /^[1-9]\d*$/;

  @ViewChild(AqsComputationSheetConfirmPopupComponent, { read: false, static: false })
  private _deleteModal: AqsComputationSheetConfirmPopupComponent;
  private titleDeletePopup = "Are you sure?";
  private subtitleDeletePopup = "Do you really want to delete this row?";

  _rowsWillBeRemoved: number[] = [];

  _tableForChanges: PartInfoModel[] = [];
  _isDeleteRun = false;
  constructor(private _cdref: ChangeDetectorRef) { }

  ngOnInit() {
    if (this._table.length > 0) {
      this._table.forEach(item => {
        let newItem = new PartInfoModel();
        newItem = Object.assign(newItem, item);
        this._tableForChanges.push(newItem);
      })
    } else {
      this.addTableItem();
    }
  }

  setTable() {
    this._tableForChanges = [];
    this._table.forEach(item => {
      let newItem = new PartInfoModel();
      newItem = Object.assign(newItem, item);
      this._tableForChanges.push(newItem);
    })
  }

  ngAfterContentChecked(): void {
    this._cdref.detectChanges();
  }

  addTableItem() {
    this._tableForChanges.push(new PartInfoModel());

    let defaultParameters = new PartInfoModel();
    defaultParameters.partNumber = '';
    defaultParameters.quantity = 0;
    defaultParameters.description = '';
    defaultParameters.weight = 0;
    defaultParameters.unitPriceMaterial = 0;
    defaultParameters.unitPriceLabor = 0;
    defaultParameters.totalPriceMaterial = 0;
    defaultParameters.totalPriceLabor = 0;

    this._table.push(defaultParameters);
    this._isChangedTable.emit(true);
  }

  deleteTableItem(index: number) {
    if (this.isEmptyRow(index)) {
      this.deleteRow(index)
    } else {
      this._deleteModal.showConfirmPopup(this.titleDeletePopup, this.subtitleDeletePopup, index);
    }
  }

  deleteRow(index: number) {
    if (this._table[index].id) {
      this._rowsWillBeRemoved.push(this._table[index].id);
    }
    this._tableForChanges.splice(index, 1);
    this._table.splice(index, 1);
    this._isChangedTable.emit(true);
  }

  onChangePartNumber(index: number) {
    if (this._tableForChanges[index].partNumber !== null &&
      this._tableForChanges[index].partNumber !== this._table[index].partNumber) {

      this._table[index].partNumber = this._tableForChanges[index].partNumber;
    }
  }

  onChangeQuantity(index: number) {
    if (!_isNumberValue(this._tableForChanges[index].quantity)) {
      this._table[index].quantity = 0;
    }

    if (this._tableForChanges[index].quantity !== this._table[index].quantity) {

      this._table[index].quantity = Number(this._tableForChanges[index].quantity);
    }
  }

  onChangeDescription(index: number) {
    if (this._tableForChanges[index].description !== null &&
      this._tableForChanges[index].description !== this._table[index].description) {

      this._table[index].description = this._tableForChanges[index].description;
    }
  }

  onChangeWeight(index: number) {
    if (!_isNumberValue(this._tableForChanges[index].weight)) {
      this._table[index].weight = 0;
    }

    if (this._tableForChanges[index].weight !== this._table[index].weight) {
      this._table[index].weight = Number(this._tableForChanges[index].weight);
    }
  }

  onChangeUnitPriceMaterial(index: number) {
    if (!_isNumberValue(this._tableForChanges[index].unitPriceMaterial)) {
      this._table[index].unitPriceMaterial = 0;
    }

    if (this._tableForChanges[index].unitPriceMaterial !== this._table[index].unitPriceMaterial) {
      this._table[index].unitPriceMaterial = Number(this._tableForChanges[index].unitPriceMaterial);
    }
  }

  onChangeUnitPriceLabor(index: number) {
    if (!_isNumberValue(this._tableForChanges[index].unitPriceLabor)) {
      this._table[index].unitPriceLabor = 0
    }

    if (this._tableForChanges[index].unitPriceLabor !== this._table[index].unitPriceLabor) {
      this._table[index].unitPriceLabor = Number(this._tableForChanges[index].unitPriceLabor);
    }
  }

  calculateTotalPrice(index: number, parameter: string): number {
    let totalPrice: number;

    switch (parameter) {
      case 'material':
        let materialPrice = this._tableForChanges[index].unitPriceMaterial * this._tableForChanges[index].quantity;

        if (_isNumberValue(materialPrice)) {
          totalPrice = this.roundToTwoDecimalPlaces(materialPrice);
          this._table[index].totalPriceMaterial = totalPrice;
        }
        break;
      case 'labor':
        let laborPrice = this._tableForChanges[index].unitPriceLabor * this._tableForChanges[index].quantity;

        if (_isNumberValue(laborPrice)) {
          totalPrice = this.roundToTwoDecimalPlaces(laborPrice);
          this._table[index].totalPriceLabor = totalPrice;
        }
        break;
      default:
        break;
    }

    if (totalPrice === 0 || !_isNumberValue(totalPrice)) {
      return null;
    }

    return totalPrice;
  }

  numberOnly(event) {
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!this.regexIntOnly.test(input)) {
      event.preventDefault();
    }
  }

  pasteNumber(event) {
    let clipboardData = event.clipboardData;
    let pastedData = clipboardData.getData('Text');
    if (!this.regexIntOnly.test(pastedData)) {
      event.preventDefault();
    }
  }

  private roundToTwoDecimalPlaces(number: number): number {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  isEmptyRow(index: number): boolean {
    return (this._tableForChanges[index].partNumber == '' || this._tableForChanges[index].partNumber == null)
      && (Number(this._tableForChanges[index].quantity == 0) || this._tableForChanges[index].quantity == null)
      && (this._tableForChanges[index].description == '' || this._tableForChanges[index].description == null)
      && (Number(this._tableForChanges[index].weight) == 0 || this._tableForChanges[index].weight == null)
      && (Number(this._tableForChanges[index].unitPriceMaterial) == 0 || this._tableForChanges[index].unitPriceMaterial == null)
      && (Number(this._tableForChanges[index].unitPriceLabor) == 0 || this._tableForChanges[index].unitPriceLabor == null)
  }

}
