import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  BinSnapshotTimestampsByPeriodResponse,
  CableSchemeViewModel,
  CableSnapshotResponse,
  CompanyViewModel,
  KtxReportHistoricalDataFilter,
  KtxWeeksRangeModel,
  LocationKtxViewModel,
  LocationNameViewModel,
  SensorKtxSchemeViewModel,
  TelemetrySnapshotViewModel,
  TemperatureColorViewModel,
  TemperatureRangeAlarmViewModel,
  TemperatureRangeViewModel,
} from '../../models';
import { ColumnTypeEnum, SensorStatusEnum, TutorialMenuEnum } from '../../enums';
import { LocationsKtxDataSourceService, ReportDatasourceService, RoleService } from '../../services';
import { CommonFunctions, ConvertDataFunctions, ErrorModel, UserRoleEnum } from '../../../common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TreeNode } from 'primeng';
import { TutorialPopupComponent } from '../popups';

@Component({
  selector: 'greensleeves-ktx-historical-data-tab',
  templateUrl: './ktx-historical-data-tab.component.html',
})
export class KtxHistoricalDataTabComponent implements OnInit {
  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent

  _companies: CompanyViewModel[] = [];
  _ktxLocationNodes: TreeNode[] = [];
  _nodeSelected: TreeNode;
  _isOpenFilterList: boolean = false;
  _isOpenDateFilter: boolean = false;
  _selectedTimeStamp: number;
  _location: LocationKtxViewModel;
  _temperatureColorRange: TemperatureColorViewModel[];
  _selectedLocationName: string;
  _selectedUnitName: string;
  _selectedBinName: string;
  _filterParameters: { binId: number, locationid: number };
  _temperatureInfo: TemperatureRangeAlarmViewModel;
  _snapshots: number[] = [];
  _filterFormBuilder: FormGroup;
  _measuring: { label: string, value: number }[] = [];
  _ktxWeeksRange: KtxWeeksRangeModel[];
  _ktxWeeks: { week: string, range: string, value: number }[] = [];
  _gridData: CableSnapshotResponse[] = [];
  _columnType = ColumnTypeEnum;
  _isLoading: boolean;
  _columnsView = [];
  _sortField = 'name';
  temperatureTelemetry: TelemetrySnapshotViewModel;
  temperatureRanges: TemperatureRangeViewModel[];
  _frozenColumns = [{ header: 'Cable', columnType: ColumnTypeEnum.CableName, dataField: 'name', width: 50 }];
  _loading: boolean = false;

  public isOpenLocationList = false;
  private isFarenheitMeasureMode: boolean;
  private isTemperatureMeasureMode: boolean = true;
  private subscriptions: Subscription[] = [];

  get _getSelectedFilterWeek() {
    return this._ktxWeeks[this._filterFormBuilder!.controls!.dateRange!.value - 1].week;
  }

  get _getSelectedFilterMeasure() {
    return this._measuring[this._filterFormBuilder!.controls!.measuring!.value].label;
  }

  get _getSelectedFilterDateRange() {
    return this._ktxWeeks[this._filterFormBuilder!.controls!.dateRange!.value - 1].range;
  }

  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  get _isFarenheitMeasureMode() {
    return this.isFarenheitMeasureMode;
  }

  set _isFarenheitMeasureMode(value: boolean) {
    this.isFarenheitMeasureMode = value;
  }

  get _isTemperatureMeasureMode() {
    return this.isTemperatureMeasureMode;
  }

  set _isTemperatureMeasureMode(value: boolean) {
    this.isTemperatureMeasureMode = value;
  }

  get isTutorialUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser);
  }

  get _temperatureRanges(): TemperatureColorViewModel[] {
    if (!this._temperatureInfo || !this._temperatureInfo.temperatureRanges) {
      return [];
    }

    const temperatures: TemperatureColorViewModel[] = this._isFarenheitMeasureMode
      ? this._temperatureInfo.temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
        value: this.onCelciusToFarenheit(tr.to),
        colorHex: tr.colorHex,
      }))
      : this._temperatureInfo.temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
        value: Math.round(tr.to).toString(),
        colorHex: tr.colorHex,
      }));
    const {
      openTcColorHex,
      noResponseColorHex,
      maxRiseColorHex,
      highResistanceColorHex,
      highTemperatureColorHex,
    } = this._temperatureInfo.alarm;

    const hlValue = this._isTemperatureMeasureMode
      ? highTemperatureColorHex
      : highResistanceColorHex;

    return [
      ...temperatures,
      {
        value: 'OT',
        colorHex: openTcColorHex,
      },
      {
        value: 'BL',
        colorHex: noResponseColorHex,
      },
      {
        value: 'MR',
        colorHex: maxRiseColorHex,
      },
      {
        value: 'HL',
        colorHex: hlValue,
      },
    ];
  }

  constructor(
    private _el: ElementRef,
    private _formBuilder: FormBuilder,
    private _reportDatasource: ReportDatasourceService,
    private _locationKtxDataSourceService: LocationsKtxDataSourceService,
    private _ktxLocationService: LocationsKtxDataSourceService,
    private _roleService: RoleService,
  ) {
  }

  async ngOnInit() {
    this.initListOfWeeksRange();
    this.initMeasuring();

    this._filterFormBuilder = this._formBuilder.group({
      dateRange: [this._ktxWeeks[0].value],
      measuring: [this._measuring[1].value],
    });
    this._isFarenheitMeasureMode = this._filterFormBuilder.controls.measuring.value == 1;
    this.temperatureTelemetry = new TelemetrySnapshotViewModel();
    this.setSubscriptions();
  }

  ngAfterViewInit() {
    this.reinitScrollBarTable(0);
    this.reinitScrollBar(0);
    this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body').classList.add('is-scrollable-y', 'is-scrollable-x');
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  openDateFilter() {
    this._isOpenDateFilter = !this._isOpenDateFilter;
    if (this._isOpenDateFilter) {
      this._isOpenFilterList = false;
      this.isOpenLocationList = false;
    }
  }

  openLocationList(): void {
    this.isOpenLocationList = !this.isOpenLocationList;
    if (this.isOpenLocationList) {
      this._isOpenDateFilter = false;
      this._isOpenFilterList = false;
    }
  }

  openFilterList(): void {
    this._isOpenFilterList = !this._isOpenFilterList;
    if (this._isOpenFilterList) {
      this._isOpenDateFilter = false;
      this.isOpenLocationList = false;
    }
  }

  markAsSelectedDateRange(index: number) {
    this._filterFormBuilder.controls.dateRange.setValue([this._ktxWeeks[index].value]);
    this.openDateFilter();
    this._gridData = [];
    this._columnsView = [];
    this.fetchListOfSnapshots();
  }

  markAsSelectedMeasuring(index: number) {
    this._filterFormBuilder.controls.measuring.setValue([this._measuring[index].value]);
    this._isFarenheitMeasureMode = this._filterFormBuilder.controls.measuring.value == 1;

    this.openFilterList();
  }

  fetchLocationDetails() {
    if (this._filterParameters.locationid) {
      this._locationKtxDataSourceService.getTemperatureRangesForLocation(this._filterParameters.locationid).then((result) => {
        this._temperatureInfo = result as TemperatureRangeAlarmViewModel;
        this._temperatureColorRange = TemperatureRangeViewModel.toTemperatureColorViewModels(this._temperatureInfo.temperatureRanges);
      });
    }
  }

  fetchListOfSnapshots() {
    if (this._filterFormBuilder && this._filterFormBuilder.controls['dateRange'].value && this._filterParameters) {
      this._gridData = [];
      this._columnsView = [];

      let weekFromTS = Math.floor(this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekFrom.getTime() / 1000);
      let weeksToTS = Math.floor(this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekTo.getTime() / 1000);

      this._locationKtxDataSourceService.getListOfTimestampsForThePeriod(
        this._filterParameters.locationid,
        this._filterParameters.binId,
        weekFromTS,
        weeksToTS).then((x) => {
          let result = x as BinSnapshotTimestampsByPeriodResponse;
          this._snapshots = result.timeStamps && result.timeStamps.sort(function (a, b) { return b - a; });
          if (this._snapshots && this._snapshots.length > 0) {
            this._selectedTimeStamp = this._snapshots[0];
            this.fetchHistoricalSnapshotByTimeStamp(this._snapshots[0]);
          }
          else {
            this._loading = false;
          }
        });
    }
    else {
      this._loading = false;
    }
  }

  fetchHistoricalSnapshotByTimeStamp(timestamp: number) {
    if (timestamp && this._filterParameters && this._filterParameters.binId) {
      this._locationKtxDataSourceService.getHistoricalSnapshotByTimestamp(
        this._filterParameters.binId, timestamp).then((x) => {
          this._gridData = [];
          this._columnsView = [];
          let result = x as CableSnapshotResponse[];
          let maxSensorCount = Math.max(...result.map(x => {
            return x.sensors.length;
          }));

          for (let i = 1; i <= maxSensorCount; i++) {
            this._columnsView.push({ header: 'TC ' + i, columnType: ColumnTypeEnum.TC, dataField: 'sensors' })
          }
          this._loading = false;
          this._gridData = result;
          return x;
        });
    }
  }

  getDate(unix: number) {
    var date = new Date(unix * 1000);
    let result = date.toLocaleString('default', { day: 'numeric' }) + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.toLocaleString('default', { year: 'numeric' });
    return result;
  }

  getTime(unix: number) {
    var date = new Date(unix * 1000);
    let result = date.toLocaleTimeString();
    return result;
  }

  async onClickBinFilter(event) {
    if (event.node.children === false) {
      this._filterParameters = null;
      let selectedNode: TreeNode = event.node;
      this._selectedLocationName = null;
      this._selectedBinName = null;
      this._selectedUnitName = null;
      this._nodeSelected = null;
      this._nodeSelected = selectedNode;
      if (selectedNode) {
        this._selectedBinName = selectedNode.label;
        let binId = selectedNode.data.id;
        let selectedLocation: LocationNameViewModel;
        this._companies.forEach((c) => {
          c.locations.forEach((l) => {
            if (l.bins.some(b => b.id == binId) || l.bins.some(u => u.bins && u.bins.some(b => b.id == binId))) {
              selectedLocation = l;
            };
          });
        });
        this._selectedLocationName = selectedLocation.name;
        if (selectedLocation.bins) {
          selectedLocation.bins.forEach((unit) => {
            if (unit.bins && unit.bins.length > 0 && unit.bins.some(bin => bin.id == binId)) {
              this._selectedUnitName = unit.name;
            }
          })
        }
        this._filterParameters = { binId: binId, locationid: selectedLocation.id };
      }

      this.openLocationList();
      this.tryFetchSnapshotList();
    }
    else {
      this._nodeSelected = null;
    }
  }

  onClickTimeStamp(timestamp: number) {
    if (timestamp) {
      this._selectedTimeStamp = timestamp;
      this.fetchHistoricalSnapshotByTimeStamp(timestamp);
    }
  }

  async onClickExportReport() {
    if (this._filterParameters.binId && this._filterParameters.locationid &&
      this._filterFormBuilder!.controls!.dateRange!.value && this._filterFormBuilder!.controls!.dateRange!.value) {
      let filter: KtxReportHistoricalDataFilter = new KtxReportHistoricalDataFilter();
      filter.binId = this._filterParameters.binId;
      filter.locationId = this._filterParameters.locationid;
      filter.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      filter.temperatureMeasureType = this._measuring[this._filterFormBuilder!.controls!.measuring!.value].value;
      filter.timestampFrom = Math.floor(this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekFrom.getTime() / 1000);
      filter.timestampTo = Math.floor(this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekTo.getTime() / 1000);

      let result = await this._reportDatasource.downloadKtxHistoricalReportFile(filter);
      if (!(result instanceof ErrorModel)) {
        let fileName = this._selectedLocationName + ' ' + this._selectedBinName + ' ' + this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekFrom.toLocaleDateString() + ' - ' + this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekTo.toLocaleDateString();
        const url = URL.createObjectURL(result as Blob);
        let downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
  }

  tryFetchSnapshotList() {
    this._loading = true;
    //this._gridData = [];
    this.fetchListOfSnapshots();
    this.fetchLocationDetails();
  }

  splitStringBySpace(input: string): string[] {
    return input.split(' ');
  }

  private reinitScrollBar(timeout?: number) {
    const el = this._el.nativeElement.querySelector('.popup-container');
  }

  reinitScrollBarTable(timeout?: number, withCheck = false) {
    const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (el) {
    }
  }

  private isAnyChild(treeNode: TreeNode): boolean {
    return treeNode.children && treeNode.children.length > 0;
  }

  _getSensorColor(cable: CableSchemeViewModel, sensorIndex: number): string {
    const sensor = cable && cable.sensors ? cable.sensors[sensorIndex] as SensorKtxSchemeViewModel : null;
    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return this._temperatureInfo.alarm.openTcColorHex;
      }

      else if (sensor.status === SensorStatusEnum.NoResponse) {
        return this._temperatureInfo.alarm.noResponseColorHex;
      }

      else {
        if (sensor.status === SensorStatusEnum.HighLimit) {
          return this._temperatureInfo.alarm.highTemperatureColorHex;
        }

        else if (sensor.status === SensorStatusEnum.MaxRise) {
          return this._temperatureInfo.alarm.maxRiseColorHex;
        }

        else if (this._temperatureInfo && this._temperatureInfo.temperatureRanges) {
          const temperatureRange = this._temperatureInfo.temperatureRanges.find(x => sensor.value >= x.from && sensor.value <= x.to);
          return temperatureRange == undefined ? '' : temperatureRange.colorHex;
        }

        return '-';
      }
    }
    return '-';
  }

  _getSensorValue(cable: CableSchemeViewModel, sensorIndex: number): string | number {
    const sensor = cable && cable.sensors ? cable.sensors[sensorIndex] as SensorKtxSchemeViewModel : null;
    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return 'OT';
      }

      if (sensor.status === SensorStatusEnum.NoResponse) {
        return '-';
      }

      if (this.isFarenheitMeasureMode) {
        const farenheitValue = this.onCelciusToFarenheit(sensor.value);
        return farenheitValue;
      }

      return sensor.value;
    }
    return '';
  }

  onCelciusToFarenheit(value: number): string {
    if (value || value === 0) {
      const convertedValue = ConvertDataFunctions.celsiusToFahrenheit(value);
      return convertedValue.toString();
    } else {
      return '-';
    }
  }

  getBackGroundTextColor(cable: CableSchemeViewModel, sensorIndex: number): string {
    const sensor = cable ? cable[sensorIndex] as SensorKtxSchemeViewModel : null;

    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return '#FFFFFF';
      }
    }

    return '';
  }

  getWeekByIndex(index: number) {
    return this._ktxWeeks[index].week;
  }

  getDateRangeByIndex(index: number) {
    return this._ktxWeeks[index].range;
  }

  getMeasureByIndex(index: number) {
    return this._measuring[index].label;
  }

  setSubscriptions(): void {
    const companiesSubscription =
      this._ktxLocationService.companiesWithLocations$.subscribe(
        (companies) => {
          this._companies = companies;
          this.initCompanyWithLocationsNodes(companies);
        }
      );

    const withBins = true;
    this._ktxLocationService.getCompaniesWithLocations(withBins);

    this.subscriptions.push(companiesSubscription);
  }

  private initCompanyWithLocationsNodes(companies: CompanyViewModel[]) {
    const companyWithLocationNodes =
      companies && companies
        .map(CompanyViewModel.toKtxTreeNode)
        .filter(c => c.children && c.children.length > 0)
        .sort(CommonFunctions.compareTreeNodeLables);

    this._ktxLocationNodes = companyWithLocationNodes;
    if (this._ktxLocationNodes.length > 0) {
      let treeNode: TreeNode = this._ktxLocationNodes[0];
      while (this.isAnyChild(treeNode)) {
        treeNode.selectable = false;
        if (!this.isAnyChild(treeNode.children[0])) {
          treeNode.expanded = true;
          treeNode.children[0].partialSelected = true;
          this._nodeSelected = treeNode.children[0];
          this._selectedLocationName = treeNode.label
          this._selectedBinName = treeNode.children[0].label;
          this._filterParameters = { binId: treeNode.children[0].data.id, locationid: treeNode.data.id };
          this.tryFetchSnapshotList();
        }
        treeNode.expanded = true;
        treeNode = treeNode.children[0];
      }
    }
  }

  private initListOfWeeksRange() {
    this._ktxWeeksRange;
    const weeks: KtxWeeksRangeModel[] = [];
    const currentDate = new Date();
    for (let i = 1; i <= 78; i++) {
      const weekStartDate = new Date(currentDate);
      weekStartDate.setDate(currentDate.getDate() - i * 7);
      weekStartDate.setHours(0, 0, 0, 0);

      const weekEndDate = new Date(currentDate);
      weekEndDate.setDate(currentDate.getDate() - i * 7 + 7);
      weekEndDate.setHours(23, 59, 59, 999);

      const model = new KtxWeeksRangeModel(i, weekStartDate, weekEndDate);
      this._ktxWeeks.push({ week: i + ' week ago ', range: weekStartDate.toLocaleDateString() + ' - ' + weekEndDate.toLocaleDateString(), value: i });
      weeks.push(model);
    }

    this._ktxWeeksRange = weeks;
  }

  private initMeasuring() {
    this._measuring.push({ label: 'C', value: 0 });
    this._measuring.push({ label: 'F', value: 1 });
  }

  getTitle() {
    if (this._selectedUnitName) {
      return this._selectedLocationName + ": " + this._selectedUnitName + ": " + this._selectedBinName;
    } else {
      return this._selectedLocationName + ": " + this._selectedBinName;
    }
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.KtxHistoricalData);
  }
}
