import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, NgZone, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { FullnessRangeViewModel, LocationItxViewModel } from './../../../models/';
import { ColumnTypeEnum, ProjectEnum } from '../../../enums';
import { ItxAddEditPopupComponent, ItxManageColorPopupComponent, ItxRangeDeletePopupComponent, ItxListAddRadarPopupComponent, DeviceCommunicationPopupComponent } from '../../popups';
import { LocationsItxDatasourceService } from '@services';
import { MainRoutePaths } from '../../../constants';


@Component({
  selector: 'greensleeves-itx-grid',
  templateUrl: './itx-grid.component.html'
})

export class ITXGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DeviceCommunicationPopupComponent, { read: false, static: false })
  private deviceCommunicationPopup: DeviceCommunicationPopupComponent;

  static LOCATION_NAME_KEY = 'locationName';
  static COMPANY_NAME_KEY = 'companyName';
  static SORT_ORDER_DEFAULT = 1;

  public onEditClicked = new EventEmitter<LocationItxViewModel>();
  public onFullnessRangesClicked = new EventEmitter<LocationItxViewModel>();
  public onDeleteClicked = new EventEmitter<LocationItxViewModel>();

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = ITXGridComponent.LOCATION_NAME_KEY;
  _sortOrder = ITXGridComponent.SORT_ORDER_DEFAULT;
  _gridData: LocationItxViewModel[];
  _companyNameKey = ITXGridComponent.COMPANY_NAME_KEY;
  _locationNameKey = ITXGridComponent.LOCATION_NAME_KEY;
  _searchPhrase: string = '';
  _ps: PerfectScrollbar;
  _initializeDeviceKey = 'isInitialized';
  _canBeInitializedKey = 'canDeviceBeInitialized';

  @ViewChild(ItxAddEditPopupComponent, { read: false, static: false })
  _editModal: ItxAddEditPopupComponent;

  @ViewChild(ItxManageColorPopupComponent, { read: false, static: false })
  private manageColorModal: ItxManageColorPopupComponent;

  @ViewChild(ItxRangeDeletePopupComponent, { read: false, static: false })
  _rangeDeleteModal: ItxRangeDeletePopupComponent;

  @ViewChild(ItxManageColorPopupComponent, { read: false, static: false })
  _fullnessRangesModal: ItxManageColorPopupComponent;

  _columnsView = [
    { header: 'Location name', columnType: ColumnTypeEnum.LocationName, dataField: ITXGridComponent.LOCATION_NAME_KEY },
    { header: 'Company', columnType: ColumnTypeEnum.Company, dataField: ITXGridComponent.COMPANY_NAME_KEY },
    { header: 'ITX IP Address', columnType: ColumnTypeEnum.IPAddress, dataField: 'ipAddress' },
    { header: 'Port', columnType: ColumnTypeEnum.Port, dataField: 'port', width: "70" },
    { header: 'Acq rate', columnType: ColumnTypeEnum.AcquisitionRate, dataField: 'acquisitionRate', width: "100" },
    { header: 'Storages', columnType: ColumnTypeEnum.Bins, dataField: 'countOfBins', width: "70" },
  ]

  _frozenColumns = [
    { header: '', columnType: ColumnTypeEnum.InitializeDevice, width: 46 },
    { header: '', columnType: ColumnTypeEnum.DownloadConnectionFile, width: 46 },
    { header: '', columnType: ColumnTypeEnum.Equipment, width: 46, dataField: 'id' },
    { header: '', columnType: ColumnTypeEnum.Edit, width: 46 },
    { header: '', columnType: ColumnTypeEnum.Delete, width: 46 }
  ]

  _searchForm: FormGroup;
  _lastSearchPhrase: string;

  _initLoaders: { [key: number]: boolean } = {};
  subscriptions: Subscription[] = [];
  _loading: boolean = false;
  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  constructor(
    private _locationsItxDataSourceService: LocationsItxDatasourceService,
    private _el: ElementRef,
    private _zone: NgZone,
    formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }

  async ngOnInit() {
    this.reinitScrollBar(0);
    let locationSub = this._locationsItxDataSourceService.locationsItx$.subscribe(items => {
      this._gridData = items;
      this._loading = false;
    });
    this._loading = true;
    this._locationsItxDataSourceService.get();
    this.subscriptions.push(locationSub);
  }

  ngOnDestroy() {
    this.subscriptions &&
      this.subscriptions.forEach((sub) => sub.unsubscribe());

    this._ps && this._ps.destroy();
    this._ps = null;
  }

  ngAfterViewInit() {

    let rangeDeleteSubscription = this._fullnessRangesModal.onRangeDeleteClicked.subscribe((range: FullnessRangeViewModel) => {
      this._rangeDeleteModal.show(range);
    })


    let fullnessSubscription = this.onFullnessRangesClicked.subscribe((location) => {
      this._fullnessRangesModal.show(location);
    });

    let rangeDeletedSubscription = this._rangeDeleteModal.onSubmit$.subscribe((range: FullnessRangeViewModel) => {
      this._fullnessRangesModal.removeRange(range);
    });

    this.subscriptions.push(
      fullnessSubscription,
      rangeDeleteSubscription,
      rangeDeletedSubscription
    )
    this.reinitScrollBar(0);
  }

  public async onEdit(location) {
    await this._locationsItxDataSourceService.markLocationItxForOthersAsInUpdate(location.id);
    this.onEditClicked.emit(location);
  }

  async onClickDownloadFile(location: LocationItxViewModel) {
    if (!location) {
      return;
    }

    this.deviceCommunicationPopup.onShow({ locationName: location.locationName, locationId: location.id, deviceId: location.deviceId, project: ProjectEnum.Itx });
  }

  onManageEquipmentClick(location: LocationItxViewModel) {
    this.manageColorModal.show(location);
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
  }

  async onClickDelete(location: LocationItxViewModel) {
    this.onDeleteClicked.emit(location);
  }

  async onClickInitialized(location: LocationItxViewModel) {
    if (!location) {
      return;
    }

    location.isDeviceInInitialization = true;
    location.isInitialized = await this._locationsItxDataSourceService.initializeDeviceConfiguration(location.id) as boolean;
    location.isDeviceInInitialization = false;
  }

  public reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  onShowStorages(locationId: number) {
    this._locationsItxDataSourceService.markLocationItxForOthersAsStoragesInView(locationId, true);
    this._router.navigate([`../${MainRoutePaths.ITX_STORAGES_MANAGEMENT}`, locationId], { relativeTo: this._route });
  }
}
