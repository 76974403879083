import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'greensleeves-aqs-computation-sheet-confirm-popup',
  templateUrl: './aqs-computation-sheet-confirm-popup.component.html',
  styles: []
})
export class AqsComputationSheetConfirmPopupComponent implements OnInit {

  constructor() { }

  @Output() method = new EventEmitter<any>();

  _title = "";
  _subtitle = "";
  _isHidden = true;
  _parameter: any;

  ngOnInit() {
  }

  actionMethod() {
    if (this._parameter != undefined) {
      this.method.emit(this._parameter);
    } else {
      this.method.emit();
    }

    this.onCancel();
  }

  public showConfirmPopup(title: string, subtitle: string, parameter: any = undefined) {
    this._title = title;
    this._subtitle = subtitle;

    if (parameter != undefined) {
      this._parameter = parameter;
    }
    this._isHidden = false;
  }

  public onCancel() {
    this._isHidden = true;
  }
}
