import { OnInit, AfterViewChecked, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import PerfectScrollbar from 'perfect-scrollbar';
import { Dropdown } from 'primeng/dropdown';
import { MultiSelect } from 'primeng/multiselect';

export class BasePopupBehavior implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChildren('dropdownScrollable')
  _dropdownsAll: QueryList<Dropdown>;

  @ViewChildren('multiSelectScrollable')
  _multiSelectsAll: QueryList<MultiSelect>;

  _perfectScrollbarMain: PerfectScrollbar;
  _resizeHelperMutationObserver: MutationObserver;

  _perfectScrollbarForDropdown: PerfectScrollbar;
  _perfectScrollBarForTable: PerfectScrollbar;

  private isDropdownsInited = false;
  private isMultiSelectsInited = false;
  private dropdownItemsWrapperClass = 'ui-dropdown-items-wrapper';
  private multiSelectItemsWrapperClass = 'ui-multiselect-items-wrapper';

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    if (!this.isDropdownsInited && this._dropdownsAll && this._dropdownsAll.length > 0) {
      this.isDropdownsInited = true;
      this._dropdownsAll.forEach(d => d.onShow.subscribe(event => this.onShowDropdown(event)));
    }
    if (!this.isMultiSelectsInited && this._multiSelectsAll && this._multiSelectsAll.length > 0) {
      this.isMultiSelectsInited = true;
      this._multiSelectsAll.forEach(d => d.onPanelShow.subscribe(() => this.onOpenMultiSelect(d)));
    }
  }

  ngOnDestroy() {
    this._perfectScrollbarMain && this._perfectScrollbarMain.destroy();
    this._perfectScrollbarForDropdown && this._perfectScrollbarForDropdown.destroy();
    this._perfectScrollBarForTable && this._perfectScrollBarForTable.destroy();
  }

  onShowDropdown(event: AnimationEvent) {
    let el = event.element.getElementsByClassName(this.dropdownItemsWrapperClass);
    this.reinitDropdownScrollBar(el[0], 10);
  }

  onOpenMultiSelect(multiSelect: MultiSelect) {
    let el = multiSelect.el.nativeElement.getElementsByClassName(this.multiSelectItemsWrapperClass);
    this.reinitDropdownScrollBar(el[0], 10);
  }

  protected reinitMainScrollBar(element: HTMLElement, timeout?: number) {
    this._perfectScrollbarMain && this._perfectScrollbarMain.destroy();

    const createScrollbarFunc = () => {
      this._perfectScrollbarMain = new PerfectScrollbar(element);
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  protected reinitTableScrollBar(element: HTMLElement, timeout?: number) {
    this._perfectScrollBarForTable && this._perfectScrollBarForTable.destroy();

    const createScrollbarFunc = () => {
      this._perfectScrollBarForTable = new PerfectScrollbar(element);
    };

    if(timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  protected destroyMainScrollBar() {
    if (this._perfectScrollbarMain) {
      this._perfectScrollbarMain.destroy();
    }
  }

  protected destroyTableScrollBar() {
    this._perfectScrollBarForTable && this._perfectScrollBarForTable.destroy();
  }

  private reinitDropdownScrollBar(element: HTMLElement, timeout?: number) {
    this._perfectScrollbarForDropdown && this._perfectScrollbarForDropdown.destroy();

    const createScrollbarFunc = () => {
      this._perfectScrollbarForDropdown = new PerfectScrollbar(element);
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }
}
