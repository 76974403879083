export enum SwitchTypeEnum {
    NONE = 0,
    MUX,
    CABLE,
    BCS_AR,
    BCS_ARO,
    BCS_KT,
    BCS_SAE,
    BCS_110E,
    BCS_IICS,
    BCS_FLEX,
    BCS_RT,
    BCS_GT1000
}