import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ColumnTypeEnum } from '../../../enums';
import { CompanyAdminViewModel } from '../../../models';
import { CompanyAdminsDataSourceService } from '../../../services';
import { LocalStorageService, UserRoleEnum } from '../../../../common';
import { Subscription } from 'rxjs';
import { CompanyAdminEditPopupComponent, CompanyAdminDeletePopupComponent } from '../../popups';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'greensleeves-company-admins-grid',
  templateUrl: './company-admins-grid.component.html'
})

export class CompanyAdminsGridComponent implements OnInit, OnDestroy {
  static COMPANY_NAME_KEY = 'companyName';
  static COMPANY_ORDER_KEY = 'id';
  static SORT_ORDER_DEFAULT = -1;

  @ViewChild(CompanyAdminEditPopupComponent, { read: false, static: false })
  _editModal: CompanyAdminEditPopupComponent;

  @ViewChild(CompanyAdminDeletePopupComponent, { read: false, static: false })
  _deleteModal: CompanyAdminDeletePopupComponent;

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = CompanyAdminsGridComponent.COMPANY_ORDER_KEY;
  _sortOrder = CompanyAdminsGridComponent.SORT_ORDER_DEFAULT;
  _gridData: CompanyAdminViewModel[];
  _companyNameKey = CompanyAdminsGridComponent.COMPANY_NAME_KEY;
  _currentUserId: string;
  _searchPhrase: string = '';
  _searchForm: FormGroup;
  _lastSearchPhrase: string;
  _loading: boolean = false;

  _companyAdminsSubscription: Subscription;
  _role: UserRoleEnum;

  get _isServiceUser(): boolean {
    return this._role === UserRoleEnum.ServiceUser;
  }

  get _isCompanyAdmin(): boolean {
    return this._role === UserRoleEnum.CompanyAdmin;
  }

  get _columns() {
    return [
      { header: 'First name', columnType: ColumnTypeEnum.UserFirstName, dataField: 'firstName' },
      { header: 'Last name', columnType: ColumnTypeEnum.UserLastName, dataField: 'lastName' },
      ...(this._isServiceUser ? [{ header: 'Company', dataField: 'companyName' }] : []),
      { header: 'Email', dataField: 'email' },
      { header: 'Last login date', columnType: ColumnTypeEnum.Date, dataField: 'lastLoginDate' },
      { header: '', columnType: ColumnTypeEnum.Edit, width: "46" },
      { header: '', columnType: ColumnTypeEnum.Delete, dataField: 'id', width: "46" }
    ];
  }

  constructor(
    private _companyAdminsService: CompanyAdminsDataSourceService,
    private _localStorageService: LocalStorageService,
    formBuilder: FormBuilder
  ) {
    this._searchForm = formBuilder.group({
      searchPhrase: ['']
    });
  }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;
    this._currentUserId = info.id;
    if (this._isServiceUser) {
      this._companyAdminsService.get(null);
    } else {
      this._companyAdminsService.get(info.companyId);
    }
    this._loading = true;
    this._companyAdminsSubscription = this._companyAdminsService.companyAdmins$
      .subscribe(data => {
        this._gridData = data
        this._loading = false;
      });
  }

  ngOnDestroy() {
    this._companyAdminsSubscription && this._companyAdminsSubscription.unsubscribe();
    this._companyAdminsSubscription = null;
  }

  onClickEdit(companyAdmin: CompanyAdminViewModel) {
    this._companyAdminsService.markCompanyAdminForOthersAsInUpdate(companyAdmin.id, companyAdmin.companyId);
    this._editModal.show(companyAdmin);
  }

  onClickDelete(companyAdmin: CompanyAdminViewModel) {
    this._deleteModal.show(companyAdmin);
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
  }
}
