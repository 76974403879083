import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng';
import { Subscription } from 'rxjs';

import { LocationsBtxDataSourceService, RoleService } from '@services';
import { CommonFunctions, UserRoleEnum } from '../../../common';

import {
  BtxAccordionLocationItemViewModel,
  BtxLiveDataEquipmentViewModel,
  BtxLiveDataLocationViewModel,
  CompanyViewModel,
} from '@models';
import { BtxNotificationFilterNodeTypeEnum, TutorialMenuEnum } from '../../enums';
import {
  EquipmentPlaceholderClassNames,
  MainRoutePaths,
} from '../../constants';
import { TutorialPopupComponent } from '../popups';

@Component({
  selector: 'greensleeves-btx-live-data-tab',
  templateUrl: './btx-live-data-tab.component.html',
})
export class BtxLiveDataTabComponent implements OnInit {
  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  _isOpenFilterList: boolean = false;
  _btxLocationNodes: TreeNode[] = [];
  _btxLocationNodesSelected: TreeNode[];
  _searchForm: FormGroup;
  _liveDataLocations: BtxLiveDataLocationViewModel[];
  _filteredLiveDataLocations: BtxAccordionLocationItemViewModel[];
  _searchedEquipmentIds: number[] = [];
  _filteredEquipmentIds: number[] = [];
  _loading: boolean = false;
  private subscriptions: Subscription[] = [];

  get _countSelectedFilter(): number {
    if (
      !this._btxLocationNodesSelected ||
      !this._btxLocationNodesSelected.length
    ) {
      return 0;
    }

    const selectedEquipment = this._btxLocationNodesSelected.filter((node) => {
      return node.data.nodeType === BtxNotificationFilterNodeTypeEnum.Equipment;
    });

    return selectedEquipment.length;
  }

  get isTutorialUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser);
  }

  constructor(
    private _btxLocationService: LocationsBtxDataSourceService,
    private _router: Router,
    private _route: ActivatedRoute,
    formBuilder: FormBuilder,
    private _roleService: RoleService
  ) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }

  ngOnInit() {
    this.setSubscriptions();
  }

  setSubscriptions(): void {
    const companiesSubscription =
      this._btxLocationService.companiesWithLocations$.subscribe(
        (companies) => {
          this.initCompanyWithLocationsNodes(companies);
        }
      );

    const liveDatalocationsSubscription =
      this._btxLocationService.liveDataLocations$.subscribe(
        liveDataLocations => {

          const sortedLocations = liveDataLocations
            .filter(l => l.equipments && l.equipments.length > 0)
            .map<BtxLiveDataLocationViewModel>(location => ({
              ...location,
              equipments: location.equipments.sort((equipmentA, equipmentB) =>
                CommonFunctions.compareStrings(
                  equipmentA.name,
                  equipmentB.name,
                ),
              ),
            }))
            .sort((locationA, locationB) =>
              CommonFunctions.compareStrings(locationA.name, locationB.name),
            );

          this._liveDataLocations = sortedLocations;
          this.updateFilteredLiveDataLocations();
          this.expandPreviousSelectedEquipmentLocation();
          this._loading = false;
        },
      );

    const withEquipment = true;
    this._btxLocationService.getCompaniesWithLocations(withEquipment);
    if (!this._liveDataLocations || this._liveDataLocations.length == 0) {
      this._loading = true;
      this._btxLocationService.getLiveDataLocations();
    }
    this.subscriptions.push(
      companiesSubscription,
      liveDatalocationsSubscription
    );
  }

  getEquipmentPlaceholderClassName(
    equipment: BtxLiveDataEquipmentViewModel
  ): string {
    const equipmentPlaceholderClassName =
      EquipmentPlaceholderClassNames[equipment.type.id][equipment.subType.id];

    return equipmentPlaceholderClassName;
  }

  openFilterList() {
    this._isOpenFilterList = !this._isOpenFilterList;
  }

  onClickLocationFilter() {
    const equipmentIds: number[] = this._btxLocationNodesSelected
      .filter(
        (n) => n.data.nodeType === BtxNotificationFilterNodeTypeEnum.Equipment
      )
      .map((n) => n.data.id);

    this._filteredEquipmentIds = equipmentIds;

    this.updateFilteredLiveDataLocations();
  }

  onSearch() {
    const { value } = this._searchForm.controls.searchPhrase;
    const equipmentIds = this.findEquipmentIdsBySearchQuery(value);

    this._searchedEquipmentIds = equipmentIds;

    this.updateFilteredLiveDataLocations();
  }

  redirectToEquipment(equipment: BtxLiveDataEquipmentViewModel) {
    this._btxLocationService.selectedEquipmentId$.next(equipment.id);
    this._router.navigate(
      [`../${MainRoutePaths.BTX_LIVE_DATA_EQUIPMENT_VIEW}`],
      {
        relativeTo: this._route,
      }
    );
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.BtxLiveData);
  }

  private initCompanyWithLocationsNodes(companies: CompanyViewModel[]) {
    const companyWithLocationNodes =
      companies &&
      companies
        .map(CompanyViewModel.toBtxTreeNode)
        .sort((a, b) => a.label.localeCompare(b.label));

    this._btxLocationNodes = companyWithLocationNodes;
  }

  private getAllAccordionLocations(): BtxAccordionLocationItemViewModel[] {
    if (!this._liveDataLocations) {
      return [];
    }

    const accordionLocations =
      this._liveDataLocations.map<BtxAccordionLocationItemViewModel>(
        (location) => {
          const accordionLocation: BtxAccordionLocationItemViewModel = {
            ...location,
            isActive: false,
          };

          return accordionLocation;
        }
      );

    return accordionLocations;
  }

  private getAccordionLocationsByEquipmentIds(
    equipmentIds: number[]
  ): BtxAccordionLocationItemViewModel[] {
    if (!this._liveDataLocations) {
      return;
    }

    const filteredAccordionLocations = this._liveDataLocations
      .map<BtxAccordionLocationItemViewModel>((location) => {
        const filteredEquipments: BtxLiveDataEquipmentViewModel[] =
          location.equipments.filter((e) => equipmentIds.includes(e.id));

        if (!filteredEquipments || !filteredEquipments.length) {
          return null;
        }

        const accordionLocation: BtxAccordionLocationItemViewModel = {
          ...location,
          isActive: true,
          equipments: filteredEquipments,
        };

        return accordionLocation;
      })
      .filter(Boolean);

    return filteredAccordionLocations;
  }

  private findEquipmentIdsBySearchQuery(searchQuery: string): number[] {
    if (searchQuery === '') {
      return [];
    }

    const allEquipments: BtxLiveDataEquipmentViewModel[] =
      this._liveDataLocations.reduce(
        (result, location) => result.concat(location.equipments),
        []
      );

    searchQuery = searchQuery.toLowerCase();

    const equipmentIds: number[] = allEquipments
      .filter((equipment) => {
        const equipmentName = equipment.name.toLowerCase();
        const equipmentSubTypeName = equipment.subType.name.toLowerCase();
        const equipmentTypeName = equipment.type.name.toLowerCase();

        return (
          equipmentName.includes(searchQuery) ||
          equipmentSubTypeName.includes(searchQuery) ||
          equipmentTypeName.includes(searchQuery)
        );
      })
      .map((e) => e.id);

    return equipmentIds.length === 0 ? [-1] : equipmentIds;
  }

  private updateFilteredLiveDataLocations() {
    const searchedIdsLength = this._searchedEquipmentIds.length;
    const filteredIdsLength = this._filteredEquipmentIds.length;

    if (searchedIdsLength === 0 && filteredIdsLength === 0) {
      this._filteredLiveDataLocations = this.getAllAccordionLocations();
      return;
    }

    const equipmentIds: number[] =
      searchedIdsLength > 0 && filteredIdsLength > 0
        ? this._searchedEquipmentIds.filter((id) =>
          this._filteredEquipmentIds.includes(id)
        )
        : filteredIdsLength > 0
          ? this._filteredEquipmentIds
          : this._searchedEquipmentIds;

    const filteredLiveDataLocations =
      this.getAccordionLocationsByEquipmentIds(equipmentIds);

    this._filteredLiveDataLocations = filteredLiveDataLocations;
  }

  private expandPreviousSelectedEquipmentLocation() {
    const previousEquipment = this._btxLocationService.selectedEquipmentId$.getValue();

    if (!previousEquipment) {
      return;
    }
    let previousEquipmentLocation: BtxAccordionLocationItemViewModel;
    this._filteredLiveDataLocations.forEach(x => {
      if (x.equipments.find(e => e.id == previousEquipment)) {
        previousEquipmentLocation = x;
      }
    })

    if (!previousEquipmentLocation) {
      return;
    }

    previousEquipmentLocation.isActive = true;
    setTimeout(() => {
     document.getElementById(`equipment_${previousEquipment}_card`).scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
    this._btxLocationService.selectedEquipmentId$.next(null);
    },1);
  }
}
