import { Component, OnInit } from '@angular/core';

import { VendorDataSourceService } from '../../../services';
import { VendorViewModel } from './../../../models/vendor.model';

@Component({
  selector: 'greensleeves-vendor-delete-popup',
  templateUrl: './vendor-delete-popup.component.html',
  styles: []
})
export class VendorDeletePopupComponent implements OnInit {
  _isHidden = true;
  _vendor: VendorViewModel;
  _companyId: number;
  constructor(
    private _vendorDataSourceService: VendorDataSourceService
  ) { }

  ngOnInit() {
  }

  async onSubmit() {
    const result = await this._vendorDataSourceService.deleteVendor(this._vendor.id,this._companyId);
    if (result != null) {
      this._isHidden = true;
      this._vendor = null;
    }
  }

  onCancel() {
    this._isHidden = true;
    this._vendor = null;
  }

  public show(vendor: VendorViewModel, companyId: number) {
    this._isHidden = false;
    this._vendor = vendor;
    this._companyId=companyId;
  }

}
