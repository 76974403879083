import { ItxBinTypeEnum, CapacityUnitEnum, SizeMeasureEnum, TemperatureMeasureEnum, ItxStorageSortingEnum } from "../enums";

export class ItxBinIconClass {
    public static readonly bin_conical = 'bin-conical-icon';
    public static readonly bin_sloped = 'bin-sloped-icon';
    public static readonly bin_flat = 'bin-flat-icon';
    public static readonly silo_flat = 'silo-flat-icon';
    public static readonly silo_conical = 'silo-conical-icon';
    public static readonly silo_sloped = 'silo-sloped-icon';
    public static readonly liquid_flat = 'liquid-storage-flat-icon';
    public static readonly liquid_conical = 'liquid-storage-conical-icon';
    public static readonly liquid_sloped = 'liquid-storage-sloped-icon';
    public static readonly storage_flat = 'flat-storage-level-icon';
    public static readonly storage_conical = 'flat-storage-conical-icon';
    public static readonly storage_sloped = 'flat-storage-sloped-icon';
    public static getIconClass(binType: ItxBinTypeEnum): string {
        switch (binType) {
            case ItxBinTypeEnum.BinFlat:
                return ItxBinIconClass.bin_flat;
            case ItxBinTypeEnum.BinConical:
                return ItxBinIconClass.bin_conical;
            case ItxBinTypeEnum.BinSloped:
                return ItxBinIconClass.bin_sloped;
            case ItxBinTypeEnum.SiloFlat:
                return ItxBinIconClass.silo_flat;
            case ItxBinTypeEnum.SiloConical:
                return ItxBinIconClass.silo_conical;
            case ItxBinTypeEnum.SiloSloped:
                return ItxBinIconClass.silo_sloped;
            case ItxBinTypeEnum.LiquidStorageFlat:
                return ItxBinIconClass.liquid_flat;
            case ItxBinTypeEnum.LiquidStorageConical:
                return ItxBinIconClass.liquid_conical;
            case ItxBinTypeEnum.LiquidStorageSloped:
                return ItxBinIconClass.liquid_sloped;
            case ItxBinTypeEnum.FlatStorageLevel:
                return ItxBinIconClass.storage_flat;
            case ItxBinTypeEnum.FlatStorageConical:
                return ItxBinIconClass.storage_conical;
            case ItxBinTypeEnum.FlatStorageSloped:
                return ItxBinIconClass.storage_sloped;
        }
    }
}

export const ItxBinTypeLabel = {
    [ItxBinTypeEnum.BinFlat]: 'Bin flat',
    [ItxBinTypeEnum.BinConical]: 'Bin conical',
    [ItxBinTypeEnum.BinSloped]: 'Bin sloped',
    [ItxBinTypeEnum.SiloFlat]: 'Silo flat',
    [ItxBinTypeEnum.SiloConical]: 'Silo conical',
    [ItxBinTypeEnum.SiloSloped]: 'Silo sloped',
    [ItxBinTypeEnum.FlatStorageLevel]: ' Flat storage level',
    [ItxBinTypeEnum.FlatStorageConical]: 'Flat storage conical',
    [ItxBinTypeEnum.FlatStorageSloped]: 'Flat storage sloped',
    [ItxBinTypeEnum.LiquidStorageFlat]: 'Liquid storage flat',
    [ItxBinTypeEnum.LiquidStorageConical]: 'Liquid storage conical',
    [ItxBinTypeEnum.LiquidStorageSloped]: 'Liquid storage sloped',
}

export const CapacityUnitLabel = {
    [CapacityUnitEnum.Ft_3]: 'ft³',
    [CapacityUnitEnum.M_3]: 'm³',
    [CapacityUnitEnum.Bsh]: 'bsh',
    [CapacityUnitEnum.L]: 'L',
    [CapacityUnitEnum.HL]: 'hL',
    [CapacityUnitEnum.KL]: 'kL',
    [CapacityUnitEnum.Gal]: 'gal',
    [CapacityUnitEnum.Bbl]: 'BBL'
}

export const CapacityUnitLiquidLabel = {
    [CapacityUnitEnum.L]: 'L',
    [CapacityUnitEnum.HL]: 'hL',
    [CapacityUnitEnum.KL]: 'kL',
    [CapacityUnitEnum.Gal]: 'gal',
    [CapacityUnitEnum.Bbl]: 'BBL'
}

export const CapacityUnitLoosLabel = {
    [CapacityUnitEnum.Ft_3]: 'ft³',
    [CapacityUnitEnum.M_3]: 'm³',
    [CapacityUnitEnum.Bsh]: 'bsh'
}

export const SizeMeasureLabel = {
    [SizeMeasureEnum.Ft]: 'ft',
    [SizeMeasureEnum.M]: 'm',
    [SizeMeasureEnum.In]: 'in'
}

export const TemperatureMeasureLabel = {
    [TemperatureMeasureEnum.Fahrenheit]: '°F',
    [TemperatureMeasureEnum.Celsius]: '°C',
}

export const SortParametersLabel = {
    [ItxStorageSortingEnum.Alphabetically]: "Alphabetically A-Z",
    [ItxStorageSortingEnum.AlphabeticallyDesc]: "Alphabetically Z-A",
    [ItxStorageSortingEnum.Percentage]: "From lowest percentage to highest",
    [ItxStorageSortingEnum.PercentageDesc]: "From highest percentage to lowest",
    [ItxStorageSortingEnum.Capacity]: "From lowest capacity to highest",
    [ItxStorageSortingEnum.CapacityDesc]: "From highest capacity to lowest" ,
    [ItxStorageSortingEnum.Material]: "By stored material"
}

export const StorageBinTypesIcons = {
    [ItxBinTypeEnum.BinFlat]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='157.827' height='165.002' viewBox='0 0 157.827 165.002'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23dce1eb%7D.cls-2%7Bfill:%23bec3d2%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1178' transform='translate(-181.033 -72.312)'%3E%3Cpath id='Subtraction_1' d='M-2153.174 1955.139H-2311V1828h157.827v127.138zm-144.275-113.588v82.1h132.319v-82.1z' class='cls-1' transform='translate(2492.033 -1717.826)'/%3E%3Cpath id='Path_798' d='M10688 1108.376h-40.652l55.8-33.08h6.775z' class='cls-2' transform='translate(-10462.725 -1002.984)'/%3E%3Cpath id='Path_797' d='M10712.707 1075.3h9.167v34.276h-28.7z' class='cls-1' transform='translate(-10462.725 -1002.984)'/%3E%3Cpath id='Path_796' d='M10725.461 1075.3v33.877h27.1l-19.529-33.877z' class='cls-1' transform='translate(-10462.725 -1002.984)'/%3E%3Cpath id='Path_801' d='M10735.823 1075.3h8.768l53.8 33.478h-41.449z' class='cls-2' transform='translate(-10462.725 -1002.984)'/%3E%3C/g%3E%3C/svg%3E%0A",
    [ItxBinTypeEnum.BinConical]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='157.827' height='164.843' viewBox='0 0 157.827 164.843'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23bec3d2%7D.cls-2%7Bfill:%23dce1eb%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1182' transform='translate(-181.275 -282.312)'%3E%3Cpath id='Path_798' d='M10688 1108.376h-40.652l55.8-33.08h6.775z' class='cls-1' transform='translate(-10462.725 -792.984)'/%3E%3Cpath id='Path_797' d='M10712.707 1075.3h9.167v34.276h-28.7z' class='cls-2' transform='translate(-10462.725 -792.984)'/%3E%3Cpath id='Path_796' d='M10725.461 1075.3v33.877h27.1l-19.529-33.877z' class='cls-2' transform='translate(-10462.725 -792.984)'/%3E%3Cpath id='Path_801' d='M10735.823 1075.3h8.768l53.8 33.478h-41.449z' class='cls-1' transform='translate(-10462.725 -792.984)'/%3E%3Cpath id='Subtraction_5' d='M-2153.174 1955.139H-2311V1828h157.827v127.138zM-2298.5 1841.5v72.282l47.148 28.963h37.046l47.823-28.963V1841.5z' class='cls-2' transform='translate(2492.275 -1507.984)'/%3E%3C/g%3E%3C/svg%3E%0A",
    [ItxBinTypeEnum.BinSloped]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='157.827' height='165.002'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23dce1eb%7D.cls-2%7Bfill:%23bec3d2%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1186' data-name='Group 1186' transform='translate(-181.033 -525.312)'%3E%3Cpath id='Subtraction_9' data-name='Subtraction 9' class='cls-1' d='M-2153.174 1955.139H-2311V1828h157.827v127.138zm-144.275-104.621v82.1l132.319-18.833v-63.269z' transform='translate(2492.033 -1264.826)'/%3E%3Cpath id='Path_798' data-name='Path 798' class='cls-2' d='M10688 1108.376h-40.652l55.8-33.08h6.775z' transform='translate(-10462.725 -549.984)'/%3E%3Cpath id='Path_797' data-name='Path 797' class='cls-1' d='M10712.707 1075.3h9.167v34.276h-28.7z' transform='translate(-10462.725 -549.984)'/%3E%3Cpath id='Path_796' data-name='Path 796' class='cls-1' d='M10725.461 1075.3v33.877h27.1l-19.529-33.877z' transform='translate(-10462.725 -549.984)'/%3E%3Cpath id='Path_801' data-name='Path 801' class='cls-2' d='M10735.823 1075.3h8.768l53.8 33.478h-41.449z' transform='translate(-10462.725 -549.984)'/%3E%3C/g%3E%3C/svg%3E%0A",
  
    [ItxBinTypeEnum.SiloFlat]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='99.638' height='165.451'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23dce1eb%7D.cls-2%7Bfill:%23bec3d2%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1179' data-name='Group 1179' transform='translate(-399.275 -74.051)'%3E%3Cpath id='Subtraction_2' data-name='Subtraction 2' class='cls-1' d='M-2211.362 1954.741H-2311V1828h99.638v126.74zM-2298 1840.754v83.3h74.529v-83.3z' transform='translate(2710.275 -1715.239)'/%3E%3Cpath id='Path_804' data-name='Path 804' class='cls-2' d='m10953.477 1075.2-36.3 33.975h26.7l14.154-33.975z' transform='translate(-10517.903 -1000.773)'/%3E%3Cpath id='Path_805' data-name='Path 805' class='cls-1' d='M10965.179 1109.583h-18.159l13.583-34.336h4.576z' transform='translate(-10517.903 -1001.196)'/%3E%3Cpath id='Path_806' data-name='Path 806' class='cls-1' d='M10967 1074.5h6.2l13.206 34.139H10967z' transform='translate(-10517.904 -1000.238)'/%3E%3Cpath id='Path_807' data-name='Path 807' class='cls-2' d='M10975.692 1075.47h4.47l36.059 34.127h-27.379z' transform='translate(-10517.903 -1001.196)'/%3E%3C/g%3E%3C/svg%3E%0A",
    [ItxBinTypeEnum.SiloConical]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='99.638' height='165.706'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23bec3d2%7D.cls-2%7Bfill:%23dce1eb%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1183' data-name='Group 1183' transform='translate(-399.275 -284.051)'%3E%3Cg id='Group_822' data-name='Group 822' transform='translate(-10462.903 -1656.984)'%3E%3Cpath id='Path_804' data-name='Path 804' class='cls-1' d='m10953.477 1075.2-36.3 33.975h26.7l14.154-33.975z' transform='translate(-55 866.211)'/%3E%3Cpath id='Path_805' data-name='Path 805' class='cls-2' d='M10965.179 1109.583h-18.159l13.583-34.336h4.576z' transform='translate(-55 865.789)'/%3E%3Cpath id='Path_806' data-name='Path 806' class='cls-2' d='M10967 1074.5h6.2l13.206 34.139H10967z' transform='translate(-55 866.746)'/%3E%3Cpath id='Path_807' data-name='Path 807' class='cls-1' d='M10975.692 1075.47h4.47l36.059 34.127h-27.379z' transform='translate(-55 865.789)'/%3E%3C/g%3E%3Cpath id='Subtraction_6' data-name='Subtraction 6' class='cls-2' d='M-2211.362 1954.741H-2311V1828h99.638v126.74zM-2298 1840v71.341l27.1 30.688h22.319l25.108-30.688V1840z' transform='translate(2710.275 -1504.984)'/%3E%3C/g%3E%3C/svg%3E%0A",
    [ItxBinTypeEnum.SiloSloped]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='99.638' height='165.451'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23dce1eb%7D.cls-2%7Bfill:%23bec3d2%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1187' data-name='Group 1187' transform='translate(-399.275 -527.051)'%3E%3Cpath id='Subtraction_10' data-name='Subtraction 10' class='cls-1' d='M-2211.362 1954.741H-2311V1828h99.638v126.74zM-2298 1840.754v83.3l74.529-10.79v-72.508z' transform='translate(2710.275 -1262.239)'/%3E%3Cpath id='Path_804' data-name='Path 804' class='cls-2' d='m10953.477 1075.2-36.3 33.975h26.7l14.154-33.975z' transform='translate(-10517.903 -547.773)'/%3E%3Cpath id='Path_805' data-name='Path 805' class='cls-1' d='M10965.179 1109.583h-18.159l13.583-34.336h4.576z' transform='translate(-10517.903 -548.196)'/%3E%3Cpath id='Path_806' data-name='Path 806' class='cls-1' d='M10967 1074.5h6.2l13.206 34.139H10967z' transform='translate(-10517.904 -547.238)'/%3E%3Cpath id='Path_807' data-name='Path 807' class='cls-2' d='M10975.692 1075.47h4.47l36.059 34.127h-27.379z' transform='translate(-10517.903 -548.196)'/%3E%3C/g%3E%3C/svg%3E%0A",
  
    [ItxBinTypeEnum.FlatStorageLevel]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='221.691' height='166.556'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23bec3d2%7D.cls-2%7Bfill:%23dce1eb%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1181' data-name='Group 1181' transform='translate(-701.916 -70.253)'%3E%3Cpath id='Path_810' data-name='Path 810' class='cls-1' d='M11465.688 1075.159h10.377l-32.545 35.139h-60.845z' transform='translate(-10680.759 -1004.906)'/%3E%3Cpath id='Path_811' data-name='Path 811' class='cls-2' d='M11479.6 1075.159h12.264v34.667h-40.327z' transform='translate(-10680.759 -1004.906)'/%3E%3Cpath id='Path_812' data-name='Path 812' class='cls-2' d='M11497.286 1075.159h10.849l29.715 33.724h-40.563z' transform='translate(-10680.759 -1004.906)'/%3E%3Cpath id='Path_813' data-name='Path 813' class='cls-1' d='M11513.323 1075.159h9.669l80.891 33.724h-60.845z' transform='translate(-10680.759 -1004.906)'/%3E%3Cpath id='Subtraction_4' data-name='Subtraction 4' class='cls-2' d='M-2089.31 1952.35H-2311V1828h221.691v124.349zm-204.061-110v81.7h186.125v-81.7z' transform='translate(3012.916 -1715.542)'/%3E%3C/g%3E%3C/svg%3E%0A",
    [ItxBinTypeEnum.FlatStorageConical]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='222.05' height='166.113'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23bec3d2%7D.cls-2%7Bfill:%23dce1eb%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1185' data-name='Group 1185' transform='translate(-701.916 -280.253)'%3E%3Cg id='Group_824' data-name='Group 824' transform='translate(-10462.725 -1656.984)'%3E%3Cpath id='Path_810' data-name='Path 810' class='cls-1' d='M11465.688 1075.159h10.377l-32.545 35.139h-60.845z' transform='translate(-218.034 862.078)'/%3E%3Cpath id='Path_811' data-name='Path 811' class='cls-2' d='M11479.6 1075.159h12.264v34.667h-40.327z' transform='translate(-218.034 862.078)'/%3E%3Cpath id='Path_812' data-name='Path 812' class='cls-2' d='M11497.286 1075.159h10.849l29.715 33.724h-40.563z' transform='translate(-218.034 862.078)'/%3E%3Cpath id='Path_813' data-name='Path 813' class='cls-1' d='M11513.323 1075.159h9.669l80.891 33.724h-60.845z' transform='translate(-218.034 862.078)'/%3E%3C/g%3E%3Cpath id='Subtraction_8' data-name='Subtraction 8' class='cls-2' d='M-2089.31 1952.35H-2311V1828h221.691v124.349zM-2294 1845v68.392l47.667 22.279h83.936l54.92-19.17V1845z' transform='translate(3013.275 -1505.984)'/%3E%3C/g%3E%3C/svg%3E%0A",
    [ItxBinTypeEnum.FlatStorageSloped]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='221.691' height='166.556'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23bec3d2%7D.cls-2%7Bfill:%23dce1eb%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1189' data-name='Group 1189' transform='translate(-701.916 -523.253)'%3E%3Cpath id='Path_810' data-name='Path 810' class='cls-1' d='M11465.688 1075.159h10.377l-32.545 35.139h-60.845z' transform='translate(-10680.759 -551.906)'/%3E%3Cpath id='Path_811' data-name='Path 811' class='cls-2' d='M11479.6 1075.159h12.264v34.667h-40.327z' transform='translate(-10680.759 -551.906)'/%3E%3Cpath id='Path_812' data-name='Path 812' class='cls-2' d='M11497.286 1075.159h10.849l29.715 33.724h-40.563z' transform='translate(-10680.759 -551.906)'/%3E%3Cpath id='Path_813' data-name='Path 813' class='cls-1' d='M11513.323 1075.159h9.669l80.891 33.724h-60.845z' transform='translate(-10680.759 -551.906)'/%3E%3Cpath id='Subtraction_12' data-name='Subtraction 12' class='cls-2' d='M-2089.31 1952.35H-2311V1828h221.691v124.349zm-204.061-110v81.7l186.125-25.663v-56.042z' transform='translate(3012.916 -1262.542)'/%3E%3C/g%3E%3C/svg%3E%0A",
  
    [ItxBinTypeEnum.LiquidStorageFlat]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='99.042' height='165.697'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23bec3d2%7D.cls-2%7Bfill:%23dce1eb%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1180' data-name='Group 1180' transform='translate(-554.276 -74.051)'%3E%3Cpath id='Path_804' data-name='Path 804' class='cls-1' d='m10953.477 1075.2-36.3 33.975h26.7l14.154-33.975z' transform='translate(-10362.902 -1000.773)'/%3E%3Cpath id='Path_805' data-name='Path 805' class='cls-2' d='M10965.179 1109.583h-18.159l13.583-34.336h4.576z' transform='translate(-10362.902 -1001.196)'/%3E%3Cpath id='Path_806' data-name='Path 806' class='cls-2' d='M10967 1074.5h6.2l13.206 34.139H10967z' transform='translate(-10362.902 -1000.238)'/%3E%3Cpath id='Path_807' data-name='Path 807' class='cls-1' d='M10975.692 1075.47h4.47l36.059 34.127h-27.379z' transform='translate(-10362.902 -1001.196)'/%3E%3Cpath id='Subtraction_3' data-name='Subtraction 3' class='cls-2' d='M-2222.4 1955.232h-7.665v-30.045h-74.5v30.045H-2311V1828h88.6v127.231zm-75.6-113.742v63.769h61.623v-63.769z' transform='translate(2871.274 -1715.484)'/%3E%3C/g%3E%3C/svg%3E%0A",
    [ItxBinTypeEnum.LiquidStorageConical]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='99.042' height='166.197'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23bec3d2%7D.cls-2%7Bfill:%23dce1eb%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1184' data-name='Group 1184' transform='translate(-554.276 -284.051)'%3E%3Cg id='Group_823' data-name='Group 823' transform='translate(-10307.902 -1656.984)'%3E%3Cpath id='Path_804' data-name='Path 804' class='cls-1' d='m10953.477 1075.2-36.3 33.975h26.7l14.154-33.975z' transform='translate(-55 866.211)'/%3E%3Cpath id='Path_805' data-name='Path 805' class='cls-2' d='M10965.179 1109.583h-18.159l13.583-34.336h4.576z' transform='translate(-55 865.789)'/%3E%3Cpath id='Path_806' data-name='Path 806' class='cls-2' d='M10967 1074.5h6.2l13.206 34.139H10967z' transform='translate(-55 866.746)'/%3E%3Cpath id='Path_807' data-name='Path 807' class='cls-1' d='M10975.692 1075.47h4.47l36.059 34.127h-27.379z' transform='translate(-55 865.789)'/%3E%3C/g%3E%3Cpath id='Subtraction_7' data-name='Subtraction 7' class='cls-2' d='M-2222.4 1955.232h-7.665v-30.045h-74.5v30.045H-2311V1828h88.6v127.231zm-75.1-114.732v50.257l20.725 20.207h21.243l18.652-20.207V1840.5z' transform='translate(2871.274 -1504.984)'/%3E%3C/g%3E%3C/svg%3E%0A",
    [ItxBinTypeEnum.LiquidStorageSloped]: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='99.042' height='165.697'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:%23bec3d2%7D.cls-2%7Bfill:%23dce1eb%7D %3C/style%3E%3C/defs%3E%3Cg id='Group_1188' data-name='Group 1188' transform='translate(-554.276 -527.051)'%3E%3Cpath id='Path_804' data-name='Path 804' class='cls-1' d='m10953.477 1075.2-36.3 33.975h26.7l14.154-33.975z' transform='translate(-10362.902 -547.773)'/%3E%3Cpath id='Path_805' data-name='Path 805' class='cls-2' d='M10965.179 1109.583h-18.159l13.583-34.336h4.576z' transform='translate(-10362.902 -548.196)'/%3E%3Cpath id='Path_806' data-name='Path 806' class='cls-2' d='M10967 1074.5h6.2l13.206 34.139H10967z' transform='translate(-10362.902 -547.238)'/%3E%3Cpath id='Path_807' data-name='Path 807' class='cls-1' d='M10975.692 1075.47h4.47l36.059 34.127h-27.379z' transform='translate(-10362.902 -548.196)'/%3E%3Cpath id='Subtraction_11' data-name='Subtraction 11' class='cls-2' d='M-2222.4 1955.232h-7.665v-30.045h-74.5v30.045H-2311V1828h88.6v127.231zm-75.6-113.742v63.769l61.623-9.366v-54.4z' transform='translate(2871.274 -1262.484)'/%3E%3C/g%3E%3C/svg%3E%0A",
  }

export const LiquidStorageTypes = [ItxBinTypeEnum.LiquidStorageFlat, ItxBinTypeEnum.LiquidStorageConical, ItxBinTypeEnum.LiquidStorageSloped];
export const FlatStorageTypes = [ItxBinTypeEnum.FlatStorageLevel, ItxBinTypeEnum.FlatStorageConical, ItxBinTypeEnum.FlatStorageSloped];
export const LiquidCapacityUnit = [CapacityUnitEnum.L, CapacityUnitEnum.HL, CapacityUnitEnum.KL, CapacityUnitEnum.Gal, CapacityUnitEnum.Bbl];
