import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorModel, HttpService } from 'src/app/common';
import { ApiRoutes } from '../../constants';
import { LocationItxGetModel,
       LocationItxViewModel,
       LocationItxPostModel,
       FullnessRangesGetModel,
       FullnessRangePostModel,
       ItxLocationNameModel,
       } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class LocationsItxApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router) { }

    public async getAll() : Promise<LocationItxViewModel[] | ErrorModel> {
      try {
        if (this._router.url.indexOf('/back-door') > -1) {
          return this.getFakeData();
        }
        
        const result = await this._httpService.get(ApiRoutes.LocationItx.getAll);
        return result.map((item) => {
          return LocationItxGetModel.toViewModel(item);
        });
      } catch (error) {
        return this.processError(error);
      }
    }
  public async getItxLocationForCompany(companyId: number): Promise<LocationItxViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const result = await this._httpService.get(ApiRoutes.LocationItx.getLocationsWithBinsForCompany, { companyId: companyId });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }
  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }
  }

  public async edit(location: LocationItxPostModel) : Promise<LocationItxViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.LocationItx.edit, location);

      const locationViewModel = LocationItxGetModel.toViewModel(result);
      return locationViewModel;
    } catch (error) {
      return this.processError(error);
    }
  }  

  public async add(location: LocationItxPostModel) : Promise<LocationItxViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.LocationItx.add, location);

      const locationViewModel = LocationItxGetModel.toViewModel(result);
      return locationViewModel;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async delete(locationId: number) : Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.delete(ApiRoutes.LocationItx.delete, { locationId});
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getFullnessRanges(locationId: number): Promise<FullnessRangesGetModel | ErrorModel>{
    try {
      if(this._router.url.indexOf('/back-door') > -1) {
        return this.generateFakeFullnessRange(locationId);
      }
      const result = await this._httpService.get(ApiRoutes.LocationItx.getFullnessRanges, { locationId }) as FullnessRangesGetModel;
      return result;
    } catch(error) {
      return this.processError(error);
    }
  }

  public async initializeDeviceConfiguration(locationId: number) : Promise<boolean | ErrorModel> {
    try {
      let response = await this._httpService.post(ApiRoutes.LocationItx.initializeDeviceConfiguration, locationId);

      return response;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadConnectionFile(locationId: number) : Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.getArrayBuffer(ApiRoutes.LocationItx.downloadConnectionFile, { locationId });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  generateFakeFullnessRange(locationId: number): FullnessRangesGetModel{
    const fullnessRangeViewModel = new FullnessRangesGetModel();
    fullnessRangeViewModel.locationId = locationId;
    fullnessRangeViewModel.isInitialized = true;
    fullnessRangeViewModel.canDeviceBeInitialized = false;
    fullnessRangeViewModel.fullnessRanges = [
      { id: 1, from: 0, to: 40, colorHex: "#71c5a3" },
      { id: 2, from: 41, to: 69, colorHex: "#8bc9ff" },
      { id: 3, from: 70, to: 80, colorHex: "#f4bd56" },
      { id: 4, from: 81, to: 90, colorHex: "#8f65c7" },
      { id: 5, from: 91, to: 100,colorHex: "#d95656" }
    ]
    return fullnessRangeViewModel;
  }
  public getFakeData(): LocationItxViewModel[] {
    let data=[
      {
        acquisitionRate: 1, companyName: "Company1", countOfBins: 12, isInitialized: true, locationName: "Location1", id: 1,  deviceId: "8080", modelType: "", port: 1,
        ipAddress: "asd", itxBins:[{id:1,name:'Fake1'},{id:2,name:'Fake2'},{id:3,name:'Fake3'},{id:4,name:'Fake4'}],fullnessRanges:[], isSomeoneInViewStorages:false
      },
      {
        acquisitionRate: 2, companyName: "Company2", countOfBins: 12, isInitialized: true, locationName: "Location2", id: 2, deviceId: "8080", modelType: "", port: 1,
        ipAddress: "asd", itxBins:[{id:1,name:'Fake1'},{id:2,name:'Fake2'},{id:3,name:'Fake3'},{id:4,name:'Fake4'}],fullnessRanges:[], isSomeoneInViewStorages:false,
      },
      {
        acquisitionRate: 15, companyName: "Company3", countOfBins: 5, isInitialized: true, locationName: "Location3", id: 3, deviceId: "8080", modelType: "", port: 1,
        ipAddress: "asd", itxBins:[{id:1,name:'Fake1'},{id:2,name:'Fake2'},{id:3,name:'Fake3'},{id:4,name:'Fake4'}],fullnessRanges:[], isSomeoneInViewStorages:false,
      },
      {
        acquisitionRate: 6, companyName: "Company4", countOfBins: 9, isInitialized: true, locationName: "Location4", id: 4, deviceId: "8080", modelType: "", port: 1,
        ipAddress: "asd", itxBins:[{id:1,name:'Fake1'},{id:2,name:'Fake2'},{id:3,name:'Fake3'},{id:4,name:'Fake4'}],fullnessRanges:[], isSomeoneInViewStorages:false,
      },
    ]as  LocationItxViewModel[];
    return data;
  }

  public async editFullnessRange(fullnessEditModel: FullnessRangePostModel) : Promise<FullnessRangesGetModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.LocationItx.editFullnessRange, fullnessEditModel);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getListOfAssignedItxLocationsWithItxBins(): Promise<ItxLocationNameModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeItxLocationData();
      }
      const result = await this._httpService.get(ApiRoutes.LocationItx.getListOfAssignedItxLocationsWithItxBins) as ItxLocationNameModel[];
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private getFakeItxLocationData(){
    let data=[
      {
        name: "Location1", id: 1, bins:[{id:1,name:'Fake1'},{id:2,name:'Fake2'},{id:3,name:'Fake3'},{id:4,name:'Fake4'}]
      },
      {
        name: "Location2", id: 2, bins:[{id:5,name:'Fake1'},{id:6,name:'Fake2'},{id:7,name:'Fake3'},{id:8,name:'Fake4'}]
      },
      {
        name: "Location3", id: 3, bins:[]
      },
      {
        name: "Location4", id: 4, bins:[{id:9,name:'Fake1'},{id:10,name:'Fake2'},{id:11,name:'Fake3'},{id:12,name:'Fake4'}]
      },
    ]as  ItxLocationNameModel[];
    return data;
  }
}
