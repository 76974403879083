import { ProjectEnum } from "../enums";

export class NotificationReportPostModel {
    public locationIds: number[];
    public equipmentIds: number[];
    public eventTypes: number[];
    public dateTimeFromSeconds: number;
    public dateTimeToSeconds: number;
    public project: ProjectEnum;
    public timeZone: string;
}