import { Component, OnDestroy, OnInit } from '@angular/core';
import { CancelUpdateFirmwarePostModel, LocationUpdaterViewModel, ProjectFirmwareUpdateViewModel, UpdateFirmwarePostModel, UpdateFirmwareResultModel, UpdateFirmwaresDevicePostModel, UpdateFirmwaresPostModel } from '@models';
import { UpdaterDeviceDatasourceService } from '@services';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { ErrorModel } from '../../../../common';
import { FirmwareFileFolder } from '../../../constants';
import { ColumnTypeEnum, ProjectEnum, UpdateProjectStateEnum } from '../../../enums';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-update-firmware-popup',
  templateUrl: './update-firmware-popup.component.html',
  styles: [],
  providers: [MessageService]
})
export class UpdateFirmwarePopupComponent extends BasePopupBehavior implements OnInit, OnDestroy {
  static CREATING_DATE_KEY = 'locationName';
  static SORT_ORDER_DEFAULT = 1;

  _sortOrder = UpdateFirmwarePopupComponent.SORT_ORDER_DEFAULT;
  _sortField = UpdateFirmwarePopupComponent.CREATING_DATE_KEY;

  _isHidden = true;
  _loading = false;
  _selectedProject: ProjectEnum = null;
  _dropdownOptions: { label: string, value: ProjectEnum }[] = [
    { label: 'Btx', value: ProjectEnum.Btx },
    { label: 'Ktx', value: ProjectEnum.Ktx },
    { label: 'Itx', value: ProjectEnum.Itx }];
  _dropdownFirmwareOptions: { label: string, value: string }[];
  _selectFirmware: string = null;
  _gridData: ProjectFirmwareUpdateViewModel[] = [];
  _columnTypeEnum = ColumnTypeEnum;
  _selectedUpdaters: ProjectFirmwareUpdateViewModel[] = [];
  _uppateState = UpdateProjectStateEnum;
  _columnsView = [
    { header: 'Selected', columnType: ColumnTypeEnum.QuoteSelected, dataField: 'isSelected', width: 100 },
    { header: 'Location name', columnType: ColumnTypeEnum.LocationName, dataField: 'locationName', width: 200 },
    { header: 'Version', columnType: ColumnTypeEnum.CurrentVersion, dataField: 'currentVersion', width: 100 },
    { header: 'Last Updated', columnType: ColumnTypeEnum.Date, dataField: 'lastUpdated', width: 150 },
    { header: '', columnType: ColumnTypeEnum.DownloadConnectionFile, width: 122 },
    { header: '', columnType: ColumnTypeEnum.Status, width: 40 },
  ];

  private subscriptions: Subscription[] = [];
  private locations: LocationUpdaterViewModel[] = [];
  constructor(
    private _updaterDeviceDatasourceService: UpdaterDeviceDatasourceService,
    private _messageService: MessageService
  ) { super(); }

  ngOnInit() {
    this._selectedProject = this._dropdownOptions[0].value;
    let fileSub = this._updaterDeviceDatasourceService.firmwares$.subscribe(items => {
      this._dropdownFirmwareOptions = [];
      if (items) {
        let data = items.sort((n1, n2) => {
          if (n1.lastModified > n2.lastModified) {
            return 1;
          }

          if (n1.lastModified < n2.lastModified) {
            return -1;
          }

          return 0;
        });
        if (data.length > 0) {
          data.forEach(x => {
            this._dropdownFirmwareOptions.push({ label: x.name, value: x.name });
          });
        }
        else{
          this._dropdownFirmwareOptions.push({ label: 'There are no firmwares', value: null });
        }
      }
    });

    let locationSub = this._updaterDeviceDatasourceService.locationsUpdaters$.subscribe(item => {
      this.locations = item;
      this.fillGridData();
    });
    this.subscriptions.push(fileSub);
    this.subscriptions.push(locationSub);
  }

  show() {
    this._selectedProject = this._dropdownOptions[0].value;
    this._isHidden = false;
    this._loading = true;
    this._updaterDeviceDatasourceService.getBlobFirmwareFiles(FirmwareFileFolder[this._selectedProject]);
    this.fillGridData();
  }

  async onClose() {
    this._isHidden = true;
    this._selectedProject = null;
    this._loading = false;
    this._selectFirmware = null;
    this._selectedUpdaters = [];
  }

  onProjectChange() {
    this._loading = true;
    this._selectFirmware = null;
    this._selectedUpdaters = [];
    this._dropdownFirmwareOptions = [];
    this._updaterDeviceDatasourceService.getBlobFirmwareFiles(FirmwareFileFolder[this._selectedProject]);
    this.fillGridData();
  }

  async onBulkUpdateDevicesFirmware() {
    this._selectedUpdaters.forEach(x => {
      x.state = this._uppateState.Updating;
      x.sendCommand = true;
    });
    const request = {
      project: this._selectedProject,
      fileName: this._selectFirmware,
      fileFolder: FirmwareFileFolder[this._selectedProject],
      devices: this._selectedUpdaters.map(x => {
        return { locationId: x.locationId, updaterDeviceId: x.updaterDeviceId, projectDeviceId: x.projectDeviceId };
      }) as UpdateFirmwaresDevicePostModel[],
    } as UpdateFirmwaresPostModel;

    const result = await this._updaterDeviceDatasourceService.updateDeviceFirmwares(request);
    this.UpdateErrors(result);
  }

  async onUpdateDeviceFirmware(updater: ProjectFirmwareUpdateViewModel) {
    updater.sendCommand = true;
    if (updater.state != this._uppateState.Updating && updater.state != this._uppateState.Starting) {
      updater.state = this._uppateState.Updating;
      const updateFirmwarePostModel = {
        project: this._selectedProject,
        fileName: this._selectFirmware,
        fileFolder: FirmwareFileFolder[this._selectedProject],
        updaterDeviceId: updater.updaterDeviceId,
        locationId: updater.locationId,
        projectDeviceId: updater.projectDeviceId,
      } as UpdateFirmwarePostModel;
      const result = await this._updaterDeviceDatasourceService.updateDeviceFirmware(updateFirmwarePostModel);
      this.UpdateError(result, updater);
    }
    else {
      const cancelUpdate = {
        locationId: updater.locationId,
        projectDeviceId: updater.projectDeviceId,
        updaterDeviceId: updater.updaterDeviceId,
      } as CancelUpdateFirmwarePostModel;
      const result = await this._updaterDeviceDatasourceService.cancelUpdateDeviceFirmware(cancelUpdate);
      this.UpdateError(result, updater);
    }
  }

  onSelectUpdater(updater: ProjectFirmwareUpdateViewModel) {
    if (this._selectedUpdaters.find(x => x.locationId == updater.locationId)) {
      this._selectedUpdaters = this._selectedUpdaters.filter(x => x.locationId != updater.locationId);
    }
    else {
      this._selectedUpdaters.push(updater);
    }
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.forEach(x => x.unsubscribe());
  }

  isUpdatingState(data: ProjectFirmwareUpdateViewModel): boolean {
    return data.state == this._uppateState.Updating || data.state == this._uppateState.Starting;
  }

  private fillGridData() {
    this._gridData = [];
    this.locations.forEach(x => {
      let updater = x.updaterDevices.find(u => u.isInitialized && (u.projectsAvailable & this._selectedProject) === this._selectedProject);
      if (updater) {
        let data = new ProjectFirmwareUpdateViewModel();
        data.locationId = x.id;
        data.locationName = x.name;
        data.project = ProjectEnum[this._selectedProject];
        data.updaterDeviceId = updater.id;
        let projectDevice = updater.projectDevices.find(d => d.project == this._selectedProject);
        data.currentVersion = projectDevice.currentVersion;
        data.lastUpdated = projectDevice.lastUpdate == null ? null : projectDevice.lastUpdate * 1000;
        data.state = projectDevice.state;
        data.errorMessage = projectDevice.errorMessage;
        data.projectDeviceId = projectDevice.deviceId;
        this._gridData.push(data);
      }
    })
    this._loading = false;
  }

  private UpdateErrors(result) {
    if (result instanceof ErrorModel) {
      this._messageService.add({ severity: 'error', summary: 'Error!', detail: result.message });
    }
    (result as UpdateFirmwareResultModel[]).forEach(item => {
      if (!item.payLoadSuccess) {
        var selectLocation = this._selectedUpdaters.find(x => x.locationId = item.locationId);
        this.UpdateError(item, selectLocation);
      }
    });
    this._gridData.forEach(x => x.isSelected = false);
    this._selectedUpdaters = [];
  }

  private UpdateError(result, updater: ProjectFirmwareUpdateViewModel) {
    if (result instanceof ErrorModel) {
      this._messageService.add({ severity: 'error', summary: 'Error!', detail: result.message });
      updater.state = this._uppateState.Failed;
    }
    if (!result.payLoadSuccess) {
      let location = this._updaterDeviceDatasourceService.locationsUpdaters$.getValue().find(x => x.id == updater.locationId);
      this._messageService.add({ severity: 'error', summary: `${location.name} Firmware update error:`, detail: `${result.payLoadMessage}`, life: 10000 });
    }
  }
}
