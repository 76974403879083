import { ItxLocationNameModel } from "@models";
import { UserStatusEnum } from "../enums";

export class VendorViewModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public itxLocations: Array<ItxLocationNameModel>;
    public itxLocationsNames: string[];
    public email: string;
    public status: UserStatusEnum;
    public lastLoginDate: Date;
    public countOfBins: number;
    public companyName: string;

    //User behavior lock fields
    public isSomeoneUpdateVendor: boolean;
}

export class VendorGetModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public itxLocations: Array<ItxLocationNameModel>;
    public email: string;
    public status: UserStatusEnum;
    public lastLoginDate: Date;
    public companyName: string;

    public static toViewModel(getModel: VendorGetModel): VendorViewModel {
        const viewModel = new VendorViewModel();
        viewModel.id = getModel.id;
        viewModel.firstName = getModel.firstName;
        viewModel.lastName = getModel.lastName;
        viewModel.itxLocations = getModel.itxLocations;
        viewModel.email = getModel.email;
        viewModel.status = getModel.status;
        viewModel.lastLoginDate = getModel.lastLoginDate;
        viewModel.companyName = getModel.companyName;
        viewModel.countOfBins = 0;
        viewModel.itxLocations.forEach(i => { viewModel.countOfBins += i.bins.length });
        viewModel.itxLocationsNames = getModel.itxLocations.map(l => l.name);

        return viewModel;
    }
}

export class VendorInviteModel {
    email: string;
    itxStorageIds: Array<number>;
}

export class VendorEditModel {
    id: number;
    status: UserStatusEnum;
    itxStorageIds: Array<number>;

    constructor() {
        this.itxStorageIds = [];
    }
}