export enum ErrorCodeEnum {
    InternalError = -1,
    Base = 1000,
    Security = 2000,
    NotFound = 3000,
    LocationNotFound=3003,
    ItxStorageNotFound=3015,
    ItxRadarAlreadyExixtsInThisLocation = 3017,
    BadRequest = 4000,
    InvalidModelState = 5000,
    InvalidUserEmailOrPassword = 5001,
    InvalidToken=5002,
    PermissionError = 5003,
    UserIsLocked=5004,
    NotUniqueLocationName = 6000,
    NotUniqueCompanyName = 6003,
    ArduinoDeviceAlreadyAssigned = 6004,
    UserAlreadyExist = 8000,
    RaspberryDeviceAlreadyAssigned = 6005,
    ItxDeviceAlreadyAssigned = 6006,
    PasswordAndConfirmPasswordShouldMatch = 9000,
    AbsentXmlElement = 9001,
    AbsentXmlAttribute = 9002,
    WrongFormatOfValue = 9003,
    WrongFormatOfConfigFile = 9004,
    QuoteNotFound = 1002,
}
