import { AqsCoverSheetTypeEnum } from "../../enums";

export class AqsCoverSheetModel {
  id: number;
  type: AqsCoverSheetTypeEnum;
  numberTypical: number;
  customerName: string;
  companyName: string;
  city: string;
  contact: string;
  quoteNumber: string;
  locationName: string;
  locationCity: string;
  comment: string;

  constructor() {
    this.id = 0;
    this.numberTypical = 1;
  }
}
