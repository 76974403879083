
import { Component, EventEmitter, OnInit } from '@angular/core';

import { FullnessRangeViewModel, TemperatureRangeViewModel } from '../../../../models';
import { BasePopupBehavior } from '../../common';

@Component({
  selector: 'greensleeves-itx-range-delete-popup',
  templateUrl: './itx-range-delete-popup.component.html'
})
export class ItxRangeDeletePopupComponent extends BasePopupBehavior implements OnInit {
  _isHidden = true;
  range: TemperatureRangeViewModel;
  onSubmit$ = new EventEmitter<TemperatureRangeViewModel>();

  constructor() { 
    super();
  }
  ngOnInit() {
  }

  onCancel() {
    this._isHidden = true;
    this.range = null;
  }

  onSubmit() {
    this.onSubmit$.emit(this.range);
    this._isHidden = true;
  }

  show(range: FullnessRangeViewModel) {
    this._isHidden = false;
    this.range = range;
  }
}
