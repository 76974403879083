import {
  Directive,
  HostListener,
  Host
} from '@angular/core';
import { CustomKeyboardEvent, MaskDirective } from 'ngx-mask';

@Directive({ selector: 'input[mask$="IP"], textarea[mask]' })
export class IpMaskFixDirective {
  constructor(
    @Host() private _maskDirective: MaskDirective
  ) { }

  @HostListener('input', ['$event'])
  onInput(e: CustomKeyboardEvent) {
    const el: HTMLInputElement = e.target as HTMLInputElement;
    const value = el.value;
    if (value) {
      let digits: number = 0;
      for (let i = 0; i < value.length; i++) {
        if (value[i].match('\\d')) {
          digits++;
        }
      }
  
      if (!digits) {
        el.value = '';
      }
    }
    
    this._maskDirective.onInput(e);
  }
}