import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { AuthorizeService } from '../common';
import { ErrorModel, LoginViewModel, ErrorCodeEnum } from '../common';
import { emailValidator } from '../common/validators';
import { RoutePaths } from '../common';

@Component({
  selector: 'greensleeves-sign-in',
  templateUrl: './sign-in.component.html'
})

export class SignInComponent implements OnInit {
  _submitted = false;
  _invalidEmailOrPassword = false;
  _permissionError = false;
  _userLockedError = false;
  _isUserEmailFieldActivated = false;
  _isUserPasswordFieldActivated = false;

  _showPassword: boolean;
  _loginForm: FormGroup;
  _privacyLink = RoutePaths.PRIVACY;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthorizeService
  ) {
    this._authService.checkIfAuthorizedAndNavigate();
  }

  ngOnInit() {
    this._loginForm = this._formBuilder.group({
      userEmail: [
        '',
        Validators.compose([
          Validators.required,
          emailValidator({ message: "Incorrect Email format!", maxDomainLength: -1, maxNameLength: -1 }),
          Validators.minLength(3),
          Validators.maxLength(50)
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(50)
        ])
      ]
    });

    this._loginForm.valueChanges.subscribe(() => this._invalidEmailOrPassword = false);
    this._loginForm.valueChanges.subscribe(() => this._permissionError = false);
    this._loginForm.valueChanges.subscribe(() => this._userLockedError = false);
  }

  get f() { return this._loginForm.controls; }

  onSubmit() {
    this._submitted = true;

    const userEmail = this.f.userEmail.value.trim();
    const password = this.f.password.value.trim();

    if (this._loginForm.invalid && (!this._invalidEmailOrPassword || !this._permissionError)) {
      return;
    }
    this._invalidEmailOrPassword = false;
    this._permissionError = false;

    this._authService.login(userEmail, password)
      .then((response: LoginViewModel) => {
        if (response instanceof ErrorModel) {
          if (response.code === ErrorCodeEnum.InvalidUserEmailOrPassword) {
            this._invalidEmailOrPassword = true;
          } else if (response.code === ErrorCodeEnum.PermissionError) {
            this._permissionError = true;
          } else if (response.code === ErrorCodeEnum.UserIsLocked) {
            this._userLockedError = true;
          }
        } else if (response) {
          this.resetForm();
        }
      });
  }

  onShowHidePassword() {
    this._showPassword = !this._showPassword;
  }

  private resetForm() {
    this._submitted = false;
    this._loginForm.reset();
    this._loginForm.markAsUntouched();
    Object.keys(this._loginForm.controls).forEach((name) => {
      const control = this._loginForm.controls[name];
      control.setErrors(null);
    });
  }
}
