import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { LocalStorageService, UserRoleEnum } from '../../../common';
import { ColumnTypeEnum, UserStatusEnum } from '../../enums';
import { ServiceUserAddPopupComponent, ServiceUserDeletePopupComponent, ServiceUserEditPopupComponent } from '../popups';
import { SalesUserViewModel } from './../../models';
import { SalesUsersDatasourceService } from './../../services';

@Component({
  selector: 'greensleeves-sales-users-tab',
  templateUrl: './sales-users-tab.component.html',
  styles: []
})
export class SalesUsersTabComponent implements OnInit, OnDestroy {
  @ViewChild(ServiceUserEditPopupComponent, { read: false, static: false })
  private editModal: ServiceUserEditPopupComponent;

  @ViewChild(ServiceUserAddPopupComponent, { read: false, static: false })
  private addModal: ServiceUserAddPopupComponent;

  @ViewChild(ServiceUserDeletePopupComponent, { read: false, static: false })
  private deleteModal: ServiceUserDeletePopupComponent;

  _role: UserRoleEnum;
  _gridData: SalesUserViewModel[];
  _columnTypeEnum = ColumnTypeEnum;
  _userStatus = UserStatusEnum;

  private salesUsersSubscription: Subscription;

  get _columns() {
    return [
      { header: 'First name', columnType: ColumnTypeEnum.UserFirstName, dataField: 'firstName' },
      { header: 'Last name', columnType: ColumnTypeEnum.UserLastName, dataField: 'lastName' },
      { header: 'Email', dataField: 'email' },
      { header: 'Last login date', columnType: ColumnTypeEnum.Date, dataField: 'lastLoginDate' },
      { header: 'Status', columnType: ColumnTypeEnum.Status, dataField: 'status', width: "80" },
      { header: '', columnType: ColumnTypeEnum.Edit, width: "46" },
      { header: '', columnType: ColumnTypeEnum.Delete, dataField: 'id', width: "46" }
    ];
  }

  constructor(
    private _salesUserDatasouceService: SalesUsersDatasourceService,
    private _localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;

    this.salesUsersSubscription = this._salesUserDatasouceService.salesUsers$.subscribe(data => this._gridData = data);
    this._salesUserDatasouceService.getAll();
  }

  ngOnDestroy() {
    this.salesUsersSubscription && this.salesUsersSubscription.unsubscribe();
    this.salesUsersSubscription = null;
  }

  onClickEdit(salesUser: SalesUserViewModel) {
    this.editModal.showSalesUserEdit(salesUser);
  }

  onClickAdd() {
    this.addModal.showForSalesUser();
  }

  onClickDelete(salesUser: SalesUserViewModel) {
    this.deleteModal.showSalesUser(salesUser.id);
  }

}
