import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'greensleeves-upload-equipment-sensor-delete-popup',
  templateUrl: './upload-equipment-sensor-delete-popup.component.html',
  styles: []
})

export class UploadEquipmentSensorDeletePopupComponent implements OnInit {
  @Output('sensorDelete')
  sensorDelete: EventEmitter<number> = new EventEmitter<number>();

  @Output('equipmentDelete')
  equipmentDelete: EventEmitter<number> = new EventEmitter<number>();

  _isHidden = true;
  _isSubmitted: boolean;
  _isSensorDeletePopup: boolean;
  _sensorId: number;
  _equipmentIndex: number;
  constructor() { }

  ngOnInit() {
    this._isSubmitted = false;
    this._isHidden = true;
  }  
  
  public showSensorView(sensorId: number){
    this._sensorId = sensorId;
    this._isSubmitted = false;
    this._isSensorDeletePopup = true;
     this._isHidden = false;
  }

  public showEquipmentView(equipmentIndex: number){
    this._isSubmitted = false;
    this._equipmentIndex = equipmentIndex;
    this._isSensorDeletePopup = false;
    this._isHidden = false;
 }

  async onSubmit() {
    this._isSubmitted = true;
    if (this._isSensorDeletePopup){
      this.sensorDelete.emit(this._sensorId);
    } else {
      this.equipmentDelete.emit(this._equipmentIndex);
    }

    this._isHidden = true;
  }

  onCancel(){
    this._isHidden = true;
    this._isSubmitted = false;
  }
}