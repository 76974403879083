import { Component, ViewChild } from '@angular/core';

import { AuthorizeService, LocalStorageService, UserRoleEnum } from '../../../common';
import { UserProfilePopupComponent } from './../popups';
import { LocationsBtxDataSourceService, LocationsItxDatasourceService, LocationsKtxDataSourceService, RoleService } from '@services';
import { Router } from '@angular/router';
import { BACK_DOOR } from '../../constants';

@Component({
  selector: 'greensleeves-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  _role: UserRoleEnum;
  _roleString: string;
  _roleLetter: string;

  @ViewChild(UserProfilePopupComponent, { read: false, static: false })
  private profilePopup: UserProfilePopupComponent;

  constructor(
    private _localStorageService: LocalStorageService,
    private _authorizeService: AuthorizeService,
    private _btxLocationDataService: LocationsBtxDataSourceService,
    private _ktxLocationDataService: LocationsKtxDataSourceService,
    private _itxLocationDataService: LocationsItxDatasourceService,
    private _router: Router,
    private _roleService: RoleService
  ) {
  }

  ngOnInit() {
    const url = this._router.url;
    const isBackDoor = url.indexOf(BACK_DOOR) !== -1;

    this._role  = isBackDoor
      ? this._roleService.getBackDoorUserWithRole(url).role
      : this._localStorageService.getLoginInfo().role;

    switch (this._role) {
      case UserRoleEnum.CompanyAdmin:
        this._roleString = 'Company Admin';
        this._roleLetter = 'A';
        break;
      case UserRoleEnum.ServiceUser:
        this._roleString = 'Super Admin';
        this._roleLetter = 'A';
        break;
      case UserRoleEnum.CompanyUser:
        this._roleString = 'Company User';
        this._roleLetter = 'U';
        break;
      case UserRoleEnum.Vendor:
        this._roleString = 'Vendor';
        this._roleLetter = 'V';
        break;
      case UserRoleEnum.SalesUser:
        this._roleString = 'Sales user';
        this._roleLetter = 'S';
        break;
      default:
        this._roleString = 'Unknown';
        this._roleLetter = 'U';
    }
  }

  onClickProfile() {
    this.profilePopup.show();
  }

 async onLogoutClick() {
    this._authorizeService.logout();
    this._btxLocationDataService.clearBehaveCache();
    this._ktxLocationDataService.clearBehaveCache();
  }
}
