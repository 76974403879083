import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { AqsRoundStorageWiewModel } from '@models';
import { AqsConfirmPopupComponent } from '@popups';
import { AqsRoundStorageTypeLabel } from './../../../constants';
import { AqsRoundStorageHopperTypeEnum } from './../../../enums';
import { AqsRoundViewTabComponent } from './aqs-round-view-tab';

@Component({
  selector: 'greensleeves-aqs-round-configuration-tab',
  templateUrl: './aqs-round-configuration-tab.component.html',
  styles: []
})
export class AqsRoundConfigurationTabComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() _roundStorage: AqsRoundStorageWiewModel;

  @Input() _currentPageError: boolean;
  @Output() _currentPageErrorChange = new EventEmitter<boolean>();

  @Output() onChangedRoundConfiguration = new EventEmitter();

  @ViewChild(AqsRoundViewTabComponent, { read: false, static: false })
  private viewStoradgeComponent: AqsRoundViewTabComponent;

  @ViewChild(AqsConfirmPopupComponent, { read: false, static: false })
  private confirmModal: AqsConfirmPopupComponent;

  _storageHyperType: { label: string, value: AqsRoundStorageHopperTypeEnum }[] = [];

  _hopperType: AqsRoundStorageHopperTypeEnum;
  _dia: number;
  _height: number;
  _cap: number;
  _sw: number;
  _ledge: number;
  _pitch: number;
  _hopPitch: number;
  _outlet: number;
  _oheight: number;
  _repose: number;

  _effDepth: number;
  _capacity: number;

  _diaToSmallError = false;
  _heightOutOfRangeError = false;
  _capMoreDiaError = false;
  _ladgeOutHopperError = false;
  _noStemWallError = false;
  _noLadgeError = false;
  _outletOutOfRange = false;
  _outletAndOutOfRange = false;
  _outletOutHopperError = false;
  _outletToLargeError = false;
  _reposeOutOfRange = false;
  _pitchOutOfRange = false;
  _hopperpitchOutOfRange = false;
  private confirmSubscription: Subscription;
  constructor() { }

  ngOnInit() {
    for (const [key, value] of Object.entries(AqsRoundStorageTypeLabel)) {
      this._storageHyperType.push({ label: value, value: Number(key) });
    }

    this._hopperType = this._roundStorage.hopperType;
    this._dia = this._roundStorage.diameter;
    this._height = this._roundStorage.eaveHeight;
    this._cap = this._roundStorage.capDiameter;
    this._sw = this._roundStorage.steamwall;
    this._ledge = this._roundStorage.ledge;
    this._pitch = this._roundStorage.roofPitch;
    this._hopPitch = this._roundStorage.hopperPitch;
    this._outlet = this._roundStorage.outletDiameter;
    this._oheight = this._roundStorage.ovlHeight;
    this._repose = this._roundStorage.reposeAngle;
    this._capacity = this._roundStorage.capacity;
    this._effDepth = this._roundStorage.effDepth;

  }

  ngAfterViewInit() {
    this.confirmSubscription = this.confirmModal.confirm.subscribe(confirm => {
      if (confirm) {
        this._roundStorage.capacity = this._capacity;
        this._roundStorage.effDepth = this._effDepth;
      }
      else {
        this._capacity = this._roundStorage.capacity;
        this._effDepth = this._roundStorage.effDepth;
        this.changeRoundConfiguration();
      }
    });
    this.checkErrorAndOutModel();
  }

  ngOnDestroy() {
    this.confirmSubscription.unsubscribe();
  }

  onChangeHopperType() {
    this._roundStorage.hopperType = this._hopperType;
    this.setOvlHeight();
    this.checkErrorAndOutModel();
    this.setCapacityAndEffDepth();
    this.changeRoundConfiguration();
  }

  onChangeDiameter() {
    if (this._dia == null || this._dia < 0) {
      this._dia = this._roundStorage.diameter;
    }

    if (this._dia != this._roundStorage.diameter) {
      this._roundStorage.diameter = this._dia;
      this.setOvlHeight();
      this.setCapacityAndEffDepth();
      this.checkErrorAndOutModel();
      this.changeRoundConfiguration();
    }
  }

  onChangeEaveHeight() {
    if (this._height == null || this._height < 0) {
      this._height = this._roundStorage.eaveHeight;
    }

    if (this._height != this._roundStorage.eaveHeight) {
      this._roundStorage.eaveHeight = this._height;
      this.setOvlHeight();
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangeCapDiameter() {
    if (this._cap == null || this._cap < 0) {
      this._cap = this._roundStorage.capDiameter;
    }

    if (this._cap != this._roundStorage.capDiameter) {
      this._roundStorage.capDiameter = this._cap;
      this.setOvlHeight();
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangeStemwall() {
    if (this._sw == null || this._sw < 0) {
      this._sw = this._roundStorage.steamwall;
    }

    if (this._sw != this._roundStorage.steamwall) {
      this._roundStorage.steamwall = this._sw;
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangeLedge() {
    if (this._ledge == null || this._ledge < 0) {
      this._ledge = this._roundStorage.ledge;
    }

    if (this._ledge != this._roundStorage.ledge) {
      this._roundStorage.ledge = this._ledge;
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangePitch() {
    if (this._pitch == null || this._pitch < 0) {
      this._pitch = this._roundStorage.roofPitch;
    }

    if (this._pitch != this._roundStorage.roofPitch) {
      this._roundStorage.roofPitch = this._pitch;
      this._roundStorage.reposeAngle = this._pitch;
      this._repose = this._pitch;
      this.setOvlHeight();
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangeHopPitch() {
    if (this._hopPitch == null || this._hopPitch < 0) {
      this._hopPitch = this._roundStorage.hopperPitch;
    }

    if (this._hopPitch != this._roundStorage.hopperPitch) {
      this._roundStorage.hopperPitch = this._hopPitch;
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangeOutLet() {
    if (this._outlet == null || this._outlet < 0) {
      this._outlet = this._roundStorage.outletDiameter;
    }
    if (this._outlet != this._roundStorage.outletDiameter) {
      this._roundStorage.outletDiameter = this._outlet;
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangeOvlHeight() {
    if (this._oheight == null || this._oheight < 0) {
      this._oheight = this._roundStorage.ovlHeight;
    }
    if (this._oheight != this._roundStorage.ovlHeight) {
      this._roundStorage.ovlHeight = this._oheight;
      this.setRoofPitch();
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangeRepose() {
    if (this._repose == null || this._repose < 0) {
      this._repose = this._roundStorage.reposeAngle;
    }
    if (this._repose != this._roundStorage.reposeAngle) {
      this._roundStorage.reposeAngle = this._repose;
      this.checkErrorAndOutModel();
      this.setCapacityAndEffDepth();
      this.changeRoundConfiguration();
    }
  }

  onChangeEffDepthOrCapacity() {
    if (this._capacity != this._roundStorage.capacity || this._effDepth != this._roundStorage.effDepth) {
      if (this._capacity == null) this._capacity = 0;
      if (this._effDepth == null) this._effDepth = 0;
      let [calcEffDepth, calcCapacity] = this._roundStorage.getCalulatedDepthAndCapacity();
      this.confirmModal.showForConfirmCapacityAndEffDepth(this._effDepth, calcEffDepth, this._capacity, calcCapacity);
    }
  }

  setCapacityAndEffDepth() {
    this._roundStorage.calculateAndSetDepthAndCapacity();
    this._capacity = this._roundStorage.capacity;
    this._effDepth = this._roundStorage.effDepth;
  }

  changeRoundConfiguration() {
    this.onChangedRoundConfiguration.emit();
  }

  private setOvlHeight() {
    this._roundStorage.calculateAndSetOvlHeight();
    this._oheight = this._roundStorage.ovlHeight;
  }

  private setRoofPitch() {
    this._roundStorage.calculateAndSetRoofPich();
    this._pitch = this._roundStorage.roofPitch;
    this._repose = this._roundStorage.reposeAngle;
  }

  private checkErrorAndOutModel() {

    this._diaToSmallError = this._roundStorage.diameter < 10;
    this._heightOutOfRangeError = this._roundStorage.eaveHeight <= 0;
    this._capMoreDiaError = this._roundStorage.capDiameter >= this._roundStorage.diameter;
    this._ladgeOutHopperError = this._roundStorage.ledge > 0 && this._roundStorage.hopperPitch == 0;
    this._noStemWallError = this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.SideDraw && this._roundStorage.hopperPitch > 0
      && this._roundStorage.steamwall > 0;
    this._noLadgeError = this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.SideDraw && this._roundStorage.hopperPitch > 0
      && this._roundStorage.ledge > 0;
    this._outletAndOutOfRange = this._roundStorage.outletDiameter >= this._roundStorage.diameter - (2 * this._ledge);
    this._outletOutOfRange = this._roundStorage.outletDiameter < 0;
    this._outletOutHopperError = this._roundStorage.outletDiameter > 0 && this._roundStorage.hopperPitch == 0;
    this._outletToLargeError = this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.SideDraw && this._roundStorage.outletDiameter >= this._roundStorage.diameter / 3;
    this._reposeOutOfRange = this._roundStorage.reposeAngle < 0 || this._roundStorage.reposeAngle > this._roundStorage.roofPitch;
    this._pitchOutOfRange = this._roundStorage.roofPitch < 0 || this._roundStorage.roofPitch > 50;
    this._hopperpitchOutOfRange = this._roundStorage.hopperType < 0 || this._roundStorage.hopperPitch > 50;
    this.setGlobalError();

    this.viewStoradgeComponent.reDraw(this._currentPageError);
  }

  private setGlobalError() {
    this._currentPageError = this._diaToSmallError || this._heightOutOfRangeError || this._capMoreDiaError || this._ladgeOutHopperError || this._noStemWallError
      || this._noLadgeError || this._outletOutOfRange || this._outletOutHopperError || this._outletToLargeError || this._reposeOutOfRange || this._pitchOutOfRange
      || this._hopperpitchOutOfRange || this._outletAndOutOfRange;
    this._currentPageErrorChange.emit(this._currentPageError);

  }
}
