import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import { HubService, UpdaterDeviceService } from '../http';
import { CancelUpdateFirmwarePostModel, CancelUpdateModel, CheckProjectsGetModel, GetInstalledProjectFirmwareInfo, GetLocationUpdatersFilterModel, InstallProjectPostModel, LocationUpdaterViewModel, ProjectFirmwareViewModel, ProjectUpdaterDeviceViewModel, UpdateDeviceViewModel, UpdateFirmwarePostModel, UpdateFirmwareResultModel, UpdateFirmwaresPostModel, UpdateFirmwareTriggerModel, UpdateProjectDeviceState, UpdaterProjectUpdateFirmwareHubMessage, VersionHistoryResponseModel } from '../../models';
import { UpdateProjectStateEnum } from '../../enums';
import { HubMethods } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class UpdaterDeviceDatasourceService {
  public locationsUpdaters$: BehaviorSubject<LocationUpdaterViewModel[]> = new BehaviorSubject([]);
  public firmwares$: BehaviorSubject<ProjectFirmwareViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(
    private _updateDeviceService: UpdaterDeviceService,
    private _hubService: HubService,
  ) {
    this.subscribeToHubService();
  }

  public async getByFilter(request: GetLocationUpdatersFilterModel) {
    try {
      const updaters = await this._updateDeviceService.getByFilter(request) as LocationUpdaterViewModel[];
      if (updaters) {
        updaters.forEach(x => {
          let projectsAvailable = 0;
          let projectUpdatersAvailable = 0;

          x.locationDevices.forEach(device => {
            projectsAvailable |= device.project;
          })
          x.projectsAvailable = projectsAvailable;

          x.updaterDevices.forEach(updater => {
            projectUpdatersAvailable |= updater.projectsAvailable;
          })
          x.projectUpdaterAvailable = projectUpdatersAvailable;
        })
        this.locationsUpdaters$.next(updaters as LocationUpdaterViewModel[]);
        this.errors$.next(null);
      }
      else {
        this.locationsUpdaters$.next([]);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async add(updater: { locationId: number, projectsAvailable: number }): Promise<void | ErrorModel> {
    try {
      const result = await this._updateDeviceService.add(updater) as UpdateDeviceViewModel;
      if (result) {
        let locations = this.locationsUpdaters$.getValue();
        let location = locations.find(x => x.id == updater.locationId);

        this.addUpdaterToProj(result, location);

        this.locationsUpdaters$.next(locations);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async edit(id: string, updaterEdit: { projectsAvailable: number }) {
    try {
      const result = await this._updateDeviceService.edit(id, updaterEdit) as UpdateDeviceViewModel;
      if (result) {
        let locations = this.locationsUpdaters$.getValue();
        let location = locations.find(x => x.id == result.locationId);

        let updaterIndex = location.updaterDevices.indexOf(location.updaterDevices.find(u => u.id == id));
        location.updaterDevices.splice(updaterIndex, 1);
        this.addUpdaterToProj(result, location);

        this.locationsUpdaters$.next(locations);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async initializeDeviceConfiguration(id: string): Promise<boolean | ErrorModel> {
    try {
      const result = await this._updateDeviceService.initializeDeviceConfiguration(id);
      if (result) {
        this.errors$.next(null);
        return result;
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async downloadConnectionFile(id: string): Promise<Blob | ErrorModel> {
    try {
      const result = await this._updateDeviceService.downloadConnectionFile(id);
      return Promise.resolve(
        new Blob([result as BinaryType], { type: "application/zip" })
      );
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async delete(updaterId: string, locationId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._updateDeviceService.delete(updaterId);
      if (result) {
        let locations = this.locationsUpdaters$.getValue();
        let location = locations.find(x => x.id == locationId);
        location.updaterDevices = location.updaterDevices.filter(x => x.id != updaterId);

        this.locationsUpdaters$.next(locations);
        this.errors$.next(null);
        return result;
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getBlobFirmwareFiles(project?: string) {
    try {
      const result = await this._updateDeviceService.getBlobFirmwareFiles(project) as ProjectFirmwareViewModel[];
      if (result) {
        result.forEach(e => {
          e.name = this.nameWithoutFolder(e.name) as string;
        });

        this.firmwares$.next(result);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async downloadFirmwareFile(request: { directoryName: string, fileName: string }): Promise<Blob | ErrorModel> {
    try {
      const result = await this._updateDeviceService.downloadFirmwareFile(request);
      this.errors$.next(null);

      return Promise.resolve(
        new Blob([result as BinaryType], { type: "application/txt" })
      );
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadFirmwareFiles(request: { directoryName: string, fileNames: string[] }): Promise<Blob | ErrorModel> {
    try {
      const result = await this._updateDeviceService.downloadFirmwareFiles(request);
      this.errors$.next(null);

      return Promise.resolve(
        new Blob([result as BinaryType], { type: "application/zip" })
      );
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async deleteSelectedFirmwares(request: { directoryName: string, fileNames: string[] }): Promise<boolean | ErrorModel> {
    try {
      const result = await this._updateDeviceService.deleteSelectedFirmwares(request);
      if (result) {
        let firmwares = this.firmwares$.getValue();
        firmwares.filter(f => !request.fileNames.includes(f.name));
        this.firmwares$.next(firmwares);
      }

      this.errors$.next(null);

      return result;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async uploadFirmware(request: { directoryName: string, insertFile: File }): Promise<ProjectFirmwareViewModel | ErrorModel> {
    try {
      const result = await this._updateDeviceService.uploadFirmware(request) as ProjectFirmwareViewModel;
      if (result) {
        result.name = this.nameWithoutFolder(result.name) as string;

        let firmwares = this.firmwares$.getValue();
        let filtered = firmwares.filter(f => f.name !== result.name);

        filtered.push(result);
        this.firmwares$.next(filtered);
        this.errors$.next(null);

        return result;
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async installProjectFirmware(request: InstallProjectPostModel): Promise<UpdateFirmwareResultModel | ErrorModel> {
    try {
      const result = await this._updateDeviceService.installProjectFirmware(request) as UpdateFirmwareResultModel;
      if (result) {
        if (result) {
          this.updateStateAfterSendUpdateCommand([result] as UpdateFirmwareResultModel[]);
          this.sendMessageUpdateState([result] as UpdateFirmwareResultModel[]);
        }
        return result;
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getInstalledProjectFirmwareInformation(request: CheckProjectsGetModel): Promise<GetInstalledProjectFirmwareInfo | ErrorModel> {
    try {
      const result = await this._updateDeviceService.getInstalledProjectFirmwareInformation(request) as GetInstalledProjectFirmwareInfo;
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async cancelUpdateDeviceFirmware(request: CancelUpdateFirmwarePostModel): Promise<CancelUpdateModel | ErrorModel> {
    try {
      const result = await this._updateDeviceService.cancelUpdateDeviceFirmware(request) as CancelUpdateModel;
      if (result) {
        let locations = this.locationsUpdaters$.getValue();
        let updatedLocation = locations.find(x => x.id == request.locationId);
        let updateDevice = updatedLocation.updaterDevices.find(x => x.id == request.updaterDeviceId);
        if (updateDevice) {
          var project = updateDevice.projectDevices.find(x => x.deviceId == request.projectDeviceId);
          if (result.payLoadSuccess) {
            project.state = UpdateProjectStateEnum.Ok;
          }
          this.locationsUpdaters$.next(locations);
        }
      }

      this.errors$.next(null);
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async updateDeviceFirmware(request: UpdateFirmwarePostModel): Promise<UpdateFirmwareResultModel | ErrorModel> {
    try {
      const result = await this._updateDeviceService.updateDeviceFiemware(request) as UpdateFirmwareResultModel;
      if (result) {
        this.updateStateAfterSendUpdateCommand([result] as UpdateFirmwareResultModel[]);
        this.sendMessageUpdateState([result] as UpdateFirmwareResultModel[]);
      }

      this.errors$.next(null);
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async updateDeviceFirmwares(request: UpdateFirmwaresPostModel): Promise<UpdateFirmwareResultModel[] | ErrorModel> {
    try {
      const result = await this._updateDeviceService.updateDeviceFiemwares(request) as UpdateFirmwareResultModel[];
      if (result) {
        this.updateStateAfterSendUpdateCommand(result);
        this.sendMessageUpdateState(result);
      }
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getUpdateHistoryVersions(devideId: string, locationId: string): Promise<VersionHistoryResponseModel[] | ErrorModel> {
    try {
      const result = await this._updateDeviceService.getUpdateHistoryVersions(devideId, locationId) as VersionHistoryResponseModel[];
      if (result) {
        result.forEach(v => {
          if (v.targetVersion === '') {
            v.targetVersion = '-';
          }
          if (v.lastErrorMessage === '') {
            v.lastErrorMessage = '-';
          }
          if (v.currentVersion === '') {
            v.currentVersion = '-';
          }
        })
        return result;
      }
      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getUpdaterStates(devices: UpdateFirmwareTriggerModel[]) {
    try {
      const request = devices.map(x => x.projectDeviceId);
      const result = await this._updateDeviceService.getUpdaterStates(request) as UpdateProjectDeviceState[];
      if (result) {
        var locations = this.locationsUpdaters$.getValue();
        result.forEach(projectState => {
          const device = devices.find(x => x.projectDeviceId == projectState.projectDeviceId)
          if (device) {
            let projectLocation = locations.find(x => x.id == device.locationId);
            if (projectLocation) {
              let updater = projectLocation.updaterDevices.find(x => x.id == device.updaterDeviceId);
              let project = updater.projectDevices.find(x => x.deviceId == device.projectDeviceId);
              project.currentVersion = projectState.currentVersion;
              project.errorMessage = projectState.errorMessage;
              project.lastUpdate = projectState.lastUpdate;
              project.state = projectState.state;
            }
          }
        });
        this.locationsUpdaters$.next(locations);
      }
      this.errors$.next(null);

    } catch (error) {
      this.errors$.next(error);
    }
  }

  private async sendMessageUpdateState(model: UpdateFirmwareResultModel[]): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.Updater.updaterProjectUpdateFirmware, new UpdaterProjectUpdateFirmwareHubMessage(model));
  }


  private subscribeToHubService() {
    this._hubService.updaterProjectUpdateFirmware.subscribe((hubMessage: UpdaterProjectUpdateFirmwareHubMessage) => {
      if (hubMessage.projectsUpdateState) {
        this.updateStateAfterSendUpdateCommand(hubMessage.projectsUpdateState);
      }
    });
  }

  private updateStateAfterSendUpdateCommand(result: UpdateFirmwareResultModel[]) {
    let locations = this.locationsUpdaters$.getValue();
    result.forEach(y => {
      let updatedLocation = locations.find(x => x.id == y.locationId);
      let updateDevice = updatedLocation.updaterDevices.find(x => x.id == y.updaterDeviceId);
      if (updateDevice) {
        var project = updateDevice.projectDevices.find(x => x.deviceId == y.projectDeviceId);
        project.lastUpdate = new Date().getTime() / 1000;
        if (y.payLoadSuccess) {
          project.state = UpdateProjectStateEnum.Starting;
          project.errorMessage = '';
        } else {
          project.state = UpdateProjectStateEnum.Failed;
          project.errorMessage = y.payLoadMessage;
        }
      }
    });
    this.locationsUpdaters$.next(locations);
  }

  private nameWithoutFolder(name: string): string | void {
    const index = name.indexOf('/');
    if (index !== -1) {
      return name.slice(index + 1, name.length);
    }

    return;
  }

  private addUpdaterToProj(newUpdater: UpdateDeviceViewModel, location: LocationUpdaterViewModel) {

    let locationDevices = location.locationDevices.filter(d => (d.project & newUpdater.projectsAvailable) !== 0);

    locationDevices.forEach(d => {
      let newProjectUpd = new ProjectUpdaterDeviceViewModel();
      newProjectUpd.currentVersion = null,
        newProjectUpd.deviceId = d.deviceId,
        newProjectUpd.lastUpdate = null,
        newProjectUpd.project = d.project,

        newUpdater.projectDevices.push(newProjectUpd)
    });

    location.updaterDevices.push(newUpdater);
  }
}
