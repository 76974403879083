import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { AqsRoundStorageWiewModel } from '@models';
import { AqsRoundStorageHopperTypeEnum } from './../../../../enums';

@Component({
  selector: 'greensleeves-aqs-round-view-tab',
  templateUrl: './aqs-round-view-tab.component.html',
  styles: []
})
export class AqsRoundViewTabComponent implements OnInit {
  private FontSize: number = 23;

  @ViewChild('viewCanvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @Input() _roundStorage: AqsRoundStorageWiewModel;

  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;
  private scale: number;
  private heightOfTopPart: number;
  private roofHeight: number;
  private hopperHeight: number;
  constructor() { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;
    this.heightOfTopPart = this.height / 2.5;
  }

  public reDraw(error: boolean) {
    if (!error) {
      this.calculateScale();
      this.clearView();
      this.ctx.strokeStyle = 'black';
      this.drawScheme();
    }
    else {
      this.drawErrorBorder();
    }
  }

  private calculateScale() {
    this.roofHeight = (this._roundStorage.diameter / 2 - this._roundStorage.capDiameter / 2) * Math.tan(this._roundStorage.roofPitch * Math.PI / 180);
    this.hopperHeight = this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.Conical
      ? (this._roundStorage.diameter / 2 - this._roundStorage.ledge - this._roundStorage.outletDiameter / 2) * Math.tan(this._roundStorage.hopperPitch * Math.PI / 180)
      : this._roundStorage.diameter * Math.tan(this._roundStorage.hopperPitch * Math.PI / 180);

    let fullHeight = this.roofHeight + this.hopperHeight + this._roundStorage.eaveHeight + this._roundStorage.steamwall;

    this.scale = this.heightOfTopPart / fullHeight;
    if (this._roundStorage.diameter * this.scale > this.heightOfTopPart) this.scale = this.heightOfTopPart / (this._roundStorage.diameter);

  }

  private clearView() {
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  private drawScheme() {
    this.drawCircleSchema();
    this.drawStorageSchema();
  }

  private drawCircleSchema() {
    this.drawTopCircle(this._roundStorage.diameter / 2);
    if (this._roundStorage.capDiameter > 0) {
      this.drawTopCircle(this._roundStorage.capDiameter / 2);
    }

    if (this._roundStorage.outletDiameter > 0 && this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.Conical) {
      this.drawTopCircle(this._roundStorage.outletDiameter / 2);
    }

    if (this._roundStorage.ledge > 0) {
      let ladgeCircleDiameter = this._roundStorage.diameter - this._roundStorage.ledge*2;
      this.drawTopCircle(ladgeCircleDiameter / 2);
    }
  }

  private drawTopCircle(raidus: number) {
    this.ctx.beginPath();
    this.ctx.arc(this.width / 2, this.heightOfTopPart / 2, raidus * this.scale, 0, 2 * Math.PI, false);
    this.ctx.stroke()
  }

  private drawErrorBorder() {
    this.ctx.lineWidth = 3;
    this.ctx.strokeStyle = 'red';
    this.ctx.strokeRect(0, 0, this.width, this.height);
    this.ctx.lineWidth = 1;
  }

  private drawStorageSchema() {
    this.ctx.lineJoin = "miter";
    this.ctx.beginPath();
    let middleSLayerY = this.height - (this.height - this.heightOfTopPart) / 2;
    let middleSLayerX = this.width / 2;

    let x = middleSLayerX - this._roundStorage.diameter / 2 * this.scale;
    let y = middleSLayerY + (this._roundStorage.eaveHeight + this._roundStorage.steamwall) / 2 * this.scale;

    let startX = x;
    let startY = y;

    this.ctx.moveTo(x, y);
    y -= (this._roundStorage.eaveHeight + this._roundStorage.steamwall) * this.scale;
    this.ctx.lineTo(x, y);
    let roofStartX = x;
    let roofStartY = y;
    if (this._roundStorage.roofPitch != this._roundStorage.reposeAngle) {
      let pileHeight = this._roundStorage.diameter / 2 * Math.tan(this._roundStorage.reposeAngle * Math.PI / 180);
      if (pileHeight > this.roofHeight) {
        let diffY = 0;
        let diffX = 0;
        diffY = (pileHeight - this.roofHeight) * this.scale;
        diffX = (diffY / Math.tan(this._roundStorage.reposeAngle * Math.PI / 180)) * 2 * this.scale;
        if (diffX > this._roundStorage.capDiameter * this.scale) diffX = this._roundStorage.capDiameter * this.scale;
        x += this._roundStorage.diameter / 2 * this.scale - diffX / 2;
        y -= this.roofHeight * this.scale;
        this.ctx.lineTo(x, y);
        x += diffX;
        this.ctx.lineTo(x, y);
        x += this._roundStorage.diameter / 2 * this.scale - diffX / 2;
        y += this.roofHeight * this.scale;
        this.ctx.lineTo(x, y);
      }
      else {
        x += this._roundStorage.diameter / 2 * this.scale;
        y -= pileHeight * this.scale;
        this.ctx.lineTo(x, y);
        x += this._roundStorage.diameter / 2 * this.scale;
        y += pileHeight * this.scale;
        this.ctx.lineTo(x, y);
      }
    }
    else {
      x += (this._roundStorage.diameter / 2 - this._roundStorage.capDiameter / 2) * this.scale;
      y -= this.roofHeight * this.scale;
      this.ctx.lineTo(x, y);
      x += this._roundStorage.capDiameter * this.scale;
      this.ctx.lineTo(x, y);
      x += (this._roundStorage.diameter / 2 - this._roundStorage.capDiameter / 2) * this.scale;
      y += this.roofHeight * this.scale;
      this.ctx.lineTo(x, y);
    }


    y += (this._roundStorage.eaveHeight + this._roundStorage.steamwall) * this.scale;
    this.ctx.lineTo(x, y);
    if (this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.SideDraw) {
      y += this.hopperHeight * this.scale;
      this.ctx.lineTo(x, y);
      x -= this._roundStorage.outletDiameter * this.scale;
      this.ctx.lineTo(x, y);
      this.ctx.lineTo(startX, startY);
    }
    else {
      if (this._roundStorage.ledge > 0) {
        x -= this._roundStorage.ledge * this.scale;
        this.ctx.lineTo(x, y);
      }
      x -= (this._roundStorage.diameter / 2 - this._roundStorage.ledge - this._roundStorage.outletDiameter / 2) * this.scale;
      y += this.hopperHeight * this.scale;
      this.ctx.lineTo(x, y);
      x -= this._roundStorage.outletDiameter * this.scale;
      this.ctx.lineTo(x, y);
      y -= this.hopperHeight * this.scale;
      x -= (this._roundStorage.diameter / 2 - this._roundStorage.ledge - this._roundStorage.outletDiameter / 2) * this.scale;
      this.ctx.lineTo(x, y);
      this.ctx.lineTo(startX, startY);
    }

    this.ctx.fillStyle = "Bisque";
    this.ctx.fill();
    this.ctx.stroke();
    //Roof
    if (this._roundStorage.roofPitch != this._roundStorage.reposeAngle) {
      this.ctx.beginPath();
      x = roofStartX;
      y = roofStartY;
      this.ctx.moveTo(x, y);
      x += (this._roundStorage.diameter / 2 - this._roundStorage.capDiameter / 2) * this.scale;
      y -= this.roofHeight * this.scale;
      this.ctx.lineTo(x, y);
      x += this._roundStorage.capDiameter * this.scale;
      this.ctx.lineTo(x, y);
      x += (this._roundStorage.diameter / 2 - this._roundStorage.capDiameter / 2) * this.scale;
      y += this.roofHeight * this.scale;
      this.ctx.lineTo(x, y);
      this.ctx.stroke();
    }

    this.ctx.fillStyle = "black";
    this.ctx.font = `${this.FontSize}px serif`

    this.drawDiameterLabel(startX, startY);
    this.drawEaveHeightLabel(startX, startY);
    this.drawOvLHeightLabel(startX, startY);
    this.drawRoofPithLabel(roofStartX, roofStartY);
    this.drawReposeLabel(roofStartX, roofStartY);
    this.drawStewallLabel(startX, startY);
    this.drawLedgeLabel(startX, startY);
    this.drawHopperPitchLabel(startX, startY);
    this.drawOutletDiameterlabel(startX, startY);
    this.drawCapDiameterLabel(startX, startY);
  }

  private drawOutletDiameterlabel(startX: number, startY: number) {
    if (this._roundStorage.outletDiameter > 0) {
      let x: number;
      let y: number;
      let posLabelRelativeToDia = this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.Conical ? this._roundStorage.diameter / 2 : this._roundStorage.diameter - this._roundStorage.outletDiameter / 2;
      posLabelRelativeToDia *= this.scale;
      let numCount = this.getNumberCount(this._roundStorage.outletDiameter);
      x = startX + posLabelRelativeToDia - this.FontSize * numCount / 4;
      y = startY + this.hopperHeight * this.scale + this.FontSize;
      this.ctx.fillText(this._roundStorage.outletDiameter.toString(), x, y);
    }
  }

  private drawHopperPitchLabel(startX: number, startY: number) {
    if (this._roundStorage.hopperPitch > 0) {
      let x: number;
      let y: number;
      if (this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.SideDraw) {
        x = startX + this.FontSize / 4;
        y = startY - 2;
      } else {
        x = startX + this._roundStorage.ledge * this.scale + this.FontSize / 4;
        y = startY - 2;
      }

      this.ctx.fillText(this._roundStorage.hopperPitch.toString(), x, y);
    }
  }

  private drawLedgeLabel(startX: number, startY: number) {
    if (this._roundStorage.ledge > 0 && this._roundStorage.hopperType == AqsRoundStorageHopperTypeEnum.Conical) {
      //left
      let x = startX + this._roundStorage.ledge / 2 * this.scale - this.FontSize / 4;
      let y = startY + this.FontSize;
      this.ctx.fillText(this._roundStorage.ledge.toString(), x, y);
      //right
      x = startX + (this._roundStorage.diameter - this._roundStorage.ledge / 2) * this.scale - this.FontSize / 4;
      this.ctx.fillText(this._roundStorage.ledge.toString(), x, y);
    }
  }

  private drawStewallLabel(startX: number, startY: number) {
    let numCount = this.getNumberCount(this._roundStorage.steamwall);
    if (this._roundStorage.steamwall > 0) {
      let x = this._roundStorage.steamwall * this.scale >= this.FontSize ? startX - 2 - this.FontSize / 2 * numCount : startX - 6 - this.FontSize * numCount;
      let y = this._roundStorage.steamwall * this.scale >= this.FontSize / 2 ? startY - this._roundStorage.steamwall / 2 * this.scale + this.FontSize / 2 : startY + this.FontSize / 2;
      this.ctx.fillText(this._roundStorage.steamwall.toString(), x, y);
      //left
      x = startX - 10;
      y = startY;
      this.ctx.beginPath();
      this.ctx.moveTo(x, y);
      x -= this.FontSize / 4;
      this.ctx.lineTo(x, y);
      x += this.FontSize / 8;
      this.ctx.moveTo(x, y);
      y += 5;
      this.ctx.lineTo(x, y);
      //right
      x = startX + this._roundStorage.diameter * this.scale + 10;
      y = startY;
      this.ctx.moveTo(x, y);
      x += this.FontSize / 4;
      this.ctx.lineTo(x, y);
      x -= this.FontSize / 8;
      this.ctx.moveTo(x, y);
      y += 5;
      this.ctx.lineTo(x, y);
      this.ctx.stroke();

      x = this._roundStorage.steamwall * this.scale >= this.FontSize ? startX + 2 + this._roundStorage.diameter * this.scale : startX + 6 + this._roundStorage.diameter * this.scale + this.FontSize / 2;
      y = this._roundStorage.steamwall * this.scale >= this.FontSize / 2 ? startY - this._roundStorage.steamwall / 2 * this.scale + this.FontSize / 2 : startY + this.FontSize / 2;
      this.ctx.fillText(this._roundStorage.steamwall.toString(), x, y);
    }
  }

  private drawCapDiameterLabel(startX: number, startY: number) {
    if (this._roundStorage.capDiameter > 0) {
      let numCount = this.getNumberCount(this._roundStorage.capDiameter)
      let x = startX + this._roundStorage.diameter / 2 * this.scale - this.FontSize * numCount / 4;
      let y = startY - (this._roundStorage.steamwall + this._roundStorage.eaveHeight + this.roofHeight) * this.scale + this.FontSize;
      this.ctx.fillText(this._roundStorage.capDiameter.toString(), x, y);
    }
  }

  private drawDiameterLabel(startX: number, startY: number) {
    let numCount = this.getNumberCount(this._roundStorage.diameter);
    this.ctx.beginPath()
    let x = startX + this._roundStorage.diameter / 2 * this.scale - this.FontSize * numCount / 4;
    let y = startY - (this._roundStorage.steamwall + this._roundStorage.eaveHeight + this.roofHeight) * this.scale - this.FontSize / 4;
    this.ctx.fillText(this._roundStorage.diameter.toString(), x, y);

    y -= this.FontSize / 4;
    x -= 1;
    this.ctx.moveTo(x, y);
    x -= this._roundStorage.diameter / 2 * this.scale - this.FontSize * numCount / 4;
    this.ctx.lineTo(x, y);
    y += this.FontSize / 8;
    this.ctx.moveTo(x, y);
    y -= this.FontSize / 4;
    this.ctx.lineTo(x, y);
    x = startX + this._roundStorage.diameter / 2 * this.scale + this.FontSize * numCount / 4;
    y = startY - (this._roundStorage.steamwall + this._roundStorage.eaveHeight + this.roofHeight) * this.scale - this.FontSize / 2;
    this.ctx.moveTo(x, y);
    x += this._roundStorage.diameter / 2 * this.scale - this.FontSize * numCount / 4 + 1;
    this.ctx.lineTo(x, y);
    y += this.FontSize / 8;
    this.ctx.moveTo(x, y);
    y -= this.FontSize / 4;
    this.ctx.lineTo(x, y);
    this.ctx.stroke();
  }

  private drawOvLHeightLabel(startX: number, startY: number) {
    this.ctx.beginPath()
    let x = startX + this._roundStorage.diameter * this.scale + 10;
    let y = startY - this._roundStorage.steamwall * this.scale;
    this.ctx.moveTo(x, y);
    x += this.FontSize / 4;
    this.ctx.lineTo(x, y);
    x -= this.FontSize / 8;
    this.ctx.moveTo(x, y);
    y -= (this._roundStorage.eaveHeight + this.roofHeight) / 2 * this.scale - this.FontSize / 2;
    this.ctx.lineTo(x, y);
    y -= this.FontSize;
    this.ctx.moveTo(x, y);
    y -= (this._roundStorage.eaveHeight + this.roofHeight) / 2 * this.scale - this.FontSize / 2;
    this.ctx.lineTo(x, y);
    x -= this.FontSize / 8;
    this.ctx.moveTo(x, y);
    x += this.FontSize / 4;
    this.ctx.lineTo(x, y);
    this.ctx.stroke();

    x = startX + 2 + this._roundStorage.diameter * this.scale;
    y = startY - (this._roundStorage.eaveHeight + this.roofHeight) / 2 * this.scale - this._roundStorage.steamwall * this.scale + this.FontSize / 4;
    this.ctx.fillText(this._roundStorage.ovlHeight.toString(), x, y);
  }

  private drawEaveHeightLabel(startX: number, startY: number) {
    let numCount = this.getNumberCount(this._roundStorage.eaveHeight);
    this.ctx.beginPath()
    let x = startX - 10;
    let y = startY - this._roundStorage.steamwall * this.scale;
    this.ctx.moveTo(x, y);
    x -= this.FontSize / 4;
    this.ctx.lineTo(x, y);
    x += this.FontSize / 8;
    this.ctx.moveTo(x, y);
    y -= this._roundStorage.eaveHeight / 2 * this.scale - this.FontSize / 2;
    this.ctx.lineTo(x, y);
    y -= this.FontSize;
    this.ctx.moveTo(x, y);
    y -= this._roundStorage.eaveHeight / 2 * this.scale - this.FontSize / 2;
    this.ctx.lineTo(x, y);
    x -= this.FontSize / 8;
    this.ctx.moveTo(x, y);
    x += this.FontSize / 4;
    this.ctx.lineTo(x, y);
    this.ctx.stroke();

    x = this._roundStorage.eaveHeight * this.scale >= this.FontSize ? startX - 2 - this.FontSize / 2 * numCount : startX - 2 - this.FontSize * numCount;
    y = startY - this._roundStorage.eaveHeight / 2 * this.scale - this._roundStorage.steamwall * this.scale + this.FontSize / 4;
    this.ctx.fillText(this._roundStorage.eaveHeight.toString(), x, y);
  }

  private drawRoofPithLabel(startX: number, startY: number) {
    if (this._roundStorage.roofPitch > 0) {
      let x = startX;
      let y = startY - this.FontSize / 1.5;
      this.ctx.fillText(this._roundStorage.roofPitch.toString(), x, y);
    }
  }

  private drawReposeLabel(startX: number, startY: number) {
    let x = startX + this._roundStorage.diameter / 7 * this.scale;
    let y = startY + this.FontSize / 4;
    if (this._roundStorage.reposeAngle > 0 && this._roundStorage.reposeAngle != this._roundStorage.roofPitch && this._roundStorage.diameter / 3 * this.scale > this.FontSize) {
      this.ctx.fillText(this._roundStorage.reposeAngle.toString(), x, y);
    }
  }

  private getNumberCount(number: number): number {
    let count = 0;
    do {
      number /= 10;
      number = number | 0;
      count++;
    } while (number != 0);
    return count;
  }

}