import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { CompanyAdminInviteModel, CompanyAdminViewModel } from '../../../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CompaniesDataSourceService, CompanyAdminsDataSourceService } from '../../../services';
import { LocalStorageService, UserRoleEnum, ErrorModel, ErrorCodeEnum } from '../../../../common';
import { emailValidator } from '../../../../common/validators';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-company-admin-add-popup',
  templateUrl: './company-admin-add-popup.component.html',
  styles: []
})
export class CompanyAdminAddPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked  {
  _isHidden = true;
  _submitted = false;
  _invalidEmail = false;
  _isSubmit = false;

  _companyAdminForm: FormGroup;

  _companies: { label: string, value: number }[];
  private _companiesSubscription: Subscription;

  _role: UserRoleEnum;

  get _isServiceUser() {
    return this._role === UserRoleEnum.ServiceUser;
  }

  get _isCompanyAdmin() {
    return this._role === UserRoleEnum.CompanyAdmin;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _companiesService: CompaniesDataSourceService,
    private _localStorageService: LocalStorageService,
    private _companyAdminsService: CompanyAdminsDataSourceService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this._companiesSubscription = this._companiesService.companies$.subscribe(data => {
      if (data) {
        this._companies = data.map(x => ({ label: x.name, value: x.id }));
      }
    });
    
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;

    if(this._isServiceUser) {
      this._companiesService.get();
    }

    this._invalidEmail = false;

    const loginInfo = this._localStorageService.getLoginInfo();
    this._role = loginInfo ? loginInfo.role : null;

    this._companyAdminForm = this._formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          emailValidator(),
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      ...(this._isServiceUser ? {
        companyId: [
          null,
          Validators.compose([
            Validators.required
          ])
        ]
      } : {})
    });

    this._companyAdminForm.valueChanges.subscribe(() => this._invalidEmail = false);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._companiesSubscription && this._companiesSubscription.unsubscribe();
    this._companiesSubscription = null;
  }

  ngAfterViewChecked() {
    super.ngAfterViewChecked();
  }

  public show() {
    this._invalidEmail = false;
    this._submitted = false;
    this._companyAdminForm.reset();
    if (this._isServiceUser){
      this._companiesService.get();
    }
    this._isHidden = false;
  }

  async onSubmit() {
    this._submitted = true;
    if (this._companyAdminForm.invalid || this._invalidEmail) {
      return;
    }

    const value = this._companyAdminForm.value as CompanyAdminInviteModel;
    if (this._isCompanyAdmin) {
      value.companyId = this._localStorageService.getLoginInfo().companyId;
    }

    this._companyAdminsService.invite(value)
    .then((response) => {
      if(response instanceof ErrorModel && response.code === ErrorCodeEnum.UserAlreadyExist) {
        this._isSubmit = false;
        this._invalidEmail = true;
      } else if(response) {
        this.resetForm();
      }
    })
  }

  private resetForm() {
    this._submitted = false;
    this._isSubmit = false;
    this._isHidden = true;
    this._companyAdminForm.reset();
    this._companyAdminForm.markAsUntouched();
    Object.keys(this._companyAdminForm.controls).forEach((name) => {
      const control = this._companyAdminForm.controls[name];
      control.setErrors(null);
    });
  }
}
