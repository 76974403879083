import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorModel, HttpService } from '../../../common';
import { ApiRoutes } from '../../constants';
import {
  ItxEventByPeriodPostModel,
  ItxEventGetModel,
  ItxEventsRequestModel,
  KtxEventByPeriodPostModel,
  KtxEventGetModel,
  KtxEventsRequestModel,
  BtxEventByPeriodPostModel,
  BtxEventGetModel,
  BtxEventsRequestModel,
} from '@models';

@Injectable({
  providedIn: 'root',
})
export class EventApiService {
  constructor(private _httpService: HttpService, private _router: Router) { }

  public async getItxByFilter(
    query: ItxEventByPeriodPostModel
  ): Promise<ItxEventsRequestModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getItxFakeData();
      }

      const result = (await this._httpService.post(
        ApiRoutes.Notification.getItxNotificationByQuery,
        query
      )) as ItxEventsRequestModel;

      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getKtxByFilter(
    query: KtxEventByPeriodPostModel
  ): Promise<KtxEventsRequestModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getKtxFakeData();
      }

      const result = (await this._httpService.post(
        ApiRoutes.Notification.getKtxNotificationByQuery,
        query
      )) as KtxEventsRequestModel;

      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getBtxByFilter(
    query: BtxEventByPeriodPostModel
  ): Promise<BtxEventsRequestModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getBtxFakeData();
      }

      const result = (await this._httpService.post(
        ApiRoutes.Notification.getBtxNotificationByQuery,
        query
      )) as BtxEventsRequestModel;

      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);

    return null;
  }

  private getItxFakeData() {
    let events = [
      {
        eventId: 1,
        title: 'Alarm TITLE',
        body: 'Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm',
        firstNotificationTimeStamp: Date.now(),
        lastNotificationTimeStamp: Date.now(),
        itxOptions: { itxAlertType: 0, itxBinId: 1, locationId: 2 },
      },
      {
        eventId: 2,
        title: 'Warning TITLE',
        body: 'Radar 2 has warning',
        firstNotificationTimeStamp: Date.now(),
        lastNotificationTimeStamp: Date.now(),
        itxOptions: { itxAlertType: 1, itxBinId: 1, locationId: 2 },
      },
      {
        eventId: 3,
        title: 'Error TITLE',
        body: 'Radar 21 has error',
        firstNotificationTimeStamp: Date.now(),
        lastNotificationTimeStamp: Date.now(),
        itxOptions: { itxAlertType: 2, locationId: 2 },
      },
    ] as ItxEventGetModel[];

    let request = new ItxEventsRequestModel();
    request.events = events;
    request.itemsPerPage = 12;
    request.totalCount = 5;

    return request;
  }

  private getKtxFakeData() {
    const events: KtxEventGetModel[] = [
      {
        eventId: 1,
        title: 'Alarm TITLE',
        body: 'Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm',
        firstNotificationTimeStamp: Date.now(),
        lastNotificationTimeStamp: Date.now(),
        isAlarm: true,
        locationId: 1,
        ktxOptions: {
          binName: 'Bin Name',
          unitName: 'Unit Name',
          locationName: 'Location Name',
          nextReadTimeStamp: Date.now(),
          binId: 1,
        },
      },
      {
        eventId: 2,
        title: 'Info TITLE',
        body: 'Radar 2 has info',
        firstNotificationTimeStamp: Date.now(),
        lastNotificationTimeStamp: Date.now(),
        isAlarm: false,
        locationId: 1,
        ktxOptions: {
          binName: 'Bin Name',
          unitName: 'Unit Name',
          locationName: 'Location Name',
          nextReadTimeStamp: Date.now(),
          binId: null,
        },
      },
    ];

    const request = new KtxEventsRequestModel();
    request.events = events;
    request.itemsPerPage = 12;
    request.totalCount = 5;

    return request;
  }

  private getBtxFakeData() {
    const events: BtxEventGetModel[] = [];
    for (let index = 1; index < 20; index++) {
      events.push(
        {
          eventId: index,
          title: `Alarm TITLE---${index}`,
          body: 'Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm,Radar 1 has alarm',
          firstNotificationTimeStamp: Date.now(),
          lastNotificationTimeStamp: Date.now(),
          isAlarm: true,
          locationId: 1,
          btxOptions: { equipmentId: index, equipmentName: `FanEquipment-${index}` },
        }
      );

    }

    const request = new BtxEventsRequestModel();
    request.events = events;
    request.itemsPerPage = 20;
    request.totalCount = 5;

    return request;
  }
}
