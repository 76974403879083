import { Injectable } from '@angular/core';

import { HttpService, ErrorModel, ApiRoutes } from '../../../common';
import { ServiceUserViewModel, ServiceUserInviteModel, ServiceUserPostModel, ServiceUserDeleteModel, ServiceUserGetModel } from '../../models';
import { Router } from '@angular/router';

@Injectable()
export class ServiceUsersApiService {
  constructor(
    private _httpService: HttpService,
    private _router: Router) { }

  public async loadAll() : Promise<ServiceUserViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const data = await this._httpService.get(ApiRoutes.Account.getAllServiceUser) as ServiceUserGetModel[];
      if (data) {
        const viewModels = data.map(user => ServiceUserGetModel.toViewModel(user));
        return viewModels;
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async invite(serviceUser: ServiceUserInviteModel) : Promise<ServiceUserViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.addServiceUser, serviceUser) as ServiceUserGetModel;      
      const viewModel = ServiceUserGetModel.toViewModel(result);
      return Promise.resolve(viewModel);
      } catch (error) {
      return this.processError(error);
    }
  }

  public async edit(serviceUser: ServiceUserPostModel) : Promise<ServiceUserViewModel | ErrorModel> {
    try {
      const getModel = await this._httpService.post(ApiRoutes.Account.editServiceUser, serviceUser) as ServiceUserGetModel;
      if (getModel != null) {
      const viewModel = ServiceUserGetModel.toViewModel(getModel);
      return Promise.resolve(viewModel);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async delete(serviceUserId: number) : Promise<number | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.deleteUser, { id: serviceUserId })
      if (result){
      return Promise.resolve(serviceUserId);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  public getFakeData(): ServiceUserViewModel[] {
    const data = [
      { id: 1, firstName: 'firstName', lastName: 'lastName', email: 'email', lastLoginDate: new Date },
      { id: 2, firstName: 'firstName2', lastName: 'lastName2', email: 'email2', lastLoginDate: new Date }
    ] as ServiceUserViewModel[];
    return data;
  }
}
