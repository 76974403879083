import { CalendarDefaultValues } from '../constants';
import { BtxEventTypeEnum } from '../enums';

export class BtxEventViewModel {
  public eventId: number;
  public title: string;
  public body: string;
  public firstNotificationTimeStamp: number;
  public lastNotificationTimeStamp: number;
  public btxAlertType: BtxEventTypeEnum;
  public locationId: number;
  public equipmentId: number;
  public equipmentName: string;
}

export class BtxEventGetModel {
  public eventId: number;
  public title: string;
  public body: string;
  public firstNotificationTimeStamp: number;
  public lastNotificationTimeStamp: number;
  public isAlarm: boolean;
  public locationId: number;
  public btxOptions: BtxOptions;

  public static toViewModel(getModel: BtxEventGetModel): BtxEventViewModel {
    const viewModel = new BtxEventViewModel();
    viewModel.eventId = getModel.eventId;
    viewModel.title = getModel.title;
    viewModel.body = getModel.body;
    viewModel.firstNotificationTimeStamp =
      getModel.firstNotificationTimeStamp *
      CalendarDefaultValues.defaultOneSecondMilliseconds;
    viewModel.lastNotificationTimeStamp =
      getModel.lastNotificationTimeStamp *
      CalendarDefaultValues.defaultOneSecondMilliseconds;
    viewModel.btxAlertType = getModel.isAlarm ? BtxEventTypeEnum.Alarm : BtxEventTypeEnum.Warning;
    viewModel.locationId = getModel.locationId;
    if (getModel.btxOptions) {
      viewModel.equipmentId = getModel.btxOptions.equipmentId;
      viewModel.equipmentName = getModel.btxOptions.equipmentName;
    }
    return viewModel;
  }
}

export class BtxOptions {
  public equipmentId: number;
  public equipmentName: string;
}

export class BtxEventsRequestModel {
  public events: Array<BtxEventGetModel>;
  public totalCount: number;
  public itemsPerPage: number;
}

export class BtxEventByPeriodPostModel {
  public locationIds: Array<number>;
  public equipmentIds: Array<number>;
  public dateTimeFrom: number;
  public dateTimeTo: number;
  public currentPage: number;
  public eventTypes: Array<BtxEventTypeEnum>;
  public isWeb: boolean = true;

  public get countSelectedFilter() {
    let count = 0;
    count += this.eventTypes.length;
    count += this.locationIds.length;
    count += this.equipmentIds.length;
    return count;
  }

  constructor(public eventsPerPage: number) {
    this.locationIds = [];
    this.equipmentIds = [];
    this.eventTypes = [];
    this.currentPage = 1;
  }
}
