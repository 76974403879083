import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BtxReportLiveDataFilter, NotificationReportPostModel, ReportDataFileFilterPost, ReportDataFilterPost, ReportHistoricalDataResponse, ReportLiveDataFilterPost } from '@models';
import { ReportApiService } from '../http';
import { ErrorModel } from '../../../common';
import { ReportFileFormatLabel } from '../../constants';
import { KtxReportCurrentDataFilter, KtxReportHistoricalDataFilter } from '../../models/ktx-reports.model';
import { BtxHistoricalDataReportFilterRequest } from '../../models/btx-reports.model';

@Injectable({
  providedIn: 'root'
})
export class ReportDatasourceService {
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  constructor(
    private _reportApi:ReportApiService
  ) { }

  async getItxReportHistoricalDataByFilter(filter: ReportDataFilterPost): Promise<ReportHistoricalDataResponse | ErrorModel>{
    try{
      const data = await this._reportApi.getItxReportHistoricalDataByFilter(filter);
      return data;
    }
    catch(error){
      this.errors$.next(error);
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async downloadKtxHistoricalReportFile(filter: KtxReportHistoricalDataFilter): Promise<Blob | ErrorModel> {
    try {
      const result = await this._reportApi.downloadKtxHistoricalReportFile(filter);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${ReportFileFormatLabel[0]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadBtxHistoricalDataReportFile(filter: BtxHistoricalDataReportFilterRequest): Promise<Blob | ErrorModel> {
    try {
      const result = await this._reportApi.downloadBtxHistoricalDataReportFile(filter);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${ReportFileFormatLabel[0]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadKtxCurrentDataReportFile(filter: KtxReportCurrentDataFilter): Promise<Blob | ErrorModel> {
    try {
      const result = await this._reportApi.downloadKtxCurrentDataReportFile(filter);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${ReportFileFormatLabel[0]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadItxHistoricalReportFile(filter: ReportDataFileFilterPost): Promise<Blob | ErrorModel> {
    try {
      const result = await this._reportApi.downloadItxHistoricalReportFile(filter);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${ReportFileFormatLabel[filter.reportFormat]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadItxLiveDataReportFile(filter: ReportLiveDataFilterPost): Promise<Blob | ErrorModel> {
    try {
      const result = await this._reportApi.downloadItxLiveDataReportFile(filter);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${ReportFileFormatLabel[filter.reportFormat]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadBtxLiveDataReportFile(filter: BtxReportLiveDataFilter): Promise<Blob | ErrorModel> {
    try {
      const result = await this._reportApi.downloadBtxLiveDataReportFile(filter);
      return Promise.resolve(new Blob([result as BinaryType], { type: `application/${ReportFileFormatLabel[0]}` }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadNotificationReport(model: NotificationReportPostModel) {
    try {
      const result = await this._reportApi.downloadNotificationReport(model);
      return Promise.resolve(new Blob([result as BinaryType], { type: "application/csv" }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }
}
