import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService, ErrorModel, ApiRoutes } from '../../../common';
import { UserStatusEnum } from '../../enums';
import { VendorViewModel, VendorGetModel, VendorInviteModel, VendorEditModel } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class VendorApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router) { }

  public async getAllForCompany(companyId: number): Promise<VendorViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const data = await this._httpService.get(ApiRoutes.Company.getAllVendorsForCompany, { companyId: companyId });
      if (data) {
        const vendorView = data.map(d => VendorGetModel.toViewModel(d));
        return vendorView;
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async invite(vendor: VendorInviteModel): Promise<VendorViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.addVendor, vendor);
      if (result) {
        return Promise.resolve(result);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }
    console.log(error);
    return null;
  }

  public async editForCompany(vendor: VendorEditModel): Promise<VendorViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.editVendorForCompany, vendor) as VendorGetModel;
      if (result) {
        const vendorViewModel = VendorGetModel.toViewModel(result);
        return Promise.resolve(vendorViewModel);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async deleteVendor(vendorId: number, companyId: number): Promise<number | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.deleteVendor, { vendorId: vendorId, companyId: companyId });
      if (result) {
        return Promise.resolve(vendorId);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  private getFakeData(): VendorViewModel[] {
    const data = [
      {
        id: 0, companyName: 'fakeCom', firstName: 'firstName', lastName: 'lastName', email: 'email', status: UserStatusEnum.Unlocked, countOfBins: 4, itxLocationsNames: ['location1', 'location2'], lastLoginDate: new Date,
        itxLocations: [{ id: 1, name: 'Location1', bins: [{ id: 1, name: 'bin1' }, { id: 2, name: 'bin2' }] }, { id: 2, name: 'Location2', bins: [{ id: 3, name: 'bin1' }, { id: 4, name: 'bin2' }] }]
      },
      {
        id: 0, companyName: 'fakeCom', firstName: 'firstName2', lastName: 'lastName2', email: 'email2', status: UserStatusEnum.Locked, countOfBins: 4, itxLocationsNames: ['location3', 'location4'], lastLoginDate: new Date,
        itxLocations: [{ id: 3, name: 'Location3', bins: [{ id: 5, name: 'bin1' }, { id: 6, name: 'bin2' }] }, { id: 4, name: 'Location4', bins: [{ id: 7, name: 'bin1' }, { id: 8, name: 'bin2' }] }]
      },
    ] as VendorViewModel[];

    return data;
  }
}