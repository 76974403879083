import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RadarPostModel, RadarViewModel } from '@models';
import { Component, EventEmitter, OnInit } from '@angular/core';

import { ItxBinDataSourceService } from '../../../services';
import { digitsValidator, ErrorCodeEnum, ErrorModel, ConvertDataFunctions } from '../../../../common';
import { ItxBinViewModel } from './../../../models';
import { ItxRadarLocationEnum, SizeMeasureEnum } from '../../../enums';
import { ItxRadarLocationLabel, SizeMeasureLabel } from './../../../constants';

@Component({
  selector: 'greensleeves-itx-edit-radar-popup',
  templateUrl: './itx-edit-radar-popup.component.html',
  styles: []
})
export class ItxEditRadarPopupComponent implements OnInit {
  _isHidden = true;
  _itxRadarForm: FormGroup;
  _radarIdAddressNotUnique: boolean;
  _radarIdNotUnique: boolean;
  _hasDistanceError: boolean;
  _hasRadarLocationError: boolean;
  _allRadars: RadarViewModel[];
  _submitted: boolean;
  _hasIdErrors: boolean;
  _hasIdAddressErrors: boolean;
  _itxBinViewModel: ItxBinViewModel;
  _radarLocation: ItxRadarLocationEnum;
  _distanceFromBinCenter: number;
  _sizeMeasureLabel = SizeMeasureLabel;
  _radarLocations: { label: string, value: ItxRadarLocationEnum }[];
  _radarIdAddresses: { label: string, value: number }[];

  needToUpdateItxDeviceEnrollment = new EventEmitter();

  private isNeedRadarPosittion: boolean;
  private radar: RadarPostModel;

  get _isSiloOrBinTypes(): boolean {
    return this.isNeedRadarPosittion;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _itxBinDataSouceService: ItxBinDataSourceService) { }

  ngOnInit() {
    this._radarLocations = [];
    for (const [key, value] of Object.entries(ItxRadarLocationLabel)) {
      this._radarLocations.push({ label: value, value: Number(key) });
    }

  }

  async onSubmit() {
    this._submitted = true;
    if (this._itxRadarForm.invalid) {
      if (this._itxRadarForm.controls.radarId.invalid)
        this._hasIdErrors = true;
      if (this._itxRadarForm.controls.radarIdAddress.invalid)
        this._hasIdAddressErrors = true;
      if (this._itxRadarForm.controls.radarLocation.invalid)
        this._hasRadarLocationError = true;
      if (this._itxRadarForm.controls.distanceFromBinCenter.invalid)
        this._hasDistanceError = true;
      return;
    }

    let distance: number = null;
    if (this._itxBinViewModel.radars.some(r => r.radarLocation != null) || this._itxRadarForm.controls.radarLocation.value != null) {
      distance = parseFloat(this._itxRadarForm.controls.distanceFromBinCenter.value);
      if (isNaN(distance) || (distance < 0 || distance > this._itxBinViewModel.width / 2)) {
        this._hasDistanceError = true;
        this._itxRadarForm.controls.distanceFromBinCenter.setErrors({ digitsValidator: true });
        return;
      }
    }

    const value = { ...this._itxRadarForm.value, distanceFromBinCenter: distance, itxBinId: this._itxBinViewModel.id, id: this.radar.id, locationId: this._itxBinViewModel.locationId } as RadarPostModel
    if (!this.checkRadarUniquenessPerLocation(value)) {
      return;
    }
    if (value.distanceFromBinCenter != null) {
      value.distanceFromBinCenter = ConvertDataFunctions.convertSizeToFt(value.distanceFromBinCenter, this._itxBinViewModel.sizeMeasure);
    }

    await this._itxBinDataSouceService.editRadar(value).then((response: RadarViewModel) => {
      if (response instanceof ErrorModel && response.code === ErrorCodeEnum.ItxRadarAlreadyExixtsInThisLocation) {
        this._radarIdAddressNotUnique = true;
        this._radarIdNotUnique = true;
      } else {
        this.needToUpdateItxDeviceEnrollment.emit();
      }
    });

    this.onCancel();
  }

  public show(radar: RadarPostModel, itxBinViewModel: ItxBinViewModel, radarIdAddresses: { label: string, value: number }[], isNeedPosition: boolean) {
    this._isHidden = false;
    this.radar = radar;
    this._radarIdAddresses = radarIdAddresses;
    this._radarIdAddresses.pop();
    this._radarIdAddresses.push({ label: radar.radarIdAddress.toString(), value: radar.radarIdAddress });
    this._radarIdAddresses = this._radarIdAddresses.sort((x, y) => x.value - y.value);
    this._itxBinViewModel = itxBinViewModel;
    this.isNeedRadarPosittion = isNeedPosition;
    if (this.radar.distanceFromBinCenter != null) {
      this.radar.distanceFromBinCenter = ConvertDataFunctions.convertSizeFromFt(this.radar.distanceFromBinCenter, this._itxBinViewModel.sizeMeasure);
    }
    this.initForm();
  }

  onChangeRadarLocationValues() {
    this._itxRadarForm.controls.distanceFromBinCenter.clearValidators();
    this._itxRadarForm.controls.distanceFromBinCenter.setValidators(Validators.compose([
      Validators.required,
    ]));
    this._itxRadarForm.controls.distanceFromBinCenter.updateValueAndValidity();

    this._itxRadarForm.controls.radarLocation.clearValidators();
    this._itxRadarForm.controls.radarLocation.setValidators(Validators.compose([Validators.required]));
    this._itxRadarForm.controls.radarLocation.updateValueAndValidity();

    if (this._itxRadarForm.controls.distanceFromBinCenter.value == '') {
      this._itxRadarForm.controls.distanceFromBinCenter.setValue(null)
    };

    if (this._itxRadarForm.controls.radarLocation.value == null && this._itxRadarForm.controls.distanceFromBinCenter.value == null) {
      this._itxRadarForm.controls.radarLocation.clearValidators();
      this._itxRadarForm.controls.radarLocation.updateValueAndValidity();
      this._itxRadarForm.controls.distanceFromBinCenter.clearValidators();
      this._itxRadarForm.controls.distanceFromBinCenter.updateValueAndValidity();
    }
  }

  private checkRadarUniquenessPerLocation(radar: RadarViewModel): boolean {
    let _allBins = this._itxBinDataSouceService.itxBin$.getValue();
    this._allRadars = _allBins.map(b => b.radars).reduce((a, b) => a.concat(b));
    if (this._allRadars.findIndex(r => r.radarId == radar.radarId && r.id != radar.id) > -1) {
      this._hasIdErrors = true;
      this._radarIdNotUnique = true;
    }
    if (this._allRadars.findIndex(r => r.radarIdAddress == radar.radarIdAddress && r.id != radar.id) > -1) {
      this._hasIdAddressErrors = true;
      this._radarIdAddressNotUnique = true;
    }
    if (this._radarIdNotUnique || this._radarIdAddressNotUnique) {
      return false;
    }
    return true;
  }

  onCancel() {
    this._isHidden = true;
    this._submitted = false;
    this.clearErrors();
    this._itxRadarForm.reset();
  }

  private initForm() {
    this._itxRadarForm = this._formBuilder.group({
      radarIdAddress: [this.radar.radarIdAddress, Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(65535),
        digitsValidator
      ])],
      radarId: [this.radar.radarId, Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(65535),
        digitsValidator
      ])],
      radarLocation: [this.radar.radarLocation],
      distanceFromBinCenter: [this.radar.distanceFromBinCenter],
    });
    this._itxRadarForm.controls.radarId.valueChanges.subscribe(() => {
      this._hasIdErrors = false;
      this._radarIdNotUnique = false;
    })
    this._itxRadarForm.controls.radarIdAddress.valueChanges.subscribe(() => {
      this._hasIdAddressErrors = false;
      this._radarIdAddressNotUnique = false;
    });
    this._itxRadarForm.controls.distanceFromBinCenter.valueChanges.subscribe(() => {
      this._hasDistanceError = false;
    });
    this._itxRadarForm.controls.radarLocation.valueChanges.subscribe(() => {
      this._hasRadarLocationError = false;
    });

    if (this._itxBinViewModel.radars.some(r => r.radarLocation != null)) {
      this._itxRadarForm.controls.radarLocation.setValidators(Validators.compose([Validators.required]));
      this._itxRadarForm.controls.distanceFromBinCenter.setValidators(Validators.compose([Validators.required,
      ]));
    }
  }

  private clearErrors() {
    this._hasIdErrors = false;
    this._hasIdAddressErrors = false;
    this._hasRadarLocationError = false;
    this._hasDistanceError = false;
  }
}
