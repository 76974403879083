import { UserStatusEnum } from "../enums";

export class CompanyUserViewModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public arduinoLocations: Array<UserLocation>;
    public arduinoLocationNames: string[];
    public raspberryLocations: Array<UserLocation>;
    public raspberryLocationNames: string[];
    public itxLocations: Array<UserLocation>;
    public itxLocationsNames: string[];
    public email: string;
    public status: UserStatusEnum;
    public lastLoginDate: Date;

    //User behavior lock fields
    public isSomeoneUpdateCompanyUser: boolean;
}

export class UserLocation {
    id: number;
    name: string;
}

export class CompanyUserInviteModel {
    email: string;
    ktxLocationIds: Array<number>;
    btxLocationIds: Array<number>;
    itxLocationIds: Array<number>;

}

export class CompanyUserPostModel {
    id: number;
    firstName: string;
    lastName: string;
    ktxLocationIds: Array<number>;
    btxLocationIds: Array<number>;
    itxLocationIds: Array<number>;
    status: UserStatusEnum;
}


export class CompanyUserGetModel {
    id: number;
    firstName: string;
    lastName: string;
    arduinoLocations: Array<UserLocation>;
    raspberryLocations: Array<UserLocation>;
    itxLocations: Array<UserLocation>;
    email: string;
    status: UserStatusEnum;
    lastLoginDate: Date;

    public static toViewModel(getModel: CompanyUserGetModel): CompanyUserViewModel {
        const viewModel = new CompanyUserViewModel();
        viewModel.id = getModel.id;
        viewModel.firstName = getModel.firstName;
        viewModel.lastName = getModel.lastName;
        viewModel.arduinoLocations = getModel.arduinoLocations;
        viewModel.raspberryLocations = getModel.raspberryLocations;
        viewModel.itxLocations= getModel.itxLocations;
        viewModel.email = getModel.email;
        viewModel.status = getModel.status;
        viewModel.lastLoginDate = getModel.lastLoginDate;

        viewModel.arduinoLocationNames = viewModel.arduinoLocations.map(l => l.name);
        viewModel.raspberryLocationNames = viewModel.raspberryLocations.map(l => l.name);
        viewModel.itxLocationsNames=viewModel.itxLocations.map(l=>l.name);

        return viewModel;
    }
}