import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, NgZone, Output, EventEmitter, ViewChild } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ColumnTypeEnum } from '../../../enums';
import { LocationViewModel } from '../../../models';
import { LocationsDataSourceService, RoleService } from '../../../services';
import { EquipmentPopupComponent, LocationDeletePopupComponent } from '../../popups';
import { UserRoleEnum } from '../../../../common'

@Component({
  selector: 'greensleeves-locations-grid',
  templateUrl: './locations-grid.component.html'
})

export class LocationsGridComponent implements OnInit, OnDestroy, AfterViewInit {
  static LOCATION_NAME_KEY = 'name';
  static COMPANY_NAME_KEY = 'companyName';
  static SORT_ORDER_DEFAULT = 1;

  @ViewChild(EquipmentPopupComponent, { read: false, static: false })
  _equipmentPopup: EquipmentPopupComponent;

  @ViewChild(LocationDeletePopupComponent, { read: false, static: false })
  _locationDeletePopup: LocationDeletePopupComponent;

  @Output()
  onEditClicked = new EventEmitter<LocationViewModel>();

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = LocationsGridComponent.LOCATION_NAME_KEY;
  _sortOrder = LocationsGridComponent.SORT_ORDER_DEFAULT;
  _gridData: LocationViewModel[];
  _companyNameKey = LocationsGridComponent.COMPANY_NAME_KEY;
  _locationNameKey = LocationsGridComponent.LOCATION_NAME_KEY;
  _searchPhrase: string = '';
  _ps: PerfectScrollbar;
  _initializeDeviceKey = 'isInitialized';

  _columnsView = [
    { header: 'Location name', columnType: ColumnTypeEnum.LocationName, dataField: LocationsGridComponent.LOCATION_NAME_KEY },
    { header: 'Location address', columnType: ColumnTypeEnum.LocationAddress, dataField: 'address' },
    { header: 'Service email', columnType: ColumnTypeEnum.ServiceEmail, dataField: 'serviceEmail' },
    { header: 'Service phone', columnType: ColumnTypeEnum.ServicePhone, dataField: 'servicePhone' },
    { header: 'Emergency phone', columnType: ColumnTypeEnum.EmergencyPhone, dataField: 'emergencyPhone' },
    { header: 'Site phone', columnType: ColumnTypeEnum.SitePhone, dataField: 'sitePhone' },
    { header: 'Site email', columnType: ColumnTypeEnum.SiteEmail, dataField: 'siteEmail' },
  ]

  _frozenColumns = [
    { header: '', columnType: ColumnTypeEnum.Edit, width: 46 }
  ]

  _locationsSubscription: Subscription;
  _locationsErrorsSubscription: Subscription;

  _searchForm: FormGroup;
  _lastSearchPhrase: string;

  _initLoaders: { [key: number]: boolean } = {};

  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  constructor(
    private _locationsDataSourceService: LocationsDataSourceService,
    private _el: ElementRef,
    private _zone: NgZone,
    private _roleService: RoleService,
    formBuilder: FormBuilder) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }

  ngOnInit() {
    this.reinitScrollBar(0);
    this._locationsSubscription = this._locationsDataSourceService.locations$.subscribe(items => {
      this._gridData = items;
    });
    this._locationsDataSourceService.get();

    if (this._roleService.userHasRole(UserRoleEnum.ServiceUser)) {
      const companyColomn = { header: 'Company', columnType: ColumnTypeEnum.Company, dataField: LocationsGridComponent.COMPANY_NAME_KEY };
      const deleteIcon = { header: '', columnType: ColumnTypeEnum.Delete, width: 46 };

      this._columnsView.splice(1, 0, companyColomn);
      this._frozenColumns.push(deleteIcon);
    }
  }

  ngOnDestroy() {
    this._locationsSubscription && this._locationsSubscription.unsubscribe();
    this._locationsSubscription = null;

    this._locationsErrorsSubscription && this._locationsErrorsSubscription.unsubscribe();
    this._locationsErrorsSubscription = null;

    this._ps && this._ps.destroy();
    this._ps = null;
  }

  ngAfterViewInit() {
    this.reinitScrollBar(0);
  }

  async onEdit(location: LocationViewModel) {
    await this._locationsDataSourceService.markLocationForOthersAsInUpdate(location.id);
    this.onEditClicked.emit(location);
  }

  reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  _updateScroll() {
    this._ps && this._ps.update();
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
  }

  async onClickDelete(location: LocationViewModel) {
    if (!location || location.hasActiveDevice) {
      return;
    }

    this._locationDeletePopup.show(location);
  }
}
