import { FormControl } from '@angular/forms';

export function digitsValidator(control: FormControl) {
    return /^[0-9]*$/.test(control.value) ? null : { 'digitsValidator': 'Should contain only digits' };
}

export function digitsFractionalValidator(control: FormControl) {
    return /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/.test(control.value) ? null : { 'digitsValidator': 'Should contain only digits' };
}

export function emailValidator(options: { message: string, maxNameLength?: number, maxDomainLength?: number } = null ) {
    const { message, maxNameLength, maxDomainLength } = {
        ...{ message: 'Email not valid', maxDomainLength: 255, maxNameLength: 64 },
        ...options
    };
    const regexString = `^(?=^.${maxNameLength > 0 ? `{1,${maxNameLength}}` : '*'}@.${maxDomainLength > 0 ? `{1,${maxDomainLength}}` : '*'}$)(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`;
    const regexp = new RegExp(regexString);
    return (control: FormControl) => {
        return regexp.test(control.value) ? null : { 'emailValidator': message };
    }
}

export function checkboxValidator(control: FormControl) {
    return control.value && control.value.some(() => true) ? null : {'checkboxValidator': 'At least one checkbox should be picked'};
}

export function ipValidator(control: FormControl) {
    const regexp = new RegExp('^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[1-9]).(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]).(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]).(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$'
    );
    return regexp.test(control.value) ? null : {'ipValidator': 'Incorrect IP format!'}
}