import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ItxBinDataSourceService } from '@services';
import { UserPreferences, UserPreferencesPost } from './../../../models';
import { CapacityUnitEnum, SizeMeasureEnum, TemperatureMeasureEnum } from './../../../enums';
import { CapacityUnitLiquidLabel, SizeMeasureLabel, TemperatureMeasureLabel,CapacityUnitLoosLabel } from 'src/app/main/constants';

@Component({
    selector: 'greensleeves-itx-live-data-setting-popup',
    templateUrl: './itx-live-data-setting-popup.component.html',
    styles: []
})
export class ItxLiveDataSettingPopupComponent implements OnInit{
    _isHidden = true;
    _itxUserPreferencesForm: FormGroup;
    _submitted: boolean;
    
    subscriptions: Subscription[] = [];

    _headSpace: { label: string, value: SizeMeasureEnum }[] = [];
    _selectedHeadSpace: SizeMeasureEnum;

    _liquidCapacity: { label: string, value: CapacityUnitEnum }[] = [];
    _selectedLiquidCapacity: CapacityUnitEnum;

    _bulkCapacity: { label: string, value: CapacityUnitEnum }[] = [];
    _selectedBulkCapacity: CapacityUnitEnum;

    _ambientTemperature: { label: string, value: TemperatureMeasureEnum }[] = [];
    _selectedAmbientTemperature: TemperatureMeasureEnum;

    constructor(private _itxBinDataSerivece: ItxBinDataSourceService,
        private formBuilder: FormBuilder,) { }
  ngOnInit(): void {
    this.setUnitLabels();
    this.initForm();
  }

  private setUnitLabels(){
    for (const [key, value] of Object.entries(CapacityUnitLiquidLabel)) {
      this._liquidCapacity.push({ label: value, value: Number(key) });
    }
    for (const [key, value] of Object.entries(CapacityUnitLoosLabel)) {
        this._bulkCapacity.push({ label: value, value: Number(key) });
    }
    for (const [key, value] of Object.entries(TemperatureMeasureLabel)) {
        this._ambientTemperature.push({ label: value, value: Number(key) });
    }
    for (const [key, value] of Object.entries(SizeMeasureLabel)) {
        this._headSpace.push({ label: value, value: Number(key) });
    }
  }
    
    public show(userPreferences: UserPreferences) {
        this._selectedAmbientTemperature = userPreferences.temperatureMeasure;
        this._selectedBulkCapacity = userPreferences.capacityLoosUnit;
        this._selectedLiquidCapacity = userPreferences.capacityLiquidUnit;
        this._selectedHeadSpace = userPreferences.sizeMeasure;
      this.initForm();
        this._isHidden = false;
    }

    onClickCancel() {
       this._isHidden = true;
       this.resetForm();
       this._submitted = false;
    }
    async onSubmit() {
        this._submitted = true;
        if (this._itxUserPreferencesForm.invalid) {
            return;
        }

        let userPref = new UserPreferencesPost();
        userPref.temperatureMeasure = this._selectedAmbientTemperature;
        userPref.sizeMeasure = this._selectedHeadSpace;
        userPref.capacityLoosUnit = this._selectedBulkCapacity;
        userPref.capacityLiquidUnit = this._selectedLiquidCapacity;
       await this._itxBinDataSerivece.setUserPreferences(userPref);

       this._isHidden = true;
       this._submitted = false;
       this.resetForm();
    }

    initForm(){
      this._itxUserPreferencesForm = this.formBuilder.group({
        headSpace: [this._selectedHeadSpace, Validators.compose([])],
        bulkCapacity: [this._selectedBulkCapacity, Validators.compose([])],
        liquidCapacity: [this._selectedLiquidCapacity, Validators.compose([])],
        ambientTemperature: [this._selectedAmbientTemperature, Validators.compose([])],
      });
    }

    private resetForm() {
      this._itxUserPreferencesForm.reset();
    }
}
