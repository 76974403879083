import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CompleteRegistrationComponent } from './complete-registration.component';
import { RoutePaths } from '../common';

@NgModule({
  declarations: [
    CompleteRegistrationComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      { path: RoutePaths.COMPLETE_REGISTRATION, component: CompleteRegistrationComponent },
    ])
  ]
})

export class CompleteRegistrationModule { }
