import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { ItxBinDataSourceService } from '../../../services';
import { AddItxBinModel, ItxBinViewModel, EditItxBinModel } from '../../../models';
import { CapacityUnitEnum, ItxBinTypeEnum, SizeMeasureEnum } from '../../../enums';
import { ConvertDataFunctions, digitsFractionalValidator } from '../../../../common';
import { BasePopupBehavior, } from '../common';
import {
  CapacityUnitLiquidLabel, CapacityUnitLoosLabel, ItxBinIconClass, ItxBinTypeLabel, SizeMeasureLabel,
  LiquidStorageTypes, FlatStorageTypes, LiquidCapacityUnit
} from '../../../constants';

@Component({
  selector: 'greensleeves-itx-bin-add-edit-popup',
  templateUrl: './itx-bin-add-edit-popup.component.html',
  styles: []
})
export class ItxBinAddEditPopupComponent extends BasePopupBehavior implements OnInit, AfterViewChecked {
  _isHidden = true;
  _isAddPopup = true;
  _storageForm: FormGroup;
  _location: { id: number, name: string };
  _selectedItxBinType: ItxBinTypeEnum;
  _itxBinTypes: { label: string, value: ItxBinTypeEnum }[] = [];
  _capacityUnit: any;
  _selectedCapacityUnit: CapacityUnitEnum;
  capacityUnitLiquid: { label: string, value: CapacityUnitEnum }[] = [];
  capacityUnitLoos: { label: string, value: CapacityUnitEnum }[] = [];
  _sizeMeasure: { label: string, value: SizeMeasureEnum }[] = [];
  _selectedSizeMeasure: SizeMeasureEnum;
  _submitted = false;
  itxBin: ItxBinViewModel;
  _disableSubmit = false;

  get _isSelectedBinType() {
    return this._selectedItxBinType != undefined;
  }

  get _isSelectedFlatStorage() {
    return FlatStorageTypes.includes(this._selectedItxBinType);
  }

  get _isSelectedCapacityUnit() {
    return this._selectedCapacityUnit != undefined;
  }

  get _isBinOrSiloStorage(): boolean {
    if (this._selectedItxBinType != null && this._selectedItxBinType != undefined) {
      return this._selectedItxBinType == ItxBinTypeEnum.BinConical || this._selectedItxBinType == ItxBinTypeEnum.BinFlat || this._selectedItxBinType == ItxBinTypeEnum.BinSloped ||
        this._selectedItxBinType == ItxBinTypeEnum.SiloConical || this._selectedItxBinType == ItxBinTypeEnum.SiloFlat || this._selectedItxBinType == ItxBinTypeEnum.SiloSloped;
    }
    return false
  }

  constructor(private _formBuilder: FormBuilder,
    private _itxBinDataService: ItxBinDataSourceService
  ) {
    super();
  }

  ngOnInit() {
    for (const [key, value] of Object.entries(ItxBinTypeLabel)) {
      this._itxBinTypes.push({ label: value, value: Number(key) });
    }

    for (const [key, value] of Object.entries(SizeMeasureLabel)) {
      this._sizeMeasure.push({ label: value, value: Number(key) });
    }

    for (const [key, value] of Object.entries(CapacityUnitLiquidLabel)) {
      this.capacityUnitLiquid.push({ label: value, value: Number(key) });
    }

    for (const [key, value] of Object.entries(CapacityUnitLoosLabel)) {
      this.capacityUnitLoos.push({ label: value, value: Number(key) });
    }
  }

  ngAfterViewChecked() {
    super.ngAfterViewChecked();
  }

  public showAdd(location: any) {
    this._isAddPopup = true;
    this._location = location;
    this.initFrom();
    this._isHidden = false;
  }

  public showEdit(itxBin: ItxBinViewModel) {
    this._isAddPopup = false;
    this.itxBin = itxBin;
    this._itxBinDataService.markItxBinForOthersAsInUpdate(this.itxBin ? this.itxBin.id : 0);
    this.initFrom();
    this._isHidden = false;
  }

  onChangeBinType() {
    this.manageDepthControl();
    this.manageCapacityUnitControl();
  }

  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  onCancel() {
    this._isHidden = true;
    this._submitted = false;
    this.resetForm();
    if (!this._isAddPopup) {
      this._itxBinDataService.markItxBinForOthersAsUpdated(this.itxBin, false);
      this.itxBin = null;
    }
  }

  async onSubmit() {
    this._submitted = true;
    if (this._storageForm.invalid) {
      return;
    }

    if (!this._isSelectedBinType || !this._isSelectedCapacityUnit) {
      return;
    }

    if (this._isBinOrSiloStorage && this.checkErrorRoofAngleAndCap()) {
      return;
    }

    let itxBin = this._isAddPopup ? new AddItxBinModel({
      locationId: this._location.id,
      ...this._storageForm.value,
    } as AddItxBinModel) :
      new EditItxBinModel({
        id: this.itxBin.id,
        ...this._storageForm.value,
      } as EditItxBinModel);
    itxBin.depth = this.convertSizeValueToFt(itxBin.depth, itxBin.sizeMeasure);
    itxBin.width = this.convertSizeValueToFt(itxBin.width, itxBin.sizeMeasure);
    itxBin.height = this.convertSizeValueToFt(itxBin.height, itxBin.sizeMeasure);
    itxBin.totalCapacity = this.convertCapacityValueToDefaultUnit(itxBin.totalCapacity, itxBin.capacityUnit, itxBin.binType);
    if (itxBin.roofCap != null) {
      itxBin.roofCap = this.convertSizeValueToFt(itxBin.roofCap, itxBin.sizeMeasure);
    }

    this._disableSubmit = true;
    const result = this._isAddPopup ? await this._itxBinDataService.add(itxBin as AddItxBinModel)
      : await this._itxBinDataService.edit(itxBin as EditItxBinModel);
    if (result) {
      if (!this._isAddPopup) {
        await this._itxBinDataService.markItxBinForOthersAsUpdated(result as ItxBinViewModel, true);
        this.itxBin = null;
      }
      this._isHidden = true;
      this._submitted = false;
      this.resetForm();
    }

    this._disableSubmit = false;
  }

  private convertSizeValueToFt(value: number, sizeUnit: SizeMeasureEnum): number {
    let result = ConvertDataFunctions.convertSizeToFt(value, sizeUnit);
    return result;
  }

  private convertCapacityValueToDefaultUnit(value: number, capacityUnit: CapacityUnitEnum, binType: ItxBinTypeEnum): number {
    let result: number;
    switch (binType) {
      case ItxBinTypeEnum.LiquidStorageConical:
      case ItxBinTypeEnum.LiquidStorageFlat:
      case ItxBinTypeEnum.LiquidStorageSloped:
        result = ConvertDataFunctions.convertToGal(value, capacityUnit);
        break;
      default:
        result = ConvertDataFunctions.convertToFt3(value, capacityUnit);
    }
    return result;
  }

  getBinClass(binType: ItxBinTypeEnum = this.itxBin ? this.itxBin.binType : null): string {
    return ItxBinIconClass.getIconClass(binType);
  }

  private manageDepthControl() {
    if (this._isSelectedFlatStorage) {
      this._storageForm.controls.depth.setValidators(Validators.compose([
        Validators.required,
        digitsFractionalValidator,
        Validators.min(1)
      ]));
      this._storageForm.controls.depth.enable();
    } else {
      this._storageForm.controls.depth.reset();
      this._storageForm.controls.depth.clearValidators();
      this._storageForm.controls.depth.disable();
    }
  }

  private manageCapacityUnitControl() {
    if (LiquidStorageTypes.includes(this._selectedItxBinType)) {
      if (!LiquidCapacityUnit.includes(this._selectedCapacityUnit)) {
        this._capacityUnit = this.capacityUnitLiquid;
        this._storageForm.controls.capacityUnit.reset();
        this._storageForm.controls.roofAngle.setValue(null);
        this._storageForm.controls.roofCap.setValue(null);
      }
    }
    else {
      if (LiquidCapacityUnit.includes(this._selectedCapacityUnit)) {
        this._capacityUnit = this.capacityUnitLoos;
        this._storageForm.controls.capacityUnit.reset();
      }
    }
  }

  private initFrom() {
    this._selectedItxBinType = this.itxBin ? this.itxBin.binType : null;
    this._selectedSizeMeasure = this.itxBin ? this.itxBin.sizeMeasure : SizeMeasureEnum.Ft;
    this._selectedCapacityUnit = this.itxBin ? this.itxBin.capacityUnit : null;
    this._capacityUnit = LiquidStorageTypes.includes(this._selectedItxBinType) ? this.capacityUnitLiquid : this.capacityUnitLoos;
    this._storageForm = this._formBuilder.group({
      name: [this.itxBin ? this.itxBin.name : null, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ])],
      totalCapacity: [this.itxBin ? this.itxBin.totalCapacity : null, Validators.compose([
        Validators.required,
        digitsFractionalValidator,
        Validators.min(1)
      ])],
      material: [this.itxBin ? this.itxBin.material : null, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ])],
      binType: [this._selectedItxBinType, Validators.compose([])],
      tiltAngle: [this.itxBin ? this.itxBin.tiltAngle : null, Validators.compose([
        Validators.required,
        digitsFractionalValidator,
        Validators.min(0),
        Validators.max(90)
      ])],
      height: [this.itxBin ? this.itxBin.height : null, Validators.compose([
        Validators.required,
        digitsFractionalValidator,
        Validators.min(0)
      ])],
      width: [this.itxBin ? this.itxBin.width : null, Validators.compose([
        Validators.required,
        digitsFractionalValidator,
        Validators.min(0)
      ])],
      depth: [{ value: this.itxBin ? this.itxBin.depth : null, disabled: !this._isSelectedFlatStorage }],
      sizeMeasure: [this._selectedSizeMeasure, Validators.compose([])],
      capacityUnit: [this._selectedCapacityUnit, Validators.compose([])],
      roofAngle: [this.itxBin ? this.itxBin.roofAngle : null],
      roofCap: [this.itxBin ? this.itxBin.roofCap : null],
    });
  }

  private resetForm() {
    this._storageForm.reset();
    this._selectedSizeMeasure = SizeMeasureEnum.Ft;
    this._capacityUnit = this.capacityUnitLoos;
    this._disableSubmit = false;
  }

  private checkErrorRoofAngleAndCap(): boolean {
    if ((this._storageForm.controls.roofAngle.value == null || this._storageForm.controls.roofAngle.value == '') && (this._storageForm.controls.roofCap.value == null || this._storageForm.controls.roofCap.value == '')) {
      return false;
    }

    if (this._storageForm.controls.roofAngle.value != null && isNaN(parseInt(this._storageForm.controls.roofAngle.value))) {
      this._storageForm.controls.roofAngle.setErrors({ min: true });
      return true;
    }

    if (this._storageForm.controls.roofAngle.value != null && isNaN(parseInt(this._storageForm.controls.roofCap.value))) {
      this._storageForm.controls.roofCap.setErrors({ min: true });
      return true;
    }

    if (this._storageForm.controls.roofAngle.value != null && (this._storageForm.controls.roofCap.value == null || this._storageForm.controls.roofCap.value == '')) {
      this._storageForm.controls.roofCap.setErrors({ required: true });
      return true;
    }
    if (this._storageForm.controls.roofCap.value != null && (this._storageForm.controls.roofAngle.value == null || this._storageForm.controls.roofAngle.value == '')) {
      this._storageForm.controls.roofAngle.setErrors({ required: true });
      return true;
    }
    if (this._storageForm.controls.roofCap.value != null && parseInt(this._storageForm.controls.roofCap.value) > parseInt(this._storageForm.controls.width.value)) {
      this._storageForm.controls.roofCap.setErrors({ max: true });
      return true;
    }


    return false;
  }
}
