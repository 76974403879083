import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConvertDataFunctions, ErrorModel } from '../../../../common';
import { CapacityUnitEnum, ColumnTypeEnum, ItxBinTypeEnum, ItxRadarLocationEnum, SizeMeasureEnum } from '../../../enums';
import { ItxBinDataSourceService } from '../../../services';
import { PileShapeLabel } from './../../../constants';
import { CapacityUnitLabel, ItxRadarLocationLabel, SizeMeasureLabel } from './../../../constants';
import { PileShale } from './../../../enums';
import { ItxBinLiveDataModel, ItxBinLiveDataViewModel, RadarLiveDataModel } from './../../../models';
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'greensleeves-itx-storage-radars-popup',
  templateUrl: './itx-storage-radars-popup.component.html',
  styles: []
})
export class ItxStorageRadarsPopupComponent implements OnInit, OnDestroy {
  _isHidden = true;
  _binName: string;
  _binId: number = 0;
  _hasRadarError: boolean = false;
  allRadars: RadarLiveDataModel[];
  _gridData: RadarLiveDataModel[];
  _columnTypeEnum = ColumnTypeEnum;
  _columnsView = [];
  _calculatedAvailableCapacity: number;
  _calculatedUsedCapacity: number;
  _ps: PerfectScrollbar;
  private calculatedErrorBound: number;
  private sizeUnit: SizeMeasureEnum;
  private capacityUnit: CapacityUnitEnum;
  private binType: ItxBinTypeEnum;
  private radarLocationLabel = ItxRadarLocationLabel;
  private capacityUnitLabel = CapacityUnitLabel;
  private subscriptions: Subscription[] = [];
  private sizeMeasureLabel = SizeMeasureLabel;
  private pileShapeLabel = PileShapeLabel;
  _lastUpdate: string;
  _pileShape: string = null;
  _loading: boolean = false;
  _errorLoading: boolean = false;

  constructor(
    private _itxBinDataSouceService: ItxBinDataSourceService,
    private _el: ElementRef,
    private _zone: NgZone,
  ) { }

  calculateRadarCapacityValue(value: number, capacityUnit: CapacityUnitEnum, binType: ItxBinTypeEnum): number {
    let result: number;
    switch (binType) {
      case ItxBinTypeEnum.LiquidStorageConical:
      case ItxBinTypeEnum.LiquidStorageFlat:
      case ItxBinTypeEnum.LiquidStorageSloped:
        result = ConvertDataFunctions.convertFromGal(value, capacityUnit);
        break;
      default:
        result = ConvertDataFunctions.convertFromFt3(value, capacityUnit);
    }
    return result;
  }

  ngOnInit() {
    let itxSub = this._itxBinDataSouceService.itxBinLiveData$.subscribe(items => {
      let currentStorage = items.find(bin => bin.id == this._binId);
      if (currentStorage) {
        let radars = currentStorage.radars.map(r => {
          return this.fillRadarValues(r);
        });

        this._gridData = radars as RadarLiveDataModel[];
        this._hasRadarError = this._gridData.some(r => r.isRadarTelemetryError);
        this._lastUpdate = currentStorage.timestamp;
        if (this._gridData.length > 0 && !this._hasRadarError) {
          let radar = this._gridData.find(e => !e.isRadarTelemetryError);
          if (radar.pileShape != null) {
            this._pileShape = this.getPileShapeLabel(radar.pileShape);
            this._pileShape += radar.fillingStatus ? ' ↑' : ' ↓';
          }
        }

        this._calculatedAvailableCapacity = currentStorage.calculatedAvailableCapacity;
        this.calculatedErrorBound = currentStorage.calculatedErrorBound;
        this._calculatedUsedCapacity = currentStorage.calculatedUsedCapacity;
      }
    });

    this.subscriptions.push(itxSub);
  }

  ngOnDestroy() {
    this.subscriptions &&
      this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public show(storage: ItxBinLiveDataViewModel) {
    this.sizeUnit = storage.sizeUnit;
    this.capacityUnit = storage.capacityUnit;
    this.binType = storage.binType;
    this._binName = storage.name;
    this._binId = storage.id;
    this._calculatedAvailableCapacity = storage.calculatedAvailableCapacity;
    this.calculatedErrorBound = storage.calculatedErrorBound;
    this._calculatedUsedCapacity = storage.calculatedUsedCapacity;
    let radars = storage.radars.map(r => {
      return this.fillRadarValues(r);
    });
    this._lastUpdate = storage.timestamp;
    this._gridData = radars as RadarLiveDataModel[];
    this._isHidden = false;
    this._hasRadarError = this._gridData.some(r => r.isRadarTelemetryError);
    if (this._gridData.length > 0 && !this._hasRadarError) {
      let radar = this._gridData.find(e => !e.isRadarTelemetryError);
      if (radar.pileShape != null) {
        this._pileShape = this.getPileShapeLabel(radar.pileShape);
        this._pileShape += radar.fillingStatus ? ' ↑' : ' ↓';
      }
    }

    this.initColumView();
    this._loading = false;
  }

  public async showWithLoadTelemetry(binId: number) {
    this._loading = true;
    this._isHidden = false;
    this._binName = 'Loading...';
    this._itxBinDataSouceService.getRadarTelemetryWithReferences(binId).then(value => {
      if (value instanceof ItxBinLiveDataViewModel) {
        this.show(value);
      }
      else if (value instanceof ErrorModel) {
        this._errorLoading = true;
      }
      this._loading = false;
    });
  }

  onCancel() {
    this._pileShape = null;
    this._isHidden = true;
    this._columnsView = [];
    this._lastUpdate = null;
    this._errorLoading = false;
  }

  getRadarDistanceWithLabel(distance: number) {
    if (distance != null) {
      return `${ConvertDataFunctions.roundAndUsString(distance, 2)} ${this.sizeMeasureLabel[this.sizeUnit]}`;
    }
    return '-';
  }

  getRadarLocationLabel(radarLocation: ItxRadarLocationEnum): string {
    if (radarLocation != null) {
      return this.radarLocationLabel[radarLocation];
    }
    return '-';
  }

  getRequireRadarLocation(): boolean {
    if (this.binType != null && this.binType != undefined) {
      return this.binType == ItxBinTypeEnum.BinConical || this.binType == ItxBinTypeEnum.BinFlat || this.binType == ItxBinTypeEnum.BinSloped ||
        this.binType == ItxBinTypeEnum.SiloConical || this.binType == ItxBinTypeEnum.SiloFlat || this.binType == ItxBinTypeEnum.SiloSloped;
    }
    return false
  }

  getCapacityWithLabel(capacity: number): string {
    let capacityString = (Math.round(capacity * 100) / 100).toLocaleString('en-Us');
    return `${capacityString} ${this.capacityUnitLabel[this.capacityUnit]}`;
  }

  getPileShapeLabel(pileShape: PileShale): string {
    if (pileShape != null) {
      return this.pileShapeLabel[pileShape];
    }
    return '-';
  }

  getCalculatedCapasityString(calculatedAvailableCapacity: number, calculatedUsedCapacity: number, isUsedCapacity: boolean): string {
    if (this.getRequireCalculationValue() && calculatedAvailableCapacity != 0 && calculatedUsedCapacity != 0) {
      let cap = this.calculateRadarCapacityValue(isUsedCapacity ? calculatedUsedCapacity : calculatedAvailableCapacity, this.capacityUnit, this.binType);
      let capStr = `${(Math.round(cap * 100) / 100).toLocaleString('en-Us')} ${CapacityUnitLabel[this.capacityUnit]}`;
      if (isUsedCapacity) {
        capStr += this.getErrorBoundString(this.calculatedErrorBound);
      }
      return capStr;
    }
    return '-';
  }

  reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector(
            '.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body'
          );
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20,
          });
        } catch (error) { }
      });
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  private getRequireCalculationValue(): boolean {
    return this.getRequireRadarLocation() && this._gridData && this._gridData.length >= 3;
  }

  private getErrorBoundString(errorBound: number): string {
    if (errorBound != null) {
      let errorString = (Math.round(errorBound * 10) / 10).toString();
      return ` ±${errorString}%`;
    }
    return '';
  }

  private fillRadarValues(radar: RadarLiveDataModel): RadarLiveDataModel {
    let r = new RadarLiveDataModel();
    r.radarId = radar.radarId;
    r.headSpace = this.calculateRadarSizeValue(radar.headSpace, this.sizeUnit);
    r.percentFull = radar.percentFull;
    r.isRadarTelemetryError = radar.isRadarTelemetryError;
    r.capacity = this.calculateRadarCapacityValue(radar.capacity, this.capacityUnit, this.binType);
    radar.distanceFromBinCenter != null ? r.distanceFromBinCenter = this.calculateRadarSizeValue(radar.distanceFromBinCenter, this.sizeUnit) : r.distanceFromBinCenter = radar.distanceFromBinCenter;
    r.radarLocation = radar.radarLocation;
    r.pileShape = radar.pileShape;
    r.fillingStatus = radar.fillingStatus;
    return r;
  }

  private calculateRadarSizeValue(value: number, sizeUnit: SizeMeasureEnum): number {
    let result = ConvertDataFunctions.convertSizeFromFt(value, sizeUnit);
    return result;
  }

  private initColumView() {
    this._columnsView.push({ header: 'Radar ID:', dataField: 'radarId', columnType: ColumnTypeEnum.Number });
    if (this.getRequireRadarLocation()) {
      this._columnsView.push({ header: 'Radar location:', dataField: 'radarLocation', columnType: ColumnTypeEnum.RadarLocation });
      this._columnsView.push({ header: 'Distance:', dataField: 'distanceFromBinCenter', columnType: ColumnTypeEnum.RadarDistance });
    }

    this._columnsView.push({ header: 'Percent full:', dataField: 'percentFull', columnType: ColumnTypeEnum.Number });
    this._columnsView.push({ header: 'Head space:', dataField: 'headSpace', columnType: ColumnTypeEnum.HeadSpace });
    this._columnsView.push({ header: 'Used capacity:', dataField: 'capacity', columnType: ColumnTypeEnum.CapacityUnit });
  }
}
