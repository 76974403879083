export class ServiceUserViewModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public lastLoginDate: Date;

    //User behavior lock fields
    public isSomeoneUpdateServiceUser: boolean;
}

export class ServiceUserInviteModel {
    email: string;

    public static toViewModel(inviteModel: ServiceUserInviteModel): ServiceUserViewModel {
        const viewModel = new ServiceUserViewModel();
        viewModel.email = inviteModel.email;

        return viewModel;
    }
}

export class ServiceUserPostModel {
    id: number;
    firstName: string;
    lastName: string;
}

export class ServiceUserDeleteModel {
    id: number;
}

export class ServiceUserGetModel {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    lastLoginDate: Date;

    public static toViewModel(getModel: ServiceUserGetModel): ServiceUserViewModel {
        const viewModel = new ServiceUserViewModel();
        viewModel.id = getModel.id;
        viewModel.firstName = getModel.firstName;
        viewModel.lastName = getModel.lastName;
        viewModel.email = getModel.email;
        viewModel.lastLoginDate = getModel.lastLoginDate;

        return viewModel;
    }
}