export class FullnessRangeViewModel {
    id: number;
    from: number;
    to: number;
    colorHex: string;
}

export class FullnessRangePostModel {
    public locationId: number;
    public ranges: FullnessRangeViewModel[];
}
export class FullnessRangesGetModel {
    public locationId: number;
    public fullnessRanges: FullnessRangeViewModel[];
    public canDeviceBeInitialized: boolean;
    public isInitialized: boolean;
}

