import { Injectable } from '@angular/core';

import { LocalStorageKeys } from '../../constants';
import { LoginViewModel } from '../../models';

@Injectable()
export class LocalStorageService {
  constructor() {}

  public getLoginInfo(): LoginViewModel {
    const valueToReturn = localStorage.getItem(LocalStorageKeys.LOGIN_INFO);
    if (valueToReturn !== null) {
      const toReturn = JSON.parse(valueToReturn);
      return <LoginViewModel>toReturn;
    }

    return null;
  }

  public setLoginInfo(value: LoginViewModel) {
    const valueToSave = JSON.stringify(value);
    localStorage.setItem(LocalStorageKeys.LOGIN_INFO, valueToSave);
    this.saveAuthToken(value.authToken);
  }

  public getAuthToken(): string {
    const valueToReturn = localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);
    if (valueToReturn !== null) {
      return JSON.parse(valueToReturn);
    }

    return null;
  }

  public setAuthToken(value: string) {
    this.saveAuthToken(value);
  }

  public clear() {
    localStorage.clear();
  }

  private saveAuthToken(value: string) {
    const valueToSave = JSON.stringify(value);
    localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, valueToSave);
  }
}