import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AccountApiService } from '../http';
import { ErrorModel } from './../../../common';
import { AccountProfileModel } from './../../models';

@Injectable({
  providedIn: 'root'
})
export class AccountDatasourceService {
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  constructor(private _accountApi: AccountApiService) { }

  public getProfile() {
    try {
      let result = this._accountApi.getProfile();
      if (result) {
        this.errors$.next(null);
        return result;
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async editProfile(profile: AccountProfileModel) {
    try {
      let result = this._accountApi.editProfile(profile);
      this.errors$.next(null);
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }
}
