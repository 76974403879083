import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ColumnTypeEnum, UserStatusEnum } from '../../../enums';
import { ServiceUserViewModel, ServiceUserPostModel } from '../../../models';
import { ServiceUsersDataSourceService } from '../../../services';
import { LocalStorageService, UserRoleEnum } from '../../../../common';
import { Subscription } from 'rxjs';
import { ServiceUserEditPopupComponent, ServiceUserDeletePopupComponent } from '../../popups';

@Component({
  selector: 'greensleeves-service-users-grid',
  templateUrl: './service-users-grid.component.html'
})

export class ServiceUsersGridComponent implements OnInit, OnDestroy {
  static Service_USER_ID_KEY = 'id';
  static SORT_ORDER_DEFAULT = -1;

  @ViewChild(ServiceUserEditPopupComponent, { read: false, static: false })
  _editModal: ServiceUserEditPopupComponent;

  @ViewChild(ServiceUserDeletePopupComponent, { read: false, static: false })
  _deleteModal: ServiceUserDeletePopupComponent;

  _columnTypeEnum = ColumnTypeEnum;
  _userStatus = UserStatusEnum;
  _sortField = ServiceUsersGridComponent.Service_USER_ID_KEY;
  _sortOrder = ServiceUsersGridComponent.SORT_ORDER_DEFAULT;
  _gridData: ServiceUserViewModel[];
  _locationNameKey = ServiceUsersGridComponent.Service_USER_ID_KEY;
  _currentUserId : string;

  _searchPhrase: string = '';

  _serviceUsersSubscription: Subscription;
  _role: UserRoleEnum;

  get _isServiceUser(): boolean {
    return this._role === UserRoleEnum.ServiceUser;
  }

  get _columns() {
    return [
      { header: 'First name', columnType: ColumnTypeEnum.UserFirstName, dataField: 'firstName' },
      { header: 'Last name', columnType: ColumnTypeEnum.UserLastName, dataField: 'lastName' },
      { header: 'Email', dataField: 'email' },
      { header: 'Last login date', columnType: ColumnTypeEnum.Date, dataField: 'lastLoginDate' },
      { header: '', columnType: ColumnTypeEnum.Edit, width: "46" },
      { header: '', columnType: ColumnTypeEnum.Delete, dataField: 'id', width: "46" }
    ];
  }

  constructor(
    private _serviceUsersService: ServiceUsersDataSourceService,
    private _localStorageService: LocalStorageService
  ) {
  }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;
    this._currentUserId = info.id;

    this._serviceUsersService.get();
    this._serviceUsersSubscription = this._serviceUsersService.serviceUsers$
      .subscribe(data => this._gridData = data);
  }

  ngOnDestroy() {
    this._serviceUsersSubscription && this._serviceUsersSubscription.unsubscribe();
    this._serviceUsersSubscription = null;
  }

  onClickEdit(serviceUser: ServiceUserViewModel) {
    this._editModal.show(serviceUser);
  }

  onClickDelete(serviceUser: ServiceUserViewModel) {
    this._deleteModal.show(serviceUser);
  }
}