import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import {
  CompanyViewModel,
  CompanyPostModel,
  LocationUpdatedHubMessage,
  CompanyAddedHubMessage,
  CompanyDeletedHubMessage,
  CompanyInUpdateHubMessage,
  CompanyUpdatedHubMessage,
} from '../../models';
import { CompaniesApiService, HubService } from '../http';
import { HubMethods } from '../../constants';

@Injectable()
export class CompaniesDataSourceService {
  public companies$: BehaviorSubject<CompanyViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(
    private _companiesApi: CompaniesApiService,
    private _hubService: HubService,) {
    this.subscribeToHubService();
  }

  public async get() {
    try {
      const data = await this._companiesApi.loadAll();
      this.companies$.next(data as CompanyViewModel[]);
      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async add(companyname: string): Promise<CompanyViewModel | ErrorModel> {
    try {
      const result = await this._companiesApi.add(companyname) as CompanyViewModel;
      if (result) {
        this._hubService.sendToAll(HubMethods.Company.companyAdded, new CompanyAddedHubMessage(result));
        this.companies$.next([
          ...this.companies$.getValue(),
          result
        ]);
        return Promise.resolve(result);
      }
      this.errors$.next(null);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async edit(companyModel: CompanyPostModel): Promise<CompanyViewModel | ErrorModel> {
    try {
      const result = await this._companiesApi.edit(companyModel) as CompanyViewModel;
      if (result) {
        this._hubService.sendToAll(HubMethods.Company.companyUpdated, new CompanyUpdatedHubMessage(result, true));
        const companies = this.companies$.getValue().map(l => l.id === result.id ? { ...l, ...result } : l);
        this.companies$.next(companies);
        return Promise.resolve(result);
      }
      this.errors$.next(null);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }
  public async delete(companyId: number) {
    try {
      const result = await this._companiesApi.delete(companyId);
      if (result) {
        this._hubService.sendToAll(HubMethods.Company.companyDeleted, new CompanyDeletedHubMessage(companyId));
        const companies = this.companies$.getValue().filter(l => l.id !== result);
        this.companies$.next(companies);
        return Promise.resolve(true);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async markCompanyForOthersAsInUpdate(companyId: number): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.Company.companyInUpdate, new CompanyInUpdateHubMessage(companyId));
  }

  public async markCompanyForOthersAsUpdated(company: CompanyViewModel, withChanges: boolean): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.Company.companyUpdated, new CompanyUpdatedHubMessage(company, withChanges));
  }

  public async getCompaniesForUpdaterFilter(): Promise<CompanyViewModel[] | ErrorModel> {
    try {
      var result = await this._companiesApi.getCompaniesForUpdaterFilter() as CompanyViewModel[];
      this.errors$.next(null);
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  private subscribeToHubService() {
    this._hubService.locationUpdated.subscribe((hubMessage: LocationUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedLocation) {
        const companies = this.companies$.getValue();
        for (let company of companies) {
          const locationToUpdate = company.locations ? company.locations.find(l => l.id === hubMessage.updatedLocation.id) : null;
          if (locationToUpdate) {
            locationToUpdate.name = hubMessage.updatedLocation.name;
            break;
          }
        };
      }
    });
    this._hubService.companyAdded.subscribe((hubMessage: CompanyAddedHubMessage) => {
      if (hubMessage && hubMessage.newCompany) {
        this.companies$.next([
          ...this.companies$.getValue(),
          hubMessage.newCompany
        ]);
      }
    });
    this._hubService.companyDeleted.subscribe((hubMessage: CompanyDeletedHubMessage) => {
      if (hubMessage) {
        const companies = this.companies$.getValue().filter(l => l.id !== hubMessage.companyId);
        this.companies$.next(companies);
      }
    });
    this._hubService.companyInUpdate.subscribe((hubMessage: CompanyInUpdateHubMessage) => {
      if (hubMessage) {
        const company = this.companies$.getValue().find(l => l.id === hubMessage.companyId);
        if (company) {
          company.isSomeoneUpdateCompany = true;
        }
      }
    });
    this._hubService.companyUpdated.subscribe((hubMessage: CompanyUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedCompany) {
        if (hubMessage.withChanges) {
          hubMessage.updatedCompany.isSomeoneUpdateCompany = undefined;
          this.companies$.next(this.companies$.getValue().map(c => c.id === hubMessage.updatedCompany.id ? hubMessage.updatedCompany : c));
        } else {
          const company = this.companies$.getValue().find(l => l.id == hubMessage.updatedCompany.id);
          company.isSomeoneUpdateCompany = false;
        }
      }
    });
  }
}