export class ApiRoutes {
    private static readonly locationBaseUrl: string = '/location';
    private static readonly locationBtxBaseUrl = '/LocationBtx';
    private static readonly locationItxBaseUrl = '/LocationItx';
    private static readonly locationKtxBaseUrl = '/locationKtx';
    private static readonly companyBaseUrl: string = '/company';
    private static readonly equipmentBaseUrl: string = '/equipment';
    public static readonly hub: string = '/webhub';
    private static readonly telemetryBaseUrl: string = '/TelemetrySnapshot';
    private static readonly binBaseUrl: string = '/bins';
    private static readonly telemetry: string = '/telemetry';
    private static readonly telemetrySnapshot: string = '/telemetrySnapshot';
    private static readonly itxBinBaseUrl: string = '/itxBins';
    private static readonly notififcationUrl: string = '/notification';
    private static readonly reportUrl: string = '/report';
    private static readonly CommodityBaseUrl: string = '/commodity';
    private static readonly aqsReportUrl: string = '/aqsReport';
    private static readonly AirflowBaseUrl: string = '/aqsAirflow';
    private static readonly quoteStorageUrl: string = '/aqsQuoteStorage';
    private static readonly deiveLog: string = '/deviceLog';
    private static readonly updaterDevice: string = '/updaterDevice';

    static Location = class {
        public static readonly getAll: string = `${ApiRoutes.locationBaseUrl}/getAll`;
        public static readonly getAllForCompany: string = `${ApiRoutes.locationBaseUrl}/getAllForCompany`;
        public static readonly getKtxBtxItxLocationsForCompany: string = `${ApiRoutes.locationBaseUrl}/getKtxBtxItxLocationsForCompany`;
        public static readonly add: string = `${ApiRoutes.locationBaseUrl}/add`;
        public static readonly edit: string = `${ApiRoutes.locationBaseUrl}/edit`;
        public static readonly delete = `${ApiRoutes.locationBaseUrl}/delete`;
    }

    static LocationItx = class {
        public static readonly getLocationsWithBinsForCompany: string = `${ApiRoutes.locationItxBaseUrl}/getLocationsWithBinsForCompany`;
        public static readonly getAll: string = `${ApiRoutes.locationItxBaseUrl}/getAll`;
        public static readonly edit: string = `${ApiRoutes.locationItxBaseUrl}/edit`;
        public static readonly add: string = `${ApiRoutes.locationItxBaseUrl}/add`;
        public static readonly delete: string = `${ApiRoutes.locationItxBaseUrl}/delete`;
        public static readonly editFullnessRange: string = `${ApiRoutes.locationItxBaseUrl}/editFullnessRange`;
        public static readonly getFullnessRanges: string = `${ApiRoutes.locationItxBaseUrl}/getFullnessRanges`;
        public static readonly initializeDeviceConfiguration: string = `${ApiRoutes.locationItxBaseUrl}/initializeDeviceEnrollment`;
        public static readonly downloadConnectionFile: string = `${ApiRoutes.locationItxBaseUrl}/getConnectionFile`;
        public static readonly getListOfAssignedItxLocationsWithItxBins: string = `${ApiRoutes.locationItxBaseUrl}/getListOfAssignedItxLocationsWithItxBins`;
    }

    static LocationBtx = class {
        public static readonly getAll: string = `${ApiRoutes.locationBtxBaseUrl}/getAll`;
        public static readonly add: string = `${ApiRoutes.locationBtxBaseUrl}/add`;
        public static readonly edit: string = `${ApiRoutes.locationBtxBaseUrl}/edit`;
        public static readonly delete: string = `${ApiRoutes.locationBtxBaseUrl}/delete`;
        public static readonly downloadConnectionFile: string = `${ApiRoutes.locationBtxBaseUrl}/getConnectionFile`;
        public static readonly initializeDeviceConfiguration: string = `${ApiRoutes.locationBtxBaseUrl}/initializeDeviceEnrollment`;
        public static readonly getCompaniesWithLocations: string = `${ApiRoutes.locationBtxBaseUrl}/getCompaniesWithLocations`;
        public static readonly getLiveDataLocations: string = `${ApiRoutes.locationBtxBaseUrl}/getLiveDataLocations`;
        public static readonly parseLocationConfiguration: string = `${ApiRoutes.locationBtxBaseUrl}/parseBtxLocationConfiguration`;
        public static readonly uploadBtxLocationConfiguration: string = `${ApiRoutes.locationBtxBaseUrl}/uploadBtxLocationConfiguration`;
    }

    static LocationKtx = class {
        public static readonly getAll: string = `${ApiRoutes.locationKtxBaseUrl}/getAll`;
        public static readonly add: string = `${ApiRoutes.locationKtxBaseUrl}/add`;
        public static readonly edit: string = `${ApiRoutes.locationKtxBaseUrl}/edit`;
        public static readonly delete: string = `${ApiRoutes.locationKtxBaseUrl}/delete`;
        public static readonly downloadConnectionFile: string = `${ApiRoutes.locationKtxBaseUrl}/getConnectionFile`;
        public static readonly initializeDeviceConfiguration: string = `${ApiRoutes.locationKtxBaseUrl}/initializeDeviceEnrollment`;
        public static readonly editTemperatureRange: string = `${ApiRoutes.locationKtxBaseUrl}/EditTemperatureRange`;
        public static readonly getTemperatureRanges: string = `${ApiRoutes.locationKtxBaseUrl}/GetTemperatureRanges`;
        public static readonly getCompaniesWithLocations: string = `${ApiRoutes.locationKtxBaseUrl}/getCompaniesWithLocations`;
    }

    static Company = class {
        public static readonly getAll: string = `${ApiRoutes.companyBaseUrl}/getAll`;
        public static readonly add: string = `${ApiRoutes.companyBaseUrl}/AddCompany`;
        public static readonly delete: string = `${ApiRoutes.companyBaseUrl}/DeleteCompany`;
        public static readonly edit: string = `${ApiRoutes.companyBaseUrl}/EditCompany`;
        public static readonly getCompaniesForUpdaterFilter: string = `${ApiRoutes.companyBaseUrl}/getCompaniesForUpdaterFilter`;
    }

    static Equipment = class {
        public static readonly get: string = `${ApiRoutes.equipmentBaseUrl}/GetEquipmentsInfoWeb`;
        public static readonly getEquipmentSensorsInfo: string = `${ApiRoutes.equipmentBaseUrl}/getEquipmentSensorsInfo`;
        public static readonly edit: string = `${ApiRoutes.equipmentBaseUrl}/AddUpdateEquipment`;
        public static readonly getEquipmentWithTelemetry: string = `${ApiRoutes.equipmentBaseUrl}/GetEquipmentWithTelemetry`;
    }

    static Hub = class {
        public static readonly sendToAll: string = `SendToAll`;
        public static readonly sendToAllServiceUsers: string = `SendToAllServiceUsers`;
        public static readonly sendToCompanyAdminsAllAndServiceUsers: string = `SendToCompanyAdminsAllAndServiceUsers`;
        public static readonly sendToCompanyAdmins: string = `SendToCompanyAdmins`;
        public static readonly bindCompanyAdminToCompanyGroup: string = `BindCompanyAdminToCompanyGroup`;
    }

    static Bin = class {
        public static readonly get2dScheme: string = `${ApiRoutes.binBaseUrl}/get2DScheme`;
        public static readonly get3dScheme: string = `${ApiRoutes.binBaseUrl}/get3DScheme`;
        public static readonly getLastSnapshotData: string = `${ApiRoutes.telemetryBaseUrl}/getLastSnapshotData`;
        public static readonly uploadConfigurationFile: string = `${ApiRoutes.binBaseUrl}/uploadConfigurationFile`;
        public static readonly readSiloData: string = `${ApiRoutes.telemetryBaseUrl}/readSiloData`;
        public static readonly getScheme: string = `${ApiRoutes.binBaseUrl}/getScheme`;
        public static readonly addEditLocationScheme: string = `${ApiRoutes.binBaseUrl}/addEditLocationScheme`;
        public static readonly getBinsForConnectionEditByLocationId: string = `${ApiRoutes.binBaseUrl}/getBinsForConnectionByLocation`;
        public static readonly editBinsConnections: string = `${ApiRoutes.binBaseUrl}/editBinsConnections`;
        public static readonly getBinScheme: string = `${ApiRoutes.binBaseUrl}/getBinScheme`;
    }

    static Telemetry = class {
        public static readonly getLastData: string = `${ApiRoutes.telemetry}/GetLastData`;
        public static readonly getHistoricalDataForThePeriod: string = `${ApiRoutes.telemetry}/getHistoricalDataForThePeriod`;
    }

    static TelemetrySnapshot = class {
        public static readonly getListOfTimestampsForThePeriod: string = `${ApiRoutes.telemetrySnapshot}/GetListOfTimestampsForThePeriod`;
        public static readonly getHistoricalSnapshotByTimestamp: string = `${ApiRoutes.telemetrySnapshot}/GetHistoricalSnapshotByTimestamp`;
    }

    static ItxBin = class {
        public static readonly getItxBinsByLocationId: string = `${ApiRoutes.itxBinBaseUrl}/GetItxBinsByLocationId`;
        public static readonly add: string = `${ApiRoutes.itxBinBaseUrl}/Add`;
        public static readonly edit: string = `${ApiRoutes.itxBinBaseUrl}/Edit`;
        public static readonly delete: string = `${ApiRoutes.itxBinBaseUrl}/Delete`;
        public static readonly addRadar: string = `${ApiRoutes.itxBinBaseUrl}/AddRadar`;
        public static readonly editRadar: string = `${ApiRoutes.itxBinBaseUrl}/EditRadar`;
        public static readonly deleteRadar: string = `${ApiRoutes.itxBinBaseUrl}/DeleteRadar`;
        public static readonly getSchemeForLiveData: string = `${ApiRoutes.itxBinBaseUrl}/getItxBinSchemeForUser`;
        public static readonly getDataForLiveData: string = `${ApiRoutes.itxBinBaseUrl}/getDataForLiveData`;
        public static readonly getStaticSchemeForFiveData: string = `${ApiRoutes.itxBinBaseUrl}/getStaticItxBinSchemeData`;
        public static readonly setUserPreferences: string = `${ApiRoutes.itxBinBaseUrl}/setUserPreferences`;
        public static readonly quicEdit: string = `${ApiRoutes.itxBinBaseUrl}/QuickEditStorage`;
        public static readonly getLocationReportFilters: string = `${ApiRoutes.itxBinBaseUrl}/GetItxLocationReportFilters`;
        public static readonly getRadarTelemetryWithUserPreferences: string = `${ApiRoutes.itxBinBaseUrl}/GetRadarTelemetryWithUserPreferences`;
    }

    static Report = class {
        public static readonly getItxReportHistoricalDataByFilter: string = `${ApiRoutes.reportUrl}/getItxReportHistoricalDataByFilter`
        public static readonly getItxReportFile: string = `${ApiRoutes.reportUrl}/GetItxReportFile`;
        public static readonly getItxReportLiveDataByFilter: string = `${ApiRoutes.reportUrl}/getItxReportLiveDataByFilter`;
        public static readonly getKtxReportHistoricalByFilter: string = `${ApiRoutes.reportUrl}/getKtxReportHistoricalByFilter`;
        public static readonly getKtxReportCurrentDataByFilter: string = `${ApiRoutes.reportUrl}/getKtxReportCurrentDataByFilter`;
        public static readonly getBtxReportLiveDataByFilter: string = `${ApiRoutes.reportUrl}/getBtxReportLiveDataByFilter`;
        public static readonly getBtxReportHistoricalDataByFilter: string = `${ApiRoutes.reportUrl}/getBtxReportHistoricalDataByFilter`;
        public static readonly getNotificationReport: string = `${ApiRoutes.reportUrl}/getNotificationReport`;
    }

    static AqsReport = class {
        public static readonly getReportComputationSheet: string = `${ApiRoutes.aqsReportUrl}/getReportComputationSheet`;
        public static readonly getReportComputationSheetToSend: string = `${ApiRoutes.aqsReportUrl}/getReportComputationSheetToSend`;
        public static readonly getReportQuoteRound: string = `${ApiRoutes.aqsReportUrl}/getReportQuoteRound`;
        public static readonly getReportRoundStorageToSend: string = `${ApiRoutes.aqsReportUrl}/getReportRoundStorageToSend`;
        public static readonly getReportQuoteFlat: string = `${ApiRoutes.aqsReportUrl}/getReportQuoteFlat`;
        public static readonly getReportFlatStorageToSend: string = `${ApiRoutes.aqsReportUrl}/getReportFlatStorageToSend`;
        public static readonly getReportListOfQuote: string = `${ApiRoutes.aqsReportUrl}/getListOfQuote`;
        public static readonly getReportListOfQuoteToSend: string = `${ApiRoutes.aqsReportUrl}/getReportListOfQuoteToSend`;
    }

    static Notification = class {
        public static readonly getItxNotificationByQuery: string = `${ApiRoutes.notififcationUrl}/getItxEvents`;
        public static readonly getKtxNotificationByQuery: string = `${ApiRoutes.notififcationUrl}/getKtxAlerts`;
        public static readonly getBtxNotificationByQuery: string = `${ApiRoutes.notififcationUrl}/getBtxEvents`;
    }

    static Commodity = class {
        public static readonly getAll: string = `${ApiRoutes.CommodityBaseUrl}/getAll`;
    }

    static Airflow = class {
        public static readonly getAllFanTypesWithFans: string = `${ApiRoutes.AirflowBaseUrl}/getAllFanTypesWithFans`;
    }

    static QuoteStorage = class {
        //Flat
        public static readonly addQuoteFlatStorage: string = `${ApiRoutes.quoteStorageUrl}/addQuoteFlatStorage`;
        public static readonly getQuoteFlatStorage: string = `${ApiRoutes.quoteStorageUrl}/getQuoteFlatStorage`;
        public static readonly editQuoteFlatStorage: string = `${ApiRoutes.quoteStorageUrl}/editQuoteFlatStorage`;
        //Round
        public static readonly addQuoteRoundStorage: string = `${ApiRoutes.quoteStorageUrl}/addQuoteRoundStorage`;
        public static readonly getQuoteRoundStorage: string = `${ApiRoutes.quoteStorageUrl}/getQuoteRoundStorage`;
        public static readonly editQuoteRoundStorage: string = `${ApiRoutes.quoteStorageUrl}/editQuoteRoundStorage`;

        public static readonly deleteQuoteStorage: string = `${ApiRoutes.quoteStorageUrl}/deleteQuoteStorage`;
        //Computation
        public static readonly addComputationSheet: string = `${ApiRoutes.quoteStorageUrl}/addComputationSheet`;
        public static readonly editComputationSheet: string = `${ApiRoutes.quoteStorageUrl}/editComputationSheet`;
        public static readonly getComputationSheetById: string = `${ApiRoutes.quoteStorageUrl}/getComputationSheetById`;
        public static readonly getAllComputationSheetsForBindingByUserId: string = `${ApiRoutes.quoteStorageUrl}/GetComputationSheetsForBindingByUserId`;
        //Quotes
        public static readonly getAllSavedQuotes: string = `${ApiRoutes.quoteStorageUrl}/getAllSavedQuotesByUserId`;
    }

    static DeviceLog = class {
        public static readonly getLogsInfoForDeivce: string = `${ApiRoutes.deiveLog}/getLogsInfoForDevice`;
        public static readonly downloadLogFile: string = `${ApiRoutes.deiveLog}/downloadLogFile`;
        public static readonly downloadLogFiles: string = `${ApiRoutes.deiveLog}/downloadLogFiles`;
        public static readonly addLogLinkToDeviceTwin: string = `${ApiRoutes.deiveLog}/addLinkToTwin`;
    }

    static UpdaterDevice = class {
        public static readonly getByFilter: string = `${ApiRoutes.updaterDevice}/getByFilter`;
        public static readonly addUpdaterDevice: string = `${ApiRoutes.updaterDevice}/create`;
        public static readonly initializeDeviceConfiguration: string = `${ApiRoutes.updaterDevice}/initializeDeviceEnrollment`;
        public static readonly editUpdaterDevice: string = `${ApiRoutes.updaterDevice}/update`;
        public static readonly deleteUpdaterDevice: string = `${ApiRoutes.updaterDevice}/delete`;
        public static readonly downloadConnectionFile: string = `${ApiRoutes.updaterDevice}/getConnectionFile`;
        public static readonly getBlobFirmwareFiles: string = `${ApiRoutes.updaterDevice}/getBlobFirmwareFiles`;
        public static readonly downloadFirmwareFile: string = `${ApiRoutes.updaterDevice}/downloadFirmwareFile`;
        public static readonly downloadFirmwareFiles: string = `${ApiRoutes.updaterDevice}/downloadFirmwareFiles`;
        public static readonly deleteSelectedFirmwares: string = `${ApiRoutes.updaterDevice}/deleteSelectedFirmwares`;
        public static readonly uploadFirmware: string = `${ApiRoutes.updaterDevice}/uploadFirmware`;
        public static readonly updateDeviceFirmware: string = `${ApiRoutes.updaterDevice}/updateDeviceFirmware`;
        public static readonly updateDeviceFirmwares: string = `${ApiRoutes.updaterDevice}/updateDeviceFirmwares`;
        public static readonly cancelUpdateDeviceFirmware: string = `${ApiRoutes.updaterDevice}/cancelUpdateDeviceFirmware`;
        public static readonly getUpdateHistoryVersions: string = `${ApiRoutes.updaterDevice}/getUpdateHistoryVersionsById`;
        public static readonly getUpdateStates: string = `${ApiRoutes.updaterDevice}/getUpdateStates`;
        public static readonly getDeviceConnectionStatus: string = `${ApiRoutes.updaterDevice}/getDeviceConnectionStatus`;
        public static readonly installProjectFirmware: string = `${ApiRoutes.updaterDevice}/installProjectFirmware`;
        public static readonly getInstalledProjectFirmware: string = `${ApiRoutes.updaterDevice}/getInstalledProjectFirmware`;
    }
}
