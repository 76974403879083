import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BACK_DOOR, UsersTutorialMenu } from '../../../../constants';
import { CommonFunctions, LocalStorageService, UserRoleEnum } from './../../../../../common';
import { RoleService } from './../../../../services';
import { ProjectEnum, TutorialMenuEnum, TutorialParagraphEnum } from '../../../../enums';

import { TutorialImages } from './tutorial-img-constants';

@Component({
  selector: 'greensleeves-tutorial',
  templateUrl: './tutorial.component.html',
  styles: []
})
export class TutorialComponent implements OnInit {
  @Input() _paragraph: TutorialParagraphEnum[];
  @Input() menuNum: TutorialMenuEnum;
  @Output() menuNumChange = new EventEmitter<number>();

  _menuItems: { label: string, value: TutorialMenuEnum }[];
  _userRoles = UserRoleEnum;
  _tutorialMenu = TutorialMenuEnum;
  _tutorialParagraph = TutorialParagraphEnum;
  _project = ProjectEnum;
  _images = TutorialImages;

  private role: UserRoleEnum;
  private projects: number;

  public get _isCompanyAdmin(): boolean {
    return this.role == UserRoleEnum.CompanyAdmin;
  }



  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService,
    private _roleService: RoleService,
  ) { }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    if (this._router.url.indexOf(BACK_DOOR) !== -1) {
      const backDorInfo = this._roleService.getBackDoorUserWithRole(this._router.url);
      this.role = backDorInfo.role
      this.projects = backDorInfo.projects;
    }
    else {
      this.role = info.role;
      this.projects = info.projects;
    }
    this._menuItems = [];
    if (this.role != UserRoleEnum.ServiceUser && this.role != UserRoleEnum.Vendor) {

      if (this.role == UserRoleEnum.CompanyAdmin) {
        this._menuItems.push(...this.getProjectMeny(ProjectEnum.None));
      }

      if (CommonFunctions.containsProject(this.projects, ProjectEnum.Btx)) {
        this._menuItems.push(...this.getProjectMeny(ProjectEnum.Btx));
      }

      if (CommonFunctions.containsProject(this.projects, ProjectEnum.Ktx)) {
        this._menuItems.push(...this.getProjectMeny(ProjectEnum.Ktx));
      }

      if (CommonFunctions.containsProject(this.projects, ProjectEnum.Itx)) {
        this._menuItems.push(...this.getProjectMeny(ProjectEnum.Itx));
      }
    }
  }

  onSelectMenu(value: TutorialMenuEnum) {
    this.menuNum = value;
    this.menuNumChange.emit(value);
  }

  checkProject(project: ProjectEnum): boolean {
    return CommonFunctions.containsProject(this.projects, project);
  }

  private getProjectMeny(project: ProjectEnum): { label: string, value: number }[] {
    let menuArr: { label: string, value: number }[] = [];
    const menu = UsersTutorialMenu[this.role][project]
    for (let key in menu) {
      if (menu.hasOwnProperty(key)) {
        menuArr.push({ label: menu[key], value: Number(key) });
      }
    }
    return menuArr;
  }

  getIndex(paragraph: TutorialParagraphEnum): number {
    return Number(paragraph);
  }

  getActiveIndex(paragraph: TutorialParagraphEnum): number[] {
    return [Number(paragraph)];
  }
}



