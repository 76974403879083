import { AqsSessionTypeEnum, AqsStorageTypeEnum } from "../../enums";

export class AqsSavedQuoteModel {
  public isSelected: boolean;
  public quoteNumber: string;
  public createdDate: Date;
  public sessionType: AqsSessionTypeEnum;
  public storageType: AqsStorageTypeEnum;
  public customerName: string;
  public companyName: string;
  public quoteId: number;
}
