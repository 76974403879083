import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'greensleeves-equipment-sensor-delete-popup',
  templateUrl: './equipment-sensor-delete-popup.component.html',
  styles: []
})

export class EquipmentSensorDeletePopupComponent implements OnInit {
  @Output('sensorDelete')
  sensorDelete: EventEmitter<number> = new EventEmitter<number>();

  @Output('equipmentDelete')
  equipmentDelete: EventEmitter<number> = new EventEmitter<number>();

  _isHidden = true;
  _isSubmitted: boolean;
  _isSensorDeletePopup: boolean;
  _sensorId: number;
  _equipmentId: number;
  constructor() { }

  ngOnInit() {
    this._isSubmitted = false;
    this._isHidden = true;
  }

  public showSensorView(sensorId: number) {
    this._sensorId = sensorId;
    this._isSubmitted = false;
    this._isSensorDeletePopup = true;
    this._isHidden = false;
  }

  public showEquipmentView(equipmentId: number) {
    this._isSubmitted = false;
    this._equipmentId = equipmentId;
    this._isSensorDeletePopup = false;
    this._isHidden = false;
  }

  async onSubmit() {
    this._isSubmitted = true;
    if (this._isSensorDeletePopup) {
      this.sensorDelete.emit(this._sensorId);
    } else {
      this.equipmentDelete.emit(this._equipmentId);
    }

    this._isHidden = true;
  }

  onCancel() {
    this._isHidden = true;
    this._isSubmitted = false;
  }
}
