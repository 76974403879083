import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { HttpService, AuthorizeService, LocalStorageService, AccountService } from './services';

@NgModule({
  imports: [
  ],
  providers: [
    HttpService,
    AuthorizeService,
    LocalStorageService,
    AccountService,
  ]
})
export class CommonModule { }
