import { PLCDeviceEnum } from './../../../enums/equipment.enum';
import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
  EquipmentViewModel,
  LocationBtxEquipmentViewModel,
  LocationBtxViewModel
} from '../../../models';
import { LocationsBtxDataSourceService, LocationsDataSourceService } from '../../../services';
import { AcquisitionRateEnum } from '../../../enums';
import { TemplateFunctions, ValidationFunctions } from '../../common';
import { BasePopupBehavior } from '../common';
import { digitsValidator } from 'src/app/common/validators';
import { BtxHardwareIconClass, BtxPLCDeviceLabel, ModelsTypes } from '../../../constants';
import { LocationChangedPopupComponent } from '../location-changed-popup';
import { ErrorCodeEnum, ErrorModel, LocalStorageService, UserRoleEnum } from '../../../../common';

@Component({
  selector: 'greensleeves-btx-location-edit-popup',
  templateUrl: './btx-location-edit-popup.component.html',
  styles: []
})
export class BtxLocationEditPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(LocationChangedPopupComponent, { read: false, static: false })
  _locationChangedPopupComponent: LocationChangedPopupComponent;

  _isHidden = true;
  _submitted = false;
  _isAddPopup = false;
  _arduinoAlreadyAssigned = false;

  _acquisitionRateOptions = [
    { label: "10 seconds", value: AcquisitionRateEnum.TenSeconds },
    { label: "30 seconds", value: AcquisitionRateEnum.ThirtySeconds },
    { label: "1 minute", value: AcquisitionRateEnum.OneMinute },
    { label: "3 minutes", value: AcquisitionRateEnum.ThreeMinutes },
    { label: "5 minutes", value: AcquisitionRateEnum.FiveMinutes }
  ];

  _selectedHardwareOptions: PLCDeviceEnum;
  _hardwareOptions: { label: string, value: PLCDeviceEnum }[] = [];
  _deviceEnum = PLCDeviceEnum;

  _locationBtx: LocationBtxEquipmentViewModel;
  _locationForm: FormGroup;

  _bitOptions: Array<{ label: string, value: number }> = Array(16).fill(null).map((_, i) => ({ label: (i + 1).toLocaleString(), value: i + 1 }));

  _locationsBtx: { label: string, value: number }[];
  _locations: { label: string, value: number }[];
  _nameControlKey = 'name';
  _uniquenessErrorKey = 'unique';

  _customPatternsForMacAddress = { 'A': { pattern: new RegExp('([a-fA-F])|([0-9])') } };

  private _subscriptions: Subscription[] = [];
  private role: UserRoleEnum;
  constructor(
    private _formBuilder: FormBuilder,
    private _locationsBtxService: LocationsBtxDataSourceService,
    private _locationService: LocationsDataSourceService,
    private _localStorageService: LocalStorageService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const info = this._localStorageService.getLoginInfo();
    this.role = info ? info.role : null;

    for (const [key, value] of Object.entries(BtxPLCDeviceLabel)) {
      this._hardwareOptions.push({ label: value, value: Number(key) });
    }
    if (this.role == UserRoleEnum.ServiceUser) {
      this._locationBtx = new LocationBtxEquipmentViewModel();
      let subscriptionBtx = this._locationsBtxService.locationsBtx$.subscribe(data => {
        if (data) {
          this._locationsBtx = data.map(x => ({ label: x.locationName, value: x.id }));
        }
      });

      this._locationService.get();

      let subscriptionLocations = this._locationService.locations$.subscribe(data => {
        if (data) {
          this._locations = data.map(x => ({ label: x.name, value: x.id }));
        }
      });

      this._subscriptions.push(subscriptionBtx);
      this._subscriptions.push(subscriptionLocations);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions && this._subscriptions.forEach(sub => sub.unsubscribe());
    this._subscriptions = [];
  }

  ngAfterViewChecked() {
    super.ngAfterViewChecked();
  }
  onChangeHardware() {
    this._locationBtx.plcDeviceConnection = this._selectedHardwareOptions;
    if (this._selectedHardwareOptions == PLCDeviceEnum.Raspberry) {
      this._locationForm.controls.deviceMacAddress.clearValidators();
      this._locationForm.controls.deviceMacAddress.disable();
      this._locationForm.controls.deviceMacAddress.updateValueAndValidity();
      this._locationForm.controls.deviceIp.clearValidators();
      this._locationForm.controls.deviceIp.disable();
      this._locationForm.controls.deviceIp.updateValueAndValidity();
    }
    else {
      this._locationForm.controls.deviceIp.enable();
      this._locationForm.controls.deviceIp.setValidators(
        Validators.compose([
          Validators.required,
          TemplateFunctions.ipValidator,
        ]));
      this._locationForm.controls.deviceIp.updateValueAndValidity();
      this._locationForm.controls.deviceMacAddress.enable();
      this._locationForm.controls.deviceMacAddress.setValidators(
        Validators.compose([
          Validators.required,
          TemplateFunctions.macAddressValidator,
        ]));
      this._locationForm.controls.deviceMacAddress.updateValueAndValidity();
    }
  }

  async onSubmit() {
    this._arduinoAlreadyAssigned = false;
    this._submitted = true;
    if (this._locationForm.invalid || this._selectedHardwareOptions == undefined) {
      return;
    }
    const value = {
      ...this._locationBtx,
      ...this._locationForm.value,
      companyName: this._locations.find(x => x.value == this._locationForm.value.locationId).label
    } as LocationBtxEquipmentViewModel;
    if (this._selectedHardwareOptions == PLCDeviceEnum.Raspberry) {
      value.deviceMacAddress = null;
      value.deviceIp = null;
    }

    this.validateAddressesForUniqueness(value);
    if (this._locationForm.invalid) {
      return;
    }

    const response = this._isAddPopup
      ? await this._locationsBtxService.add(value)
      : await this._locationsBtxService.edit(value);
    if (response instanceof ErrorModel && response.code === ErrorCodeEnum.ArduinoDeviceAlreadyAssigned) {
      this._arduinoAlreadyAssigned = true;
    } else if (response) {
      this._locationBtx = null;
      this._isHidden = true;
      this._submitted = false;
      this._arduinoAlreadyAssigned = false;

      if ((response as LocationBtxViewModel).isConnectionFileUpdated) {
        this._locationChangedPopupComponent.show();
      }
    }
  }

  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  async onClose() {
    this._locationsBtxService.markLocationForOthersAsUpdated(this._locationBtx, false);
  }

  public show(location?: LocationBtxEquipmentViewModel) {
    this._isAddPopup = !location;
    this._submitted = false;
    this._locationBtx = location ? Object.assign({}, location) : new LocationBtxEquipmentViewModel();
    this._isHidden = false;
    this._selectedHardwareOptions = location ? location.plcDeviceConnection : undefined;
    this.reinitForm();
  }

  private validateAddressesForUniqueness(location: LocationBtxEquipmentViewModel) {
    const existentItems: (LocationBtxViewModel | EquipmentViewModel)[] = [];
    if (this._locationBtx.equipments) {
      this._locationBtx.equipments.forEach(eq => existentItems.push(eq));
    }

    location.modelType = ModelsTypes.locationViewModel;
    const notUniqueAddresses = ValidationFunctions.checkIsAllAddressesUnique(existentItems, location);

    if (notUniqueAddresses && notUniqueAddresses.length > 0) {
      TemplateFunctions.markAddressControllsAsNotUnique(this._locationForm, notUniqueAddresses);
    }
  }

  private reinitForm() {
    this._locationForm = this._formBuilder.group({
      locationId: [
        this._locationBtx.id,
        Validators.compose([
          Validators.required,
        ])
      ],
      deviceIp: [
        this._locationBtx.deviceIp,
        Validators.compose([
          Validators.required,
          TemplateFunctions.ipValidator,
        ])
      ],
      deviceMacAddress: [
        this._locationBtx.deviceMacAddress,
        Validators.compose([
          Validators.required,
          TemplateFunctions.macAddressValidator,
        ])
      ],
      btxIp: [
        this._locationBtx.btxIp,
        Validators.compose([
          Validators.required,
          TemplateFunctions.ipValidator,
        ])
      ],
      btxPort: [
        this._locationBtx.btxPort,
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(65535),
          digitsValidator
        ])
      ],
      acquisitionRate: [
        this._locationBtx.acquisitionRate,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(65536),
          digitsValidator
        ])
      ],
      batteryAlarmAddressBit: [
        this._locationBtx.batteryAlarmAddressBit,
        Validators.compose([
          Validators.required,
        ])
      ],
      batteryAlarmAddress: [
        this._locationBtx.batteryAlarmAddress,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(65536),
          digitsValidator
        ])
      ],
      suppressorAlarmAddressBit: [
        this._locationBtx.suppressorAlarmAddressBit,
        Validators.compose([
          Validators.required,
        ])
      ],
      suppressorAlarmAddress: [
        this._locationBtx.suppressorAlarmAddress,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(65536),
          digitsValidator
        ])
      ],
      communicationAlarmAddressBit: [
        this._locationBtx.communicationAlarmAddressBit,
        Validators.compose([
          Validators.required,
        ])
      ],
      communicationAlarmAddress: [
        this._locationBtx.communicationAlarmAddress,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(65536),
          digitsValidator
        ])
      ],
      hardwareOptions: [
        this._locationBtx.plcDeviceConnection,
      ],
    });

    if (this._selectedHardwareOptions == PLCDeviceEnum.Raspberry) {
      this._locationForm.controls.deviceMacAddress.clearValidators();
      this._locationForm.controls.deviceMacAddress.disable();
      this._locationForm.controls.deviceMacAddress.updateValueAndValidity();
      this._locationForm.controls.deviceIp.clearValidators();
      this._locationForm.controls.deviceIp.disable();
      this._locationForm.controls.deviceIp.updateValueAndValidity();
    }
  }

  getHardwareClass(plcDeviceType: PLCDeviceEnum = this._locationBtx ? this._locationBtx.plcDeviceConnection : null): string {
    return BtxHardwareIconClass.getIconClass(plcDeviceType);
  }
}
