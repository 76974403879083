import { UserStatusEnum } from "../enums";

export class SalesUserViewModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public lastLoginDate: Date;
    public status: UserStatusEnum;

    //User behavior lock fields
    public isSomeoneUpdateSalesUser: boolean;
}

export class SalesUserGetModel {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    lastLoginDate: Date;
    public status: UserStatusEnum;

    public static toViewModel(getModel: SalesUserGetModel): SalesUserViewModel {
        const viewModel = new SalesUserViewModel();
        viewModel.id = getModel.id;
        viewModel.firstName = getModel.firstName;
        viewModel.lastName = getModel.lastName;
        viewModel.email = getModel.email;
        viewModel.lastLoginDate = getModel.lastLoginDate;
        viewModel.status = getModel.status;

        return viewModel;
    }
}

export class SalesUserPostModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public status: UserStatusEnum;
}