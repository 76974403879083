import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AqsCoverSheetModel } from '@models';
import { CoverSheetConst } from '../../../../constants';
import { AqsCoverSheetTypeEnum } from '../../../../enums';
import { AqsQuoteStorageDataSourceService } from './../../../../services';

@Component({
  selector: 'greensleeves-aqs-cover-sheet',
  templateUrl: './aqs-cover-sheet.component.html',
  styleUrls: []
})
export class AqsCoverSheetComponent implements OnInit {
  @Output() onChangedCoverSheetModel = new EventEmitter();

  @Input() _coverSheetDesc: AqsCoverSheetModel;
  @Input() _linkComputationSheetId: number;
  @Output() _linkComputationSheetIdChange = new EventEmitter<number>();

  isSaveWasCalled: boolean = false;
  _constrTypes: { label: string, value: AqsCoverSheetTypeEnum }[] = [];
  _computationSheetsOptions: { label: string, value: number }[] = [];
  link: number = null;

  constructor(private _quoteDataService: AqsQuoteStorageDataSourceService) { }

  ngOnInit() {
    for (const [key, value] of Object.entries(CoverSheetConst)) {
      this._constrTypes.push({ label: value, value: Number(key) });
    }

    let compSheets = this._quoteDataService.bindingComputationSheets$.getValue();
    this._computationSheetsOptions.push({ label: 'None', value: null });
    compSheets.forEach(item => {
      this._computationSheetsOptions.push({ label: item.coverSheet.quoteNumber, value: item.id });
    });
    if (!this._coverSheetDesc) this._coverSheetDesc = new AqsCoverSheetModel();
  }

  checkModelBeforeSave(): boolean {
    this.isSaveWasCalled = true;
    let canToSave = true;

    if (
      this._coverSheetDesc.numberTypical == null || this._coverSheetDesc.numberTypical > 9999 ||
      this._coverSheetDesc.customerName == null || this._coverSheetDesc.customerName.length > 50 ||
      this._coverSheetDesc.companyName == null || this._coverSheetDesc.companyName.length > 50 ||
      this._coverSheetDesc.quoteNumber == null || this._coverSheetDesc.quoteNumber.length > 50
    ) {
      canToSave = false;
    }
     if (this._coverSheetDesc.city) this._coverSheetDesc.city.length > 50 ? canToSave = false : canToSave = true;
     if (this._coverSheetDesc.contact) this._coverSheetDesc.contact.length > 50 ? canToSave = false : canToSave = true;
     if (this._coverSheetDesc.locationName) this._coverSheetDesc.locationName.length > 50 ? canToSave = false : canToSave = true;
     if (this._coverSheetDesc.locationCity) this._coverSheetDesc.locationCity.length > 50 ? canToSave = false : canToSave = true;
     if (this._coverSheetDesc.comment) this._coverSheetDesc.comment.length > 75 ? canToSave = false : canToSave = true;

    return canToSave;
  }

  changeCoverSheetModel() {
    this.onChangedCoverSheetModel.emit();
  }

  changeLinkComputationSheet() {
    this.onChangedCoverSheetModel.emit();
    this._linkComputationSheetIdChange.emit(this._linkComputationSheetId);
    if (this._linkComputationSheetId != null) {
      let computationCover = this._quoteDataService.bindingComputationSheets$.getValue().find(i => i.id == this._linkComputationSheetId);
      if (computationCover) {
        this._coverSheetDesc.customerName = computationCover.coverSheet.customerName;
        this._coverSheetDesc.companyName = computationCover.coverSheet.companyName;
        this._coverSheetDesc.quoteNumber = computationCover.coverSheet.quoteNumber;
        this._coverSheetDesc.city = computationCover.coverSheet.city;
        this._coverSheetDesc.contact = computationCover.coverSheet.contact;
        this._coverSheetDesc.locationName = computationCover.coverSheet.locationName;
        this._coverSheetDesc.locationCity = computationCover.coverSheet.locationCity;
        this._coverSheetDesc.comment = computationCover.coverSheet.comment;
      }
    }
  }
}
