import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AqsFlushFloorDesignTunnelForFourFansLabel, AqsFlushFloorDesignTunnelForSixFansLabel, AqsFlushFloorDesignTunnelForTwoFansLabel } from './../../../constants';
import { AqsAirflowFanTypeEnum, AqsFlushFloorTunnelDesignEnum } from './../../../enums';
import { AqsAirflowViewModel, AqsFlushFloorCalculatedValues, AqsFlushFloorViewModel, AqsRoundStorageWiewModel } from './../../../models';

@Component({
  selector: 'greensleeves-aqs-round-flush-design-tab',
  templateUrl: './aqs-round-flush-design-tab.component.html',
  styles: []
})
export class AqsRoundFlushDesignTabComponent implements OnInit {
  @Input() airflowConfiguration: AqsAirflowViewModel;
  @Input() storageConfiguration: AqsRoundStorageWiewModel;
  @Input() flushFloorConfiguration: AqsFlushFloorViewModel;
  @Output() flushFloorConfigurationChange = new EventEmitter<AqsFlushFloorViewModel>();

  @Output() onChangedFlushFloorModel = new EventEmitter();

  @Input() currentPageError: boolean;
  @Output() currentPageErrorChange = new EventEmitter<boolean>();


  _flushDesignOptions: { label: string, value: AqsFlushFloorTunnelDesignEnum }[];
  _tunnelChoice: AqsFlushFloorTunnelDesignEnum;
  _flushFloorDesign = AqsFlushFloorTunnelDesignEnum;
  _flushFloorCalcValues: AqsFlushFloorCalculatedValues;

  _flushFloorTunnelFillColor: string = 'gray';
  _flushFloorTunnelStrokeColor: string = 'black';
  constructor() { }

  ngOnInit() {
    let csaFanCount: number;
    this._flushFloorCalcValues = new AqsFlushFloorCalculatedValues();
    this._flushDesignOptions = [];
    if (this.airflowConfiguration.numberfans < 3) {
      this.initFlushFloorOptions(AqsFlushFloorDesignTunnelForTwoFansLabel);
      if (this.flushFloorConfiguration.tunnelChoice == null) {
        this._tunnelChoice = AqsFlushFloorTunnelDesignEnum.DoubleH;
      }
      else this._tunnelChoice = this.flushFloorConfiguration.tunnelChoice;
      csaFanCount = 2;
    }

    if (this.airflowConfiguration.numberfans == 4) {
      this.initFlushFloorOptions(AqsFlushFloorDesignTunnelForFourFansLabel);
      if (this.flushFloorConfiguration.tunnelChoice == null) {
        this._tunnelChoice = AqsFlushFloorTunnelDesignEnum.FourLaneSpecial;
      }
      else this._tunnelChoice = this.flushFloorConfiguration.tunnelChoice;
      csaFanCount = 4;
    }

    if (this.airflowConfiguration.numberfans == 6) {
      this.initFlushFloorOptions(AqsFlushFloorDesignTunnelForSixFansLabel);
      this._tunnelChoice = AqsFlushFloorTunnelDesignEnum.SixLaneSpecial;
      if (this.flushFloorConfiguration.tunnelChoice != null) {
        this._tunnelChoice = this.flushFloorConfiguration.tunnelChoice;
      }
      csaFanCount = 6;
    }

    this.flushFloorConfiguration.tunnelChoice = this._tunnelChoice;

   
    this._flushFloorCalcValues.lowerLarge = Math.max(...this.airflowConfiguration.airflowData.map(k => k.kcfm)) * this.airflowConfiguration.numberfans * 1000;
    this._flushFloorCalcValues.rad = this.storageConfiguration.diameter * 6;
    if (this.airflowConfiguration.fanType == AqsAirflowFanTypeEnum.Axial) {
      this._flushFloorCalcValues.mcsv = 3000;
      this._flushFloorCalcValues.msv = 40;
    }
    else {
      this._flushFloorCalcValues.mcsv = 3500;
      this._flushFloorCalcValues.msv = 45;
    }

    if (this.airflowConfiguration.fanType == AqsAirflowFanTypeEnum.Centrifugal60HZ) {
      let fanNumber = parseFloat(this.airflowConfiguration.selectedFan.fanName);
      if (!isNaN(fanNumber) && fanNumber != 2003 && fanNumber != 2205 && fanNumber < 2250) {
        this._flushFloorCalcValues.mcsv = 4000;
        this._flushFloorCalcValues.msv = 50;
      }
    }

    this._flushFloorCalcValues.csa = this._flushFloorCalcValues.lowerLarge / (this._flushFloorCalcValues.mcsv * csaFanCount);
    //IF FANTYPE=1 THEN MCSV=3000:MSV=40 ELSE MCSV=3500:MSV=45
    // 1560 IF FANTYPE=2 AND (FANINFO(23)<>2003 AND FANINFO(23)<>2205 AND FANINFO(23)<2250) THEN MCSV=4000:MSV=50
    // 1570 CSA=LOWERLARGE/(MCSV*2): 'cross section area (sq ft)
    this._flushFloorCalcValues.maxSpan = this.storageConfiguration.ovlHeight < 138
      ? 80.49 * Math.pow(this.storageConfiguration.ovlHeight, -0.499)
      : 7;

    if (this._flushFloorCalcValues.maxSpan > 32.8) {
      this._flushFloorCalcValues.maxSpan = 32.8
    }
    if (this._flushFloorCalcValues.maxSpan < 7) {
      this._flushFloorCalcValues.maxSpan = 7;
    }
  }

  onChangeTunnel() {
    this.flushFloorConfiguration = new AqsFlushFloorViewModel();
    this.flushFloorConfigurationChange.emit(this.flushFloorConfiguration);
    this.flushFloorConfiguration.tunnelChoice = this._tunnelChoice;
    this.onChangedFlushFloorModel.emit();
  }

  changeFlushFloorModel() {
    this.onChangedFlushFloorModel.emit();
  }

  changedCurrentPageError(err: boolean) {
    this.currentPageErrorChange.emit(err);
  }

  private initFlushFloorOptions(labels: any) {
    for (const [key, value] of Object.entries(labels)) {
      this._flushDesignOptions.push({ label: value as string, value: Number(key) });
    }
  }

}
