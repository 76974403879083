import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiRoutes} from '../../constants';
import { LocalStorageService } from '../common';
import { HttpService } from './http.service';
import { CompleteRegistrationModel, CompleteRegistrationVendorModel, PasswordRecoverModel, PasswordUpdateModel } from '../../models';

@Injectable()
export class AccountService {
  public redirectUrl: string = null;

  constructor(
    private _httpService: HttpService,
    private _localStorageService: LocalStorageService,
    private _router: Router) {
  }

  public async completeRegistration(completeRegistrationModel : CompleteRegistrationModel){
    try {
      const result = this._httpService.post(ApiRoutes.Account.completeRegistration, completeRegistrationModel);
    } catch(error) {
      console.log(error);
      return null;
    }
  }

  public async completeRegistrationVendor(completeRegistrationVendorModel : CompleteRegistrationVendorModel){
    try {
      const result = this._httpService.post(ApiRoutes.Account.completeRegistrationVendor, completeRegistrationVendorModel);
    } catch(error) {
      console.log(error);
      return null;
    }
  }
  public async passwordRecover(passwordRecoverModel : PasswordRecoverModel){
    try {
      const result = this._httpService.post(ApiRoutes.Account.resetPassword, passwordRecoverModel);
    } catch(error) {
      console.log(error);
      return null;
    }
  }

  public async passwordUpdate(passwordUpdateModel : PasswordUpdateModel){
    try {
      const result = this._httpService.post(ApiRoutes.Account.updatePassword, passwordUpdateModel);
    } catch(error) {
      console.log(error);
      return null;
    }
  }
}
