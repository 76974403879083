import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { EquipmentApiService } from '../http';

import { ErrorModel } from '../../../common/models';
import { BtxEquipmentWithTelemetryViewModel } from '../../models/equipment-btx.model';

@Injectable()
export class EquipmentDataSourceService {
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  public equipmentWithTelemetry$: BehaviorSubject<BtxEquipmentWithTelemetryViewModel> =
    new BehaviorSubject(null);

  constructor(private _equipmentApi: EquipmentApiService) {}

  public async getEquipmentWithTelemetry(equipmentId: number) {
    try {
      const data = await this._equipmentApi.getEquipmentWithTelemetry(
        equipmentId,
      );

      this.equipmentWithTelemetry$.next(
        data as BtxEquipmentWithTelemetryViewModel,
      );

      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }
}
