import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Chart } from 'chart.js';
import { Calendar } from 'primeng/calendar';

import { ErrorModel, UserRoleEnum } from '../../../common';
import { CalendarDefaultValues, DefaultDateRangeLabel, EnglishDateLocale, equipmentSideLabels, sensorPositionLabels, sensorTypeLabels } from "../../constants";
import { DefaultDateRangeEnum, SensorPositionEnum, SensorTypeEnum, TutorialMenuEnum } from '../../enums';
import { LocationsBtxDataSourceService, ReportDatasourceService, RoleService } from '../../services';
import { HistoricalDataFilter, HistoricalDataTelemetryResponse, TelemetryDomain } from '../../models/historicalDataTelemetryResponse.model';
import { Subscription } from 'rxjs';
import { CompanyViewModel } from '../../models/companies.model';
import { EquipmentViewModel } from '../../models/equipment-btx.model';
import { SensorInfo } from '../../models/sensors';
import { BtxHistoricalDataReportFilterRequest } from '../../models/btx-reports.model';
import { TutorialPopupComponent } from '../popups';

Chart.defaults.multicolorLine = Chart.defaults.line;
Chart.controllers.multicolorLine = Chart.controllers.line.extend({
  draw: function (ease) {
    let startIndex = 0,
      meta = this.getMeta(),
      points = meta.data || [],
      area = this.chart.chartArea,
      originalDatasets = meta.dataset._children;

    function _setLine(newColor, borderDash, meta) {
      meta.dataset._view.borderColor = newColor;
      meta.dataset._view.borderDash = borderDash;
    }

    let segmentColor = 'rgba(255,0,0,1)'
    let nanIndex: number[] = [];
    let mainColor: string;
    points.forEach(x => {
      if (x._view.skip) {
        nanIndex.push(x._index);
        mainColor = x._view.borderColor;
      }
    });

    nanIndex.forEach(x => {
      _setLine(mainColor, [], meta);
      meta.dataset._children = originalDatasets.slice(startIndex, x);
      meta.dataset.draw();
      startIndex = x - 1;

      _setLine(segmentColor, [3, 3], meta);
      meta.dataset._children = originalDatasets.slice(startIndex, x + 2);
      meta.dataset.draw();
      startIndex = x + 1;
    });

    if (nanIndex.length > 0) {
      _setLine(mainColor, [], meta);
    }
    meta.dataset._children = originalDatasets.slice(startIndex);
    meta.dataset.draw();
    meta.dataset._children = originalDatasets;

    points.forEach(function (point) {
      point.draw(area);
    });
  }
});

@Component({
  selector: 'greensleeves-btx-historical-data-tab',
  templateUrl: './btx-historical-data-tab.component.html',
})

export class BtxHistoricalDataTabComponent implements OnInit {
  private readonly MaxDeviceCircleSeconds: number = 330;
  @ViewChild("calendar", { static: false })
  private calendar: Calendar;

  @ViewChild('chartReport', { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;


  private ctx: CanvasRenderingContext2D;
  private subscriptions: Subscription[] = [];
  private chart: Chart;
  private defaultDateRangePreviousState: DefaultDateRangeEnum = null;

  options: any;
  value: Date;
  startDate: Date;
  endDate: Date;
  _dataLocale: EnglishDateLocale = new EnglishDateLocale();
  _historicalDataFilter: HistoricalDataFilter = new HistoricalDataFilter();
  _selectedDefaultDateRange: DefaultDateRangeEnum = null;

  _companies: CompanyViewModel[];
  _equipments: EquipmentViewModel[];
  _companiesFilter: { label: string, value: number }[] = [];
  _locationsFilter: { label: string, value: number }[] = [];
  _equipmentsFilter: { label: string, value: number }[] = [];
  _sensorsFilter: { label: string, value: number }[] = [];
  _sensorTypesFilter: { label: string, value: number }[] = [];
  _equipmentSensorInfo: SensorInfo[];

  _selectedCompanyId: number;
  _selectedLocationId: number;
  _selectedEquipmentId: number;
  _selectedSensorIds: number[];
  _selectedSensorType: SensorTypeEnum;

  _reportPeriod: FormGroup;
  _rangeDate: Date[] = [];
  _dateRange: { label: string, value: DefaultDateRangeEnum }[] = [];
  _selectedDateRange: { label: string, value: DefaultDateRangeEnum } = { label: "Day", value: DefaultDateRangeEnum.Day };
  _selectReportData: FormGroup;
  isReportGenerated: boolean;
  _isReportRun = false;
  _isReportEmpty: boolean;
  _isCollapseSidebar: boolean = false;

  collapseSidebar() {
    this._isCollapseSidebar = true;
  };

  expandSidebar() {
    this._isCollapseSidebar = false;
  }

  private chartColors: string[] = [
    'rgba(54, 162, 235, 1)', 'rgba(54, 235, 93, 1)', 'rgba(191, 54, 235, 1)', 'rgba(6, 6, 6, 1)', 'rgba(139,69,19,1)', 'rgba(112,128,144, 1)', 'rgba(245, 40, 145, 1)'
  ];

  get _startDate() {
    return this._rangeDate[0];
  }
  get _endDate() {
    return this._rangeDate[1] == null ? this._rangeDate[0] : this._rangeDate[1];
  }

  get _equipmentName() {
    return this._equipmentsFilter.find(x => x.value === this._selectedEquipmentId).label;
  }

  get _sensorTypeName() {
    if (this._selectedSensorType) {
      return ', ' + this._sensorTypesFilter.find(x => this._selectedSensorType == x.value).label;
    } else {
      return '';
    }
  }

  get _locationName() {
    return this._locationsFilter.find(x => x.value === this._selectedLocationId).label;
  }

  get isTutorialUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser);
  }

  constructor(
    private _btxLocationService: LocationsBtxDataSourceService,
    private _formBuilder: FormBuilder,
    private _reportDatasource: ReportDatasourceService,
    private _roleService: RoleService,
  ) {

    this._selectReportData = this._formBuilder.group({
      company: null,
      location: null,
      equipment: null,
      sensorstype: null,
      sensors: null,
    })
  }

  async ngOnInit() {
    for (const [key, value] of Object.entries(DefaultDateRangeLabel)) {
      this._dateRange.push({ label: value, value: Number(key) })
    }

    this.setSubscriptions();
    this._btxLocationService.getCompaniesWithLocations(true);
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.resetCalendar();
    this._reportPeriod = this._formBuilder.group({
      selectedPeriod: [this._rangeDate],
      dateRange: [],
      startPeriod: [this._startDate],
      endOfPeriod: [this._endDate],
    });

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this._historicalDataFilter.timeStampFrom = (today.getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this._historicalDataFilter.timeStampTo = (today.getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this.createEmptyChart();
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  setSubscriptions(): void {
    const companiesSubscription =
      this._btxLocationService.companiesWithLocations$.subscribe(
        (companies) => {
          this._companies = companies;
          this.initDefaultFilters();
        }
      );

    this.subscriptions.push(companiesSubscription);
  }

  clearSubscriptions(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = null;
  }

  async initDefaultFilters() {
    if (this._companies && this._companies.length > 0) {
      this._companiesFilter = this._companies.sort((a, b) => a.name.localeCompare(b.name)).map((c) => {
        return { label: c.name, value: c.id, locations: c.locations };
      });

      this._selectedCompanyId = this._companiesFilter[0].value;
      await this.onChangeSelectedCompany();
    }
  }

  async onChangeSelectedCompany() {
    if (this._selectedCompanyId) {
      this._locationsFilter = this._companies.filter(c => c.id == this._selectedCompanyId)[0].locations
        .filter(l => l.equipments && l.equipments.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((location) => {
          return { label: location.name, value: location.id };
        });

      this._selectedLocationId = this._locationsFilter[0].value;
      await this.onChangeSelectedLocation();
    }
  }

  async onChangeSelectedLocation() {
    if (this._selectedLocationId) {
      this._equipmentsFilter = this._companies.filter(c => c.id == this._selectedCompanyId)[0]
        .locations.filter(l => l.id == this._selectedLocationId)[0]
        .equipments
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((location) => {
          return { label: location.name, value: location.id };
        });

      this._selectedEquipmentId = this._equipmentsFilter[0].value;
      await this.onChangeSelectedEquipment();
    }
  }

  async onChangeSelectedEquipment() {
    if (this._selectedEquipmentId) {
      let sensors = await this._btxLocationService.getSensors(this._selectedEquipmentId, this._selectedLocationId) as SensorInfo[];
      if (sensors && sensors.length > 0) {
        this._equipmentSensorInfo = sensors;

        this._sensorTypesFilter = [];
        sensors.forEach(s => {
          if (!this._sensorTypesFilter.some(stf => stf.value == s.type)) {
            this._sensorTypesFilter.push({ label: sensorTypeLabels[s.type], value: s.type })
          }
        });

        this._selectedSensorType = this._sensorTypesFilter[0].value;
        this.onChangeSelectedSensorType();
      }
    }
  }

  async onChangeSelectedSensorType() {
    if (this._equipmentSensorInfo && this._equipmentSensorInfo.length > 0) {
      this._sensorsFilter = this._equipmentSensorInfo.filter(s => s.type == this._selectedSensorType)
        .map((sensor) => {
          return { label: sensorPositionLabels[sensor.position] + ', ' + equipmentSideLabels[sensor.side], value: sensor.id };
        }).sort((a, b) => a.label.localeCompare(b.label));

      this._selectedSensorIds = [];
      this._selectedSensorIds.push(this._sensorsFilter[0].value);
    }
  }

  async onChangeSelectedSensor() {
    if (!this._selectReportData.controls.sensors || this._selectReportData.controls.sensors.value.length == 0) {
      this._selectedSensorIds = [];
    }
  }

  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  onSelectCalendar() {
    if (this._rangeDate[0] && this._rangeDate[1]) {
      let startDate = new Date(this._rangeDate[0]);
      let endDate = new Date(this._rangeDate[1]);

      const diffInMS = new Date(endDate).getTime() - new Date(startDate).getTime();
      const msToDaysDivider = 1000 * 60 * 60 * 24;
      const diffInDays = diffInMS / msToDaysDivider;

      if (diffInDays > 30) {
        const thirtyDaysInMs = 1000 * 60 * 60 * 24 * 30;
        this._rangeDate[1] = new Date(this._rangeDate[0].getTime() + (thirtyDaysInMs));
      }
    }
  }

  async onGenerateReport() {
    this._isReportRun = true;

    let request: HistoricalDataFilter = new HistoricalDataFilter();
    request.equipmentId = this._selectedEquipmentId;
    request.sensorIds = this._selectedSensorIds;
    request.timeStampFrom = this._rangeDate[0].getTime() / 1000;
    request.timeStampTo = this._rangeDate[1].getTime() / 1000;

    let result = await this._btxLocationService.getHistoricalDataForThePeriod(request);
    this.clearChart();
    if (result) {
      if (result instanceof ErrorModel) {
        this._isReportEmpty = true;
        this.isReportGenerated = false;
      }
      else {
        this._isReportEmpty = false;
        this.isReportGenerated = true;
        let data = result as HistoricalDataTelemetryResponse[];

        let chartDatasets = this.createDataSets(data);
        if (chartDatasets) {
          this.chart = new Chart(this.ctx, {
            type: 'multicolorLine',
            plugins: [this.verticalHoverPlugin],
            data: {
              datasets: chartDatasets
            },
            options: {
              legend: {
                display: true,
                position: 'right',
                align: 'start',
                labels: {
                  boxWidth: 12,
                }
              },
              scales: {
                xAxes: [{
                  type: "time",
                  time: this.scalesChart(data),
                  bounds: 'ticks',
                  ticks: {
                    major: {
                      enabled: true,
                      fontStyle: 'bold',
                    },
                  },
                }],
              },
              tooltips: {
                mode: "index",
                intersect: false,
                callbacks: {
                  label: function (tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                      label += ': ';
                    }
                    label += new Intl.NumberFormat('en-US').format(tooltipItem.yLabel);
                    return label;
                  }
                },
              },
              elements: {
                line: {
                  tension: 0
                }
              },
              hover: {
                intersect: false
              },
            }
          });
        }
        else {
          this.createEmptyChart();
          this._isReportEmpty = true;
          this.isReportGenerated = false;
        }
      }
    }
    else {
      this.createEmptyChart();
      this._isReportEmpty = true;
      this.isReportGenerated = false;
    }
    this._isReportRun = false;
  }

  onApplyDateRangeFilter() {
    this.isReportGenerated = false;
    if (this._rangeDate == undefined || this._rangeDate.length == 0) {
      this.resetCalendar();
    }
    if (this._rangeDate[1] == undefined || this._rangeDate[1] == null) {
      this._rangeDate[1] = this._rangeDate[0];
    }
    this._historicalDataFilter.timeStampFrom = (this._rangeDate[0].getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this._historicalDataFilter.timeStampTo = (this._rangeDate[1].getTime() / CalendarDefaultValues.defaultOneSecondMilliseconds) | 0;
    this.defaultDateRangePreviousState = this._selectedDefaultDateRange;
    this.calendar.toggle();
  }

  onCloseCalendar() {
    if (this._historicalDataFilter.timeStampFrom == null && this._historicalDataFilter.timeStampTo == null) {
      this.resetCalendar();
      return;
    }

    this._rangeDate = [];
    this._rangeDate.push(new Date(this._historicalDataFilter.timeStampFrom * CalendarDefaultValues.defaultOneSecondMilliseconds));
    this._rangeDate.push(new Date(this._historicalDataFilter.timeStampTo * CalendarDefaultValues.defaultOneSecondMilliseconds));

    let startDate = new Date(this._historicalDataFilter.timeStampFrom * CalendarDefaultValues.defaultOneSecondMilliseconds);
    let endDate = new Date(this._historicalDataFilter.timeStampTo * CalendarDefaultValues.defaultOneSecondMilliseconds);

    const diffInMS = new Date(endDate).getTime() - new Date(startDate).getTime();
    const diffInDays = diffInMS / 1000 / 60 / 60 / 24;
    let dateRange: DefaultDateRangeEnum;

    if (diffInDays > 7) {
      dateRange = DefaultDateRangeEnum.Month;
    } else if (diffInDays > 1) {
      dateRange = DefaultDateRangeEnum.Week;
    } else {
      dateRange = DefaultDateRangeEnum.Day;
    }

    this._selectedDateRange = this._dateRange.find(x => x.value == dateRange);
  }

  setDefaultDate(defaultDateRange: DefaultDateRangeEnum) {
    let endDate = new Date();
    let startDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    this._rangeDate = [];
    switch (defaultDateRange) {
      case DefaultDateRangeEnum.Week:
        startDate.setDate(startDate.getDate() - CalendarDefaultValues.defaultOneWeekDays);
        break;
      case DefaultDateRangeEnum.Month:
        startDate.setDate(startDate.getDate() - CalendarDefaultValues.defaultOneMonthDays);
        break;
    }
    this._selectedDefaultDateRange = defaultDateRange;
    this._rangeDate.push(startDate);
    this._rangeDate.push(endDate);
    this._selectedDateRange = this._dateRange.find(x => x.value == defaultDateRange);
  }

  async onClickExportReport() {
    this._isReportRun = true;

    let request: BtxHistoricalDataReportFilterRequest = new BtxHistoricalDataReportFilterRequest();
    request.equipmentId = this._selectedEquipmentId;
    request.sensorIds = this._selectedSensorIds;
    request.timeStampFrom = this._rangeDate[0].getTime() / 1000;
    request.timeStampTo = this._rangeDate[1].getTime() / 1000;
    request.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let result = await this._reportDatasource.downloadBtxHistoricalDataReportFile(request);
    if (!(result instanceof ErrorModel)) {
      let fileName = 'Historical Report for ' + this._equipmentName + ' ' + new Date().toLocaleString();
      const url = URL.createObjectURL(result as Blob);
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    this._isReportRun = false;
  }

  private resetCalendar() {
    this.defaultDateRangePreviousState = null;
    this._selectedDefaultDateRange = null;
    this._rangeDate = [];
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this._rangeDate.push(today);
    this._rangeDate.push(today);
  }

  private clearChart() {
    if (this.chart !== undefined) this.chart.destroy();
  }

  private createEmptyChart() {
    this.clearChart();
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this.chart = new Chart(this.ctx, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        scales: {
          xAxes: [{
            type: "time",
            time: {
              unit: "hour",
              displayFormats: {
                hour: 'HH:mm',
              }
            },
            ticks: {
              min: new Date().setHours(0, 0, 0, 0),
              max: new Date().setHours(24, 0, 0, 0),
            },
          }],
          yAxes: [{
            ticks: {
              max: 100,
              min: 0,
            }
          }]
        },
        elements: {
          line: {
            tension: 0
          }
        },
      }
    });
  }

  private scalesChart(telemetry: HistoricalDataTelemetryResponse[]) {
    let dates: number[] = [];
    telemetry.forEach(x => {
      dates.push(Math.min(...x.telemetries.map(i => Math.floor(i.timestamp))));
      dates.push(Math.max(...x.telemetries.map(i => Math.floor(i.timestamp))));
    })
    let minDate = Math.min(...dates);
    let maxDate = Math.max(...dates);
    let diffDays = ((maxDate - minDate) / (3600 * 24)) | 0;
    if (diffDays <= 1) {
      return {
        unit: 'minute',
        displayFormats: {
          hour: 'HH:mm',
          day: 'MM.DD.YYYY',
          month: 'MMM,YY',
          year: 'YYYY',
        },
        tooltipFormat: 'MMM DD, YYYY, HH:mm',
      }
    }

    if (diffDays <= 21) {
      return {
        unit: 'hour',
        displayFormats: {
          hour: 'HH:mm',
          day: 'MM.DD.YYYY',
          month: 'MMM,YY',
          year: 'YYYY',
        },
        tooltipFormat: 'MMM DD, YYYY, HH:mm',
      }
    }

    if (diffDays <= 180) {
      return {
        unit: 'day',
        displayFormats: {
          hour: 'HH:mm',
          day: 'DD',
          month: 'MMM YYYY',
          year: 'YYYY'
        },
        tooltipFormat: 'MMM DD, YYYY, HH:mm',
      }
    }

    return {
      unit: 'month',
      displayFormats: {
        hour: 'HH:mm',
        day: 'DD',
        month: 'MMM',
        year: 'YYYY'
      },
      tooltipFormat: 'MMM DD, YYYY, HH:mm',
    }
  }

  private createDataSets(sensors: HistoricalDataTelemetryResponse[]): any {
    if (!sensors || sensors.length == 0) {
      return null;
    }

    let dataset: any[] = [];
    sensors.forEach((t, index) => {
      let label: string;

      let sensor = this._equipmentSensorInfo.find(s => s.id == t.sensorId);
      if (sensor) {
        if (sensor.position == SensorPositionEnum.OnePerEquipment || sensor.position == SensorPositionEnum.CenterOfTheEquipmentScheme) {
          label = sensorTypeLabels[sensor.type];
        } else {
          label = sensorPositionLabels[sensor.position] + ', ' + equipmentSideLabels[sensor.side];
        }
      } else {
        label = 'Sensor ' + (index + 1);
      }

      dataset.push({
        label: label,
        data: this.getSeriesPoints(t.telemetries),
        fill: false,
        borderColor: index < this.chartColors.length ? this.chartColors[index] : this.random_rgba(),
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: true,
      });
    });

    return dataset;
  }

  private getSeriesPoints(telemetry: TelemetryDomain[]) {
    let delta = this.MaxDeviceCircleSeconds;
    let points: { x: Date, y: number }[] = [];
    let previosDt: number = Math.min(...telemetry.map(x => x.timestamp));
    telemetry.forEach(pt => {
      let diff: number = pt.timestamp - previosDt;
      if (diff > delta) {
        points.push({ x: new Date((pt.timestamp - diff / 2) * CalendarDefaultValues.defaultOneSecondMilliseconds), y: null });
      }
      points.push({ x: new Date(pt.timestamp * CalendarDefaultValues.defaultOneSecondMilliseconds), y: pt.value });
      previosDt = pt.timestamp;
    });
    return points;
  }

  private random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ', 1)';
  }

  verticalHoverPlugin = {
    afterDraw: chart => {
      if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
        let activePoint = this.chart.tooltip._active[0];
        let ctx = this.chart.ctx;
        let x = activePoint.tooltipPosition().x;
        let topY = this.chart.chartArea.top;
        let bottomY = this.chart.chartArea.bottom;

        // Set line opts
        ctx.save();
        ctx.lineWidth = 1;
        ctx.setLineDash([3, 3]);
        ctx.strokeStyle = '#FF4949';

        // draw vertical line
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.stroke();
        ctx.restore();
      }
    }
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.BtxHistoricalData);
  }
}