import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { EmailServicePostModel, EmailServiceViewModel } from '@models';
import { BasePopupBehavior } from '../common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConvertDataFunctions, ErrorModel, LocalStorageService } from '../../../../common';
import { EmailServiceDataSourceService } from '../../../services';

import { BtxEventTypesLabel, EmailServiceScheduleLable, ItxEventTypesLabel } from '../../../constants';
import { EmailServiceScheduleEnum, KtxEventTypeEnum } from '../../../enums';

@Component({
  selector: 'greensleeves-email-service-add-edit-popup',
  templateUrl: './email-service-add-edit-popup.component.html',
  styles: []
})
export class EmailServiceAddEditPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy {
  @Input() _btxLocations: { label: string, value: number }[];
  @Input() _ktxLocations: { label: string, value: number }[];
  @Input() _itxLocations: { label: string, value: number }[];
  _isHidden = true;
  _isAddPopup: boolean = true;
  _emailServiceForm: FormGroup;
  _submitted: boolean = false;

  _btxEventTypesOprions: { label: string, value: number }[] = [];
  _ktxEventTypesOprions: { label: string, value: number }[] = [];
  _itxEventTypesOprions: { label: string, value: number }[] = [];
  _scheduleOptions: { label: string, value: number }[] = [];
  _scheduleTypes = EmailServiceScheduleEnum;

  private emailService: EmailServiceViewModel;
  private companyId: number;

  constructor(
    private _formBuilder: FormBuilder,
    private _localStorageService: LocalStorageService,
    private _emailServiceDatasource: EmailServiceDataSourceService,
  ) {
    super();
  }

  get _isSelectedLocations() {
    return this._emailServiceForm.controls.btxLocations.value.length > 0 || this._emailServiceForm.controls.ktxLocations.value.length > 0 || this._emailServiceForm.controls.itxLocations.value.length > 0;
  }

  ngOnInit() {

    for (const [key, value] of Object.entries(EmailServiceScheduleLable)) {
      this._scheduleOptions.push({ label: value, value: Number(key) });
    }

    for (const [key, value] of Object.entries(BtxEventTypesLabel)) {
      this._btxEventTypesOprions.push({ label: value, value: Number(key) });
    }

    this._ktxEventTypesOprions.push({ label: 'Alarm', value: KtxEventTypeEnum.Alarm });

    for (const [key, value] of Object.entries(ItxEventTypesLabel)) {
      this._itxEventTypesOprions.push({ label: value, value: Number(key) });
    }

    const info = this._localStorageService.getLoginInfo();
    this.companyId = info ? info.companyId : 0;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onShow(emailService: EmailServiceViewModel, addPopup: boolean = true) {
    this._isAddPopup = addPopup;
    this._isHidden = false;
    this.emailService = emailService;
    this.buildForm();
  }

  onCancel() {
    this._isHidden = true;
    this._submitted = false;
    this._emailServiceForm.reset();
  }

  needSelectEventTypes(locationControlName: string, eventTypeControlName: string): boolean {
    return this._emailServiceForm.controls[locationControlName].value.length > 0 && this._emailServiceForm.controls[eventTypeControlName].value.length == 0;
  }

  async onSubmit() {
    this._submitted = true;
    if (this._emailServiceForm.invalid || !this._isSelectedLocations || this.needSelectEventTypes('btxLocations', 'btxEventTypes') || this.needSelectEventTypes('ktxLocations', 'ktxEventTypes') || this.needSelectEventTypes('itxLocations', 'itxEventTypes')) {
      return;
    }

    const value = {
      id: this._isAddPopup ? 0 : this.emailService.id,
      ...this._emailServiceForm.value,
      emails: JSON.stringify(this._emailServiceForm.controls.emails.value),
      scheduleTime: ConvertDataFunctions.stringToSeconds(this._emailServiceForm.controls.scheduleTime.value),
      companyId: this.companyId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ktxLocations: this._emailServiceForm.controls.scheduleType.value == this._scheduleTypes.Daily ? [] : this._emailServiceForm.controls.ktxLocations.value,
    } as EmailServicePostModel;

    const result = await this._emailServiceDatasource.addEditSevice(value);
    if (result instanceof ErrorModel) {
      console.log(result);
    }

    this.onCancel();
  }

  onChangesKtxLocation(event) {
    if (this._emailServiceForm.controls.ktxLocations.value.length > 0) {
      this._emailServiceForm.controls.ktxEventTypes.setValue([0]);
    }
    else {
      this._emailServiceForm.controls.ktxEventTypes.setValue([]);
    }
  }

  onAddChip(event) {
    if (!event.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      this._emailServiceForm.controls.emails.value.pop();
    }
  }

  private buildForm() {
    this._emailServiceForm = this._formBuilder.group({
      ...{
        emails: [
          this._isAddPopup ? null : this.emailService.emails,
          Validators.compose([
            Validators.required,
          ])
        ]
      },
      ...{
        btxLocations: [
          this._isAddPopup ? [] : this.emailService.btxLocations.map(x => x.id),
        ]
      },
      ...{
        ktxLocations: [
          this._isAddPopup ? [] : this.emailService.ktxLocations.map(x => x.id),
        ]
      },
      ...{
        itxLocations: [
          this._isAddPopup ? [] : this.emailService.itxLocations.map(x => x.id),
        ]
      },
      scheduleType: [
        this._isAddPopup ? null : this.emailService.scheduleType,
        Validators.compose([
          Validators.required,
        ])],
      scheduleTime: [
        this._isAddPopup ? null : ConvertDataFunctions.secondsToHHMMString(this.emailService.scheduleTime),
        Validators.compose([
          Validators.required,
        ])
      ],
      ...{
        btxEventTypes: [
          this._isAddPopup ? [] : this.emailService.btxEventTypes,
        ]
      },
      ...{
        ktxEventTypes: [
          this._isAddPopup ? [] : this.emailService.ktxEventTypes,
        ]
      },
      ...{
        itxEventTypes: [
          this._isAddPopup ? [] : this.emailService.itxEventTypes,
        ]
      },
    });
  }
}
