import { CapacityUnitEnum, ItxBinTypeEnum, ItxRadarLocationEnum, ItxStorageSortingEnum, SizeMeasureEnum, TemperatureMeasureEnum } from "../enums";
import { FullnessRangeViewModel, ItxBinNameModel } from '../models';
import { PileShale } from './../enums';

export class ItxBinViewModel {
    id: number;
    name: string;
    locationId: number;
    height: number;
    width: number;
    depth: number;
    material: string;
    totalCapacity: number;
    countOfRadars: number;
    binType: ItxBinTypeEnum;
    sizeMeasure: SizeMeasureEnum;
    capacityUnit: CapacityUnitEnum;
    tiltAngle: number;
    roofAngle: number;
    roofCap: number;
    radars: Array<RadarViewModel>;

    isSomeoneUpdateStorage: boolean;
    isSomeoneUpdateRadar: boolean;

    public static ToItxBinRadarViewModel(itxBinViewModel: ItxBinViewModel): ItxBinRadarViewModel {
        const viewModel = new ItxBinRadarViewModel();
        viewModel.id = itxBinViewModel.id;
        viewModel.locationId = itxBinViewModel.locationId;
        viewModel.name = itxBinViewModel.name;
        viewModel.radars = itxBinViewModel.radars;

        return viewModel;
    }

    public static ToNewViewModel(sourceModel: ItxBinViewModel): ItxBinViewModel {
        let viewModel = new ItxBinViewModel();
        viewModel.id = sourceModel.id;
        viewModel.binType = sourceModel.binType;
        viewModel.capacityUnit = sourceModel.capacityUnit;
        viewModel.countOfRadars = sourceModel.countOfRadars;
        viewModel.depth = sourceModel.depth;
        viewModel.height = sourceModel.height;
        viewModel.isSomeoneUpdateRadar = sourceModel.isSomeoneUpdateRadar;
        viewModel.isSomeoneUpdateStorage = sourceModel.isSomeoneUpdateStorage;
        viewModel.locationId = sourceModel.locationId;
        viewModel.material = sourceModel.material;
        viewModel.name = sourceModel.name;
        viewModel.radars = sourceModel.radars;
        viewModel.roofAngle = sourceModel.roofAngle;
        viewModel.roofCap = sourceModel.roofCap;
        viewModel.sizeMeasure = sourceModel.sizeMeasure;
        viewModel.tiltAngle = sourceModel.tiltAngle;
        viewModel.totalCapacity = sourceModel.totalCapacity;
        viewModel.width = sourceModel.width;
        return viewModel;
    }
}

export class ItxBinRadarViewModel {
    id: number;
    name: string;
    locationId: number;
    radars: Array<RadarViewModel>;
}

export class ItxBinGetModel {
    id: number;
    name: string;
    locationId: number;
    height: number;
    width: number;
    depth: number;
    material: string;
    totalCapacity: number;
    countOfRadars: number;
    binType: ItxBinTypeEnum;
    sizeMeasure: SizeMeasureEnum;
    capacityUnit: CapacityUnitEnum;
    tiltAngle: number;
    roofAngle: number;
    roofCap: number;
    radars: Array<RadarViewModel>;

    public static toViewModel(getModel: ItxBinGetModel): ItxBinViewModel {
        const viewModel = new ItxBinViewModel();
        viewModel.id = getModel.id;
        viewModel.name = getModel.name;
        viewModel.locationId = getModel.locationId;
        viewModel.binType = getModel.binType;
        viewModel.height = getModel.height;
        viewModel.width = getModel.width;
        viewModel.depth = getModel.depth;
        viewModel.sizeMeasure = getModel.sizeMeasure;
        viewModel.material = getModel.material;
        viewModel.totalCapacity = getModel.totalCapacity;
        viewModel.capacityUnit = getModel.capacityUnit;
        viewModel.countOfRadars = getModel.radars.length;
        viewModel.radars = getModel.radars;
        viewModel.tiltAngle = getModel.tiltAngle;
        viewModel.roofAngle = getModel.roofAngle;
        viewModel.roofCap = getModel.roofCap;
        return viewModel;
    }
}

export class AddItxBinModel {
    locationId: number;
    name: string;
    height: number;
    width: number;
    depth: number;
    material: string;
    totalCapacity: number;
    binType: ItxBinTypeEnum;
    sizeMeasure: SizeMeasureEnum;
    capacityUnit: CapacityUnitEnum;
    tiltAngle: number;
    roofAngle: number;
    roofCap: number;
    constructor(viewModel: AddItxBinModel) {
        this.locationId = viewModel.locationId;
        this.name = viewModel.name;
        this.height = Number(viewModel.height);
        this.width = Number(viewModel.width);
        this.material = viewModel.material;
        this.totalCapacity = Number(viewModel.totalCapacity);
        this.binType = viewModel.binType;
        this.sizeMeasure = viewModel.sizeMeasure;
        this.capacityUnit = viewModel.capacityUnit;
        this.tiltAngle = Number(viewModel.tiltAngle);
        this.depth = viewModel.depth == undefined ? null : Number(viewModel.depth);
        this.roofAngle = viewModel.roofAngle == undefined ? null : Number(viewModel.roofAngle);
        this.roofCap = viewModel.roofCap == undefined ? null : Number(viewModel.roofCap);
    }
}

export class EditItxBinModel {
    id: number;
    name: string;
    height: number;
    width: number;
    depth: number;
    material: string;
    totalCapacity: number;
    binType: ItxBinTypeEnum;
    sizeMeasure: SizeMeasureEnum;
    capacityUnit: CapacityUnitEnum;
    tiltAngle: number;
    roofAngle: number;
    roofCap: number;
    constructor(viewModel: EditItxBinModel) {
        this.id = viewModel.id;
        this.name = viewModel.name;
        this.height = Number(viewModel.height);
        this.width = Number(viewModel.width);
        this.material = viewModel.material;
        this.totalCapacity = Number(viewModel.totalCapacity);
        this.binType = viewModel.binType;
        this.sizeMeasure = viewModel.sizeMeasure;
        this.capacityUnit = viewModel.capacityUnit;
        this.tiltAngle = Number(viewModel.tiltAngle);
        this.depth = viewModel.depth == null ? null : Number(viewModel.depth);
        this.roofAngle = viewModel.roofAngle == null ? null : Number(viewModel.roofAngle);
        this.roofCap = viewModel.roofCap == null ? null : Number(viewModel.roofCap);
    }
}
export class ItxBinStaticMembersResponse {
    locationId: number;
    locationName: string;
    companyId: number;
    companyName: string;
    acquisitionRate: number;
    fullnessRangeResponses: FullnessRangeViewModel[];
    bins: Array<ItxBinNameModel>
}

export class PeriodToGetliveDataForLocations {
    locationId: number;
    acquisitionRate: number;
}

export class ItxStaticScemeData {
    locationsLiveData: ItxBinStaticMembersResponse[];
    userMeasurePreferences: UserPreferences;
    periodToGetDataForLocations: PeriodToGetliveDataForLocations[]

}
export class ItxSchemeLiveData {
    itxBinSchemeResponses: ItxBinLiveDataViewModel[];
    totalFilteredCount: number;
}
export class LiveDataModel {
    name: string;
    value: number;
    constructor(name: string, value: number) {
        this.name = name;
        this.value = value;
    }
}

export class ItxBinRadarsLiveDataViewModel {
    name: string;
    radars: RadarLiveDataModel[];
}

export class ItxBinTelemetryWithPreference {
    userPreference: UserPreferences;
    telemetry: ItxBinLiveDataModel;
    itxBin: ItxBinGetModel;
    public static toBinLiveDataViewModel(getModel: ItxBinTelemetryWithPreference): ItxBinLiveDataViewModel {
        const viewModel = new ItxBinLiveDataViewModel();
        viewModel.id = getModel.itxBin.id;
        viewModel.locationId = getModel.itxBin.locationId;
        viewModel.binType = getModel.itxBin.binType;
        viewModel.height = getModel.itxBin.height;
        viewModel.sizeUnit = getModel.userPreference.sizeMeasure;
        viewModel.name = getModel.itxBin.name;
        viewModel.material = getModel.itxBin.material;
        viewModel.totalCapacity = getModel.itxBin.totalCapacity;
        viewModel.capacityUnit = getModel.itxBin.capacityUnit;
        viewModel.temperatureUnit = getModel.userPreference.temperatureMeasure;
        viewModel.binPercentFullness = getModel.telemetry.binPercentFullness;
        viewModel.binHeadSpace = getModel.telemetry.binHeadSpace;
        viewModel.binFullnessCapacity = getModel.telemetry.binFullnessCapacity;
        viewModel.binTemperature = getModel.telemetry.binTemperature;
        viewModel.calculatedAvailableCapacity = getModel.telemetry.calculatedAvailableCapacity;
        viewModel.calculatedErrorBound = getModel.telemetry.calculatedErrorBound;
        viewModel.calculatedUsedCapacity = getModel.telemetry.calculatedUsedCapacity;
        viewModel.radars = getModel.telemetry.radars;
        return viewModel;
    }
}

export class ItxBinLiveDataViewModel {
    id: number;
    locationId: number;
    binType: number;
    companyId: number;
    height: number;
    sizeUnit: SizeMeasureEnum;
    name: string;
    locationName: string;
    companyName: string;
    material: string;
    totalCapacity: number;
    capacityUnit: number;
    temperatureUnit: TemperatureMeasureEnum = TemperatureMeasureEnum.Fahrenheit;
    binPercentFullness: number;
    binHeadSpace: number;
    binFullnessCapacity: number;
    binTemperature: number;
    hasNoValue: boolean;
    colorHex: string;
    radars: RadarLiveDataModel[];
    hasRadarError: boolean;
    timestamp: string;
    calculatedUsedCapacity: number;
    calculatedAvailableCapacity: number;
    calculatedErrorBound: number;
}

export class ItxBinLiveDataModel {
    id: number;
    locationId: number;
    radars: RadarLiveDataModel[];
    binPercentFullness: number;
    binHeadSpace: number;
    binFullnessCapacity: number;
    binTemperature: number;
    calculatedUsedCapacity: number;
    calculatedAvailableCapacity: number;
    calculatedErrorBound: number;
    hasRadarError: boolean;
    timestamp: string;
}
export class UserPreferencesPost {
    capacityLoosUnit: CapacityUnitEnum;
    capacityLiquidUnit: CapacityUnitEnum;
    temperatureMeasure: TemperatureMeasureEnum;
    sizeMeasure: SizeMeasureEnum;
}
export class UserPreferences {
    id: number;
    capacityLoosUnit: CapacityUnitEnum;
    capacityLiquidUnit: CapacityUnitEnum;
    temperatureMeasure: TemperatureMeasureEnum;
    sizeMeasure: SizeMeasureEnum;
}

export class RadarViewModel {
    id: number;
    radarId: number;
    itxBinId: number;
    radarIdAddress: number;
    radarLocation: ItxRadarLocationEnum;
    distanceFromBinCenter: number;
    constructor() { }

    public static ToPostModel(model: RadarViewModel): RadarPostModel {
        let postModel = new RadarPostModel();
        postModel.id = model.id;
        postModel.itxBinId = model.itxBinId;
        postModel.radarId = model.radarId;
        postModel.radarIdAddress = model.radarIdAddress;
        postModel.distanceFromBinCenter = model.distanceFromBinCenter;
        postModel.radarLocation = model.radarLocation;
        return postModel;
    }

    public static CreateNewInstance(radarViewModel: RadarViewModel) {
        let postModel = new RadarViewModel();
        postModel.id = Number(radarViewModel.id);
        postModel.itxBinId = Number(radarViewModel.itxBinId);
        postModel.radarIdAddress = Number(radarViewModel.radarIdAddress);
        postModel.radarId = Number(radarViewModel.radarId);
        postModel.distanceFromBinCenter = radarViewModel.distanceFromBinCenter;
        postModel.radarLocation = radarViewModel.radarLocation;
        return postModel;
    }
}

export class RadarLiveDataModel {
    radarId: number;
    temperature: number;
    headSpace: number;
    percentFull: number;
    capacity: number;
    radarLocation: ItxRadarLocationEnum;
    distanceFromBinCenter: number;
    isRadarTelemetryError: boolean;
    pileShape: PileShale;
    fillingStatus: boolean;
}

export class RadarPostModel extends RadarViewModel {
    locationId: number;
    constructor() {
        super();
    }

    public static CreateNewInstance(radarViewModel: RadarPostModel) {
        let postModel = new RadarPostModel();
        postModel.id = Number(radarViewModel.id);
        postModel.itxBinId = Number(radarViewModel.itxBinId);
        postModel.radarIdAddress = Number(radarViewModel.radarIdAddress);
        postModel.radarId = Number(radarViewModel.radarId);
        postModel.locationId = Number(radarViewModel.locationId);
        postModel.distanceFromBinCenter = radarViewModel.distanceFromBinCenter != null ? Number(radarViewModel.distanceFromBinCenter) : null;
        postModel.radarLocation = radarViewModel.radarLocation;
        return postModel;
    }

    ToRadarPostModel(viewModel: RadarViewModel): RadarPostModel {
        let model = new RadarPostModel();

        return model;
    }

}

export class ItxBinFilterParametersRequest {
    constructor(filterLocation: number[], filterCompany: number[], searchBinByName: string, filterStorages: number[]) {
        this.searchBinByName = searchBinByName;
        this.locationIdsToFilter = filterLocation;
        this.companyIdsToFilter = filterCompany;
        this.storageIdsToFilter = filterStorages;
    }

    searchBinByName: string;
    locationIdsToFilter: number[];
    companyIdsToFilter: number[];
    storageIdsToFilter: number[];
}

export class ItxBinFilterParameters {
    constructor() {
        this.searchBinByName = '';
        this.storageIdsToFilter = [];
    }

    searchBinByName: string = '';
    storageIdsToFilter: number[] = [];
    public static toItxBinFilterParametersRequest(itxBinFilterParameters: ItxBinFilterParameters): ItxBinFilterParametersRequest {
        const itxBinFilterParametersRequest = new ItxBinFilterParametersRequest([], [], itxBinFilterParameters.searchBinByName, itxBinFilterParameters.storageIdsToFilter);
        return itxBinFilterParametersRequest;
    }
}

export class ItxBinParametersRequest {
    constructor(itxBinFilterParametersRequest: ItxBinFilterParametersRequest, pagenumber: number, sortParameter: ItxStorageSortingEnum) {
        this.itxBinFilterParametersRequest = itxBinFilterParametersRequest;
        this.itxBinSortParameter = sortParameter;
        this.pageNumber = pagenumber;
    }

    itxBinFilterParametersRequest: ItxBinFilterParametersRequest;
    pageNumber: number;
    itxBinSortParameter: ItxStorageSortingEnum;
}

export class ItxBinQuickEditPostModel {
    id: number;
    material: string;
    name: string;
}

export class ItxLocationReportFilter {
    locationId: number;
    locationName: string;
    companyId: number;
    companyName: string;
    itxBins: ItxBinReportFilter[];
}

export class ItxBinReportFilter {
    id: number;
    name: string;
    material: string;
    binType: ItxBinTypeEnum;
    radars: ItxRadarReportFilter[];
}

export class ItxRadarReportFilter {
    id: number;
    radarId: number;
    radarIdAddress: number;
    radarLocation: ItxRadarLocationEnum;
    distanceFromBinCenter: number;
}