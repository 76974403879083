import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';

import { RoutePaths } from '../common';
import { AuthorizeService, LocalStorageService } from '../common';
import { CommonFunctions } from '../common';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
     private _router: Router,
     private _authService:  AuthorizeService,
     private _localStorageService: LocalStorageService) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      let isValid = this.isLoginInfoValid(next);

      if (isValid) {
        return true;
      } else {
        await this._authService.refreshTokens();
        isValid = this.isLoginInfoValid(next);

        if (isValid) {
          return true;
        }

        this._authService.redirectUrl = state.url;
        this._router.navigate([RoutePaths.SIGN_IN]);
        return false;
      }
  }

  private isLoginInfoValid(next: ActivatedRouteSnapshot): boolean {
    const loginInfo = this._localStorageService.getLoginInfo();
    const expectedRole = next.data.expectedRole;

    return loginInfo && loginInfo.role === expectedRole && CommonFunctions.checkIfTimeNotExpired(loginInfo.expiresAt);
  }
}
