import { Component, OnInit, OnDestroy } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';

import { UserStatusEnum } from './../../../enums';
import { LocationsItxDatasourceService, VendorDataSourceService, } from './../../../services';
import { LocalStorageService, UserRoleEnum } from './../../../../common';
import { VendorViewModel, VendorEditModel } from './../../../models';

@Component({
  selector: 'greensleeves-vendor-edit-popup',
  templateUrl: './vendor-edit-popup.component.html',
  styles: []
})
export class VendorEditPopupComponent implements OnInit, OnDestroy {
  _isHidden = true;
  _vendor: VendorViewModel;
  _submitted = false;
  _nodeSelected: TreeNode[] = [];
  _itxLocations: TreeNode[];
  _noBinSelected = false;
  _disableSubmit = false;
  _selectedStatus;
  private _locationsSubscription: Subscription;
  _role: UserRoleEnum;

  get _isCompanyAdmin() {
    return this._role === UserRoleEnum.CompanyAdmin;
  }
  constructor(private _localStorageService: LocalStorageService,
    private _locationItxData: LocationsItxDatasourceService,
    private _vendorDataServie: VendorDataSourceService) { }

  ngOnInit() {
    const loginInfo = this._localStorageService.getLoginInfo();
    this._role = loginInfo ? loginInfo.role : null;
    if (this._isCompanyAdmin) {
      this._locationItxData.getLocationsWithBinsForCompany(loginInfo ? loginInfo.companyId : 0);
      this._locationsSubscription = this._locationItxData.locationsItx$.subscribe(data => {
        if (data) {
          this._itxLocations = data.map(i => ({ label: i.locationName, key: i.id.toString() + i.locationName, data: i.id, expanded: false, partialSelected: false, children: i.itxBins.map(b => ({ label: b.name, data: b.id, key: b.id.toString() + b.name })) }));
        }
      });
    }
  }

  public show(vendor: VendorViewModel) {
    this._vendor = vendor;
    this._selectedStatus = UserStatusEnum[vendor.status];
    this.clearForm();
    this.setSelectedItem();
    this._isHidden = false;
    this._disableSubmit = false;
  }

  ngOnDestroy() {
    this._locationsSubscription && this._locationsSubscription.unsubscribe();
    this._locationsSubscription = null;
  }

  onCancel() {
    this._vendorDataServie.markCompanyUserForOthersAsUpdated(this._vendor, false);
    this._isHidden = true;
    this._submitted = false;
  }

  nodeSelect(event) {
    if (this._submitted) {
      this._noBinSelected = false;
    }
    if (!event.node.parent) {
      event.node.expanded = true;
    }
  }

  async onSubmit() {
    this._submitted = true;
    this._disableSubmit = true;
    this._noBinSelected = false;
    if (!this._nodeSelected || this._nodeSelected.length == 0) {
      this._noBinSelected = true;
      this._disableSubmit = false;
      this.collapseTree();
      return;
    }

    const editModel = new VendorEditModel();
    editModel.id = this._vendor.id;
    editModel.status = Number(UserStatusEnum[this._selectedStatus]);
    this._nodeSelected.forEach(i => {
      if (i.parent) {
        editModel.itxStorageIds.push(i.data);
      }
    });
    try {
      const result = await this._vendorDataServie.editForCompany(editModel);
      if (result) {
        this._isHidden = true;
        this._submitted = false;
        this.clearForm();
      }
    } catch (error) {
      this._disableSubmit = false;
      console.log('ERROR', error)
    }

  }

  private setSelectedItem() {
    this._itxLocations.forEach(item => {
      this._vendor.itxLocations.forEach(value => {
        if (item.data == value.id) {
          item.expanded = true;
          let parentNode = { label: value.name, key: value.id.toString() + value.name, data: value.id, expanded: true, children: undefined, parent: undefined, partialSelected: true };
          var childrenNode = value.bins.map(b => ({ label: b.name, data: b.id, key: b.id.toString() + b.name, parent: parentNode, partialSelected: false }));
          parentNode.children = childrenNode;
          if (value.bins.length == item.children.length) {
            parentNode.partialSelected = false;
            this._nodeSelected.push(parentNode);
          }
          this._nodeSelected.push(...childrenNode);
          item.partialSelected = parentNode.partialSelected;
        }
      });
    });
  }

  private collapseTree() {
    this._itxLocations.forEach(i => i.expanded = false);
  }

  private clearForm() {
    this._submitted = false;
    this._noBinSelected = false;
    this._itxLocations.forEach(i => { i.partialSelected = false });
    this._nodeSelected = [];
    this.collapseTree()
    this._disableSubmit = false;
  }
}
