import { AqsFlushFloorTunnelDesignEnum } from "../enums";

export const AqsFlushFloorDesignTunnelForTwoFansLabel = {
    [AqsFlushFloorTunnelDesignEnum.DoubleH]: 'Double H',
    [AqsFlushFloorTunnelDesignEnum.TwoRuns]: 'Two Runs',
    [AqsFlushFloorTunnelDesignEnum.DoubleTSpecial]: 'Double T Special',
    [AqsFlushFloorTunnelDesignEnum.DoubleT]: 'Double T',
}

export const AqsFlushFloorDesignTunnelForFourFansLabel = {
    [AqsFlushFloorTunnelDesignEnum.FourLaneSpecial]: '4 Lane Special',
    [AqsFlushFloorTunnelDesignEnum.FourLaneSpeciaWithTee]: '4 Lane Special With Tee',
}

export const AqsFlushFloorDesignTunnelForSixFansLabel = {
    [AqsFlushFloorTunnelDesignEnum.SixLaneSpecial]: '6 Lane Special',
}

export const AqsFlushFloorDesignVelocityTableHeaderLabel = {
    valueLabel: 'Value',
    crossSectionLabel: 'Cross sectional velocity (ft/s)',
    surfaceVelocityLabel: 'Surface velocity (ft/s)',
}