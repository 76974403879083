import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiRoutes, ErrorModel, HttpService } from './../../../common';
import { AccountProfileModel } from './../../models';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router,
  ) { }

  public async getProfile(): Promise<AccountProfileModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      let result = this._httpService.get(ApiRoutes.Account.getProfile);
      if (result) {
        return result;
      }
    }
    catch (error) {
      return this.processError(error);
    }
  }

  public async editProfile(profile: AccountProfileModel) {
    try {
      let result = this._httpService.post(ApiRoutes.Account.editProfile, profile);
      if (result) {
        return result;
      }
    }
    catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  private getFakeData() {
    let profile = {
      userId: 0,
      firstName: 'FakeFirstName',
      lastName: 'FakeLastName',
      companyName: 'FakeCompanyName',
    } as AccountProfileModel;
    return profile;
  }
}
