import { Component, OnInit } from '@angular/core';

import { ServiceUserDeleteModel } from '../../../models';
import { SalesUsersDatasourceService, ServiceUsersDataSourceService } from '../../../services';

@Component({
  selector: 'greensleeves-service-user-delete-popup',
  templateUrl: './service-user-delete-popup.component.html',
  styles: []
})

export class ServiceUserDeletePopupComponent implements OnInit {
  _isHidden = true;
  _submitted = false;
  _serviceUser: ServiceUserDeleteModel;
  private salesUserId: number;
  private isServiceUser: boolean;

  constructor(
    private _serviceUserDataSourceService: ServiceUsersDataSourceService,
    private _salesUserService: SalesUsersDatasourceService
  ) { }

  ngOnInit() {
  }

  public show(serviceUser: ServiceUserDeleteModel) {
    this._submitted = false;
    this._isHidden = false;
    this._serviceUser = serviceUser;
    this.isServiceUser = true;
  }

  public showSalesUser(salesUserId: number) {
    this._submitted = false;
    this._isHidden = false;
    this.isServiceUser = false;
    this.salesUserId = salesUserId;
  }

  async onSubmit() {
    this._submitted = true;
    const result = this.isServiceUser ? await this._serviceUserDataSourceService.delete(this._serviceUser.id)
      : await this._salesUserService.delete(this.salesUserId);
    if (result != null) {
      this._isHidden = true;
      this._serviceUser = null;
      this.salesUserId = 0;
    }
  }

  onCancel() {
    this._isHidden = true;
    this._serviceUser = null;
    this.salesUserId = 0;
  }
}