import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import {
  LocationEquipmentsInUpdateHubMessage, LocationEquipmentsUpdatedHubMessage, LocationDeviceInInitializationHubMessage, LocationDeviceInitializedHubMessage, LocationBtxDeletedHubMessage, LocationBtxInUpdateHubMessage, LocationBtxAddedHubMessage, LocationBtxViewModel, LocationBtxPostModel, LocationBtxUpdatedHubMessage, LocationBtxEquipmentViewModel, LocationBtxEquipmentPostModel, CompanyViewModel, BtxLiveDataLocationViewModel, SensorInfo, BTXLocationConfiguration
} from '../../models';
import { EquipmentApiService, HubService, LocationsBtxApiService } from '../http';
import { HubMethods } from '../../constants';
import { LastTelemetrySensorResponse } from '../../models/lastTelemetrySensorResponse.model';
import { HistoricalDataFilter, HistoricalDataTelemetryResponse } from '../../models/historicalDataTelemetryResponse.model';

@Injectable({
  providedIn: 'root'
})
export class LocationsBtxDataSourceService {
  public locationsBtx$: BehaviorSubject<LocationBtxViewModel[]> = new BehaviorSubject([]);
  public locationsBtxForCompany$: BehaviorSubject<LocationBtxViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  public companiesWithLocations$: BehaviorSubject<CompanyViewModel[]> = new BehaviorSubject([]);
  public liveDataLocations$: BehaviorSubject<BtxLiveDataLocationViewModel[]> = new BehaviorSubject([]);
  public selectedEquipmentId$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(
    private _locationsBtxApi: LocationsBtxApiService,
    private _equipmentApi: EquipmentApiService,
    private _hubService: HubService,) {
    this.subscribeToHubService();
  }

  public async get() {
    try {
      let data = this.locationsBtx$.getValue();
      if (!data || data && data.length == 0) {
        const data = await this._locationsBtxApi.getAll();
        this.locationsBtx$.next(data as LocationBtxViewModel[]);
      }
      else {
        this.locationsBtx$.next(data);
      }

      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getForCompany(companyId: number) {
    try {
      const data = await this._locationsBtxApi.getAllForCompany(companyId);
      if (data) {
        this.locationsBtxForCompany$.next(data as LocationBtxViewModel[]);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async add(location: LocationBtxViewModel): Promise<LocationBtxViewModel | ErrorModel> {
    try {
      const locationPostModel = new LocationBtxPostModel(location);
      const result = await this._locationsBtxApi.add(locationPostModel) as LocationBtxViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxAdded, new LocationBtxAddedHubMessage(result));
        this.locationsBtx$.next([
          ...this.locationsBtx$.getValue(),
          result
        ]);
        return Promise.resolve(result);
      }

      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async edit(location: LocationBtxViewModel): Promise<LocationBtxViewModel | ErrorModel> {
    try {
      const locationPutModel = new LocationBtxPostModel(location);
      const result = await this._locationsBtxApi.edit(locationPutModel) as LocationBtxViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxUpdated, new LocationBtxUpdatedHubMessage(result, true));
        this.locationsBtx$.next(this.locationsBtx$.getValue().map(l => l.deviceId === result.deviceId ? result : l));
        return Promise.resolve(result);
      }

      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getEquipment(deviceId: string): Promise<LocationBtxEquipmentViewModel> {
    let location = this.locationsBtx$.getValue().find(x => x.deviceId === deviceId);
    if (!location) {
      await this.get();
      location = this.locationsBtx$.getValue().find(x => x.deviceId === deviceId);
    }

    try {
      const equipment = await this._equipmentApi.get(deviceId) as LocationBtxEquipmentViewModel;

      return equipment;
    } catch (error) {
      return error;
    }
  }

  public async getSensors(equipmentId: number, locationId: number): Promise<SensorInfo[] | ErrorModel> {
    try {
      const sensors = await this._equipmentApi.getSensors(equipmentId, locationId);
      return sensors;
    } catch (error) {
      return error;
    }
  }

  public async addOrUpdateEquipment(model: LocationBtxEquipmentPostModel): Promise<boolean | ErrorModel> {
    try {
      const result = await this._equipmentApi.addOrUpdate(model);

      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxEquipmentsUpdated, new LocationEquipmentsUpdatedHubMessage(model.Id));
        const location = this.locationsBtx$.getValue().find(l => l.deviceId === model.deviceId);
        location.isInitialized = false;
      }

      return result;
    } catch (error) {
      return false;
    }
  }

  public async uploadToParseConfigurationFile(file: File, locationId: number): Promise<BTXLocationConfiguration | ErrorModel> {
    try {
      const uploadForm = new FormData();
      uploadForm.append('configurationFile', file, file.name);
      uploadForm.append('locationId', locationId.toString());
      const result = await this._locationsBtxApi.uploadToParseConfigurationFile(uploadForm) as BTXLocationConfiguration;
      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async uploadBtxLocationEquipmentsConfigurationFile(locationConfiguration: BTXLocationConfiguration): Promise<void | ErrorModel> {
    try {
      const result = await this._locationsBtxApi.uploadBtxLocationEquipmentsConfigurationFile(locationConfiguration);
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxEquipmentsUpdated, new LocationEquipmentsUpdatedHubMessage(locationConfiguration.locationId));
        const location = this.locationsBtx$.getValue().find(l => l.deviceId === locationConfiguration.deviceId);
        location.isInitialized = false;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async initializeDeviceConfiguration(locationId: number): Promise<boolean | ErrorModel> {
    try {
      await this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxDeviceInInitialization, new LocationDeviceInInitializationHubMessage(locationId));
      const result = await this._locationsBtxApi.initializeDeviceConfiguration(locationId);
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxDeviceInitialized, new LocationDeviceInitializedHubMessage(locationId));
        const location = this.locationsBtx$.getValue().find(l => l.id === locationId);
        location.isInitialized = true;
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadConnectionFile(locationId: number): Promise<Blob | ErrorModel> {
    try {
      const result = await this._locationsBtxApi.downloadConnectionFile(locationId);

      return Promise.resolve(new Blob([result as BinaryType], { type: 'application/zip' }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async delete(locationId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._locationsBtxApi.delete(locationId);

      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxDeleted, new LocationBtxDeletedHubMessage(locationId));
        const locations = this.locationsBtx$.getValue().filter(l => l.id !== locationId);
        this.locationsBtx$.next(locations);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getLastData(locationId: number, equipmentId: number, equipmentSensorIDs: number[]): Promise<LastTelemetrySensorResponse[] | ErrorModel> {
    try {
      let data = await this._equipmentApi.getLastData(locationId, equipmentId, equipmentSensorIDs);
      return data;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getHistoricalDataForThePeriod(request: HistoricalDataFilter): Promise<HistoricalDataTelemetryResponse[] | ErrorModel> {
    try {
      let data = await this._equipmentApi.getHistoricalDataForThePeriod(request);
      return data;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async markLocationForOthersAsInUpdate(locationId: number): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxInUpdate, new LocationBtxInUpdateHubMessage(locationId));
  }

  public async markLocationForOthersAsUpdated(location: LocationBtxViewModel, withChanges: boolean): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxUpdated, new LocationBtxUpdatedHubMessage(location, withChanges));
  }

  public async markLocationEquipmentsForOthersAsInUpdate(locationId: number): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxEquipmentsInUpdate, new LocationEquipmentsInUpdateHubMessage(locationId));
  }

  public async markLocationEquipmentsForOthersAsUpdated(locationId: number): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.LocationBtx.locationBtxEquipmentsUpdated, new LocationEquipmentsInUpdateHubMessage(locationId));
  }

  public async getCompaniesWithLocations(withEquipments = false) {
    try {
      const bihaveData = this.companiesWithLocations$.getValue();
      if (!bihaveData || bihaveData && bihaveData.length == 0) {
        const data = await this._locationsBtxApi.getCompaniesWithLocations(withEquipments);
        this.companiesWithLocations$.next(data as CompanyViewModel[]);
        this.errors$.next(null);
      } else this.companiesWithLocations$.next(bihaveData as CompanyViewModel[]);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getLiveDataLocations() {
    try {
      const cachData = this.liveDataLocations$.getValue();
      if (!cachData || cachData.length == 0) {
        const data = await this._locationsBtxApi.getLiveDataLocations();
        this.liveDataLocations$.next(data as BtxLiveDataLocationViewModel[]);
        this.errors$.next(null);
      }
      else {
        this.liveDataLocations$.next(cachData as BtxLiveDataLocationViewModel[]);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  private subscribeToHubService() {
    this._hubService.locationBtxAdded.subscribe((hubMessage: LocationBtxAddedHubMessage) => {
      if (hubMessage && hubMessage.newLocation) {
        this.locationsBtx$.next([
          ...this.locationsBtx$.getValue(),
          hubMessage.newLocation
        ]);
      }
    });
    this._hubService.locationBtxDeleted.subscribe((hubMessage: LocationBtxDeletedHubMessage) => {
      if (hubMessage) {
        const locations = this.locationsBtx$.getValue().filter(l => l.id !== hubMessage.locationId);
        this.locationsBtx$.next(locations);
      }
    });
    this._hubService.locationBtxInUpdate.subscribe((hubMessage: LocationBtxInUpdateHubMessage) => {
      if (hubMessage) {
        const location = this.locationsBtx$.getValue().find(l => l.id === hubMessage.locationId);
        if (location) {
          location.isSomeoneUpdateLocation = true;
        }
      }
    });
    this._hubService.locationBtxUpdated.subscribe((hubMessage: LocationBtxUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedLocation) {
        if (hubMessage.withChanges) {
          hubMessage.updatedLocation.isSomeoneUpdateLocation = undefined;
          this.locationsBtx$.next(this.locationsBtx$.getValue().map(l => {
            if (l.id === hubMessage.updatedLocation.id) {
              return hubMessage.updatedLocation;
            } else {
              return l;
            }
          }));
        } else {
          const location = this.locationsBtx$.getValue().find(l => l.id == hubMessage.updatedLocation.id);
          location.isSomeoneUpdateLocation = false;
        }
      }
    });
    this._hubService.locationBtxEquipmentsInUpdate.subscribe((hubMessage: LocationEquipmentsInUpdateHubMessage) => {
      if (hubMessage) {
        const location = this.locationsBtx$.getValue().find(l => l.id === hubMessage.locationId);
        if (location) {
          location.isSomeoneUpdateLocation = true;
        }
      }
    });
    this._hubService.locationBtxEquipmentsUpdated.subscribe((hubMessage: LocationEquipmentsUpdatedHubMessage) => {
      if (hubMessage) {
        const location = this.locationsBtx$.getValue().find(l => l.id === hubMessage.locationId);
        if (location) {
          location.isSomeoneUpdateLocation = false;
          location.isInitialized = false;
        }
      }
    });
    this._hubService.locationBtxDeviceInInitialization.subscribe((hubMessage: LocationDeviceInInitializationHubMessage) => {
      if (hubMessage) {
        const location = this.locationsBtx$.getValue().find(l => l.id === hubMessage.locationId);
        if (location) {
          location.isDeviceInInitialization = true;
        }
      }
    });
    this._hubService.locationBtxDeviceInitialized.subscribe((hubMessage: LocationDeviceInitializedHubMessage) => {
      if (hubMessage) {
        const location = this.locationsBtx$.getValue().find(l => l.id === hubMessage.locationId);
        if (location) {
          location.isDeviceInInitialization = false;
          location.isInitialized = true;
        }
      }
    });
  }

  clearBehaveCache() {
    this.locationsBtx$.next([]);
    this.liveDataLocations$.next([]);
    this.companiesWithLocations$.next([]);
  }
}
