import { AqsFlatDuctingShapeTypeEnum } from "../../../enums";

export class AqsFlatDuctModel {
  id: number;
  ductType: AqsFlatDuctingShapeTypeEnum;
  near: AqsFlatDuctSideParamsModel;
  far: AqsFlatDuctSideParamsModel;
  front: AqsFlatDuctSideParamsModel;
  rear: AqsFlatDuctSideParamsModel;
  ductDiameter: number; //DUCTDIA
  openingDiameter: number; //OPENDIA

  constructor() {
    this.ductType = AqsFlatDuctingShapeTypeEnum.FullRound;
    this.near = new AqsFlatDuctSideParamsModel();
    this.near.name = 'Near';
    this.far = new AqsFlatDuctSideParamsModel();
    this.far.name = 'Far';
    this.front = new AqsFlatDuctSideParamsModel();
    this.front.name = 'Front';
    this.rear = new AqsFlatDuctSideParamsModel();
    this.rear.name = 'Rear';
    this.id = 0;
  }

  clearModel() {
    this.ductType = AqsFlatDuctingShapeTypeEnum.FullRound;
    this.near = new AqsFlatDuctSideParamsModel();
    this.near.name = 'Near';
    this.far = new AqsFlatDuctSideParamsModel();
    this.far.name = 'Far';
    this.front = new AqsFlatDuctSideParamsModel();
    this.front.name = 'Front';
    this.rear = new AqsFlatDuctSideParamsModel();
    this.rear.name = 'Rear';
  }
}

export class AqsFlatDuctSideParamsModel {
  id: number;
  name: string;
  numberFansPerSide: number;
  ctroctr: number;
  perfPerRun: number;
  solidPerRun: number;
  distanceFromEdge: number;
  maxDuctLenght: number;
  idealDuctLenght: number;
}
