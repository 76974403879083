import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, NgZone, Output, EventEmitter, ViewChild } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ColumnTypeEnum, PLCDeviceEnum, ProjectEnum, } from '../../../enums';
import { LocationViewModel, LocationBtxEquipmentViewModel, LocationBtxViewModel } from '../../../models';
import { LocationsBtxDataSourceService } from '../../../services';
import { DeviceCommunicationPopupComponent, EquipmentPopupComponent, LocationBtxKtxDeletePopupComponent } from '../../popups';
import { UploadBtxLocationConfigurationFilePopupComponent } from '../../popups/upload-btx-location-configuration-file-popup';
import { LocalStorageService, UserRoleEnum } from '../../../../common';

@Component({
  selector: 'greensleeves-btx-grid',
  templateUrl: './btx-grid.component.html'
})

export class BtxGridComponent implements OnInit, OnDestroy, AfterViewInit {
  static LOCATION_NAME_KEY = 'locationName';
  static COMPANY_NAME_KEY = 'companyName';
  static SORT_ORDER_DEFAULT = 1;

  @ViewChild(EquipmentPopupComponent, { read: false, static: false })
  _equipmentPopup: EquipmentPopupComponent;

  @ViewChild(LocationBtxKtxDeletePopupComponent, { read: false, static: false })
  _locationBtxDeletePopup: LocationBtxKtxDeletePopupComponent;

  @ViewChild(DeviceCommunicationPopupComponent, { read: false, static: false })
  private deviceCommunicationPopup: DeviceCommunicationPopupComponent;

  @ViewChild(UploadBtxLocationConfigurationFilePopupComponent, { read: false, static: false })
  private _uploadBtxLocationConfigurationFilePopupComponent: UploadBtxLocationConfigurationFilePopupComponent;

  @Output()
  onEditClicked = new EventEmitter<LocationViewModel>();

  _columnTypeEnum = ColumnTypeEnum;
  _deviceEnum = PLCDeviceEnum;
  _sortField = BtxGridComponent.LOCATION_NAME_KEY;
  _sortOrder = BtxGridComponent.SORT_ORDER_DEFAULT;
  _gridData: LocationBtxViewModel[];
  _companyNameKey = BtxGridComponent.COMPANY_NAME_KEY;
  _locationNameKey = BtxGridComponent.LOCATION_NAME_KEY;
  _searchPhrase: string = '';
  _ps: PerfectScrollbar;
  _initializeDeviceKey = 'isInitialized';
  _role: UserRoleEnum;

  get _isServiceUser(): boolean {
    return this._role === UserRoleEnum.ServiceUser;
  }

  _columnsView = [
    { header: '', columnType: ColumnTypeEnum.Hardware, width: 50, dataField: 'deviceLabel' },
    { header: 'Location', columnType: ColumnTypeEnum.LocationName, dataField: BtxGridComponent.LOCATION_NAME_KEY },
    { header: 'Company', columnType: ColumnTypeEnum.Company, dataField: BtxGridComponent.COMPANY_NAME_KEY, width: 130 },
    { header: 'P1AM MAC Address', columnType: ColumnTypeEnum.P1AMMac, dataField: 'deviceMacAddress' },
    { header: 'P1AM IP Address', columnType: ColumnTypeEnum.P1AMIp, dataField: 'deviceIp' },
    { header: 'BTX IP Address', columnType: ColumnTypeEnum.IPAddress, dataField: 'btxIp' },
    { header: 'Port', columnType: ColumnTypeEnum.Port, dataField: 'btxPort', width: 90 },
    { header: 'Acq rate', columnType: ColumnTypeEnum.AcquisitionRate, dataField: 'acquisitionRate', width: 90 },
  ]

  _frozenColumns = [
    { header: '', columnType: ColumnTypeEnum.InitializeDevice, width: 46 },
    { header: '', columnType: ColumnTypeEnum.DownloadConnectionFile, width: 46 },
    { header: '', columnType: ColumnTypeEnum.UploadConfigFile, width: 46 },
    { header: '', columnType: ColumnTypeEnum.Equipment, width: 46 },
    { header: '', columnType: ColumnTypeEnum.Edit, width: 46 },
    { header: '', columnType: ColumnTypeEnum.Delete, width: 46 }
  ]

  _locationsSubscription: Subscription;
  _locationsErrorsSubscription: Subscription;

  _searchForm: FormGroup;
  _lastSearchPhrase: string;

  _initLoaders: { [key: number]: boolean } = {};
  _loading: boolean = false;
  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  constructor(
    private _locationsBtxDataSourceService: LocationsBtxDataSourceService,
    private _localStorageService: LocalStorageService,
    private _el: ElementRef,
    private _zone: NgZone,
    formBuilder: FormBuilder) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;

    if(!this._isServiceUser){
      this._frozenColumns = this._frozenColumns.filter(x => x.columnType !== ColumnTypeEnum.UploadConfigFile);
    }

    this.reinitScrollBar(0);
    this._locationsSubscription = this._locationsBtxDataSourceService.locationsBtx$.subscribe(items => {
      this._gridData = items;
      this._loading = false;
    });
    this._loading = true;
    this._locationsBtxDataSourceService.get();
  }

  ngOnDestroy() {
    this._locationsSubscription && this._locationsSubscription.unsubscribe();
    this._locationsSubscription = null;

    this._locationsErrorsSubscription && this._locationsErrorsSubscription.unsubscribe();
    this._locationsErrorsSubscription = null;

    this._ps && this._ps.destroy();
    this._ps = null;
  }

  ngAfterViewInit() {
    this.reinitScrollBar(0);
  }

  async onEdit(location: LocationViewModel) {
    await this._locationsBtxDataSourceService.markLocationForOthersAsInUpdate(location.id);
    this.onEditClicked.emit(location);
  }

  async onManageEquipmentClick(location: LocationBtxEquipmentViewModel) {
    await this._locationsBtxDataSourceService.markLocationEquipmentsForOthersAsInUpdate(location.id);
    let locationEquipment = await this._locationsBtxDataSourceService.getEquipment(location.deviceId);
    locationEquipment = {
      ...locationEquipment,
      ...location
    }
    this._equipmentPopup.show(locationEquipment);
  }

  async onClickInitialized(location: LocationBtxViewModel) {
    if (!location) {
      return;
    }

    location.isDeviceInInitialization = true;
    location.isInitialized = await this._locationsBtxDataSourceService.initializeDeviceConfiguration(location.id) as boolean;
    location.isDeviceInInitialization = false;
  }

  async onClickDownloadFile(location: LocationBtxViewModel) {
    if (!location) {
      return;
    }

    if (location.plcDeviceConnection == this._deviceEnum.Raspberry) {
      this.deviceCommunicationPopup.onShow({ locationName: location.locationName, locationId: location.id, deviceId: location.deviceId, project: ProjectEnum.Btx });
    } else {
      let result = await this._locationsBtxDataSourceService.downloadConnectionFile(location.id);

      if (result instanceof Blob) {
        const url = URL.createObjectURL(result as Blob);
        let downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = 'configuration.zip';
        downloadLink.click();
      }
    }
  }

  async onClickUploadLocationConfigurationFile(location: LocationBtxViewModel) {
    if (!location) {
      return;
    }

    this._uploadBtxLocationConfigurationFilePopupComponent.show(location);
  }

  public reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  _updateScroll() {
    this._ps && this._ps.update();
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
  }

  async onClickDelete(location: LocationBtxViewModel) {
    if (!location) {
      return;
    }
    this._locationBtxDeletePopup.show(location);
  }
}