import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationUpdaterViewModel, UpdateDeviceViewModel } from '@models';
import { UpdaterDeviceDatasourceService } from '@services';
import { ProjectEnum } from 'src/app/main/enums';

import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-updater-add-edit-popup',
  templateUrl: './updater-add-edit-popup.component.html',
  styleUrls: []
})
export class UpdaterAddEditPopupComponent extends BasePopupBehavior implements OnInit {
  @Output() onEdetUpdater = new EventEmitter<UpdateDeviceViewModel>();
  _updater: UpdateDeviceViewModel;
  _location: LocationUpdaterViewModel;
  _updaterForm: FormGroup;
  _submitted = false;
  _hasSubmittionErrors = false;
  _isHidden = true;
  _isAddPopup = true;
  _projects: { label: string, value: number }[];
  _selectedProjects: number[];

  constructor(
    private _formBuilder: FormBuilder,
    private _updaterDeviceDatasourceService: UpdaterDeviceDatasourceService,
  ) {
    super();
  }

  ngOnInit() {
    this._updater = new UpdateDeviceViewModel();
    this._location = new LocationUpdaterViewModel();
  }

  public showEdit(updater: UpdateDeviceViewModel, location: LocationUpdaterViewModel) {
    this._isHidden = false;
    this._submitted = false;
    this._isAddPopup = false;
    this._updater = updater;
    this._location = location;

    this._projects = this.selectAvailableProjects();

    this._selectedProjects = [];

    for (const value in ProjectEnum) {
      if (typeof ProjectEnum[value as keyof typeof ProjectEnum] === 'number') {
        const enumValue = ProjectEnum[value as keyof typeof ProjectEnum];
        const valueToCheck = updater.projectsAvailable & enumValue;
        if (valueToCheck !== 0 && enumValue !== 127) {
          this._selectedProjects.push(enumValue);
        }
      }
    }

    this._selectedProjects.forEach(p => this._projects.push({ label: ProjectEnum[p], value: p }));

    this.initForm();
  }

  public showAdd(location: LocationUpdaterViewModel) {
    this._isHidden = false;
    this._submitted = false;
    this._isAddPopup = true;
    this._location = location;

    this._projects = this.selectAvailableProjects();

    this.initForm();
  }

  async onSubmit() {
    this._submitted = true;

    if (this._updaterForm.invalid) {
      this._submitted = false;
      this._hasSubmittionErrors = true;
      return;
    }

    const value = {
      locationId: this._location.id,
      projectsAvailable: this._selectedProjects.reduce((accum, current) => accum + current, 0)
    };

    this._isAddPopup
      ? await this._updaterDeviceDatasourceService.add(value)
      : await this._updaterDeviceDatasourceService.edit(this._updater.id, { projectsAvailable: value.projectsAvailable });

    this._isHidden = true;
    this._submitted = false;
    this._hasSubmittionErrors = false;
    if (!this._isAddPopup) {
      this.onEdetUpdater.emit(this._updater);
    }
    this._updaterForm.reset();
  }

  async onClose() {
    this._submitted = false;
    this._isHidden = true;
    this._isAddPopup = true;
    this._location = new LocationUpdaterViewModel();
    this._updaterForm.reset();
  }

  private initForm() {
    this._updaterForm = this._formBuilder.group({
      projects: [this._selectedProjects, Validators.compose([
        Validators.required,
      ])],
    })
  }

  private selectAvailableProjects(): { label: string, value: number }[] {
    this._projects = this._location.locationDevices.map((d) => ({ label: ProjectEnum[d.project], value: d.project }));

    let projectsWithUpdaters = new Set<number>();
    this._location.updaterDevices.forEach(u => {
      u.projectDevices.forEach(p => {
        projectsWithUpdaters.add(p.project);
      })
    });

    return this._projects = this._projects.filter(p => !projectsWithUpdaters.has(p.value));
  }
}
