import { switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  AqsAirflowViewModel, AqsCoverSheetModel,
  AqsExhausterViewModel, AqsFlatDuctModel,
  AqsSelectedFanModel, FlatStorageParams,
  IncludeFlowOption, QuoteFlatStorageViewModel,
  QuoteFlatStorageReportModel
} from '@models';
import { AqsFlushDuctDesignEnum, AqsPagesEnum, AqsSessionTypeEnum } from '../../enums';
import { AqsPageLabel } from '../../constants';
import { AqsAirflowDatasourceService, AqsQuoteStorageDataSourceService } from '../../services';
import { AqsCoverSheetComponent } from '../common';
import { ErrorCodeEnum, ErrorModel } from '../../../common';
import { AqsComputationSheetResavePopupComponent, ComputationSheetReportPopupComponent, ComputationSheetShareReportPopupComponent } from '@popups';

@Component({
  selector: 'greensleeves-aqs-flat-storage-tab',
  templateUrl: './aqs-flat-storage-tab.component.html',
  styles: []
})
export class AqsFlatStorageTabComponent implements OnInit {
  @ViewChild(AqsCoverSheetComponent, { read: false, static: false })
  private coverSheetComponent: AqsCoverSheetComponent;

  @ViewChild(ComputationSheetReportPopupComponent, { read: false, static: false })
  private _exportReportModal: ComputationSheetReportPopupComponent;

  @ViewChild(AqsComputationSheetResavePopupComponent, { read: false, static: false })
  private reSaveModal: AqsComputationSheetResavePopupComponent;

  @ViewChild(ComputationSheetShareReportPopupComponent, { read: false, static: false })
  private _shareReportModal: ComputationSheetShareReportPopupComponent;

  _quoteFlatStorage: QuoteFlatStorageViewModel;
  _pageIndex: AqsPagesEnum;
  _pageEnum = AqsPagesEnum;
  _coverSheetPageIndex: AqsPagesEnum = AqsPagesEnum.CoverSheet;
  _pages: { index: AqsPagesEnum, label: string }[] = [];
  _currentPageError: boolean = false;
  _isSaveRun: boolean = false;
  _isLoad: boolean = false;
  _isSuccessSaved: boolean = false;
  _isChangedTables: boolean = false;

  private title: string = 'Save Flat Storage Quote';

  constructor(private _aqsAirflowService: AqsAirflowDatasourceService, private _route: ActivatedRoute,
    private _quoteDataService: AqsQuoteStorageDataSourceService) { }

  async ngOnInit() {
    this._aqsAirflowService.getAllFanTypesWithFans();
    this._quoteDataService.getAllComputationSheetForBinding();

    this._isLoad = true;
    this._pages.push({ index: AqsPagesEnum.FlatStorageConfiguration, label: AqsPageLabel[AqsPagesEnum.FlatStorageConfiguration] });
    this._quoteFlatStorage = new QuoteFlatStorageViewModel();
    this._quoteFlatStorage.commodities = [];
    this._quoteFlatStorage.flatStorage = new FlatStorageParams();

    let quoteId: number;
    this._route.queryParams.subscribe((queryParam: any) => {
      quoteId = queryParam['id'];
    });

    if (!isNaN(quoteId)) {
      let result = await this._quoteDataService.getQuoteFlatStorage(quoteId);
      if (result) {
        if (!(result instanceof ErrorModel)) {
          let quote = result as QuoteFlatStorageViewModel;
          this._quoteFlatStorage.id = quote.id;
          this._quoteFlatStorage.session = quote.session;
          this._quoteFlatStorage.linkComputationSheetId = quote.linkComputationSheetId;
          this._quoteFlatStorage.airflowConfiguration = new AqsAirflowViewModel();
          Object.assign(this._quoteFlatStorage.airflowConfiguration, result.airflowConfiguration);
          this._quoteFlatStorage.airflowConfiguration.airflowData = [];
          Object.assign(this._quoteFlatStorage.airflowConfiguration.airflowData, result.airflowConfiguration.airflowData);
          this._quoteFlatStorage.airflowConfiguration.selectedFan = result.airflowConfiguration.selectedFan;
          this._quoteFlatStorage.coverSheet = new AqsCoverSheetModel();
          Object.assign(this._quoteFlatStorage.coverSheet, result.coverSheet);
          Object.assign(this._quoteFlatStorage.commodities, result.commodities);
          Object.assign(this._quoteFlatStorage.flatStorage, result.flatStorage);
          this._quoteFlatStorage.includeFlowOption = new IncludeFlowOption();
          Object.assign(this._quoteFlatStorage.includeFlowOption, result.includeFlowOption);
          this._quoteFlatStorage.includeFlowOption.includeDesign = result.includeFlowOption.includeDesign;
          this._pageIndex = AqsPagesEnum.FlatStorageConfiguration;

          if (result.exhausterSheet) {
            this._quoteFlatStorage.exhausterSheet = new AqsExhausterViewModel();
            Object.assign(this._quoteFlatStorage.exhausterSheet, result.exhausterSheet);
          }

          if (result.flatDuctModel) {
            this._quoteFlatStorage.flatDuctModel = new AqsFlatDuctModel();
            Object.assign(this._quoteFlatStorage.flatDuctModel, result.flatDuctModel);
          }

          this.fillPagesList();
        }
        else if (result instanceof ErrorModel && (result.code == ErrorCodeEnum.PermissionError || result.code == ErrorCodeEnum.QuoteNotFound)) {
          console.log(result.errors);
        }
      }
    }

    this._quoteFlatStorage.session = AqsSessionTypeEnum.FlatSessionType;
    this._pageIndex = AqsPagesEnum.FlatStorageConfiguration;
    this._isLoad = false;
  }

  fillPagesList() {
    if (this._quoteFlatStorage.airflowConfiguration)
      this._pages.push({ index: AqsPagesEnum.DetermineTargetAirflow, label: AqsPageLabel[AqsPagesEnum.DetermineTargetAirflow] });
    if (this._quoteFlatStorage.exhausterSheet)
      this._pages.push({ index: AqsPagesEnum.ExhausterRecommendations, label: AqsPageLabel[AqsPagesEnum.ExhausterRecommendations] });
    if (this._quoteFlatStorage.flatDuctModel)
      this._pages.push({ index: AqsPagesEnum.DuctDesign, label: AqsPageLabel[AqsPagesEnum.DuctDesign] });
    if (this._quoteFlatStorage.coverSheet)
      this._pages.push({ index: AqsPagesEnum.CoverSheet, label: AqsPageLabel[AqsPagesEnum.CoverSheet] });
  }

  async onClickSaveButton() {
    if (this.coverSheetComponent.checkModelBeforeSave()) {
      if (this._quoteFlatStorage.id == undefined || this._quoteFlatStorage.id == 0) {
        this._isSaveRun = true;
        let result = await this._quoteDataService.addQuoteFlatStorage(this._quoteFlatStorage);
        if (!(result instanceof ErrorModel)) {
          this._isSuccessSaved = true;
          setTimeout(() => { this._isSuccessSaved = false; }, 3000);
          this._isChangedTables = false;
          this._quoteFlatStorage = result;
        } else {
          console.log(result);
        }
        this._isSaveRun = false;
      }
      else {
        this.reSaveModal.showConfirmPopup(this.title);
      }
    }
  }

  async editQuote() {
    if (this.coverSheetComponent.checkModelBeforeSave()) {
      this._isSaveRun = true;
      let result = await this._quoteDataService.editFlatStorage(this._quoteFlatStorage);
      if (!(result instanceof ErrorModel)) {
        this._isSuccessSaved = true;
        setTimeout(() => { this._isSuccessSaved = false; }, 3000);
        this._isChangedTables = false;
        this._quoteFlatStorage = result;
      } else {
        console.log(result);
      }
      this._isSaveRun = false;
    }
  }

  async saveAsNewQuote() {
    this._quoteFlatStorage.id = 0;
    this._quoteFlatStorage.coverSheet.id = 0;
    this._quoteFlatStorage.flatStorage.id = 0;
    this._quoteFlatStorage.airflowConfiguration.id = 0;
    this._quoteFlatStorage.airflowConfiguration.airflowData.forEach(item => {
      item.id = 0;
    });

    if (this._quoteFlatStorage.includeFlowOption.includeExhauster) {
      this._quoteFlatStorage.exhausterSheet.id = 0;
    }

    if (this._quoteFlatStorage.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.Duct) {
      this._quoteFlatStorage.flatDuctModel.id = 0;
      this._quoteFlatStorage.flatDuctModel.far.id = 0;
      this._quoteFlatStorage.flatDuctModel.front.id = 0;
      this._quoteFlatStorage.flatDuctModel.near.id = 0;
      this._quoteFlatStorage.flatDuctModel.rear.id = 0;
    }
    this.onClickSaveButton();
  }

  onChangePage(newPageIndex: AqsPagesEnum) {
    this._pageIndex = newPageIndex;
  }

  onBackPage() {
    let currentPageindex = this._pages.findIndex(p => p.index == this._pageIndex);
    if (currentPageindex != 0) {
      this._pageIndex = this._pages[--currentPageindex].index;
    }
  }

  onNextPage() {
    switch (this._pageIndex) {
      case AqsPagesEnum.FlatStorageConfiguration:
        if (!this._currentPageError && this._quoteFlatStorage.commodities.length > 0) {
          if (!this._quoteFlatStorage.airflowConfiguration) {
            this._quoteFlatStorage.commodities.sort((a, b) => { return a.name.localeCompare(b.name) });
            this._quoteFlatStorage.airflowConfiguration = new AqsAirflowViewModel();
          }
          if (!this._quoteFlatStorage.includeFlowOption) this._quoteFlatStorage.includeFlowOption = new IncludeFlowOption();
          this.addedNextPage(AqsPagesEnum.DetermineTargetAirflow);
        }
        break;
      case AqsPagesEnum.DetermineTargetAirflow:
        if (this.checkCurrentPage()) {
          if (this._quoteFlatStorage.includeFlowOption.includeExhauster) {
            if (!this._quoteFlatStorage.exhausterSheet) this._quoteFlatStorage.exhausterSheet = new AqsExhausterViewModel();
            this.addedNextPage(AqsPagesEnum.ExhausterRecommendations);
            break;
          }
          else if (this._quoteFlatStorage.includeFlowOption.includeDesign) {
            if (!this._quoteFlatStorage.flatDuctModel) this._quoteFlatStorage.flatDuctModel = new AqsFlatDuctModel();
            this.addedNextPage(AqsPagesEnum.DuctDesign);
            break;
          }
          else {
            this._quoteFlatStorage.exhausterSheet = null;
            this._quoteFlatStorage.flatDuctModel = null;
            if (!this._quoteFlatStorage.coverSheet) this._quoteFlatStorage.coverSheet = new AqsCoverSheetModel();
            this.addedNextPage(AqsPagesEnum.CoverSheet);
          }
        }
        break;
      case AqsPagesEnum.ExhausterRecommendations:
        if (this.checkCurrentPage()) {
          if (this._quoteFlatStorage.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.Duct) {
            if (!this._quoteFlatStorage.flatDuctModel) this._quoteFlatStorage.flatDuctModel = new AqsFlatDuctModel();
            this.addedNextPage(AqsPagesEnum.DuctDesign);
            break;
          } else {
            this._quoteFlatStorage.flatDuctModel = null;
            if (!this._quoteFlatStorage.coverSheet) this._quoteFlatStorage.coverSheet = new AqsCoverSheetModel();
            this.addedNextPage(AqsPagesEnum.CoverSheet);
          }
        }
        break;
      case AqsPagesEnum.DuctDesign:
        if (this.checkCurrentPage()) {
          if (!this._quoteFlatStorage.coverSheet) this._quoteFlatStorage.coverSheet = new AqsCoverSheetModel();
          this.addedNextPage(AqsPagesEnum.CoverSheet);
        }
        break;
    };
  }

  checkCurrentPage() {
    switch (this._pageIndex) {
      case AqsPagesEnum.FlatStorageConfiguration:
        return !this._currentPageError && this._quoteFlatStorage.commodities.length > 0;
      case AqsPagesEnum.DetermineTargetAirflow:
        return this._quoteFlatStorage.airflowConfiguration.airflowData.length > 0 && !this._quoteFlatStorage.airflowConfiguration.airflowData.some(m => m.kcfm == -1 || m.kcfm == null);
      case AqsPagesEnum.ExhausterRecommendations:
        return !this._currentPageError;
      case AqsPagesEnum.DuctDesign:
        return !this._currentPageError;
      default:
        return true;
    }
  }

  changeQuoteModel() {
    this._isChangedTables = true;
    switch (this._pageIndex) {
      case AqsPagesEnum.FlatStorageConfiguration:
        this.removeNextPages(AqsPagesEnum.FlatStorageConfiguration);
        this._quoteFlatStorage.airflowConfiguration = null;
        this._quoteFlatStorage.exhausterSheet = null;
        this._quoteFlatStorage.flatDuctModel = null;
        break;
      case AqsPagesEnum.DetermineTargetAirflow:
        this.removeNextPages(AqsPagesEnum.DetermineTargetAirflow);
        this._quoteFlatStorage.exhausterSheet = null;
        this._quoteFlatStorage.flatDuctModel = null;
        break;
      case AqsPagesEnum.ExhausterRecommendations:
        break;
      case AqsPagesEnum.DuctDesign:
        break;
    }
  }

  changedFlowOptions() {
    if (this._pageIndex == AqsPagesEnum.DetermineTargetAirflow) {
      this.removeNextPages(AqsPagesEnum.DetermineTargetAirflow);
    }
  }

  onClickExportReport() {
    let model = this.buildReportModel();
    this._exportReportModal.showDownloadQuoteFlatStorageReport(model);
  }

  onClickShareReport() {
    let model = this.buildReportModel();
    this._shareReportModal.showDownloadQuoteFlatStorageReport(model);
  }

  private removeNextPages(fromPages: AqsPagesEnum) {
    let index = this._pages.findIndex(i => i.index == fromPages);
    this._pages.splice(++index, this._pages.length);
  }

  private addedNextPage(aqsPage: AqsPagesEnum) {
    if (!this._pages.some(i => i.index == aqsPage)) {
      this._pages.push({ index: aqsPage, label: AqsPageLabel[aqsPage] });
      this._pageIndex = aqsPage;
    }
    else {
      let currentPageindex = this._pages.findIndex(p => p.index == this._pageIndex);
      this._pageIndex = this._pages[++currentPageindex].index;
    }
  }

  private buildReportModel(): QuoteFlatStorageReportModel {
    let model = new QuoteFlatStorageReportModel();
    model.coverSheet = this._quoteFlatStorage.coverSheet;
    model.flatStorage = this._quoteFlatStorage.flatStorage;
    model.commodities = this._quoteFlatStorage.commodities;
    model.airflowConfiguration = this._quoteFlatStorage.airflowConfiguration;
    model.includeFlowOption = this._quoteFlatStorage.includeFlowOption;
    model.exhausterSheet = this._quoteFlatStorage.exhausterSheet;
    model.ductDesign = this._quoteFlatStorage.flatDuctModel;
    return model;
  }

  checkForError() {
    if (!this._quoteFlatStorage.flatStorage.storageType) {
      console.log('Storage type is undefined: ' + this._quoteFlatStorage.flatStorage.storageType);
    }
    if (this._quoteFlatStorage.airflowConfiguration.airflowData.length === 0) {
      console.log('Airflow data is empty, length = ' + this._quoteFlatStorage.airflowConfiguration.airflowData.length);
    }
    if (this._quoteFlatStorage.airflowConfiguration.numberfans === undefined) {
      console.log('Number of fans is undefined: ' + this._quoteFlatStorage.airflowConfiguration.numberfans);
    }
    if (this._quoteFlatStorage.airflowConfiguration.numberfans === 0) {
      console.log('Number of fans is 0: ' + this._quoteFlatStorage.airflowConfiguration.numberfans);
    }
  }
}
