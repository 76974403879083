import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { BinSchemeViewModel, LocationKtxViewModel, TemperatureRangeViewModel } from './../../models';
import {
  KtxBinDetailsPopupComponent,
  KtxListCablesPopupComponent,
  KtxLocationAddEditPopupComponent,
  KtxLocationBinsPopupComponent,
  UploadConfigurationFilePopupComponent,
  SuccessOperationPopupComponent,
  KtxTemperatureRangesPopupComponent,
  LocationBtxKtxDeletePopupComponent,
  KtxUnitDetailsPopupComponent,
  KtxRangeDeletePopupComponent,
  KtxConnectionPopupComponent
} from '../popups';
import { KtxGridComponent } from './ktx-grid';

@Component({
  selector: 'greensleeves-ktx-tab',
  templateUrl: './ktx-tab.component.html'
})
export class KtxTabComponent implements AfterViewInit, OnDestroy {
  @ViewChild(KtxLocationAddEditPopupComponent, { read: false, static: false })
  private _addEditModal: KtxLocationAddEditPopupComponent;

  @ViewChild(KtxLocationBinsPopupComponent, { read: false, static: false })
  private _binsModal: KtxLocationBinsPopupComponent;

  @ViewChild(KtxBinDetailsPopupComponent, { read: false, static: false })
  private _binDetailsModal: KtxBinDetailsPopupComponent;

  @ViewChild(KtxListCablesPopupComponent, { read: false, static: false })
  private _listCablesModal: KtxListCablesPopupComponent;

  @ViewChild(KtxGridComponent, { read: false, static: false })
  private _gridComponent: KtxGridComponent;

  @ViewChild(KtxTemperatureRangesPopupComponent, { read: false, static: false })
  private _temperatureRangesModal: KtxTemperatureRangesPopupComponent;

  @ViewChild(UploadConfigurationFilePopupComponent, { read: false, static: false })
  private _uploadConfigurationFilePopupComponent: UploadConfigurationFilePopupComponent;

  @ViewChild('successUpload', { read: false, static: false })
  private _successOperationPopupComponent: SuccessOperationPopupComponent;

  @ViewChild(LocationBtxKtxDeletePopupComponent, { read: false, static: false })
  private deleteKtxComponent: LocationBtxKtxDeletePopupComponent;

  @ViewChild(KtxUnitDetailsPopupComponent, { read: false, static: false })
  private _unitDetailsModal: KtxUnitDetailsPopupComponent;

  @ViewChild(KtxRangeDeletePopupComponent, { read: false, static: false })
  private _rangeDeleteModal: KtxRangeDeletePopupComponent;

  @ViewChild(KtxConnectionPopupComponent, { read: false, static: false })
  private _connectionModal: KtxConnectionPopupComponent;

  private _subscriptions: Subscription[] = [];

  constructor(
  ) { }

  ngAfterViewInit() {
    let detailsSubscription = this._binsModal.onDetailsClick.subscribe((bin: BinSchemeViewModel) => {
      this._binDetailsModal.show(bin);
    });

    let binsSubscription = this._gridComponent.onBinClicked.subscribe(async (location: LocationKtxViewModel) => {
      await this._binsModal.show(location.id);
    });

    let editSubscription = this._gridComponent.onEditClicked.subscribe((location: LocationKtxViewModel) => {
      this._addEditModal.showEdit(location);
    });

    let cableSubscription = this._binDetailsModal.onViewCableDetailsClick.subscribe((bin: BinSchemeViewModel) => {
      this._listCablesModal.show(bin);
    });

    let temperatureSubscription = this._gridComponent.onTemperatureRangesClicked.subscribe((location) => {
      this._temperatureRangesModal.show(location);
    });

    let uploadSubscription = this._gridComponent.onUploadClicked.subscribe((location: LocationKtxViewModel) => {
      this._uploadConfigurationFilePopupComponent.show(location.id, location.locationName);
    });

    let successUploadSubscription = this._uploadConfigurationFilePopupComponent.onUploadSuccess.subscribe((emitData: [string, number]) => {
      this._successOperationPopupComponent.show(emitData[0], emitData[1]);
    });

    let goToBinDetailsClickedSubscription = this._successOperationPopupComponent.onPrimaryClicked.subscribe(async (entityId: number) => {
      await this._binsModal.show(entityId);
    });

    let deleteKtxSubscription = this._gridComponent.onDeleteClicked.subscribe((location: LocationKtxViewModel) => {
      this.deleteKtxComponent.show(location);
    });

    let unitDetailsSubscription = this._binsModal.onUnitDetailsClick.subscribe((unit: BinSchemeViewModel) => {
      this._unitDetailsModal.show(unit);
    });

    let schemeDetailsFromUnit = this._unitDetailsModal.onDetailsClick.subscribe((scheme: BinSchemeViewModel) => {
      this._binDetailsModal.show(scheme);
    });

    let rangeDeleteSubscription = this._temperatureRangesModal.onClickRangeDelete.subscribe((range: TemperatureRangeViewModel) => {
      this._rangeDeleteModal.show(range);
    })

    let rangeDeletedSubscription = this._rangeDeleteModal.onSubmit$.subscribe((range: TemperatureRangeViewModel) => {
      this._temperatureRangesModal.removeRange(range);
    });

    let connectionEditSubscription = this._gridComponent.onConnectionEditClicked.subscribe((location: LocationKtxViewModel) => {
      this._connectionModal.show({ locationName: location.locationName, locationId: location.id });
    });

    this._subscriptions.push(
      detailsSubscription,
      binsSubscription,
      editSubscription,
      cableSubscription,
      uploadSubscription,
      successUploadSubscription,
      goToBinDetailsClickedSubscription,
      temperatureSubscription,
      deleteKtxSubscription,
      unitDetailsSubscription,
      schemeDetailsFromUnit,
      rangeDeleteSubscription,
      rangeDeletedSubscription,
      connectionEditSubscription
    );
  }

  ngOnDestroy() {
    this._subscriptions &&
      this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onAdd() {
    this._addEditModal.showAdd();
  }
}
