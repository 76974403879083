import { AqsAirflowFanTypeEnum, AqsAirflowLevelEnum } from "../enums";

export const AqsAirflowLevelLabel = {
    [AqsAirflowLevelEnum.Normal]: 'Normal',
    [AqsAirflowLevelEnum.RoofPull]: 'Roof Pull',
    [AqsAirflowLevelEnum.Tandem]: 'Tandem',
    [AqsAirflowLevelEnum.PushPull]: 'Push Pull',
}

export const AqsChartFanTypeNote = {
    [AqsAirflowFanTypeEnum.Axial]: { leftNote: "Two stage axial", rightNote: "Single stage axial" },
    [AqsAirflowFanTypeEnum.Centrifugal60HZ]: { leftNote: "1750 rpm centrifugal 60 Hz", rightNote: "3450 rpm centrifugal 60 Hz" },
    [AqsAirflowFanTypeEnum.Centrifugal60HZAF]: { leftNote: "1750 rpm centrifugal 60 Hz air foil", rightNote: "" },
    [AqsAirflowFanTypeEnum.Centrifugal50HZ]: { leftNote: "1458 rpm centrifugal 50 Hz", rightNote: "2875 rpm centrifugal 50 Hz" },
}