import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { AqsCommoditiesDatasourceService } from './../../../../services';
import { AqsCommoditiesViewModel } from '@models';

@Component({
  selector: 'greensleeves-aqs-commodities',
  templateUrl: './aqs-commodities.component.html',
  styles: []
})
export class AqsCommoditiesComponent implements OnInit, OnDestroy {

  @Input() commodities: AqsCommoditiesViewModel[];
  @Output() onChangedCommoditiesList = new EventEmitter();

  _commoditiesOptions: { groupCommodities: { id: number, name: string, value: AqsCommoditiesViewModel }[] }[];
  _selectCommodities: { id: number, name: string, value: AqsCommoditiesViewModel }[] = [];

  private _commoditiesSubscription: Subscription;

  constructor(private _commodityService: AqsCommoditiesDatasourceService) { }

  ngOnInit() {
    this._commoditiesSubscription = this._commodityService.commodities$.subscribe((items) => {
      if (items) {
        this._commoditiesOptions = [];
        let columnGroup: { id: number, name: string, value: AqsCommoditiesViewModel }[] = [];
        let countOneColumn = Math.ceil(items.length / 4);
        let index = 0;
        items.forEach(item => {
          columnGroup.push({ id: item.id, name: item.name, value: item });
          index++
          if (index == countOneColumn) {
            index = 0;
            this._commoditiesOptions.push({ groupCommodities: columnGroup });
            columnGroup = [];
          }
        });
        if (columnGroup.length != 0) {
          this._commoditiesOptions.push({ groupCommodities: columnGroup });
        }

        this._selectCommodities = [];
        if (this.commodities && this.commodities.length > 0) {
          this._commoditiesOptions.forEach(comgroup => {
            this.commodities.forEach(item => {
              this._selectCommodities.push(...comgroup.groupCommodities.filter(c => c.id == item.id));
            });
          });
        }
      }
    });
    this._commodityService.getAll();
  }

  ngOnDestroy() {
    this._commoditiesSubscription.unsubscribe();
  }

  onClickCheckBox(control) {
    if (this._selectCommodities && this._selectCommodities.length > 6) {
      this._selectCommodities.pop();
      control.checked = false;
    }

    this.commodities.splice(0, this.commodities.length);
    this._selectCommodities.forEach(item => this.commodities.push(item.value));
    this.onChangedCommoditiesList.emit();
  }

}
