import { Component, OnInit } from '@angular/core';
import { ErrorModel } from 'src/app/common';
import { LocationsDataSourceService } from 'src/app/main/services';
import { LocationViewModel } from '../../../models';

@Component({
  selector: 'greensleeves-location-delete-popup',
  templateUrl: './location-delete-popup.component.html',
  styles: []
})
export class LocationDeletePopupComponent implements OnInit {
  _isHidden = true;

  _submitted = false;

  _location: LocationViewModel;
  _error: string;

  constructor(
    private _locationDatasourceService: LocationsDataSourceService
  ) { }

  ngOnInit() {
  }

  public show(location: LocationViewModel) {
    this._location = location;
    this._isHidden = false;
  }

  async onSubmit() {
    if (this._location && !this._submitted) {
      this._submitted = true;
      const result = await this._locationDatasourceService.delete(this._location.id);
      this._submitted = false;
      if (result === true) {
        this.onClickCancel();
      } else if (result === false) {
        this._error = 'Unknown error occured';
      } else {
        const errorModel = result as ErrorModel;
        if (errorModel) {
          this._error = errorModel.message;
        }
      }
    }
  }

  onClickCancel() {
    this._location = null;
    this._error = null;
    this._isHidden = true;
  }
}
