import { TreeNode } from "primeng";
import { EquipmentTypeEnum, EquipmentSubTypeEnum, BtxNotificationFilterNodeTypeEnum, AcquisitionRateEnum } from "../enums";
import { ModelsTypes, equipmentSubTypeLabels, equipmentTypeLabels } from '../constants';
import { BtxSensorWithTelemetryGetModel, BtxSensorWithTelemetryViewModel, SensorGetModel, SensorPostModel, SensorSchemaViewModel, SensorViewModel } from "./sensors";
import { NotificationFilterTreeNodeData } from "./notifications.model";
import { NameViewModel } from './common.model';
import { BtxLocationEquipmentConfiguration } from "./locations";

export class EquipmentViewModel {
    constructor() {
        this.id = 0;
        this.sensors = [];
    }

    id: number;
    type: EquipmentTypeEnum;
    subType: EquipmentSubTypeEnum;
    name: string;
    totalRunHoursFirstRegisterAddress: number;
    totalRunHoursSecondRegisterAddress: number;
    timeTillMaintenanceAddress: number;
    shutDownAlarmAddress: number;
    shutDownAlarmAddressBit: number;
    maintenanceRequiredWarningAddress: number;
    maintenanceRequiredWarningAddressBit: number;
    sensors: SensorViewModel[];
    maintenanceCountDownValue: number;
    totalRunHoursValue: number;
    isMaintenanceRequired: boolean;
    maintenaceCountDown: number;
    timestamp: number;
    shutdownAlarm:boolean;

    //Infrastructure fields
    modelType = ModelsTypes.equipmentViewModel;

    public static toViewModel(equipment: BtxLocationEquipmentConfiguration): EquipmentViewModel {
        const viewModel = new EquipmentViewModel;
        viewModel.id = equipment.index;
        viewModel.type = equipment.type;
        viewModel.subType = equipment.subType;
        viewModel.name = equipment.name;
        viewModel.maintenaceCountDown = equipment.maintenaceCountDown;
        viewModel.totalRunHoursFirstRegisterAddress = equipment.totalRunHoursFirstRegisterAddress;
        viewModel.totalRunHoursSecondRegisterAddress = equipment.totalRunHoursSecondRegisterAddress;
        viewModel.timeTillMaintenanceAddress = equipment.timeTillMaintenanceAddress;
        viewModel.shutDownAlarmAddress = equipment.shutDownAlarmAddress;
        viewModel.shutDownAlarmAddressBit = equipment.shutDownAlarmAddressBit;
        viewModel.maintenanceRequiredWarningAddress = equipment.maintenanceRequiredWarningAddress;
        viewModel.maintenanceRequiredWarningAddressBit = equipment.maintenanceRequiredWarningAddressBit;
        if (equipment.sensors && equipment.sensors.length > 0) {
            viewModel.sensors = equipment.sensors.map(sensor => SensorViewModel.toViewModel(sensor));
        }

        return viewModel;
    }
}

export class EquipmentGetModel {
    id: number;
    type: EquipmentTypeEnum;
    subType: EquipmentSubTypeEnum;
    name: string;
    totalRunHoursFirstRegisterAddress: number;
    totalRunHoursSecondRegisterAddress: number;
    timeTillMaintenanceAddress: number;
    shutDownAlarmAddress: number;
    shutDownAlarmAddressBit: number;
    maintenanceRequiredWarningAddress: number;
    maintenanceRequiredWarningAddressBit: number;
    maintenanceCountDownValue: number;
    totalRunHoursValue: number;
    isMaintenanceRequired: boolean;
    sensors: SensorGetModel[];

    public static toViewModel(getModel: EquipmentGetModel): EquipmentViewModel {
        const viewModel = new EquipmentViewModel();
        viewModel.id = getModel.id;
        viewModel.type = getModel.type;
        viewModel.subType = getModel.subType;
        viewModel.name = getModel.name;
        viewModel.totalRunHoursFirstRegisterAddress = getModel.totalRunHoursFirstRegisterAddress;
        viewModel.totalRunHoursSecondRegisterAddress = getModel.totalRunHoursSecondRegisterAddress;
        viewModel.timeTillMaintenanceAddress = getModel.timeTillMaintenanceAddress;
        viewModel.shutDownAlarmAddress = getModel.shutDownAlarmAddress;
        viewModel.shutDownAlarmAddressBit = getModel.shutDownAlarmAddressBit;
        viewModel.maintenanceRequiredWarningAddress = getModel.maintenanceRequiredWarningAddress;
        viewModel.maintenanceRequiredWarningAddressBit = getModel.maintenanceRequiredWarningAddressBit;
        viewModel.maintenanceCountDownValue = getModel.maintenanceCountDownValue;
        viewModel.totalRunHoursValue = getModel.totalRunHoursValue;
        viewModel.isMaintenanceRequired = getModel.isMaintenanceRequired;

        if (getModel.sensors) {
            viewModel.sensors = getModel.sensors.map(s => SensorGetModel.toViewModel(s));
        }

        return viewModel;
    }
}

export class EquipmentPostModel {
    id: number;
    type: EquipmentTypeEnum;
    subType: EquipmentSubTypeEnum;
    name: string;
    totalRunHoursFirstRegisterAddress: number;
    totalRunHoursSecondRegisterAddress: number;
    timeTillMaintenanceAddress: number;
    shutDownAlarmAddress: number;
    shutDownAlarmAddressBit: number;
    maintenanceRequiredWarningAddress: number;
    maintenanceRequiredWarningAddressBit: number;
    sensors: SensorPostModel[];

    constructor(viewModel: EquipmentViewModel) {
        this.id = viewModel.id;
        this.type = viewModel.type;
        this.subType = viewModel.subType;
        this.name = viewModel.name;
        this.totalRunHoursFirstRegisterAddress = Number(viewModel.totalRunHoursFirstRegisterAddress);//field is string after input form
        this.totalRunHoursSecondRegisterAddress = Number(viewModel.totalRunHoursSecondRegisterAddress);//field is string after input form
        this.timeTillMaintenanceAddress = viewModel.timeTillMaintenanceAddress
            ? Number(viewModel.timeTillMaintenanceAddress)
            : viewModel.timeTillMaintenanceAddress;//field is string after input form
        this.shutDownAlarmAddress = Number(viewModel.shutDownAlarmAddress);//field is string after input form
        this.shutDownAlarmAddressBit = viewModel.shutDownAlarmAddressBit;
        this.maintenanceRequiredWarningAddress = Number(viewModel.maintenanceRequiredWarningAddress);//field is string after input form
        this.maintenanceRequiredWarningAddressBit = viewModel.maintenanceRequiredWarningAddressBit;

        if (viewModel.sensors) {
            this.sensors = viewModel.sensors.map(s => new SensorPostModel(s));
        }
    }
}

export class EquipmentNameViewModel {
    id: number;
    name: string;

    static toBtxTreeNode(equipment: EquipmentNameViewModel, locationId: number): TreeNode {
        const data: NotificationFilterTreeNodeData<BtxNotificationFilterNodeTypeEnum> = {
            id: equipment.id,
            nodeType: BtxNotificationFilterNodeTypeEnum.Equipment,
            locationId: locationId,
        };

        const locationWithEquipmentNodes: TreeNode = {
            label: equipment.name,
            data,
            selectable: true,
        };

        return locationWithEquipmentNodes;
    }
}

export class EquipmentNameGetModel {
    id: number;
    name: string;

    public static toViewModel(getModel: EquipmentNameGetModel): EquipmentNameViewModel {
        const viewModel = new EquipmentNameViewModel();

        viewModel.id = getModel.id;
        viewModel.name = getModel.name;

        return viewModel;
    }
}

export class BtxLiveDataEquipmentViewModel {
    id: number;
    name: string;
    type: NameViewModel;
    subType: NameViewModel;
    alarms: number;
    warnings: number;

    public static toReportEquipmentTreeNode(equipmentViewModel: BtxLiveDataEquipmentViewModel): TreeNode {
        const equipmentTreeNode: TreeNode = {
            label: equipmentViewModel.name,
            data: equipmentViewModel.id,
            selectable: true,
            key: equipmentViewModel.id.toString()
        };

        return equipmentTreeNode;
    }
}

export class BtxLiveDataEquipmentGetModel {
    id: number;
    name: string;
    type: EquipmentTypeEnum;
    subType: EquipmentSubTypeEnum;
    alarms: number;
    warnings: number;

    public static toViewModel(getModel: BtxLiveDataEquipmentGetModel): BtxLiveDataEquipmentViewModel {
        const viewModel = new BtxLiveDataEquipmentViewModel();

        viewModel.id = getModel.id;
        viewModel.name = getModel.name;
        viewModel.type = BtxLiveDataEquipmentGetModel.getEquipmentTypeNameViewModel(getModel.type);
        viewModel.subType = BtxLiveDataEquipmentGetModel.getEquipmentSubTypeNameViewModel(getModel.subType);
        viewModel.alarms = getModel.alarms;
        viewModel.warnings = getModel.warnings;

        return viewModel;
    }

    private static getEquipmentTypeNameViewModel(type: EquipmentTypeEnum): NameViewModel {
        const nameViewModel = new NameViewModel();

        nameViewModel.id = type;
        nameViewModel.name = equipmentTypeLabels[type];

        return nameViewModel;
    }

    private static getEquipmentSubTypeNameViewModel(subType: EquipmentSubTypeEnum): NameViewModel {
        const nameViewModel = new NameViewModel();

        nameViewModel.id = subType;
        nameViewModel.name = equipmentSubTypeLabels[subType];

        return nameViewModel;
    }
}

export class BtxEquipmentWithTelemetryViewModel {
    id: number;
    name: string;
    type: EquipmentTypeEnum;
    subType: EquipmentSubTypeEnum;
    locationId: number;
    locationName: string;
    maintenanceCountDownValue: number;
    totalRunHoursValue: number;
    timestamp: number;
    acquisitionRate: AcquisitionRateEnum;
    sensors: BtxSensorWithTelemetryViewModel[];
    isMaintenanceRequired: boolean;
    shutdownAlarm: boolean;

    public static toEquipmentViewModel(equipmentWithTelemetry: BtxEquipmentWithTelemetryViewModel): EquipmentViewModel {
        const equipmentViewModel = new EquipmentViewModel();

        equipmentViewModel.id = equipmentWithTelemetry.id;
        equipmentViewModel.type = equipmentWithTelemetry.type;
        equipmentViewModel.subType = equipmentWithTelemetry.subType;
        equipmentViewModel.name = equipmentWithTelemetry.name;
        equipmentViewModel.maintenanceCountDownValue = equipmentWithTelemetry.maintenanceCountDownValue;
        equipmentViewModel.totalRunHoursValue = equipmentWithTelemetry.totalRunHoursValue;
        equipmentViewModel.timestamp = equipmentWithTelemetry.timestamp;
        equipmentViewModel.isMaintenanceRequired = equipmentWithTelemetry.isMaintenanceRequired;
        equipmentViewModel.shutdownAlarm=equipmentWithTelemetry.shutdownAlarm;

        if (equipmentWithTelemetry.sensors) {
            equipmentViewModel.sensors = equipmentWithTelemetry.sensors.map(s => BtxSensorWithTelemetryViewModel.toSensorViewModel(s));
        }

        return equipmentViewModel;
    }
}

export class BtxEquipmentWithTelemetryGetModel {
    id: number;
    name: string;
    type: EquipmentTypeEnum;
    subType: EquipmentSubTypeEnum;
    locationId: number;
    locationName: string;
    maintenanceCountDownValue: number;
    totalRunHoursValue: number;
    timestamp: number;
    acquisitionRate: AcquisitionRateEnum;
    sensors: BtxSensorWithTelemetryGetModel[];
    isMaintenanceRequired: boolean;
    shutdownAlarm: boolean;

    public static toViewModel(getModel: BtxEquipmentWithTelemetryGetModel): BtxEquipmentWithTelemetryViewModel {
        const viewModel = new BtxEquipmentWithTelemetryViewModel();

        viewModel.id = getModel.id;
        viewModel.name = getModel.name;
        viewModel.type = getModel.type;
        viewModel.subType = getModel.subType;
        viewModel.locationName = getModel.locationName;
        viewModel.locationId = getModel.locationId;
        viewModel.maintenanceCountDownValue = getModel.maintenanceCountDownValue;
        viewModel.totalRunHoursValue = getModel.totalRunHoursValue;
        viewModel.timestamp = getModel.timestamp;
        viewModel.acquisitionRate = getModel.acquisitionRate;
        viewModel.isMaintenanceRequired = getModel.isMaintenanceRequired;
        viewModel.shutdownAlarm = getModel.shutdownAlarm;

        if (getModel.sensors) {
            viewModel.sensors = getModel.sensors.map((s) => BtxSensorWithTelemetryGetModel.toViewModel(s));
        }

        return viewModel;
    }
}

export class EquipmentSchemaViewModel {
    className: string;
    markers: SensorSchemaViewModel[];
}