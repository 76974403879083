import { Component, OnInit } from '@angular/core';

import { EmailServiceDataSourceService } from '@services';
import { ErrorModel } from '../../../../common';
import { LocalStorageService } from './../../../../common';

@Component({
  selector: 'greensleeves-email-service-delete-popup',
  templateUrl: './email-service-delete-popup.component.html',
  styles: []
})
export class EmailServiceDeletePopupComponent implements OnInit {
  _isHidden = true;
  private emailServiceId: number;
  constructor(
    private _emailServiceDataSource: EmailServiceDataSourceService,
    private _localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
  }

  onCancel() {
    this._isHidden = true;
  }

  onShow(emailServiceId: number) {
    this.emailServiceId = emailServiceId;
    this._isHidden = false;
  }

  async onSubmit() {
    const info = this._localStorageService.getLoginInfo();
    const result = await this._emailServiceDataSource.delete(this.emailServiceId, info ? info.companyId : 0);
    if (result instanceof ErrorModel) {
      console.log(result);
    }
    this.onCancel();
  }
}
