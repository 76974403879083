import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { ApiRoutes, ErrorModel, HttpService } from '../../../common';
import { EmailServiceGetModel, EmailServicePostModel, EmailServiceViewModel } from "../../models";
import { EmailServiceScheduleEnum } from "../../enums";

@Injectable()
export class EmailServiceApiService {
    constructor(
        private _httpService: HttpService,
        private _router: Router) { }

    public async loadAll(userCompanyId: number): Promise<EmailServiceViewModel[] | ErrorModel> {
        try {
            if (this._router.url.indexOf('/back-door') > -1) {
                return this.getFakeData();
            }
            const data = await this._httpService.get(ApiRoutes.Company.getAllEmailReportConfigurationsForCompany, { companyId: userCompanyId }) as EmailServiceGetModel[];
            if (data) {
                const emailServiceView = data.map(user => EmailServiceGetModel.toViewModel(user));
                return emailServiceView;
            }
        } catch (error) {
            return this.processError(error);
        }
    }

    public async addEdit(emailService: EmailServicePostModel): Promise<EmailServiceViewModel | ErrorModel> {
        try {
            const result = await this._httpService.post(ApiRoutes.Company.addEditEmailReportConfigurationForCompany, emailService) as EmailServiceGetModel;
            if (result) {
                const emailServiceView = EmailServiceGetModel.toViewModel(result);
                return Promise.resolve(emailServiceView);
            }
        } catch (error) {
            return this.processError(error);
        }
    }

    public async delete(id: number, companyId: number) {
        try {
            const result = await this._httpService.delete(ApiRoutes.Company.deleteEmailReportConfigurationForCompany, { emailServiceId: id, companyId: companyId });

            return Promise.resolve(result);
        } catch (error) {
            return this.processError(error);
        }
    }

    private processError(error: any) {
        if (error instanceof ErrorModel) {
            return Promise.reject(error);
        }

        console.log(error);
        return null;
    }

    private getFakeData(): EmailServiceViewModel[] {
        const data = [
            {
                id: 1, emails: ['email@mail.com', 'email@mail.com', 'email@mail.com', 'email@mail.com'], btxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], btxLocationNames: ['Location1', 'Location2'], ktxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], ktxLocationNames: ['Location1', 'Location2'], itxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], itxLocationNames: ['Location1', 'Location2'], scheduleType: EmailServiceScheduleEnum.Daily, scheduleTime: 32400,
                btxEventTypes: [0, 1], ktxEventTypes: [0], itxEventTypes: [0, 2],
            },
            {
                id: 2, emails: ['email2@mail.com', 'email2@mail.com', 'email2email3@mail.com', 'email2@mail.com'], btxLocations: [{ id: 1, name: 'Location3' }, { id: 2, name: 'Location4' }], btxLocationNames: ['Location3', 'Location4'], ktxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], ktxLocationNames: ['Location1', 'Location2'], itxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], itxLocationNames: ['Location1', 'Location2'], scheduleType: EmailServiceScheduleEnum.Weekly, scheduleTime: 54000,
                btxEventTypes: [1], ktxEventTypes: [1], itxEventTypes: [1],
            },
            {
                id: 3, emails: ['email3email3@mail.com', 'email3@mail.com', 'email3@mail.com', 'email3@mail.com'], btxLocations: [{ id: 1, name: 'Location3' }, { id: 2, name: 'Location4' }], btxLocationNames: ['Location5', 'Location6'], ktxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], ktxLocationNames: ['Location1', 'Location2'], itxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], itxLocationNames: ['Location1', 'Location2'], scheduleType: EmailServiceScheduleEnum.Monthly, scheduleTime: 36000,
                btxEventTypes: [0], ktxEventTypes: [0, 1], itxEventTypes: [0, 1, 2],
            },
        ] as EmailServiceViewModel[];
        return data;
    }
}
