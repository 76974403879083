import { FormControl, FormGroup } from '@angular/forms';

import { ModbussAddress } from './validation.function';

export class TemplateFunctions {
  public static makeid(length): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public static markAddressControllsAsNotUnique(formGroup: FormGroup, notUniqueAddresses: ModbussAddress[]) {
    notUniqueAddresses.forEach(notUniqueAddress => {
      formGroup.controls[notUniqueAddress.Name].setErrors({
        uniqueAddress: notUniqueAddress.ExistIn != null ? notUniqueAddress.ExistIn : "This address is already in use"
      });

      if (notUniqueAddress.NameOfPair) {
        const subs = formGroup.controls[notUniqueAddress.NameOfPair].valueChanges.subscribe(() => {
          subs.unsubscribe();
          formGroup.controls[notUniqueAddress.Name].reset(formGroup.controls[notUniqueAddress.Name].value);
        });
      }
    });
  }

  public static ipValidator(control: FormControl) {
    // tslint:disable-next-line: max-line-length
    return /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[1-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/
      .test(control.value) ? null : { 'ipValidator': 'IP Address not valid.' };
  }

  public static phoneValidator(control: FormControl) {
    return /^(?:[0-9] ?){6,14}[0-9]$/.test(control.value) ? null : { 'phoneValidator': 'Phone number not valid.' };
  }

  public static emergencyPhoneValidator(control: FormControl) {
    return /^(?:[0-9] ?){2,14}[0-9]$/.test(control.value) ? null : { 'emergencyPhoneValidator': 'Phone number not valid.' };
  }

  public static macAddressValidator(control: FormControl) {
    return /(^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$)|([0-9A-Fa-f]{12})/.test(control.value) ? null : { 'macAddressValidator': 'MAC address not valid.' };
  }
}
