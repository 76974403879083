export enum AqsStorageTypeEnum {
    Conical = 1,
    SideDraw = 2,
    Flat = 3,
    Peaked = 4,
    SlopedPeak = 5,
    TruncatedPeak = 6,
    None = 7,
}

export enum AqsStorageTypeStringEnum {
  Conical = 'Conical',
  SideDraw = 'Side Draw',
  Flat = 'Flat',
  Peaked = 'Peaked',
  SlopedPeak = 'Four Sloped Peak',
  TruncatedPeak = 'Truncated Peak',
  None = 'None',
}
