import { Injectable } from '@angular/core';

import { ErrorModel, HttpService } from '../../../common';
import { Router } from '@angular/router';
import { CancelUpdateFirmwarePostModel, CancelUpdateModel, CheckProjectsGetModel, GetInstalledProjectFirmwareInfo, GetLocationUpdatersFilterModel, InstallProjectPostModel, LocationUpdaterViewModel, ProjectFirmwareViewModel, UpdateDeviceViewModel, UpdateFirmwarePostModel, UpdateFirmwareResultModel, UpdateFirmwaresPostModel, UpdateProjectDeviceState, VersionHistoryResponseModel as VersionHistoryViewModel } from '../../models';
import { ApiRoutes } from '../../constants';
import { ProjectEnum, UpdateProjectStateEnum } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class UpdaterDeviceService {

  constructor(private _httpService: HttpService,
    private _router: Router) { }

  public async getByFilter(request: GetLocationUpdatersFilterModel): Promise<LocationUpdaterViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const result = await this._httpService.post(ApiRoutes.UpdaterDevice.getByFilter, request) as LocationUpdaterViewModel[];
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async add(updater: { locationId: number, projectsAvailable: number }): Promise<UpdateDeviceViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.UpdaterDevice.addUpdaterDevice, updater) as UpdateDeviceViewModel;
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async edit(id: string, updaterEdit: { projectsAvailable: number }): Promise<UpdateDeviceViewModel | ErrorModel> {
    try {
      const result = await this._httpService.put(ApiRoutes.UpdaterDevice.editUpdaterDevice + `/${id}`, updaterEdit) as UpdateDeviceViewModel;
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async initializeDeviceConfiguration(id: string): Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.UpdaterDevice.initializeDeviceConfiguration, id) as boolean;
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadConnectionFile(id: string): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.getArrayBuffer(ApiRoutes.UpdaterDevice.downloadConnectionFile, {
        updaterId: id
      });

      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async delete(id: string): Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.delete(ApiRoutes.UpdaterDevice.deleteUpdaterDevice, {
        updaterId: id,
      }) as boolean;
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getBlobFirmwareFiles(folder: string): Promise<ProjectFirmwareViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeFirmwareData();
      }
      const result = await this._httpService.get(ApiRoutes.UpdaterDevice.getBlobFirmwareFiles, {
        directoryName: folder,
      }) as ProjectFirmwareViewModel[];
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadFirmwareFile(request: { directoryName: string, fileName: string }): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.getArrayBuffer(ApiRoutes.UpdaterDevice.downloadFirmwareFile, {
        directoryName: request.directoryName,
        fileName: request.fileName
      });

      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadFirmwareFiles(request: { directoryName: string, fileNames: string[] }): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.UpdaterDevice.downloadFirmwareFiles, request);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async deleteSelectedFirmwares(request: { directoryName: string, fileNames: string[] }): Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.UpdaterDevice.deleteSelectedFirmwares, request);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async uploadFirmware(request: { directoryName: string, insertFile: File }): Promise<ProjectFirmwareViewModel | ErrorModel> {
    try {
      const formData: FormData = new FormData();
      formData.append('directoryName', request.directoryName);
      formData.append('insertFile', request.insertFile, request.insertFile.name);

      const result = await this._httpService.postDataWithContent(ApiRoutes.UpdaterDevice.uploadFirmware, formData) as ProjectFirmwareViewModel;
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async installProjectFirmware(request: InstallProjectPostModel): Promise<UpdateFirmwareResultModel | ErrorModel> {
    try {
      const formData: FormData = new FormData();
      formData.append('locationId', request.locationId.toString());
      formData.append('updaterId', request.updaterDeviceId);
      formData.append('projectId', request.projectDeviceId);
      formData.append('project', request.project.toString());
      formData.append('overwriteConfig', `${request.overwriteConfig}`);
      formData.append('insertFile', request.file, request.file.name);

      const result = await this._httpService.postDataWithContent(ApiRoutes.UpdaterDevice.installProjectFirmware, formData) as UpdateFirmwareResultModel;
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async updateDeviceFiemware(request: UpdateFirmwarePostModel): Promise<UpdateFirmwareResultModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeUpdate(request);
      }
      const result = await this._httpService.post(ApiRoutes.UpdaterDevice.updateDeviceFirmware, request);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async cancelUpdateDeviceFirmware(request: CancelUpdateFirmwarePostModel): Promise<CancelUpdateModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.UpdaterDevice.cancelUpdateDeviceFirmware, request);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getInstalledProjectFirmwareInformation(request: CheckProjectsGetModel): Promise<GetInstalledProjectFirmwareInfo | ErrorModel> {
    try {
      const result = await this._httpService.get(ApiRoutes.UpdaterDevice.getInstalledProjectFirmware, request);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async updateDeviceFiemwares(request: UpdateFirmwaresPostModel): Promise<UpdateFirmwareResultModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeBulkUpdates(request);
      }
      const result = await this._httpService.post(ApiRoutes.UpdaterDevice.updateDeviceFirmwares, request);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getUpdateHistoryVersions(deviceId: string, locationId: string): Promise<VersionHistoryViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeUpdateHistoryData();
      }
      const request: { deviceId: string, locationId: string } = { deviceId: deviceId, locationId: locationId };
      const result = await this._httpService.get(ApiRoutes.UpdaterDevice.getUpdateHistoryVersions, request);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getUpdaterStates(deviceIds: string[]): Promise<UpdateProjectDeviceState[] | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.UpdaterDevice.getUpdateStates, deviceIds) as UpdateProjectDeviceState[];
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  private getFakeUpdate(request: UpdateFirmwarePostModel) {
    let result = {
      locationId: request.locationId,
      projectDeviceId: request.projectDeviceId,
      updaterDeviceId: request.updaterDeviceId,
      status: this.getFakeStatus(),
      payLoadSuccess: true,
      payLoadMessage: ''
    } as UpdateFirmwareResultModel;
    if (result.status != 100 || request.project == ProjectEnum.Itx) {
      result.payLoadSuccess = false;
      result.payLoadMessage = this.getFakeMessages();
      result.status = 500;
    }
    return result;
  }

  private getFakeBulkUpdates(request: UpdateFirmwaresPostModel) {
    let result: UpdateFirmwareResultModel[] = [];
    request.devices.forEach(item => {
      let response = {
        locationId: item.locationId,
        projectDeviceId: item.projectDeviceId,
        updaterDeviceId: item.updaterDeviceId,
        status: this.getFakeStatus(),
        payLoadSuccess: true,
        payLoadMessage: ''
      } as UpdateFirmwareResultModel;
      if (response.status != 100 || request.project == ProjectEnum.Itx) {
        response.payLoadSuccess = false;
        response.payLoadMessage = this.getFakeMessages();
        response.status = 500;
      }
      result.push(response);
    })

    return result;
  }

  private getFakeStatus() {
    let statuses: number[] = [100, 500];
    return statuses[Math.floor(Math.random() * (2))];
  }

  private getFakeMessages() {
    let messages: string[] = ['Exception while handling method callback: Update already active.', 'Device offline', 'Udater device unexpected device error'];
    return messages[Math.floor(Math.random() * (3))];
  }

  private getFakeUpdateHistoryData(): VersionHistoryViewModel[] {
    const data = [
      {
        currentVersion: '1.0.1',
        lastErrorMessage: '',
        realUpdateTime: new Date(2024, 7, 25),
        status: 'ok',
        targetVersion: ''
      },
      {
        currentVersion: '1.0.2',
        lastErrorMessage: '',
        realUpdateTime: new Date(2024, 7, 25),
        status: 'updating',
        targetVersion: ''
      },
      {
        currentVersion: '1.0.2',
        lastErrorMessage: '',
        realUpdateTime: new Date(2024, 7, 25),
        status: 'updaiting',
        targetVersion: '1.1.0'
      },
      {
        currentVersion: '1.0.1',
        lastErrorMessage: "Exception while updating: (500): execution of 'setsid' '-w' 'env' 'PROJECT_NAME=BTX' '/usr/local/bin/azure_updater/tmp/scripts/update.sh' '-v' '-v' exited with failure: 1",
        realUpdateTime: new Date(2024, 7, 25),
        status: 'error',
        targetVersion: '',
      }
    ] as VersionHistoryViewModel[];
    return data;
  }

  private getFakeFirmwareData(): ProjectFirmwareViewModel[] {
    const data = [
      {
        name: '/firmware-one12335569f938274967902834770235775920283745899208293704.zip',
        size: 12345,
        sizeLabel: "12.345 Kb",
        createdOn: new Date(2024, 7, 15),
        lastModified: new Date(2024, 7, 15),
      },
      {
        name: '/firmware-two.zip',
        size: 12345,
        sizeLabel: "12.345 Kb",
        createdOn: new Date(2024, 7, 15),
        lastModified: new Date(2024, 7, 15),
      },
      {
        name: '/firmware-three.zip',
        size: 12345,
        sizeLabel: "12.345 Kb",
        createdOn: new Date(2024, 7, 15),
        lastModified: new Date(2024, 7, 15),
      },
      {
        name: '/firmware-four.zip',
        size: 12345,
        sizeLabel: "12.345 Kb",
        createdOn: new Date(2024, 7, 15),
        lastModified: new Date(2024, 7, 15),
      },
      {
        name: '/firmware-five.zip',
        size: 12345,
        sizeLabel: "12.345 Kb",
        createdOn: new Date(2024, 7, 15),
        lastModified: new Date(2024, 7, 15),
      },
      {
        name: '/firmware-six.zip',
        size: 12345,
        sizeLabel: "12.345 Kb",
        createdOn: new Date(2024, 7, 15),
        lastModified: new Date(2024, 7, 15),
      },
      {
        name: '/firmware-seven.zip',
        size: 12345,
        sizeLabel: "12.345 Kb",
        createdOn: new Date(2024, 7, 15),
        lastModified: new Date(2024, 7, 15),
      },
      {
        name: '/firmware-eight.zip',
        size: 12345,
        sizeLabel: "12.345 Kb",
        createdOn: new Date(2024, 6, 15),
        lastModified: new Date(2024, 6, 15),
      }
    ] as ProjectFirmwareViewModel[];

    return data;
  }

  private getFakeData(): LocationUpdaterViewModel[] {
    const data = [
      {
        id: 1,
        name: 'Fake location 182749-19274789598718181389293807875911',
        companyName: 'Fake Company',
        projectsAvailable: ProjectEnum.Btx | ProjectEnum.Itx,
        locationDevices: [
          {
            deviceId: 'e535495b-e874-4eb4-91d6-34df11d2d04d',
            isInitialized: true,
            project: ProjectEnum.Btx,
          },
          {
            deviceId: 'e535495b-e874-4eb4-91d6-34df11d2d02d',
            isInitialized: true,
            project: ProjectEnum.Itx,
          },
        ],
        updaterDevices: [
          {
            id: 'e535495b-e874-4eb4-91d6-34df11d2d021',
            isInitialized: false,
            locationId: 1,
            projectsAvailable: ProjectEnum.Btx,
            projectDevices: [{
              deviceId: 'e535495b-e874-4eb4-91d6-34df11d2d04d',
              project: ProjectEnum.Btx,
              currentVersion: null,
              lastUpdate: null,
            }]
          },
          {
            id: 'e535495b-e874-4eb4-91d6-345f11d2d021',
            isInitialized: true,
            locationId: 1,
            projectsAvailable: ProjectEnum.Itx,
            projectDevices: [{
              deviceId: 'e535495b-e874-4eb4-91d6-34df11d2d02d',
              project: ProjectEnum.Itx,
              currentVersion: "1.2.1",
              lastUpdate: new Date().getTime() / 1000,
            }]
          }
        ]
      },
      {
        id: 2,
        name: 'Fake location 2',
        companyName: 'Fake Company',
        projectsAvailable: ProjectEnum.Btx | ProjectEnum.Itx | ProjectEnum.Ktx,
        locationDevices: [
          {
            deviceId: '60e7e135-3d7d-4beb-bcbc-5297378c3e2f',
            isInitialized: true,
            project: ProjectEnum.Btx,
          },
          {
            deviceId: 'e5351bd4-7e09-4f65-a51a-af8815c6c539',
            isInitialized: true,
            project: ProjectEnum.Itx,
          },
          {
            deviceId: 'dcb05793-7596-407f-b6e6-d9041e37b8c9',
            isInitialized: true,
            project: ProjectEnum.Ktx,
          },
        ],
        updaterDevices: [
          {
            id: 'e535495b-e874-4eb4-91d6-34df11d2d021',
            isInitialized: true,
            locationId: 2,
            projectsAvailable: ProjectEnum.Btx,
            projectDevices: [{
              deviceId: '60e7e135-3d7d-4beb-bcbc-5297378c3e2f',
              project: ProjectEnum.Btx,
              currentVersion: "1.0.1",
              lastUpdate: new Date().getTime() / 1000,
              state: UpdateProjectStateEnum.Updating,
            }]
          },
          {
            id: '9041a40a-9eb0-4075-b21e-72abc65122e3',
            isInitialized: true,
            locationId: 2,
            projectsAvailable: ProjectEnum.Itx,
            projectDevices: [{
              deviceId: 'e5351bd4-7e09-4f65-a51a-af8815c6c539',
              project: ProjectEnum.Itx,
              currentVersion: "1.2.1",
              lastUpdate: new Date().getTime() / 1000,
              state: UpdateProjectStateEnum.Failed,
              errorMessage: "Exception while updating: (500): execution of 'setsid' '-w' 'env' 'PROJECT_NAME=BTX' '/usr/local/bin/azure_updater/tmp/scripts/update.sh' '-v' '-v' exited with failure: 1",
            }]
          },
          {
            id: 'f2e98bd0-151d-4a86-9c16-b05e291ebc67',
            isInitialized: true,
            locationId: 2,
            projectsAvailable: ProjectEnum.Ktx,
            projectDevices: [{
              deviceId: 'dcb05793-7596-407f-b6e6-d9041e37b8c9',
              project: ProjectEnum.Ktx,
              currentVersion: "1.29.1.1",
              lastUpdate: new Date().getTime() / 1000,
              state: UpdateProjectStateEnum.Updating,
            }]
          },
        ]
      },
      {
        id: 3,
        name: 'Fake location 3',
        companyName: 'Fake Company',
        projectsAvailable: ProjectEnum.Btx | ProjectEnum.Itx | ProjectEnum.Ktx,
        locationDevices: [
          {
            deviceId: '7ed6b028-a190-4353-bc7e-a407ed058aa8',
            isInitialized: true,
            project: ProjectEnum.Btx,
          },
          {
            deviceId: 'f484a8e2-9500-4609-95d0-0e531b65625f',
            isInitialized: true,
            project: ProjectEnum.Itx,
          },
          {
            deviceId: '39cfccba-fe53-4d2d-945f-8feeaf2a80b2',
            isInitialized: true,
            project: ProjectEnum.Ktx,
          },
        ],
        updaterDevices: [],
      },
      {
        id: 4,
        name: 'Fake location 4',
        companyName: 'Fake Company',
        projectsAvailable: ProjectEnum.Btx | ProjectEnum.Itx | ProjectEnum.Ktx,
        locationDevices: [
          {
            deviceId: '7ed6b028-a190-4353-bc7e-a407ed058ab8',
            isInitialized: true,
            project: ProjectEnum.Btx,
          },
          {
            deviceId: 'f484a8e2-9500-4609-95d0-0e531b65626f',
            isInitialized: true,
            project: ProjectEnum.Itx,
          },
          {
            deviceId: '39cfccba-fe53-4d2d-945f-8feeaf2a8012',
            isInitialized: true,
            project: ProjectEnum.Ktx,
          },
        ],
        updaterDevices: [
          {
            id: 'f434a8e2-9500-4609-95d0-0e531b65626f',
            isInitialized: true,
            locationId: 4,
            projectsAvailable: ProjectEnum.Btx | ProjectEnum.Itx | ProjectEnum.Ktx,
            projectDevices: [
              {
                deviceId: '7ed6b028-a190-4353-bc7e-a407ed058ab8',
                isInitialized: true,
                project: ProjectEnum.Btx,
                currentVersion: "1.0.1",
                lastUpdate: new Date().getTime() / 1000,
                state: UpdateProjectStateEnum.Failed,
                errorMessage: "Exception while updating: (500): execution of 'setsid' '-w' 'env' 'PROJECT_NAME=BTX' '/usr/local/bin/azure_updater/tmp/scripts/update.sh' '-v' '-v' exited with failure: 1",
              },
              {
                deviceId: 'f484a8e2-9500-4609-95d0-0e531b65626f',
                isInitialized: true,
                project: ProjectEnum.Itx,
                currentVersion: "1.2.1",
                lastUpdate: new Date().getTime() / 1000,
                state: UpdateProjectStateEnum.Updating,
              },
              {
                deviceId: '39cfccba-fe53-4d2d-945f-8feeaf2a8012',
                isInitialized: true,
                project: ProjectEnum.Ktx,
                currentVersion: "1.29.1.1",
                lastUpdate: new Date().getTime() / 1000,
              },
            ]
          }
        ]
      },

    ] as LocationUpdaterViewModel[];
    return data;
  }
}
