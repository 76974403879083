import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AccountDatasourceService } from '@services';
import { LocalStorageService, UserRoleEnum } from './../../../../common';
import { AccountProfileModel } from './../../../models';

@Component({
  selector: 'greensleeves-user-profile-popup',
  templateUrl: './user-profile-popup.component.html',
  styles: []
})
export class UserProfilePopupComponent implements OnInit {
  _isHidden = true;
  _userProfileForm: FormGroup;
  _role: UserRoleEnum;
  _roleEnum = UserRoleEnum;
  _submitted = false;
  private userId: number;

  get isUserHasCompany() {
    return this._role != UserRoleEnum.ServiceUser && this._role != UserRoleEnum.SalesUser;
  }

  constructor(private _formBuilder: FormBuilder,
    private _localStorageService: LocalStorageService,
    private _accountSource: AccountDatasourceService,
    private _router: Router
  ) { }

  ngOnInit() {
    if (this._router.url.indexOf('/back-door') > -1) {
      this._role = UserRoleEnum.Vendor;
      return;
    }
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;
  }

  public async show() {
    let profile = await this._accountSource.getProfile() as AccountProfileModel;
    if (profile) {
      this.initForm(profile);
      this._isHidden = false;
      this.userId = profile.userId;
    }
    this._submitted = false;
  }

  onCancel() {
    this._isHidden = true;
  }

  async onSubmit() {
    this._submitted = true;
    if (this._userProfileForm.invalid) {
      return;
    }
    let profile = { ...this._userProfileForm.value, userId: this.userId } as AccountProfileModel;
    await this._accountSource.editProfile(profile);
    this._submitted = true;
    this.onCancel();
  }

  private initForm(profile: AccountProfileModel) {
    this._userProfileForm = this._formBuilder.group({
      companyName: [
        { value: profile.companyName, disabled: this._role != this._roleEnum.Vendor }
      ],
      firstName: [
        profile.firstName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      lastName: [
        profile.lastName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
    });
    if (this._role != this._roleEnum.ServiceUser) {
      this._userProfileForm.controls.companyName.setValidators(
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]));
    }
  }
}
