import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import {
  AuthorizeService,
  CommonFunctions,
  LocalStorageService,
} from '../../../common';
import {
  RoutesForRoles,
  NavigationItemsInfo,
  BackDoorRouteToRoleMap,
  BACK_DOOR,
} from '../../constants';
import { UserRoleEnum } from '../../../common';
import { MenuItem, NavigationUser, RoleRouteInfo } from '@models';
import { RoleService } from '@services';

@Component({
  selector: 'greensleeves-navigation-bar',
  templateUrl: './navigation-bar.component.html',
})
export class NavigationBarComponent implements OnInit {
  @Output() toggleNavigationBar: EventEmitter<boolean> = new EventEmitter(
    false
  );
  _menuItems: Array<MenuItem>;
  _isOpenNavigationBar = true;
  private user: NavigationUser;
  constructor(
    private _localStorageService: LocalStorageService,
    private _router: Router,
    private _roleService: RoleService
  ) { }

  ngOnInit() {
    const url = this._router.url;
    const isBackDoor = url.indexOf(BACK_DOOR) !== -1;

    this.user = isBackDoor
      ? this._roleService.getBackDoorUserWithRole(url)
      : this._localStorageService.getLoginInfo();

    if (this.user.role in UserRoleEnum) {
      this._menuItems = this.getMenuItemsByUser(this.user);
      this.redirectCompanyUser(this.user);
    }
  }

  redirectCompanyUser(user: NavigationUser): void {
    if (user.role === UserRoleEnum.CompanyUser) {
      const menuItem = this._menuItems.find((mi) => mi.project && mi.children.length);

      if (menuItem && menuItem.children) {
        const url = this.getUrlToFirstChild(menuItem);
        menuItem.isExpanded = true;

        this._router.navigateByUrl(url);
      }
    }
  }

  getRoleRoute(): string {
    const isBackDoor = this._router.url.indexOf(BACK_DOOR) !== -1;
    const backDoorRoleRoute = Object.keys(BackDoorRouteToRoleMap).find(
      (key) => this.user.role === BackDoorRouteToRoleMap[key]
    );

    const roleRoute = isBackDoor
      ? backDoorRoleRoute
      : backDoorRoleRoute.substring(BACK_DOOR.length);

    return roleRoute;
  }

  getUrlToFirstChild(menuItem: MenuItem): string {
    const roleRoute = this.getRoleRoute();
    const firstChildRoute = menuItem.children[0];

    const url = `${roleRoute}/${firstChildRoute.route}`;

    return url;
  }

  getMenuItemsByUser(user: NavigationUser): MenuItem[] {
    const routesForRoles = RoutesForRoles[user.role];
    const menuItems = this.getMenuItems(routesForRoles, user.projects);

    return menuItems;
  }

  getMenuItems(
    routesForRoles: RoleRouteInfo[],
    userProjects: number | null
  ): MenuItem[] {
    const menuItems = routesForRoles.map<MenuItem>((roleRoute) => {
      if (
        userProjects !== null &&
        roleRoute.project &&
        !CommonFunctions.containsProject(userProjects, roleRoute.project)
      ) {
        return null;
      }

      const routeInfo = NavigationItemsInfo[roleRoute.route];
      let childrenRoleRoutes: Array<RoleRouteInfo> = [];

      if (routeInfo.childrenRoutes) {
        childrenRoleRoutes = routeInfo.childrenRoutes.map<RoleRouteInfo>(
          (childRoute) => ({
            route: childRoute,
          })
        );
      }

      const routeChildren = this.getMenuItems(childrenRoleRoutes, userProjects);

      const menuItem: MenuItem = {
        route: roleRoute.route,
        label: routeInfo.label,
        icon: routeInfo.icon,
        isDisabled: roleRoute.isDisabled,
        isExpanded: false,
        children: routeChildren,
        project: roleRoute.project,
      };

      return menuItem;
    });

    return menuItems.filter((item) => item !== null);
  }

  isChildOrDependentRoute(route: string): boolean {
    const url = this._router.url;
    const { isExpanded, children } = this._menuItems.find(
      (mi) => mi.route === route
    );

    if (
      !isExpanded &&
      children &&
      children.some((child) => url.includes(child.route))
    ) {
      return true;
    }

    const { dependentRoutes } = NavigationItemsInfo[route];

    if (
      dependentRoutes &&
      dependentRoutes.some((dependentRoute) => url.includes(dependentRoute))
    ) {
      return true;
    }

    return false;
  }

  isDependentRoute(route: string):boolean {
    const url = this._router.url;
    const { dependentRoutes } = NavigationItemsInfo[route];

    if (
      dependentRoutes && dependentRoutes.some((dependentRoute) => url.includes(dependentRoute))
    ) {
      return true;
    }
    if(url.includes(route)){
      return true;
    }
    return false;
  }

  onToggleMenuItemClick(event: Event, item: MenuItem) {
    event.stopPropagation();
    event.preventDefault();
    item.isExpanded = !item.isExpanded;
  }

  onMenuItemClick(item: MenuItem) {
    if (!item.children || item.children.length === 0) {
      return;
    }

    if (item.isDisabled) {
      const url = this.getUrlToFirstChild(item);

      this._router.navigateByUrl(url);
    }

    item.isExpanded = true;
  }

  onToggleNavigationClick() {
    this._isOpenNavigationBar = !this._isOpenNavigationBar;
    this.toggleNavigationBar.emit(this._isOpenNavigationBar);
  }


}
