import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ComputationSheetAddEditModel, ComputationSheetResponseModel, AqsSavedQuoteModel, QuoteFlatStorageViewModel, QuoteRoundStorageViewModel, BindingComputationSheets } from '../../models';
import { ErrorModel, HttpService } from '../../../common';
import { ApiRoutes } from '../../constants';
import { AqsSessionTypeEnum, AqsStorageTypeEnum } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class AqsQuoteStorageApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router,) { }

  public async getFlatById(id: number): Promise<QuoteFlatStorageViewModel | ErrorModel> {
    try {
      const result = await this._httpService.get(ApiRoutes.QuoteStorage.getQuoteFlatStorage, { id });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async getRoundById(id: number): Promise<QuoteRoundStorageViewModel | ErrorModel> {
    try {
      const result = await this._httpService.get(ApiRoutes.QuoteStorage.getQuoteRoundStorage, { id });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async editQuoteFlatStorage(quoteFlatStorage: QuoteFlatStorageViewModel): Promise<QuoteFlatStorageViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return quoteFlatStorage;
      }
      const result = await this._httpService.post(ApiRoutes.QuoteStorage.editQuoteFlatStorage, quoteFlatStorage);
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async editQuoteRoundStorage(quoteRoundStorage: QuoteRoundStorageViewModel): Promise<QuoteRoundStorageViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return quoteRoundStorage;
      }
      const result = await this._httpService.post(ApiRoutes.QuoteStorage.editQuoteRoundStorage, quoteRoundStorage);
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async addQuoteFlatStorage(quoteFlatStorage: QuoteFlatStorageViewModel): Promise<QuoteFlatStorageViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return quoteFlatStorage;
      }
      const result = await this._httpService.post(ApiRoutes.QuoteStorage.addQuoteFlatStorage, quoteFlatStorage);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async addQuoteRoundStorage(quoteRoundStorage: QuoteRoundStorageViewModel): Promise<QuoteRoundStorageViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return quoteRoundStorage;
      }
      const result = await this._httpService.post(ApiRoutes.QuoteStorage.addQuoteRoundStorage, quoteRoundStorage);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async deleteQuoteStorage(id: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.delete(ApiRoutes.QuoteStorage.deleteQuoteStorage, { id });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async GetAllQuotes(): Promise<AqsSavedQuoteModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const result = await this._httpService.get(ApiRoutes.QuoteStorage.getAllSavedQuotes);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async addComputationSheet(computationSheet: ComputationSheetAddEditModel): Promise<ComputationSheetResponseModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.QuoteStorage.addComputationSheet, computationSheet);
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async editComputationSheet(computationSheet: ComputationSheetAddEditModel): Promise<ComputationSheetResponseModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.QuoteStorage.editComputationSheet, computationSheet);
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async getComputationSheetById(id: number): Promise<ComputationSheetResponseModel | ErrorModel> {
    try {
      const result = await this._httpService.get(ApiRoutes.QuoteStorage.getComputationSheetById, { id });
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async getAllComputationSheetForBinding(): Promise<BindingComputationSheets[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.fakeLinkData();
      }
      const result = await this._httpService.get(ApiRoutes.QuoteStorage.getAllComputationSheetsForBindingByUserId);
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  private fakeLinkData(): BindingComputationSheets[] {
    let data = [
      { id:1, coverSheet:{companyName:'companyName1',city:'city1',comment:'comment1',locationCity:'locationCity1',contact:'contact1',customerName:'customerName1',locationName:'locationName1',quoteNumber:'quoteNumber1',id:1,numberTypical:null,type:null} },
      { id:2, coverSheet:{companyName:'companyName2',city:'city2',comment:'comment2',locationCity:'locationCity2',contact:'contact2',customerName:'customerName2',locationName:'locationName2',quoteNumber:'quoteNumber2',id:2,numberTypical:null,type:null} },
      { id:3, coverSheet:{companyName:'companyName3',city:'city3',comment:'comment3',locationCity:'locationCity3',contact:'contact3',customerName:'customerName3',locationName:'locationName3',quoteNumber:'quoteNumber3',id:3,numberTypical:null,type:null} },
      { id:4, coverSheet:{companyName:'companyName4',city:'city4',comment:'comment4',locationCity:'locationCity4',contact:'contact4',customerName:'customerName4',locationName:'locationName4',quoteNumber:'quoteNumber4',id:4,numberTypical:null,type:null} },
      { id:5, coverSheet:{companyName:'companyName5',city:'city5',comment:'comment5',locationCity:'locationCity5',contact:'contact5',customerName:'customerName5',locationName:'locationName5',quoteNumber:'quoteNumber5',id:5,numberTypical:null,type:null} },
      { id:6, coverSheet:{companyName:'companyName6',city:'city6',comment:'comment6',locationCity:'locationCity6',contact:'contact6',customerName:'customerName6',locationName:'locationName6',quoteNumber:'quoteNumber6',id:6,numberTypical:null,type:null} }
    ] as BindingComputationSheets[]
    return data;
  }

  private getFakeData(): AqsSavedQuoteModel[] {
    let quotes = [
      { quoteNumber: "QuoteNumberF1", sessionType: AqsSessionTypeEnum.FlatSessionType, storageType: AqsStorageTypeEnum.Flat },
      { quoteNumber: "QuoteNumberF2", sessionType: AqsSessionTypeEnum.FlatSessionType, storageType: AqsStorageTypeEnum.TruncatedPeak },
      { quoteNumber: "QuoteNumberF3", sessionType: AqsSessionTypeEnum.FlatSessionType, storageType: AqsStorageTypeEnum.SlopedPeak },
      { quoteNumber: "QuoteNumberR1", sessionType: AqsSessionTypeEnum.RoundSessionType, storageType: AqsStorageTypeEnum.Conical },
      { quoteNumber: "QuoteNumberR2", sessionType: AqsSessionTypeEnum.RoundSessionType, storageType: AqsStorageTypeEnum.SideDraw },
      { quoteNumber: "QuoteNumberC1", sessionType: AqsSessionTypeEnum.ComputationSheetType, storageType: AqsStorageTypeEnum.None },
    ] as AqsSavedQuoteModel[];
    return quotes;
  }
}
