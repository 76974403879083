import { Injectable } from '@angular/core';

import { HttpService, ErrorModel, ApiRoutes } from '../../../common';
import { CompanyUserViewModel, CompanyUserInviteModel, CompanyUserPostModel, CompanyUserGetModel } from '../../models';
import { Router } from '@angular/router';
import { UserStatusEnum } from '../../enums';

@Injectable()
export class CompanyUsersApiService {
  constructor(
    private _httpService: HttpService,
    private _router: Router) { }

  public async loadAll(userCompanyId: number): Promise<CompanyUserViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const data = await this._httpService.get(ApiRoutes.Company.getAllCompanyUser, { companyId: userCompanyId });
      if (data) {
        const companyUsersView = data.map(user => CompanyUserGetModel.toViewModel(user));
        return companyUsersView;
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async invite(companyUser: CompanyUserInviteModel): Promise<CompanyUserViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.addCompanyUser, companyUser);
      if (result) {
        return Promise.resolve(result);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async edit(companyUser: CompanyUserPostModel): Promise<CompanyUserViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.editCompanyUser, companyUser) as CompanyUserGetModel;
      if (result) {
        const companyUserViewModel = CompanyUserGetModel.toViewModel(result);
        return Promise.resolve(companyUserViewModel);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async delete(companyUserId: number): Promise<number | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.deleteUser, { id: companyUserId });
      if (result) {
        return Promise.resolve(companyUserId);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  public getFakeData(): CompanyUserViewModel[] {
    const data = [
      { id: 1, firstName: 'firstName', lastName: 'lastName', email: 'email', status: UserStatusEnum.Locked, arduinoLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], arduinoLocationNames: ['Location1', 'Location2'], raspberryLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], raspberryLocationNames: ['Location1', 'Location2'], itxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], itxLocationsNames: ['Location1', 'Location2'], lastLoginDate: new Date },
      { id: 2, firstName: 'firstName2', lastName: 'lastName2', email: 'email2', status: UserStatusEnum.Locked, arduinoLocations: [{ id: 1, name: 'Location3' }, { id: 2, name: 'Location4' }], arduinoLocationNames: ['Location3', 'Location4'], raspberryLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], raspberryLocationNames: ['Location1', 'Location2'], itxLocations: [{ id: 1, name: 'Location1' }, { id: 2, name: 'Location2' }], itxLocationsNames: ['Location1', 'Location2'], lastLoginDate: new Date }
    ] as CompanyUserViewModel[];
    return data;
  }
}
