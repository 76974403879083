import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, NgZone, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { SensorViewModel } from '../../../../models';
import { EquipmentSubTypeEnum, EquipmentTypeEnum, SensorTypeEnum, SensorPositionEnum, EquipmentSideEnum } from '../../../../enums';
import { equipmentSensorMapping } from 'src/app/main/constants/equipment-sensor-mapping.constants';
import { equipmentSideLabels, sensorPositionLabels, sensorTypeLabels, equipmentNorthSouthSideLabels, equipmentWestEastSideLabels } from 'src/app/main/constants';
import PerfectScrollbar from 'perfect-scrollbar';
import { bitOptions, disabledBitOptions } from 'src/app/main/constants/equipment-options';
import { digitsValidator } from 'src/app/common/validators';
import { EquipmentSensorDeletePopupComponent } from '../equipment-sensor-delete-popup/equipment-sensor-delete-popup.component';
import { ModbussAddress, TemplateFunctions } from '../../../common';

@Component({
  selector: 'greensleeves-equipment-scheme-editor',
  templateUrl: './equipment-scheme.component.html',
  styles: []
})
export class EquipmentSchemeEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(EquipmentSensorDeletePopupComponent, { read: false, static: false })
  private _equipmentSensorDeleteModal: EquipmentSensorDeletePopupComponent;

  @Input()
  type: EquipmentSubTypeEnum;

  @Input()
  equipment: EquipmentTypeEnum;

  @Input()
  schemes: SensorViewModel[];

  @Input()
  uniqueValidator: (sensor: SensorViewModel) => boolean;

  @Input()
  uniqueAddressesValidator: (sensor: SensorViewModel) => ModbussAddress[];

  @Output()
  schemesChange: EventEmitter<Array<SensorViewModel>> = new EventEmitter<Array<SensorViewModel>>();

  @Output()
  onEditClicked: EventEmitter<{ sensor: SensorViewModel, index: number }> = new EventEmitter<{ sensor: SensorViewModel, index: number }>();

  @Output()
  onViewClicked: EventEmitter<SensorViewModel> = new EventEmitter<SensorViewModel>();

  _view = 'view';
  _edit = 'edit';
  _delete = 'delete';
  _sensorType = 'sensorType';
  _sensorPosition = 'sensorPosition';
  _equipmentSide = 'equipmentSide';
  _generalAddressErrorText = 'Address already in use';
  _generalBitErrorText = 'Bit already in use';

  _submitted = false;
  _sensorForm: FormGroup;

  _sensorTypes: Array<{ label: string, value: number }> = [];
  _sensorPositions: Array<{ label: string, value: number }> = [];
  _equipmentSides: Array<{ label: string, value: number }> = [];

  private disableBitOptions = disabledBitOptions;
  private enabledBitOptions = bitOptions;
  _bitOptions = bitOptions;
  _rateOfChangeBitOptions = bitOptions;

  _columns = [
    { header: '', dataField: 'index', getLabel: (_, index) => ++index },
    { header: '', dataField: 'type', getLabel: (data: SensorViewModel) => data && sensorTypeLabels[data.type] },
    { header: '', dataField: 'position', getLabel: (data: SensorViewModel) => data && sensorPositionLabels[data.position] },
    { header: '', dataField: 'side', getLabel: (data: SensorViewModel) => data && equipmentSideLabels[data.side] },
    { header: '', type: this._view },
    { header: '', type: this._edit },
    { header: '', type: this._delete }
  ];

  _ps: PerfectScrollbar;
  _psConfig = {
    wheelSpeed: 2,
    wheelPropagation: true,
    minScrollbarLength: 20
  };

  get _allowedSensorTypes() {
    return this.toDropdownOptions(equipmentSensorMapping[this.equipment][this.type], this._sensorType);
  }

  get _allowedSensorPositions() {
    return this.getAllowedSensorPositions(this._sensorForm.get('type').value);
  }

  get _allowedSides() {
    return this.getAllowedSides(this._sensorForm.get('type').value, this._sensorForm.get('position').value);
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _el: ElementRef,
    private _zone: NgZone
  ) {
    this._sensorForm = _formBuilder.group({
      type: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      position: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      side: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      modbusAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      warningAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      warningAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ])
      ],
      alarmAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      alarmAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ])
      ],
      rateOfChangeWarningAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      rateOfChangeWarningAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ])
      ],
      rateOfChangeAlarmAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      rateOfChangeAlarmAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ])
      ]
    })
  }

  ngOnInit() {
    this.reinitScrollBar(0);
    this.updateFormOptions();
  }

  ngAfterViewChecked() {
    this._equipmentSensorDeleteModal.sensorDelete.subscribe(sensorId => {
      this.onDeleteSensor(sensorId);
    });
  }

  ngOnDestroy() {
    this._ps && this._ps.destroy();
    this._ps = null;
  }

  ngAfterViewInit() {
    this.reinitScrollBar(0);
  }

  onSchemeFormSubmit() {
    this._submitted = true;
    if (this._sensorForm.invalid) {
      return;
    }

    const scheme = SensorViewModel.getViewModel(this._sensorForm.value);
    if (this.uniqueValidator && !this.uniqueValidator(scheme)) {
      this._sensorForm.setErrors({
        unique: 'Sensor with such type, position and side is already set'
      });
      return;
    }

    const notUniqueAddresses = this.uniqueAddressesValidator(scheme);
    if (notUniqueAddresses && notUniqueAddresses.length > 0) {
      TemplateFunctions.markAddressControllsAsNotUnique(this._sensorForm, notUniqueAddresses);
      return;
    }

    this._sensorForm.controls.rateOfChangeAlarmAddressBit.statusChanges.subscribe
    const schemes = [
      ...this.schemes,
      scheme
    ];

    this.schemesChange.emit(schemes);
    this._submitted = false;
    this._sensorForm.reset();
    this.onSelectSensorType();
  }

  getAllowedSensorPositions(sensorType: SensorTypeEnum) {
    return this.toDropdownOptions((sensorType || sensorType === 0) && equipmentSensorMapping[this.equipment][this.type][sensorType] || null, this._sensorPosition);
  }

  getAllowedSides(sensorType: SensorTypeEnum, position: SensorPositionEnum) {
    let allowedSides = this.toDropdownOptions((sensorType || sensorType === 0) && (position || position === 0) && equipmentSensorMapping[this.equipment][this.type][sensorType][position] || null, this._equipmentSide);
    return allowedSides.filter(side => side.label);
  }

  private toDropdownOptions(map: Object, type: string) {
    if (!map) {
      return [];
    }

    let labels;
    switch (type) {
      case this._sensorType:
        labels = sensorTypeLabels;
        break;
      case this._sensorPosition:
        labels = sensorPositionLabels;
        break;
      case this._equipmentSide:
        if (this.schemes.length === 0) {
          labels = equipmentSideLabels;
        } else if (this.schemes[0].side as EquipmentSideEnum === EquipmentSideEnum.East || this.schemes[0].side as EquipmentSideEnum === EquipmentSideEnum.West) {
          labels = equipmentWestEastSideLabels;
        } else if (this.schemes[0].side as EquipmentSideEnum === EquipmentSideEnum.South || this.schemes[0].side as EquipmentSideEnum === EquipmentSideEnum.North) {
          labels = equipmentNorthSouthSideLabels;
        } else {
          labels = equipmentSideLabels;
        }
        break;
      default:
        return [];
    }

    return Object.keys(map).map(k => {
      return {
        label: labels[k],
        value: Number.parseInt(k)
      };
    });
  }

  updateFormOptions() {
    this._sensorTypes = this._allowedSensorTypes;
    this._sensorPositions = this._allowedSensorPositions;
    this._equipmentSides = this._allowedSides;
  }

  onClickEdit(sensor: SensorViewModel, index: number) {
    this.onEditClicked.emit({ sensor, index });
  }

  onClickView(sensor: SensorViewModel) {
    this.onViewClicked.emit(sensor);
  }

  onDeleteSensor(sensorId: number) {
    this.schemes = this.schemes.filter(sensor => sensor.id !== sensorId);
    this.schemesChange.emit(this.schemes);
  }

  onClickDelete(sensor: SensorViewModel) {
    this._equipmentSensorDeleteModal.showSensorView(sensor.id);
  }

  updateEditableOptions() {
    this._updateScroll();
  }

  reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.scroll-container .ui-table-scrollable-body');
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  _updateScroll() {
    this._ps && this._ps.update();
  }

  hasValue(control: FormControl) {
    return control.value || control.value === 0;
  }

  onSelectSensorType() {
    this.updateFormOptions();
    switch (this._sensorForm.controls.type.value) {
      case SensorTypeEnum.Speed:
      case SensorTypeEnum.CurrentAmpDraw:
      case SensorTypeEnum.CurrentDesignLoad:
        this._sensorForm.controls.position.setValue(SensorPositionEnum.OnePerEquipment);
        this.updateFormOptions();
        this._sensorForm.controls.side.setValue(EquipmentSideEnum.None);
        break;
      default:
        this._sensorForm.controls.position.setValue(null);
        break;
    }

    switch (this._sensorForm.controls.type.value) {
      case SensorTypeEnum.Vibration:
      case SensorTypeEnum.CurrentAmpDraw:
      case SensorTypeEnum.CurrentDesignLoad:
        this._sensorForm.controls.rateOfChangeWarningAddress.reset();
        this._sensorForm.controls.rateOfChangeWarningAddress.clearValidators();
        this._sensorForm.controls.rateOfChangeWarningAddress.disable();
        this._sensorForm.controls.rateOfChangeWarningAddress.setValue('-');
        this._sensorForm.controls.rateOfChangeWarningAddressBit.reset();
        this._sensorForm.controls.rateOfChangeWarningAddressBit.clearValidators();
        this._sensorForm.controls.rateOfChangeWarningAddressBit.setValue(null);
        this._sensorForm.controls.rateOfChangeWarningAddressBit.disable();
        this._sensorForm.controls.rateOfChangeAlarmAddress.reset();
        this._sensorForm.controls.rateOfChangeAlarmAddress.clearValidators();
        this._sensorForm.controls.rateOfChangeAlarmAddress.setValue("-");
        this._sensorForm.controls.rateOfChangeAlarmAddress.disable();
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.reset();
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.clearValidators();
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.setValue(null);
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.disable();
        this._rateOfChangeBitOptions = disabledBitOptions;
        break;
      default:
        this._sensorForm.controls.rateOfChangeWarningAddress.setValidators(Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ]));
        this._sensorForm.controls.rateOfChangeWarningAddress.setValue(null);
        this._sensorForm.controls.rateOfChangeWarningAddress.enable();
        this._sensorForm.controls.rateOfChangeWarningAddressBit.setValidators(Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ]));
        this._sensorForm.controls.rateOfChangeWarningAddressBit.enable();
        this._sensorForm.controls.rateOfChangeAlarmAddress.setValidators(Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ]));
        this._sensorForm.controls.rateOfChangeAlarmAddress.setValue(null);
        this._sensorForm.controls.rateOfChangeAlarmAddress.enable();
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.setValidators(Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(16)
        ]));
        this._sensorForm.controls.rateOfChangeAlarmAddressBit.enable();
        this._rateOfChangeBitOptions = this.enabledBitOptions;
        break;
    }
  }
}
