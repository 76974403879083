import { Routes } from '@angular/router';
import { MainRoutePaths } from './constants';
import {
  AqsComputationSheetTabComponent,
  AqsFlatStorageTabComponent,
  AqsQuotesTabComponent,
  AqsRoundStorageTabComponent,
  BtxHistoricalDataTabComponent,
  BtxLiveDataEquipmentViewTabComponent,
  BtxLiveDataTabComponent,
  BtxNotificationsTabComponent,
  BtxTabComponent,
  CompaniesTabComponent,
  CompanyAdminsTabComponent,
  CompanyUsersTabComponent,
  EmailManagementTabComponent,
  ItxAlertTabComponent,
  ItxBinsTabComponent,
  ItxLiveDataTabComponent,
  ItxReportsTabComponent,
  ItxTabComponent,
  KtxBinDetailsTabComponent,
  KtxCurrentDataTabComponent,
  KtxHistoricalDataTabComponent,
  KtxNotificationsTabComponent,
  KtxTabComponent,
  LocationsTabComponent,
  SalesUsersTabComponent,
  ServiceUsersTabComponent,
  VendorsTabComponent,
  DeviceUpdateManagementTabComponent,
} from './components';

export const serviceUserRoutes: Routes = [
  {
    path: '',
    redirectTo: MainRoutePaths.COMPANIES,
    pathMatch: 'full',
  },
  {
    path: MainRoutePaths.COMPANIES,
    component: CompaniesTabComponent,
  },
  {
    path: MainRoutePaths.LOCATIONS,
    component: LocationsTabComponent,
  },
  {
    path: MainRoutePaths.FIRMWARE_MANAGEMENT,
    component: DeviceUpdateManagementTabComponent,
  },
  {
    path: MainRoutePaths.BTX_MANAGEMENT,
    component: BtxTabComponent,
  },
  {
    path: MainRoutePaths.BTX_LIVE_DATA,
    component: BtxLiveDataTabComponent,
  },
  {
    path: MainRoutePaths.BTX_LIVE_DATA_EQUIPMENT_VIEW,
    component: BtxLiveDataEquipmentViewTabComponent,
  },
  {
    path: MainRoutePaths.BTX_HISTORICAL_DATA,
    component: BtxHistoricalDataTabComponent,
  },
  {
    path: MainRoutePaths.BTX_NOTIFICATIONS,
    component: BtxNotificationsTabComponent,
  },
  {
    path: MainRoutePaths.KTX_MANAGEMENT,
    component: KtxTabComponent,
  },
  {
    path: MainRoutePaths.KTX_LOCATION_VIEW,
    component: KtxCurrentDataTabComponent,
  },
  {
    path: `${MainRoutePaths.KTX_BIN_DETAILS}/:id`,
    component: KtxBinDetailsTabComponent,
  },
  {
    path: MainRoutePaths.KTX_HISTORICAL_DATA,
    component: KtxHistoricalDataTabComponent,
  },
  {
    path: MainRoutePaths.KTX_NOTIFICATIONS,
    component: KtxNotificationsTabComponent,
  },
  {
    path: MainRoutePaths.COMPANY_ADMINS,
    component: CompanyAdminsTabComponent,
  },
  {
    path: MainRoutePaths.SERVICE_USERS,
    component: ServiceUsersTabComponent,
  },
  {
    path: MainRoutePaths.SALES_USERS,
    component: SalesUsersTabComponent,
  },
  {
    path: MainRoutePaths.ITX_MANAGEMENT,
    component: ItxTabComponent,
  },
  {
    path: `${MainRoutePaths.ITX_STORAGES_MANAGEMENT}/:id`,
    component: ItxBinsTabComponent,
  },
  {
    path: MainRoutePaths.ITX_VIEWS,
    component: ItxLiveDataTabComponent,
  },
  {
    path: MainRoutePaths.ITX_REPORTS,
    component: ItxReportsTabComponent,
  },
  {
    path: MainRoutePaths.ITX_ALERT,
    component: ItxAlertTabComponent,
  },
];

export const companyAdminRoutes: Routes = [
  {
    path: '',
    redirectTo: MainRoutePaths.COMPANY_ADMINS,
    pathMatch: 'full',
  },
  {
    path: MainRoutePaths.COMPANY_USERS,
    component: CompanyUsersTabComponent,
  },
  {
    path: MainRoutePaths.COMPANY_ADMINS,
    component: CompanyAdminsTabComponent,
  },
  {
    path: MainRoutePaths.SERVICE_USERS,
    component: ServiceUsersTabComponent,
  },
  {
    path: MainRoutePaths.VENDORS,
    component: VendorsTabComponent,
  },
  {
    path: MainRoutePaths.EMAIL_MANAGEMENT,
    component: EmailManagementTabComponent,
  },
  {
    path: MainRoutePaths.LOCATIONS,
    component: LocationsTabComponent,
  },
  {
    path: MainRoutePaths.BTX_LIVE_DATA,
    component: BtxLiveDataTabComponent,
  },
  {
    path: MainRoutePaths.BTX_LIVE_DATA_EQUIPMENT_VIEW,
    component: BtxLiveDataEquipmentViewTabComponent,
  },
  {
    path: MainRoutePaths.BTX_HISTORICAL_DATA,
    component: BtxHistoricalDataTabComponent,
  },
  {
    path: MainRoutePaths.BTX_NOTIFICATIONS,
    component: BtxNotificationsTabComponent,
  },
  {
    path: MainRoutePaths.KTX_LOCATION_VIEW,
    component: KtxCurrentDataTabComponent,
  },
  {
    path: `${MainRoutePaths.KTX_BIN_DETAILS}/:id`,
    component: KtxBinDetailsTabComponent,
  },
  {
    path: MainRoutePaths.KTX_HISTORICAL_DATA,
    component: KtxHistoricalDataTabComponent,
  },
  {
    path: MainRoutePaths.KTX_NOTIFICATIONS,
    component: KtxNotificationsTabComponent,
  },
  {
    path: MainRoutePaths.ITX_VIEWS,
    component: ItxLiveDataTabComponent,
  },
  {
    path: MainRoutePaths.ITX_REPORTS,
    component: ItxReportsTabComponent,
  },
  {
    path: MainRoutePaths.ITX_ALERT,
    component: ItxAlertTabComponent,
  },
];

export const companyUserRoutes: Routes = [
  {
    path: MainRoutePaths.BTX_LIVE_DATA,
    component: BtxLiveDataTabComponent,
  },
  {
    path: MainRoutePaths.BTX_LIVE_DATA_EQUIPMENT_VIEW,
    component: BtxLiveDataEquipmentViewTabComponent,
  },
  {
    path: MainRoutePaths.BTX_HISTORICAL_DATA,
    component: BtxHistoricalDataTabComponent,
  },
  {
    path: MainRoutePaths.BTX_NOTIFICATIONS,
    component: BtxNotificationsTabComponent,
  },
  {
    path: MainRoutePaths.KTX_LOCATION_VIEW,
    component: KtxCurrentDataTabComponent,
  },
  {
    path: `${MainRoutePaths.KTX_BIN_DETAILS}/:id`,
    component: KtxBinDetailsTabComponent,
  },
  {
    path: MainRoutePaths.KTX_HISTORICAL_DATA,
    component: KtxHistoricalDataTabComponent,
  },
  {
    path: MainRoutePaths.KTX_NOTIFICATIONS,
    component: KtxNotificationsTabComponent,
  },
  {
    path: MainRoutePaths.ITX_VIEWS,
    component: ItxLiveDataTabComponent,
  },
  {
    path: MainRoutePaths.ITX_REPORTS,
    component: ItxReportsTabComponent,
  },
  {
    path: MainRoutePaths.ITX_ALERT,
    component: ItxAlertTabComponent,
  },
];
export const vendorRoutes: Routes = [
  {
    path: '',
    redirectTo: MainRoutePaths.ITX_VIEWS,
    pathMatch: 'full',
  },
  {
    path: MainRoutePaths.ITX_VIEWS,
    component: ItxLiveDataTabComponent,
  },
  {
    path: MainRoutePaths.ITX_REPORTS,
    component: ItxReportsTabComponent,
  },
];

export const salesUserRoutes: Routes = [
  {
    path: '',
    redirectTo: MainRoutePaths.AQS_QUOTES,
    pathMatch: 'full',
  },
  {
    path: MainRoutePaths.AQS_QUOTES,
    component: AqsQuotesTabComponent,
  },
  {
    path: MainRoutePaths.AQS_ROUND_STORAGE,
    component: AqsRoundStorageTabComponent,
  },
  {
    path: MainRoutePaths.AQS_FLAT_STORAGE,
    component: AqsFlatStorageTabComponent,
  },
  {
    path: MainRoutePaths.AQS_COMPUTATION_SHEET,
    component: AqsComputationSheetTabComponent,
  },
];
