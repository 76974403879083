import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService, ErrorModel } from '../../../common';
import { ApiRoutes } from '../../constants';
import { LocationViewModel, LocationGetModel, LocationPostModel, BtxKtxItxLocationsViewModel, BtxKtxItxLocationsGetModel, LocationItxViewModel, BTXLocationConfiguration, BtxLocationEquipmentConfiguration, BtxLocationSensorConfiguration } from '../../models';
import { stringify } from 'querystring';

@Injectable()
export class LocationsApiService {
  constructor(
    private _httpService: HttpService,
    private _router: Router,
  ) { }

  public async getAll(): Promise<LocationViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }

      const result = await this._httpService.get(ApiRoutes.Location.getAll);
      return result.map((item) => {
        return LocationGetModel.toViewModel(item);
      });
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getAllForCompany(companyId: number): Promise<LocationViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }

      const result = await this._httpService.get(ApiRoutes.Location.getAllForCompany, { companyId: companyId });
      return result.map((item) => {
        return LocationGetModel.toViewModel(item);
      });
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getKtxBtxItxLocationForCompany(companyId: number): Promise<BtxKtxItxLocationsViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeBtxKtxItxData();
      }

      const result = await this._httpService.get(ApiRoutes.Location.getKtxBtxItxLocationsForCompany, { companyId: companyId });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async add(location: LocationPostModel): Promise<LocationViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Location.add, location);

      const locationViewModel = LocationGetModel.toViewModel(result);
      return locationViewModel;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async edit(location: LocationPostModel): Promise<LocationViewModel | ErrorModel> {
    try {
      const result = await this._httpService.put(ApiRoutes.Location.edit, location);

      const locationViewModel = LocationGetModel.toViewModel(result);
      return locationViewModel;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async delete(locationId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.delete(ApiRoutes.Location.delete, { locationId });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  public getFakeData(): LocationViewModel[] {
    const data = [
      { id: 1, name: 'locationName1', companyId: 1, companyName: 'company1', serviceEmail: 'somemail1@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 2, name: 'locationName2', companyId: 2, companyName: 'company2', serviceEmail: 'somemail2@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 3, name: 'locationName3', companyId: 3, companyName: 'company3', serviceEmail: 'somemail3@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 4, name: 'locationName4', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 5, name: 'locationName5', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 6, name: 'locationName6', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 7, name: 'locationName7', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 8, name: 'locationName8', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 9, name: 'locationName9', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 10, name: 'locationName10', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 11, name: 'locationName11', companyId: 5, companyName: 'company5', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 12, name: 'locationName12', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 13, name: 'locationName13', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 14, name: 'locationName14', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 15, name: 'locationName15', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' },
      { id: 16, name: 'locationName16', companyId: 4, companyName: 'company4', serviceEmail: 'somemail4@gmail.com', servicePhone: '+8024242342343', emergencyPhone: '+802343423545435', sitePhone: '+843534539584535', siteEmail: 'siteEmailEmailEmail@email.com' }
    ] as LocationViewModel[];
    return data;
  }

  public getFakeBtxKtxItxData(): BtxKtxItxLocationsViewModel {
    const data = {
      ktxLocations: [{ id: 1, locationName: 'Location1' }, { id: 2, locationName: 'Location2' }],
      btxLocations: [{ locationId: 1, locationName: 'Location1' }, { locationId: 2, locationName: 'Location2' }],
      itxLocations: [{ id: 1, locationName: 'Location1' }, { id: 2, locationName: 'Location2' }],
    } as BtxKtxItxLocationsViewModel;

    return data;
  }
}