import { Injectable } from '@angular/core';

import { HttpService, ErrorModel, ApiRoutes } from '../../../common';
import { CompanyAdminViewModel, CompanyAdminInviteModel, CompanyAdminPostModel, CompanyAdminGetModel } from '../../models';

@Injectable()
export class CompanyAdminsApiService {
  constructor(
    private _httpService: HttpService) { }

  public async loadAll() : Promise<CompanyAdminViewModel[] | ErrorModel> {
    try {
      const data = await this._httpService.get(ApiRoutes.Company.getAllCompanyAdmin) as CompanyAdminGetModel[];
      if (data) {
        const companyAdminsView = data.map(user => CompanyAdminGetModel.toViewModel(user));
        return companyAdminsView;
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async loadAllForCompany(companyId: number) : Promise<CompanyAdminViewModel[] | ErrorModel> {
    try {
      const data = await this._httpService.get(ApiRoutes.Company.getAllCompanyAdminForCompany, { companyId: companyId });
      return data
    } catch (error) {
      return this.processError(error);
    }
  }

  public async invite(companyAdmin: CompanyAdminInviteModel) : Promise<CompanyAdminViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Account.addCompanyAdmin, companyAdmin);
      if (result != null) {
        return Promise.resolve(result);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async edit(companyAdmin: CompanyAdminPostModel) : Promise<CompanyAdminViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post( ApiRoutes.Account.editCompanyAdmin, companyAdmin) as CompanyAdminGetModel;
      if (result) {
        const companyAdminViewModel = CompanyAdminGetModel.toViewModel(result);
        return Promise.resolve(companyAdminViewModel);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  public async delete(companyAdminId: number) : Promise<number | ErrorModel> {
    try {
      const result = await this._httpService.post( ApiRoutes.Account.deleteUser, { id: companyAdminId })
      if (result) {
      return Promise.resolve(companyAdminId);
      }
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }
}
