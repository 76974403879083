import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthorizeService, LocalStorageService } from '../common';
import { CommonFunctions } from '../common';
import { RoutePaths } from '../common';

@Injectable()
export class AccessTokenFactory {
    constructor(
        private _authorizeService: AuthorizeService,
        private _localStorageService: LocalStorageService,
        private _router: Router,
        ) { }

    public async getAuthorizationAttributeValue(): Promise<string> {
        let loginInfo = this._localStorageService.getLoginInfo();
        const isNotExpired = CommonFunctions.checkIfTimeNotExpired(loginInfo.expiresAt)
        if (!isNotExpired) {
            const result = await this._authorizeService.refreshTokens();
            if (!result) {
              this._router.navigate([RoutePaths.SIGN_IN]);
            }

            loginInfo = this._localStorageService.getLoginInfo();
        }

        return Promise.resolve(loginInfo.authToken);
    }
}