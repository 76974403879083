import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { 
  AlarmViewModel, 
  FullnessRangeViewModel, 
  LocationItxViewModel, 
  FullnessRangePostModel } from '../../../models';

import { ColumnTypeEnum } from '../../../enums';
import { BasePopupBehavior } from '../common';
import {
  LocationsItxDatasourceService,
  LocationsKtxDataSourceService
} from '../../../services';
import { ConvertDataFunctions, digitsValidator, ErrorModel } from '../../../../common';

import { ItxRangeDeletePopupComponent } from './itx-range-delete-popup';

@Component({
  selector: 'greensleeves-itx-manage-color-popup',
  templateUrl: './itx-manage-color-popup.component.html'
})
export class ItxManageColorPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewInit {
  static FROM_FIELD = 'from';
  static DEFAULT_COLOR = '#FF0000';
  static TO_FIELD = 'to';
  static COLOR_HEX_FIELD = 'colorHex';
  static PERCENTAGES = 100;
  public customPatterns = { '9': { pattern: new RegExp('\\d')} };

  _isHidden: boolean = true;
  _columnTypes = ColumnTypeEnum;
  _rangeForm: FormGroup;
  _rangeSubmitted: boolean;
  _onSubmit: boolean;
  _subscriptions: Subscription[] = [];
  _gridData: FullnessRangeViewModel[];
  _uniqueRangeError: boolean;
  _percentageGapsError: boolean;
  _isSubsequentError: boolean;
  _locationId: number;
  _rangeToAdd: FullnessRangeViewModel;
  _isLoading: boolean;
  _sortField: string = 'from';
  _fullnessRangeDelta: number = -1;
  onRangeDeleteClicked = new EventEmitter<FullnessRangeViewModel>();

  _columnsView = [
    { header: '#', columnType: ColumnTypeEnum.Number },
    { header: 'From', columnType: ColumnTypeEnum.From, dataField: ItxManageColorPopupComponent.FROM_FIELD },
    { header: 'To', columnType: ColumnTypeEnum.To, dataField: ItxManageColorPopupComponent.TO_FIELD },
    { header: 'Color', columnType: ColumnTypeEnum.Color, dataField: ItxManageColorPopupComponent.COLOR_HEX_FIELD },
    { header: '', columnType: ColumnTypeEnum.Delete },
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private _el: ElementRef,
    private _locationItxDataSourceService: LocationsItxDatasourceService) {
    super();
  }

  ngOnInit() {
    this._rangeToAdd = new FullnessRangeViewModel();

    this._rangeForm = this._formBuilder.group({
      from: [null, Validators.compose([
        Validators.required,         
        Validators.min(1),
        Validators.max(100),
        digitsValidator
      ])],
      to: [null, Validators.compose([
        Validators.required,         
        Validators.min(1),
        Validators.max(100),
        digitsValidator
      ])],
      colorHex: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    this.reinitScrollBar(0);
  }

  onClose() {
    this._gridData = [];
    this._rangeSubmitted = false;
    this._percentageGapsError = false;
    this._onSubmit = false;
    this._isSubsequentError = false;
    this._uniqueRangeError = false;
    this._rangeToAdd = new FullnessRangeViewModel();
  }

  show(location: LocationItxViewModel) {
    this._isHidden = false;
    this._locationId = location.id;
    this._isLoading = true;
    this._rangeToAdd.colorHex = ItxManageColorPopupComponent.DEFAULT_COLOR;
    this._locationItxDataSourceService.getFullnessRanges(location.id).then(()=>{
      this._isLoading = false;

      this._gridData = location.fullnessRanges ? JSON.parse(JSON.stringify(location.fullnessRanges)) : [];
    });
  }

  onFocusRange() {
    if (this._uniqueRangeError) {
      this._uniqueRangeError = false;
    }
    if (this._isSubsequentError) {
      this._isSubsequentError = false;
    }
  }

  ngOnDestroy() {
    this.destroyMainScrollBar();
    this._subscriptions && this._subscriptions.forEach(x => x.unsubscribe());
    super.ngOnDestroy();
  }

  onClickDelete(range: FullnessRangeViewModel) {
    this.onRangeDeleteClicked.emit(range);
  }

  removeRange(range: FullnessRangeViewModel) {
    this._gridData.splice(this._gridData.indexOf(range), 1);
  }

  reinitScrollBar(timeout: number) {
    const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view .ui-table-scrollable-body');
    this.reinitMainScrollBar(el, timeout);
  }

  onRangeSubmit() {
    this._rangeSubmitted = true;
    this._percentageGapsError = false;
    if (this._rangeForm.invalid) {
      return;
    }

    this._rangeToAdd.from = +this._rangeToAdd.from;
    this._rangeToAdd.to = +this._rangeToAdd.to;

    if(this._rangeToAdd.from >= this._rangeToAdd.to) {
      this._isSubsequentError = true;
      return;
    }

    if (this._gridData.some(x => x.from === this._rangeToAdd.from && x.to === this._rangeToAdd.to)) {
      this._uniqueRangeError = true;
      return;
    }

    if (this._gridData.some(x => (
            (this._rangeToAdd.from >= x.from && this._rangeToAdd.from <= x.to)
        ||  (this._rangeToAdd.to >= x.from && this._rangeToAdd.to <= x.to)
        ||  (x.from > this._rangeToAdd.from && x.to < this._rangeToAdd.to)))) {

      this._isSubsequentError = true;
      return;
    }

    this._rangeSubmitted = false;
    this._gridData.push(this._rangeToAdd);
    var tempColor = this._rangeToAdd.colorHex;
    this._rangeToAdd = new FullnessRangeViewModel();
    this._rangeToAdd.colorHex = tempColor;
    tempColor = null;
  }

  onSubmit() {
    this._onSubmit =true;
    const editModel = {
      locationId: this._locationId,
      ranges: JSON.parse(JSON.stringify(this._gridData)),
    } as FullnessRangePostModel;
    if(!this.checkForGaps(editModel)){
      this._percentageGapsError = true;
            return;
    }

    this._locationItxDataSourceService.editFullnessRange(editModel).then((result) => {
      if (result instanceof ErrorModel) {
        console.error(result);
      } else {
        this.onClose();
        this._isHidden = true;
      }
    })
  }

  private checkForGaps (model: FullnessRangePostModel): boolean {
    this._fullnessRangeDelta = 0;
    model.ranges.forEach(x => {
      this._fullnessRangeDelta += x.to+1;
      this._fullnessRangeDelta -= x.from;
    })
    if(this._fullnessRangeDelta != ItxManageColorPopupComponent.PERCENTAGES)
    return false;
    return true;
  }



  showAdd() {
    this._isHidden = false;
  }
  
  onCancel(){
    this._isHidden = true;
  }

}
