import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'greensleeves-upload-success-popup',
  templateUrl: './upload-success-popup.component.html',
  styles: []
})
export class UploadSuccessPopupComponent implements OnInit {
  @Output()
  onClose = new EventEmitter<void>();

  _isHidden = true;
  _submitted = false;
  _error: string;

  constructor(
  ) { }

  ngOnInit() {
  }

  public show() {
    this._isHidden = false;
  }

  async onSubmit() {
    this._error = null;
    this._isHidden = true;
    this.onClose.next();
  }
}