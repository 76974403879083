import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FullnessRangePostModel } from './../../models/fullness-range.model';
import { ErrorModel } from 'src/app/common';
import { HubService, LocationsItxApiService } from "../http";
import { HubMethods } from "../../constants";
import {
  LocationItxViewModel,
  FullnessRangesGetModel,
  LocationItxPostModel, LocationItxUpdatedHubMessage,
  LocationItxDeletedHubMessage,
  LocationItxAddedHubMessage,
  LocationItxInUpdateHubMessage,
  LocationItxStoragesInViewHubMessage,
  LocationItxFullnessRangeUpdatedHubMessage,
  ItxBinAddedHubMessage,
  ItxBinDeletedHubMessage,
  LocationItxDeviceInInitializationHubMessage,
  LocationItxDeviceInitializedHubMessage,
  ItxLocationNameModel,
} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class LocationsItxDatasourceService {
  public locationsItx$: BehaviorSubject<LocationItxViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(
    private _locationsItxApi: LocationsItxApiService,
    private _hubService: HubService,
  ) {
    this.subscribeToHubService();
  }


  public async getLocationsWithBinsForCompany(companyId: number) {
    try {
      const data = await this._locationsItxApi.getItxLocationForCompany(companyId) as LocationItxViewModel[];
      if (data) {
        this.locationsItx$.next(data as LocationItxViewModel[]);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async get() {
    try {
      const data = await this._locationsItxApi.getAll();
      this.locationsItx$.next(data as LocationItxViewModel[]);
      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async markLocationItxForOthersAsInUpdate(
    locationId: number
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.LocationItx.locationItxInUpdate,
      new LocationItxInUpdateHubMessage(locationId)
    );
  }

  public async markLocationItxForOthersAsStoragesInView(
    locationId: number,
    isView: boolean
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.LocationItx.locationItxStorageInView,
      new LocationItxStoragesInViewHubMessage(locationId, isView)
    );
  }

  public async markLocationItxForOthersAsUpdated(
    location: LocationItxViewModel,
    withChanges: boolean
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.LocationItx.locationItxUpdated,
      new LocationItxUpdatedHubMessage(location, withChanges)
    );
  }

  public async add(location: LocationItxViewModel): Promise<LocationItxViewModel | ErrorModel> {
    try {
      const locationPostModel = new LocationItxPostModel(location);
      const result = (await this._locationsItxApi.add(
        locationPostModel
      )) as LocationItxViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(
          HubMethods.LocationItx.locationItxAdded,
          new LocationItxAddedHubMessage(result)
        );
        this.locationsItx$.next([...this.locationsItx$.getValue(), result]);
        return Promise.resolve(result);
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }
  public async edit(
    location: LocationItxViewModel
  ): Promise<LocationItxViewModel | ErrorModel> {
    try {
      const locationPutModel = new LocationItxPostModel(location);
      const result = (await this._locationsItxApi.edit(
        locationPutModel
      )) as LocationItxViewModel;
      if (result) {
        this.locationsItx$.next(
          this.locationsItx$
            .getValue()
            .map((l) => (l.id === result.id ? result : l))
        );
        return Promise.resolve(result);
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async delete(locationId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._locationsItxApi.delete(locationId);
      if (result) {
        this._hubService.sendToAllServiceUsers(
          HubMethods.LocationItx.locationItxDeleted,
          new LocationItxDeletedHubMessage(locationId)
        );
        const locations = this.locationsItx$
          .getValue()
          .filter((l) => l.id !== locationId);
        this.locationsItx$.next(locations);
        return Promise.resolve(true);
      }
      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getFullnessRanges(locationId: number) {
    try {
      const result = await this._locationsItxApi.getFullnessRanges(locationId) as FullnessRangesGetModel;

      if (result) {
        const location = this.locationsItx$.getValue().find(x => x.id === result.locationId);
        location.fullnessRanges = result.fullnessRanges;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async editFullnessRange(editFullnessmodel: FullnessRangePostModel): Promise<FullnessRangesGetModel | ErrorModel> {
    try {
      const result = await this._locationsItxApi.editFullnessRange(editFullnessmodel) as FullnessRangesGetModel
      if (result) {
        const location = this.locationsItx$.getValue().find(l => l.id == result.locationId);
        if (location) {
          location.isInitialized = result.isInitialized;
          location.canDeviceBeInitialized = result.canDeviceBeInitialized;
          location.fullnessRanges = result.fullnessRanges;
        }

        await this._hubService.sendToAll(HubMethods.LocationItx.locationItxFullnessRangeUpdated, new LocationItxFullnessRangeUpdatedHubMessage(editFullnessmodel));
      }

      return result;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  private subscribeToHubService() {
    this._hubService.locationItxAdded.subscribe(
      (hubMessage: LocationItxAddedHubMessage) => {
        if (hubMessage && hubMessage.newLocationItx) {
          this.locationsItx$.next([
            ...this.locationsItx$.getValue(),
            hubMessage.newLocationItx,
          ]);
        }
      }
    );

    this._hubService.locationItxStorageInView.subscribe(
      (hubMessage: LocationItxStoragesInViewHubMessage) => {
        if (hubMessage) {
          const location = this.locationsItx$
            .getValue()
            .find((l) => l.id === hubMessage.locationId);
          if (location) {
            location.isSomeoneInViewStorages = hubMessage.isView;
          }
        }
      }
    );

    this._hubService.locationItxInUpdate.subscribe(
      (hubMessage: LocationItxInUpdateHubMessage) => {
        if (hubMessage) {
          const location = this.locationsItx$
            .getValue()
            .find((l) => l.id === hubMessage.locationId);
          if (location) {
            location.isSomeoneUpdateLocation = true;
          }
        }
      }
    );
    this._hubService.locationItxUpdated.subscribe(
      (hubMessage: LocationItxUpdatedHubMessage) => {
        if (hubMessage && hubMessage.updatedLocationItx) {
          if (hubMessage.withCahnges) {
            hubMessage.updatedLocationItx.isSomeoneUpdateLocation = false;
            this.locationsItx$.next(
              this.locationsItx$.getValue().map((l) => {
                if (l.id == hubMessage.updatedLocationItx.id) {
                  return hubMessage.updatedLocationItx;
                } else {
                  return l;
                }
              })
            );
          } else {
            const location = this.locationsItx$
              .getValue()
              .find((l) => l.id == hubMessage.updatedLocationItx.id);
            location.isSomeoneUpdateLocation = false;
            console.log(location.isSomeoneUpdateLocation)
          }
        }
      }
    );
    this._hubService.locationItxDeleted.subscribe(
      (hubMessage: LocationItxDeletedHubMessage) => {
        if (hubMessage) {
          const locations = this.locationsItx$
            .getValue()
            .filter((l) => l.id !== hubMessage.locationId);
          this.locationsItx$.next(locations);
        }
      }
    );
    this._hubService.fullnessRangeUpdated.subscribe((hubMessage: LocationItxFullnessRangeUpdatedHubMessage) => {
      if (hubMessage) {
        const location = this.locationsItx$.getValue().find(x => x.id === hubMessage.updatedRanges.locationId);
        if (location) {
          location.fullnessRanges = hubMessage.updatedRanges.ranges;
        }
      }
    });
    this._hubService.itxBinAdded.subscribe((hubMessage: ItxBinAddedHubMessage) => {
      if (hubMessage) {
        const locations = this.locationsItx$.getValue();
        const location = locations.find(l => l.id === hubMessage.newItxbin.locationId);
        if (location) {
          location.countOfBins++;
          this.locationsItx$.next(locations);
        }
      }
    });
    this._hubService.itxBinDeleted.subscribe((hubMessage: ItxBinDeletedHubMessage) => {
      const locations = this.locationsItx$.getValue();
      const location = locations.find(l => l.id === hubMessage.itxLocationId);
      if (location) {
        location.countOfBins--;
        if (location.countOfBins < 0) {
          location.countOfBins = 0;
        }
        this.locationsItx$.next(locations);
      }
    });

    this._hubService.locationItxDeviceInInitialization.subscribe((hubMessage: LocationItxDeviceInInitializationHubMessage) => {
      const locations = this.locationsItx$.getValue();
      const location = locations.find(l => l.id == hubMessage.locationId);
      if (location) {
        location.isDeviceInInitialization = true;
        this.locationsItx$.next(locations);
      }
    });
    this._hubService.locationItxDeviceInitialized.subscribe((hubMessage: LocationItxDeviceInitializedHubMessage) => {
      const locations = this.locationsItx$.getValue();
      const location = locations.find(l => l.id == hubMessage.locationId);
      if (location) {
        location.isDeviceInInitialization = false;
        location.isInitialized = hubMessage.isInitialized;
        this.locationsItx$.next(locations);
      }
    });
  }

  public async initializeDeviceConfiguration(locationId: number): Promise<boolean | ErrorModel> {
    try {
      await this._hubService.sendToAllServiceUsers(HubMethods.LocationItx.locationItxDeviceInInitialization, new LocationItxDeviceInInitializationHubMessage(locationId));
      const result = await this._locationsItxApi.initializeDeviceConfiguration(locationId);
      await this._hubService.sendToAllServiceUsers(HubMethods.LocationItx.locationItxDeviceInitialized, new LocationItxDeviceInitializedHubMessage(locationId, result as boolean));
      if (result) {
        return Promise.resolve(true);
      }
      return false;
    } catch (error) {
      await this._hubService.sendToAllServiceUsers(HubMethods.LocationItx.locationItxDeviceInitialized, new LocationItxDeviceInitializedHubMessage(locationId, false));
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadConnectionFile(locationId: number): Promise<Blob | ErrorModel> {
    try {
      const result = await this._locationsItxApi.downloadConnectionFile(locationId);
      return Promise.resolve(new Blob([result as BinaryType], { type: "application/zip" }));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getListOfAssignedItxLocationsWithItxBins(): Promise< ItxLocationNameModel[] | ErrorModel> {
    try {
      const data = await this._locationsItxApi.getListOfAssignedItxLocationsWithItxBins() as ItxLocationNameModel[];
      return data;
    } catch (error) {
      this.errors$.next(error);
    }
  }
}
