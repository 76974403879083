import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService, ErrorModel } from '../../../common';
import { CompanyViewModel, CompanyGetModel, CompanyPostModel } from '../../models';
import { ApiRoutes } from '../../constants';

@Injectable()
export class CompaniesApiService {
  constructor(
    private _httpService: HttpService,
    private _router: Router,
  ) { }

  public async loadAll(): Promise<CompanyViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }

      const result = await this._httpService.get(ApiRoutes.Company.getAll);

      return result.map((item) => {
        return CompanyGetModel.toViewModel(item);
      });
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }

      console.log(error);
      return null;
    }
  }

  public async add(companyName: string): Promise<CompanyViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Company.add, companyName);
      if (result) {
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async edit(company: CompanyPostModel): Promise<CompanyViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Company.edit, company) as CompanyGetModel;
      if (result) {
        const companyViewModel = CompanyGetModel.toViewModel(result);
        return companyViewModel;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async delete(companyId: number): Promise<number | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Company.delete, { id: companyId })
      return Promise.resolve(companyId);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }
    }
  }

  public async getCompaniesForUpdaterFilter(): Promise<CompanyViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }

      const result = await this._httpService.get(ApiRoutes.Company.getCompaniesForUpdaterFilter);
      return result.map((item) => {
        return CompanyGetModel.toViewModel(item);
      });
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }

      console.log(error);
      return null;
    }
  }

  private getFakeData(): CompanyViewModel[] {
    const data = [
      { id: 1, name: 'Company1' },
      { id: 2, name: 'Company2' },
      { id: 3, name: 'Company3' },
      { id: 4, name: 'Company4' },
      { id: 5, name: 'Company5' }
    ] as CompanyViewModel[];
    return data;
  }
}