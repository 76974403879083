import { PartTypeEnum } from "../../../enums";

export class PartInfoModel {
    public id: number;
    public partNumber: string;
    public quantity: number;
    public description: string;
    public weight: number;
    public unitPriceMaterial: number;
    public unitPriceLabor: number;
    public totalPriceMaterial: number;
    public totalPriceLabor: number;
    public partType: PartTypeEnum;
}

export class PartInfoAddEditModel {
    public id: number;
    public partNumber: string;
    public quantity: number;
    public description: string;
    public weight: number;
    public unitPriceMaterial: number;
    public unitPriceLabor: number;
    public partType: PartTypeEnum;
    public position: number;
    public computationSheetId: number;
}

export class PartInfoResponseModel {
    public id: number;
    public partNumber: string;
    public quantity: number;
    public description: string;
    public weight: number;
    public unitPriceMaterial: number;
    public unitPriceLabor: number;
    public partType: PartTypeEnum;
    public position: number;
}