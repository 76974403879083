import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AqsAirflowFanTypeViewModel } from '../../models';
import { AqsAirflowApiService } from '../http';
import { ErrorModel } from './../../../common';

@Injectable({
  providedIn: 'root'
})
export class AqsAirflowDatasourceService {
  public aqsFanTypesWithFans$: BehaviorSubject<AqsAirflowFanTypeViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  
  constructor(private _aqsAirflowApi: AqsAirflowApiService) { }

  public async getAllFanTypesWithFans() {
    try {
      let aqsFanTypes = this.aqsFanTypesWithFans$.getValue();
      if (!aqsFanTypes || aqsFanTypes && aqsFanTypes.length == 0) {
        const data = await this._aqsAirflowApi.getAllFanTypesWithFans() as AqsAirflowFanTypeViewModel[];
        if (data) {
          this.aqsFanTypesWithFans$.next(data);
          this.errors$.next(null);
        }
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }
}
