import {
  AqsAirflowViewModel, AqsCommoditiesViewModel,
  AqsCoverSheetModel, AqsExhausterViewModel, IncludeFlowOption
} from "@models";
import { AQSFlatStorageTypesEnum, AqsSessionTypeEnum, ReportFormatEnum } from "./../../../enums";
import { AqsFlatDuctModel } from "./aqs-flat-duct.model";

export class QuoteFlatStorageViewModel {
  public id: number;
  public coverSheet: AqsCoverSheetModel;
  public flatStorage: FlatStorageParams;
  public commodities: AqsCommoditiesViewModel[];
  public exhausterSheet: AqsExhausterViewModel;
  public session: AqsSessionTypeEnum;
  public airflowConfiguration: AqsAirflowViewModel;
  public includeFlowOption: IncludeFlowOption;
  public flatDuctModel: AqsFlatDuctModel;
  public userId: number;
  public linkComputationSheetId: number;

  constructor() {
    this.linkComputationSheetId = null;
  }
}

export class QuoteFlatStorageReportModel {
  public coverSheet: AqsCoverSheetModel;
  public flatStorage: FlatStorageParams;
  public commodities: AqsCommoditiesViewModel[];
  public airflowConfiguration: AqsAirflowViewModel;
  public includeFlowOption: IncludeFlowOption;
  public exhausterSheet: AqsExhausterViewModel;
  public ductDesign: AqsFlatDuctModel;
  public reportFormat: ReportFormatEnum;
  public timeZone: string;
  public printDate: Date;
}

export class FlatStorageParams {
  public id: number;
  public storageType: AQSFlatStorageTypesEnum;

  public width: number;
  public lenght: number;
  public height: number;

  //capacity
  public effectiveDepth: number;
  public buCapacityPile: number;

  //flat peaked storage
  public repose?: number;
  public rise?: number;

  //flat sloped and trunced
  public qAllHeight?: number

  //roof parameters for 2-4 types
  public offsetLenght: number;
  public offsetWidth: number;
  public roof: number;

  commodities: AqsCommoditiesViewModel[];

  constructor() {
    this.id = 0;
    this.storageType = AQSFlatStorageTypesEnum.Flat;

    this.width = 50;
    this.lenght = 100;
    this.height = 10;

    this.repose = 0;
    this.rise = 0;

    this.qAllHeight = 20;

    this.offsetLenght = 12.5;
    this.offsetWidth = this.offsetLenght;
    this.roof = 0;
    this.commodities = [];

    this.getCalculateCapacity();
  }

  onChangeStorageType() {
    this.width = 50;
    this.lenght = 100;
    this.height = 10;

    this.offsetLenght = 12.5;
    this.offsetWidth = this.offsetLenght;
    this.roof = 10;
    this.qAllHeight = this.height + this.roof;
    this.onChangeQAllHeight();
    this.getCalculateCapacity();
  }

  getCalculateRoofParams() {
    this._getParametersByType();
    this.getCalcualteRiseRepose();
  }

  private getCalcualteRiseRepose() {
    this.rise = (this.roof * 12) / this.offsetWidth;
    this.repose =
      (Math.atan(this.rise / 12) * 180) / Math.PI;
  }

  // FAUTODIM 2830 ZINITIAL=PILEWIDTH:Y=9:GOSUB 3620:IF FLAG$="M" OR FLAG$="C" OR FLAG$="D" THEN RETURN
  onChangeWidth() {
    if (this.storageType !== AQSFlatStorageTypesEnum.Flat) {
      if (this.storageType == AQSFlatStorageTypesEnum.Sloped || this.storageType == AQSFlatStorageTypesEnum.Peaked) {
        if (this.width < this.lenght || this.storageType == AQSFlatStorageTypesEnum.Peaked) {
          this.qAllHeight = this.rise * (this.width / 2) / 12 + this.height
        } else {
          this.qAllHeight = this.rise * (this.lenght / 2) / 12 + this.height;
        }
      }
      else {
        this.offsetWidth = this.width / 4;
        this.offsetLenght = this.offsetWidth;
        if (this.offsetLenght * 4 > this.lenght) {
          this.offsetLenght = this.lenght / 4;
          this.offsetWidth = this.offsetLenght;
        }

        this.qAllHeight = this.height + this.offsetWidth * this.rise / 12;
        this.roof = this.qAllHeight - this.height;
      }
    }
  }

  //FAUTODIM 2980 ZINITIAL=PILELENGTH:Y=10:GOSUB 3620:IF FLAG$="M" OR FLAG$="C" OR FLAG$="D" THEN RETURN
  onChangeLenght() {
    if (this.storageType === AQSFlatStorageTypesEnum.Sloped) {
      if (this.lenght < this.width) {
        this.qAllHeight = this.rise * (this.lenght / 2) / 12 + this.height;
      } else {
        this.qAllHeight = this.rise * (this.width / 2) / 12 + this.height;
      }
    }

    if (this.storageType === AQSFlatStorageTypesEnum.Truncated) {
      this.offsetWidth = this.width / 4;
      this.offsetLenght = this.offsetWidth;

      if (this.offsetLenght * 4 > this.lenght) {
        this.offsetLenght = this.lenght / 4;
        this.offsetWidth = this.offsetLenght;
      }

      this.qAllHeight = this.height + this.offsetWidth * this.rise / 12;
      this.roof = this.qAllHeight - this.height;
    }
  }

  //FAUTODIM 3210 ZINITIAL=OVERALLHEIGHT:Y=12:GOSUB 3620:IF FLAG$="C" OR FLAG$="M" OR FLAG$="D" THEN RETURN
  onChangeQAllHeight() {
    if (this.storageType == AQSFlatStorageTypesEnum.Flat) {
      this.roof = 0;
    } else {
      this.roof = this.qAllHeight - this.height;

      if (this.storageType == AQSFlatStorageTypesEnum.Peaked) {
        this.offsetLenght = 0;
        this.offsetWidth = this.width / 2;
      }
      else if (this.storageType == AQSFlatStorageTypesEnum.Sloped) {
        this.offsetWidth = this.width / 2;
        this.offsetLenght = this.offsetWidth;
      }
      else {
        let offset = this.roof * 12 / this.rise;
        if (offset + 1 < this.width / 2 || offset + 1 < this.lenght / 2) {
          this.offsetLenght = offset;
          this.offsetWidth = offset;
        }
      }
    }

    let pitch = 0;
    this.rise = (this.roof * 12) / this.offsetWidth;
    this.repose =
      (Math.atan(this.rise / 12) * 180) / Math.PI;


    if (this.storageType === AQSFlatStorageTypesEnum.Truncated) {
      if (this.width < this.lenght) {
        pitch = (this.qAllHeight - this.height) / (this.width / 2);
      } else {
        pitch = (this.qAllHeight - this.height) / (this.lenght / 2);
      }

      if (pitch < 60 / 12 && pitch > this.rise / 12) {
        this.rise = pitch * 12 + 4;
        this.repose = Math.atan(this.rise / 12) * 180 / Math.PI;
      }

    } else {
      if (this.storageType === AQSFlatStorageTypesEnum.Peaked ||
        (this.storageType === AQSFlatStorageTypesEnum.Sloped && this.width > this.lenght)) {
        this.rise = ((this.qAllHeight - this.height) * 12) / (this.width / 2);
      } else {
        this.rise = ((this.qAllHeight - this.height) * 12) / (this.lenght / 2);
      }

      this.repose = Math.atan(this.rise / 12) * 180 / Math.PI;
    }
  }

  //FAUTODIM 3480 ZINITIAL=RISE:Y=19:GOSUB 3620:IF  FLAG$="M" OR FLAG$="C" OR FLAG$="D" THEN RETURN
  onChangeRise() {
    this.repose = Math.atan(this.rise / 12) * 180 / Math.PI;
    //3530 REPOSE=ATN(RISE/12)*180/PI

    if (this.storageType !== AQSFlatStorageTypesEnum.Truncated) {
      if (
        this.storageType === AQSFlatStorageTypesEnum.Peaked ||
        (this.storageType === AQSFlatStorageTypesEnum.Sloped && this.lenght > this.width)
      ) {
        this.qAllHeight = this.rise * this.width / 24 + this.height;
      } else {
        this.qAllHeight = this.rise * this.lenght / 24 + this.height;
      }
    }
    this.roof = this.qAllHeight - this.height;
  }

  //AUTODIM 3360 ZINITIAL=REPOSE:Y=17:GOSUB 3620:IF FLAG$="M" OR FLAG$="C" OR FLAG$="D" THEN RETURN
  onChangeRepose() {
    this.rise = Math.tan(this.repose * Math.PI / 180) * 12;

    if (this.storageType !== AQSFlatStorageTypesEnum.Truncated) {
      if (
        this.storageType === AQSFlatStorageTypesEnum.Peaked ||
        (this.storageType === AQSFlatStorageTypesEnum.Sloped && this.lenght > this.width)
      ) {
        this.qAllHeight = this.rise * this.width / 24 + this.height;
      } else {
        this.qAllHeight = this.rise * this.lenght / 24 + this.height;
      }
    }
  }

  getCalculateCapacity() {
    this._getParametersByType();
    if (
      this.width > 0 &&
      this.height > 0 &&
      this.lenght > 0
    ) {
      let width = this.width;
      let height = this.height;
      let lenght = this.lenght;
      let offsetLenght = this.offsetLenght;
      let offsetWidth = this.offsetWidth;
      let roof = this.roof;

      //Lower rectangle capacity to follow
      let capacity = width * height * lenght;
      //Peak internal rectangle
      capacity += (lenght - 2 * offsetLenght) * (width - 2 * offsetWidth) * roof;
      //4 corner pieces together form  a pyramid
      capacity += (offsetWidth * 2 * offsetLenght * 2 * roof) / 3;
      //Each side piece as a gableroof
      capacity += offsetWidth * (lenght - offsetLenght * 2) * roof;
      //Each end piece as a gableroof
      capacity += (width - offsetWidth * 2) * offsetLenght * roof;

      this.effectiveDepth = capacity / (lenght * width);
      this.buCapacityPile = capacity * 0.8036;
    }
  }

  private _getParametersByType() {
    switch (this.storageType) {
      case 1: {
        this.roof = 0;
        break;
      }
      case 2: {
        this.offsetLenght = 0;
        this.offsetWidth = this.width / 2;
        this.roof = this.qAllHeight - this.height;
        break;
      }
      case 3: {
        this.offsetWidth = this.width / 2;
        this.offsetLenght = this.offsetWidth;
        this.roof = this.qAllHeight - this.height;
        break;
      }
      case 4: {
        let offset = this.roof * 12 / this.rise;
        if (offset + 1 < this.width / 2 || offset + 1 < this.lenght / 2) {
          this.offsetLenght = offset;
          this.offsetWidth = offset;
        }
        break;
      }
    }
  }
}
