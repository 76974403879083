import { Component, EventEmitter, OnInit } from '@angular/core';

import { ItxBinDataSourceService } from '../../../services';
import { ErrorModel } from '../../../../common';
import { RadarViewModel } from '@models';

@Component({
  selector: 'greensleeves-itx-delete-radar-popup',
  templateUrl: './itx-delete-radar-popup.component.html',
  styles: []
})
export class ItxDeleteRadarPopupComponent implements OnInit {
  _isHidden = true;
  radar: RadarViewModel;
  _error: string;
  _submitted: boolean;
  onDeletedRadar = new EventEmitter<number>();

  constructor(
    private _itxBinDataSourceService: ItxBinDataSourceService
  ) { }

  ngOnInit() {
  }

  public show(radar: RadarViewModel) {
    this._isHidden = false;
    this.radar = radar;
  }

  onCancel() {
    this._isHidden = true;
    this._error = null;
    this.radar = null;
    this._submitted = false;
  }

  async onSubmit() {
    if (!this._submitted)
      this._submitted = true;
    let result: boolean | ErrorModel;
    result = await this._itxBinDataSourceService.deleteRadar(this.radar);

    if (result === true) {
      this.onDeletedRadar.emit(this.radar.id);
      this.onCancel();
    } else if (result === false) {
      this._error = 'Unknown error occured';
    } else {
      const errorModel = result as ErrorModel;
      if (errorModel) {
        this._error = errorModel.message;
      }
    }
    this._submitted = false;
  }
}
