import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectFirmwareViewModel } from '@models';
import { UpdaterDeviceDatasourceService } from '@services';
import { Subscription } from 'rxjs';
import { ColumnTypeEnum, ConfirmationDialogEnum, ProjectEnum } from '../../../enums';

import { BasePopupBehavior } from '../common';
import { UploadFirmwarePopupComponent } from '../upload-firmware-popup';
import { FirmwareFileFolder } from '../../../constants/device-update.constants';
import { ConfirmationPopupComponent } from '../confirmation-popup';

@Component({
  selector: 'greensleeves-firmware-management-popup',
  templateUrl: './firmware-management-popup.component.html',
  styleUrls: []
})
export class FirmwareManagementPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy {
  @ViewChild(UploadFirmwarePopupComponent, { read: false, static: false })
  private uploadComponent: UploadFirmwarePopupComponent;

  @ViewChild(ConfirmationPopupComponent, { read: false, static: false })
  private confirmationPopup: ConfirmationPopupComponent;

  static LAST_MODIFIED_DATE_KEY = 'lastModified';
  static SORT_ORDER_DEFAULT = -1;

  _columnTypeEnum = ColumnTypeEnum;
  _sortOrder = FirmwareManagementPopupComponent.SORT_ORDER_DEFAULT;
  _sortField = FirmwareManagementPopupComponent.LAST_MODIFIED_DATE_KEY;
  _isHidden = true;
  _locationName: string = '';
  _gridData: ProjectFirmwareViewModel[];
  _selectedFiles: ProjectFirmwareViewModel[] = [];
  _loading = true;
  _isDeleteDownloadArchive = false;
  _isDownloadFile = 'isDownload';
  _dropdownOptions: { label: string, value: string }[] = [
    { label: ProjectEnum[ProjectEnum.Btx], value: FirmwareFileFolder[ProjectEnum.Btx] },
    { label: ProjectEnum[ProjectEnum.Ktx], value: FirmwareFileFolder[ProjectEnum.Ktx] },
    { label: ProjectEnum[ProjectEnum.Itx], value: FirmwareFileFolder[ProjectEnum.Itx] }];
  _selectedProject: string;

  _columnsView = [
    { header: 'Selected', columnType: ColumnTypeEnum.QuoteSelected, dataField: 'isSelected', width: 100 },
    { header: 'File', columnType: ColumnTypeEnum.FileName, dataField: 'name', width: 200 },
    { header: 'Last modified', columnType: ColumnTypeEnum.Date, dataField: 'lastModified', width: 150 },
    { header: 'Creation date', columnType: ColumnTypeEnum.Date, dataField: 'createdOn', width: 150 },
    { header: 'Size', columnType: ColumnTypeEnum.Details, dataField: 'size', width: 100 },
    { header: '', columnType: ColumnTypeEnum.DownloadConnectionFile, width: 135 },
  ];

  private _subscriptions: Subscription[] = [];

  constructor(
    private _updaterDeviceDatasourceService: UpdaterDeviceDatasourceService,
  ) {
    super();
  }

  async ngOnInit() {
    let firmwareSub = this._updaterDeviceDatasourceService.firmwares$.subscribe(items => {
      this._gridData = items;
      this._loading = false;
    });
    this._subscriptions.push(firmwareSub);
    this._selectedProject = this._dropdownOptions[0].value;
  }

  ngOnDestroy() {
    this._subscriptions && this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  async onShow() {
    this._selectedProject = this._dropdownOptions[0].value;
    this._isHidden = false;
    this._loading = true;
    this._updaterDeviceDatasourceService.getBlobFirmwareFiles(this._selectedProject);
  }

  onSelectFirmware(file: ProjectFirmwareViewModel) {
    if (this._selectedFiles.includes(file)) {
      this._selectedFiles = this._selectedFiles.filter(x => x != file);
    }
    else {
      this._selectedFiles.push(file);
    }
  }

  async onProjectChange() {
    this._loading = true;
    this._gridData = [];
    this._updaterDeviceDatasourceService.getBlobFirmwareFiles(this._selectedProject);
  }

  onClearSelected() {
    this._gridData.forEach(item => {
      item['isSelected'] = false;
    });
    this._selectedFiles = [];
  }

  onCancel() {
    this._isHidden = true;
    this._selectedFiles = [];
    this._gridData = [];
    this._locationName = '';
    this._loading = false;
  }

  async onDownloadFirmwareFile(fileInfo: ProjectFirmwareViewModel) {
    fileInfo[this._isDownloadFile] = true;
    let result = await this._updaterDeviceDatasourceService.downloadFirmwareFile({ directoryName: this._selectedProject, fileName: fileInfo.name });
    if (result instanceof Blob) {
      const url = URL.createObjectURL(result as Blob);
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileInfo.name;
      downloadLink.click();
    }
    fileInfo[this._isDownloadFile] = false;
  }

  async onDownloadSelectedFileLogs() {
    if (this._selectedFiles.length > 0) {
      this._isDeleteDownloadArchive = true;
      let files = this._selectedFiles.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
      let fileList = files.map(x => x.name);
      this.onClearSelected();
      let result = await this._updaterDeviceDatasourceService.downloadFirmwareFiles({ directoryName: this._selectedProject, fileNames: fileList });
      if (result instanceof Blob) {
        let fileName: string = `${this._selectedProject}_${new Date(files[0].createdOn).getDate()}-${new Date(files[0].createdOn).getMonth() + 1}-${new Date(files[0].createdOn).getFullYear()}`;
        if (files.length > 1) {
          let lastIndex = files.length - 1;
          fileName = fileName + `_${new Date(files[lastIndex].createdOn).getDate()}-${new Date(files[lastIndex].createdOn).getMonth() + 1}-${new Date(files[lastIndex].createdOn).getFullYear()}`
        }
        fileName = fileName + '.zip';
        const url = URL.createObjectURL(result as Blob);
        let downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      this._isDeleteDownloadArchive = false;
    }
  }

  async onDeleteFirmwares() {
    if (this._selectedFiles.length > 0) {
      let fileList = 'Files: ' + this._selectedFiles.map(x => x.name).join(',') + '.';
      this.confirmationPopup.show('Do you really want to delete selected firmware files?', fileList);
    }
  }

  async deleteFirmware(confirmationResult: ConfirmationDialogEnum) {
    if (confirmationResult == ConfirmationDialogEnum.Confirmation) {
      if (this._selectedFiles.length > 0) {
        this._isDeleteDownloadArchive = true;
        let fileList = this._selectedFiles.map(x => x.name);

        const result = await this._updaterDeviceDatasourceService.deleteSelectedFirmwares({ directoryName: this._selectedProject, fileNames: fileList });
        if (result) {
          for (let i = this._gridData.length - 1; i >= 0; i--) {
            if (this._selectedFiles.includes(this._gridData[i])) {
              this._gridData.splice(i, 1);
            }
          }
          this.onClearSelected();
        }
        this._isDeleteDownloadArchive = false;
      }
    }
  }

  async onUpdateFirmware() {
    this.onClearSelected();
    this.uploadComponent.show(this._selectedProject, this._gridData);
  }
}
