import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'greensleeves-equipment-menu',
  templateUrl: './equipment-menu.component.html',
  styles: []
})
export class EquipmentMenuComponent {
  @Input()
  index: number = -1;

  @Output()
  onToggle: EventEmitter<{ index: number, isOpen: boolean }> = new EventEmitter<{ index: number, isOpen: boolean }>();
  
  _isMenuOpen: boolean = false;
  
  close() {
    this._isMenuOpen = false;
    this.notifyNext()
  }

  onToogleMenu() {
    this._isMenuOpen = !this._isMenuOpen;
    this.notifyNext();
  }

  onElementClick(e: Event) {
    e.stopPropagation();
  }

  private notifyNext() {
    this.onToggle.emit({ isOpen: this._isMenuOpen, index: this.index });
  }
}
