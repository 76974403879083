import { Component, EventEmitter, OnInit } from '@angular/core';

import { QuoteFlatStorageReportModel, QuoteRoundStorageReportModel, ReportComputationSheetModel } from '@models';
import { AqsReportDatasourceService } from '@services';
import { ErrorModel } from 'src/app/common';
import { AqsReportFormatFileLabel } from '../../../constants';
import { AqsSessionTypeEnum, ReportFormatEnum } from '../../../enums';


@Component({
  selector: 'greensleeves-computation-sheet-share-report-popup',
  templateUrl: './computation-sheet-share-report-popup.component.html',
  styles: []
})
export class ComputationSheetShareReportPopupComponent implements OnInit {
  public shareCompleted = new EventEmitter<boolean>();
  private reportComputationSheetModel: ReportComputationSheetModel;
  private quoteRoundStorageReportModel: QuoteRoundStorageReportModel;
  private quoteFlatStorageReportModel: QuoteFlatStorageReportModel;
  private quoteStorageIds: number[];
  private quoteNumbers: string[];

  _session: AqsSessionTypeEnum;
  _sessionTypeEnum = AqsSessionTypeEnum;

  _isHidden = true;
  _formatReport: { label: string, value: number }[] = [];
  _selectedFormat: ReportFormatEnum;
  _isReportRun = false;
  _date: Date;

  private subject: string;

  constructor(private _aqsReportService: AqsReportDatasourceService) { }

  ngOnInit() {
    this._date = new Date();
  }

  async onClickShare() {
    this._isReportRun = true;

    let result: any;

    switch (this._session) {
      case AqsSessionTypeEnum.ComputationSheetType:
        this.reportComputationSheetModel.reportFormat = this._selectedFormat;
        this.reportComputationSheetModel.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.reportComputationSheetModel.printDate = this._date;
        result = await this._aqsReportService.getComputationSheetReportInBlob(this.reportComputationSheetModel);
        break;
      case AqsSessionTypeEnum.RoundSessionType:
        this.quoteRoundStorageReportModel.reportFormat = this._selectedFormat;
        this.quoteRoundStorageReportModel.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.quoteRoundStorageReportModel.printDate = this._date;
        result = await this._aqsReportService.getQuoteRoundStorageReportInBlob(this.quoteRoundStorageReportModel);
        break;
      case AqsSessionTypeEnum.FlatSessionType:
        this.quoteFlatStorageReportModel.reportFormat = this._selectedFormat;
        this.quoteFlatStorageReportModel.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.quoteFlatStorageReportModel.printDate = this._date;
        result = await this._aqsReportService.getQuoteFlatStorageReportInBlob(this.quoteFlatStorageReportModel);
        break;
      default:
        let format = this._selectedFormat;
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        result = await this._aqsReportService.getListOfQuoteReportInBlob(
          this.quoteStorageIds,
          format,
          timeZone,
          this._date);
        break;
    }

    if (!(result instanceof ErrorModel)) {
      let bodyText = this.generateEmailText(result);
      let email = document.createElement('a');
      email.href = `mailto:?to=&subject=${this.getStringMailTo(this.subject)}&body=${this.getStringMailTo(bodyText)}`;
      email.click();
      this.onCancel();
      this.shareCompleted.emit(true);
    } else {
      this._isReportRun = false;
    }
  }

  showDownloadComputationSheetReport(reportComputationSheetModel: ReportComputationSheetModel) {
    this.initFormatLabels();
    this.reportComputationSheetModel = reportComputationSheetModel;
    this._session = AqsSessionTypeEnum.ComputationSheetType;
    this.subject = `${reportComputationSheetModel.customerCompany.name}, ${reportComputationSheetModel.customerCompany.companyName}. ${reportComputationSheetModel.customerStorageLocation.quoteNumber}.`
    this._isHidden = false;
  }

  public showDownloadQuoteRoundStorageReport(quoteStorage: QuoteRoundStorageReportModel) {
    this.initFormatLabels();
    this.quoteRoundStorageReportModel = quoteStorage;
    this._session = AqsSessionTypeEnum.RoundSessionType;
    this.subject = `${quoteStorage.coverSheet.customerName}, ${quoteStorage.coverSheet.companyName}. ${quoteStorage.coverSheet.quoteNumber}.`
    this._isHidden = false;
  }

  public showDownloadQuoteFlatStorageReport(quoteStorage: QuoteFlatStorageReportModel) {
    this.initFormatLabels();
    this.quoteFlatStorageReportModel = quoteStorage;
    this._session = AqsSessionTypeEnum.FlatSessionType;
    this.subject = `${quoteStorage.coverSheet.customerName}, ${quoteStorage.coverSheet.companyName}. ${quoteStorage.coverSheet.quoteNumber}.`
    this._isHidden = false;
  }

  public showDownloadListOfQuoteReport(quoteStorageIds: number[], quoteNumbers: string[]) {
    this.initFormatLabels();
    this.quoteStorageIds = quoteStorageIds;
    this.quoteNumbers = quoteNumbers;
    this._isHidden = false;
    this.subject = '';
  }

  onCancel() {
    this._isReportRun = false;
    this._isHidden = true;
    this.subject = '';
    this._date = new Date();
  }

  private generateEmailText(link: string): string {
    let quote: string = 'Quote ';

    switch (this._session) {
      case AqsSessionTypeEnum.ComputationSheetType:
        quote += `${this.reportComputationSheetModel.customerStorageLocation.quoteNumber} has`;
        break;
      case AqsSessionTypeEnum.RoundSessionType:
        quote += `${this.quoteRoundStorageReportModel.coverSheet.quoteNumber} has`;
        break;
      case AqsSessionTypeEnum.FlatSessionType:
        quote += `${this.quoteFlatStorageReportModel.coverSheet.quoteNumber} has`;
        break;
      default:
        let quoteNumbers = [... new Set(this.quoteNumbers)];
        if (quoteNumbers.length == 1) {
          quote += `${quoteNumbers[0]} has`;
        } else {
          quote = `Quotes ${quoteNumbers[0]}`;
          for (let i = 1; i < quoteNumbers.length; i++) {
            quote += `, ${quoteNumbers[i]}`;
          }

          quote += ' have'
        }
        break;
    }

    return `Dear Customer:\n${quote} been shared with you from Rolfes @ Boone.` +
      `\n\nPlease find the quote at:\n${link}` +
      `\n\nFor additional inquiries please contact us at info@rolfesatboone.com or call 1.800.265.2010.` +
      `\n\nThank you for your interest, Rolfes @ Boone.`
  }

  private getStringMailTo(text: string): string {
    text = text.replace(/%/g, "%25");
    text = text.replace(/\\u0026/g, "%26");
    text = text.replace(/\n/g, "%0D%0A");
    text = text.replace(/ /g, "%20");
    text = text.replace(/"/g, "%22");
    text = text.replace(/'/g, "%27");
    text = text.replace(/&/g, "%26");
    text = text.replace(/\t/g, "%09");

    return text;
  }

  private initFormatLabels() {
    this._formatReport = [];

    for (const [key, value] of Object.entries(AqsReportFormatFileLabel)) {

      let label = value as string;

      this._formatReport.push({ label: label, value: Number(key) })
    }

    this._selectedFormat = this._formatReport[0].value;
  }
}