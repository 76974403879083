import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-upload-firmware-rebase-popup',
  templateUrl: './upload-firmware-rebase-popup.component.html',
  styleUrls: []
})
export class UploadFirmwareRebasePopupComponent extends BasePopupBehavior implements OnInit {
  @Output() onUploadFile = new EventEmitter<boolean>();

  _isHidden = true;
  _submitted = false;
  _selectedProjectGroup: string;
  _projectName: string;

  constructor() {
    super();
  }

  onShow(selectedProjectGroup: string, projectName: string){
    this._isHidden = false;
    this._selectedProjectGroup = selectedProjectGroup;
    this._projectName = projectName;
  }

  ngOnInit() {
  }

  onSubmit(){
    this._submitted = true;
    this.onUploadFile.emit(true);
    this._submitted = false;
    this._selectedProjectGroup = null;
    this._isHidden = true;
  }

  onClickCancel(){
    this.onUploadFile.emit(false);
    this._isHidden = true;
    this._submitted = false;
    this._selectedProjectGroup = null;
  }
}
