import { CommonFunctions } from "../../../common";
import { BtxNotificationTypeLabel, ModelsTypes, equipmentSideLabels, sensorPositionLabels, sensorTypeLabels } from '../../constants';
import { BtxSensorEventTypeEnum, EquipmentSideEnum, SensorPositionEnum, SensorSideEnum, SensorTypeEnum, SensorValuesStatusEnum } from "../../enums";
import { NameViewModel } from '../common.model';
import { BtxLocationSensorConfiguration } from "../locations";

export class SensorViewModel {
    id: number;
    type: SensorTypeEnum;
    position: SensorPositionEnum;
    side: EquipmentSideEnum;
    modbusAddress: number;
    warningAddress: number;
    warningAddressBit: number;
    alarmAddress: number;
    alarmAddressBit: number;
    rateOfChangeWarningAddress: number;
    rateOfChangeWarningAddressBit: number;
    rateOfChangeAlarmAddress: number;
    rateOfChangeAlarmAddressBit: number;

    //Infrastructure fields
    modelType = ModelsTypes.sensorViewModel;

    public static toViewModel(sensor: BtxLocationSensorConfiguration) : SensorViewModel {
        const viewModel = new SensorViewModel;
        viewModel.id = sensor.index;
        viewModel.type = sensor.type;
        viewModel.position = sensor.position;
        viewModel.side = sensor.side;
        viewModel.modbusAddress = sensor.modbusAddress;
        viewModel.warningAddress = sensor.warningAddress;
        viewModel.warningAddressBit = sensor.warningAddressBit;
        viewModel.alarmAddress = sensor.alarmAddress;
        viewModel.alarmAddressBit = sensor.alarmAddressBit;
        viewModel.rateOfChangeWarningAddress = sensor.rateOfChangeWarningAddress;
        viewModel.rateOfChangeWarningAddressBit = sensor.rateOfChangeWarningAddressBit;
        viewModel.rateOfChangeAlarmAddress = sensor.rateOfChangeAlarmAddress;
        viewModel.rateOfChangeAlarmAddressBit = sensor.rateOfChangeAlarmAddressBit;

        return viewModel;
    }
    public static getViewModel(getModel: SensorGetModel): SensorViewModel {
        const viewModel = new SensorViewModel();
        viewModel.id = getModel.id == undefined ? 0 : getModel.id;
        viewModel.type = getModel.type;
        viewModel.position = getModel.position;
        viewModel.side = getModel.side;
        viewModel.modbusAddress = Number(getModel.modbusAddress);
        viewModel.warningAddress = Number(getModel.warningAddress);
        viewModel.warningAddressBit = Number(getModel.warningAddressBit);
        viewModel.alarmAddress = Number(getModel.alarmAddress);
        viewModel.alarmAddressBit = Number(getModel.alarmAddressBit);
        viewModel.rateOfChangeWarningAddress = isNaN(Number(getModel.rateOfChangeWarningAddress)) || getModel.rateOfChangeWarningAddress == null ? null : Number(getModel.rateOfChangeWarningAddress);
        viewModel.rateOfChangeWarningAddressBit = getModel.rateOfChangeWarningAddressBit == undefined ? null : getModel.rateOfChangeWarningAddressBit;
        viewModel.rateOfChangeAlarmAddress = isNaN(Number(getModel.rateOfChangeAlarmAddress)) || getModel.rateOfChangeAlarmAddress == null ? null : Number(getModel.rateOfChangeAlarmAddress);
        viewModel.rateOfChangeAlarmAddressBit = getModel.rateOfChangeAlarmAddressBit == undefined ? null : getModel.rateOfChangeAlarmAddressBit;
        return viewModel;
    }
}

export class SensorGetModel {
    id: number;
    type: SensorTypeEnum;
    position: SensorPositionEnum;
    side: EquipmentSideEnum;
    modbusAddress: number;
    warningAddress: number;
    warningAddressBit: number;
    alarmAddress: number;
    alarmAddressBit: number;
    rateOfChangeWarningAddress: number;
    rateOfChangeWarningAddressBit: number;
    rateOfChangeAlarmAddress: number;
    rateOfChangeAlarmAddressBit: number;

    public static toViewModel(getModel: SensorGetModel): SensorViewModel {
        return SensorViewModel.getViewModel(getModel);
    }
}

export class SensorPostModel {
    id: number;
    type: SensorTypeEnum;
    position: SensorPositionEnum;
    side: EquipmentSideEnum;
    modbusAddress: number;
    warningAddress: number;
    warningAddressBit: number;
    alarmAddress: number;
    alarmAddressBit: number;
    rateOfChangeWarningAddress: number;
    rateOfChangeWarningAddressBit: number;
    rateOfChangeAlarmAddress: number;
    rateOfChangeAlarmAddressBit: number;

    constructor(viewModel: SensorViewModel) {
        this.id = viewModel.id;
        this.type = viewModel.type;
        this.position = viewModel.position;
        this.side = viewModel.side;
        this.modbusAddress = Number(viewModel.modbusAddress);//field is string after input form
        this.warningAddress = Number(viewModel.warningAddress);//field is string after input form
        this.warningAddressBit = viewModel.warningAddressBit;
        this.alarmAddress = Number(viewModel.alarmAddress);//field is string after input form
        this.alarmAddressBit = viewModel.alarmAddressBit;
        this.rateOfChangeWarningAddress = viewModel.rateOfChangeWarningAddress == null ? null : Number(viewModel.rateOfChangeWarningAddress);
        this.rateOfChangeWarningAddressBit = viewModel.rateOfChangeWarningAddressBit == null ? null : viewModel.rateOfChangeWarningAddressBit;
        this.rateOfChangeAlarmAddress = viewModel.rateOfChangeAlarmAddress == null ? null : Number(viewModel.rateOfChangeAlarmAddress);
        this.rateOfChangeAlarmAddressBit = viewModel.rateOfChangeAlarmAddressBit == null ? null : viewModel.rateOfChangeAlarmAddressBit;
    }
}

export class BtxSensorWithTelemetryViewModel {
    id: number;
    index: number;
    type: NameViewModel;
    position: NameViewModel;
    side: NameViewModel;
    value: number;
    status: NameViewModel;
    eventType: NameViewModel;
    sensorMeasure: string;

    public static toSensorViewModel(sensorWithTelemetry: BtxSensorWithTelemetryViewModel): SensorViewModel {
        const sensorViewModel = new SensorViewModel();

        sensorViewModel.id = sensorWithTelemetry.id;
        sensorViewModel.type = sensorWithTelemetry.type.id;
        sensorViewModel.position = sensorWithTelemetry.position.id;
        sensorViewModel.side = sensorWithTelemetry.side.id;

        return sensorViewModel;
    }
}

export class BtxSensorWithTelemetryGetModel {
    id: number;
    type: SensorTypeEnum;
    position: SensorPositionEnum;
    side: EquipmentSideEnum;
    value: number;
    status: SensorValuesStatusEnum;
    eventType: BtxSensorEventTypeEnum;

    public static toViewModel(getModel: BtxSensorWithTelemetryGetModel): BtxSensorWithTelemetryViewModel {
        const viewModel = new BtxSensorWithTelemetryViewModel();
        viewModel.id = getModel.id;
        viewModel.type = BtxSensorWithTelemetryGetModel.getSensorTypeEnumViewModel(getModel.type);
        viewModel.position = BtxSensorWithTelemetryGetModel.getSensorPositionEnumViewModel(getModel.position);
        viewModel.side = BtxSensorWithTelemetryGetModel.getEquipmentSideEnumViewModel(getModel.side);
        viewModel.value = getModel.value;
        viewModel.status = BtxSensorWithTelemetryGetModel.getSensorValuesStatusEnumViewModel(getModel.status);
        viewModel.eventType = BtxSensorWithTelemetryGetModel.getBtxNotificationTypeEnumViewModel(getModel.eventType);
        viewModel.sensorMeasure = CommonFunctions.btxGetSensorMeasure(getModel.type);

        return viewModel;
    }

    private static getSensorTypeEnumViewModel(type: SensorTypeEnum): NameViewModel {
        const nameViewModel = new NameViewModel();

        nameViewModel.id = type;
        nameViewModel.name = sensorTypeLabels[type];

        return nameViewModel;
    }

    private static getSensorPositionEnumViewModel(type: SensorPositionEnum): NameViewModel {
        const nameViewModel = new NameViewModel();

        nameViewModel.id = type;
        nameViewModel.name = sensorPositionLabels[type];

        return nameViewModel;
    }

    private static getEquipmentSideEnumViewModel(type: EquipmentSideEnum): NameViewModel {
        const nameViewModel = new NameViewModel();

        nameViewModel.id = type;
        nameViewModel.name = equipmentSideLabels[type];

        return nameViewModel;
    }

    private static getSensorValuesStatusEnumViewModel(status: SensorValuesStatusEnum) {
        const nameViewModel = new NameViewModel();

        nameViewModel.id = status;

        switch (status) {
            case SensorValuesStatusEnum.Norm:
                nameViewModel.name = 'Normal';
                break;
            case SensorValuesStatusEnum.Alarm:
                nameViewModel.name = 'Alarm';
                break;
            case SensorValuesStatusEnum.Warning:
                nameViewModel.name = 'Warning';
                break;
             case SensorValuesStatusEnum.Error:
                nameViewModel.name = 'Error';
                break;
            default:
                nameViewModel.name = 'No Data';
                break;
        }

        return nameViewModel;
    }

    private static getBtxNotificationTypeEnumViewModel(type: BtxSensorEventTypeEnum): NameViewModel {
        if (!type) {
            return null;
        }

        const nameViewModel = new NameViewModel();

        nameViewModel.id = type;
        nameViewModel.name = BtxNotificationTypeLabel[type];

        return nameViewModel;
    }
}

export class SensorSchemaViewModel {
    className: string;
    index: number;
    tooltip: string;
}

export class SensorInfo {
    id: number;
    type: SensorTypeEnum;
    position: SensorPositionEnum;
    side: SensorSideEnum;
}