import { AqsSessionTypeEnum, AqsStorageTypeEnum, } from "../enums";

export const AqsSessionsTypeContants = {
  [AqsSessionTypeEnum.ComputationSheetType]: "navigation-icon-computation-sheet",
  [AqsSessionTypeEnum.FlatSessionType]: "navigation-icon-flat-storage",
  [AqsSessionTypeEnum.RoundSessionType]: "navigation-icon-round-storage",
}

export const AqsStorageTypesTreeNodeConstants = {
  [AqsSessionTypeEnum.RoundSessionType]: {
    label: 'Quote round storage',
    key: 'roundStorage',
    data: AqsSessionTypeEnum.RoundSessionType,
    expanded: true,
    children: [
      {label: 'Conical', key: 'conical', expanded: false, data: AqsStorageTypeEnum.Conical,},
      {label: 'Side draw', key: 'sideDraw', expanded: false, data: AqsStorageTypeEnum.SideDraw,},
  ]},
  [AqsSessionTypeEnum.FlatSessionType]: {
    label: 'Quote flat storage',
      key: 'flatStorage',
      data: AqsSessionTypeEnum.FlatSessionType,
      expanded: true,
      children: [
        {label: 'Flat', key: 'flat', expanded: false, data: AqsStorageTypeEnum.Flat,},
        {label: 'Peaked', key: 'peaked', expanded: false, data: AqsStorageTypeEnum.Peaked,},
        {label: '4 sloped peak', key: 'sloped', expanded: false, data: AqsStorageTypeEnum.SlopedPeak,},
        {label: 'Truncated peak', key: 'truncated', expanded: false, data: AqsStorageTypeEnum.TruncatedPeak,},
      ]
  },
  [AqsSessionTypeEnum.ComputationSheetType]: {
    label: 'Computation sheet',
      key: 'computation',
      data: AqsStorageTypeEnum.None,
      expanded: false,
  },
}
