import { Component, OnInit } from '@angular/core';

import { CompanyViewModel } from '../../../models';
import { CompaniesDataSourceService } from '../../../services';


@Component({
  selector: 'greensleeves-companies-delete-popup',
  templateUrl: './companies-delete-popup.component.html',
  styles: []
})

export class CompaniesDeletePopupComponent implements OnInit {
  _isHidden = true;
  _submitted: boolean;
  _company: CompanyViewModel;
  constructor(
    private _companiesDataSourceService: CompaniesDataSourceService,
    ) { }

  ngOnInit() {
  }
  
  public show(company: CompanyViewModel){
    this._submitted = false;
     this._isHidden = false;
     this._company = company;
  }

  async onSubmit() {
    try {
    const result = await this._companiesDataSourceService.delete(this._company.id);
    if (result != null){
      this._isHidden = true;
      this._company = null;
      this._submitted = true;
    }
  } catch (error){
    this._submitted = false;
    console.log('ERROR', error);
  }
  }

  onCancel(){
    
    this._isHidden = true;
    this._company = null;
  }
}
