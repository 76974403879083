import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService, ErrorModel } from '../../../common';
import { ApiRoutes } from '../../constants';
import {
  LocationBtxViewModel,
  LocationBtxPostModel,
  LocationBtxGetModel,
  LocationBtxEquipmentViewModel,
  LocationBtxEquipmentGetModel,
  LocationGetModel,
  CompanyViewModel,
  CompanyGetModel,
  BtxLiveDataLocationViewModel,
  BtxLiveDataLocationGetModel,
  BtxLiveDataEquipmentGetModel,
  BTXLocationConfiguration,
  BtxLocationEquipmentConfiguration,
  BtxLocationSensorConfiguration,
} from '../../models';
import { EquipmentSubTypeEnum, EquipmentTypeEnum } from '../../enums';

@Injectable()
export class LocationsBtxApiService {
  constructor(
    private _httpService: HttpService,
    private _router: Router,
  ) { }

  public async getAll() : Promise<LocationBtxViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }

      const result = await this._httpService.get(ApiRoutes.LocationBtx.getAll);
      return result.map((item) => {
        return LocationBtxGetModel.toViewModel(item);
      });
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getAllForCompany(companyId: number) : Promise<LocationBtxViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      
      const result = await this._httpService.get(ApiRoutes.Location.getAllForCompany, { companyId: companyId });
      return result.map((item) => {
        return LocationGetModel.toViewModel(item);
      });
    } catch (error) {
      return this.processError(error);
    }
  }

  public async add(location: LocationBtxPostModel) : Promise<LocationBtxViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.LocationBtx.add, location);

      const locationViewModel = LocationBtxGetModel.toViewModel(result);
      return locationViewModel;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async edit(location: LocationBtxPostModel) : Promise<LocationBtxViewModel | ErrorModel> {
    try {
      const result = await this._httpService.put(ApiRoutes.LocationBtx.edit, location);

      const locationViewModel = LocationBtxGetModel.toViewModel(result);
      return locationViewModel;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async initializeDeviceConfiguration(locationId: number) : Promise<boolean | ErrorModel> {
    try {
      let response = await this._httpService.post(ApiRoutes.LocationBtx.initializeDeviceConfiguration, locationId);

      return response;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadConnectionFile(locationId: number) : Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.getArrayBuffer(ApiRoutes.LocationBtx.downloadConnectionFile, { locationId });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async delete(locationId: number) : Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.delete(ApiRoutes.LocationBtx.delete, { locationId});
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  public async getEquipment(locationId: number): Promise<LocationBtxEquipmentViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        //return this.getFakeData(locationId);
      }

      const result = await this._httpService.get(ApiRoutes.Equipment.get, { locationId });

      return LocationBtxEquipmentGetModel.toViewModel(result);
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getCompaniesWithLocations(
    withEquipments: boolean
  ): Promise<CompanyViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeCompaniesWithLocations();
      }
      
      const result = await this._httpService.get(
        ApiRoutes.LocationBtx.getCompaniesWithLocations,
        { withEquipments }
      );

      return result.map((item) => CompanyGetModel.toViewModel(item));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }

      console.log(error);
      return null;
    }
  }

  public async getLiveDataLocations(): Promise<BtxLiveDataLocationViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeLiveDataLocations();
      }
      
      const result = await this._httpService.get(
        ApiRoutes.LocationBtx.getLiveDataLocations,
      );

      return result.map((item) => BtxLiveDataLocationGetModel.toViewModel(item));
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }

      console.log(error);
      return null;
    }
  }

  public async uploadToParseConfigurationFile(configFileData: FormData): Promise<BTXLocationConfiguration | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeBtxUploadedSensorConfigurationData();
      }

      const result = await this._httpService.postDataWithContent(ApiRoutes.LocationBtx.parseLocationConfiguration, configFileData);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async uploadBtxLocationEquipmentsConfigurationFile(locationConfiguration: BTXLocationConfiguration): Promise<void | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return;
      }

      const result = await this._httpService.post(ApiRoutes.LocationBtx.uploadBtxLocationConfiguration, locationConfiguration);
      if(result) {
        return result;
      }
      return null;
    } catch (error) {
      return this.processError(error);
    }
  }

  public getFakeData(): LocationBtxViewModel[] {
    const data = [
      { id: 1, btxIp: '192.168.0.1', btxPort: 8080, locationName: 'locationName1', companyName: 'company1', acquisitionRate: 150, isInitialized: true },
      { id: 2, btxIp: '192.168.0.2', btxPort: 8080, locationName: 'locationName2', companyName: 'company2', acquisitionRate: 120, isInitialized: true },
      { id: 3, btxIp: '192.168.0.3', btxPort: 8080, locationName: 'locationName3', companyName: 'company3', acquisitionRate: 60, isInitialized: true },
   ] as LocationBtxViewModel[];
    return data;
  }

  private getFakeCompaniesWithLocations(): CompanyViewModel[] {
    const data = [
      {
        id: 1,
        name: 'Company1',
        locations: [{ name: 'Location 1',equipments:[{id:1,name:'fan'},{id:2,name:'fan2'},{id:3,name:'fan3'}] }],
      },
      {
        id: 2,
        name: 'Company2',
        locations: [{ name: 'Location 2',equipments:[{id:4,name:'Leg'},{id:5,name:'Leg2'},{id:6,name:'Leg3'}] }, 
        { name: 'Location 3', equipments:[{id:7,name:'Leg7'},{id:8,name:'Leg8'},{id:9,name:'Leg9'}]  }],
      },
      {
        id: 3,
        name: 'Company3',
        locations: [
          { name: 'Location 4' },
          { name: 'Location 5' },
          { name: 'Location 6' },
        ],
      },
      {
        id: 4,
        name: 'Company4',
        locations: [
          { name: 'Location 7' },
          { name: 'Location 8' },
          { name: 'Location 9' },
        ],
      },
      { id: 5, name: 'Company5', locations: [{ name: 'Location 10' }] },
    ] as CompanyViewModel[];

    return data;
  }

  private getFakeLiveDataLocations(): BtxLiveDataLocationViewModel[] {
    const equipmentsGetModels = [
      {
        id: 1,
        type: EquipmentTypeEnum.Silo,
        subType: EquipmentSubTypeEnum.Small,
        name: 'Silo small (None)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 2,
        type: EquipmentTypeEnum.Silo,
        subType: EquipmentSubTypeEnum.Medium,
        name: 'Silo medium (None)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 3,
        type: EquipmentTypeEnum.Silo,
        subType: EquipmentSubTypeEnum.Large,
        name: 'Silo large (None)',
        alarms: 0,
        warnings: 0,
      },
      /* LEG */
      {
        id: 4,
        type: EquipmentTypeEnum.Leg,
        subType: EquipmentSubTypeEnum.WithKneeBearing,
        name: 'Leg with knee bearing (North-South)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 5,
        type: EquipmentTypeEnum.Leg,
        subType: EquipmentSubTypeEnum.WithoutKneeBearing,
        name: 'Leg without knee bearing (North-South)',
        alarms: 0,
        warnings: 0,
      },
      /* CONVEYOR */
      {
        id: 6,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Drag,
        name: 'Conveyor drag (North-South)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 7,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Belt,
        name: 'Conveyor belt (North-South)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 8,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Screw,
        name: 'Conveyor screw (None)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 9,
        type: EquipmentTypeEnum.Leg,
        subType: EquipmentSubTypeEnum.WithKneeBearing,
        name: 'Leg with knee bearing (East-West)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 10,
        type: EquipmentTypeEnum.Leg,
        subType: EquipmentSubTypeEnum.WithoutKneeBearing,
        name: 'Leg without knee bearing (East-West)',
        alarms: 0,
        warnings: 0,
      },
      /* CONVEYOR */
      {
        id: 11,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Drag,
        name: 'Conveyor drag (East-West)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 12,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Belt,
        name: 'Conveyor belt (East-West)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 13,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Screw,
        name: 'Conveyor screw (None)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 14,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.CoveredBelt,
        name: 'Conveyor covered belt (East-West)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 15,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.CoveredBelt,
        name: 'Conveyor covered belt (North-South)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 16,
        type: EquipmentTypeEnum.Tripper,
        subType: EquipmentSubTypeEnum.None,
        name: 'Tripper (North-South)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 17,
        type: EquipmentTypeEnum.Tripper,
        subType: EquipmentSubTypeEnum.None,
        name: 'Tripper (East-West)',
        alarms: 0,
        warnings: 0,
      },
      //FAN
      {
        id: 18,
        type: EquipmentTypeEnum.Fan,
        subType: EquipmentSubTypeEnum.DirectDrive,
        name: 'Fan Direct Drive (None)',
        alarms: 0,
        warnings: 0,
      },
      {
        id: 19,
        type: EquipmentTypeEnum.Fan,
        subType: EquipmentSubTypeEnum.BeltDrive,
        name: 'Fan Belt Drive (None)',
        alarms: 0,
        warnings: 1,
      },
    ] as BtxLiveDataEquipmentGetModel[];


    const equipmentViewModels = equipmentsGetModels.map(
      BtxLiveDataEquipmentGetModel.toViewModel
    );

    const segmentSize = equipmentViewModels.length / 4;

    const equipments1 = equipmentViewModels.slice(0, segmentSize);
    const equipments2 = equipmentViewModels.slice(segmentSize, 2 * segmentSize);
    const equipments3 = equipmentViewModels.slice(
      2 * segmentSize,
      3 * segmentSize
    );
    const equipments4 = equipmentViewModels.slice(3 * segmentSize);

    const locations: BtxLiveDataLocationViewModel[] = [
      { id: 1, name: 'Location1', equipments: equipments1 },
      { id: 2, name: 'Location2', equipments: equipments2 },
      { id: 3, name: 'Location3', equipments: equipments3 },
      { id: 4, name: 'Location4', equipments: equipments4 },
    ];

    return locations;
  }

  public getFakeBtxUploadedSensorConfigurationData(): BTXLocationConfiguration {
    const sensor1 = {
      index: 4,
      type: 1,
      position: 2,
      side: 1,
      modbusAddress: 65531,
      warningAddress: 113,
      warningAddressBit: 2,
      alarmAddress: 42,
      alarmAddressBit: 1,
      rateOfChangeWarningAddress: 33,
      rateOfChangeWarningAddressBit: 6,
      rateOfChangeAlarmAddress: 17,
      rateOfChangeAlarmAddressBit: 16,
    } as BtxLocationSensorConfiguration;

    const sensor2 = {
      index: 5,
      type: 3,
      position: 1,
      side: 1,
      modbusAddress: 65530,
      warningAddress: 112,
      warningAddressBit: 2,
      alarmAddress: 41,
      alarmAddressBit: 1,
      rateOfChangeWarningAddress: 33,
      rateOfChangeWarningAddressBit: 6,
      rateOfChangeAlarmAddress: 16,
      rateOfChangeAlarmAddressBit: 16,
    } as BtxLocationSensorConfiguration;

    const sensor3 = {
      index: 6,
      type: 2,
      position: 1,
      side: 2,
      modbusAddress: 65532,
      warningAddress: 114,
      warningAddressBit: 2,
      alarmAddress: 44,
      alarmAddressBit: 1,
      rateOfChangeWarningAddress: 35,
      rateOfChangeWarningAddressBit: 6,
      rateOfChangeAlarmAddress: 19,
      rateOfChangeAlarmAddressBit: 16,
    } as BtxLocationSensorConfiguration;

    const equipment1 = {
      index: 1,
      type: 1,
      subType: 1,
      name: 'Leg with knee bearing (North-South)',
      totalRunHoursFirstRegisterAddress: 1,
      totalRunHoursSecondRegisterAddress: 65536,
      timeTillMaintenanceAddress: 2,
      shutDownAlarmAddress: 3,
      shutDownAlarmAddressBit: 1,
      maintenanceRequiredWarningAddress: 3,
      maintenanceRequiredWarningAddressBit: 2,
      maintenanceCountDown: 11,
      totalRunHours: 1111,
      sensors: [sensor1, sensor2],
    } as { } as BtxLocationEquipmentConfiguration;

    const equipment2 = {
      index: 2,
      type: 2,
      subType: 1,
      name: 'Leg with knee bearing (North-South)2',
      totalRunHoursFirstRegisterAddress: 1,
      totalRunHoursSecondRegisterAddress: 65536,
      timeTillMaintenanceAddress: 2,
      shutDownAlarmAddress: 3,
      shutDownAlarmAddressBit: 1,
      maintenanceRequiredWarningAddress: 3,
      maintenanceRequiredWarningAddressBit: 2,
      maintenanceCountDown: 11,
      totalRunHours: 1111,
      sensors: [sensor3],
    } as { } as BtxLocationEquipmentConfiguration;

    const data = {
      locationId: 1,
      deviceId: '123123',
      equipments: [equipment1, equipment2],
    } as BTXLocationConfiguration;

    return data;
  }
}