import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RoutePaths } from '../common';
import { PrivacyComponent } from './privacy.component';

@NgModule({
  declarations: [
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      { path: RoutePaths.PRIVACY, component: PrivacyComponent },
    ])]
})

export class PrivacyModule { }