import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, NgZone, EventEmitter, ViewChild } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { LocationKtxViewModel } from './../../../models/';
import { ColumnTypeEnum, ProjectEnum } from '../../../enums';
import { LocationsKtxDataSourceService } from '../../../services';
import { DeviceCommunicationPopupComponent } from '../../popups';

@Component({
  selector: 'greensleeves-ktx-grid',
  templateUrl: './ktx-grid.component.html'
})

export class KtxGridComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DeviceCommunicationPopupComponent, { read: false, static: false })
  private deviceCommunicationPopup: DeviceCommunicationPopupComponent;

  static LOCATION_NAME_KEY = 'locationName';
  static COMPANY_NAME_KEY = 'companyName';
  static SORT_ORDER_DEFAULT = 1;

  public onEditClicked = new EventEmitter<LocationKtxViewModel>();
  public onBinClicked = new EventEmitter<LocationKtxViewModel>();
  public onUploadClicked = new EventEmitter<LocationKtxViewModel>();
  public onTemperatureRangesClicked = new EventEmitter<LocationKtxViewModel>();
  public onDeleteClicked = new EventEmitter<LocationKtxViewModel>();
  public onConnectionEditClicked = new EventEmitter<LocationKtxViewModel>();

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = KtxGridComponent.LOCATION_NAME_KEY;
  _sortOrder = KtxGridComponent.SORT_ORDER_DEFAULT;
  _gridData: LocationKtxViewModel[];
  _companyNameKey = KtxGridComponent.COMPANY_NAME_KEY;
  _locationNameKey = KtxGridComponent.LOCATION_NAME_KEY;
  _searchPhrase: string = '';
  _ps: PerfectScrollbar;
  _initializeDeviceKey = 'isInitialized';
  _canBeInitializedKey = 'canDeviceBeInitialized';

  _columnsView = [
    { header: 'Location name', columnType: ColumnTypeEnum.LocationName, dataField: KtxGridComponent.LOCATION_NAME_KEY },
    { header: 'Company', columnType: ColumnTypeEnum.Company, dataField: KtxGridComponent.COMPANY_NAME_KEY },
    { header: 'Bins', columnType: ColumnTypeEnum.Bins, dataField: 'countOfBins' },
  ]

  _frozenColumns = [
    { header: '', columnType: ColumnTypeEnum.InitializeDevice, width: 46 },
    { header: '', columnType: ColumnTypeEnum.DownloadConnectionFile, width: 46 },
    { header: '', columnType: ColumnTypeEnum.UploadConfigFile, width: 46 },
    { header: '', columnType: ColumnTypeEnum.TemperatureRanges, width: 46 },
    { header: '', columnType: ColumnTypeEnum.IPAddress, width: 46 },
    { header: '', columnType: ColumnTypeEnum.EditKTX, width: 46 },
    { header: '', columnType: ColumnTypeEnum.Delete, width: 46 }
  ]

  _searchForm: FormGroup;
  _lastSearchPhrase: string;

  _initLoaders: { [key: number]: boolean } = {};
  subscriptions: Subscription[] = [];
  _loading = false;

  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  constructor(
    private _locationsKtxDataSourceService: LocationsKtxDataSourceService,
    private _el: ElementRef,
    private _zone: NgZone,
    formBuilder: FormBuilder) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }

  ngOnInit() {
    this.reinitScrollBar(0);
    let locationSub = this._locationsKtxDataSourceService.locationsKtx$.subscribe(items => {
      this._gridData = items;
      this._loading = false;
    });
    this._loading = true;
    this._locationsKtxDataSourceService.get();
    this.subscriptions.push(locationSub);
  }

  ngOnDestroy() {
    this.subscriptions &&
      this.subscriptions.forEach((sub) => sub.unsubscribe());

    this._ps && this._ps.destroy();
    this._ps = null;
  }

  ngAfterViewInit() {
    this.reinitScrollBar(0);
  }

  async onEdit(location: LocationKtxViewModel) {
    await this._locationsKtxDataSourceService.markLocationKtxForOthersAsInUpdate(location.id);
    this.onEditClicked.emit(location);
  }

  async onClickInitialized(location: LocationKtxViewModel) {
    if (!location) {
      return;
    }

    location.isDeviceInInitialization = true;
    location.isInitialized = await this._locationsKtxDataSourceService.initializeDeviceConfiguration(location.id) as boolean;
    location.isDeviceInInitialization = false;
  }

  async onClickDownloadFile(location: LocationKtxViewModel) {
    if (!location) {
      return;
    }
    this.deviceCommunicationPopup.onShow({ locationName: location.locationName, locationId: location.id, deviceId: location.deviceId, project: ProjectEnum.Ktx });
  }

  onClickBins(location?: LocationKtxViewModel) {
    this.onBinClicked.emit(location);
  }

  onClickTemperatureRanges(location: LocationKtxViewModel) {
    this.onTemperatureRangesClicked.emit(location);
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
  }

  async onClickDelete(location: LocationKtxViewModel) {
    this.onDeleteClicked.emit(location);
  }

  async onClickUploadConfigFile(location: LocationKtxViewModel) {
    this.onUploadClicked.emit(location);
  }

  async onClickEditConnections(location: LocationKtxViewModel) {
    if (location.countOfBins > 0) {
      await this._locationsKtxDataSourceService.markLocationKtxForOthersAsInUpdate(location.id);
      this.onConnectionEditClicked.emit(location);
    }
  }

  private reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }
}
