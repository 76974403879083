import { Component, OnInit, Input } from '@angular/core';

import { TemperatureColorViewModel } from '@models';

@Component({
  selector: 'greensleeves-ktx-temperature-ranges',
  templateUrl: './ktx-temperature-ranges.component.html',
})
export class KtxTemperatureRangesComponent implements OnInit {
  @Input()
  temperatureRanges: TemperatureColorViewModel[];
  constructor() {}

  ngOnInit() {}
}
