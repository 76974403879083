import { CalendarDefaultValues } from '../constants';
import { ItxEventTypeEnum } from '../enums';

export class ItxEventViewModel {
    public eventId: number;
    public title: string;
    public body: string;
    public firstNotificationTimeStamp: number;
    public lastNotificationTimeStamp: number;
    public itxAlertType: ItxEventTypeEnum;
    public itxBinId: number;
    public locationId: number;
}

export class ItxEventGetModel {
    public eventId: number;
    public title: string;
    public body: string;
    public locationId: number;
    public firstNotificationTimeStamp: number;
    public lastNotificationTimeStamp: number;
    public itxOptions: ItxOptions;

    public static toViewModel(getModel: ItxEventGetModel): ItxEventViewModel {
        const viewModel = new ItxEventViewModel();
        viewModel.eventId = getModel.eventId;
        viewModel.title = getModel.title;
        viewModel.body = getModel.body;
        viewModel.firstNotificationTimeStamp = getModel.firstNotificationTimeStamp * CalendarDefaultValues.defaultOneSecondMilliseconds;
        viewModel.lastNotificationTimeStamp = getModel.lastNotificationTimeStamp * CalendarDefaultValues.defaultOneSecondMilliseconds;
        viewModel.locationId = getModel.itxOptions.locationId;
        viewModel.locationId = getModel.locationId;
        if (getModel.itxOptions) {
            viewModel.itxAlertType = getModel.itxOptions.itxAlertType;
            viewModel.itxBinId = getModel.itxOptions.itxBinId;
        }
        return viewModel;
    }
}

export class ItxOptions {
    public itxAlertType: ItxEventTypeEnum;
    public itxBinId: number;
    public locationId: number;
}

export class ItxEventsRequestModel {
    public events: Array<ItxEventGetModel>;
    public totalCount: number;
    public itemsPerPage: number;
}

export class ItxEventByPeriodPostModel {
    public locationIds: Array<number>;
    public itxBinIds: Array<number>;
    public dateTimeFrom: number;
    public dateTimeTo: number;
    public currentPage: number;
    public eventTypes: Array<ItxEventTypeEnum>;
    public isWeb: boolean=true;

    public get countSelectedFilter() {
        let count = 0;
        count += this.eventTypes.length;
        count += this.locationIds.length;
        count += this.itxBinIds.length;
        return count;
    }

    constructor(public eventsPerPage: number) {
        this.locationIds = [];
        this.itxBinIds = [];
        this.eventTypes = [];
        this.currentPage = 1;
    }
}