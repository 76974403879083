import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import { SalesUserAddedHubMessage, SalesUserDeletedHubMessage, SalesUserInUpdateHubMessage, SalesUserPostModel, SalesUserUpdatedHubMessage, SalesUserViewModel, ServiceUserInviteModel } from '../../models';
import { HubService, SalesUsersApiService } from '../http';
import { HubMethods } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class SalesUsersDatasourceService {
  public salesUsers$: BehaviorSubject<SalesUserViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(private _salesUsersApi: SalesUsersApiService,
    private _hubService: HubService) {
    this.subscribeToHubService();
  }

  public async getAll() {
    try {
      const salesUsers = await this._salesUsersApi.loadAll();
      if (salesUsers) {
        this.salesUsers$.next(salesUsers as SalesUserViewModel[]);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async edit(salesUser: SalesUserPostModel): Promise<boolean | ErrorModel> {
    try {
      const result = await this._salesUsersApi.edit(salesUser) as SalesUserViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.SalesUser.salesUserUpdated, new SalesUserUpdatedHubMessage(result, true));
        const salesUsers = this.salesUsers$.getValue().map(l => l.id === result.id ? { ...l, ...result } : l);
        this.salesUsers$.next(salesUsers);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async invite(salesUser: ServiceUserInviteModel): Promise<SalesUserViewModel | ErrorModel> {
    try {
      const result = await this._salesUsersApi.invite(salesUser) as SalesUserViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.SalesUser.salesUserAdded, new SalesUserAddedHubMessage(result));
        this.salesUsers$.next([
          ...this.salesUsers$.getValue(),
          result
        ]);
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async delete(salesUserId: number) : Promise<boolean|ErrorModel> {
    try
    {
      const result = await this._salesUsersApi.delete(salesUserId);
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.SalesUser.salesUserDeleted, new SalesUserDeletedHubMessage(salesUserId));
        const serviceUsers = this.salesUsers$.getValue().filter(l => l.id !== result);
        this.salesUsers$.next(serviceUsers);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async markServiceUserForOthersAsInUpdate(userId: number): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.SalesUser.salesUserInUpdate, new SalesUserInUpdateHubMessage(userId));
  }

  public async markServiceUserForOthersAsUpdated(salesUser: SalesUserViewModel, withChanges: boolean): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.SalesUser.salesUserUpdated, new SalesUserUpdatedHubMessage(salesUser, withChanges));
  }


  private subscribeToHubService() {
    this._hubService.salesUserInUpdate.subscribe((hubMessage: SalesUserInUpdateHubMessage) => {
      if (hubMessage) {
        const salesUser = this.salesUsers$.getValue().find(l => l.id === hubMessage.salesUserId);
        if (salesUser) {
          salesUser.isSomeoneUpdateSalesUser = true;
        }
      }
    });

    this._hubService.salesUserUpdated.subscribe((hubMessage: SalesUserUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedSalesUser) {
        if (hubMessage.withChanges) {
          hubMessage.updatedSalesUser.isSomeoneUpdateSalesUser = undefined;
          this.salesUsers$.next(this.salesUsers$.getValue().map(l => l.id === hubMessage.updatedSalesUser.id ? hubMessage.updatedSalesUser : l));
        } else {
          const salesUser = this.salesUsers$.getValue().find(l => l.id == hubMessage.updatedSalesUser.id);
          salesUser.isSomeoneUpdateSalesUser = false;
        }
      }
    });

    this._hubService.salesUserAdded.subscribe((hubMessage: SalesUserAddedHubMessage) => {
      if (hubMessage && hubMessage.newSalesUser) {
        this.salesUsers$.next([
          ...this.salesUsers$.getValue(),
          hubMessage.newSalesUser
        ]);
      }
    });

    this._hubService.salesUserDeleted.subscribe((hubMessage: SalesUserDeletedHubMessage) => {
      if (hubMessage) {
        const users = this.salesUsers$.getValue().filter(l => l.id !== hubMessage.salesUserId);
        this.salesUsers$.next(users);
      }
    });
  }
}
