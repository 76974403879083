import { Component, OnInit } from '@angular/core';
import { ErrorModel } from 'src/app/common';
import { LocationBtxViewModel, LocationItxViewModel, LocationKtxViewModel, LocationUpdaterViewModel, LocationViewModel, UpdateDeviceViewModel } from '../../../models';
import { LocationsBtxDataSourceService, LocationsKtxDataSourceService } from '../../../services';
import { LocationsItxDatasourceService, UpdaterDeviceDatasourceService } from 'src/app/main/services/data';

@Component({
  selector: 'greensleeves-location-btx-ktx-delete-popup',
  templateUrl: './location-btx-ktx-delete-popup.component.html',
  styles: []
})
export class LocationBtxKtxDeletePopupComponent implements OnInit {
  _isHidden = true;
  _isKtxToDelete: boolean;
  _isItxToDelete: boolean;
  _submitted = false;

  _location: LocationBtxViewModel;
  _locationKtx: LocationKtxViewModel;
  _locationItx: LocationItxViewModel;
  _error: string;

  constructor(
    private _locationBtxDatasourceService: LocationsBtxDataSourceService,
    private _locationKtxDatasourceService: LocationsKtxDataSourceService,
    private _locationItxDatasourceService: LocationsItxDatasourceService,
  ) { }

  ngOnInit() {
  }

  public show(location: LocationBtxViewModel | LocationKtxViewModel | LocationItxViewModel)  {
    if (location instanceof LocationBtxViewModel) {
      this._isKtxToDelete = false;
      this._location = location;
    } else if (location instanceof LocationKtxViewModel) {
      this._isKtxToDelete = true;
      this._locationKtx = location as LocationKtxViewModel;
    }else if (location instanceof LocationItxViewModel) {
      this._isItxToDelete = true;
      this._locationItx = location as LocationItxViewModel;
    }

    this._isHidden = false;
  }
  
  async onSubmit() {
    if ((this._location || this._locationKtx || this._locationItx) && !this._submitted) {
      this._submitted = true;

      let result: boolean | ErrorModel;
      if(this._location) {
        result = await this._locationBtxDatasourceService.delete(this._location.id);
      } else if(this._locationKtx) {
        result = await this._locationKtxDatasourceService.delete(this._locationKtx.id);
      }else if(this._locationItx) {
        result = await this._locationItxDatasourceService.delete(this._locationItx.id);
      }

      this._submitted = false;
      if (result === true) {
        this.onClickCancel();
      } else if (result === false) {
        this._error = 'Unknown error occured';
      } else {
        const errorModel = result as ErrorModel;
        if (errorModel) {
          this._error = errorModel.message;
        }
      }
    }
  }

  onClickCancel() {
    this._location = null;
    this._locationKtx = null;
    this._locationItx = null;
    this._error = null;
    this._isHidden = true;
  }
}
