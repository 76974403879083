import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { LocationsDataSourceService, LocationsItxDatasourceService } from '@services';
import { LocationItxViewModel } from '@models';
import { BasePopupBehavior } from '../common';
import { digitsValidator, ErrorCodeEnum, ErrorModel } from '../../../../common';
import { TemplateFunctions } from '../../common';

@Component({
  selector: 'greensleeves-itx-add-edit-popup',
  templateUrl: './itx-add-edit-popup.component.html',
  styles: []
})
export class ItxAddEditPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy {
  _isAddPopup = true;
  _submitted = false;
  _isHidden = true;
  _isUniqueDeviceError = false;
  _locationForm: FormGroup;
  _ipAddress: string = '';
  _acquisitionRate: number;
  _port: number;
  _location: LocationItxViewModel = new LocationItxViewModel();
  _locations: { label: string, value: number }[];

  private subscription: Subscription;


  get locationForm(): FormGroup {

    return this._locationForm;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _locationsService: LocationsDataSourceService,
    private _locationsItxService: LocationsItxDatasourceService,
  ) {
    super();
  }

  ngOnInit() {
    this._location = new LocationItxViewModel();
    this.subscription = this._locationsService.locations$.subscribe((data) => {
      if (data) {
        this._locations = data.map((x) => ({ label: x.name, value: x.id }));
      }
    });
  }

  public showAdd() {
    this._submitted = false;
    this._isAddPopup = true;
    this._isHidden = false;
    this.initForm();
  }

  public showEdit(location: LocationItxViewModel) {
    this._submitted = false;
    this._isAddPopup = false;
    this._isHidden = false;
    this._location = Object.create(location);
    this._acquisitionRate = location.acquisitionRate;
    this._ipAddress = location.ipAddress;
    this._port = location.port;
    this.initForm();
  }

  async onClose() {
    this._isHidden = true;
    this._isUniqueDeviceError = false;
    this._ipAddress = '';
    this._acquisitionRate = null;
    this._port = null;
    if (!this._isAddPopup) {
      await this._locationsItxService.markLocationItxForOthersAsUpdated(this._location, false);
    }
    this._location = new LocationItxViewModel();
    this._locationForm.reset();
  }


  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  async onSubmit() {
    this._submitted = true;

    if (this._locationForm.invalid) {
      return;
    }

    const value = {
      ...this._location,
      ...this._locationForm.value,
    } as LocationItxViewModel;

    const result = !!this._isAddPopup
      ? await this._locationsItxService.add(value)
      : await this._locationsItxService.edit(value);

    if (result instanceof ErrorModel) {
      if (result.code === ErrorCodeEnum.ItxDeviceAlreadyAssigned) {
        this._isUniqueDeviceError = true;
      }
    } else if (result) {
      if (!this._isAddPopup) {
        await this._locationsItxService.markLocationItxForOthersAsUpdated(result, true);
      }
      this._isHidden = true;
      this._submitted = false;
      this._isUniqueDeviceError = false;
      this._locationForm.reset();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initForm() {
    this._locationForm = this._formBuilder.group({
      locationId: [this._location.id, Validators.compose([
        Validators.required,
      ])],
      ipAddress: [this._location.ipAddress, Validators.compose([
        Validators.required,
        TemplateFunctions.ipValidator,
      ])
      ],
      acquisitionRate: [this._location.acquisitionRate, Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(60),
        digitsValidator
      ])
      ],
      port: [this._location.port, Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(65535),
        digitsValidator
      ])],
    });
  }
}
