import { CapacityUnitEnum, SensorTypeEnum, SizeMeasureEnum } from "../../main/enums";

export class ConvertDataFunctions {
  // To hhhh:mm:ss or hh:mm:ss
  public static secondsToString(totalSeconds: number, is4HoursFormat?: boolean): string {
    if (totalSeconds === 0) {
      return '';
    }

    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return [hours, minutes, seconds]
      .map((v, i) => {
        if (i === 0 && is4HoursFormat) {
          switch (true) {
            case (v < 10):
              return '000' + v;
            case (v < 100):
              return '00' + v;
            case (v < 1000):
              return '0' + v;
            default:
              return v;
          }
        }
        return v < 10 ? '0' + v : v
      })
      .join(":")
  }

  public static stringToSeconds(value: string, args?: any): number {
    const arr = value.split(":");
    let result = 0;
    for (let index = 0; index < arr.length; index++) {
      if (arr[index] === "00") {
        continue;
      }
      switch (index) {
        case 0:
          result += +arr[index] * 60 * 60;
          break;
        case 1:
          result += +arr[index] * 60;
          break;
        case 2:
          result += +arr[index];
      }
    }
    return result;
  }

  public static celsiusToFahrenheit(celsius: number): number {
    return Math.round((celsius * (9 / 5)) + 32)
  }

  public static fahrenheitToCelsius(fahrenheit: number, withRounding?: boolean): number {
    const result = withRounding ? Math.round((fahrenheit - 32) * (5 / 9)) : (fahrenheit - 32) * (5 / 9);
    return result;
  }

  public static deltaCelsiusToDeltaFahrenheit(celciusDelta: number): number {
    const result = Math.round(celciusDelta * 9 / 5);
    return result;
  }

  public static deltaFahrenheitToDeltaCelsius(fahrenheitDelta: number): number {
    const result = fahrenheitDelta * 5 / 9;
    return result
  }

  public static convertSizeFromFt(value: number, convertTo: SizeMeasureEnum): number {
    var result: number;
    switch (convertTo) {
      case SizeMeasureEnum.M: {
        result = value * 0.3048;
        break;
      }
      case SizeMeasureEnum.In: {
        result = value * 12;
        break;
      }
      default:
        result = value;
    }
    return result;
  }

  public static convertSizeToFt(value: number, sizeUnit: SizeMeasureEnum): number {
    let result: number;
    switch (sizeUnit) {
      case SizeMeasureEnum.In:
        result = value / 12;
        break;
      case SizeMeasureEnum.M:
        result = value / 0.3048;
        break;
      default:
        result = value;
    }
    return result;
  }

  public static convertFromGal(value: number, capacityUnit: CapacityUnitEnum): number {
    let result: number;
    switch (capacityUnit) {
      case CapacityUnitEnum.Bbl:
        result = value / 42;
        break;
      case CapacityUnitEnum.HL:
        result = value / 26.417205236;
        break;
      case CapacityUnitEnum.KL:
        result = value / 264.17205236;
        break;
      case CapacityUnitEnum.L:
        result = value / 0.264172;
        break;
      default:
        result = value;
    }
    return result;
  }

  public static convertToGal(value: number, capacityUnit: CapacityUnitEnum): number {
    let result: number;
    switch (capacityUnit) {
      case CapacityUnitEnum.Bbl:
        result = value * 42;
        break;
      case CapacityUnitEnum.HL:
        result = value * 26.417205236;
        break;
      case CapacityUnitEnum.KL:
        result = value * 264.17205236;
        break;
      case CapacityUnitEnum.L:
        result = value * 0.264172;
        break;
      default:
        result = value;
    }
    return result;
  }

  public static convertFromFt3(value: number, capacityUnit: CapacityUnitEnum): number {
    let result: number;
    switch (capacityUnit) {
      case CapacityUnitEnum.M_3:
        result = value / 35.3147;
        break;
      case CapacityUnitEnum.Bsh:
        result = value * 0.80356395494834;
        break;
      default:
        result = value;
    }
    return result;
  }
  public static convertToFt3(value: number, capacityUnit: CapacityUnitEnum): number {
    let result: number;
    switch (capacityUnit) {
      case CapacityUnitEnum.M_3:
        result = value * 35.3147;
        break;
      case CapacityUnitEnum.Bsh:
        result = value / 0.80356395494834;
        break;
      default:
        result = value;
    }
    return result;
  }

  public static roundAndUsString(num: number, digits: number): string {
    switch (digits) {
      case 1:
        return (Math.round(num * 10) / 10).toLocaleString('en-Us');
      case 2:
        return (Math.round(num * 100) / 100).toLocaleString('en-Us');
      default:
        return (Math.round(num * 1000) / 1000).toLocaleString('en-Us');
    }
  }

  public static aqsMakeStringFromFtToFtIn(valueInFt): string {
    let ft = Math.floor(valueInFt / 12);
    let inch = Math.round(valueInFt % 12);
    if (inch == 12) {
      ft++;
      inch = 0;
    }
    return `${ft}-${inch}`;
    // STR$(INT(AA/12))+"-"+RIGHT$(STR$(CINT(AA-(INT(AA/12)*12)))
  }

  public static secondsToHHMMString(totalSeconds: number, is4HoursFormat?: boolean): string {
    if (totalSeconds === 0) {
      return '00:00';
    }
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    return [hours, minutes]
    .map((v, i) => {
      return v < 10 ? '0' + v : v
    })
    .join(":")
  }
}
