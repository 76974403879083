import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ErrorCodeEnum, ErrorModel } from '../../../../common';
import { emailValidator } from '../../../../common/validators';
import { SalesUserViewModel, ServiceUserInviteModel, ServiceUserViewModel } from '../../../models';
import { ServiceUsersDataSourceService, SalesUsersDatasourceService } from '../../../services';

@Component({
  selector: 'greensleeves-service-user-add-popup',
  templateUrl: './service-user-add-popup.component.html',
  styles: []
})
export class ServiceUserAddPopupComponent implements OnInit {
  _isHidden = true;
  _submitted = false;
  _isSubmit = false;
  _invalidEmail = false;
  _serviceAndSalesUserForm: FormGroup;
  _isServiceUser: boolean;
  _locations: { label: string, value: number }[];

  constructor(
    private _formBuilder: FormBuilder,
    private _serviceUsersService: ServiceUsersDataSourceService,
    private _salesUserSerivece: SalesUsersDatasourceService,
  ) {
  }

  ngOnInit() {

    this._serviceAndSalesUserForm = this._formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          emailValidator(),
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ]
    });

    this._serviceAndSalesUserForm.valueChanges.subscribe(() => this._invalidEmail = false);
  }

  public show() {
    this.resetValuesBeforeShow();
    this._isServiceUser = true;
  }

  public showForSalesUser() {
    this.resetValuesBeforeShow();
    this._isServiceUser = false;
  }

  async onSubmit() {
    this._isSubmit = true;
    this._submitted = true;
    if (this._serviceAndSalesUserForm.invalid && !this._invalidEmail) {
      this._isSubmit = false;
      return;
    }

    const value = this._serviceAndSalesUserForm.value as ServiceUserInviteModel;
    if (this._isServiceUser) {
      this._serviceUsersService.invite(value)
        .then((response: ServiceUserViewModel) => {
          if (response instanceof ErrorModel && response.code === ErrorCodeEnum.UserAlreadyExist) {
            this._isSubmit = false;
            this._invalidEmail = true;
          } else if (response) {
            this.resetForm();
          }
        })
    }else{
      this._salesUserSerivece.invite(value)
        .then((response: SalesUserViewModel) => {
          if (response instanceof ErrorModel && response.code === ErrorCodeEnum.UserAlreadyExist) {
            this._isSubmit = false;
            this._invalidEmail = true;
          } else if (response) {
            this.resetForm();
          }
        })
    }
  }

  private resetValuesBeforeShow() {
    this._submitted = false;
    this._serviceAndSalesUserForm.reset();
    this._isHidden = false;
    this._isSubmit = false;
  }

  private resetForm() {
    this._submitted = false;
    this._isHidden = true;
    this._serviceAndSalesUserForm.reset();
    this._serviceAndSalesUserForm.markAsUntouched();
    Object.keys(this._serviceAndSalesUserForm.controls).forEach((name) => {
      const control = this._serviceAndSalesUserForm.controls[name];
      control.setErrors(null);
    });
  }
}
