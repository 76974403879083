import { ColumnTypeEnum } from './../../../enums';
import { AqsFlushFloorDesignVelocityTableHeaderLabel } from './../../../constants';
export class CanvasFlushFloorSupportFunction {

    public static FontSize: number = 23;

    public static supportHeightOptions: { label: string, value: number }[] = [
        { label: '7.25', value: 7.25 },
        { label: '11.25', value: 11.25 },
    ];

    public static velocitiesFor2FanTableColumns = [
        { header: AqsFlushFloorDesignVelocityTableHeaderLabel.valueLabel, columnType: ColumnTypeEnum.Number, dataField: 'value', },
        { header: AqsFlushFloorDesignVelocityTableHeaderLabel.crossSectionLabel, columnType: ColumnTypeEnum.Csv, dataField: 'crossSectional' },
        { header: AqsFlushFloorDesignVelocityTableHeaderLabel.surfaceVelocityLabel, columnType: ColumnTypeEnum.Sv, dataField: 'surfaceVelocity' },
    ];

    public static aqsCalcDimensionBeforeTunnel(r: number, tunelSize: number, distanceFromCenter: number): number {
        if (tunelSize >= r * 2) {
            return 0;
        }
        let chord = Math.sqrt(Math.pow(r, 2) - Math.pow(distanceFromCenter, 2)) * 2;
        let x = (chord - tunelSize) / 2;
        if (x < 0) x = 0;
        return x;
    }

    public static aqsCalcTunnelSupport(tunnerWidth: number, tunnerLenght: number, maxSpan: number, minSpan: number): { totalTunnelSupport: number, span: number }[] {
        let tunnelSupports: { totalTunnelSupport: number, span: number }[] = [];
        let tunnelSupport: number = 1;
        let span: number = 0;
        let acturalDiameter = tunnerWidth - 6;
        do {
            span = (acturalDiameter - tunnelSupport * 1.5) / (tunnelSupport + 1);
            if (span >= minSpan && span <= maxSpan) {
                tunnelSupports.push({ totalTunnelSupport: Math.ceil(tunnerLenght / 10 * tunnelSupport), span: Math.round(span * 1000) / 1000 });
            }
            tunnelSupport++;
        } while (span > minSpan);
        if (tunnelSupports.length == 0) {
            tunnelSupports.push({ totalTunnelSupport: Math.ceil(tunnerLenght / 10), span: 7 })
        }
        return tunnelSupports;
    }

    public static clearCanvas(ctx: CanvasRenderingContext2D) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    public static calculateScale(ctx: CanvasRenderingContext2D, dia: number, indent: number = 60): number {
        let minSide = Math.min(...[ctx.canvas.width, ctx.canvas.height]) - indent;
        return minSide / (dia * 12);
    }

    public static drawErrorBorder(ctx: CanvasRenderingContext2D) {
        ctx.lineWidth = 3;
        ctx.strokeStyle = 'red';
        ctx.strokeRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.lineWidth = 1;
    }

    public static drawCircleFromCenter(ctx: CanvasRenderingContext2D, raidus: number, scale: number) {
        ctx.beginPath();
        ctx.arc(ctx.canvas.width / 2, ctx.canvas.height / 2, raidus * scale, 0, 2 * Math.PI, false);
        ctx.stroke();
    }

    public static drawFlushFloorForTwoFans(ctx: CanvasRenderingContext2D, x: number[], y: number[], fillColor: string) {
        ctx.lineJoin = "miter";
        this.drawLinesTwoFans(ctx, x, y, fillColor);
        for (let i = 0; i < x.length; i++) {
            x[i] = ctx.canvas.width - x[i];
        }
        this.drawLinesTwoFans(ctx, x, y, fillColor);
    }

    public static drawVerticalLineWithLabelLeft(ctx: CanvasRenderingContext2D, scale: number, x: number, y: number, label: string, side: number) {
        ctx.fillText(label, x, y + CanvasFlushFloorSupportFunction.FontSize / 3);
        ctx.beginPath();
        y -= side / 2 * scale;
        x += CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.moveTo(x, y);
        x += CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        x -= CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        y += side * scale;
        ctx.lineTo(x, y);
        x -= CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        x += CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        ctx.stroke();
    }

    public static drawVerticalLineWithLabelRight(ctx: CanvasRenderingContext2D, scale: number, x: number, y: number, label: string, side: number) {
        ctx.fillText(label, x, y + CanvasFlushFloorSupportFunction.FontSize / 3);
        ctx.beginPath();
        y -= side / 2 * scale;
        x -= CanvasFlushFloorSupportFunction.FontSize / 3;
        ctx.moveTo(x, y);
        x += CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        x -= CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        y += side * scale;
        ctx.lineTo(x, y);
        x -= CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        x += CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        ctx.stroke();
    }

    public static drawHorizontalLineWithBottomLabel(ctx: CanvasRenderingContext2D, scale: number, x: number, y: number, label: string, side: number) {
        ctx.fillText(label, x, y);
        ctx.beginPath();
        x -= side / 2 * scale - CanvasFlushFloorSupportFunction.FontSize / 4;
        y -= CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.moveTo(x, y);
        y -= CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        y += CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        x += side * scale;
        ctx.lineTo(x, y);
        y += CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        y -= CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        ctx.stroke();
    }

    public static drawHorizontalLineWithTopLabel(ctx: CanvasRenderingContext2D, scale: number, x: number, y: number, label: string, side: number) {
        ctx.fillText(label, x, y);

        ctx.beginPath();
        x -= side / 2 * scale - CanvasFlushFloorSupportFunction.FontSize / 4;
        y += CanvasFlushFloorSupportFunction.FontSize / 3;
        ctx.moveTo(x, y);
        y -= CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        y += CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        x += side * scale;
        ctx.lineTo(x, y);
        y += CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        y -= CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        ctx.stroke();
    }

    public static drawObliqueLineBottomWithLabel(ctx: CanvasRenderingContext2D, scale: number, x: number, y: number, label: string, side: number) {
        ctx.beginPath();
        ctx.moveTo(x, y);
        x += (side / 2) * scale;
        y += scale * Math.sqrt(Math.pow(side, 2) - Math.pow(side / 2, 2));
        ctx.lineTo(x, y);
        ctx.stroke();
        y += CanvasFlushFloorSupportFunction.FontSize;
        ctx.fillText(label, x, y);
    }

    public static drawObliqueLineTopWithLabel(ctx: CanvasRenderingContext2D, scale: number, x: number, y: number, label: string, side: number) {
        ctx.beginPath();
        ctx.moveTo(x, y);
        x += (side / 2) * scale;
        y -= scale * Math.sqrt(Math.pow(side, 2) - Math.pow(side / 2, 2));
        ctx.lineTo(x, y);
        ctx.stroke();
        y -= CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.fillText(label, x, y);
    }

    public static drawLabelInRect(ctx: CanvasRenderingContext2D, x: number, y: number, label: string, offset: number = 2) {
        let textWidth = ctx.measureText(label).width;
        ctx.clearRect(x - (textWidth + offset * 2) / 2, y, textWidth + offset * 2, CanvasFlushFloorSupportFunction.FontSize - 2);
        ctx.beginPath();
        ctx.rect(x - (textWidth + offset * 2) / 2, y, textWidth + offset * 2, CanvasFlushFloorSupportFunction.FontSize - 2);
        ctx.stroke();
        ctx.fillText(label, x - textWidth / 2, y + CanvasFlushFloorSupportFunction.FontSize - 5);
    }

    public static drawFlushFloorForFourFans(ctx: CanvasRenderingContext2D, x: number[], y: number[], fillColor: string) {
        ctx.lineJoin = "miter";
        this.drawLinesFourFans(ctx, x, y, fillColor);
        for (let i = 0; i < x.length; i++) {
            x[i] = ctx.canvas.width - x[i];
        }
        this.drawLinesFourFans(ctx, x, y, fillColor);
    }

    public static drawFlushFloorForSixFans(ctx: CanvasRenderingContext2D, x: number[], y: number[], fillColor: string) {
        ctx.lineJoin = "miter";
        this.drawLinesSixFans(ctx, x, y, fillColor);
        for (let i = 0; i < x.length; i++) {
            x[i] = ctx.canvas.width - x[i];
        }
        this.drawLinesSixFans(ctx, x, y, fillColor);
    }

    public static drawCenterVerticalLineWithLabelRight(ctx: CanvasRenderingContext2D, scale: number, x: number, y: number, label: string, side: number) {
        ctx.fillText(label, x, y);
        ctx.beginPath();
        y -= side / 2 * scale;
        x -= CanvasFlushFloorSupportFunction.FontSize / 3;
        ctx.moveTo(x, y);
        x += CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        x -= CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        y += side * scale;
        ctx.lineTo(x, y);
        x -= CanvasFlushFloorSupportFunction.FontSize / 4;
        ctx.moveTo(x, y);
        x += CanvasFlushFloorSupportFunction.FontSize / 2;
        ctx.lineTo(x, y);
        ctx.stroke();
    }

    private static drawLinesTwoFans(ctx: CanvasRenderingContext2D, x: number[], y: number[], fillColor: string) {
        ctx.beginPath();
        ctx.moveTo(x[1], y[1]);
        ctx.lineTo(x[x.length - 2], y[x.length - 2]);
        ctx.moveTo(x[1], y[1]);
        for (let i = 2; i < x.length - 1; i++) {
            ctx.lineTo(x[i], y[i]);
        }

        ctx.fillStyle = fillColor;
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = 'grey';
        ctx.moveTo(x[x.length - 2], y[x.length - 2]);
        ctx.lineTo(x[x.length - 1], y[x.length - 1]);
        ctx.moveTo(x[0], y[0]);
        ctx.lineTo(x[1], y[1]);
        ctx.stroke();
        ctx.strokeStyle = 'black';
    }

    private static drawLinesFourFans(ctx: CanvasRenderingContext2D, x: number[], y: number[], fillColor: string) {
        ctx.beginPath();
        ctx.moveTo(x[1], y[1]);
        ctx.lineTo(x[8], y[8]);
        ctx.moveTo(x[1], y[1]);
        for (let i = 2; i < 9; i++) {
            ctx.lineTo(x[i], y[i]);
        }

        ctx.fillStyle = fillColor;
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(x[11], y[11]);
        for (let i = 12; i <= 14; i++) {
            ctx.lineTo(x[i], y[i]);
        }
        ctx.fillStyle = fillColor;
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(x[11], y[11]);
        ctx.lineTo(x[14], y[14]);
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = 'grey';
        ctx.moveTo(x[8], y[8]);
        ctx.lineTo(x[9], y[9]);
        ctx.moveTo(x[0], y[0]);
        ctx.lineTo(x[1], y[1]);

        ctx.moveTo(x[14], y[14]);
        ctx.lineTo(x[15], y[15]);
        ctx.moveTo(x[10], y[10]);
        ctx.lineTo(x[11], y[11]);
        ctx.stroke();
        ctx.strokeStyle = 'black';
    }

    private static drawLinesSixFans(ctx: CanvasRenderingContext2D, x: number[], y: number[], fillColor: string) {
        ctx.beginPath();
        ctx.moveTo(x[1], y[1]);
        ctx.lineTo(x[8], y[8]);
        ctx.moveTo(x[1], y[1]);
        for (let i = 2; i <= 8; i++) {
            ctx.lineTo(x[i], y[i]);
        }
        ctx.fillStyle = fillColor;
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(x[11], y[11]);
        ctx.lineTo(x[14], y[14]);
        ctx.moveTo(x[11], y[11]);
        for (let i = 12; i <= 14; i++) {
            ctx.lineTo(x[i], y[i]);
        }
        ctx.fillStyle = fillColor;
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(x[17], y[17]);
        ctx.lineTo(x[20], y[20]);
        ctx.moveTo(x[17], y[17]);

        for (let i = 18; i <= 20; i++) {
            ctx.lineTo(x[i], y[i]);
        }
        ctx.fillStyle = fillColor;
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = 'grey';
        ctx.moveTo(x[0], y[0]);
        ctx.lineTo(x[1], y[1]);
        ctx.moveTo(x[8], y[8]);
        ctx.lineTo(x[9], y[9]);
        ctx.moveTo(x[10], y[10]);
        ctx.lineTo(x[11], y[11]);
        ctx.moveTo(x[14], y[14]);
        ctx.lineTo(x[15], y[15]);
        ctx.moveTo(x[16], y[16]);
        ctx.lineTo(x[17], y[17]);
        ctx.moveTo(x[20], y[20]);
        ctx.lineTo(x[21], y[21]);
        ctx.stroke();
        ctx.strokeStyle = 'black';
    }
}
