import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { AccountProfileModel, ComputationSheetAddEditModel, ComputationSheetDescription, ComputationSheetModel, ComputationSheetResponseModel, CustomerCompany, CustomerStorageLocation, PartInfoAddEditModel, PartInfoModel, PartInfoResponseModel, ReportComputationSheetModel } from '@models';
import { AqsComputationSheetResavePopupComponent, ComputationSheetReportPopupComponent, ComputationSheetShareReportPopupComponent } from '@popups';
import { ErrorModel } from '../../../common';
import { PartTypeEnum } from '../../enums';
import { AccountDatasourceService, AqsQuoteStorageDataSourceService } from '../../services';
import { ComputationSheetTableComponent } from './computation-sheet-table';

@Component({
  selector: 'greensleeves-aqs-computation-sheet-tab',
  templateUrl: './aqs-computation-sheet-tab.component.html',
  styles: [],
})
export class AqsComputationSheetTabComponent implements OnInit {
  @ViewChild('groupForm', { static: true })
  private form: NgForm;

  @ViewChild(ComputationSheetReportPopupComponent, { read: false, static: false })
  private _exportReportModal: ComputationSheetReportPopupComponent;

  @ViewChild(ComputationSheetShareReportPopupComponent, { read: false, static: false })
  private _shareReportModal: ComputationSheetShareReportPopupComponent;

  @ViewChild(AqsComputationSheetResavePopupComponent, { read: false, static: false })
  private resaveModal: AqsComputationSheetResavePopupComponent;

  @ViewChild('ductTable', { read: false, static: false })
  private ductTable: ComputationSheetTableComponent;
  @ViewChild('fansTable', { read: false, static: false })
  private fansTable: ComputationSheetTableComponent;
  @ViewChild('manifoldTable', { read: false, static: false })
  private manifoldTable: ComputationSheetTableComponent;
  @ViewChild('miscellaneousEquipmentTable', { read: false, static: false })
  private miscellaneousEquipmentTable: ComputationSheetTableComponent;

  _computationSheet: ComputationSheetModel;

  _isSaveRun = false;
  _isSuccessSaved = false;
  _isChangedTables = false;
  _isSubmited: boolean = false;
  _isLoad: boolean = false;

  _tableName = {
    duct: "Duct and accessories",
    fans: "Fans and accessories",
    manifold: "Manifold and accessories",
    miscellaneous: "Miscellaneous equipment"
  }

  constructor(
    private _accountSource: AccountDatasourceService,
    private _computationSheetService: AqsQuoteStorageDataSourceService,
    private _route: ActivatedRoute,) {
  }

  ngOnInit() {
    let quoteId: number;
    this._route.queryParams.subscribe((queryParam: any) => {
      quoteId = queryParam['id'];
    });

    this.initializeModel();
    if (!isNaN(quoteId)) {
      this.downloadComputationSheetById(quoteId);
    }
  }

  isChangedTables(isChanged: boolean) {
    this._isChangedTables = isChanged;
    this._isSubmited = false;
  }

  onChangeCrewTravelNet() {
    if (!_isNumberValue(this._computationSheet.crewTravelNetPrice)) {
      this._computationSheet.crewTravelNetPrice = 0;
    }
  }

  onChangeFreightNet() {
    if (!_isNumberValue(this._computationSheet.freightNetPrice)) {
      this._computationSheet.freightNetPrice = 0;
    }
  }

  checkPercentage(percentageField: string) {
    let regex = /^([0-9]{1,2}){1}(\.[0-9]{1,2})?$/

    switch (percentageField) {
      case "materialDiscount":
        if (!regex.test(this._computationSheet.materialDiscountPercentage.toString())) {
          this._computationSheet.materialDiscountPercentage = 0;
        }
        break;
      case "laborDiscount":
        if (!regex.test(this._computationSheet.laborDiscountPercentage.toString())) {
          this._computationSheet.laborDiscountPercentage = 0;
        }
        break;
      case "materialTax":
        if (!regex.test(this._computationSheet.materialTaxPercentage.toString())) {
          this._computationSheet.materialTaxPercentage = 0;
        }
        break;
      case "laborTax":
        if (!regex.test(this._computationSheet.laborTaxPercentage.toString())) {
          this._computationSheet.laborTaxPercentage = 0;
        }
        break;
      case "crewTravelTax":
        if (!regex.test(this._computationSheet.crewTravelTaxPercentage.toString())) {
          this._computationSheet.crewTravelTaxPercentage = 0;
        }
        break;
      case "freightTax":
        if (!regex.test(this._computationSheet.freightTaxPercentage.toString())) {
          this._computationSheet.freightTaxPercentage = 0;
        }
        break;
      default:
        break;
    }
  }

  calculateTotalFreightWeight(): number {
    var result = 0;

    this._computationSheet.ductTable.forEach(item => {
      var a = item.quantity * item.weight;
      result += a;
    })

    this._computationSheet.fansTable.forEach(item => {
      var a = item.quantity * item.weight;
      result += a;
    })

    this._computationSheet.manifoldTable.forEach(item => {
      var a = item.quantity * item.weight;
      result += a;
    })

    this._computationSheet.miscellaneousEquipmentTable.forEach(item => {
      var a = item.quantity * item.weight;
      result += a;
    })

    this._computationSheet.totalFreightWeight = result;

    return this._computationSheet.totalFreightWeight;
  }

  calculateMaterialPriceWithDiscountAndTax(): number {
    let totalMaterialPrice = this.getTotalPriceAllMaterials();

    let discount = (totalMaterialPrice * this._computationSheet.materialDiscountPercentage) / 100;
    let materialPriceWithDiscount = totalMaterialPrice - discount;

    let tax = (materialPriceWithDiscount * this._computationSheet.materialTaxPercentage) / 100;

    this._computationSheet.materialPriceWithDiscountAndTax = this.roundToTwoDecimalPlaces(materialPriceWithDiscount + tax);

    return this._computationSheet.materialPriceWithDiscountAndTax;
  }

  getTotalPriceAllMaterials(): number {
    let totalMaterialPrice = 0;

    this._computationSheet.ductTable.forEach(item => {
      totalMaterialPrice += item.totalPriceMaterial;
    });

    this._computationSheet.fansTable.forEach(item => {
      totalMaterialPrice += item.totalPriceMaterial;
    });

    this._computationSheet.manifoldTable.forEach(item => {
      totalMaterialPrice += item.totalPriceMaterial;
    });

    this._computationSheet.miscellaneousEquipmentTable.forEach(item => {
      totalMaterialPrice += item.totalPriceMaterial;
    });

    this._computationSheet.totalPriceAllMaterials = this.roundToTwoDecimalPlaces(totalMaterialPrice);
    return totalMaterialPrice;
  }

  calculateLaborPriceWithDiscountAndTax(): number {
    let totalLaborPrice = this.getTotalPriceAllLabors();

    let discount = (totalLaborPrice * this._computationSheet.laborDiscountPercentage) / 100;
    let laborPriceWithDiscount = totalLaborPrice - discount;

    let tax = (laborPriceWithDiscount * this._computationSheet.laborTaxPercentage) / 100;

    this._computationSheet.laborPriceWithDiscountAndTax = this.roundToTwoDecimalPlaces(laborPriceWithDiscount + tax);

    return this._computationSheet.laborPriceWithDiscountAndTax;
  }

  getTotalPriceAllLabors(): number {
    let totalLaborPrice = 0;

    this._computationSheet.ductTable.forEach(item => {
      totalLaborPrice += item.totalPriceLabor;
    });

    this._computationSheet.fansTable.forEach(item => {
      totalLaborPrice += item.totalPriceLabor;
    });

    this._computationSheet.manifoldTable.forEach(item => {
      totalLaborPrice += item.totalPriceLabor;
    });

    this._computationSheet.miscellaneousEquipmentTable.forEach(item => {
      totalLaborPrice += item.totalPriceLabor;
    });

    this._computationSheet.totalPriceAllLabors = this.roundToTwoDecimalPlaces(totalLaborPrice);
    return totalLaborPrice;
  }

  calculateCrewTravelPriceWithTax(): number {
    let result: number;

    let tax = (this._computationSheet.crewTravelNetPrice * this._computationSheet.crewTravelTaxPercentage) / 100;

    if (tax === 0) {
      result = this._computationSheet.crewTravelNetPrice;
    } else {
      result = this._computationSheet.crewTravelNetPrice + tax;
    }

    this._computationSheet.crewTravelNetWithTax = this.roundToTwoDecimalPlaces(Number(result));

    return this._computationSheet.crewTravelNetWithTax;
  }

  calculateFreightNetPriceWithTax(): number {
    let result: number;

    let tax = (this._computationSheet.freightNetPrice * this._computationSheet.freightTaxPercentage) / 100;

    if (tax === 0) {
      result = this._computationSheet.freightNetPrice;
    } else {
      result = this._computationSheet.freightNetPrice + tax;
    }

    this._computationSheet.freightNetWithTax = this.roundToTwoDecimalPlaces(Number(result));

    return this._computationSheet.freightNetWithTax;
  }

  calculateGrandTotal(): number {
    let result = this._computationSheet.materialPriceWithDiscountAndTax +
      this._computationSheet.laborPriceWithDiscountAndTax +
      this._computationSheet.crewTravelNetWithTax +
      this._computationSheet.freightNetWithTax;

    this._computationSheet.grandTotal = this.roundToTwoDecimalPlaces(result);

    return result;
  }

  public onClickShareReport() {
    let model = this.buildReportModel()
    this._shareReportModal.showDownloadComputationSheetReport(model);
  }

  onClickExportReport() {
    let model = this.buildReportModel()
    this._exportReportModal.showDownloadComputationSheetReport(model);
  }

  onClickSaveComputationSheet() {
    this._isSubmited = true;

    if (this.form.invalid || !this.tablesIsValid()) {
      return;
    }

    if (!this._computationSheet.id) {
      this.saveComputationSheet();
    } else {
      this.resaveModal.showConfirmPopup();
    }
  }

  async editComputationSheet() {
    this._isSaveRun = true;

    let rowsForDelete = this.getRowIdsForDelete();

    let model = this.buildComputationSheetAddEditModel(true);
    model.partsInfoIdsForDelete = rowsForDelete;
    let result = await this._computationSheetService.editComputationSheet(model) as ComputationSheetResponseModel;
    this.processingResultAddEdit(result);
  }

  private getRowIdsForDelete(): number[] {
    let rowsForDelete: number[] = [];

    if (this.ductTable._rowsWillBeRemoved.length > 0) {
      rowsForDelete = rowsForDelete.concat(this.ductTable._rowsWillBeRemoved);
    }

    if (this.fansTable._rowsWillBeRemoved.length > 0) {
      rowsForDelete = rowsForDelete.concat(this.fansTable._rowsWillBeRemoved);
    }

    if (this.manifoldTable._rowsWillBeRemoved.length > 0) {
      rowsForDelete = rowsForDelete.concat(this.manifoldTable._rowsWillBeRemoved);
    }

    if (this.miscellaneousEquipmentTable._rowsWillBeRemoved.length > 0) {
      rowsForDelete = rowsForDelete.concat(this.miscellaneousEquipmentTable._rowsWillBeRemoved);
    }

    if (this.ductTable._table.length == 1 &&
      this.ductTable.isEmptyRow(0)
      && this.ductTable._table[0].id) {
      rowsForDelete.push(this.ductTable._table[0].id)
    }

    if (this.fansTable._table.length == 1 &&
      this.fansTable.isEmptyRow(0)
      && this.fansTable._table[0].id) {
      rowsForDelete.push(this.fansTable._table[0].id)
    }

    if (this.manifoldTable._table.length == 1 &&
      this.manifoldTable.isEmptyRow(0)
      && this.manifoldTable._table[0].id) {
      rowsForDelete.push(this.manifoldTable._table[0].id)
    }

    if (this.miscellaneousEquipmentTable._table.length == 1 &&
      this.miscellaneousEquipmentTable.isEmptyRow(0)
      && this.miscellaneousEquipmentTable._table[0].id) {
      rowsForDelete.push(this.miscellaneousEquipmentTable._table[0].id)
    }

    return rowsForDelete;
  }

  async downloadComputationSheetById(id: number) {
    this._isLoad = true;
    let result = await this._computationSheetService.getComputationSheetById(id) as ComputationSheetResponseModel;

    if (!(result instanceof ErrorModel)) {
      this._computationSheet = new ComputationSheetModel()
      this._computationSheet.id = result.id;
      this._computationSheet.customerCompany = new CustomerCompany();
      Object.assign(this._computationSheet.customerCompany, result.customerCompany);
      this._computationSheet.customerStorageLocation = new CustomerStorageLocation();
      Object.assign(this._computationSheet.customerStorageLocation, result.customerStorageLocation);
      this._computationSheet.description = new ComputationSheetDescription();
      Object.assign(this._computationSheet.description, result.description);
      this._computationSheet.crewTravelNetPrice = result.crewTravelNetPrice;
      this._computationSheet.freightNetPrice = result.freightNetPrice;
      this._computationSheet.materialDiscountPercentage = result.materialDiscountPercentage;
      this._computationSheet.laborDiscountPercentage = result.laborDiscountPercentage;
      this._computationSheet.materialTaxPercentage = result.materialTaxPercentage;
      this._computationSheet.laborTaxPercentage = result.laborTaxPercentage;
      this._computationSheet.crewTravelTaxPercentage = result.crewTravelTaxPercentage;
      this._computationSheet.freightTaxPercentage = result.freightTaxPercentage;
      this._computationSheet.preparedBy = result.preparedBy;
      this._computationSheet.leadTime = result.leadTime;
      this._computationSheet.ductTable = [];
      this._computationSheet.fansTable = [];
      this._computationSheet.manifoldTable = [];
      this._computationSheet.miscellaneousEquipmentTable = [];

      result.partInfos = result.partInfos.sort((a, b) => a.position - b.position);

      result.partInfos.forEach(item => {
        let partInfo = new PartInfoModel();
        Object.assign(partInfo, item);

        switch (item.partType) {
          case PartTypeEnum.duct:
            this._computationSheet.ductTable.push(partInfo);
            break;
          case PartTypeEnum.fans:
            this._computationSheet.fansTable.push(partInfo);
            break;
          case PartTypeEnum.manifold:
            this._computationSheet.manifoldTable.push(partInfo);
            break;
          case PartTypeEnum.miscellaneousEquipment:
            this._computationSheet.miscellaneousEquipmentTable.push(partInfo);
            break;
          default:
            break;
        }
      })

      this.ductTable._table = this._computationSheet.ductTable;
      this.fansTable._table = this._computationSheet.fansTable;
      this.manifoldTable._table = this._computationSheet.manifoldTable;
      this.miscellaneousEquipmentTable._table = this._computationSheet.miscellaneousEquipmentTable;

      this.ductTable.setTable()
      this.fansTable.setTable()
      this.manifoldTable.setTable()
      this.miscellaneousEquipmentTable.setTable()
    }

    this._isChangedTables = false;
    this.form.form.markAsPristine();
    this._isLoad = false;
  }

  async saveComputationSheet() {
    this._isSaveRun = true;
    let model = this.buildComputationSheetAddEditModel();
    let result = await this._computationSheetService.addComputationSheet(model) as ComputationSheetResponseModel;
    this.processingResultAddEdit(result);
  };

  tablesIsValid(): boolean {
    return this.isValidTable(this._computationSheet.ductTable) &&
      this.isValidTable(this._computationSheet.fansTable) &&
      this.isValidTable(this._computationSheet.manifoldTable) &&
      this.isValidTable(this._computationSheet.miscellaneousEquipmentTable)
  }

  isInvalidForReport(): boolean {
    return this.form.invalid || this.form.dirty || !this.tablesIsValid() || this._isChangedTables;
  }

  private processingResultAddEdit(result: ComputationSheetResponseModel) {
    if (result instanceof ErrorModel) {
      return;
    } else if (result) {
      this._isSuccessSaved = true;
      setTimeout(() => { this._isSuccessSaved = false; }, 3000);

      this._computationSheet.id = result.id;
      this.setIdIntoTables(result.partInfos);

      this._isChangedTables = false;
      this.form.form.markAsPristine();
    }

    this._isSaveRun = false;
  }

  private setIdIntoTables(partInfos: PartInfoResponseModel[]) {
    partInfos.forEach(item => {

      switch (item.partType) {
        case PartTypeEnum.duct:
          this._computationSheet.ductTable[item.position].id = item.id;
          break;
        case PartTypeEnum.fans:
          this._computationSheet.fansTable[item.position].id = item.id;
          break;
        case PartTypeEnum.manifold:
          this._computationSheet.manifoldTable[item.position].id = item.id;
          break;
        case PartTypeEnum.miscellaneousEquipment:
          this._computationSheet.miscellaneousEquipmentTable[item.position].id = item.id;
          break;
        default:
          break;
      }
    });
  }

  private buildReportModel(): ReportComputationSheetModel {
    let reportModel = new ReportComputationSheetModel();

    reportModel.id = this._computationSheet.id;
    reportModel.customerCompany = new CustomerCompany();
    Object.assign(reportModel.customerCompany, this._computationSheet.customerCompany);
    reportModel.customerStorageLocation = new CustomerStorageLocation();
    Object.assign(reportModel.customerStorageLocation, this._computationSheet.customerStorageLocation);
    reportModel.description = new ComputationSheetDescription();
    Object.assign(reportModel.description, this._computationSheet.description);
    reportModel.crewTravelNetPrice = this._computationSheet.crewTravelNetPrice;
    reportModel.freightNetPrice = this._computationSheet.freightNetPrice;
    reportModel.materialDiscountPercentage = this._computationSheet.materialDiscountPercentage;
    reportModel.laborDiscountPercentage = this._computationSheet.laborDiscountPercentage;
    reportModel.materialTaxPercentage = this._computationSheet.materialTaxPercentage;
    reportModel.laborTaxPercentage = this._computationSheet.laborTaxPercentage;
    reportModel.crewTravelTaxPercentage = this._computationSheet.crewTravelTaxPercentage;
    reportModel.freightTaxPercentage = this._computationSheet.freightTaxPercentage;
    reportModel.preparedBy = this._computationSheet.preparedBy;
    reportModel.partInfos = this.fillPartsInfoReportModel();
    reportModel.totalFreightWeight = this._computationSheet.totalFreightWeight;
    reportModel.totalPriceAllMaterials = this._computationSheet.totalPriceAllMaterials;
    reportModel.totalPriceAllLabors = this._computationSheet.totalPriceAllLabors;
    reportModel.materialPriceWithDiscountAndTax = this._computationSheet.materialPriceWithDiscountAndTax;
    reportModel.laborPriceWithDiscountAndTax = this._computationSheet.laborPriceWithDiscountAndTax;
    reportModel.crewTravelNetWithTax = this._computationSheet.crewTravelNetWithTax;
    reportModel.freightNetWithTax = this._computationSheet.freightNetWithTax;
    reportModel.grandTotal = this._computationSheet.grandTotal;
    reportModel.leadTime = this._computationSheet.leadTime;
    return reportModel;
  }

  private fillPartsInfoReportModel(): PartInfoModel[] {
    this.setTableTypes();
    let partInfos: PartInfoModel[] = [];

    if (!this.isEmptyTable(this._computationSheet.ductTable)) {
      Array.prototype.push.apply(partInfos, this._computationSheet.ductTable);
    }

    if (!this.isEmptyTable(this._computationSheet.fansTable)) {
      Array.prototype.push.apply(partInfos, this._computationSheet.fansTable);
    }

    if (!this.isEmptyTable(this._computationSheet.manifoldTable)) {
      Array.prototype.push.apply(partInfos, this._computationSheet.manifoldTable);
    }

    if (!this.isEmptyTable(this._computationSheet.miscellaneousEquipmentTable)) {
      Array.prototype.push.apply(partInfos, this._computationSheet.miscellaneousEquipmentTable);
    }

    return partInfos;
  }

  private setTableTypes() {
    this._computationSheet.ductTable.forEach(item => {
      item.partType = PartTypeEnum.duct;
    });

    this._computationSheet.fansTable.forEach(item => {
      item.partType = PartTypeEnum.fans;
    });

    this._computationSheet.manifoldTable.forEach(item => {
      item.partType = PartTypeEnum.manifold;
    });

    this._computationSheet.miscellaneousEquipmentTable.forEach(item => {
      item.partType = PartTypeEnum.miscellaneousEquipment;
    });
  }

  private roundToTwoDecimalPlaces(number: number): number {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  private isValidTable(table: PartInfoModel[]): boolean {
    let isValid: boolean = true;

    for (let item of table) {
      if (item.quantity == 0 || item.description == '') {
        isValid = false;
      }
    }

    return this.isEmptyTable(table) || isValid;
  }

  private isEmptyTable(table: PartInfoModel[]): boolean {
    return table.length === 1 &&
      (table[0].partNumber === undefined || table[0].partNumber === '') &&
      (table[0].quantity === 0 || !_isNumberValue(table[0].quantity)) &&
      table[0].description === '' &&
      (table[0].weight === 0 || !_isNumberValue(table[0].weight)) &&
      (table[0].unitPriceMaterial === 0 || !_isNumberValue(table[0].unitPriceMaterial)) &&
      (table[0].unitPriceLabor === 0 || !_isNumberValue(table[0].unitPriceLabor))
  }

  private buildComputationSheetAddEditModel(isEditModel: boolean = false): ComputationSheetAddEditModel {
    this.setTableTypes();
    let computationSheetAddEditModel = new ComputationSheetAddEditModel();

    if (isEditModel) {
      computationSheetAddEditModel.id = this._computationSheet.id;
    }

    computationSheetAddEditModel.customerCompany = new CustomerCompany();
    Object.assign(computationSheetAddEditModel.customerCompany, this._computationSheet.customerCompany);
    computationSheetAddEditModel.customerStorageLocation = new CustomerStorageLocation();
    Object.assign(computationSheetAddEditModel.customerStorageLocation, this._computationSheet.customerStorageLocation);
    computationSheetAddEditModel.description = new ComputationSheetDescription();
    Object.assign(computationSheetAddEditModel.description, this._computationSheet.description);
    computationSheetAddEditModel.crewTravelNetPrice = this._computationSheet.crewTravelNetPrice;
    computationSheetAddEditModel.freightNetPrice = this._computationSheet.freightNetPrice;
    computationSheetAddEditModel.materialDiscountPercentage = this._computationSheet.materialDiscountPercentage;
    computationSheetAddEditModel.laborDiscountPercentage = this._computationSheet.laborDiscountPercentage;
    computationSheetAddEditModel.materialTaxPercentage = this._computationSheet.materialTaxPercentage;
    computationSheetAddEditModel.laborTaxPercentage = this._computationSheet.laborTaxPercentage;
    computationSheetAddEditModel.crewTravelTaxPercentage = this._computationSheet.crewTravelTaxPercentage;
    computationSheetAddEditModel.freightTaxPercentage = this._computationSheet.freightTaxPercentage;
    computationSheetAddEditModel.preparedBy = this._computationSheet.preparedBy;
    computationSheetAddEditModel.leadTime = this._computationSheet.leadTime;

    computationSheetAddEditModel.partInfos = [];

    if (!this.isEmptyTable(this._computationSheet.ductTable)) {
      Array.prototype.push.apply(computationSheetAddEditModel.partInfos, this.setTableToPartsInfoAddEditModel(this._computationSheet.ductTable, isEditModel));
    }

    if (!this.isEmptyTable(this._computationSheet.fansTable)) {
      Array.prototype.push.apply(computationSheetAddEditModel.partInfos, this.setTableToPartsInfoAddEditModel(this._computationSheet.fansTable, isEditModel));
    }

    if (!this.isEmptyTable(this._computationSheet.manifoldTable)) {
      Array.prototype.push.apply(computationSheetAddEditModel.partInfos, this.setTableToPartsInfoAddEditModel(this._computationSheet.manifoldTable, isEditModel));
    }

    if (!this.isEmptyTable(this._computationSheet.miscellaneousEquipmentTable)) {
      Array.prototype.push.apply(computationSheetAddEditModel.partInfos, this.setTableToPartsInfoAddEditModel(this._computationSheet.miscellaneousEquipmentTable, isEditModel));
    }

    return computationSheetAddEditModel;
  }

  private setTableToPartsInfoAddEditModel(partsInfo: PartInfoModel[], isEditModel): PartInfoAddEditModel[] {
    let result: PartInfoAddEditModel[] = [];

    for (let i = 0; i < partsInfo.length; i++) {
      let partInfo = new PartInfoAddEditModel();

      if (isEditModel) {
        partInfo.id = partsInfo[i].id;
      }

      partInfo.partNumber = partsInfo[i].partNumber;
      partInfo.quantity = partsInfo[i].quantity;
      partInfo.description = partsInfo[i].description;
      partInfo.weight = partsInfo[i].weight;
      partInfo.unitPriceMaterial = partsInfo[i].unitPriceMaterial;
      partInfo.unitPriceLabor = partsInfo[i].unitPriceLabor;
      partInfo.partType = partsInfo[i].partType;
      partInfo.position = i;

      result.push(partInfo);
    }

    return result;
  }

  private initializeModel() {
    this._computationSheet = new ComputationSheetModel();
    this._computationSheet.customerCompany = new CustomerCompany();
    this._computationSheet.customerCompany.name = '';
    this._computationSheet.customerCompany.companyName = '';
    this._computationSheet.customerCompany.city = '';
    this._computationSheet.customerCompany.contact = '';

    this._computationSheet.customerStorageLocation = new CustomerStorageLocation();
    this._computationSheet.customerStorageLocation.quoteNumber = '';
    this._computationSheet.customerStorageLocation.name = '';
    this._computationSheet.customerStorageLocation.city = '';
    this._computationSheet.customerStorageLocation.description = '';

    this._computationSheet.description = new ComputationSheetDescription();
    this._computationSheet.description.descriptionOfStorage = '';
    this._computationSheet.description.descriptionOfSystem = '';

    this._computationSheet.ductTable = [];
    this._computationSheet.fansTable = [];
    this._computationSheet.manifoldTable = [];
    this._computationSheet.miscellaneousEquipmentTable = [];

    this._computationSheet.crewTravelNetPrice = 0;
    this._computationSheet.freightNetPrice = 0;
    this._computationSheet.materialDiscountPercentage = 0;
    this._computationSheet.laborDiscountPercentage = 0;
    this._computationSheet.materialTaxPercentage = 0;
    this._computationSheet.laborTaxPercentage = 0;
    this._computationSheet.crewTravelTaxPercentage = 0;
    this._computationSheet.freightTaxPercentage = 0;
    this._computationSheet.totalFreightWeight = 0;
    this._computationSheet.totalPriceAllMaterials = 0;
    this._computationSheet.totalPriceAllLabors = 0;
    this._computationSheet.materialPriceWithDiscountAndTax = 0;
    this._computationSheet.laborPriceWithDiscountAndTax = 0;
    this._computationSheet.crewTravelNetWithTax = 0;
    this._computationSheet.freightNetWithTax = 0;
    this._computationSheet.grandTotal = 0;
    this._computationSheet.leadTime = '';

    this._accountSource.getProfile().then((profile: AccountProfileModel) => {
      let fistName = profile.firstName ? profile.firstName : '';
      let lastName = profile.lastName ? ' ' + profile.lastName  : ''
      this._computationSheet.preparedBy = fistName + lastName;
    });
  }
}