import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BtxReportLiveDataFilter, KtxReportCurrentDataFilter, KtxReportHistoricalDataFilter, NotificationReportPostModel, ReportDataFileFilterPost, ReportDataFilterPost, ReportHistoricalDataResponse, ReportLiveDataFilterPost } from '@models';
import { ErrorModel, HttpService } from 'src/app/common';
import { ApiRoutes } from '../../constants';
import { BtxHistoricalDataReportFilterRequest } from '../../models/btx-reports.model';

@Injectable({
  providedIn: 'root'
})
export class ReportApiService {

  constructor(private _httpService: HttpService,
    private _router: Router) { }

  public async getItxReportHistoricalDataByFilter(filter: ReportDataFilterPost): Promise<ReportHistoricalDataResponse | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeReportData(filter);
      }
      const result = await this._httpService.post(ApiRoutes.Report.getItxReportHistoricalDataByFilter, filter)
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadKtxHistoricalReportFile(filter: KtxReportHistoricalDataFilter): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.Report.getKtxReportHistoricalByFilter, filter);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadBtxHistoricalDataReportFile(filter: BtxHistoricalDataReportFilterRequest): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.Report.getBtxReportHistoricalDataByFilter, filter);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadKtxCurrentDataReportFile(filter: KtxReportCurrentDataFilter): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.Report.getKtxReportCurrentDataByFilter, filter);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadItxHistoricalReportFile(filter: ReportDataFileFilterPost): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.Report.getItxReportFile, filter);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadItxLiveDataReportFile(filter: ReportLiveDataFilterPost): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.Report.getItxReportLiveDataByFilter, filter);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadBtxLiveDataReportFile(filter: BtxReportLiveDataFilter): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.Report.getBtxReportLiveDataByFilter, filter);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadNotificationReport(model: NotificationReportPostModel): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.Report.getNotificationReport, model);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }
    console.log(error);
    return null;
  }

  private getFakeReportData(filter: ReportDataFilterPost): ReportHistoricalDataResponse {

    let minut5: number = 5 * 60;
    let min = 0;
    let max = 100;
    let value: number = 1;
    let increment: number = 1;
    let data1: { timestamp: number, value: number }[] = [];
    let data2: { timestamp: number, value: number }[] = [];
    let data3: { timestamp: number, value: number }[] = [];
    let dateFrom = filter.dateFrom + minut5;
    let i = 0;
    let dateTo = filter.dateTo + 86400;
    do {
      let d1 = value - 3;
      if (d1 < min) d1 = min;
      if (i < 30 || i > 60) {
        data1.push({ timestamp: dateFrom, value: 20 });

        data2.push({ timestamp: dateFrom, value: 30 });
        data3.push({ timestamp: dateFrom, value: 40 })
      }
     
      dateFrom += minut5;
      i++;
    } while (dateFrom <= dateTo)

    let data = {
      reportData: [
        {
          reportDataTelemetry: data1,
          radarId: filter.radarIds ? 1 : null,

        },
      ]
    } as ReportHistoricalDataResponse;
    if (filter.radarIds) {
      data.reportData.push({ reportDataTelemetry: data2, radarId: 2 });
      data.reportData.push({ reportDataTelemetry: data3, radarId: 3 });
    }
    return data;
  }
}
