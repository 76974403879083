import { TreeNode } from 'primeng';
import { BinTypeEnum, SwitchTypeEnum } from "../enums";
import { AlarmViewModel, TemperatureRangeViewModel } from './temperature-range.model';
import { CableViewModel, CableSchemeViewModel, CableGetModel, AddEditCableSchemePostModel } from './cable.model';
import { KtxNotificationFilterNodeTypeEnum } from '../enums/ktx-notification-filter-type.enum';
import { NotificationFilterTreeNodeData } from './notifications.model';
import { CommonFunctions } from '../../common';

export class BinSchemeViewModel {
    public alarm: AlarmViewModel;
    public locationId: number;
    public id: number;
    public connectionId: number;
    public name: string;
    public binWithinUnitName: number;
    public binType: BinTypeEnum;
    public displaySchemes: boolean;
    public height: number;
    public width: number;
    public depth: number;
    public zIndex: number;
    public xPosition: number;
    public yPosition: number;
    public switchType: SwitchTypeEnum;
    public cables: CableSchemeViewModel[];
    public temperatureRanges: TemperatureRangeViewModel[];
    public wasDeviceInitialized: boolean;
    public isDeviceInitialized: boolean;
    public bins: BinSchemeViewModel[];
    public isDecorBin: boolean;
    public binColor: string;
    public averageBinTemperature: number;
}

export class BinSchemeGetModel {
    public alarm: AlarmViewModel;
    public locationId: number;
    public id: number;
    public name: string;
    public binType: BinTypeEnum;
    public displaySchemes: boolean;
    public height: number;
    public width: number;
    public depth: number;
    public zIndex: number;
    public xPosition: number;
    public yPosition: number;
    public cables: CableSchemeViewModel[];
    public temperatureRanges: TemperatureRangeViewModel[];
    public wasDeviceInitialized: boolean;
    public isDeviceInitialized: boolean;

    public static toViewMode(getModel: BinSchemeGetModel): BinSchemeViewModel {
        const viewModel = new BinSchemeViewModel();
        viewModel.alarm = getModel.alarm;
        viewModel.binType = getModel.binType;
        viewModel.cables = getModel.cables;
        viewModel.name = getModel.name;
        viewModel.locationId = getModel.locationId;
        viewModel.id = getModel.id;
        viewModel.zIndex = getModel.zIndex;
        viewModel.height = getModel.height;
        viewModel.width = getModel.width;
        viewModel.depth = getModel.depth;
        viewModel.displaySchemes = getModel.displaySchemes;
        viewModel.xPosition = getModel.xPosition;
        viewModel.yPosition = getModel.yPosition
        viewModel.temperatureRanges = getModel.temperatureRanges;
        viewModel.wasDeviceInitialized = getModel.wasDeviceInitialized;
        viewModel.isDeviceInitialized = getModel.isDeviceInitialized;
        return viewModel;
    }
}

export class BinViewModel {
    public locationId: number;
    public id: number;
    public name: string;
    public binWithinUnitName: number;
    public binType: BinTypeEnum;
    public displaySchemes: boolean;
    public height: number;
    public width: number;
    public depth: number;
    public zIndex: number;
    public xPosition: number;
    public yPosition: number;
    public wasDeviceInitialized: boolean;
    public isDeviceInitialized: boolean;
    public multiplexerId: number;
    public sysId: number;
    public ipAddress: string;
    public port: number;
    public portRemote: number;
    public switchType: SwitchTypeEnum;
    public cables: CableViewModel[];
    public bins: BinViewModel[];
    public isDecorBin: boolean;
    public averageBinTemperature: number;
    public binColor: string;

    public static toSchemeViewModel(viewModel: BinViewModel): BinSchemeViewModel {
        const schemeViewModel = new BinSchemeViewModel();

        schemeViewModel.locationId = viewModel.locationId;
        schemeViewModel.id = viewModel.id;
        schemeViewModel.name = viewModel.name;
        schemeViewModel.binWithinUnitName = viewModel.binWithinUnitName;
        schemeViewModel.binType = viewModel.binType;
        schemeViewModel.displaySchemes = viewModel.displaySchemes;
        schemeViewModel.height = viewModel.height;
        schemeViewModel.width = viewModel.width;
        schemeViewModel.depth = viewModel.depth;
        schemeViewModel.zIndex = viewModel.zIndex;
        schemeViewModel.xPosition = viewModel.xPosition;
        schemeViewModel.yPosition = viewModel.yPosition;
        schemeViewModel.wasDeviceInitialized = viewModel.wasDeviceInitialized;
        schemeViewModel.isDeviceInitialized = viewModel.isDeviceInitialized;
        schemeViewModel.switchType = viewModel.switchType;
        schemeViewModel.isDecorBin = viewModel.isDecorBin;
        schemeViewModel.binColor = viewModel.binColor;
        schemeViewModel.averageBinTemperature = viewModel.averageBinTemperature;

        if (viewModel.cables) {
            schemeViewModel.cables = viewModel.cables.map(s => CableViewModel.toSchemeViewModel(s));
        }

        if (viewModel.bins) {
            schemeViewModel.bins = viewModel.bins.map(s => BinViewModel.toSchemeViewModel(s));
        }

        return schemeViewModel;
    }
}

export class BinGetModel {
    public locationId: number;
    public id: number;
    public name: string;
    public binWithinUnitName: number;
    public binType: BinTypeEnum;
    public displaySchemes: boolean;
    public height: number;
    public width: number;
    public depth: number;
    public zIndex: number;
    public xPosition: number;
    public yPosition: number;
    public wasDeviceInitialized: boolean;
    public isDeviceInitialized: boolean;
    public multiplexerId: number;
    public sysId: number;
    public ipAddress: string;
    public port: number;
    public remotePort: number;
    public switchType: SwitchTypeEnum;
    public cables: CableGetModel[];
    public bins: BinGetModel[];
    public isDecorBin: boolean;

    public static toViewModel(getModel: BinGetModel) {
        const viewModel = new BinViewModel();

        viewModel.locationId = getModel.locationId;
        viewModel.id = getModel.id;
        viewModel.name = getModel.name;
        viewModel.binWithinUnitName = getModel.binWithinUnitName;
        viewModel.binType = getModel.binType;
        viewModel.displaySchemes = getModel.displaySchemes;
        viewModel.height = getModel.height;
        viewModel.width = getModel.width;
        viewModel.depth = getModel.depth;
        viewModel.zIndex = getModel.zIndex;
        viewModel.xPosition = getModel.xPosition;
        viewModel.yPosition = getModel.yPosition;
        viewModel.wasDeviceInitialized = getModel.wasDeviceInitialized;
        viewModel.isDeviceInitialized = getModel.isDeviceInitialized;
        viewModel.multiplexerId = getModel.multiplexerId;
        viewModel.sysId = getModel.sysId;
        viewModel.ipAddress = getModel.ipAddress;
        viewModel.port = getModel.port;
        viewModel.portRemote = getModel.remotePort;
        viewModel.switchType = getModel.switchType;
        viewModel.isDecorBin = getModel.isDecorBin;

        if (getModel.cables) {
            viewModel.cables = getModel.cables.map(c => CableGetModel.toViewModel(c));
        }

        if (getModel.bins) {
            viewModel.bins = getModel.bins.map(b => BinGetModel.toViewModel(b));
        }

        return viewModel;
    }
}

export class AddEditBinSchemePostModel {
    public name: string;
    public binType: BinTypeEnum;
    public height: number;
    public width: number;
    public depth: number;
    public zIndex: number;
    public xPosition: number;
    public yPosition: number;
    public ipAddress: string;
    public port: number;
    public portRemote: number;
    public switchType: SwitchTypeEnum;
    public sysId: number;
    public cables: AddEditCableSchemePostModel[];
    public bins: AddEditBinSchemePostModel[];
    public isDecorBin: boolean;

    constructor(viewModel: BinViewModel) {
        this.name = viewModel.name;
        this.binType = viewModel.binType;
        this.height = viewModel.height;
        this.width = viewModel.width;
        this.depth = viewModel.depth;
        this.zIndex = viewModel.zIndex;
        this.ipAddress = viewModel.ipAddress;
        this.port = +viewModel.port;
        this.portRemote = +viewModel.portRemote;
        this.switchType = viewModel.switchType;
        this.yPosition = viewModel.yPosition;
        this.xPosition = viewModel.xPosition;
        this.zIndex = viewModel.zIndex;
        this.sysId = viewModel.sysId;
        this.isDecorBin = viewModel.isDecorBin;

        if (viewModel.bins) {
            this.bins = viewModel.bins.map(b => new AddEditBinSchemePostModel(b));
        }

        if (viewModel.cables) {
            this.cables = viewModel.cables.map(c => new AddEditCableSchemePostModel(c));
        }
    }
}

export class BinConnectionPostModel {
    public sysId: number;
    public binIds: number[];
    public ipAddress: string;
    public port: number;
    public portRemote: number;
}

export class BinConnectionViewModel extends BinConnectionPostModel {
    public binListLabels: string;
    public withRemotePort: boolean;
}

export class EditBinConnectionPostModel {
    locationId: number;
    binConnections: BinConnectionPostModel[];
    constructor(locationId: number, binConnection: BinConnectionViewModel[]) {
        this.locationId = locationId;
        this.binConnections = binConnection;
    }
}

export class BinNameViewModel {
    id: number;
    name: string;
    bins: BinNameViewModel[];

    static toKtxTreeNode(bin: BinNameViewModel, locationId: number): TreeNode {
        const hasBins = !!bin.bins;
        const data: NotificationFilterTreeNodeData<KtxNotificationFilterNodeTypeEnum> = {
            id: bin.id,
            nodeType: hasBins ? KtxNotificationFilterNodeTypeEnum.Unit : KtxNotificationFilterNodeTypeEnum.Bin,
            locationId: locationId,
        };

        const binNodes: TreeNode = {
            label: bin.name,
            data,
            selectable: true,
            children: hasBins && bin.bins
                .map<TreeNode>(x => BinNameViewModel.toKtxTreeNode(x, locationId))
                .sort(CommonFunctions.compareTreeNodeLables)
        };

        return binNodes;
    }
}

export class BinNameGetModel {
    id: number;
    name: string;
    bins: BinNameGetModel[];

    public static toViewModel(getModel: BinNameGetModel): BinNameViewModel {
        const viewModel = new BinNameViewModel();

        viewModel.id = getModel.id;
        viewModel.name = getModel.name;

        if (getModel.bins) {
            viewModel.bins = getModel.bins.map(BinNameGetModel.toViewModel);
        }

        return viewModel;
    }
}
