import { Component, ViewChild } from '@angular/core';
import { CompaniesAddEditPopupComponent } from '../popups';
import { CompaniesDataSourceService } from '../../services';

@Component({
  selector: 'greensleeves-companies-tab',
  templateUrl: './companies-tab.component.html'
})

export class CompaniesTabComponent {
  @ViewChild(CompaniesAddEditPopupComponent, { read: false, static: false })
  _addModal: CompaniesAddEditPopupComponent;
  _gridData: Array<{ id: number, name: string, locations: string }>;

  constructor (private _companiesService: CompaniesDataSourceService) {}
  ngOnInit() {
    this._companiesService.get();
  }

  onClickAdd() {
    this._addModal.showAdd();
  }
}