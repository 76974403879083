
import { ModelsTypes } from "../../constants";
import { FullnessRangeViewModel } from "src/app/main/models/fullness-range.model";
import { ItxBinTypeEnum } from "../../enums";

export class LocationItxViewModel {
    public deviceId: string;
    public id: number;
    public locationName: string;
    public companyName: string;
    public port: number;
    public ipAddress: string;
    public itxBins: LocationItxBinNameModel[];
    public fullnessRanges: FullnessRangeViewModel[];

    //Device fields
    public isInitialized: boolean;
    public countOfBins: number;
    public acquisitionRate: number;

    //User behavior lock fields
    public isSomeoneUpdateLocation: boolean;

    //Infrastructure fields
    modelType = ModelsTypes.LocationItxViewModel;
    canDeviceBeInitialized: boolean;

    isSomeoneInViewStorages:boolean;
    isDeviceInInitialization: boolean;
}

export class LocationItxBinNameModel {
    public id: number;
    public name: string;
}

export class LocationItxGetModel {
    public deviceId: string;
    public id: number;
    public locationName: string;
    public companyName: string;
    public itxBins: LocationItxBinNameModel[];
    //Device fields
    public isInitialized: boolean;
    public countOfBins: number;
    public acquisitionRate: number;
    public port: number;
    public ipAddress: string;

    public static toViewModel(getModel: LocationItxGetModel): LocationItxViewModel {
        const viewModel = new LocationItxViewModel();
        viewModel.deviceId = getModel.deviceId;
        viewModel.id = getModel.id;
        viewModel.locationName = getModel.locationName;
        viewModel.companyName = getModel.companyName;
        viewModel.countOfBins = getModel.countOfBins;
        viewModel.acquisitionRate = getModel.acquisitionRate;
        viewModel.itxBins = getModel.itxBins;
        viewModel.port = getModel.port;
        viewModel.ipAddress = getModel.ipAddress;
        viewModel.isInitialized=getModel.isInitialized;

        return viewModel;
    }
}

export class LocationItxPostModel {
    public locationId: number;

    public ipAddress: string;
    public port: number;
    public acquisitionRate: number;

    constructor(location: LocationItxViewModel) {
        this.locationId = location.id;
        this.port = Number(location.port);
        this.ipAddress = location.ipAddress;
        this.acquisitionRate = Number(location.acquisitionRate);
    }
}

export class ItxLocationNameModel {
    id: number;
    name: string;
    bins: Array<ItxBinNameModel>
}

export class ItxBinNameModel {
    id: number;
    name: string;
    binType: ItxBinTypeEnum;
    material: string;
}