import { UserRoleEnum } from '../../enums';

export class LoginViewModel {
  public id: string;
  public role: UserRoleEnum;
  public authToken: string;
  public refreshToken: string;
  public expiresAt: number;
  public companyId: number;
  public projects: number | null;
}

export class LoginGetModel {
  public id: string;
  public role: number;
  public authToken: string;
  public refreshToken: string;
  public expiresAt: number;
  public companyId: number;
  public projects: number | null;

  public static toViewModel(getModel: LoginGetModel): LoginViewModel {
    const viewModel = new LoginViewModel();

    viewModel.id = getModel.id;
    viewModel.role = getModel.role;
    viewModel.authToken = getModel.authToken;
    viewModel.refreshToken = getModel.refreshToken;
    viewModel.expiresAt = getModel.expiresAt;
    viewModel.companyId = getModel.companyId;
    viewModel.projects = getModel.projects;

    return viewModel;
  }
}
