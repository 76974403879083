import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import {
  LocationViewModel,
  LocationPostModel,
  LocationAddedHubMessage,
  LocationDeletedHubMessage,
  LocationInUpdateHubMessage,
  LocationUpdatedHubMessage,
  LocationBtxViewModel, BtxKtxItxLocationsViewModel, LocationBtxGetModel,
  LocationKtxGetModel, LocationKtxViewModel,
  LocationItxViewModel, LocationItxGetModel
} from '../../models';
import { LocationsApiService, HubService } from '../http';
import { HubMethods } from '../../constants';

@Injectable()
export class LocationsDataSourceService {
  public locations$: BehaviorSubject<LocationViewModel[]> = new BehaviorSubject([]);
  public locationsForCompany$: BehaviorSubject<LocationViewModel[]> = new BehaviorSubject([]);
  public btxLocationsForCompany$: BehaviorSubject<LocationBtxViewModel[]> = new BehaviorSubject([]);
  public ktxLocationsForCompany$: BehaviorSubject<LocationKtxViewModel[]> = new BehaviorSubject([]);
  public itxLocationsForCompany$: BehaviorSubject<LocationItxViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(
    private _locationsApi: LocationsApiService,
    private _hubService: HubService,) {
    this.subscribeToHubService();
  }

  public async get() {
    try {
      const data = await this._locationsApi.getAll();
      this.locations$.next(data as LocationViewModel[]);
      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getForCompany(companyId: number) {
    try {
      const data = await this._locationsApi.getAllForCompany(companyId);
      if (data) {
        this.locationsForCompany$.next(data as LocationViewModel[]);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getKtxBtxItxLocationForCompany(companyId: number) {
    try {
      const data = await this._locationsApi.getKtxBtxItxLocationForCompany(companyId) as BtxKtxItxLocationsViewModel;

      if (data) {
        if (data.btxLocations) {
          const btxLocationViewModels = data.btxLocations.map(l => LocationBtxGetModel.toViewModel(l));
          this.btxLocationsForCompany$.next(btxLocationViewModels);
        }
        if (data.ktxLocations) {
          const ktxLocationViewModels = data.ktxLocations.map(l => LocationKtxGetModel.toViewModel(l));
          this.ktxLocationsForCompany$.next(ktxLocationViewModels);
        }
        if (data.itxLocations) {
          const itxLocationViewModels = data.itxLocations.map(l => LocationItxGetModel.toViewModel(l));
          this.itxLocationsForCompany$.next(itxLocationViewModels);
        }
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async add(location: LocationViewModel): Promise<LocationViewModel | ErrorModel> {
    try {
      const locationPostModel = new LocationPostModel(location);
      const result = await this._locationsApi.add(locationPostModel) as LocationViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.Location.locationAdded, new LocationAddedHubMessage(result));
        this.locations$.next([
          ...this.locations$.getValue(),
          result
        ]);
        return Promise.resolve(result);
      }

      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async edit(location: LocationViewModel): Promise<LocationViewModel | ErrorModel> {
    try {
      const locationPutModel = new LocationPostModel(location);
      const result = await this._locationsApi.edit(locationPutModel) as LocationViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.Location.locationUpdated, new LocationUpdatedHubMessage(result, true));
        this.locations$.next(this.locations$.getValue().map(l => l.id === result.id ? result : l));
        return Promise.resolve(result);
      }

      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  // TODO: will be moved to BTXLocation datasource
  public async getEquipment(locationId: number): Promise<LocationViewModel> {
    let location = this.locations$.getValue().find(x => x.id === locationId);
    if (!location) {
      await this.get();
      location = this.locations$.getValue().find(x => x.id === locationId);
    }
    const locationName = location.name;
    try {
      return {
        name: locationName,
      } as LocationViewModel;
    } catch (error) {
      return {
        id: locationId,
        name: locationName,
      } as LocationViewModel;
    }
  }

  public async delete(locationId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._locationsApi.delete(locationId);

      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.Location.locationDeleted, new LocationDeletedHubMessage(locationId));
        const locations = this.locations$.getValue().filter(l => l.id !== locationId);
        this.locations$.next(locations);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async markLocationForOthersAsInUpdate(locationId: number): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.Location.locationInUpdate, new LocationInUpdateHubMessage(locationId));
  }

  public async markLocationForOthersAsUpdated(location: LocationViewModel, withChanges: boolean): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.Location.locationUpdated, new LocationUpdatedHubMessage(location, withChanges));
  }

  private subscribeToHubService() {
    this._hubService.locationAdded.subscribe((hubMessage: LocationAddedHubMessage) => {
      if (hubMessage && hubMessage.newLocation) {
        this.locations$.next([
          ...this.locations$.getValue(),
          hubMessage.newLocation
        ]);
      }
    });
    this._hubService.locationDeleted.subscribe((hubMessage: LocationDeletedHubMessage) => {
      if (hubMessage) {
        const locations = this.locations$.getValue().filter(l => l.id !== hubMessage.locationId);
        this.locations$.next(locations);
      }
    });
    this._hubService.locationInUpdate.subscribe((hubMessage: LocationInUpdateHubMessage) => {
      if (hubMessage) {
        const location = this.locations$.getValue().find(l => l.id === hubMessage.locationId);
        if (location) {
          location.isSomeoneUpdateLocation = true;
        }
      }
    });
    this._hubService.locationUpdated.subscribe((hubMessage: LocationUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedLocation) {
        if (hubMessage.withCahnges) {

          hubMessage.updatedLocation.isSomeoneUpdateLocation = undefined;
          this.locations$.next(this.locations$.getValue().map(l => {
            if (l.id === hubMessage.updatedLocation.id) {
              return hubMessage.updatedLocation;
            } else {
              return l;
            }
          }));
        } else {
          const location = this.locations$.getValue().find(l => l.id == hubMessage.updatedLocation.id);
          location.isSomeoneUpdateLocation = false;
        }
      }
    });
  }
}