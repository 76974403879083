import { SensorPositionEnum, SensorTypeEnum, EquipmentSubTypeEnum, EquipmentSideEnum, EquipmentTypeEnum } from '../enums'

export const equipmentTypeLabels = {
    [EquipmentTypeEnum.Silo]: 'Silo',
    [EquipmentTypeEnum.Conveyor]: 'Conveyor',
    [EquipmentTypeEnum.Leg]: 'Leg',
    [EquipmentTypeEnum.Tripper]: 'Tripper',
    [EquipmentTypeEnum.Fan]: 'Fan',
}

export const equipmentSideLabels = {
    [EquipmentSideEnum.None]: '-',
    [EquipmentSideEnum.East]: 'East',
    [EquipmentSideEnum.West]: 'West',
    [EquipmentSideEnum.North]: 'North',
    [EquipmentSideEnum.South]: 'South'
}

export const equipmentNorthSouthSideLabels = {
    [EquipmentSideEnum.None]: '-',
    [EquipmentSideEnum.North]: 'North',
    [EquipmentSideEnum.South]: 'South'
}

export const equipmentWestEastSideLabels = {
    [EquipmentSideEnum.None]: '-',
    [EquipmentSideEnum.East]: 'East',
    [EquipmentSideEnum.West]: 'West'
}

export const equipmentSubTypeLabels = {
    [EquipmentSubTypeEnum.Small]: 'Small',
    [EquipmentSubTypeEnum.Medium]: 'Medium',
    [EquipmentSubTypeEnum.Large]: 'Large',
    [EquipmentSubTypeEnum.WithKneeBearing]: 'With knee bearing',
    [EquipmentSubTypeEnum.WithoutKneeBearing]: 'Without knee bearing',
    [EquipmentSubTypeEnum.Drag]: 'Drag',
    [EquipmentSubTypeEnum.Belt]: 'Belt',
    [EquipmentSubTypeEnum.Screw]: 'Screw',
    [EquipmentSubTypeEnum.CoveredBelt]: 'Covered belt',
    [EquipmentSubTypeEnum.None]: '-',
    [EquipmentSubTypeEnum.BeltDrive]: 'Belt Drive',
    [EquipmentSubTypeEnum.DirectDrive]: 'Direct Drive',
}

export const sensorTypeLabels = {
    [SensorTypeEnum.GrainDepth]: 'Grain depth',
    [SensorTypeEnum.BearingTemperature]: 'Bearing temperature',
    [SensorTypeEnum.RubBlockTemperature]: 'Rub block temperature',
    [SensorTypeEnum.Speed]: 'Speed',
    [SensorTypeEnum.ShaftBearingTemperature]: 'Shaft bearing temperature',
    [SensorTypeEnum.GearBoxTemperature]: 'Gear box temperature',
    [SensorTypeEnum.BottomRubTemperature]: 'Bottom rub temperature',
    [SensorTypeEnum.CurrentAmpDraw]: 'Current amp draw',
    [SensorTypeEnum.CurrentDesignLoad]: 'Current % design load',
    [SensorTypeEnum.Vibration]: 'Vibration',
}

export const sensorPositionLabels = {
    [SensorPositionEnum.CenterOfTheEquipmentScheme]: 'Center of the equipment scheme',
    [SensorPositionEnum.Head]: 'Head',
    [SensorPositionEnum.Knee]: 'Knee',
    [SensorPositionEnum.Boot]: 'Boot',
    [SensorPositionEnum.OnePerEquipment]: 'One per equipment',
    [SensorPositionEnum.Tail]: 'Tail',
    [SensorPositionEnum.BendHead]: 'Bend head',
    [SensorPositionEnum.BendTail]: 'Bend tail',
    [SensorPositionEnum.SnubHead]: 'Snub head',
    [SensorPositionEnum.SnubTail]: 'Snub tail',
    [SensorPositionEnum.KneeHead]: 'Knee head',
    [SensorPositionEnum.KneeTail]: 'Knee tail',
    [SensorPositionEnum.UpperTripperHead]: 'Upper tripper head',
    [SensorPositionEnum.UpperTripperTail]: 'Upper tripper tail',
    [SensorPositionEnum.LowerTripperHead]: 'Lower tripper head',
    [SensorPositionEnum.LowerTripperTail]: 'Lower tripper tail',
    [SensorPositionEnum.Upper]: 'Upper',
    [SensorPositionEnum.Lower]: 'Lower',
    [SensorPositionEnum.Top]: 'Top',
    [SensorPositionEnum.Frame]: 'Frame',
    [SensorPositionEnum.Drive]: 'Drive',
    [SensorPositionEnum.FrameUpper]: 'Frame Upper',
    [SensorPositionEnum.FrameLower]: 'Frame Lower',
    [SensorPositionEnum.DriveUpper]: 'Drive Upper',
    [SensorPositionEnum.DriveLower]: 'Drive Lower',
    [SensorPositionEnum.Motor]: 'Motor',
}