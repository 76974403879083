import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import {
  ServiceUserViewModel,
  ServiceUserPostModel,
  ServiceUserInviteModel,
  ServiceUserAddedHubMessage,
  ServiceUserDeletedHubMessage,
  ServiceUserInUpdateHubMessage,
  ServiceUserUpdatedHubMessage,
} from '../../models';
import { ServiceUsersApiService, HubService } from '../http';
import { HubMethods } from '../../constants';

@Injectable()
export class ServiceUsersDataSourceService {
  public serviceUsers$: BehaviorSubject<ServiceUserViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(
    private _serviceUsersApi: ServiceUsersApiService,
    private _hubService: HubService,) {
      this.subscribeToHubService();
    }

  public async get() {
    try {
      const serviceUsers = await this._serviceUsersApi.loadAll();
      if (serviceUsers) {
        this.serviceUsers$.next(serviceUsers as ServiceUserViewModel[]);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async invite(serviceUser: ServiceUserInviteModel) : Promise<ServiceUserViewModel|ErrorModel> {
    try {
      const result = await this._serviceUsersApi.invite(serviceUser) as ServiceUserViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.ServiceUser.serviceUserAdded, new ServiceUserAddedHubMessage(result));
        this.serviceUsers$.next([
          ...this.serviceUsers$.getValue(),
          result
        ]);
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async edit(serviceUser: ServiceUserPostModel) : Promise<boolean|ErrorModel> {
    try
    {
      const result = await this._serviceUsersApi.edit(serviceUser) as ServiceUserViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.ServiceUser.serviceUserUpdated, new ServiceUserUpdatedHubMessage(result, true));
        const serviceUsers = this.serviceUsers$.getValue().map(l => l.id === result.id ? { ...l, ...result} : l);
        this.serviceUsers$.next(serviceUsers);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async delete(serviceUserId: number) : Promise<boolean|ErrorModel> {
    try
    {
      const result = await this._serviceUsersApi.delete(serviceUserId);
      if (result) {
        this._hubService.sendToAllServiceUsers(HubMethods.ServiceUser.serviceUserDeleted, new ServiceUserDeletedHubMessage(serviceUserId));
        const serviceUsers = this.serviceUsers$.getValue().filter(l => l.id !== result);
        this.serviceUsers$.next(serviceUsers);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async markServiceUserForOthersAsInUpdate(userId: number): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.ServiceUser.serviceUserInUpdate, new ServiceUserInUpdateHubMessage(userId));
  }

  public async markServiceUserForOthersAsUpdated(serviceUser: ServiceUserViewModel, withChanges: boolean): Promise<any> {
    this._hubService.sendToAllServiceUsers(HubMethods.ServiceUser.serviceUserUpdated, new ServiceUserUpdatedHubMessage(serviceUser, withChanges));
  }

  private subscribeToHubService() {
    this._hubService.serviceUserAdded.subscribe((hubMessage: ServiceUserAddedHubMessage) => {
      if (hubMessage && hubMessage.newServiceUser) {
        this.serviceUsers$.next([
          ...this.serviceUsers$.getValue(),
          hubMessage.newServiceUser
        ]);
      }
    });
    this._hubService.serviceUserDeleted.subscribe((hubMessage: ServiceUserDeletedHubMessage) => {
      if (hubMessage) {
        const users = this.serviceUsers$.getValue().filter(l => l.id !== hubMessage.serviceUserId);
        this.serviceUsers$.next(users);
      }
    });
    this._hubService.serviceUserInUpdate.subscribe((hubMessage: ServiceUserInUpdateHubMessage) => {
      if (hubMessage) {
        const serviceUser = this.serviceUsers$.getValue().find(l => l.id === hubMessage.serviceUserId);
        if (serviceUser) {
          serviceUser.isSomeoneUpdateServiceUser = true;
        }
      }
    });
    this._hubService.serviceUserUpdated.subscribe((hubMessage: ServiceUserUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedServiceUser) {
        if (hubMessage.withChanges) {
          hubMessage.updatedServiceUser.isSomeoneUpdateServiceUser = undefined;
          this.serviceUsers$.next(this.serviceUsers$.getValue().map(l => l.id === hubMessage.updatedServiceUser.id ? hubMessage.updatedServiceUser : l));
        } else {
          const serviceUser = this.serviceUsers$.getValue().find(l => l.id == hubMessage.updatedServiceUser.id);
          serviceUser.isSomeoneUpdateServiceUser = false;
        }
      }
    });
  }
}