import { Component, ViewChild } from '@angular/core';

import { UserRoleEnum } from '../../../common';
import { TutorialMenuEnum, TutorialParagraphEnum } from '../../enums';
import { CompaniesDataSourceService, RoleService } from '../../services';
import { CompanyAdminAddPopupComponent, TutorialPopupComponent } from '../popups';

@Component({
  selector: 'greensleeves-company-admins-tab',
  templateUrl: './company-admins-tab.component.html'
})
export class CompanyAdminsTabComponent {
  @ViewChild(CompanyAdminAddPopupComponent, { read: false, static: false })
  _addModal: CompanyAdminAddPopupComponent;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  _role: UserRoleEnum;

  get _isServiceUser(): boolean {
    return this._role === UserRoleEnum.ServiceUser;
  }

  get isCompanyAdmin(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin);
  }

  constructor(
    private _companiesService: CompaniesDataSourceService,
    private _roleService: RoleService,
  ) { }

  ngOnInit() {
    if (this._isServiceUser) {
      this._companiesService.get();
    }
  }

  onClickAdd() {
    this._addModal.show();
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.UserManagement, TutorialParagraphEnum.AddCompanyAdmin);
  }
}
