import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ReadingScheduleType } from './../../../enums/';
import { LocationKtxViewModel } from './../../../models/';
import { LocationsKtxDataSourceService, RoleService } from './../../../services/';
import { LocationsDataSourceService } from '../../../services';
import { ErrorModel, ErrorCodeEnum, checkboxValidator, ConvertDataFunctions, UserRoleEnum, } from '../../../../common';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-ktx-add-edit-popup',
  templateUrl: './ktx-add-edit-popup.component.html',
  styles: []
})
export class KtxLocationAddEditPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy {
  private static SECONDS_IN_DAY = 86400;
  _isAddPopup = true;
  _isHidden = true;
  _submitted = false;
  _isCycleSchedule = true;
  _isUniqueDeviceError = false;
  _location: LocationKtxViewModel;
  _locationForm: FormGroup;
  _cycleDate: string = '';
  _scheduleDate: string = '';
  _selectedDays: ReadingScheduleType[] = [];
  _locations: { label: string, value: number }[];
  _daysControlName = 'days';
  _cycleControlName = 'cycleDate';
  _scheduleControlName = 'scheduleDate';
  _isLoading = false;

  private _subscriptions: Subscription[] = [];

  get locationForm(): FormGroup {
    if (this._isCycleSchedule) {
      this._locationForm.controls[this._daysControlName].disable();
      this._locationForm.controls[this._scheduleControlName].disable();
      this._locationForm.controls[this._cycleControlName].enable();
    } else {
      this._locationForm.controls[this._cycleControlName].disable();
      this._locationForm.controls[this._scheduleControlName].enable();
      this._locationForm.controls[this._daysControlName].enable();
    }
    return this._locationForm;
  }

  get isServiceUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.ServiceUser);
  }
  
  constructor(
    private _formBuilder: FormBuilder,
    private _locationsService: LocationsDataSourceService,
    private _locationKtxService: LocationsKtxDataSourceService,
    private _cdr: ChangeDetectorRef,
    private _roleService: RoleService,
  ) {
    super();
  }

  ngOnInit() {
    this._location = new LocationKtxViewModel();

    this._locationForm = this._formBuilder.group({
      isCycle: [this._isCycleSchedule, Validators.required],
      locationId: [this._location.id, Validators.required],
      days: [this._selectedDays, checkboxValidator],
      cycleDate: [this._cycleDate, Validators.required],
      scheduleDate: [this._scheduleDate, Validators.required],
    });

    let subscription = this._locationsService.locations$.subscribe((data) => {
      if (data) {
        this._locations = data.map((x) => ({ label: x.name, value: x.id }));
      }
    });

    let cycleDateSub = this._locationForm.controls['cycleDate'].valueChanges.subscribe((newValue: string) => {
      if(newValue) {
        const values = newValue.split(':');
        if(values[0] === '0000') {
          const splittedString = this._cycleDate.split(':');
          splittedString[0] = '0001';
          this._cycleDate = splittedString.join(':');
        } else if(+values[0] > 8760) {
          const splittedString = this._cycleDate.split(':');
          splittedString[0] = '8760';
          this._cycleDate = splittedString.join(':');
        }
      }
    });
    this._subscriptions.push(subscription, cycleDateSub);
  }

  ngOnDestroy() {
    this._subscriptions &&
      this._subscriptions.forEach((sub) => sub.unsubscribe());
  }
 
  async onSubmit() {
    this._submitted = true;

    if (this._locationForm.invalid) {
      return;
    }

    this._isLoading = true;

    const value = {
      ...this._location,
      ...this._locationForm.value,
    } as LocationKtxViewModel;

    let utcSecondsInDay;
    let selectedDaysToAdd: ReadingScheduleType[];
    if(!this._isCycleSchedule) {
      utcSecondsInDay = this.getUtcTimeSecondsWithExcess(this._scheduleDate);
      selectedDaysToAdd = this.convertSelectedDaysToUtc(utcSecondsInDay, this._selectedDays);
      utcSecondsInDay = this.removeExcessSeconds(utcSecondsInDay);
    }

    value.readingScheduleType = this._isCycleSchedule
      ? 0
      : selectedDaysToAdd.reduce((a, b) => a | b, 0);

    value.scheduleTime = this._isCycleSchedule
        ? this._cycleDate
        : ConvertDataFunctions.secondsToString(utcSecondsInDay);

    const result = !!this._isAddPopup
      ? await this._locationKtxService.add(value)
      : await this._locationKtxService.edit(value);

    if (result instanceof ErrorModel) {
      if (result.code === ErrorCodeEnum.RaspberryDeviceAlreadyAssigned) {
        this._isUniqueDeviceError = true;
      }
    } else if (result) {
      if (!this._isAddPopup) {
        await this._locationKtxService.markLocationKtxForOthersAsUpdated(this._location, true);
      }
      this._location = new LocationKtxViewModel();
      this._isHidden = true;
      this._submitted = false;
      this._isUniqueDeviceError = false;
      this._locationForm.reset();
      this._selectedDays = [];
      this._isLoading = false;
    }
  }

  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  onLocationTouch() {
    if(this._isUniqueDeviceError) {
      this._isUniqueDeviceError = false;
    }
  }

  async onClose() {
    this._isHidden = true;
    this._selectedDays = [];
    this._cycleDate = '';
    this._scheduleDate = '';
    if(!this._isAddPopup) {
      await this._locationKtxService.markLocationKtxForOthersAsUpdated(this._location, false);
    }
    this._location = new LocationKtxViewModel();
  }

  public showAdd() {
    this._isAddPopup = true;
    this._isHidden = false;
  }

  public showEdit(location: LocationKtxViewModel) {
    this._isHidden = false;
    this._isAddPopup = false;

    this._location = Object.create(location);
    this._selectedDays = this.getSelectedDays(location.readingScheduleType);
    this._isCycleSchedule = location.readingScheduleType === 0;

    if (this._isCycleSchedule) {
      this._cycleDate = location.scheduleTime;
    } else {
      let localTimeSeconds = this.getLocalTimeSecondsWithExcess(location.scheduleTime);
      this._selectedDays = this.convertSelectedDaysToUtc(localTimeSeconds, this._selectedDays);

      localTimeSeconds = this.removeExcessSeconds(localTimeSeconds);
      this._scheduleDate = ConvertDataFunctions.secondsToString(localTimeSeconds);
    }
    this._cdr.detectChanges();
  }

  getEnumValue(index: string): number {
    return ReadingScheduleType[index];
  }

  // Get Selected days from a number;
  private getSelectedDays(days: number): ReadingScheduleType[] {
    const result = [];

    if ((days & ReadingScheduleType.Mon) === ReadingScheduleType.Mon) {
      result.push(ReadingScheduleType.Mon);
    }
    if ((days & ReadingScheduleType.Tue) === ReadingScheduleType.Tue) {
      result.push(ReadingScheduleType.Tue);
    }
    if ((days & ReadingScheduleType.Wed) === ReadingScheduleType.Wed) {
      result.push(ReadingScheduleType.Wed);
    }
    if ((days & ReadingScheduleType.Thu) === ReadingScheduleType.Thu) {
      result.push(ReadingScheduleType.Thu);
    }
    if ((days & ReadingScheduleType.Fri) === ReadingScheduleType.Fri) {
      result.push(ReadingScheduleType.Fri);
    }
    if ((days & ReadingScheduleType.Sat) === ReadingScheduleType.Sat) {
      result.push(ReadingScheduleType.Sat);
    }
    if ((days & ReadingScheduleType.Sun) === ReadingScheduleType.Sun) {
      result.push(ReadingScheduleType.Sun);
    }
    
    return result;
  }

  private getUtcTimeSecondsWithExcess(localTime: string): number {
    const date = new Date();
    const localOffsetSeconds = date.getTimezoneOffset() * 60;
    const utcTime = ConvertDataFunctions.stringToSeconds(localTime) + localOffsetSeconds;

    return utcTime;
  }

  private getLocalTimeSecondsWithExcess(utcTime: string): number {
    const date = new Date();
    const localOffsetSeconds = date.getTimezoneOffset() * 60;
    const localTime = ConvertDataFunctions.stringToSeconds(utcTime) - localOffsetSeconds;

    return localTime;
  }

  // Remove excess from seconds if exists (more than 86400 or less than 0)
  private removeExcessSeconds(seconds: number): number {
    const secondsInDay = KtxLocationAddEditPopupComponent.SECONDS_IN_DAY;
    const secondsWithoutOffset = (seconds < 0 && secondsInDay + seconds) || (seconds > secondsInDay && seconds - secondsInDay) || seconds;

    return secondsWithoutOffset;
  }

  private convertSelectedDaysToUtc(seconds: number, selectedDays: ReadingScheduleType[]): ReadingScheduleType[] {
    const result = Object.assign(selectedDays);

    if(seconds < 0) {
      for(let i = 0; i < result.length; i++) {

        if(result[i] === ReadingScheduleType.Mon) {
          result[i] = ReadingScheduleType.Sun;
          continue;
        }

        result[i] = result[i] / 2;
      }
    } else if (seconds > KtxLocationAddEditPopupComponent.SECONDS_IN_DAY) {
      for(let i = 0; i < result.length; i++) {

        if(result[i] === ReadingScheduleType.Sun) {
          result[i] = ReadingScheduleType.Mon;
          continue;
        }

        result[i] = result[i] * 2;
      }
    }
    return result;
  }
}
