import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CompanyUserPostModel, CompanyUserViewModel } from '../../../models';
import { CompanyUsersDataSourceService, LocationsDataSourceService } from '../../../services';
import { LocalStorageService, UserRoleEnum } from '../../../../common';
import { UserStatusEnum } from '../../../enums';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-company-user-edit-popup',
  templateUrl: './company-user-edit-popup.component.html',
  styles: []
})
export class CompanyUserEditPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked {
  _isHidden = true;
  _submitted = false;
  _noLocationSelected = false;
  _companyUser: CompanyUserViewModel;
  _companyUserForm: FormGroup;
  _selectedlocatinos;
  _selectedBtxLocations;
  _selectedKtxLocations;
  _selectedItxLocations;
  _selectedStatus;
  _btxLocations: { label: string, value: number }[];
  _ktxLocations: { label: string, value: number }[];
  _itxLocations: { label: string, value: number }[];
  private _locationsSubscription: Subscription;

  _role: UserRoleEnum;

  get _isCompanyAdmin() {
    return this._role === UserRoleEnum.CompanyAdmin;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _companyUsersService: CompanyUsersDataSourceService,
    private _locationsService: LocationsDataSourceService,
    private _localStorageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this._locationsSubscription = this._locationsService.btxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._btxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    this._locationsSubscription = this._locationsService.ktxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._ktxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    this._locationsSubscription = this._locationsService.itxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._itxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    const loginInfo = this._localStorageService.getLoginInfo();
    this._role = loginInfo ? loginInfo.role : null;
  }

  ngAfterViewChecked() {
    super.ngAfterViewChecked();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._locationsSubscription && this._locationsSubscription.unsubscribe();
    this._locationsSubscription = null;
  }

  public show(companyUser: CompanyUserViewModel) {
    this._companyUser = companyUser;
    this._selectedStatus = UserStatusEnum[companyUser.status];
    this._selectedBtxLocations = this._companyUser.arduinoLocations.map(l => l.id);
    this._selectedKtxLocations = this._companyUser.raspberryLocations.map(l => l.id);
    this._selectedItxLocations = this._companyUser.itxLocations.map(l => l.id)
    this._selectedlocatinos = this._selectedBtxLocations + this._selectedKtxLocations + this._selectedItxLocations;

    this._companyUserForm = this._formBuilder.group({
      firstName: [
        this._companyUser.firstName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      lastName: [
        this._companyUser.lastName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      ...(this._isCompanyAdmin ? {
        btxLocationIds: [
          this._selectedBtxLocations,
          Validators.compose([
          ])
        ],
      } : {}),
      ...(this._isCompanyAdmin ? {
        ktxLocationIds: [
          this._selectedKtxLocations,
          Validators.compose([
          ])
        ],
      } : {}),
      ...(this._isCompanyAdmin ? {
        itxLocationIds: [
          this._selectedItxLocations,
          Validators.compose([
          ])
        ],
      } : {}),
      ...(this._isCompanyAdmin ? {
        status: [
          this._companyUser.status,
          Validators.compose([
            Validators.required
          ])
        ],
      } : {})
    });
    this._isHidden = false;
  }

  async onSubmit() {
    this._submitted = true;
    this._noLocationSelected = false;

    let value = {
      ...this._companyUserForm.value,
      status: UserStatusEnum[this._companyUserForm.value.status],
      id: this._companyUser.id,
      ...(this._isCompanyAdmin ? {
        locationIds: this._companyUserForm.value.locationIds
      } : {})
    } as CompanyUserPostModel;
    if (this._companyUserForm.invalid) {
      if ((!value.btxLocationIds || value.btxLocationIds.length === 0) && (!value.ktxLocationIds || value.ktxLocationIds.length === 0) && (!value.itxLocationIds || value.itxLocationIds.length === 0)) {
        this._noLocationSelected = true;
      }

      return;
    }

    if ((!value.btxLocationIds || value.btxLocationIds.length === 0) && (!value.ktxLocationIds || value.ktxLocationIds.length === 0) && (!value.itxLocationIds || value.itxLocationIds.length === 0)) {
      this._noLocationSelected = true;
      return;
    }

    try {
      const result = await this._companyUsersService.edit(value);
      if (result) {
        this._isHidden = true;
        this._submitted = false;
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  async onCancel() {
    this._companyUsersService.markCompanyUserForOthersAsUpdated(this._companyUser, false);
    this._noLocationSelected = false;
    this._submitted = false;
    this._isHidden = true;
    this._selectedStatus = null;
    this._companyUser = null;
    this._companyUserForm.reset;
    this.ngOnDestroy();
  }
}
