import { AqsSessionTypeEnum, AqsRoundStorageHopperTypeEnum, ReportFormatEnum } from './../../../enums';
import {
    AqsAirflowViewModel, AqsCommoditiesViewModel, AqsCoverSheetModel, AqsExhausterViewModel,
    AqsFlushFloorViewModel, AqsRoundStorageDuctDesignModel, IncludeFlowOption
} from '@models';

export class QuoteRoundStorageViewModel {
    id: number;
    coverSheet: AqsCoverSheetModel;
    roundStorage: AqsRoundStorageWiewModel;
    commodities: AqsCommoditiesViewModel[];
    session: AqsSessionTypeEnum;
    airflowConfiguration: AqsAirflowViewModel;
    includeFlowOption: IncludeFlowOption;
    exhausterSheet: AqsExhausterViewModel;
    flushFloorDesign: AqsFlushFloorViewModel;
    userId: number;
    ductDesign: AqsRoundStorageDuctDesignModel;
    linkComputationSheetId: number;
    constructor() {
        this.id = 0;
        this.linkComputationSheetId = null;
    }
}

export class QuoteRoundStorageReportModel {
    coverSheet: AqsCoverSheetModel;
    roundStorage: AqsRoundStorageWiewModel;
    commodities: AqsCommoditiesViewModel[];
    airflowConfiguration: AqsAirflowViewModel;
    includeFlowOption: IncludeFlowOption;
    exhausterSheet: AqsExhausterViewModel;
    flushFloorDesign: AqsFlushFloorViewModel;
    ductDesign: AqsRoundStorageDuctDesignModel;
    reportFormat: ReportFormatEnum;
    timeZone: string;
    printDate: Date;
}

export class AqsRoundStorageWiewModel {
    id: number;
    diameter: number; //DIA
    eaveHeight: number; //HEIGHT
    capDiameter: number; //CAP
    steamwall: number; //SW
    ledge: number; //LEDGE
    roofPitch: number; //PITCH
    hopperPitch: number; //HOPPITCH
    outletDiameter: number; //OUTLET
    ovlHeight: number; //OHEIGHT
    reposeAngle: number; //REPOSE
    hopperType: AqsRoundStorageHopperTypeEnum; //HOPPERTYPE
    effDepth: number; //EFFDEPTH
    capacity: number; //CAPACITY

    constructor() {
        this.id = 0;
        this.diameter = 40;
        this.eaveHeight = 30;
        this.capDiameter = 2;
        this.steamwall = 0;
        this.ledge = 0;
        this.roofPitch = 30;
        this.hopperPitch = 0;
        this.outletDiameter = 0;
        this.calculateAndSetOvlHeight();
        //CINT(((TAN(PITCH * PI / 180) * (DIA / 2 - CAP / 2)) + HEIGHT) * 10)/10
        this.reposeAngle = 27;
        this.hopperType = AqsRoundStorageHopperTypeEnum.Conical;
        this.calculateAndSetDepthAndCapacity();
    }

    public calculateAndSetOvlHeight() {
        this.ovlHeight = Math.round(((Math.tan(this.roofPitch * Math.PI / 180) * (this.diameter / 2 - this.capDiameter / 2)) + this.eaveHeight) * 10) / 10;
        //CINT(( (TAN(PITCH * PI / 180) * (DIA / 2 - CAP / 2)) + HEIGHT) * 10)/10
    }

    public calculateAndSetRoofPich() {
        this.roofPitch = Math.round(Math.atan((this.ovlHeight - this.eaveHeight) / (this.diameter / 2 - this.capDiameter / 2)) * 180 / Math.PI * 10) / 10
        //CINT( ATN((OHEIGHT - HEIGHT) / (DIA / 2 - CAP / 2)) * 180 / PI * 10 ) / 10
        if (this.roofPitch < this.reposeAngle || this.reposeAngle < 0) {
            this.reposeAngle = this.roofPitch;
        }
    }

    public calculateAndSetDepthAndCapacity() {
        [this.effDepth, this.capacity] = this.getCalulatedDepthAndCapacity();
    }

    public getCalulatedDepthAndCapacity(): [number, number] {
        let effDepth: number;
        let capacity: number;
        if (this.hopperType == AqsRoundStorageHopperTypeEnum.Conical) {
            capacity = .6311 / 3 * Math.pow(this.diameter - this.ledge * 2, 2) * (Math.tan(this.hopperPitch * Math.PI / 180) * (this.diameter / 2 - this.ledge));
            //CAPACITY = (.6311 / 3 * (DIA - LEDGE * 2) ^ 2 * (TAN(HOPPITCH * PI / 180) * (DIA / 2 - LEDGE)))
            capacity = capacity - (.6311 / 3 * Math.pow(this.outletDiameter, 2) * (Math.tan(this.hopperPitch * Math.PI / 180) * this.outletDiameter / 2));
            //CAPACITY = CAPACITY - (.6311 / 3 * OUTLET ^ 2 * (TAN(HOPPITCH * PI / 180) * OUTLET / 2))

            effDepth = Math.tan(this.hopperPitch * Math.PI / 180) * (this.diameter / 2 - this.ledge - this.outletDiameter / 2);
            //EFFDEPTH = (TAN(HOPPITCH * PI / 180)) * (DIA / 2 - LEDGE - OUTLET / 2)
            effDepth = effDepth * ((this.outletDiameter / this.diameter) + ((this.diameter - 2 * this.ledge - this.outletDiameter) / (this.diameter * 3)));
            //EFFDEPTH = EFFDEPTH * ((OUTLET / DIA) + ((DIA - 2 * LEDGE - OUTLET) / (3 * DIA)))
        }
        else {
            capacity = .6311 / 2 * Math.pow(this.diameter - this.ledge * 2, 2) * (Math.tan(this.hopperPitch * Math.PI / 180) * (this.diameter - this.ledge * 2));
            // CAPACITY = (.6311 / 2 * (DIA - LEDGE * 2) ^ 2 * (TAN(HOPPITCH * PI / 180) * (DIA - LEDGE * 2)))
            capacity = capacity - (.6311 / 2 * Math.pow(this.outletDiameter, 2) * (Math.tan(this.hopperPitch * Math.PI / 180) * this.outletDiameter));
            //CAPACITY = CAPACITY - (.6311 / 2 * OUTLET ^ 2 * (TAN(HOPPITCH * PI / 180) * OUTLET))

            effDepth = Math.tan(this.hopperPitch * Math.PI / 180) * (this.diameter - 2 * this.ledge - this.outletDiameter);
            //EFFDEPTH = (TAN(HOPPITCH * PI / 180)) * (DIA - 2 * LEDGE - OUTLET)
            effDepth = effDepth * (this.outletDiameter / this.diameter + ((this.diameter - 2 * this.ledge - this.outletDiameter) / (this.diameter * 2)));
            //EFFDEPTH = EFFDEPTH * ((OUTLET / DIA) + ((DIA - 2 * LEDGE - OUTLET) / (2 * DIA)))
        }
        capacity = capacity + .6311 * Math.pow(this.diameter, 2) * this.eaveHeight + (.6311 / 3 * Math.pow(this.diameter, 2) * (Math.tan(this.reposeAngle * Math.PI / 180) * this.diameter / 2)) - ((Math.tan(this.reposeAngle * Math.PI / 180) * this.capDiameter / 2) * .6311 / 3 * Math.pow(this.capDiameter, 2));
        //CAPACITY = CAPACITY + (.6311 * DIA ^ 2 * HEIGHT) + (.6311 / 3 * DIA ^ 2 * (TAN(REPOSE * PI / 180) * DIA / 2)) - ((TAN(REPOSE * PI / 180) * CAP / 2) * .6311 / 3 * CAP ^ 2)
        capacity = capacity + (.6311 * Math.pow(this.diameter, 2) * this.steamwall);
        //CAPACITY = (.6311 * DIA ^ 2 * SW) + CAPACITY
        let ghght = Math.tan(this.reposeAngle * Math.PI / 180) * this.diameter / 2;
        //GHGHT =TAN(REPOSE * PI / 180) * (DIA / 2)
        let ohght = Math.tan(this.roofPitch * Math.PI / 180) * (this.diameter - this.capDiameter) / 2;
        // OHGHT = TAN(PITCH * PI / 180) * ((DIA - CAP) / 2)
        let gcap = ghght > ohght ? (1 / Math.tan(this.reposeAngle * Math.PI / 180)) * (ghght - ohght) * 2 : 0;
        //IF GHGHT > OHGHT THEN GCAP = (1 / TAN(REPOSE * PI / 180)) * (GHGHT - OHGHT) * 2 ELSE GCAP = 0
        if (gcap > this.capDiameter) gcap = this.capDiameter;
        //IF GCAP > CAP THEN GCAP = CAP
        if (gcap != 0) capacity = capacity - (.6311 / 3 * Math.pow(gcap, 2) * (Math.tan(this.reposeAngle * Math.PI) * gcap / 2));
        // IF GCAP <> 0 THEN CAPACITY = CAPACITY - (.6311 / 3 * GCAP ^ 2 * (TAN(REPOSE * PI / 180) * GCAP / 2))
        effDepth = effDepth + this.eaveHeight + this.steamwall;
        // EFFDEPTH = EFFDEPTH + HEIGHT + SW
        effDepth = effDepth + (((this.diameter - gcap) / (this.diameter * 3)) + (gcap / this.diameter)) * (Math.tan(this.reposeAngle * Math.PI / 180) * ((this.diameter - gcap) / 2));
        // EFFDEPTH = EFFDEPTH + (((DIA - GCAP) / (3 * DIA)) + (GCAP / DIA)) * (TAN(REPOSE * PI / 180) * ((DIA - GCAP) / 2))
        effDepth = Math.round(effDepth * 10) / 10;
        // EFFDEPTH = INT(EFFDEPTH * 10 + .5) / 10
        capacity = Math.round(capacity * 10) / 10;
        // CAPACITY = INT(CAPACITY * 10 + .5) / 10
        if (isNaN(effDepth)) effDepth = 0;
        if (isNaN(capacity)) capacity = 0;
        return [effDepth, capacity];
    }
}
