import { switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AqsPageLabel } from '../../constants';
import { AqsFlushDuctDesignEnum, AqsPagesEnum, AqsSessionTypeEnum } from '../../enums';
import { AqsAirflowDatasourceService, AqsQuoteStorageDataSourceService } from '../../services';
import {
  AqsAirflowViewModel, AqsCoverSheetModel, AqsExhausterViewModel, AqsFlushFloorViewModel,
  AqsRoundStorageWiewModel, IncludeFlowOption, AqsSelectedFanModel, AqsRoundStorageDuctDesignModel, QuoteRoundStorageViewModel, QuoteRoundStorageReportModel
} from './../../models';
import { ErrorCodeEnum, ErrorModel } from '../../../common';
import { AqsCoverSheetComponent } from '../common';
import { AqsComputationSheetResavePopupComponent, ComputationSheetReportPopupComponent, ComputationSheetShareReportPopupComponent } from '@popups';

@Component({
  selector: 'greensleeves-aqs-round-storage-tab',
  templateUrl: './aqs-round-storage-tab.component.html',
  styles: []
})
export class AqsRoundStorageTabComponent implements OnInit {
  @ViewChild(AqsCoverSheetComponent, { read: false, static: false })
  private coverSheetComponent: AqsCoverSheetComponent;

  @ViewChild(ComputationSheetReportPopupComponent, { read: false, static: false })
  private _exportReportModal: ComputationSheetReportPopupComponent;

  @ViewChild(AqsComputationSheetResavePopupComponent, { read: false, static: false })
  private reSaveModal: AqsComputationSheetResavePopupComponent;

  @ViewChild(ComputationSheetShareReportPopupComponent, { read: false, static: false })
  private _shareReportModal: ComputationSheetShareReportPopupComponent;

  _pageIndex: AqsPagesEnum;
  _quoteRoundStorage: QuoteRoundStorageViewModel;
  _coverSheetPageIndex: AqsPagesEnum = AqsPagesEnum.CoverSheet;
  _pages: { index: AqsPagesEnum, label: string }[] = [];
  _currentPageError: boolean = false;
  _pageEnum = AqsPagesEnum;
  _isSaveRun: boolean = false;
  _isLoad: boolean = false;
  _isSuccessSaved: boolean = false;
  _isChangedTables: boolean = false;

  private title: string = 'Save Round Storage Quote';

  constructor(private _aqsAirflowService: AqsAirflowDatasourceService,
    private _route: ActivatedRoute, private _quoteDataService: AqsQuoteStorageDataSourceService,

  ) { }

  async ngOnInit() {
    this._aqsAirflowService.getAllFanTypesWithFans();
    this._quoteDataService.getAllComputationSheetForBinding();
    this._isLoad = true;

    this._pages.push({ index: AqsPagesEnum.RoundStorageConfiguration, label: AqsPageLabel[AqsPagesEnum.RoundStorageConfiguration] });
    this._quoteRoundStorage = new QuoteRoundStorageViewModel();
    this._quoteRoundStorage.roundStorage = new AqsRoundStorageWiewModel();
    this._quoteRoundStorage.commodities = [];
    this._quoteRoundStorage.session = AqsSessionTypeEnum.RoundSessionType;
    let quoteId: number;
    this._route.queryParams.subscribe((queryParam: any) => {
      quoteId = queryParam['id'];
    });

    if (!isNaN(quoteId)) {
      let result = await this._quoteDataService.getRoundFlatStorage(quoteId);
      if (result) {
        if (!(result instanceof ErrorModel)) {
          this.fillQuoteStorage(result);
          this.fillPagesList();
        }
        else if (result instanceof ErrorModel && (result.code == ErrorCodeEnum.PermissionError || result.code == ErrorCodeEnum.QuoteNotFound)) {
          console.log(result);
        }
      }
    }
    this._pageIndex = AqsPagesEnum.RoundStorageConfiguration;
    this._isLoad = false;
  }

  fillQuoteStorage(result: QuoteRoundStorageViewModel) {
    let quote = result as QuoteRoundStorageViewModel;
    this._quoteRoundStorage.id = quote.id;
    this._quoteRoundStorage.session = quote.session;
    this._quoteRoundStorage.linkComputationSheetId = quote.linkComputationSheetId;
    this._quoteRoundStorage.airflowConfiguration = new AqsAirflowViewModel();
    Object.assign(this._quoteRoundStorage.airflowConfiguration, result.airflowConfiguration);
    this._quoteRoundStorage.airflowConfiguration.airflowData = [];
    Object.assign(this._quoteRoundStorage.airflowConfiguration.airflowData, result.airflowConfiguration.airflowData);
    this._quoteRoundStorage.airflowConfiguration.selectedFan = result.airflowConfiguration.selectedFan;
    this._quoteRoundStorage.coverSheet = new AqsCoverSheetModel();
    Object.assign(this._quoteRoundStorage.coverSheet, result.coverSheet);
    this._quoteRoundStorage.commodities = [];
    Object.assign(this._quoteRoundStorage.commodities, result.commodities);

    if (result.exhausterSheet) {
      this._quoteRoundStorage.exhausterSheet = new AqsExhausterViewModel();
      Object.assign(this._quoteRoundStorage.exhausterSheet, result.exhausterSheet);
    }

    if (result.flushFloorDesign) {
      this._quoteRoundStorage.flushFloorDesign = new AqsFlushFloorViewModel();
      Object.assign(this._quoteRoundStorage.flushFloorDesign, result.flushFloorDesign);
    }

    if (result.ductDesign) {
      this._quoteRoundStorage.ductDesign = new AqsRoundStorageDuctDesignModel();
      Object.assign(this._quoteRoundStorage.ductDesign, result.ductDesign);
    }

    this._quoteRoundStorage.roundStorage = new AqsRoundStorageWiewModel();
    Object.assign(this._quoteRoundStorage.roundStorage, result.roundStorage);
    this._quoteRoundStorage.includeFlowOption = new IncludeFlowOption();
    Object.assign(this._quoteRoundStorage.includeFlowOption, result.includeFlowOption);
    this._quoteRoundStorage.includeFlowOption.includeDesign = result.includeFlowOption.includeDesign;
    this._pageIndex = AqsPagesEnum.RoundStorageConfiguration;
  }

  fillPagesList() {
    if (this._quoteRoundStorage.airflowConfiguration)
      this._pages.push({ index: AqsPagesEnum.DetermineTargetAirflow, label: AqsPageLabel[AqsPagesEnum.DetermineTargetAirflow] });
    if (this._quoteRoundStorage.exhausterSheet)
      this._pages.push({ index: AqsPagesEnum.ExhausterRecommendations, label: AqsPageLabel[AqsPagesEnum.ExhausterRecommendations] });
    if (this._quoteRoundStorage.flushFloorDesign)
      this._pages.push({ index: AqsPagesEnum.FlushFloorDesign, label: AqsPageLabel[AqsPagesEnum.FlushFloorDesign] });
    if (this._quoteRoundStorage.ductDesign)
      this._pages.push({ index: AqsPagesEnum.DuctDesign, label: AqsPageLabel[AqsPagesEnum.DuctDesign] });
    if (this._quoteRoundStorage.coverSheet)
      this._pages.push({ index: AqsPagesEnum.CoverSheet, label: AqsPageLabel[AqsPagesEnum.CoverSheet] });
  }

  onChangePage(newPageIndex: AqsPagesEnum) {
    this._pageIndex = newPageIndex;
  }

  onBackPage() {
    let currentPageindex = this._pages.findIndex(p => p.index == this._pageIndex);
    if (currentPageindex != 0) {
      this._pageIndex = this._pages[--currentPageindex].index;
    }
  }

  onNextPage() {
    switch (this._pageIndex) {
      case AqsPagesEnum.RoundStorageConfiguration:
        if (this.checkCurrentPage()) {
          if (!this._quoteRoundStorage.airflowConfiguration) {
            this._quoteRoundStorage.commodities.sort((a, b) => { return a.name.localeCompare(b.name) });
            this._quoteRoundStorage.airflowConfiguration = new AqsAirflowViewModel();
          }
          if (!this._quoteRoundStorage.includeFlowOption) this._quoteRoundStorage.includeFlowOption = new IncludeFlowOption();
          this.addedNextPage(AqsPagesEnum.DetermineTargetAirflow);
        }
        break;
      case AqsPagesEnum.DetermineTargetAirflow:
        if (this.checkCurrentPage()) {
          if (this._quoteRoundStorage.includeFlowOption.includeExhauster) {
            if (!this._quoteRoundStorage.exhausterSheet) this._quoteRoundStorage.exhausterSheet = new AqsExhausterViewModel();
            this.addedNextPage(AqsPagesEnum.ExhausterRecommendations);
            break;
          }
          if (this._quoteRoundStorage.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.FlushFloor) {
            if (!this._quoteRoundStorage.flushFloorDesign) this._quoteRoundStorage.flushFloorDesign = new AqsFlushFloorViewModel();
            this.addedNextPage(AqsPagesEnum.FlushFloorDesign);
            break;
          }
          else if (this._quoteRoundStorage.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.Duct) {
            if (!this._quoteRoundStorage.ductDesign) this._quoteRoundStorage.ductDesign = new AqsRoundStorageDuctDesignModel();
            this.addedNextPage(AqsPagesEnum.DuctDesign);
            break;
          }
          else {
            this._quoteRoundStorage.exhausterSheet = null;
            this._quoteRoundStorage.flushFloorDesign = null;
            this._quoteRoundStorage.ductDesign = null;
            if (!this._quoteRoundStorage.coverSheet) this._quoteRoundStorage.coverSheet = new AqsCoverSheetModel();
            this.addedNextPage(AqsPagesEnum.CoverSheet);
          }
        }
        break;
      case AqsPagesEnum.ExhausterRecommendations:
        if (this._quoteRoundStorage.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.FlushFloor) {
          if (!this._quoteRoundStorage.flushFloorDesign) this._quoteRoundStorage.flushFloorDesign = new AqsFlushFloorViewModel();
          this.addedNextPage(AqsPagesEnum.FlushFloorDesign);
          break;
        }
        else if (this._quoteRoundStorage.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.Duct) {
          if (!this._quoteRoundStorage.ductDesign) this._quoteRoundStorage.ductDesign = new AqsRoundStorageDuctDesignModel();
          this.addedNextPage(AqsPagesEnum.DuctDesign);
          break;
        }
        else {
          this._quoteRoundStorage.flushFloorDesign = null;
          this._quoteRoundStorage.ductDesign = null;
          if (!this._quoteRoundStorage.coverSheet) this._quoteRoundStorage.coverSheet = new AqsCoverSheetModel();
          this.addedNextPage(AqsPagesEnum.CoverSheet);
        }
        break;
      case AqsPagesEnum.FlushFloorDesign:
        if (this.checkCurrentPage()) {
          if (!this._quoteRoundStorage.includeFlowOption.includeExhauster) {
            this._quoteRoundStorage.exhausterSheet = null;
          }
          this._quoteRoundStorage.ductDesign = null;
          if (!this._quoteRoundStorage.coverSheet) this._quoteRoundStorage.coverSheet = new AqsCoverSheetModel();
          this.addedNextPage(AqsPagesEnum.CoverSheet);
        }
        break;
      case AqsPagesEnum.DuctDesign:
        if (!this._quoteRoundStorage.includeFlowOption.includeExhauster) {
          this._quoteRoundStorage.exhausterSheet = null;
        }
        this._quoteRoundStorage.flushFloorDesign = null;
        if (!this._quoteRoundStorage.coverSheet) this._quoteRoundStorage.coverSheet = new AqsCoverSheetModel();
        this.addedNextPage(AqsPagesEnum.CoverSheet);
        break;
    };
  }

  changeQuoteModel() {
    this._isChangedTables = true;
    switch (this._pageIndex) {
      case AqsPagesEnum.RoundStorageConfiguration:
        this.removeNexPages(AqsPagesEnum.RoundStorageConfiguration);
        this._quoteRoundStorage.airflowConfiguration = null;
        this._quoteRoundStorage.exhausterSheet = null;
        this._quoteRoundStorage.flushFloorDesign = null;
        this._quoteRoundStorage.ductDesign = null;
        break;
      case AqsPagesEnum.DetermineTargetAirflow:
        this.removeNexPages(AqsPagesEnum.DetermineTargetAirflow);
        this._quoteRoundStorage.exhausterSheet = null;
        this._quoteRoundStorage.flushFloorDesign = null;
        this._quoteRoundStorage.ductDesign = null;
        break;
    }
  }

  changedFlowOptions() {
    if (this._pageIndex == AqsPagesEnum.DetermineTargetAirflow) {
      this.removeNexPages(AqsPagesEnum.DetermineTargetAirflow);
    }
  }

  async onClickSaveButton() {
    if (this.coverSheetComponent.checkModelBeforeSave()) {
      if (this._quoteRoundStorage.id == undefined || this._quoteRoundStorage.id == 0) {
        this._isSaveRun = true;
        let result = await this._quoteDataService.addQuoteRoundStorage(this._quoteRoundStorage);
        if (!(result instanceof ErrorModel)) {
          this._isSuccessSaved = true;
          setTimeout(() => { this._isSuccessSaved = false; }, 3000);
          this._isChangedTables = false;
          this.fillQuoteStorage(result);
          this._pageIndex = AqsPagesEnum.CoverSheet;
        } else {
          console.log(result);
        }
        this._isSaveRun = false;
      }
      else {
        this.reSaveModal.showConfirmPopup(this.title);
      }
    }
  }

  async editQuote() {
    if (this.coverSheetComponent.checkModelBeforeSave()) {
      this._isSaveRun = true;
      let result = await this._quoteDataService.editRoundStorage(this._quoteRoundStorage);
      if (!(result instanceof ErrorModel)) {
        this._isSuccessSaved = true;
        setTimeout(() => { this._isSuccessSaved = false; }, 3000);
        this._isChangedTables = false;
        this.fillQuoteStorage(result);
        this._pageIndex = AqsPagesEnum.CoverSheet;
      } else {
        console.log(result);
      }
      this._isSaveRun = false;
    }
  }

  async saveAsNewQuote() {
    this._quoteRoundStorage.id = 0;
    this._quoteRoundStorage.coverSheet.id = 0;
    this._quoteRoundStorage.roundStorage.id = 0;
    this._quoteRoundStorage.airflowConfiguration.id = 0;
    this._quoteRoundStorage.airflowConfiguration.airflowData.forEach(item => {
      item.id = 0;
    });

    if (this._quoteRoundStorage.includeFlowOption.includeExhauster) {
      this._quoteRoundStorage.exhausterSheet.id = 0;
    }
    if (this._quoteRoundStorage.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.FlushFloor) {
      this._quoteRoundStorage.flushFloorDesign.id = 0;
    }
    if (this._quoteRoundStorage.includeFlowOption.includeDesign == AqsFlushDuctDesignEnum.Duct) {
      this._quoteRoundStorage.ductDesign.id = 0;
    }
    this.onClickSaveButton();
  }

  checkCurrentPage() {
    switch (this._pageIndex) {
      case AqsPagesEnum.RoundStorageConfiguration:
        return !this._currentPageError && this._quoteRoundStorage.commodities.length > 0;
      case AqsPagesEnum.DetermineTargetAirflow:
        return this._quoteRoundStorage.airflowConfiguration.airflowData.length > 0 && !this._quoteRoundStorage.airflowConfiguration.airflowData.some(m => m.kcfm == -1 || m.kcfm == null);
      case AqsPagesEnum.FlushFloorDesign:
        return !this._currentPageError;
      default:
        return true;
    }
  }

  onClickExportReport() {
    let model = this.buildReportModel();
    this._exportReportModal.showDownloadQuoteRoundStorageReport(model);
  }

  onClickShareReport() {
    let model = this.buildReportModel();
    this._shareReportModal.showDownloadQuoteRoundStorageReport(model);
  }

  private addedNextPage(aqsPage: AqsPagesEnum) {
    if (!this._pages.some(i => i.index == aqsPage)) {
      this._pages.push({ index: aqsPage, label: AqsPageLabel[aqsPage] });
      this._pageIndex = aqsPage;
    }
    else {
      let currentPageindex = this._pages.findIndex(p => p.index == this._pageIndex);
      this._pageIndex = this._pages[++currentPageindex].index;
    }
  }

  private removeNexPages(fromPages: AqsPagesEnum) {
    let index = this._pages.findIndex(i => i.index == fromPages);
    this._pages.splice(++index, this._pages.length);
  }

  private buildReportModel(): QuoteRoundStorageReportModel {
    let model = new QuoteRoundStorageReportModel();
    model.coverSheet = this._quoteRoundStorage.coverSheet;
    model.roundStorage = this._quoteRoundStorage.roundStorage;
    model.commodities = this._quoteRoundStorage.commodities;
    model.airflowConfiguration = this._quoteRoundStorage.airflowConfiguration;
    model.includeFlowOption = this._quoteRoundStorage.includeFlowOption;
    model.exhausterSheet = this._quoteRoundStorage.exhausterSheet;
    model.flushFloorDesign = this._quoteRoundStorage.flushFloorDesign;
    model.ductDesign = this._quoteRoundStorage.ductDesign;
    return model;
  }
}
