import { Component, Output, EventEmitter, Input, OnDestroy, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { BtxLocationEquipmentConfiguration, EquipmentViewModel } from '../../../../models';
import { bitOptions } from '../../../../constants';
import { equipmentTypeLabels, equipmentSubTypeLabels, equipmentSensorMapping } from 'src/app/main/constants';
import { digitsValidator } from 'src/app/common/validators';
import { BasePopupBehavior } from '../../common';
import { ModbussAddress, TemplateFunctions } from '../../../common';

@Component({
  selector: 'greensleeves-upload-add-edit-equipment-popup',
  templateUrl: './upload-add-edit-equipment-popup.component.html',
  styles: []
})
export class UploadAddEditEquipmentPopupComponent extends BasePopupBehavior implements  OnDestroy, AfterViewChecked {
  _isHidden = true;
  _submitted = false;

  _equipment: BtxLocationEquipmentConfiguration;
  _title: string;

  @Output()
  onClose = new EventEmitter<void>();

  @Output()
  onSave: EventEmitter<[EquipmentViewModel, boolean]> = new EventEmitter<[EquipmentViewModel, boolean]>();

  @Output()
  onCancel: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  uniqueValidator: (equipment: EquipmentViewModel) => boolean;

  @Input()
  uniqueAddressesValidator: (equipment: EquipmentViewModel) => ModbussAddress[];

  @Output()
  closedPopup: EventEmitter<any> = new EventEmitter<any>();

  _equipmentForm: FormGroup;

  _isNeedToCloseParentPopup: boolean;
  _bitOptions = bitOptions;

  _equipmentTypes: Array<{ label: string, value: number }>;

  _equipmentSubtypes: Array<{ label: string, value: number }> = [];
  _submitLabel: string;

  _generalAddressErrorText = 'Address already in use';
  _generalBitErrorText = 'Bit already in use';

  private isEditMode: boolean;

  get _hasSensor(): boolean {
    return this._equipment && this._equipment.sensors && this._equipment.sensors.length > 0;
  }

  constructor(
    formBuilder: FormBuilder,
  ) {
    super();
    this._equipmentTypes = Object.keys(equipmentSensorMapping).map(k => ({ label: equipmentTypeLabels[k], value: Number.parseInt(k) }))
    this._equipmentForm = formBuilder.group({
      type: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      subType: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ])
      ],
      totalRunHoursFirstRegisterAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      totalRunHoursSecondRegisterAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      timeTillMaintenanceAddress: [
        null,
      ],
      shutDownAlarmAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      shutDownAlarmAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(16)
        ])
      ],
      maintenanceRequiredWarningAddress: [
        null,
        Validators.compose([
          Validators.required,
          digitsValidator,
          Validators.min(1),
          Validators.max(65536)
        ])
      ],
      maintenanceRequiredWarningAddressBit: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(16)
        ])
      ]
    })
  }

  onSubmit() {
    this._submitted = true;
    if (this._equipmentForm.hasError('unique')) {
      delete this._equipmentForm.errors.unique;
      this._equipmentForm.updateValueAndValidity();
    }
    if (this._equipmentForm.invalid) {
      return;
    }

    if (this._equipmentForm.controls.timeTillMaintenanceAddress.value == '') this._equipmentForm.controls.timeTillMaintenanceAddress.setValue(null);
    if (this._equipmentForm.controls.timeTillMaintenanceAddress.value != null) {
      var address = parseInt(this._equipmentForm.controls.timeTillMaintenanceAddress.value);
      if (isNaN(address) || address < 1 || address > 65536) {
        this._equipmentForm.controls.timeTillMaintenanceAddress.setErrors({ digitsValidator: true })
        return;
      }
      this._equipmentForm.controls.timeTillMaintenanceAddress.setValue(address);
    }

    const equipment = {
      ...this._equipment,
      ...this._equipmentForm.value
    } as EquipmentViewModel;

    if (!this.uniqueValidator(equipment)) {
      this._equipmentForm.setErrors({
        unique: 'Equipment with the same name already exists in the system'
      });
      return;
    }

    const notUniqueAddresses = this.uniqueAddressesValidator(equipment);
    if (notUniqueAddresses && notUniqueAddresses.length > 0) {
      TemplateFunctions.markAddressControllsAsNotUnique(this._equipmentForm, notUniqueAddresses);
      return;
    }

    this.onSave.emit([equipment, this.isEditMode]);
    this.resetPopup();
    this._isHidden = true;
  }

  ngAfterViewChecked() {
    super.ngAfterViewChecked();
  }

  public show(title: string, submitLabel: string, equipment: BtxLocationEquipmentConfiguration = null, isNeedToCloseParentPopup = false) {
    this.isEditMode = !!equipment;
    this._equipment = equipment || new BtxLocationEquipmentConfiguration();
    this._isNeedToCloseParentPopup = isNeedToCloseParentPopup;
    this.setFormValues(equipment);
    this.onTypeChanged();
    this._title = title;
    this._submitLabel = submitLabel;
    this._isHidden = false;
  }

  onClickClose() {
    this._isHidden = true;
    this.resetPopup();
    this.closedPopup.emit();
    if (this._isNeedToCloseParentPopup) {
      this.onClose.next();
    }
  }

  onTypeChanged() {
    const equipmentType = this._equipmentForm.get('type').value;
    this._equipmentSubtypes = ((equipmentType || equipmentType === 0) ? Object.keys(equipmentSensorMapping[equipmentType]) : [])
      .map(k => ({ label: equipmentSubTypeLabels[k], value: Number.parseInt(k) }));
  }

  private resetPopup() {
    this._equipmentForm.reset();
    this._equipment = null;
    this._submitted = false;
  }

  private setFormValues(equipment: BtxLocationEquipmentConfiguration) {
    this._equipmentForm.reset();
    if (equipment) {
      const {
        type,
        subType,
        name,
        totalRunHoursFirstRegisterAddress,
        totalRunHoursSecondRegisterAddress,
        timeTillMaintenanceAddress,
        shutDownAlarmAddress,
        shutDownAlarmAddressBit,
        maintenanceRequiredWarningAddress,
        maintenanceRequiredWarningAddressBit
      } = equipment;
      this._equipmentForm.setValue({
        type,
        subType,
        name,
        totalRunHoursFirstRegisterAddress,
        totalRunHoursSecondRegisterAddress,
        timeTillMaintenanceAddress,
        shutDownAlarmAddress,
        shutDownAlarmAddressBit,
        maintenanceRequiredWarningAddress,
        maintenanceRequiredWarningAddressBit
      });
    }
  }

  hasValue(control: FormControl) {
    return control.value || control.value === 0;
  }
}