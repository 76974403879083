import { Subscription } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { VendorInviteModel, VendorViewModel } from './../../../models';
import { emailValidator, ErrorCodeEnum, ErrorModel, LocalStorageService, UserRoleEnum } from '../../../../common';
import { LocationsItxDatasourceService, VendorDataSourceService } from '../../../services';

@Component({
  selector: 'greensleeves-vendor-add-popup',
  templateUrl: './vendor-add-popup.component.html',
  styles: []
})
export class VendorAddPopupComponent implements OnInit, OnDestroy {
  _isHidden = true;
  _submitted = false;
  _role: UserRoleEnum;
  _vendorForm: FormGroup;
  _invalidEmail = false;
  _nodeSelected: TreeNode[] = [];
  _itxLocations: TreeNode[] = [];
  _noBinSelected = false;
  _disableSubmit = false;
  private _locationsSubscription: Subscription;

  get _isCompanyAdmin() {
    return this._role === UserRoleEnum.CompanyAdmin;
  }

  constructor(private _formBuilder: FormBuilder,
    private _localStorageService: LocalStorageService,
    private _locationItxData: LocationsItxDatasourceService,
    private _vendorDataServie: VendorDataSourceService,
    private _router: Router
  ) {
  }

  ngOnInit() {
    const loginInfo = this._localStorageService.getLoginInfo();
    this._role = loginInfo ? loginInfo.role : null;
    if (this._router.url.indexOf('/back-door') > -1) {
      this._role = UserRoleEnum.CompanyAdmin;
    }
    if (this._isCompanyAdmin) {
      this._locationItxData.getLocationsWithBinsForCompany(loginInfo ? loginInfo.companyId : 0);
      this._locationsSubscription = this._locationItxData.locationsItx$.subscribe(data => {
        if (data) {
          this._itxLocations = data.map(i => ({ label: i.locationName, key: i.id.toString() + i.locationName, data: i.id, expanded: false, children: i.itxBins.map(b => ({ label: b.name, data: b.id, key: b.id.toString() + b.name })) }));
        }
      });
    }

    this._vendorForm = this._formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          emailValidator(),
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
    });

    this._vendorForm.valueChanges.subscribe(() => this._invalidEmail = false);
  }

  ngOnDestroy() {
    this._locationsSubscription && this._locationsSubscription.unsubscribe();
    this._locationsSubscription = null;
  }

  public show() {
    this.clearForm();
    this._isHidden = false;
  }

  nodeSelect(event) {
    if (this._submitted) {
      this._noBinSelected = false;
    }

    if (!event.node.parent) {
      event.node.expanded = true;
    }
  }

  onSubmit() {
    this._submitted = true;
    this._noBinSelected = false;
    this._disableSubmit = true;
    if (this._vendorForm.invalid || this._invalidEmail) {
      if (!this._nodeSelected || this._nodeSelected.length === 0) {
        this._noBinSelected = true;
        this.collapseTree();
      }
      this._disableSubmit = false;
      return;
    }
    if (!this._nodeSelected || this._nodeSelected.length === 0) {
      this._noBinSelected = true;
      this.collapseTree();
      this._disableSubmit = false;
      return;
    }
    const inviteModel = new VendorInviteModel;
    inviteModel.email = this._vendorForm.value.email;
    inviteModel.itxStorageIds = [];
    this._nodeSelected.forEach(i => {
      if (i.parent) {
        inviteModel.itxStorageIds.push(i.data);
      }
    });
    this._vendorDataServie.invite(inviteModel).then((response: VendorViewModel) => {
      if (response instanceof ErrorModel && response.code === ErrorCodeEnum.UserAlreadyExist) {
        this._invalidEmail = true;
        this._disableSubmit = false;
      } else if (response) {
        const loginInfo = this._localStorageService.getLoginInfo();
        this._vendorDataServie.get(loginInfo.companyId);
        this.resetForm();
      }
    });
  }

  private clearForm() {
    this._submitted = false;
    this._noBinSelected = false;
    this._vendorForm.reset();
    this._itxLocations.forEach(i => { i.partialSelected = false });
    this._nodeSelected = [];
    this.collapseTree()
    this._disableSubmit = false;
  }

  private collapseTree() {
    this._itxLocations.forEach(i => i.expanded = false);
  }

  private resetForm() {
    this.clearForm();
    this._isHidden = true;
  }
}
