import { Component, ViewChild } from '@angular/core';

import { LocationsDataSourceService, RoleService } from '../../services';
import { LocationEditPopupComponent, TutorialPopupComponent } from '../popups';
import { LocationViewModel } from '../../models';
import { UserRoleEnum } from '../../../common';
import { TutorialMenuEnum } from '../../enums';

@Component({
  selector: 'greensleeves-locations-tab',
  templateUrl: './locations-tab.component.html',
})
export class LocationsTabComponent {
  @ViewChild(LocationEditPopupComponent, { read: false, static: false })
  private _editModal: LocationEditPopupComponent;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent

  constructor(
    private _locationsDataSourceService: LocationsDataSourceService,
    private _roleService: RoleService
  ) {}

  get _isServiceUser() {
    return this._roleService.userHasRole(UserRoleEnum.ServiceUser);
  }

  async onEdit(location?: LocationViewModel) {
    if (location) {
      let locationEquipment =
        await this._locationsDataSourceService.getEquipment(location.id);
      locationEquipment = {
        ...locationEquipment,
        ...location,
      };

      this._editModal.show(locationEquipment);
    } else {
      this._editModal.show();
    }
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.Locations);
  }
}
