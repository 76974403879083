export class DeviceFileLogInfoViewModel {
    fullFileName: string;
    fileName: string;
    fileNameWithoutDeviceId: string;
    createdDate: Date;
    lastModifiedDate: Date;
    size: number;
    sizeLabel: string;
}

export class DeviceLogInfoViewModel {
    isTwinHasLogLink: boolean;
    deviceFileLogInfo: DeviceFileLogInfoViewModel[];
}