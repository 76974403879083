import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AqsAirflowViewModel, AqsRoundStorageDuctDesignModel, AqsRoundStorageDuctDesignViewModel, AqsRoundStorageWiewModel } from '@models';
import { AqsRoundDuctTypeLabel } from '../../../constants';
import { AqsAirflowFanTypeEnum, AqsDuctTypeEnum, AqsRoundDuctDesignType, AqsRoundStorageHopperTypeEnum } from '../../../enums';
import { ConvertDataFunctions } from './../../../../common';
import { AqsRoundDuctDesignViewTabComponent } from './aqs-round-duct-design-view-tab';

@Component({
  selector: 'greensleeves-aqs-round-duct-design',
  templateUrl: './aqs-round-duct-design.component.html',
  styles: []
})
export class AqsRoundDuctDesignComponent implements OnInit {
  @Input() ductConfiguration: AqsRoundStorageDuctDesignModel;
  @Output() ductConfigurationChange = new EventEmitter<AqsRoundStorageDuctDesignModel>();

  @Input() storageConfiguration: AqsRoundStorageWiewModel;
  @Input() airflowConfiguration: AqsAirflowViewModel;

  @Output() onChangedDuctModel = new EventEmitter();

  @ViewChild(AqsRoundDuctDesignViewTabComponent, { read: false, static: false })
  private viewScheme: AqsRoundDuctDesignViewTabComponent;

  private perfDMax: number;
  private perfCMax: number;
  private kcfmMax: number;
  private lowerLarge: number;
  private surface: number;

  _ductDesign: AqsRoundStorageDuctDesignViewModel;

  _ductsPerFan: number;
  _degreesBetweenFans: number;
  _degreesBetweenDucts: number;

  _distanceBetweenDuctsFt: number;
  _distanceBetweenDuctsIn: number;
  _distanceBetweenDucts: string;

  _openingDiameter: number;
  _ductDiameter: number;

  _solidDuctLengthFt: number;
  _solidDuctLengthIn: number;
  _solidDuctLengthStr: string;

  _perforatedDuctLengthFt: number;
  _perforatedDuctLengthIn: number;
  _perforatedDuctLengthStr: string;

  _perforatedCDuctLengthFt: number;
  _perforatedCDuctLengthIn: number;
  _perforatedCDuctLengthStr: string;

  _solidDuctLengthNoSingle: number = null;
  _solidDuctLengthNoSingleFtIn: string;
  _letterSolidDuctLengthNoSingle: string;

  _solidDuctCLengthNoSingle: number = null;
  _solidDuctCLengthNoSingleFtIn: string;
  _letterSolidDuctCLengthNoSingle: string;

  _maxCrossSec: number;
  _maxSurface: number;
  _actCrossSec: number;
  _actSurface: number;
  _ductType: AqsDuctTypeEnum;

  _ductTypes: { label: string, value: AqsDuctTypeEnum }[] = [];

  _designTypeEnum = AqsRoundDuctDesignType;

  private cfr = [
    { diameter: 12, surface: 2.566 },
    { diameter: 14, surface: 2.9 },
    { diameter: 16, surface: 3.3 },
    { diameter: 18, surface: 3.9 },
    { diameter: 20, surface: 4.233 },
    { diameter: 24, surface: 5.2 },
    { diameter: 30, surface: 6.4 },
    { diameter: 36, surface: 7.666 },
  ];

  constructor() { }

  ngOnInit() {
    for (let [key, value] of Object.entries(AqsRoundDuctTypeLabel)) {
      this._ductTypes.push({ label: value, value: Number(key) });
    }

    this._ductDesign = new AqsRoundStorageDuctDesignViewModel();
    this._ductDesign.roundStorageDiameter = this.storageConfiguration.diameter;
    this._ductDesign.numberOfFans = this.airflowConfiguration.numberfans;
    this._ductDesign.hopperPitch = this.storageConfiguration.hopperPitch;
    this._ductDesign.outletDiameter = this.storageConfiguration.outletDiameter;
    this._ductDesign.steamwall = this.storageConfiguration.steamwall;
    this._ductDesign.ledge = this.storageConfiguration.ledge;
    this.kcfmMax = Math.max(...this.airflowConfiguration.airflowData.map(x => x.kcfm));
    this.lowerLarge = this.kcfmMax * this.airflowConfiguration.numberfans * 1000;

    if (!this.ductConfiguration.ductsPerFan) {
      this.setRecommendationsParams();
    } else {
      this.setMaxVelocities();
      this.setDuctDesignType();
      this.setSurface();

      Object.entries(this.ductConfiguration).forEach(([key, value]) => {
        if (value != undefined) {
          this._ductDesign[key] = value;
        }
      })

      this.processCalculate(false, true, true, true);
    }

  }

  onChangeDuctType() {
    this._ductDesign.ductType = this._ductType;

    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.SingleFan) {
      this._ductDesign.ductsPerFan = 1;
    }

    this.processCalculate(true);
  }

  onChangeDuctPerFan() {
    if ((this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchSideDraw &&
      (this._ductsPerFan < 1 || this._ductsPerFan > 4)) ||
      (this._ductsPerFan < 1 || this._ductsPerFan > 10)) {
      this._ductsPerFan = this._ductDesign.ductsPerFan;
    } else {
      this._ductDesign.ductsPerFan = Math.round(this._ductsPerFan);
      this.processCalculate(true);
    }
  }

  onChangeOpeningDiameter() {
    if (this._openingDiameter < 1 || this._openingDiameter > 688) {
      this._openingDiameter = this._ductDesign.openingDiameter;
    } else {
      this._ductDesign.openingDiameter = Math.round(this._openingDiameter);

      if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.MultipleFans &&
        this._ductType == AqsDuctTypeEnum.HalfRoundDuct) {
        this._ductDesign.perforatedDuctLengthFt = this.perfCMax;
      }
      this.processCalculate();
    }
  }

  onChangeDuctDiameter() {
    if (this.cfr.some(x => x.diameter == this._ductDiameter)) {
      this._ductDesign.ductDiameter = this._ductDiameter;
      this.surface = this.cfr.find(x => x.diameter == this._ductDiameter).surface;

      if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchConical) {
        let x = this.getPerfLengthCoefHoppitchConical();

        this._ductDesign.perforatedDuctLengthFt = x * this.perfDMax;
        this._ductDesign.perforatedCDuctLengthFt = x * this.perfCMax;
        this.calcFullDuctPerfLength(true, x);
      } else {
        this._ductDesign.perforatedDuctLengthFt = this.getFtPerf(this.lowerLarge, this.surface);

        if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.SingleFan) {
          this.calcSolidAndPerforatedLength(true);
        }
      }

      this.processCalculate();
    } else {
      this._ductDiameter = this._ductDesign.ductDiameter;
    }
  }

  onChangeSolidDuctLengthFt() {
    if (this._solidDuctLengthFt % 2 !== 0 ||
      this._solidDuctLengthFt >= this.perfDMax ||
      this._solidDuctLengthFt < 0 ||
      !_isNumberValue(this._solidDuctLengthFt)) {
      this._solidDuctLengthFt = this._ductDesign.solidDuctLengthFt;
    } else {
      this._ductDesign.solidDuctLengthFt = this._solidDuctLengthFt;
      if (this._ductDesign.solidDuctLengthFt + this._ductDesign.perforatedDuctLengthFt > this.perfDMax) {
        this._ductDesign.perforatedDuctLengthFt = this.perfDMax - this._ductDesign.solidDuctLengthFt;
      }

      this.processCalculate();
    }
  }

  onChangePerforatedDuctLengthFt() {
    switch (this._ductDesign.roundDuctDesignType) {
      case AqsRoundDuctDesignType.SingleFan:
        if (this._perforatedDuctLengthFt % 2 !== 0 ||
          this._perforatedDuctLengthFt > this.perfDMax ||
          this._perforatedDuctLengthFt < 0) {
          this._perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt;
        } else {
          this._ductDesign.perforatedDuctLengthFt = this._perforatedDuctLengthFt;
          if (this._ductDesign.perforatedDuctLengthFt + this._ductDesign.solidDuctLengthFt > this.perfDMax) {
            this._ductDesign.solidDuctLengthFt = Math.floor(this.perfDMax - this._ductDesign.perforatedDuctLengthFt);
          }
          this.processCalculate();
        }
        break;
      case AqsRoundDuctDesignType.MultipleFans:
        if (this._perforatedDuctLengthFt % 2 !== 0 ||
          this._perforatedDuctLengthFt > this.perfCMax ||
          this._perforatedDuctLengthFt <= 0) {
          this._perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt;
        } else {
          this._ductDesign.perforatedDuctLengthFt = this._perforatedDuctLengthFt;
          this._ductDesign.solidDuctLengthFt = this.perfCMax - this._ductDesign.perforatedDuctLengthFt;
          this.processCalculate();
        }
        break;
      case AqsRoundDuctDesignType.HopperPitchConical:
      case AqsRoundDuctDesignType.HopperPitchSideDraw:
        if (this._perforatedDuctLengthFt / 2 !== Math.floor(this._perforatedDuctLengthFt / 2) ||
          this._perforatedDuctLengthFt == this._ductDesign.perforatedDuctLengthFt ||
          this._perforatedDuctLengthFt > this.perfDMax ||
          this._perforatedDuctLengthFt < 2) {
          this._perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt;
        } else {
          this._ductDesign.perforatedDuctLengthFt = this._perforatedDuctLengthFt;
          this.processCalculate();
        }
        break;
      default:
        break;
    }
  }

  onChangePerforatedCDuctLengthFt() {
    if (this._perforatedCDuctLengthFt / 2 !== Math.floor(this._perforatedCDuctLengthFt / 2) ||
      this._perforatedCDuctLengthFt == this._ductDesign.perforatedCDuctLengthFt ||
      this._perforatedCDuctLengthFt > this.perfCMax ||
      this._perforatedCDuctLengthFt < 0) {
      this._perforatedCDuctLengthFt = this._ductDesign.perforatedCDuctLengthFt;
    } else {
      this._ductDesign.perforatedCDuctLengthFt = this._perforatedCDuctLengthFt;
      this.processCalculate();
    }
  }

  onChangeDistanseBetweenDucts() {
    let inches = this.parseFtInLengthStrToIn(this._distanceBetweenDucts);

    if (!inches) {
      this._distanceBetweenDucts = Math.floor(this._distanceBetweenDuctsFt) + '-' + this._distanceBetweenDuctsIn;
      return;
    }

    let temp = inches / 12;
    if (temp == this._ductDesign.degreesBetweenDucts ||
      temp * (this._ductDesign.numberOfDucts - 1) > this.storageConfiguration.diameter * 0.8 ||
      temp < this._ductDesign.ductDiameter / 12 ||
      temp < this._ductDesign.openingDiameter / 12) {
      this._distanceBetweenDucts = Math.floor(this._distanceBetweenDuctsFt) + '-' + this._distanceBetweenDuctsIn;
      return;
    }

    this._ductDesign.degreesBetweenDucts = temp;

    this.processCalculate(true, false);
  }

  onChangeSolidDuctLengthStr() {
    let inches = this.parseFtInLengthStrToIn(this._solidDuctLengthStr);

    if (inches == null) {
      this._solidDuctLengthStr = Math.floor(this._solidDuctLengthFt) + '-' + this._solidDuctLengthIn;
      return;
    }

    while (Math.floor(inches / 16) !== inches / 16) {
      inches = inches - 1;
    }

    let temp = inches / 12;

    if (temp == this._ductDesign.solidDuctLengthFt ||
      temp > this.perfDMax - (16 / 12) ||
      temp < 0) {
      this._solidDuctLengthStr = Math.floor(this._solidDuctLengthFt) + '-' + this._solidDuctLengthIn;
      return;
    }

    this._ductDesign.solidDuctLengthFt = temp;

    if (this._ductDesign.solidDuctLengthFt + this._ductDesign.perforatedDuctLengthFt > this.perfDMax) {
      this._ductDesign.perforatedDuctLengthFt = this.perfDMax - this._ductDesign.solidDuctLengthFt;

      let inches = Math.floor(this._ductDesign.perforatedDuctLengthFt * 12 + 0.5);
      while (Math.floor(inches / 16) !== inches / 16) {
        inches = inches - 1;
      }

      if (inches < 16) {
        inches = 16;
      }

      this._ductDesign.perforatedDuctLengthFt = inches / 12;
    }

    this.processCalculate();
  }

  onChangePerforatedDuctLengthStr() {
    let inches = this.parseFtInLengthStrToIn(this._perforatedDuctLengthStr);

    if (inches == null) {
      this._perforatedDuctLengthStr = Math.floor(this._perforatedDuctLengthFt) + '-' + this._perforatedDuctLengthIn;
      return;
    }

    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.MultipleFans) {
      if (inches > this.perfCMax) {
        inches = Math.floor(this.perfCMax);
      }
    } else if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchConical) {
      if (inches > this.perfDMax) {
        inches = Math.floor(this.perfDMax);
      }
    } else if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchSideDraw) {
      if (inches > Math.floor(this.perfDMax * 12)) {
        inches = Math.floor(this.perfDMax * 12);
      }
    }

    while (Math.floor(inches / 16) !== inches / 16) {
      inches = inches - 1;
    }

    if (this._ductDesign.roundDuctDesignType != AqsRoundDuctDesignType.SingleFan) {
      if (inches < 16) {
        inches = 16;
      }
    }

    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.SingleFan) {
      let temp = inches / 12;
      if (temp == this._ductDesign.perforatedDuctLengthFt ||
        temp > this.perfDMax ||
        inches < 16) {
        this._perforatedDuctLengthStr = Math.floor(this._perforatedDuctLengthFt) + '-' + this._perforatedDuctLengthIn;
        return;
      }

      this._ductDesign.perforatedDuctLengthFt = temp;

      if (this._ductDesign.solidDuctLengthFt + this._ductDesign.perforatedDuctLengthFt > this.perfDMax) {
        this._ductDesign.solidDuctLengthFt = this.perfDMax - this._ductDesign.perforatedDuctLengthFt;
        let inches = Math.floor(this._ductDesign.solidDuctLengthFt * 12 + 0.5);
        while (Math.floor(inches / 16) !== inches / 16) {
          inches = inches - 1;
        }

        if (inches < 0) {
          inches = 0;
        }

        this._ductDesign.solidDuctLengthFt = inches / 12;
      }
    }

    if (this._ductDesign.roundDuctDesignType != AqsRoundDuctDesignType.SingleFan) {
      let temp = inches / 12;

      if (temp == this._ductDesign.perforatedDuctLengthFt) {
        this._perforatedDuctLengthStr = Math.floor(this._perforatedDuctLengthFt) + '-' + this._perforatedDuctLengthIn;
        return;
      }

      this._ductDesign.perforatedDuctLengthFt = temp;
    }

    this.processCalculate();
  }

  onChangePerforatedCDuctLengthStr() {
    let inches = this.parseFtInLengthStrToIn(this._perforatedCDuctLengthStr);

    if (!inches) {
      this._perforatedCDuctLengthStr = Math.floor(this._perforatedCDuctLengthFt) + '-' + this._perforatedCDuctLengthIn;
      return;
    }

    if (this._ductDesign.roundDuctDesignType != AqsRoundDuctDesignType.SingleFan) {
      if (inches > this.perfCMax * 12) {
        inches = Math.floor(this.perfCMax * 12);
      }
    }

    while (Math.floor(inches / 16) !== inches / 16) {
      inches = inches - 1;
    }

    let temp = inches / 12;

    if (temp == this._ductDesign.perforatedCDuctLengthFt) {
      this._perforatedCDuctLengthStr = Math.floor(this._perforatedCDuctLengthFt) + '-' + this._perforatedCDuctLengthIn;
      return;
    }

    this._ductDesign.perforatedCDuctLengthFt = temp;

    this.processCalculate();
  }

  onChangeDegreesBetweenFans() {
    let maxDegree = this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchSideDraw ? 100 : 360;

    if (this._degreesBetweenFans < 5 ||
      this._degreesBetweenFans * this.airflowConfiguration.numberfans > maxDegree) {
      this._degreesBetweenFans = this._ductDesign.degreesBetweenFans;
      return;
    }

    this._degreesBetweenFans = Math.floor(this._degreesBetweenFans * 10 + 0.5) / 10
    this._ductDesign.degreesBetweenFans = this._degreesBetweenFans;

    if (this._ductDesign.ductsPerFan > 1) {
      this._degreesBetweenDucts = Math.floor((this._degreesBetweenFans / this._ductDesign.ductsPerFan) * 10 + 0.5) / 10;
      this._ductDesign.degreesBetweenDucts = this._degreesBetweenDucts;
    }

    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchSideDraw) {
      this._ductDesign.perforatedDuctLengthFt = this.getFtPerf(this.lowerLarge, this.surface);

      this.processCalculate();
    } else {
      this.setPostModel();
      this.viewScheme.drawDuctDesignScheme();
    }
  }

  onChangeDegreeBetweenDucts() {
    if (this._degreesBetweenDucts < 5 / this._ductDesign.ductsPerFan ||
      this._degreesBetweenDucts * (this._ductDesign.ductsPerFan - 1) >= this._ductDesign.degreesBetweenFans) {
      this._degreesBetweenDucts = this._ductDesign.degreesBetweenDucts;
      return;
    }

    this._degreesBetweenDucts = Math.floor(this._degreesBetweenDucts * 10 + 0.5) / 10;
    this._ductDesign.degreesBetweenDucts = this._degreesBetweenDucts;

    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchSideDraw) {
      this._ductDesign.perforatedDuctLengthFt = this.getFtPerf(this.lowerLarge, this.surface);
      this.processCalculate();
    } else {
      this.setPostModel();
      this.viewScheme.drawDuctDesignScheme();
    }
  }



  private setRecommendationsParams() {
    this._ductDesign.ductsPerFan = 1;
    this.setMaxVelocities();
    this.setDuctDesignType();
    this.processCalculate(true, true, true);
  }

  private setDuctDesignType() {
    //RSINGLE.BAS
    if (this.storageConfiguration.hopperPitch == 0 &&
      this.airflowConfiguration.numberfans == 1) {
      this._ductDesign.roundDuctDesignType = AqsRoundDuctDesignType.SingleFan;
    } else if (this.storageConfiguration.hopperPitch == 0 &&
      this.airflowConfiguration.numberfans > 1) {
      //RDUCT.BAS
      this._ductDesign.roundDuctDesignType = AqsRoundDuctDesignType.MultipleFans;
    } else if (this.storageConfiguration.hopperPitch > 0 &&
      this.storageConfiguration.hopperType == AqsRoundStorageHopperTypeEnum.Conical) {
      //RCONE.BAS
      this._ductDesign.roundDuctDesignType = AqsRoundDuctDesignType.HopperPitchConical;
    } else if (this.storageConfiguration.hopperPitch > 0 &&
      this.storageConfiguration.hopperType == AqsRoundStorageHopperTypeEnum.SideDraw) {
      //RSIDE.BAS
      this._ductDesign.roundDuctDesignType = AqsRoundDuctDesignType.HopperPitchSideDraw;
    }
  }

  private setSurface() {
    if (this.ductConfiguration.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      let index = this.cfr.findIndex(x => x.diameter == this.ductConfiguration.ductDiameter);
      this.surface = this.cfr[index].surface;
    } else {
      this.surface = 4.666;
    }
  }

  private setPostModel() {
    this.ductConfiguration.ductsPerFan = this._ductDesign.ductsPerFan;
    this.ductConfiguration.degreesBetweenFans = this._ductDesign.degreesBetweenFans;
    this.ductConfiguration.degreesBetweenDucts = this._ductDesign.degreesBetweenDucts;
    this.ductConfiguration.distanceBetweenDuctsFt = this._ductDesign.distanceBetweenDuctsFt;
    this.ductConfiguration.distanceBetweenDuctsIn = this._ductDesign.distanceBetweenDuctsIn;
    this.ductConfiguration.openingDiameter = this._ductDesign.openingDiameter;
    this.ductConfiguration.ductDiameter = this._ductDesign.ductDiameter;
    this.ductConfiguration.solidDuctLengthFt = this._ductDesign.solidDuctLengthFt;
    this.ductConfiguration.solidDuctLengthIn = this._ductDesign.solidDuctLengthIn;
    this.ductConfiguration.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt;
    this.ductConfiguration.perforatedDuctLengthIn = this._ductDesign.perforatedDuctLengthIn;
    this.ductConfiguration.perforatedCDuctLengthFt = this._ductDesign.perforatedCDuctLengthFt;
    this.ductConfiguration.perforatedCDuctLengthIn = this._ductDesign.perforatedCDuctLengthIn;
    this.ductConfiguration.ductType = this._ductDesign.ductType;

    this.ductConfigurationChange.emit(this.ductConfiguration);
    this.onChangedDuctModel.emit();
  }

  private processCalculate(
    isPreDefParams: boolean = false,
    isChangeNotDegree: boolean = true,
    isInitialization: boolean = false,
    isRestore: boolean = false) {

    switch (this._ductDesign.roundDuctDesignType) {
      case AqsRoundDuctDesignType.SingleFan:
        this.setParamsForSingleFan(isPreDefParams, isChangeNotDegree);
        break;
      case AqsRoundDuctDesignType.MultipleFans:
        this.setParamsForMultipleFans(isPreDefParams);
        break;
      case AqsRoundDuctDesignType.HopperPitchConical:
        this.setParamsForConicalWithHoppitch(isPreDefParams);
        break;
      case AqsRoundDuctDesignType.HopperPitchSideDraw:
        this.setParamsForSideDrawWithHoppitch(isPreDefParams);
        break;
      default:
        break;
    }

    this.resetModels();

    if (!isRestore) {
      this.setPostModel();
    }

    if (!isInitialization) {
      this.viewScheme.drawDuctDesignScheme();
    }
  }

  private resetModels() {
    this._ductsPerFan = this._ductDesign.ductsPerFan;
    this._degreesBetweenFans = Math.round(this._ductDesign.degreesBetweenFans * 100) / 100;
    this._degreesBetweenDucts = this._ductDesign.degreesBetweenDucts.toString().length > 4 ?
      Number(this._ductDesign.degreesBetweenDucts.toFixed(4)) : Number(this._ductDesign.degreesBetweenDucts);
    this._distanceBetweenDuctsFt = this._ductDesign.distanceBetweenDuctsFt;
    this._distanceBetweenDuctsIn = this._ductDesign.distanceBetweenDuctsIn;
    this._openingDiameter = this._ductDesign.openingDiameter;
    this._ductDiameter = this._ductDesign.ductDiameter;
    this._solidDuctLengthFt = this._ductDesign.solidDuctLengthFt;
    this._solidDuctLengthIn = this._ductDesign.solidDuctLengthIn;
    this._perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt;
    this._perforatedDuctLengthIn = this._ductDesign.perforatedDuctLengthIn;
    this._perforatedCDuctLengthFt = this._ductDesign.perforatedCDuctLengthFt;
    this._perforatedCDuctLengthIn = this._ductDesign.perforatedCDuctLengthIn;
    this._maxCrossSec = this._ductDesign.maxVelocities.crossSec;
    this._maxSurface = this._ductDesign.maxVelocities.surface;
    this._actCrossSec = this._ductDesign.actVelocities.crossSec;
    this._actSurface = this._ductDesign.actVelocities.surface;
    this._ductType = this._ductDesign.ductType;
    this._distanceBetweenDucts = Math.floor(this._distanceBetweenDuctsFt) + '-' + Math.round(this._distanceBetweenDuctsIn);
    this._solidDuctLengthStr = Math.floor(this._solidDuctLengthFt) + '-' + Math.round(this._solidDuctLengthIn);
    this._perforatedDuctLengthStr = Math.floor(this._perforatedDuctLengthFt) + '-' + Math.round(this._perforatedDuctLengthIn);
    this._perforatedCDuctLengthStr = Math.floor(this._perforatedCDuctLengthFt) + '-' + Math.round(this._perforatedCDuctLengthIn);
  }

  private setParamsForSingleFan(isPreDefParams: boolean,
    isChangeNotDegrees: boolean) {
    this._ductDesign.numberOfDucts = this._ductDesign.ductsPerFan;
    if (isPreDefParams && isChangeNotDegrees) {
      this._ductDesign.degreesBetweenDucts = this.storageConfiguration.diameter / 6;

      if (this._ductDesign.degreesBetweenDucts * (this._ductDesign.ductsPerFan - 1) >
        0.75 * this.storageConfiguration.diameter) {
        this._ductDesign.degreesBetweenDucts = (0.95 * this.storageConfiguration.diameter) /
          this._ductDesign.ductsPerFan;
      }

      this._ductDesign.degreesBetweenDucts = Math.round(this._ductDesign.degreesBetweenDucts * 12) / 12;
    }

    let inches = Math.floor(this._ductDesign.degreesBetweenDucts * 12 + 0.5);

    this._ductDesign.distanceBetweenDuctsFt = Math.floor(inches / 12);
    this._ductDesign.distanceBetweenDuctsIn = Math.round(inches - (Math.floor(inches / 12) * 12))

    let ftPerf: number;
    if (isPreDefParams) {
      this.surface = this.setDuctDiameter(this.lowerLarge);
      this._ductDesign.openingDiameter = this._ductDesign.ductDiameter;

      ftPerf = this.getFtPerf(this.lowerLarge, this.surface);
    }

    let yMax = (this._ductDesign.degreesBetweenDucts * (this._ductDesign.numberOfDucts - 1)) / 2;

    this.perfDMax = Math.pow((this.storageConfiguration.diameter / 2), 2) - Math.pow(yMax, 2);
    this.perfDMax = Math.sqrt(this.perfDMax);
    this.perfDMax = 2 * this.perfDMax;

    if (this.storageConfiguration.steamwall > 0) {
      this.perfDMax = this.perfDMax - (this._ductDesign.openingDiameter / 12);
    }

    if (isPreDefParams) {
      this._ductDesign.perforatedDuctLengthFt = ftPerf;
    }

    this.calcSolidAndPerforatedLength(isPreDefParams);
    this.setActVelocities();
  }

  private setParamsForMultipleFans(isPreDefParams: boolean = false) {
    this._ductDesign.numberOfDucts = this.airflowConfiguration.numberfans * this._ductDesign.ductsPerFan;

    if (isPreDefParams) {
      this._ductDesign.degreesBetweenFans = 360 / this.airflowConfiguration.numberfans;
      this._ductDesign.degreesBetweenDucts = this._ductDesign.degreesBetweenFans / this._ductDesign.ductsPerFan;

      this.surface = this.setDuctDiameter(this.lowerLarge);

      this._ductDesign.openingDiameter = this._ductDesign.ductDiameter;
    }

    let ftPerf = this.getFtPerf(this.lowerLarge, this.surface);

    this.perfCMax = this.storageConfiguration.diameter / 2;

    if (this.storageConfiguration.steamwall > 0) {
      this.perfCMax = this.perfCMax - (this._ductDesign.openingDiameter / 12)
    }

    let maxPerf = this.perfCMax;
    let x = ftPerf / maxPerf;

    if (isPreDefParams) {
      this._ductDesign.perforatedDuctLengthFt = x * this.perfCMax;
    }

    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      this.perfCMax = Math.floor(this.perfCMax);

      if (this.perfCMax / 2 !== Math.floor(this.perfCMax / 2)) {
        this.perfCMax = this.perfCMax - 1;
      }

      if (this.perfCMax < 2) {
        this.perfCMax = 2;
      }

      this._ductDesign.perforatedDuctLengthFt = Math.floor(Math.floor(
        this._ductDesign.perforatedDuctLengthFt * 2) / 2)

      if (this._ductDesign.perforatedDuctLengthFt / 2 !== Math.floor(this._ductDesign.perforatedDuctLengthFt / 2)) {
        this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt + 1;
      }

      if (isPreDefParams) {
        if (this._ductDesign.perforatedDuctLengthFt < x * this.perfCMax) {
          this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt + 2;
        }
      }
      if (this._ductDesign.perforatedDuctLengthFt > this.perfCMax) {
        this._ductDesign.perforatedDuctLengthFt = this.perfCMax;
      }

      if (this._ductDesign.perforatedDuctLengthFt < 2) {
        this._ductDesign.perforatedDuctLengthFt = 2;
      }
    } else {
      this.perfCMax = Math.floor(this.perfCMax * 12);

      while (this.perfCMax / 16 !== Math.floor(this.perfCMax / 16)) {
        this.perfCMax = this.perfCMax - 1;
        this.perfCMax = Math.round(this.perfCMax);
      }

      if (this.perfCMax < 16) {
        this.perfCMax = 16;
      }

      this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt * 12;

      if (this._ductDesign.perforatedDuctLengthFt > this.perfCMax) {
        this._ductDesign.perforatedDuctLengthFt = this.perfCMax;
      }

      this._ductDesign.perforatedDuctLengthFt = Math.floor(this._ductDesign.perforatedDuctLengthFt);

      while (this._ductDesign.perforatedDuctLengthFt / 16 !== Math.floor(this._ductDesign.perforatedDuctLengthFt / 16)) {
        this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt - 1;
        Math.round(this._ductDesign.perforatedDuctLengthFt);
      }

      if (this._ductDesign.perforatedDuctLengthFt < 16) {
        this._ductDesign.perforatedDuctLengthFt = 16;
      }

      this._ductDesign.perforatedDuctLengthIn = Math.round(this._ductDesign.perforatedDuctLengthFt -
        (Math.floor(this._ductDesign.perforatedDuctLengthFt / 12) * 12))

      this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt / 12;
    }
    this._ductDesign.perfCMax = this.perfCMax;

    this._letterSolidDuctLengthNoSingle = 'D';
    let r = this.storageConfiguration.diameter * 6;
    this._solidDuctLengthNoSingle = r - this._ductDesign.perforatedDuctLengthFt * 12;
    if (this._solidDuctLengthNoSingle < 0) {
      this._solidDuctLengthNoSingle = 0;
    }
    this._solidDuctLengthNoSingleFtIn = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this._solidDuctLengthNoSingle);
    this._ductDesign.solidDuctLengthNoSingle = this._solidDuctLengthNoSingle;
    this._ductDesign.letterSolidDuctLengthNoSingle = this._letterSolidDuctLengthNoSingle;

    this.setActVelocities();
  }

  private setParamsForConicalWithHoppitch(isPreDefParams: boolean = false) {
    this._ductDesign.numberOfDucts = this.airflowConfiguration.numberfans * this._ductDesign.ductsPerFan;

    if (isPreDefParams) {
      this._ductDesign.degreesBetweenFans = 360 / this.airflowConfiguration.numberfans;
      this._ductDesign.degreesBetweenDucts = this._ductDesign.degreesBetweenFans / this._ductDesign.ductsPerFan;

      this.surface = this.setDuctDiameter(this.lowerLarge);

      this._ductDesign.openingDiameter = this._ductDesign.ductDiameter;
    }

    let hopperHeight = (Math.tan(this.storageConfiguration.hopperPitch * Math.PI / 180) * (this.storageConfiguration.diameter / 2 - this.storageConfiguration.ledge)) -
      (Math.tan(this.storageConfiguration.hopperPitch * Math.PI / 180) * (this.storageConfiguration.outletDiameter / 2));

    this.perfDMax = Math.sqrt(Math.pow(hopperHeight, 2) + Math.pow((this.storageConfiguration.diameter / 2) -
      this.storageConfiguration.ledge - (this.storageConfiguration.outletDiameter / 2), 2));
    this.perfCMax = this.storageConfiguration.ledge;

    if (this.storageConfiguration.steamwall > 0) {
      this.perfCMax = this.perfCMax - (this._ductDesign.openingDiameter / 12);
    }

    let x = this.getPerfLengthCoefHoppitchConical();

    if (isPreDefParams) {
      this._ductDesign.perforatedDuctLengthFt = x * this.perfDMax;
      this._ductDesign.perforatedCDuctLengthFt = x * this.perfCMax;
    }

    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      this.calcFullDuctPerfLength(isPreDefParams, x);
    } else {
      this.perfCMax = Math.floor(this.perfCMax * 12);

      while (this.perfCMax / 16 !== Math.floor(this.perfCMax / 16)) {
        this.perfCMax = this.perfCMax - 1;
        this.perfCMax = Math.round(this.perfCMax);
      }

      this.perfDMax = Math.floor(this.perfDMax * 12);

      while (this.perfDMax / 16 !== Math.floor(this.perfDMax / 16)) {
        this.perfDMax = this.perfDMax - 1;
        this.perfDMax = Math.round(this.perfDMax);
      }

      if (this.perfCMax < 0) {
        this.perfCMax = 0;
      }

      if (this.perfDMax < 16) {
        this.perfDMax = 16;
      }

      this._ductDesign.perforatedCDuctLengthFt = this._ductDesign.perforatedCDuctLengthFt * 12;
      if (this._ductDesign.perforatedCDuctLengthFt > this.perfCMax) {
        this._ductDesign.perforatedCDuctLengthFt = this.perfCMax;
      }

      this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt * 12;
      if (this._ductDesign.perforatedDuctLengthFt > this.perfDMax) {
        this._ductDesign.perforatedDuctLengthFt = this.perfDMax;
      }
      this._ductDesign.perforatedCDuctLengthFt = Math.floor(this._ductDesign.perforatedCDuctLengthFt);
      this._ductDesign.perforatedDuctLengthFt = Math.floor(this._ductDesign.perforatedDuctLengthFt);

      while (this._ductDesign.perforatedCDuctLengthFt / 16 !== Math.floor(this._ductDesign.perforatedCDuctLengthFt / 16)) {
        this._ductDesign.perforatedCDuctLengthFt = this._ductDesign.perforatedCDuctLengthFt - 1;
        this._ductDesign.perforatedCDuctLengthFt = Math.round(this._ductDesign.perforatedCDuctLengthFt);
      }

      while (this._ductDesign.perforatedDuctLengthFt / 16 !== Math.floor(this._ductDesign.perforatedDuctLengthFt / 16)) {
        this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt - 1;
        this._ductDesign.perforatedDuctLengthFt = Math.round(this._ductDesign.perforatedDuctLengthFt);
      }

      if (this._ductDesign.perforatedCDuctLengthFt < 0) {
        this._ductDesign.perforatedCDuctLengthFt = 0;
      }

      if (this._ductDesign.perforatedDuctLengthFt < 16) {
        this._ductDesign.perforatedDuctLengthFt = 16;
      }

      this._ductDesign.perforatedCDuctLengthIn = Math.round(this._ductDesign.perforatedCDuctLengthFt - (Math.floor(this._ductDesign.perforatedCDuctLengthFt / 12) * 12))
      this._ductDesign.perforatedCDuctLengthFt = this._ductDesign.perforatedCDuctLengthFt / 12;


      this._ductDesign.perforatedDuctLengthIn = Math.round(this._ductDesign.perforatedDuctLengthFt - (Math.floor(this._ductDesign.perforatedDuctLengthFt / 12) * 12))
      this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt / 12;
    }

    this._ductDesign.perfCMax = this.perfCMax;
    this._ductDesign.perfDMax = this.perfDMax;

    this._letterSolidDuctLengthNoSingle = 'E';
    let rWithoutOutletAndLarge = this.storageConfiguration.diameter * 6 - this.storageConfiguration.outletDiameter * 6 - this.storageConfiguration.ledge * 12;
    let hopperPitchSide = rWithoutOutletAndLarge / Math.cos(this.storageConfiguration.hopperPitch * Math.PI / 180);
    this._solidDuctLengthNoSingle = hopperPitchSide - this._ductDesign.perforatedDuctLengthFt * 12;
    if (this._solidDuctLengthNoSingle < 0) {
      this._solidDuctLengthNoSingle = 0;
    }
    this._solidDuctLengthNoSingleFtIn = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this._solidDuctLengthNoSingle);
    this._ductDesign.solidDuctLengthNoSingle = this._solidDuctLengthNoSingle;
    this._ductDesign.letterSolidDuctLengthNoSingle = this._letterSolidDuctLengthNoSingle;

    if (this.storageConfiguration.ledge > 0) {
      this._letterSolidDuctCLengthNoSingle = 'F';
      this._solidDuctCLengthNoSingle = (this.storageConfiguration.ledge - this._ductDesign.perforatedCDuctLengthFt) * 12;
      if (this._solidDuctCLengthNoSingle < 0) {
        this._solidDuctCLengthNoSingle = 0;
      }
      this._solidDuctCLengthNoSingleFtIn = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this._solidDuctCLengthNoSingle);
      this._ductDesign.solidDuctCLengthNoSingle = this._solidDuctCLengthNoSingle;
      this._ductDesign.letterSolidDuctCLengthNoSingle = this._letterSolidDuctCLengthNoSingle;
    }

    this.setActVelocities();
  }

  private getPerfLengthCoefHoppitchConical(): number {
    let ftPerf = this.getFtPerf(this.lowerLarge, this.surface);

    let maxPerf;
    if (this.perfCMax > 0) {
      maxPerf = this.perfCMax + this.perfDMax;
    } else {
      maxPerf = this.perfDMax;
    }

    return ftPerf / maxPerf;
  }

  private calcFullDuctPerfLength(isPreDefParams: boolean, x: number) {
    this.perfDMax = Math.floor(this.perfDMax);

    if (this.perfDMax / 2 !== Math.floor(this.perfDMax / 2)) {
      this.perfDMax = this.perfDMax - 1;
    }

    this.perfCMax = Math.floor(this.perfCMax);

    if (this.perfCMax / 2 !== Math.floor(this.perfCMax / 2)) {
      this.perfCMax = this.perfCMax - 1;
    }

    if (this.perfDMax < 2) {
      this.perfDMax = 2;
    }

    if (this.perfCMax < 0) {
      this.perfCMax = 0;
    }

    this._ductDesign.perforatedCDuctLengthFt = Math.floor(Math.floor(this._ductDesign.perforatedCDuctLengthFt * 2) / 2);
    if (this._ductDesign.perforatedCDuctLengthFt / 2 !== Math.floor(this._ductDesign.perforatedCDuctLengthFt / 2)) {
      this._ductDesign.perforatedCDuctLengthFt = this._ductDesign.perforatedCDuctLengthFt + 1;
    }

    this._ductDesign.perforatedDuctLengthFt = Math.floor(Math.floor(this._ductDesign.perforatedDuctLengthFt * 2) / 2);
    if (this._ductDesign.perforatedDuctLengthFt / 2 !== Math.floor(this._ductDesign.perforatedDuctLengthFt / 2)) {
      this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt + 1;
    }

    if (isPreDefParams) {
      if (this._ductDesign.perforatedCDuctLengthFt < x * this.perfCMax) {
        this._ductDesign.perforatedCDuctLengthFt = this._ductDesign.perforatedCDuctLengthFt + 2;
      }

      if (this._ductDesign.perforatedDuctLengthFt < x * this.perfDMax) {
        this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt + 2;
      }
    }

    if (this._ductDesign.perforatedCDuctLengthFt > this.perfCMax) {
      this._ductDesign.perforatedCDuctLengthFt = this.perfCMax;
    }

    if (this._ductDesign.perforatedDuctLengthFt > this.perfDMax) {

      this._ductDesign.perforatedDuctLengthFt = this.perfDMax;
    }

    if (this._ductDesign.perforatedCDuctLengthFt < 0) {
      this._ductDesign.perforatedCDuctLengthFt = 0;
    }

    if (this._ductDesign.perforatedDuctLengthFt < 2) {
      this._ductDesign.perforatedDuctLengthFt = 2;
    }
  }

  private setParamsForSideDrawWithHoppitch(isPreDefParams: boolean = false) {
    this._ductDesign.numberOfDucts = this.airflowConfiguration.numberfans * this._ductDesign.ductsPerFan;

    if (isPreDefParams) {
      this._ductDesign.degreesBetweenFans = 30;

      if (this._ductDesign.degreesBetweenFans * this.airflowConfiguration.numberfans > 90) {
        this._ductDesign.degreesBetweenFans = 90 / this.airflowConfiguration.numberfans;
      }

      this._ductDesign.degreesBetweenDucts = this._ductDesign.degreesBetweenFans / this._ductDesign.ductsPerFan;
    }

    this._ductDesign.degreesBetweenFans = Math.round(this._ductDesign.degreesBetweenFans * 10) / 10;
    this._ductDesign.degreesBetweenDucts = Math.round(this._ductDesign.degreesBetweenDucts * 10) / 10;

    let ftPerf: number;
    if (isPreDefParams) {
      this.surface = this.setDuctDiameter(this.lowerLarge);
      this._ductDesign.openingDiameter = this._ductDesign.ductDiameter;

      ftPerf = this.getFtPerf(this.lowerLarge, this.surface);
    }

    let coneHeight = Math.tan(this.storageConfiguration.hopperPitch * Math.PI / 180) * this.storageConfiguration.diameter;
    let outerDegree = (((this.airflowConfiguration.numberfans - 1) * this._ductDesign.degreesBetweenFans) / 2) +
      (((this._ductDesign.ductsPerFan - 1) * this._ductDesign.degreesBetweenDucts) / 2);

    let innerDegree = 180 - (2 * outerDegree);
    let innerFanDegree = 2 * this._ductDesign.degreesBetweenFans;
    let innerDuctDegree = 2 * this._ductDesign.degreesBetweenDucts;

    this._ductDesign.coneHeight = coneHeight;
    this._ductDesign.outerDegree = outerDegree;
    this._ductDesign.innerDegree = innerDegree;
    this._ductDesign.innerFanDegree = innerFanDegree;
    this._ductDesign.innerDuctDegree = innerDuctDegree;

    let interceptDegree;
    if (this._ductDesign.ductsPerFan == 1) {
      interceptDegree = this._ductDesign.degreesBetweenFans / 2;
    } else {
      interceptDegree = this._ductDesign.degreesBetweenDucts / 2
    }

    let roverlap;
    if (this._ductDesign.ductsPerFan * this.airflowConfiguration.numberfans > 1) {
      roverlap = (this._ductDesign.ductDiameter / 24) / Math.sin(interceptDegree * Math.PI / 180);
    } else {
      roverlap = 0;
    }

    let rLimitter;
    if (this.storageConfiguration.outletDiameter > roverlap) {
      rLimitter = this.storageConfiguration.outletDiameter;
    } else {
      rLimitter = roverlap;
    }

    let Y = Math.sin(innerDegree * Math.PI / 180) * (this.storageConfiguration.diameter / 2);
    let X = Math.cos(innerDegree * Math.PI / 180) * (this.storageConfiguration.diameter / 2);

    let YY = Math.sin((180 - outerDegree) * Math.PI / 180) * rLimitter;
    let XX = Math.cos((180 - outerDegree) * Math.PI / 180) * rLimitter;

    X = this.storageConfiguration.diameter / 2 + X;
    XX = this.storageConfiguration.diameter + XX;

    this.perfDMax = Math.sqrt(Math.pow((XX - X), 2) + Math.pow((YY - Y), 2));

    let hopPitchMax = Math.sqrt(Math.pow((this.storageConfiguration.diameter - X), 2) + Math.pow(Y, 2));
    hopPitchMax = Math.atan(coneHeight / hopPitchMax);
    this.perfDMax = this.perfDMax / Math.cos(hopPitchMax);

    if (isPreDefParams) {
      this._ductDesign.perforatedDuctLengthFt = ftPerf;
    }

    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      this._ductDesign.perforatedDuctLengthFt = Math.floor(this._ductDesign.perforatedDuctLengthFt);

      if (this._ductDesign.perforatedDuctLengthFt > this.perfDMax) {
        this._ductDesign.perforatedDuctLengthFt = Math.floor(this.perfDMax);
      }

      if (Math.floor(this._ductDesign.perforatedDuctLengthFt / 2) !== this._ductDesign.perforatedDuctLengthFt / 2) {
        this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt - 1;
      }

      if (this._ductDesign.perforatedDuctLengthFt < 2) {
        this._ductDesign.perforatedDuctLengthFt = 2;
      }

      this._ductDesign.perfDMax = this.perfDMax;
    } else {
      let inchesPerfD = Math.floor(this._ductDesign.perforatedDuctLengthFt * 12);

      if (inchesPerfD > this.perfDMax * 12) {
        inchesPerfD = Math.floor(this.perfDMax * 12);
      }

      while (Math.floor(inchesPerfD / 16) !== inchesPerfD / 16) {
        inchesPerfD = inchesPerfD - 1;
      }

      if (inchesPerfD < 16) {
        inchesPerfD = 16;
      }

      this._ductDesign.perforatedDuctLengthFt = inchesPerfD / 12;
      this._ductDesign.perforatedDuctLengthIn = Math.round(inchesPerfD - (Math.floor(inchesPerfD / 12) * 12));

      let inchesDMax = Math.floor(this.perfDMax * 12);

      while (Math.floor(inchesDMax / 16) !== inchesDMax / 16) {
        inchesDMax = inchesDMax - 1;
      }

      if (inchesDMax < 16) {
        inchesDMax = 16;
      }

      this._ductDesign.perfDMax = Math.round(inchesDMax / 12);
    }

    this._letterSolidDuctLengthNoSingle = 'D';
    this._solidDuctLengthNoSingle = (this._ductDesign.perfDMax - this._ductDesign.perforatedDuctLengthFt) * 12;
    if (this._solidDuctLengthNoSingle < 0) {
      this._solidDuctLengthNoSingle = 0;
    }
    this._solidDuctLengthNoSingleFtIn = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this._solidDuctLengthNoSingle);

    this._ductDesign.solidDuctLengthNoSingle = this._solidDuctLengthNoSingle;
    this._ductDesign.letterSolidDuctLengthNoSingle = this._letterSolidDuctLengthNoSingle;

    this.setActVelocities();
  }

  private calcSolidAndPerforatedLength(isPreDefParams: boolean) {
    switch (this._ductDesign.ductType) {
      case AqsDuctTypeEnum.FullRoundDuct:

        this._ductDesign.perforatedDuctLengthFt = Math.floor(this._ductDesign.perforatedDuctLengthFt);

        if (this._ductDesign.perforatedDuctLengthFt > this.perfDMax) {
          this._ductDesign.perforatedDuctLengthFt = Math.floor(this.perfDMax)
        }

        if (Math.floor(this._ductDesign.perforatedDuctLengthFt / 2) !== this._ductDesign.perforatedDuctLengthFt / 2) {
          this._ductDesign.perforatedDuctLengthFt = this._ductDesign.perforatedDuctLengthFt - 1;
        }

        if (this._ductDesign.perforatedDuctLengthFt < 2) {
          this._ductDesign.perforatedDuctLengthFt = 2;
        }

        if (isPreDefParams) {
          if (this.storageConfiguration.steamwall > 0) {
            this._ductDesign.solidDuctLengthFt = Math.floor((this.perfDMax +
              (this._ductDesign.openingDiameter / 12) -
              this._ductDesign.perforatedDuctLengthFt) / 2);
          } else {
            this._ductDesign.solidDuctLengthFt = Math.floor((this.perfDMax - this._ductDesign.perforatedDuctLengthFt) / 2);
          }
        }

        if (this._ductDesign.solidDuctLengthFt / 2 !== Math.floor(this._ductDesign.solidDuctLengthFt / 2)) {
          this._ductDesign.solidDuctLengthFt = this._ductDesign.solidDuctLengthFt - 1;
        }

        if (this._ductDesign.solidDuctLengthFt < 0) {
          this._ductDesign.solidDuctLengthFt = 0;;
        }
        break;
      case AqsDuctTypeEnum.HalfRoundDuct:
        let inches = Math.floor(this._ductDesign.perforatedDuctLengthFt * 12);

        if (inches > this.perfDMax * 12) {
          inches = Math.floor(this.perfDMax * 12);
        }

        while (Math.floor(inches / 16) !== inches / 16) {
          inches = inches - 1;
        }

        if (inches < 16) {
          inches = 16;
        }

        this._ductDesign.perforatedDuctLengthFt = inches / 12;
        this._ductDesign.perforatedDuctLengthIn = Math.round(inches - (Math.floor(inches / 12) * 12));

        if (isPreDefParams) {
          if (this.storageConfiguration.steamwall > 0) {
            this._ductDesign.solidDuctLengthFt = ((this.perfDMax +
              (this._ductDesign.openingDiameter / 12) -
              this._ductDesign.perforatedDuctLengthFt) / 2)
          } else {
            this._ductDesign.solidDuctLengthFt = ((this.perfDMax - this._ductDesign.perforatedDuctLengthFt) / 2);
          }
        }

        inches = Math.floor(this._ductDesign.solidDuctLengthFt * 12);

        if (inches < 0) {
          inches = 0;
        }

        while (Math.floor(inches / 16) !== inches / 16) {
          inches = inches - 1;
        }

        this._ductDesign.solidDuctLengthFt = inches / 12;
        this._ductDesign.solidDuctLengthIn = Math.round(inches - (Math.floor(inches / 12) * 12));

        break;
      default:
        break;
    }
  }

  private setActVelocities() {
    if (this._ductDesign.ductType == AqsDuctTypeEnum.HalfRoundDuct) {
      if (Math.PI * Math.pow(this._ductDesign.openingDiameter, 2) <= Math.PI / 2 * Math.pow(this._ductDesign.ductDiameter, 2)) {
        this._ductDesign.actVelocities.crossSec = this.lowerLarge / (Math.pow(this._ductDesign.openingDiameter / 24, 2) * Math.PI * this._ductDesign.numberOfDucts);
      } else {
        this._ductDesign.actVelocities.crossSec = this.lowerLarge / (Math.pow(this._ductDesign.ductDiameter / 24, 2) * (Math.PI / 2) * this._ductDesign.numberOfDucts);
      }
    } else if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      if (this._ductDesign.openingDiameter <= this._ductDesign.ductDiameter) {
        this._ductDesign.actVelocities.crossSec = this.lowerLarge / (Math.pow(this._ductDesign.openingDiameter / 24, 2) * Math.PI * this._ductDesign.numberOfDucts);
      } else {
        this._ductDesign.actVelocities.crossSec = this.lowerLarge / (Math.pow(this._ductDesign.ductDiameter / 24, 2) * Math.PI * this._ductDesign.numberOfDucts);
      }
    }

    this._ductDesign.actVelocities.crossSec = Math.floor(this._ductDesign.actVelocities.crossSec + 0.5);

    this._ductDesign.actVelocities.surface = (this.lowerLarge / this._ductDesign.numberOfDucts) / this.surface;
    if (this.perfCMax > 0 && this._ductDesign.perforatedCDuctLengthFt > 0) {
      this._ductDesign.actVelocities.surface = this._ductDesign.actVelocities.surface /
        (this._ductDesign.perforatedDuctLengthFt + this._ductDesign.perforatedCDuctLengthFt);
    } else {
      this._ductDesign.actVelocities.surface = this._ductDesign.actVelocities.surface / this._ductDesign.perforatedDuctLengthFt;
    }
    this._ductDesign.actVelocities.surface = Math.floor(this._ductDesign.actVelocities.surface * 10 + 0.5) / 10;
  }

  private getFtPerf(lowerLarge: number, surface: number): number {
    let reqdPerf = (lowerLarge / this._ductDesign.numberOfDucts) / this._ductDesign.maxVelocities.surface;
    let ftPerf = reqdPerf / surface;
    let x = ftPerf;
    ftPerf = Math.floor(Math.floor(ftPerf * 2) / 2)

    if (ftPerf / 2 !== Math.floor(ftPerf / 2)) {
      ftPerf = ftPerf + 1;
    }

    if (ftPerf < x) {
      ftPerf = ftPerf + 2;
    }

    return ftPerf;
  }

  private setDuctDiameter(lowerLarge: number): number {
    let minCSA = lowerLarge / (this._ductDesign.maxVelocities.crossSec * this._ductDesign.numberOfDucts);
    let surface;
    if (this._ductDesign.ductType == AqsDuctTypeEnum.HalfRoundDuct) {
      this._ductDesign.ductDiameter = 36;
      surface = 4.666;
    } else {
      for (let item of this.cfr) {
        if (Math.pow((item.diameter / 24), 2) * Math.PI >= minCSA) {
          this._ductDesign.ductDiameter = item.diameter;
          surface = item.surface;
          break;
        }
      }

      if (!this._ductDesign.ductDiameter) {
        this._ductDesign.ductDiameter = this.cfr[this.cfr.length - 1].diameter;
        surface = this.cfr[this.cfr.length - 1].surface;
      }
    }

    return surface;
  }

  private setMaxVelocities() {
    if (this.airflowConfiguration.fanType == AqsAirflowFanTypeEnum.Axial) {
      this._ductDesign.maxVelocities.crossSec = 3000;
      this._ductDesign.maxVelocities.surface = 40;
    } else {
      let selectedFan = parseFloat(this.airflowConfiguration.selectedFan.fanName);

      if (this.airflowConfiguration.fanType == AqsAirflowFanTypeEnum.Centrifugal60HZ &&
        (selectedFan < 2250 && selectedFan !== 2003 && selectedFan !== 2205)) {
        this._ductDesign.maxVelocities.crossSec = 4000;
        this._ductDesign.maxVelocities.surface = 50;
      } else {
        this._ductDesign.maxVelocities.crossSec = 3500;
        this._ductDesign.maxVelocities.surface = 45;
      }
    }
  }

  private parseFtInLengthStrToIn(length: string): number {
    let ftDistance: number;
    let inDistance: number;

    if (length !== '') {
      let ftInDistance = length.split('-');

      if (!_isNumberValue(ftInDistance[0])) {
        return null;
      } else {
        ftDistance = Math.round(Number(ftInDistance[0]))
      }

      if (_isNumberValue(ftInDistance[1])) {
        inDistance = Math.round(Number(ftInDistance[1]))
      }
      else {
        if (ftInDistance[1] == '' && ftInDistance[2] && _isNumberValue(ftInDistance[2])) {
          inDistance = - Math.round(Number(ftInDistance[2]))
        } else {
          inDistance = 0;
        }
      }
    } else {
      return null;
    }

    let inches = (ftDistance * 12) + inDistance;

    return inches;
  }

  numberAndHyphenOnly(event) {
    if (!_isNumberValue(event.key) &&
      event.key !== '-' &&
      event.key !== '.' &&
      event.key !== 'Enter') {
      event.preventDefault();
    }
  }

}