import { AqsPagesEnum } from "../enums";

export const AqsPageLabel = {
    [AqsPagesEnum.CoverSheet]: 'Cover Sheet',
    [AqsPagesEnum.RoundStorageConfiguration]: 'Round storage',
    [AqsPagesEnum.FlatStorageConfiguration]: 'Flat storage',
    [AqsPagesEnum.DetermineTargetAirflow]: 'Airflow',
    [AqsPagesEnum.ExhausterRecommendations]: 'Exhauster',
    [AqsPagesEnum.FlushFloorDesign]: 'Flush Design',
    [AqsPagesEnum.DuctDesign]: 'Duct Design',
}