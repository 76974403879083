import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorModel, HttpService } from '../../../common';
import { ApiRoutes } from '../../constants';
import { CapacityUnitEnum, ItxBinTypeEnum, ItxRadarLocationEnum, SizeMeasureEnum, TemperatureMeasureEnum } from '../../enums';
import {
  AddItxBinModel, ItxBinGetModel, ItxBinViewModel, EditItxBinModel, RadarPostModel, RadarViewModel, ItxSchemeLiveData,
  UserPreferences, ItxBinLiveDataModel, ItxBinStaticMembersResponse, ItxStaticScemeData, PeriodToGetliveDataForLocations,
  RadarLiveDataModel, UserPreferencesPost, ItxBinParametersRequest, ReportDataFilterPost, ReportHistoricalDataResponse, ItxBinQuickEditPostModel, ItxLocationReportFilter, ItxBinLiveDataViewModel, ItxBinTelemetryWithPreference
} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ItxBinApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router) { }

  public async getItxBinsByLocationId(locationId: number): Promise<ItxBinViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const result = await this._httpService.get(ApiRoutes.ItxBin.getItxBinsByLocationId, { locationId: locationId });
      return result.map((item) => {
        return ItxBinGetModel.toViewModel(item);
      });
    } catch (error) {
      return this.processError(error);
    }
  }

  public async setUserPreferences(userPreferences: UserPreferencesPost): Promise<UserPreferences | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeUserPreference();
      }
      const result = await this._httpService.post(ApiRoutes.ItxBin.setUserPreferences, userPreferences);
      return result;
    } catch (error) {
      return this.processError(error);
    }

  }

  public async add(addItxBin: AddItxBinModel): Promise<ItxBinViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.ItxBin.add, addItxBin);
      const itxBin = ItxBinGetModel.toViewModel(result);
      return itxBin;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async edit(editItxBin: EditItxBinModel): Promise<ItxBinViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.ItxBin.edit, editItxBin);
      const itxBin = ItxBinGetModel.toViewModel(result);
      return itxBin;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async delete(itxBinId: number): Promise<boolean | ErrorModel> {
    try {
      const result = this._httpService.delete(ApiRoutes.ItxBin.delete, { itxBinId });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async addRadar(radar: RadarPostModel): Promise<RadarViewModel | ErrorModel> {
    try {

      const result = await this._httpService.post(ApiRoutes.ItxBin.addRadar, radar)
      let toRet = result as RadarViewModel;
      return toRet;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async editRadar(radar: RadarPostModel): Promise<RadarViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.ItxBin.editRadar, radar)
      let toRet = result as RadarViewModel;
      return toRet;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async deleteRadar(radarId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.delete(ApiRoutes.ItxBin.deleteRadar, { radarId });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }
    console.log(error);
    return null;
  }

  public async getSchemeForLiveData(requestParameters: ItxBinParametersRequest,): Promise<ItxSchemeLiveData | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeBinScheme();
      }
      const result = await this._httpService.post(ApiRoutes.ItxBin.getSchemeForLiveData, requestParameters);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getStaticItxBinSchemeData(): Promise<ItxStaticScemeData | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getStaticItxBinScheme();
      }
      const result = await this._httpService.get(ApiRoutes.ItxBin.getStaticSchemeForFiveData);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getLocationReportFilters(): Promise<ItxLocationReportFilter[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeLocationFilters();
      }
      const result = await this._httpService.get(ApiRoutes.ItxBin.getLocationReportFilters);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getDataForLiveData(bins: number[]): Promise<ItxBinLiveDataModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeLiveData();
      }
      let result = await this._httpService.get(ApiRoutes.ItxBin.getDataForLiveData, { bins });
      result = result as ItxBinLiveDataModel[]
      let rd: RadarLiveDataModel[];
      result.forEach(bin => {
        rd = JSON.parse(bin.radars);
        bin.radars = rd;
        bin.hasRadarError = rd.some(r => r.isRadarTelemetryError);
      });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async quickEdit(editItxBin: ItxBinQuickEditPostModel): Promise<ItxBinViewModel | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.ItxBin.quicEdit, editItxBin);
      const itxBin = ItxBinGetModel.toViewModel(result);
      return itxBin;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getItxBinLiveData(binId: number): Promise<ItxBinLiveDataViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return ItxBinTelemetryWithPreference.toBinLiveDataViewModel(this.getFakeBinLiveData(binId));
      }
      let result = await this._httpService.get(ApiRoutes.ItxBin.getRadarTelemetryWithUserPreferences, { binId: binId });
      result = result as ItxBinTelemetryWithPreference;
      result.telemetry.radars = JSON.parse(result.telemetry.radars);
      return ItxBinTelemetryWithPreference.toBinLiveDataViewModel(result);
    } catch (error) {
      return this.processError(error);
    }
  }

  private getFakeUserPreference(): UserPreferences {
    let data = {
      id: 1, capacityLoosUnit: CapacityUnitEnum.Bsh, capacityLiquidUnit: CapacityUnitEnum.Gal, temperatureMeasure: TemperatureMeasureEnum.Celsius,
      sizeMeasure: SizeMeasureEnum.In
    } as UserPreferences;
    return data;
  }

  private getFakeBinLiveData(binId: number): ItxBinTelemetryWithPreference {
    let binLiveData = new ItxBinTelemetryWithPreference();
    binLiveData.userPreference = this.getFakeUserPreference();
    binLiveData.itxBin = {
      id: binId, name: 'fakeBin3', locationId: 1, height: 30, width: 40, depth: 0, material: 'wheat', totalCapacity: 30, capacityUnit: CapacityUnitEnum.Ft_3, countOfRadars: 4, binType: ItxBinTypeEnum.SiloConical, roofAngle: 30.96, roofCap: 13.4,
      tiltAngle: 15, sizeMeasure: SizeMeasureEnum.Ft, radars: [{ id: 6, radarId: 6, radarIdAddress: 6, radarLocation: 0, distanceFromBinCenter: 20 }, { id: 7, radarId: 7, radarIdAddress: 7, radarLocation: 1, distanceFromBinCenter: 10 }, { id: 8, radarId: 8, radarIdAddress: 8, radarLocation: 2, distanceFromBinCenter: 5 }] as RadarViewModel[]
    };
    binLiveData.telemetry = {
      id: 0, binHeadSpace: 63, binFullnessCapacity: 92, binTemperature: 77, binPercentFullness: 17, radars: [
        { radarId: 172, temperature: 73, headSpace: 44, percentFull: 60, capacity: 58, radarLocation: 0, distanceFromBinCenter: 20, isRadarTelemetryError: true } as RadarLiveDataModel,
        { radarId: 129, temperature: 71, headSpace: 40, percentFull: 45, capacity: 2, radarLocation: 1, distanceFromBinCenter: 10 } as RadarLiveDataModel,
        { radarId: 155, temperature: -5, headSpace: 64, percentFull: 42, capacity: 78, radarLocation: 2, distanceFromBinCenter: 0 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 999999, calculatedAvailableCapacity: 999999, calculatedErrorBound: 30, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel

    return binLiveData;
  }

  private getFakeData(): ItxBinViewModel[] {
    let data = [
      {
        id: 1, name: 'fakeBin1', locationId: 1, height: 10, width: 20, depth: 25, material: 'corn', totalCapacity: 10, capacityUnit: CapacityUnitEnum.Ft_3, countOfRadars: 3, binType: ItxBinTypeEnum.FlatStorageLevel,
        tiltAngle: 10, sizeMeasure: SizeMeasureEnum.Ft, radars: [{ id: 1, radarId: 1, radarIdAddress: 1 }, { id: 2, radarId: 2, radarIdAddress: 2, radarLocation: null, distanceFromBinCenter: null }, { id: 3, radarId: 3, radarIdAddress: 3, radarLocation: null, distanceFromBinCenter: null }] as RadarViewModel[], isSomeoneUpdateStorage: false
      },
      {
        id: 2, name: 'fakeBin2', locationId: 1, height: 20, width: 30, depth: 0, material: 'oil', totalCapacity: 20, capacityUnit: CapacityUnitEnum.Bbl, countOfRadars: 2, binType: ItxBinTypeEnum.LiquidStorageConical,
        tiltAngle: 10, sizeMeasure: SizeMeasureEnum.Ft, radars: [{ id: 4, radarId: 4, radarIdAddress: 4, radarLocation: null, distanceFromBinCenter: null }, { id: 5, radarId: 5, radarIdAddress: 5, radarLocation: null, distanceFromBinCenter: null }] as RadarViewModel[], isSomeoneUpdateStorage: false
      },
      {
        id: 3, name: 'fakeBin3', locationId: 1, height: 30, width: 40, depth: 0, material: 'wheat', totalCapacity: 30, capacityUnit: CapacityUnitEnum.Ft_3, countOfRadars: 4, binType: ItxBinTypeEnum.SiloConical, roofAngle: 30.96, roofCap: 13.4,
        tiltAngle: 15, sizeMeasure: SizeMeasureEnum.Ft, radars: [{ id: 6, radarId: 6, radarIdAddress: 6, radarLocation: 0, distanceFromBinCenter: 20 }, { id: 7, radarId: 7, radarIdAddress: 7, radarLocation: 1, distanceFromBinCenter: 10 }, { id: 8, radarId: 8, radarIdAddress: 8, radarLocation: 2, distanceFromBinCenter: 5 }] as RadarViewModel[], isSomeoneUpdateStorage: false
      },
    ] as ItxBinViewModel[]
    return data;
  }

  private getFakeBinScheme(): ItxSchemeLiveData {
    let data = {
      itxBinSchemeResponses: [
        {
          id: 0, name: "FakeName23FakeName23", locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", binType: 0, sizeUnit: 0,
          material: "oil", totalCapacity: 94, capacityUnit: 6,
        },
        {
          id: 1, name: "MilletStorage", locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", binType: 1,
          sizeUnit: 0, material: "Millet", totalCapacity: 100, capacityUnit: 2
        },
        {
          id: 2, name: "Fake565", locationId: 5, locationName: "Second Location FAKE", companyId: 1, companyName: "My first company FAKE1", binType: 2,
          sizeUnit: 0, material: "Corn", totalCapacity: 500, capacityUnit: 1
        },

        {
          id: 3, name: "FakeName23 FakeName23", locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", binType: 3, sizeUnit: 0,
          material: "oil", totalCapacity: 100, capacityUnit: 6,
        },
        {
          id: 4, name: "MilletStorage", locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", binType: 4,
          sizeUnit: 0, material: "Millet", totalCapacity: 100, capacityUnit: 2
        },
        {
          id: 5, name: "Fake565", locationId: 5, locationName: "Second Location FAKE", companyId: 1, companyName: "My first company FAKE1", binType: 5,
          sizeUnit: 0, material: "Corn", totalCapacity: 400, capacityUnit: 1
        },

        {
          id: 6, name: "FakeName23", locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", binType: 6, sizeUnit: 0,
          material: "oil", totalCapacity: 0, capacityUnit: 6,
        },
        {
          id: 7, name: "MilletStorage", locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", binType: 7,
          sizeUnit: 0, material: "Millet", totalCapacity: 0, capacityUnit: 2
        },
        {
          id: 8, name: "Fake565", locationId: 5, locationName: "Second Location FAKE", companyId: 1, companyName: "My first company FAKE1", binType: 8,
          sizeUnit: 0, material: "Corn", totalCapacity: 0, capacityUnit: 1
        },

        {
          id: 9, name: "FakeName23", locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", binType: 9, sizeUnit: 0,
          material: "oil", totalCapacity: 0, capacityUnit: 6,
        },
        {
          id: 10, name: "MilletStorage", locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", binType: 10,
          sizeUnit: 0, material: "Millet", totalCapacity: 0, capacityUnit: 2
        },
        {
          id: 11, name: "Fake565", locationId: 5, locationName: "Second Location FAKE", companyId: 1, companyName: "My first company FAKE1", binType: 11,
          sizeUnit: 0, material: "Corn", totalCapacity: 0, capacityUnit: 1
        },
      ],
      totalFilteredCount: 12

    } as ItxSchemeLiveData
    return data;
  }

  private getFakeLiveData(): ItxBinLiveDataModel[] {
    let data = [{
      id: 0, binHeadSpace: 63, binFullnessCapacity: 92, binTemperature: 77, binPercentFullness: 17, radars: [
        { radarId: 172, temperature: 73, headSpace: 44, percentFull: 60, capacity: 58, radarLocation: 0, distanceFromBinCenter: 20 } as RadarLiveDataModel,
        { radarId: 129, temperature: 71, headSpace: 40, percentFull: 45, capacity: 2, radarLocation: 1, distanceFromBinCenter: 10 } as RadarLiveDataModel,
        { radarId: 155, temperature: -5, headSpace: 64, percentFull: 42, capacity: 78, radarLocation: 2, distanceFromBinCenter: 0 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 999999, calculatedAvailableCapacity: 999999, calculatedErrorBound: 30, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 1, binHeadSpace: 32, binFullnessCapacity: 45, binTemperature: 44, binPercentFullness: 27, radars: [
        { radarId: 43, temperature: 44, headSpace: 55, percentFull: 33, capacity: 66, radarLocation: 0, distanceFromBinCenter: 20 } as RadarLiveDataModel,
        { radarId: 54, temperature: 33, headSpace: 55, percentFull: 22, capacity: 55, radarLocation: 1, distanceFromBinCenter: 15 } as RadarLiveDataModel,
        { radarId: 67, temperature: 55, headSpace: 66, percentFull: 44, capacity: 44, radarLocation: 2, distanceFromBinCenter: 0 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 123345, calculatedAvailableCapacity: 34562, calculatedErrorBound: 44, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 2, binHeadSpace: 600, binFullnessCapacity: 300, binTemperature: 77, binPercentFullness: 30, radars: [
        { radarId: 1, temperature: 54, headSpace: 23, percentFull: 65, capacity: 250, radarLocation: 0, distanceFromBinCenter: 20 } as RadarLiveDataModel,
        { radarId: 2, temperature: 76, headSpace: 40, percentFull: 23, capacity: 300, radarLocation: 1, distanceFromBinCenter: 10 } as RadarLiveDataModel,
        { radarId: 3, temperature: 70, headSpace: 64, percentFull: 42, capacity: 350, radarLocation: 2, distanceFromBinCenter: 0 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 899888, calculatedAvailableCapacity: 20000, calculatedErrorBound: 33, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 3, binHeadSpace: 63, binFullnessCapacity: 92, binTemperature: 77, binPercentFullness: 47, radars: [
        { radarId: 172, temperature: 73, headSpace: 44, percentFull: 60, capacity: 58, radarLocation: 0, distanceFromBinCenter: 30 } as RadarLiveDataModel,
        { radarId: 129, temperature: 71, headSpace: 40, percentFull: 45, capacity: 2, radarLocation: 1, distanceFromBinCenter: 20 } as RadarLiveDataModel,
        { radarId: 155, temperature: -5, headSpace: 64, percentFull: 42, capacity: 78, radarLocation: 2, distanceFromBinCenter: 1 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 786290, calculatedAvailableCapacity: 123765, calculatedErrorBound: 22, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 4, binHeadSpace: 32, binFullnessCapacity: 45, binTemperature: 44, binPercentFullness: 57, radars: [
        { radarId: 43, temperature: 44, headSpace: 55, percentFull: 33, capacity: 66, radarLocation: 0, distanceFromBinCenter: 50 } as RadarLiveDataModel,
        { radarId: 54, temperature: 33, headSpace: 55, percentFull: 22, capacity: 55, radarLocation: 1, distanceFromBinCenter: 30 } as RadarLiveDataModel,
        { radarId: 67, temperature: 55, headSpace: 66, percentFull: 44, capacity: 44, radarLocation: 2, distanceFromBinCenter: 0 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 198279, calculatedAvailableCapacity: 123345, calculatedErrorBound: 10, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 5, binHeadSpace: 600, binFullnessCapacity: 300, binTemperature: 77, binPercentFullness: 60, radars: [
        { radarId: 1, temperature: 54, headSpace: 23, percentFull: 65, capacity: 250, radarLocation: 0, distanceFromBinCenter: 50 } as RadarLiveDataModel,
        { radarId: 2, temperature: 76, headSpace: 40, percentFull: 23, capacity: 300, radarLocation: 1, distanceFromBinCenter: 25 } as RadarLiveDataModel,
        { radarId: 3, temperature: 70, headSpace: 64, percentFull: 42, capacity: 350, radarLocation: 2, distanceFromBinCenter: 10 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 4233456, calculatedAvailableCapacity: 234455, calculatedErrorBound: 12, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 6, binHeadSpace: 63, binFullnessCapacity: 92, binTemperature: 77, binPercentFullness: 22, radars: [
        { radarId: 172, temperature: 73, headSpace: 44, percentFull: 60, capacity: 58 } as RadarLiveDataModel,
        { radarId: 129, temperature: 71, headSpace: 40, percentFull: 45, capacity: 2 } as RadarLiveDataModel,
        { radarId: 155, temperature: -5, headSpace: 64, percentFull: 42, capacity: 78 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 0, calculatedAvailableCapacity: 0, calculatedErrorBound: 0, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 7, binHeadSpace: 32, binFullnessCapacity: 45, binTemperature: 44, binPercentFullness: 87, radars: [
        { radarId: 43, temperature: 44, headSpace: 55, percentFull: 33, capacity: 66 } as RadarLiveDataModel,
        { radarId: 54, temperature: 33, headSpace: 55, percentFull: 22, capacity: 55 } as RadarLiveDataModel,
        { radarId: 67, temperature: 55, headSpace: 66, percentFull: 44, capacity: 44 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 0, calculatedAvailableCapacity: 0, calculatedErrorBound: 0, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 8, binHeadSpace: 600, binFullnessCapacity: 300, binTemperature: 77, binPercentFullness: 42, radars: [
        { radarId: 1, temperature: 54, headSpace: 23, percentFull: 65, capacity: 250 } as RadarLiveDataModel,
        { radarId: 2, temperature: 76, headSpace: 40, percentFull: 23, capacity: 300 } as RadarLiveDataModel,
        { radarId: 3, temperature: 70, headSpace: 64, percentFull: 42, capacity: 350 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 0, calculatedAvailableCapacity: 0, calculatedErrorBound: 0, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 9, binHeadSpace: 63, binFullnessCapacity: 92, binTemperature: 77, binPercentFullness: 57, radars: [
        { radarId: 172, temperature: 73, headSpace: 44, percentFull: 60, capacity: 58 } as RadarLiveDataModel,
        { radarId: 129, temperature: 71, headSpace: 40, percentFull: 45, capacity: 2 } as RadarLiveDataModel,
        { radarId: 155, temperature: -5, headSpace: 64, percentFull: 42, capacity: 78 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 0, calculatedAvailableCapacity: 0, calculatedErrorBound: 0, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 10, binHeadSpace: 32, binFullnessCapacity: 45, binTemperature: 44, binPercentFullness: 21, radars: [
        { radarId: 43, temperature: 44, headSpace: 55, percentFull: 33, capacity: 66 } as RadarLiveDataModel,
        { radarId: 54, temperature: 33, headSpace: 55, percentFull: 22, capacity: 55 } as RadarLiveDataModel,
        { radarId: 67, temperature: 55, headSpace: 66, percentFull: 44, capacity: 44 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 0, calculatedAvailableCapacity: 0, calculatedErrorBound: 0, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel,
    {
      id: 11, binHeadSpace: 600, binFullnessCapacity: 300, binTemperature: 77, binPercentFullness: 62, radars: [
        { radarId: 1, temperature: 54, headSpace: 23, percentFull: 65, capacity: 250 } as RadarLiveDataModel,
        { radarId: 2, temperature: 76, headSpace: 40, percentFull: 23, capacity: 300 } as RadarLiveDataModel,
        { radarId: 3, temperature: 70, headSpace: 64, percentFull: 42, capacity: 350 } as RadarLiveDataModel
      ], calculatedUsedCapacity: 0, calculatedAvailableCapacity: 0, calculatedErrorBound: 0, timestamp: new Date().toString(),
    } as unknown as ItxBinLiveDataModel
    ]
    return data;
  }

  private getStaticItxBinScheme(): ItxStaticScemeData {
    let data = {
      userMeasurePreferences: { id: 1, capacityLoosUnit: 0, capacityLiquidUnit: 5, temperatureMeasure: 0, sizeMeasure: 1 } as UserPreferences,
      locationsLiveData: [
        {
          locationId: 2, locationName: "Clon Arta", companyId: 1, companyName: "My first company FAKE1", fullnessRangeResponses: [
            { id: 1, from: 1, to: 26, colorHex: "#71c5a3" }, { id: 3, from: 27, to: 56, colorHex: "#e1eb28" },
            { id: 4, from: 57, to: 100, colorHex: "#eb2828" }], bins: [
              { binType: ItxBinTypeEnum.BinConical, id: 1, material: "fake material 1", name: "Fake Bin 1" },
              { binType: ItxBinTypeEnum.BinFlat, id: 2, material: "fake material 2", name: "Fake Bin 2" },
              { binType: ItxBinTypeEnum.BinSloped, id: 3, material: "fake material 3", name: "Fake Bin 3" },]
        } as ItxBinStaticMembersResponse,


        {
          locationId: 4, locationName: "First Location FAKE", companyId: 1, companyName: "My first company FAKE1", fullnessRangeResponses: [
            { id: 3, from: 27, to: 56, colorHex: "#e1eb28" }, { id: 4, from: 57, to: 100, colorHex: "#eb2828" },
            { id: 44, from: 1, to: 26, colorHex: "#71c5a3" }], bins: [
              { binType: ItxBinTypeEnum.FlatStorageConical, id: 4, material: "fake material 4", name: "Fake Bin 4" },
              { binType: ItxBinTypeEnum.FlatStorageLevel, id: 5, material: "fake material 5", name: "Fake Bin 5" },
              { binType: ItxBinTypeEnum.FlatStorageSloped, id: 6, material: "fake material 6", name: "Fake Bin 6" },]
        } as ItxBinStaticMembersResponse,

        {
          locationId: 5, locationName: "Second Location FAKE", companyId: 1, companyName: "My first company FAKE1", fullnessRangeResponses: [
            { id: 3, from: 27, to: 56, colorHex: "#e1eb28" }, { id: 4, from: 57, to: 100, colorHex: "#eb2828" }, { id: 44, from: 1, to: 26, colorHex: "#71c5a3" }], bins: [
              { binType: ItxBinTypeEnum.LiquidStorageFlat, id: 7, material: "liquid 1", name: "liquid fake" },
              { binType: ItxBinTypeEnum.LiquidStorageSloped, id: 8, material: "water 2", name: "water fake Bin" },
              { binType: ItxBinTypeEnum.SiloSloped, id: 9, material: "fake material 3", name: "silo sloped" },]
        }],
      storageCount: 11,
      periodToGetDataForLocations: [{ acquisitionRate: 3, locationId: 2 } as PeriodToGetliveDataForLocations, { acquisitionRate: 2, locationId: 4 } as PeriodToGetliveDataForLocations
        , { acquisitionRate: 5, locationId: 5 } as PeriodToGetliveDataForLocations]
    } as ItxStaticScemeData
    return data;
  }

  private getFakeLocationFilters(): ItxLocationReportFilter[] {
    let data = [
      {
        companyId: 1, companyName: 'testComany 1', locationId: 1, locationName: 'testLocation 1', itxBins: [
          {
            id: 1, material: 'test Material 1', name: 'test Bin 1', binType: ItxBinTypeEnum.BinConical, radars: [
              { id: 1, radarId: 1, radarIdAddress: 150, distanceFromBinCenter: 1, radarLocation: ItxRadarLocationEnum.Peak },
              { id: 2, radarId: 2, radarIdAddress: 250, distanceFromBinCenter: 10, radarLocation: ItxRadarLocationEnum.Middle },
              { id: 3, radarId: 3, radarIdAddress: 350, distanceFromBinCenter: 20, radarLocation: ItxRadarLocationEnum.Eave }
            ]
          },
          {
            id: 2, material: 'test Material 2', name: 'test Bin 2', binType: ItxBinTypeEnum.BinFlat, radars: [
              { id: 4, radarId: 4, radarIdAddress: 450, distanceFromBinCenter: 1, radarLocation: ItxRadarLocationEnum.Peak },
              { id: 5, radarId: 5, radarIdAddress: 550, distanceFromBinCenter: 10, radarLocation: ItxRadarLocationEnum.Middle },
              { id: 6, radarId: 6, radarIdAddress: 650, distanceFromBinCenter: 20, radarLocation: ItxRadarLocationEnum.Eave }
            ]
          }
        ]
      },
      {
        companyId: 2, companyName: 'testComany 2', locationId: 1, locationName: 'testLocation 2', itxBins: [
          {
            id: 1, material: 'test Material 1', name: 'test Bin 1', binType: ItxBinTypeEnum.BinConical, radars: [
              { id: 1, radarId: 1, radarIdAddress: 150, distanceFromBinCenter: 1, radarLocation: ItxRadarLocationEnum.Peak },
              { id: 2, radarId: 2, radarIdAddress: 250, distanceFromBinCenter: 10, radarLocation: ItxRadarLocationEnum.Middle },
              { id: 3, radarId: 3, radarIdAddress: 350, distanceFromBinCenter: 20, radarLocation: ItxRadarLocationEnum.Eave }
            ]
          },
          {
            id: 2, material: 'test Material 2', name: 'test Bin 2', binType: ItxBinTypeEnum.BinFlat, radars: [
              { id: 4, radarId: 4, radarIdAddress: 450, distanceFromBinCenter: 1, radarLocation: ItxRadarLocationEnum.Peak },
              { id: 5, radarId: 5, radarIdAddress: 550, distanceFromBinCenter: 10, radarLocation: ItxRadarLocationEnum.Middle },
              { id: 6, radarId: 6, radarIdAddress: 650, distanceFromBinCenter: 20, radarLocation: ItxRadarLocationEnum.Eave }
            ]
          }
        ]
      }
    ] as ItxLocationReportFilter[];

    return data
  }
}
