import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CanvasFlushFloorSupportFunction } from '../aqs-canvas-flushfloor-support-functions';
import { ConvertDataFunctions } from './../../../../../common';
import { AqsFlushFloorCalculatedValues, AqsFlushFloorViewModel, AqsRoundStorageWiewModel } from './../../../../models';

@Component({
  selector: 'greensleeves-aqs-round-flush-design-double-h',
  templateUrl: './aqs-round-flush-design-double-h.component.html',
  styles: []
})
export class AqsRoundFlushDesignDoubleHComponent implements OnInit {
  @Input() storageConfiguration: AqsRoundStorageWiewModel;
  @Input() flushFloorConfiguration: AqsFlushFloorViewModel;
  @Input() calcValues: AqsFlushFloorCalculatedValues;

  @Output() currentPageErrorChange = new EventEmitter<boolean>();
  @Output() onChangeFlushFloorModel = new EventEmitter();

  @Input() flushFloorTunnelFillColor: string;
  @Input() flushFloorTunnelStrokeColor: string

  @ViewChild('flushFloorCanvas', { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;

  _a: string;
  _b: number;
  _c: number;
  _d: number;
  _e: number;
  _f: number;
  _g: number;
  _h: number;
  _i: string;

  private ii: number;

  _errorBToLarge: boolean = false;
  _errorBHUnableToDraw: boolean = false;
  _errorCToLarge: boolean = false;
  _errorCHUnableToDraw: boolean = false;
  _errorDToLarge: boolean = false;
  _errorAFUnableToDraw: boolean = false;
  _errorAFBeyondBinDia: boolean = false;
  _errorVelocities: boolean = false;
  private pageError: boolean = false;

  _supportHeightOptions = CanvasFlushFloorSupportFunction.supportHeightOptions;

  _tunnelSupportOptions1: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport1: { totalTunnelSupport: number, span: number };

  _tunnelSupportOptions2: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport2: { totalTunnelSupport: number, span: number };

  _tunnelSupportOptions3: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport3: { totalTunnelSupport: number, span: number };

  _velocityTable: { value: string, crossSectional: number, surfaceVelocity: number, error: boolean }[];

  _columnsView = CanvasFlushFloorSupportFunction.velocitiesFor2FanTableColumns;

  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;
  private scale: number;
  constructor() { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;
    if (this.flushFloorConfiguration.aa == null) {
      this.calculatedRecommendationParameters();
    }

    this.fillInputValues();

  }

  onChangeValueA() {
    if (this._a != '') {
      let splitStrings = this._a.split('-');
      let val = parseFloat(splitStrings[0]);
      if (!isNaN(val) && (val >= 2 && val < 1000)) {
        this.flushFloorConfiguration.aa = val * 12;
        if (splitStrings.length > 1) {
          let index = this._a.indexOf('-');
          val = parseFloat(this._a.slice(++index));
          if (!isNaN(val)) {
            this.flushFloorConfiguration.aa += val;
          }
        }

        this.calcI();
        this.checkError();
        this.calcTunnelSupport(1);
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._a = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.aa);
  }

  onChangeValueB() {
    if (this.checkValue(this._b)) {
      this.flushFloorConfiguration.ee = this._b * 12;
      this.calcI();
      this.calculateVelocities();
      this.checkError();
      this.calcTunnelSupport(1);
      this.onChangeFlushFloorModel.emit();
    }
    else this._b = Math.round(this.flushFloorConfiguration.ee / 12);
  }

  onChangeValueC() {
    if (this.checkValue(this._c)) {
      this.flushFloorConfiguration.ff = this._c * 12;
      this.calculateVelocities();
      this.checkError();
      this.calcTunnelSupport(2);
      this.onChangeFlushFloorModel.emit();
    }
    else this._c = Math.round(this.flushFloorConfiguration.ff / 12);
  }

  onChangeValueD() {
    if (this.checkValue(this._d)) {
      this.flushFloorConfiguration.hh = this._d * 12;
      this.calculateVelocities();
      this.checkError();
      this.calcTunnelSupport(3);
      this.onChangeFlushFloorModel.emit();
    }
    else this._d = Math.round(this.flushFloorConfiguration.hh / 12);
  }

  onChangeValueE() {
    if (this._e >= 0 && this._e <= 100 && this._e != null) {
      this.flushFloorConfiguration.cc = this._e;
      this.flushFloorConfiguration.bb = (this.calcValues.rad * this._e) / 100;
      this.reDrawSchema();
      this.onChangeFlushFloorModel.emit();
      //2960 IF VAL(FLAG$)>=0 AND VAL(FLAG$)<=100 THEN III=VAL(FLAG$):II=(RAD*III)/100
    }
    else this._e = this.flushFloorConfiguration.cc;
  }

  onChangeValueF() {
    if (this._f != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._f, this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.jj) {
        this.flushFloorConfiguration.jj = calcValue;
        this.calculateVelocities();
        this.checkError();
        this.calcTunnelSupport(1);
        this.onChangeFlushFloorModel.emit();
      }
    }
    // 3040 FOR I=1 TO 7:IF VAL(FLAG$)<=CROSSSEC(0,I) THEN 3060
    // 3050 NEXT I:I=7
    // 3060 JJ=CROSSSEC(0,I)
    this._f = this.flushFloorConfiguration.jj;

  }

  onChangeValueG() {
    if (this._g != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._g, this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.kk) {
        this.flushFloorConfiguration.kk = calcValue;
        this.calculateVelocities();
        this.reDrawSchema();
        this.calcTunnelSupport(2);
        this.onChangeFlushFloorModel.emit();
      }
    }

    this._g = this.flushFloorConfiguration.kk;
    // 3140 FOR I = 1 TO 7:IF VAL(FLAG$) <= CROSSSEC(0, I) THEN 3160
    // 3150 NEXT I: I = 7
    // 3160 KK = CROSSSEC(0, I)
  }

  onChangeValueH() {
    if (this._h != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._h, this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.ll) {
        this.flushFloorConfiguration.ll = calcValue
        this.calculateVelocities();
        this.checkError();
        this.calcTunnelSupport(3);
        this.onChangeFlushFloorModel.emit();
      }
    }

    this._h = this.flushFloorConfiguration.ll;
  }

  onChangeSupportHeight() {
    this.calculateVelocities();
    this.onChangeFlushFloorModel.emit();
  }

  onChangeTunnelSupport(tunnelNumber: number) {
    switch (tunnelNumber) {
      case 1:
        this.flushFloorConfiguration.totalTunnelSupport1 = this._tunnelSupport1.totalTunnelSupport;
        this.flushFloorConfiguration.totalTunnelSupportSpan1 = this._tunnelSupport1.span;
        break;
      case 2:
        this.flushFloorConfiguration.totalTunnelSupport2 = this._tunnelSupport2.totalTunnelSupport;
        this.flushFloorConfiguration.totalTunnelSupportSpan2 = this._tunnelSupport2.span;
        break;
      case 3:
        this.flushFloorConfiguration.totalTunnelSupport3 = this._tunnelSupport3.totalTunnelSupport;
        this.flushFloorConfiguration.totalTunnelSupportSpan3 = this._tunnelSupport3.span;
        break;
    }

    this.onChangeFlushFloorModel.emit();
  }

  numberOnly(event) {
    if (!_isNumberValue(event.key) && event.key !== '.' && event.key !== '-' && event.key !== 'Enter') {
      event.preventDefault();
    }
  }

  private checkError() {
    this._errorBToLarge = this.flushFloorConfiguration.ee > this.calcValues.rad * 2;
    //3540 IF EE>RAD*2 THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW  B TOO LARGE";:RETURN
    this._errorBHUnableToDraw = this.flushFloorConfiguration.ee <= this.flushFloorConfiguration.ll;
    //3590 IF EE<=LL THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW";:RETURN
    this._errorCToLarge = this.flushFloorConfiguration.ff > this.calcValues.rad * 2;
    //IF FF>RAD*2 THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW  C TOO LARGE";:RETURN
    this._errorCHUnableToDraw = this.flushFloorConfiguration.ff <= this.flushFloorConfiguration.ll;
    //3580 IF FF<=LL THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW";:RETURN
    this._errorDToLarge = this.flushFloorConfiguration.hh > this.calcValues.rad;
    //3560 IF HH>RAD THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW  D TOO LARGE";:RETURN
    this._errorAFBeyondBinDia = Math.pow(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.jj / 2, 2) > Math.pow(this.calcValues.rad, 2);
    //3570 IF (AA/2+JJ/2)^2>RAD^2 THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW--BEYOND BIN DIA";:RETURN
    this._errorAFUnableToDraw = this.flushFloorConfiguration.aa <= this.flushFloorConfiguration.jj;
    //3600 IF AA<=JJ THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW";:RETURN

    this.pageError = this._errorBToLarge || this._errorBHUnableToDraw || this._errorCToLarge || this._errorCHUnableToDraw ||
      this._errorDToLarge || this._errorAFBeyondBinDia || this._errorAFUnableToDraw || this._errorVelocities ;
    this.currentPageErrorChange.emit(this.pageError);

    this.reDrawSchema();
  }

  private checkValue(value: number): boolean {
    return value >= 2 && value % 2 == 0;
  }

  private calculatedRecommendationParameters() {
    let i: number;
    [this.flushFloorConfiguration.supportHeight1, this.flushFloorConfiguration.jj] = this.calcValues.calcMainTunnelWidth(this.calcValues.maxTunnelWidth);
    this.flushFloorConfiguration.kk = this.calcValues.calcSupportTunnelFan(this.flushFloorConfiguration.jj / 2);
    this.flushFloorConfiguration.ll = this.flushFloorConfiguration.kk;
    this.flushFloorConfiguration.cc = 75;//use cc insted of III
    this.flushFloorConfiguration.bb = (this.flushFloorConfiguration.cc * this.calcValues.rad) / 100;//use bb instead of II
    //1670 III=75:II=(III*RAD)/100
    this.flushFloorConfiguration.aa = Math.floor((this.calcValues.rad * 2) / 5);
    this.flushFloorConfiguration.hh = Math.floor((this.flushFloorConfiguration.aa - this.flushFloorConfiguration.kk / 2 - this.flushFloorConfiguration.jj / 2) * 24) / 24;
    // AA=INT((RAD*2)/5):HH=INT((AA-KK/2-JJ/2)*24)/24
    i = this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.jj / 2 + this.flushFloorConfiguration.hh + this.flushFloorConfiguration.kk;
    //I=AA/2+JJ/2+HH+KK
    this.flushFloorConfiguration.ff = Math.floor(Math.sqrt(Math.pow(this.flushFloorConfiguration.bb, 2) - Math.pow(i, 2)) / 12) * 24;
    if (this.flushFloorConfiguration.ff < 24) this.flushFloorConfiguration.ff = 24;
    // 1700 FF=INT((SQR(II^2-I^2))/12)*24: IF FF<24 THEN FF=24
    i = this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.jj / 2;
    // 1710 I=AA/2+JJ/2
    this.flushFloorConfiguration.ee = Math.floor(Math.sqrt(Math.pow(this.flushFloorConfiguration.bb, 2) - Math.pow(i, 2)) / 12) * 24;
    // 1720 EE=INT((SQR(II^2-I^2))/12)*24: IF EE<24 THEN EE=24
    if (this.flushFloorConfiguration.ee < 24) this.flushFloorConfiguration.ee = 24;
  }

  private fillInputValues() {
    this._a = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.aa);
    this._b = Math.round(this.flushFloorConfiguration.ee / 12);
    //CINT(EE/12)
    this._c = Math.round(this.flushFloorConfiguration.ff / 12);
    //CINT(FF/12)
    this._d = Math.round(this.flushFloorConfiguration.hh / 12);
    //CINT(HH/12)
    this._e = this.flushFloorConfiguration.cc;
    //III -use cc insted of III 
    this._f = this.flushFloorConfiguration.jj;
    //JJ
    this._g = this.flushFloorConfiguration.kk;
    //KK
    this._h = this.flushFloorConfiguration.ll;
    //LL
    this.calcI();
    this.calculateVelocities();
    this.fillTunnelSupports();
    this.reDrawSchema();
  }

  public fillTunnelSupports() {
    this.calcTunnelSupport(1, true);
    if (!this.flushFloorConfiguration.totalTunnelSupport1) {
      this.onChangeTunnelSupport(1);
    } else {
      this._tunnelSupport1.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport1;
      this._tunnelSupport1.span = this.flushFloorConfiguration.totalTunnelSupportSpan1;
    }

    this.calcTunnelSupport(2, true);
    if (!this.flushFloorConfiguration.totalTunnelSupport2) {
      this.onChangeTunnelSupport(2);
    } else {
      this._tunnelSupport2.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport2;
      this._tunnelSupport2.span = this.flushFloorConfiguration.totalTunnelSupportSpan2;
    }

    this.calcTunnelSupport(3, true);
    if (!this.flushFloorConfiguration.totalTunnelSupport3) {
      this.onChangeTunnelSupport(3);
    } else {
      this._tunnelSupport3.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport3;
      this._tunnelSupport3.span = this.flushFloorConfiguration.totalTunnelSupportSpan3;
    }
  }

  private calcTunnelSupport(tunnelNum: number, install: boolean = false) {
    let tunnelSupportOptions: { label: string, value: { totalTunnelSupport: number, span: number } }[] = [];
    let tunnelDia: number;
    let tunnelLength: number;
    switch (tunnelNum) {
      case 1:
        tunnelDia = this.flushFloorConfiguration.jj;
        tunnelLength = 2 * (this.flushFloorConfiguration.ee + this.ii);
        break;
      case 2:
        tunnelDia = this.flushFloorConfiguration.kk;
        tunnelLength = 2 * this.flushFloorConfiguration.ff;
        break;
      case 3:
        tunnelDia = this.flushFloorConfiguration.ll;
        tunnelLength = 2 * this.flushFloorConfiguration.hh;
        break;
    }

    let tunnelSupports = CanvasFlushFloorSupportFunction.aqsCalcTunnelSupport(tunnelDia, tunnelLength, this.calcValues.maxSpan, this.calcValues.minSpan);
    tunnelSupports.forEach(item => {
      tunnelSupportOptions.push({
        label: `Supports: ${item.totalTunnelSupport}, Span: ${item.span}'`,
        value: { totalTunnelSupport: item.totalTunnelSupport, span: item.span }
      });
    });

    switch (tunnelNum) {
      case 1:
        this._tunnelSupportOptions1 = tunnelSupportOptions;
        this._tunnelSupport1 = this._tunnelSupportOptions1[0].value;
        if (!install) {
          this.flushFloorConfiguration.totalTunnelSupport1 = this._tunnelSupport1.totalTunnelSupport;
          this.flushFloorConfiguration.totalTunnelSupportSpan1 = this._tunnelSupport1.span;
        }
        break;
      case 2:
        this._tunnelSupportOptions2 = tunnelSupportOptions;
        this._tunnelSupport2 = this._tunnelSupportOptions2[0].value;
        if (!install) {
          this.flushFloorConfiguration.totalTunnelSupport2 = this._tunnelSupport2.totalTunnelSupport;
          this.flushFloorConfiguration.totalTunnelSupportSpan2 = this._tunnelSupport2.span;
        }
        break;
      case 3:
        this._tunnelSupportOptions3 = tunnelSupportOptions;
        this._tunnelSupport3 = this._tunnelSupportOptions3[0].value;
        if (!install) {
          this.flushFloorConfiguration.totalTunnelSupport3 = this._tunnelSupport3.totalTunnelSupport;
          this.flushFloorConfiguration.totalTunnelSupportSpan3 = this._tunnelSupport3.span;
        }
        break;
    }
  }

  private calcI() {
    this.ii = CanvasFlushFloorSupportFunction.aqsCalcDimensionBeforeTunnel(this.calcValues.rad, this.flushFloorConfiguration.ee, this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.jj / 2);
    this._i = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.ii);
  }

  private calculateVelocities() {
    this._velocityTable = [];
    this._velocityTable.push({ value: 'Maximal', crossSectional: this.calcValues.mcsv, surfaceVelocity: this.calcValues.msv, error: false });
    let totalSurfaceArea = (this.flushFloorConfiguration.ee * 2 * this.flushFloorConfiguration.jj +
      this.flushFloorConfiguration.hh * 2 * this.flushFloorConfiguration.ll +
      this.flushFloorConfiguration.kk * 2 * this.flushFloorConfiguration.ff) / 144;
    //3420 TOTALSURFACEAREA=((EE*2*JJ)+(2*HH*LL)+(2*KK*FF))/144
    let sv = Math.round((this.calcValues.lowerLarge / totalSurfaceArea) * 10) / 10;
    //3430 SV=INT(((LOWERLARGE/TOTALSURFACEAREA)*10)+.5)/10

    let csv = this.calcValues.calcCSVelocityFans(this.flushFloorConfiguration.supportHeight1, this.flushFloorConfiguration.jj);
    this._errorVelocities = sv > this.calcValues.msv || csv > this.calcValues.mcsv;
    this._velocityTable.push({ value: 'Actual', crossSectional: csv, surfaceVelocity: sv, error: this._errorVelocities });
  }

  private reDrawSchema() {
    if (this.pageError) {
      CanvasFlushFloorSupportFunction.drawErrorBorder(this.ctx);
      return;
    }
    CanvasFlushFloorSupportFunction.clearCanvas(this.ctx);
    this.scale = CanvasFlushFloorSupportFunction.calculateScale(this.ctx, this.storageConfiguration.diameter);
    this.ctx.strokeStyle = this.flushFloorTunnelStrokeColor;
    this.drawFlushSchema();
    this.drawCirclesSchema();
    this.drawSchemaLabels();

  }

  private drawSchemaLabels() {
    this.ctx.font = `${CanvasFlushFloorSupportFunction.FontSize}px serif`;
    this.ctx.strokeStyle = this.flushFloorTunnelStrokeColor;
    this.ctx.fillStyle = this.flushFloorTunnelStrokeColor;
    this.drawLabelA();
    this.drawLabelB();
    this.drawLabelC();
    this.drawLabelD();
    this.drawLabelE();
    this.drawLabelF();
    this.drawLabelG();
    this.drawLabelH();
    this.drawLabelI();
    this.drawLabel1()
    this.drawLabel2()
    this.drawLabel3()
  }

  private drawLabelA() {
    let x = this.width / 2 - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 - this.flushFloorConfiguration.ee / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 3;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithTopLabel(this.ctx, this.scale, x, y, 'A', this.flushFloorConfiguration.aa);
  }

  private drawLabelB() {
    let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.jj / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
    let y = this.height / 2;
    CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'B', this.flushFloorConfiguration.ee);
  }

  private drawLabelC() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.jj / 2 * this.scale +
      this.flushFloorConfiguration.hh * this.scale + this.flushFloorConfiguration.kk * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
    let y = this.height / 2;
    CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'C', this.flushFloorConfiguration.ff);
  }

  private drawLabelD() {
    let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale - this.flushFloorConfiguration.jj / 2 * this.scale -
      this.flushFloorConfiguration.hh / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.flushFloorConfiguration.ll / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'D', this.flushFloorConfiguration.hh);
  }

  private drawLabelE() {
    if (this.flushFloorConfiguration.cc > 0) {
      let x = this.width / 2;
      let y = this.height / 2;
      CanvasFlushFloorSupportFunction.drawObliqueLineBottomWithLabel(this.ctx, this.scale, x, y, 'E', this.flushFloorConfiguration.bb);
    }
  }

  private drawLabelF() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.calcValues.rad * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'F', this.flushFloorConfiguration.jj);
  }

  private drawLabelG() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.jj / 2 * this.scale +
      this.flushFloorConfiguration.hh * this.scale + this.flushFloorConfiguration.kk / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 - this.flushFloorConfiguration.ff / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 3;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithTopLabel(this.ctx, this.scale, x, y, 'G', this.flushFloorConfiguration.kk);
  }

  private drawLabelH() {
    let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale - this.flushFloorConfiguration.jj / 2 * this.scale -
      this.flushFloorConfiguration.hh / 2 * this.scale;
    let y = this.height / 2 - CanvasFlushFloorSupportFunction.FontSize / 2;
    CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, 'H');
  }

  private drawLabelI() {
    if (this.ii > 0) {
      let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.jj / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
      let y = this.height / 2 + this.flushFloorConfiguration.ee / 2 * this.scale + this.ii / 2 * this.scale;
      CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'I', this.ii);
    }
  }

  private drawLabel1() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale;
    let y = this.height / 2 - this.flushFloorConfiguration.ee / 4 * this.scale;
    CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, '1');
  }

  private drawLabel2() {
    let x = this.width / 2 + (this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.jj / 2 + this.flushFloorConfiguration.hh + this.flushFloorConfiguration.kk / 2) * this.scale;
    let y = this.height / 2 - this.flushFloorConfiguration.ff / 4 * this.scale;
    CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, '2');
  }

  private drawLabel3() {
    let x = this.width / 2 + (this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.jj / 2 + this.flushFloorConfiguration.hh / 2) * this.scale;
    let y = this.height / 2 - CanvasFlushFloorSupportFunction.FontSize / 2;
    CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, '3');
  }

  private drawFlushSchema() {
    //RTUNS2.BAS  1760-2060
    let x: number[] = this.determinePointsX();
    let y: number[] = this.determinePointsY();
    CanvasFlushFloorSupportFunction.drawFlushFloorForTwoFans(this.ctx, x, y, this.flushFloorTunnelFillColor);
  }

  private determinePointsY(): number[] {
    let y: number[] = [];
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow((this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.jj / 2), 2)));
    y.push(-this.flushFloorConfiguration.ee / 2);
    y.push(-this.flushFloorConfiguration.ll / 2);
    y.push(y[2]);
    y.push(-this.flushFloorConfiguration.ff / 2);
    y.push(y[4]);
    y.push(-y[5]);
    y.push(-y[4]);
    y.push(-y[3]);
    y.push(-y[2]);
    y.push(-y[1]);
    y.push(-y[1]);
    y.push(y[1]);
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow((this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.jj / 2), 2)));
    for (let i = 0; i < y.length; i++) {
      y[i] = this.height / 2 - y[i] * this.scale;
    }
    return y;
  }

  private determinePointsX(): number[] {
    let x: number[] = [];
    x.push(-(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.jj / 2));
    x.push(x[0]);
    x.push(x[1]);
    x.push(x[2] - this.flushFloorConfiguration.hh);
    x.push(x[3]);
    x.push(x[4] - this.flushFloorConfiguration.kk);
    x.push(x[5]);
    x.push(x[4]);
    x.push(x[3]);
    x.push(x[2]);
    x.push(x[1]);
    x.push(x[10] + this.flushFloorConfiguration.jj);
    x.push(x[11]);
    x.push(x[0] + this.flushFloorConfiguration.jj);
    for (let i = 0; i < x.length; i++) {
      x[i] = this.width / 2 + x[i] * this.scale;
    }
    return x;
  }

  private drawCirclesSchema() {
    CanvasFlushFloorSupportFunction.drawCircleFromCenter(this.ctx, this.calcValues.rad, this.scale);
    CanvasFlushFloorSupportFunction.drawCircleFromCenter(this.ctx, this.flushFloorConfiguration.bb, this.scale);
  }

}
