import { Component, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { LocalStorageService, UserRoleEnum } from '../../../common';
import { LocationsDataSourceService } from '../../services';
import { CompanyUserAddPopupComponent, TutorialPopupComponent } from '../popups';
import { TutorialMenuEnum, TutorialParagraphEnum } from '../../enums';

@Component({
  selector: 'greensleeves-company-users-tab',
  templateUrl: './company-users-tab.component.html'
})
export class CompanyUsersTabComponent {
  @ViewChild(CompanyUserAddPopupComponent, { read: false, static: false })
  _addModal: CompanyUserAddPopupComponent;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  _role: UserRoleEnum;
  _btxLocations: { label: string, value: number }[];
  _ktxLocations: { label: string, value: number }[];
  _itxLocations: { label: string, value: number }[];
  private _subscriptions: Subscription[] = [];

  constructor(
    private _locationsService: LocationsDataSourceService,
    private _localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;

    let subscriptionLocationBtx = this._locationsService.btxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._btxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    let subscriptionLocationKtx = this._locationsService.ktxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._ktxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    let subscriptionLocationItx = this._locationsService.itxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._itxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    this._subscriptions.push(subscriptionLocationBtx);
    this._subscriptions.push(subscriptionLocationKtx);
    this._subscriptions.push(subscriptionLocationItx);
    this._locationsService.getKtxBtxItxLocationForCompany(info ? info.companyId : 0);
  }

  ngOnDestroy() {
    this._subscriptions && this._subscriptions.forEach(sub => sub.unsubscribe());
    this._subscriptions = [];
  }

  onClickAdd() {
    this._addModal.show();
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.UserManagement, TutorialParagraphEnum.AddCompanyUser);
  }
}
