import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LocalStorageService, UserRoleEnum } from '../../../../common';
import { UserStatusEnum } from '../../../enums';
import { SalesUserPostModel, SalesUserViewModel, ServiceUserPostModel, ServiceUserViewModel } from '../../../models';
import { ServiceUsersDataSourceService, SalesUsersDatasourceService } from '../../../services';

@Component({
  selector: 'greensleeves-service-user-edit-popup',
  templateUrl: './service-user-edit-popup.component.html',
  styles: []
})
export class ServiceUserEditPopupComponent implements OnInit, OnDestroy {
  _isHidden = true;
  _submitted = false;
  _serviceUser: ServiceUserViewModel;
  _salesUser: SalesUserViewModel;
  _serviceAndSalesUserForm: FormGroup;
  _isServiceUser: boolean;
  _role: UserRoleEnum;
  _selectedStatus;

  constructor(
    private _formBuilder: FormBuilder,
    private _serviceUsersService: ServiceUsersDataSourceService,
    private _salesUserService: SalesUsersDatasourceService,
    private _localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    const loginInfo = this._localStorageService.getLoginInfo();
    this._role = loginInfo ? loginInfo.role : null;
  }

  ngOnDestroy() {
  }

  public show(serviceUser: ServiceUserViewModel) {
    this._serviceUsersService.markServiceUserForOthersAsInUpdate(serviceUser.id);
    this._submitted = false;
    this._isServiceUser = true;
    this._serviceUser = serviceUser;
    this.initForm();
    this._isHidden = false;
  }

  public showSalesUserEdit(salesUser: SalesUserViewModel) {
    this._salesUserService.markServiceUserForOthersAsInUpdate(salesUser.id);
    this._isServiceUser = false;
    this._salesUser = salesUser;
    this._selectedStatus = UserStatusEnum[salesUser.status];
    this._submitted = false;
    this.initForm();
    this._isHidden = false;
  }

  async onSubmit() {
    this._submitted = true;
    if (this._serviceAndSalesUserForm.invalid) {
      return;
    }

    try {
      let value = {
        ...this._serviceAndSalesUserForm.value,
        status: UserStatusEnum[this._serviceAndSalesUserForm.value.status],
        id: this._isServiceUser ? this._serviceUser.id : this._salesUser.id
      };
      this._isServiceUser ? value = value as ServiceUserPostModel : value = value as SalesUserPostModel;
      const result = this._isServiceUser ? await this._serviceUsersService.edit(value) : await this._salesUserService.edit(value);
      if (result) {
        this._isHidden = true;
        this._submitted = false;
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  onCancel() {
    this._isServiceUser ? this._serviceUsersService.markServiceUserForOthersAsUpdated(this._serviceUser, false)
      : this._salesUserService.markServiceUserForOthersAsUpdated(this._salesUser, false);
    this._submitted = false;
    this._isHidden = true;
    this._serviceUser = null;
    this._salesUser = null;
    this._serviceAndSalesUserForm.reset;
    this.ngOnDestroy();
  }

  private initForm() {
    this._serviceAndSalesUserForm = this._formBuilder.group({
      firstName: [
        this._isServiceUser ? this._serviceUser.firstName : this._salesUser.firstName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      lastName: [
        this._isServiceUser ? this._serviceUser.lastName : this._salesUser.lastName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      status: [
        this._isServiceUser ? 0 : this._salesUser.status,
        Validators.compose([
          Validators.required
        ])
      ],
    });
  }
}
