import {
    EquipmentViewModel,
    SensorViewModel,
    LocationBtxEquipmentViewModel,
    LocationBtxViewModel
} from '../../models';
import { ModelsTypes, sensorPositionLabels, sensorTypeLabels } from '../../constants';
import { SensorTypeEnum } from '../../enums';

interface ModbussAddressPair {
    AddressValue: number;
    AddressName: string;
    BitValue: number;
    BitName: string;
    ExistIn?: string;
}

export interface ModbussAddress {
    Value: number;
    Name: string;
    NameOfPair?: string;
    ExistIn?: string;
}

export class ValidationFunctions {
    public static checkIsAllAddressesUnique(existentItems: (LocationBtxViewModel | EquipmentViewModel | SensorViewModel)[], newItem: (LocationBtxViewModel | EquipmentViewModel | SensorViewModel), location?: LocationBtxEquipmentViewModel): ModbussAddress[] {
        let duplicatedFields: ModbussAddress[] = [];
        const newValuesNotPairs: ModbussAddress[] = [];
        const newValuesPairs: ModbussAddressPair[] = [];

        const existentValuesNotPairs: ModbussAddress[] = [];
        const existentValuesPairs: ModbussAddressPair[] = [];
        switch (newItem.modelType) {
            case ModelsTypes.locationBtxViewModel:
                ValidationFunctions.getLocationModbussAddressesPairs(newValuesPairs, <LocationBtxViewModel>newItem);
                break;
            case ModelsTypes.locationViewModel:
                ValidationFunctions.getLocationModbussAddressesPairs(newValuesPairs, <LocationBtxViewModel>newItem);
                break;
            case ModelsTypes.equipmentViewModel:
                ValidationFunctions.getEquipmentModbussAddressesPairs(newValuesPairs, <EquipmentViewModel>newItem);
                ValidationFunctions.getEquipmentModbussAddressesNotPairs(newValuesNotPairs, <EquipmentViewModel>newItem);
                break;
            case ModelsTypes.sensorViewModel:
                ValidationFunctions.getSensorModbussAddressesPairs(newValuesPairs, <SensorViewModel>newItem, location);
                ValidationFunctions.getSensorModbussAddressesNotPairs(newValuesNotPairs, <SensorViewModel>newItem, location);
                break;
        }

        existentItems.forEach((existentItem) => {
            switch (existentItem.modelType) {
                case ModelsTypes.locationBtxViewModel:
                    ValidationFunctions.getLocationModbussAddressesPairs(existentValuesPairs, <LocationBtxViewModel>existentItem);
                    break;
                case ModelsTypes.equipmentViewModel:
                    const equipment = <EquipmentViewModel>existentItem;
                    ValidationFunctions.getEquipmentModbussAddressesPairs(existentValuesPairs, <EquipmentViewModel>existentItem);
                    ValidationFunctions.getEquipmentModbussAddressesNotPairs(existentValuesNotPairs, <EquipmentViewModel>existentItem);
                    break;
                case ModelsTypes.sensorViewModel:
                    ValidationFunctions.getSensorModbussAddressesPairs(existentValuesPairs, <SensorViewModel>existentItem, location);
                    ValidationFunctions.getSensorModbussAddressesNotPairs(existentValuesNotPairs, <SensorViewModel>existentItem, location);
                    break;
            }
        });

        newValuesPairs.forEach((newValuePair: ModbussAddressPair, i: number) => {
            existentValuesPairs.forEach((existentValuePair) => {
                if (newValuePair.AddressValue == existentValuePair.AddressValue && newValuePair.BitValue == existentValuePair.BitValue) {
                    duplicatedFields.push({ Name: newValuePair.AddressName, Value: newValuePair.AddressValue, NameOfPair: newValuePair.BitName, ExistIn: existentValuePair.ExistIn });
                    duplicatedFields.push({ Name: newValuePair.BitName, Value: newValuePair.BitValue, NameOfPair: newValuePair.AddressName, ExistIn: existentValuePair.ExistIn });
                }
            });
            existentValuesNotPairs.forEach((existentValue) => {
                if (newValuePair.AddressValue == existentValue.Value) {
                    duplicatedFields.push({ Name: newValuePair.AddressName, Value: newValuePair.AddressValue, ExistIn: existentValue.ExistIn });
                }
            });

            if (newValuesPairs.some((value, index) => index != i && value.AddressValue == newValuePair.AddressValue && value.BitValue == newValuePair.BitValue)) {
                duplicatedFields.push({ Name: newValuePair.AddressName, Value: newValuePair.AddressValue, NameOfPair: newValuePair.BitName, ExistIn: newValuePair.ExistIn });
                duplicatedFields.push({ Name: newValuePair.BitName, Value: newValuePair.BitValue, NameOfPair: newValuePair.AddressName, ExistIn: newValuePair.ExistIn });
            }
        });

        newValuesNotPairs.forEach((newValue: ModbussAddress, i: number) => {
            existentValuesPairs.forEach((existentValuePair) => {
                if (newValue.Value == existentValuePair.AddressValue) {
                    duplicatedFields.push({ Name: newValue.Name, Value: newValue.Value, ExistIn: existentValuePair.ExistIn });
                }
            });
            existentValuesNotPairs.forEach((existentValue) => {
                if (newValue.Value == existentValue.Value) {
                    duplicatedFields.push({ Name: newValue.Name, Value: newValue.Value, ExistIn: existentValue.ExistIn });
                }
            });

            if (newValuesNotPairs.some((value, index) => index != i && value.Value == newValue.Value)) {
                duplicatedFields.push({ Name: newValue.Name, Value: newValue.Value, ExistIn: newValue.ExistIn });
            }
        });

        return duplicatedFields.filter((item, i, arr) => arr.indexOf(arr.find(t => t.Name === item.Name)) === i);
    }

    private static getLocationModbussAddressesPairs(modbussAddressPairs: ModbussAddressPair[], locationBtxModel: LocationBtxViewModel) {
        //batteryAlarm
        let addressPropName = 'batteryAlarmAddress';
        let bitPropName = 'batteryAlarmAddress';
        modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: locationBtxModel.batteryAlarmAddress, BitValue: locationBtxModel.batteryAlarmAddressBit, BitName: bitPropName });
        //communicationAlarm
        addressPropName = 'communicationAlarmAddress';
        bitPropName = 'communicationAlarmAddressBit';
        modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: locationBtxModel.communicationAlarmAddress, BitValue: locationBtxModel.communicationAlarmAddressBit, BitName: bitPropName });
        //suppressorAlarm
        addressPropName = 'suppressorAlarmAddress';
        bitPropName = 'suppressorAlarmAddressBit';
        modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: locationBtxModel.suppressorAlarmAddress, BitValue: locationBtxModel.suppressorAlarmAddressBit, BitName: bitPropName });
    }

    private static getEquipmentModbussAddressesPairs(modbussAddressPairs: ModbussAddressPair[], equipmentModel: EquipmentViewModel) {
        //maintenanceRequiredWarning
        let addressPropName = 'maintenanceRequiredWarningAddress';
        let bitPropName = 'maintenanceRequiredWarningAddressBit';
        modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: equipmentModel.maintenanceRequiredWarningAddress, BitValue: equipmentModel.maintenanceRequiredWarningAddressBit, BitName: bitPropName });
        //shutDownAlarmAddress
        addressPropName = 'shutDownAlarmAddress';
        bitPropName = 'shutDownAlarmAddressBit';
        modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: equipmentModel.shutDownAlarmAddress, BitValue: equipmentModel.shutDownAlarmAddressBit, BitName: bitPropName });
    }

    private static getEquipmentModbussAddressesNotPairs(modbussAddress: ModbussAddress[], equipmentModel: EquipmentViewModel) {
        let addressPropName: string;
        //timeTillMaintenanceAddress
        if (equipmentModel.timeTillMaintenanceAddress) {//timeTillMaintenanceAddress is optional
            let addressPropName = 'timeTillMaintenanceAddress';
            modbussAddress.push({ Name: addressPropName, Value: equipmentModel.timeTillMaintenanceAddress });
        }
        //totalRunHoursFirstRegisterAddress
        addressPropName = 'totalRunHoursFirstRegisterAddress';
        modbussAddress.push({ Name: addressPropName, Value: equipmentModel.totalRunHoursFirstRegisterAddress });
        //totalRunHoursSecondRegisterAddress
        addressPropName = 'totalRunHoursSecondRegisterAddress';
        modbussAddress.push({ Name: addressPropName, Value: equipmentModel.totalRunHoursSecondRegisterAddress });
    }

    private static getSensorModbussAddressesPairs(modbussAddressPairs: ModbussAddressPair[], sensorModel: SensorViewModel, location: LocationBtxEquipmentViewModel) {
        let addressPropName: string;
        let bitPropName: string;
        let existIn: string;
        //these sensors don't have rateOf.. address
        if (sensorModel.type != SensorTypeEnum.Vibration && sensorModel.type != SensorTypeEnum.CurrentAmpDraw && sensorModel.type != SensorTypeEnum.CurrentDesignLoad) {
            //rateOfChangeAlarmAddress
            addressPropName = 'rateOfChangeAlarmAddress';
            bitPropName = 'rateOfChangeAlarmAddressBit';
            existIn = '';
            if (sensorModel.id) {
                let sensorProperty = 'Rate of change alarm address';
                existIn = this.getSensorExistInText(sensorModel, location, sensorProperty);
            }
            modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: sensorModel.rateOfChangeAlarmAddress, BitValue: sensorModel.rateOfChangeAlarmAddressBit, BitName: bitPropName, ExistIn: existIn });

            //rateOfChangeWarningAddress
            addressPropName = 'rateOfChangeWarningAddress';
            bitPropName = 'rateOfChangeWarningAddressBit';
            existIn = '';
            if (sensorModel.id) {
                let sensorProperty = 'Rate of change warning address';
                existIn = this.getSensorExistInText(sensorModel, location, sensorProperty);
            }
            modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: sensorModel.rateOfChangeWarningAddress, BitValue: sensorModel.rateOfChangeWarningAddressBit, BitName: bitPropName, ExistIn: existIn });
        }
        //warningAddress
        addressPropName = 'warningAddress';
        bitPropName = 'warningAddressBit';
        existIn = '';
        if (sensorModel.id) {
            let sensorProperty = 'Warning address';
            existIn = this.getSensorExistInText(sensorModel, location, sensorProperty);
        }
        modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: sensorModel.warningAddress, BitValue: sensorModel.warningAddressBit, BitName: bitPropName, ExistIn: existIn });
        //alarmAddress
        addressPropName = 'alarmAddress';
        bitPropName = 'alarmAddressBit';
        existIn = '';
        if (sensorModel.id) {
            let sensorProperty = 'Alarm address';
            existIn = this.getSensorExistInText(sensorModel, location, sensorProperty);
        }
        modbussAddressPairs.push({ AddressName: addressPropName, AddressValue: sensorModel.alarmAddress, BitValue: sensorModel.alarmAddressBit, BitName: bitPropName, ExistIn: existIn });
    }

    private static getSensorModbussAddressesNotPairs(modbussAddress: ModbussAddress[], sensorModel: SensorViewModel, location: LocationBtxEquipmentViewModel) {
        //modbusAddress
        let addressPropName = 'modbusAddress';
        let existIn = '';
        if (sensorModel.id) {
            let sensorProperty = 'Modbus address';
            existIn = this.getSensorExistInText(sensorModel, location, sensorProperty);
        }
        modbussAddress.push({ Name: addressPropName, Value: sensorModel.modbusAddress, ExistIn: existIn });
    }

    private static getSensorExistInText(sensorModel: SensorViewModel, location: LocationBtxEquipmentViewModel, sensorProperty: string) {
        let existIn = '';

        if (location && location.equipments) {
            let equipment = location.equipments.find(e => e.sensors.find(s => s.id === sensorModel.id));
            let equipmentName = equipment.name;

            let sensor = equipment.sensors.find(s => s.id === sensorModel.id);
            let sensorPosition = sensorPositionLabels[sensor.position];
            let sensorType = sensorTypeLabels[sensor.type];

            existIn = equipmentName + ', ' + sensorType + ', ' + sensorPosition + ' is already use this address for ' + sensorProperty;
        }

        return existIn;
    }
}
