import { Component, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import {
  SensorViewModel,
  EquipmentViewModel,
  LocationBtxViewModel,
  LocationBtxEquipmentViewModel,
  BtxLocationEquipmentConfiguration,
  BtxLocationSensorConfiguration,
  BTXLocationConfiguration
} from '../../../models';
import { UploadAddEditEquipmentPopupComponent } from './upload-add-edit-equipment-popup';
import { ModelsTypes, equipmentSensorMapping } from 'src/app/main/constants';
import { UploadEquipmentViewDataPopupComponent } from './upload-equipment-view-data';
import { ModbussAddress, ValidationFunctions } from '../../common';
import { DeleteEquipmentConfirmationPopupComponent } from './delete-equipment-confirmation-popup';
import { Subscription } from 'rxjs';
import { UploadSuccessPopupComponent } from './upload-success-popup';
import { UploadCancellationPopupComponent } from './upload-cancellation-popup';
import { LocationsBtxDataSourceService, LocationsDataSourceService } from '@services';
import { EquipmentSideEnum, SensorPositionEnum, SensorSideEnum, SensorTypeEnum } from 'src/app/main/enums';

@Component({
  selector: 'greensleeves-upload-equipment-popup',
  templateUrl: './upload-equipment-popup.component.html',
  styles: []
})

export class UploadEquipmentPopupComponent implements AfterViewInit, OnDestroy {

  @ViewChild(UploadAddEditEquipmentPopupComponent, { read: false, static: false })
  private _addEquipmentModal: UploadAddEditEquipmentPopupComponent;

  @ViewChild(UploadEquipmentViewDataPopupComponent, { read: false, static: false })
  private _equipmentViewDataModal: UploadEquipmentViewDataPopupComponent;

  @ViewChild(DeleteEquipmentConfirmationPopupComponent, { read: false, static: false })
  private _deleteEquipmentModal: DeleteEquipmentConfirmationPopupComponent;

  @ViewChild(UploadSuccessPopupComponent, { read: false, static: false })
  private _uploadSuccessModal: UploadSuccessPopupComponent;

  @ViewChild(UploadCancellationPopupComponent, { read: false, static: false })
  private _uploadCancellationModal: UploadCancellationPopupComponent;

  @Output()
  onClose = new EventEmitter<void>();

  _isHidden = true;
  _submitted = false;
  _isAnyEquipmentAlreadyExist: boolean = false;
  _isNeedToHideCloseButton = true;
  _isNeedToDisableUploadButton: boolean = true;
  _isLoading: boolean = false;

  _location: LocationBtxViewModel;
  _maxSensorIndex: number;
  _equipments: BtxLocationEquipmentConfiguration[];

  _companies: { label: string, value: number }[];

  _activeEquipment: BtxLocationEquipmentConfiguration = null;
  _activeSensor: SensorViewModel = null;

  _activeAccordionTabIndex = -1;

  private _activeSensorIndex: any;
  private subscriptions: Subscription[] = [];
  private _editingEquipmentIndex?: number = null;

  _schema: Array<{ className: string, markers: Array<{ className: string, index: number, tooltip: string }> }> = [];

  constructor(
    private _locationsBtxDataSourceService: LocationsBtxDataSourceService,
  ) {
    this.isSensorHasDublicates = this.isSensorHasDublicates.bind(this);
    this.equipmentHasUniqueName = this.equipmentHasUniqueName.bind(this);
    this.ValidateSensorAddressesUnique = this.ValidateSensorAddressesUnique.bind(this);
    this.ValidateEquipmentSensorsTypePositionAndSide = this.ValidateEquipmentSensorsTypePositionAndSide.bind(this);
    this.ValidateEquipmentAddressesUnique = this.ValidateEquipmentAddressesUnique.bind(this);
    this.ValidateEquipmentAndSensors = this.ValidateEquipmentAndSensors.bind(this);
  }

  ngAfterViewInit() {
    let subs = this._addEquipmentModal.onSave.subscribe((emitModel: [BtxLocationEquipmentConfiguration, boolean]) => {
      if (emitModel[1]) {
        this.onEquipmentEditSave(emitModel[0]);
      } else {
        this.onEquipmentAddSave(emitModel[0]);
      }
    });
    this.subscriptions.push(subs);

    subs = this._addEquipmentModal.onCancel.subscribe(() => this.onEquipmentAddCancel());
    this.subscriptions.push(subs);
  }

  ngOnDestroy() {
    this._activeEquipment = null;
  }

  public async show(location: LocationBtxViewModel, locationEquipments: BtxLocationEquipmentConfiguration[], isAnyEquipmentAlreadyExist: boolean) {
    await this._locationsBtxDataSourceService.markLocationEquipmentsForOthersAsInUpdate(location.id);
    this._location = location;
    this._isAnyEquipmentAlreadyExist = isAnyEquipmentAlreadyExist;
    this._equipments = locationEquipments;
    this.ValidateEquipmentSensorsTypePositionAndSide();
    this.ValidateEquipmentAndSensors(true);

    this._maxSensorIndex = Math.max(...[].concat(...this._equipments.map(e => {
      if (e.sensors == null) {
        return 0;
      } else {
        return e.sensors.map(s => s.index)
      }
    })));

    if (!this._equipments || !this._equipments.length) {
      this._addEquipmentModal.show("Add first equipment", "Add first equipment");
    } else {
      this._isHidden = false;
    }
  }

  ValidateEquipmentSensorsTypePositionAndSide() {
    if (this._equipments) {
      this._equipments.forEach(equipment => {
        equipment.sensors.forEach(sensor => {
          let equipmentMapping = equipmentSensorMapping[equipment.type][equipment.subType];
          if (equipmentMapping) {
            if ((typeof equipmentMapping[sensor.type] === 'undefined')) {
              sensor.type = null;
              sensor.position = null;
              sensor.side = null;
            } else if (typeof equipmentMapping[sensor.type][sensor.position] === 'undefined') {
              sensor.position = null;
              sensor.side = null;
            } else if (typeof equipmentMapping[sensor.type][sensor.position][sensor.side] === 'undefined') {
              sensor.side = null;
            }
          }

          if(sensor.type == SensorTypeEnum.Speed){
            sensor.position = SensorPositionEnum.OnePerEquipment;
            sensor.side = EquipmentSideEnum.None;
          }

          if (sensor.type == SensorTypeEnum.Vibration
            || sensor.type == SensorTypeEnum.CurrentAmpDraw
            || sensor.type == SensorTypeEnum.CurrentDesignLoad) {
            sensor.rateOfChangeAlarmAddress = null;
            sensor.rateOfChangeAlarmAddressBit = null;
            sensor.rateOfChangeWarningAddress = null;
            sensor.rateOfChangeWarningAddressBit = null;
          }
        });
      });
    }
  }

  onToggleMenu({ isOpen, index }) {
    if (isOpen) {
      this._activeAccordionTabIndex = index;
    }
  }

  onAccordionOpen(index) {
    this._activeEquipment = this._equipments[index];

    setTimeout(() => {
      let tab = document.getElementById(`${this._activeEquipment.index}`);
      if (tab) {
        let sensorWrap = document.getElementById('sensorsWrap');
        if (sensorWrap) {
          sensorWrap.style.setProperty('top', `${tab.offsetTop}px`);
        }
      }
    }, 300);
  }

  onAccordionClose() {
    this._activeEquipment = null;
  }

  async onClickSave() {
    var isAnyValidationIssue = this.ValidateEquipmentAndSensors();
    if(isAnyValidationIssue){
      return;
    }

    this.onDisableUploadButton();
    this._isLoading = true;
    let request: BTXLocationConfiguration = new BTXLocationConfiguration();
    request.locationId = this._location.id;
    request.deviceId = this._location.deviceId;
    request.equipments = this._equipments;
    const result = await this._locationsBtxDataSourceService.uploadBtxLocationEquipmentsConfigurationFile(request);
    if(result) {
      this._isLoading = false;
      return;
    }

    this.onEnableUploadButton();
    this._isLoading = false;
    this._uploadSuccessModal.show();
  }

  onSensorChanged(sensor: BtxLocationSensorConfiguration) {
    this._activeEquipment.sensors[this._activeSensorIndex] = sensor;
    this._activeSensorIndex = null;
    this._activeSensor = null;
  }

  onDeleteEquipmentConfirmation(equipmentIndex: number){
    this._equipments = this._equipments.filter(e => e.index != equipmentIndex);
    if (!this._equipments || !this._equipments.length) {
      this._addEquipmentModal.show("Add first equipment", "Add first equipment", null, true);
    } else {
      this._isHidden = false;
    }
  }

  onChangeSensors(sensors: BtxLocationSensorConfiguration[]) {
    if (!this._activeEquipment) {
      return;
    }

    this._activeEquipment.sensors = sensors
  }

  onEquipmentAddSave(equipment: BtxLocationEquipmentConfiguration) {
    let maxEquipmentIndex = 0;
    if (!this._equipments || !this._equipments.length) {
      this._equipments = [];
      maxEquipmentIndex = 1;
    } else {
      maxEquipmentIndex = Math.max(...this._equipments.map(x => x.index));
    }

    equipment.index = maxEquipmentIndex + 1;
    this._equipments.push(equipment);

    if (this._equipments.length) {
      this._isHidden = false;
    }

    this.ValidateEquipmentAndSensors();
  }

  onEquipmentEditSave(equipment: BtxLocationEquipmentConfiguration) {
    this._equipments[this._editingEquipmentIndex] = equipment as BtxLocationEquipmentConfiguration;
    this._editingEquipmentIndex = null;

    if (this._equipments.length) {
      this._isHidden = false;
    }
  }

  onEquipmentAddCancel() {
    if (!this._equipments || !this._equipments.length) {
      this._isHidden = true;
    }
  }

  onClickAddMenu() {
    this._editingEquipmentIndex = null;
    this._addEquipmentModal.show("Add new equipment", "Add");
  }

  onClickDeleteEquipment(equipmentIndex: number) {
    this._deleteEquipmentModal.show(equipmentIndex);
  }

  onClickViewData(equipment: EquipmentViewModel) {
    this._equipmentViewDataModal.show(equipment, this._location.id);
  }

  onClickUploadCancellation() {
    this._uploadCancellationModal.show();
  }

  async onClickClose() {
    await this._locationsBtxDataSourceService.markLocationEquipmentsForOthersAsUpdated(this._location.id);
    this._activeEquipment = null;
    this._isHidden = true;
    this._location = null;
    this._submitted = false;
    this.onClose.emit();
  }

  onEnableUploadButton() {
    this._isNeedToDisableUploadButton = false;
  }

  onDisableUploadButton() {
    this._isNeedToDisableUploadButton = true;
  }

  isSensorHasDublicates(sensor: SensorViewModel, equipment: EquipmentViewModel): boolean {
    var result = equipment && equipment.sensors
    .filter(s => s.id != sensor.id)
    .some(s => s.type === sensor.type && s.position === sensor.position && s.side === sensor.side);
    return result;
  }

  equipmentHasUniqueName(equipment: EquipmentViewModel) {
    var result = !this._equipments
    .some((e, i) => (this._editingEquipmentIndex === null || this._editingEquipmentIndex !== i) && e.name === equipment.name);
    return result;
  }

  ValidateEquipmentAndSensors(isFirstValidation: boolean = false) {
    var isAnyValidationIssue = false;

    this._equipments.forEach(equipment => {
      equipment.validation = this.ValidateEquipmentAddressesUnique(EquipmentViewModel.toViewModel(equipment));

      var isAnyNullValueOnEquipment = equipment.name == null
      || equipment.totalRunHoursFirstRegisterAddress == null
      || equipment.totalRunHoursSecondRegisterAddress == null
      || equipment.timeTillMaintenanceAddress == null
      || equipment.shutDownAlarmAddress == null || equipment.shutDownAlarmAddressBit == null
      || equipment.maintenanceRequiredWarningAddress == null || equipment.maintenanceRequiredWarningAddressBit == null;

      if (equipment.sensors && equipment.sensors.length > 0) {
        equipment.sensors.forEach(sensor => {
          sensor.validation = this.ValidateSensorAddressesUnique(SensorViewModel.toViewModel(sensor));
        });

        var isEquipmentSensorHasDuplicates = equipment.sensors != null && equipment.sensors.some(s => this.isSensorHasDublicates(SensorViewModel.toViewModel(s), EquipmentViewModel.toViewModel(equipment)));
        var isAnyNullValueOnSensor = equipment.sensors != null &&
        equipment.sensors.some(s => s.type == null || s.position == null || s.side == null) ||
          equipment.sensors.some(s => (s.type == SensorTypeEnum.CurrentAmpDraw || s.type == SensorTypeEnum.CurrentDesignLoad || s.type == SensorTypeEnum.Vibration)
            && (s.modbusAddress == null || s.warningAddress == null || s.warningAddressBit == null || s.alarmAddress == null || s.alarmAddressBit == null))
          || equipment.sensors.some(s => (s.type != SensorTypeEnum.CurrentAmpDraw && s.type != SensorTypeEnum.CurrentDesignLoad && s.type != SensorTypeEnum.Vibration)
            && (s.modbusAddress == null || s.warningAddress == null || s.warningAddressBit == null || s.alarmAddress == null || s.alarmAddressBit == null
              && s.rateOfChangeAlarmAddress == null || s.rateOfChangeAlarmAddressBit == null || s.rateOfChangeWarningAddress == null || s.rateOfChangeWarningAddressBit == null));
      }

      var isAnyValidationIssuePerEquipment = (isAnyNullValueOnSensor
        || isAnyNullValueOnEquipment
        || isEquipmentSensorHasDuplicates
        || (equipment.validation != null && equipment.validation.length > 0)
        || (equipment.sensors != null && equipment.sensors.some(s => s.validation != null && s.validation.length > 0)))

      if (!isAnyValidationIssue && isAnyValidationIssuePerEquipment) {
        isAnyValidationIssue = true;
      }

      if (isFirstValidation) {
        equipment.isAnyValidationIssuesActive = isAnyValidationIssuePerEquipment;
      }
    });

    if (isAnyValidationIssue) {
      this.onDisableUploadButton();
    } else {
      this.onEnableUploadButton();
    }

    return isAnyValidationIssue;
  }

  ValidateSensorAddressesUnique(sensor: SensorViewModel): ModbussAddress[] {
    const existentItems: (LocationBtxViewModel | EquipmentViewModel | SensorViewModel)[] = [this._location];
    this._equipments.forEach(eq => {
      const equipmentViewModel = EquipmentViewModel.toViewModel(eq)
      existentItems.push(equipmentViewModel);
      if (eq.sensors && eq.sensors.length > 0) {
        eq.sensors.forEach(s => (s.index !== sensor.id) && existentItems.push(SensorViewModel.toViewModel(s)));
      }
    });
    
    sensor.modelType = ModelsTypes.sensorViewModel;
    var result = ValidationFunctions.checkIsAllAddressesUnique(existentItems, sensor, LocationBtxEquipmentViewModel.toViewModel(this._location, this._equipments));
    return result;
  }

   ValidateEquipmentAddressesUnique(equipment: EquipmentViewModel): ModbussAddress[] {
    const existentItems: (LocationBtxViewModel | EquipmentViewModel | SensorViewModel)[] = [this._location];
    this._equipments.forEach(eq => {
      const equipmentViewModel = EquipmentViewModel.toViewModel(eq)
      if (equipmentViewModel.id !== equipment.id) {
        existentItems.push(equipmentViewModel);
      }

      if (eq.sensors && eq.sensors.length > 0) {
        eq.sensors.forEach(sensor => existentItems.push(SensorViewModel.toViewModel(sensor)));
      }
    });
    
    equipment.modelType = ModelsTypes.equipmentViewModel;
    var result = ValidationFunctions.checkIsAllAddressesUnique(existentItems, equipment);
    return result;
  }
}