export enum AqsAirflowLevelEnum {
    Normal = 1,
    RoofPull = 2,
    Tandem = 3,
    PushPull = 4,
}

export enum AqsAirflowFanTypeEnum {
    Axial = 1,
    Centrifugal60HZ = 2,
    Centrifugal60HZAF = 3,
    Centrifugal50HZ = 4,
}

export enum AqsFlushDuctDesignEnum {
    None = 0,
    Duct = 1,
    FlushFloor = 2,
}