import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { AqsRoundStorageDuctDesignViewModel } from '@models';
import { AqsDuctTypeEnum, AqsRoundDuctDesignType } from 'src/app/main/enums';

@Component({
  selector: 'greensleeves-aqs-round-duct-design-view-tab',
  templateUrl: './aqs-round-duct-design-view-tab.component.html',
  styles: []
})
export class AqsRoundDuctDesignViewTabComponent implements OnInit {
  private FontSize: number = 23;

  @Input() _ductDesign: AqsRoundStorageDuctDesignViewModel;
  @ViewChild('viewCanvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;

  private overheadCircleRadius: number = 120;
  private overheadCircleCenterX: number;
  private overheadCircleCenterY: number;

  private overheadScale: number;

  private textHeight: number = 16;
  private rectHeight: number = 18;

  constructor() { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ctx.font = '16pt sans-serif';
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;
    this.overheadCircleCenterX = this.width / 2;
    this.overheadCircleCenterY = this.overheadCircleRadius + 2;
    this.overheadScale = this.overheadCircleRadius * 2 / this._ductDesign.roundStorageDiameter;
    this.drawDuctDesignScheme();
  }

  drawDuctDesignScheme() {
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.drawOverheadStorage()
    this.draw3DStorage();
  }

  draw3DStorage() {
    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.SingleFan ||
      this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.MultipleFans) {
      this.draw3DNoHopperPitch();
    } else if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchConical) {
      this.draw3DHopperConical();
    } else if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchSideDraw) {
      this.draw3DHopperSideDraw();
    }
  }

  private draw3DHopperSideDraw() {
    let topAspect = 10 / 36;
    let sideAspect = 20 / 12;

    let hopperHeight = (Math.tan(this._ductDesign.hopperPitch * Math.PI / 180) *
      this._ductDesign.roundStorageDiameter) -
      (Math.tan(this._ductDesign.hopperPitch * Math.PI / 180) *
        this._ductDesign.outletDiameter);

    let totalY = hopperHeight + 4;
    let yTop = this.height - (this.overheadCircleRadius * 2 - 30);
    let yBottom = this.height - 50 - (50 - this._ductDesign.hopperPitch);
    let xLeft = 25;
    let xRight = this.width - 25;
    let yScale = (yBottom - yTop) / totalY;
    let xScale = yScale * 12 / 5;

    let xCenter = this.width / 2;

    if (xScale * this._ductDesign.roundStorageDiameter > xRight - xLeft) {
      xScale = (xRight - xLeft) / this._ductDesign.roundStorageDiameter;
      yScale = 5 * xScale / 12;
    }

    let outletY = yBottom;

    let groundY = outletY - (hopperHeight * 2 * yScale);
    let cutOffY = groundY - (8 * yScale);

    //Calculate Points
    let x: number[] = [];
    x.length = 4;
    let y: number[] = [];
    y.length = 4;

    x[0] = xCenter - (this._ductDesign.roundStorageDiameter * xScale / 2) - 15;
    y[0] = groundY - (this._ductDesign.openingDiameter / 24 * yScale);
    x[1] = xCenter - (this._ductDesign.roundStorageDiameter * xScale / 2);
    y[1] = y[0];
    x[2] = x[1];
    y[2] = groundY - (this._ductDesign.ductDiameter / 24 * yScale);
    x[3] = x[1] + (Math.cos(this._ductDesign.hopperPitch * Math.PI / 180) *
      this._ductDesign.perfDMax * xScale);
    y[3] = groundY + (Math.sin(this._ductDesign.hopperPitch * Math.PI / 180) *
      yScale * this._ductDesign.perfDMax * 2);

    let xEnd = x[2] + (this._ductDesign.roundStorageDiameter * xScale);

    let startArc = Math.PI / 2;
    let endArc = 3 * Math.PI / 2;

    if (this._ductDesign.ductType == AqsDuctTypeEnum.HalfRoundDuct) {
      y[2] = groundY;
      startArc = 0;
      endArc = Math.PI + (Math.PI / 8);
    }

    //DRAW FRONT WALL
    //Scalling step
    let step;
    if (this._ductDesign.hopperPitch <= 10) {
      step = 20;
    } else {
      let maxHoperPitch = 50;
      let pitch = this._ductDesign.hopperPitch * 100 / maxHoperPitch;
      step = 20 - (pitch * 18 / 100);
    }

    for (let i = xCenter; i < xEnd - (this._ductDesign.outletDiameter / 2 * xScale); i += step) {
      let yForDraw = groundY + (((i - xCenter) /
        (xEnd - (this._ductDesign.outletDiameter / 2 * xScale) - xCenter)) *
        (outletY - groundY));
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        yForDraw,
        xEnd - i,
        (xEnd - i) * topAspect,
        0,
        0,
        Math.PI / 2,
      );
      this.ctx.stroke();
    }

    this.ctx.beginPath();
    this.ctx.fillStyle = 'white';
    this.ctx.ellipse(
      xCenter,
      groundY,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
      0,
      2 * Math.PI,
      0,
      true
    );
    this.ctx.fill();
    this.ctx.fillStyle = 'black';

    // DRAW SOLID DUCT
    let solidAddx = this._ductDesign.ductType == AqsDuctTypeEnum.HalfRoundDuct ? 8 : 0
    let beginX = x[1] + ((this._ductDesign.perfDMax - this._ductDesign.perforatedDuctLengthFt) *
      Math.cos(this._ductDesign.hopperPitch * Math.PI / 180) * xScale);

    for (let i = x[2]; i < beginX + solidAddx; i++) {
      let yForDraw = y[2] + ((i - x[2]) / (x[3] - x[2]) * (y[3] - y[2]));
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        yForDraw,
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        startArc,
        -endArc,
        true
      );
      this.ctx.stroke();
    }

    //DRAW DUCT WORK
    //Opening
    for (let i = x[0]; i < x[1]; i++) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        y[0],
        yScale * this._ductDesign.openingDiameter / 24,
        (yScale * this._ductDesign.openingDiameter / 24) * sideAspect,
        0,
        Math.PI / 2,
        -3 * Math.PI / 2,
        true
      );
      this.ctx.stroke();
    }

    //Perforated Duct
    let addx = this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct ? 0 : 12
    for (let i = beginX + addx; i < x[3] + addx; i += 4) {
      let yForDraw = y[2] + ((i - x[2]) / (x[3] - x[2]) * (y[3] - y[2]));
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        yForDraw,
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        startArc,
        -endArc,
        true
      );
      this.ctx.stroke();
    }

    //Last Duct Ellipse
    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        x[3],
        y[3],
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        0,
        2 * Math.PI
      );
      this.ctx.stroke();
    } else {
      this.ctx.beginPath();
      this.ctx.ellipse(
        x[3],
        y[3],
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        startArc,
        -endArc, true
      );
      this.ctx.stroke();
    }

    //DRAW BIN
    //Upper Ellipse
    this.ctx.beginPath();
    this.ctx.ellipse(
      xCenter,
      cutOffY,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      (this._ductDesign.roundStorageDiameter / 2 * xScale) * topAspect,
      0,
      -3 * Math.PI / 2,
      Math.PI,
      true
    );
    this.ctx.stroke();

    //Outlet
    this.ctx.beginPath();
    this.ctx.ellipse(
      xEnd - (this._ductDesign.outletDiameter / 2 * xScale),
      outletY,
      this._ductDesign.outletDiameter / 2 * xScale,
      this._ductDesign.outletDiameter / 2 * xScale * topAspect,
      0,
      -(3 * Math.PI / 2),
      0,
      true
    );
    this.ctx.stroke();

    //Front Wall
    this.ctx.beginPath();
    this.ctx.moveTo(xEnd, cutOffY);
    this.ctx.lineTo(xEnd, outletY);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(
      xCenter,
      groundY + this._ductDesign.roundStorageDiameter * xScale * topAspect / 2);
    this.ctx.lineTo(
      xEnd - (this._ductDesign.outletDiameter * xScale / 2),
      outletY + (this._ductDesign.outletDiameter * xScale * topAspect / 2));
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(
      xCenter,
      groundY + this._ductDesign.roundStorageDiameter * xScale * topAspect / 2);
    this.ctx.lineTo(
      xCenter,
      cutOffY + (this._ductDesign.roundStorageDiameter * xScale * topAspect / 2));
    this.ctx.stroke();

    //Outlet Cross
    let AX = Math.cos(Math.PI / 4) * this._ductDesign.outletDiameter / 2;
    let AY = AX;

    let leftX = xEnd - ((this._ductDesign.outletDiameter / 2 + AX) * xScale);
    let rightX = xEnd + ((AX - (this._ductDesign.outletDiameter / 2)) * xScale);
    let bottomY = outletY + (AY * xScale * topAspect);
    let topY = outletY - (AY * xScale * topAspect);

    this.ctx.beginPath();
    this.ctx.moveTo(leftX, topY);
    this.ctx.lineTo(rightX, topY);
    this.ctx.lineTo(rightX, bottomY);
    this.ctx.lineTo(leftX, bottomY);
    this.ctx.lineTo(leftX, topY);
    this.ctx.closePath();
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(rightX, topY);
    this.ctx.lineTo(leftX, bottomY);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(rightX, bottomY);
    this.ctx.lineTo(leftX, topY);
    this.ctx.stroke();

    //Wall
    for (let i = cutOffY; i < groundY; i += 4) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        xCenter,
        i,
        this._ductDesign.roundStorageDiameter / 2 * xScale,
        this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
        0,
        -3 * Math.PI / 2,
        Math.PI,
        true
      );
      this.ctx.stroke();
    }

    //Floor
    for (let i = xCenter; i < xEnd - (this._ductDesign.outletDiameter / 2 * xScale); i += 8) {
      let yForDraw = groundY + (((i - xCenter) /
        (xEnd - (this._ductDesign.outletDiameter / 2 * xScale) - xCenter)) *
        (outletY - groundY));
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        yForDraw,
        xEnd - i,
        (xEnd - i) * topAspect,
        0,
        0,
        -Math.PI,
        true
      );
      this.ctx.stroke();
    }

    //White Ellipse Under Wall
    this.ctx.beginPath();
    this.ctx.strokeStyle = 'white';
    this.ctx.lineWidth = 2;
    this.ctx.ellipse(
      xCenter,
      groundY,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
      0,
      -(3 * Math.PI / 2),
      0,
      true
    );
    this.ctx.stroke();
    this.ctx.strokeStyle = 'black';
    this.ctx.lineWidth = 1;

    //LABELS
    //A
    let aYPosition = y[0] - (yScale * this._ductDesign.openingDiameter / 12);
    this.drawLabel('A', x[0] - 5, aYPosition - (this.rectHeight - this.textHeight));

    //B
    let bXPosition = xCenter - (this._ductDesign.roundStorageDiameter / 2 * xScale) +
      (this.ctx.measureText('B').width / 2);

    this.drawLabel(
      'B',
      bXPosition,
      y[2] - (yScale * this._ductDesign.ductDiameter / 12) -
      ((this.rectHeight - this.textHeight) / 2));

    //C
    let perfStr: string;
    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      let perfDMaxStr = Math.floor(this._ductDesign.perfDMax);

      if (perfDMaxStr / 2 !== Math.floor(this._ductDesign.perfDMax / 2)) {
        perfDMaxStr = perfDMaxStr - 1;
      }

      if (perfDMaxStr < 2) {
        perfDMaxStr = 2;
      }

      perfStr = `C (2-${perfDMaxStr})`;
    } else {
      let inchesDMax = Math.floor(this._ductDesign.perfDMax * 12);

      while (Math.floor(inchesDMax / 16) !== inchesDMax / 16) {
        inchesDMax = inchesDMax - 1;
      }

      if (inchesDMax < 16) {
        inchesDMax = 16;
      }

      perfStr = `C (1-4 to ${Math.floor(inchesDMax / 12)}-${Math.round(inchesDMax - (Math.floor(inchesDMax / 12) * 12))})`;
    }

    let perfHopperHeight = (y[2] + (((x[3] + addx) - x[2]) / (x[3] - x[2]) * (y[3] - y[2]))) -
      (y[2] + (((beginX + addx) - x[2]) / (x[3] - x[2]) * (y[3] - y[2])));

    let cYPosition = (y[2] + (((x[3] + addx) - x[2]) / (x[3] - x[2]) * (y[3] - y[2]))) - (perfHopperHeight / 2);

    this.drawLabel(
      perfStr,
      (this.width / 2) -
      (this.ctx.measureText(perfStr).width / 2),
      cYPosition);

    if (this._ductDesign.solidDuctLengthNoSingle > 0) {
      let dX = x[3];
      let dY = y[3];
      let t = this._ductDesign.perforatedDuctLengthFt + this._ductDesign.solidDuctLengthNoSingle / 12 / (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct ? 2 : 1.5);
      let h = t * Math.sin(this._ductDesign.hopperPitch * Math.PI / 180);
      dX = dX - t * Math.cos(this._ductDesign.hopperPitch * Math.PI / 180) * xScale;
      dY = dY - h * yScale * sideAspect + this.textHeight / 2;
      this.drawLabel(this._ductDesign.letterSolidDuctLengthNoSingle, dX, dY);
    }


    //Draw Typical Of Runs Quantity
    if (this._ductDesign.numberOfDucts > 1) {
      this.drawTypicalOf(y[2] - (yScale * this._ductDesign.ductDiameter / 12) -
        (yScale * this._ductDesign.ductDiameter / 24))
    }
  }

  private draw3DNoHopperPitch() {
    let topAspect = 8 / 36;
    let sideAspect = 20 / 12;

    let totalY = this._ductDesign.steamwall + 4 + (this._ductDesign.roundStorageDiameter * topAspect);
    let yTop = this.height - (this.overheadCircleRadius * 2 - 30);
    let bottomY = this.height;
    let xLeft = 25;
    let xRight = this.width - 25;
    let yScale = (bottomY - yTop) / totalY;
    let xScale = yScale * 12 / 5;

    if (xScale * this._ductDesign.roundStorageDiameter > xRight - xLeft) {
      xScale = (xRight - xLeft) / this._ductDesign.roundStorageDiameter;
      yScale = 5 * xScale / 12;
    }

    let xCenter = this.width / 2;
    let yBottom = Math.floor(bottomY - (topAspect * this._ductDesign.roundStorageDiameter / 2 * xScale));
    let steamwallY = yBottom - (this._ductDesign.steamwall * 2 * yScale);
    let cutOffY = yBottom - ((this._ductDesign.steamwall * 2 + 6) * yScale);

    let x: number[] = [];
    x.length = 4;
    let y: number[] = [];
    y.length = 4;

    //Calculate points
    x[0] = xCenter - (this._ductDesign.roundStorageDiameter * xScale / 2) - 15;
    y[0] = Math.floor(steamwallY - (this._ductDesign.openingDiameter / 24 * yScale));

    if (this._ductDesign.steamwall > 0) {
      x[1] = xCenter - (((this._ductDesign.roundStorageDiameter / 2) -
        (this._ductDesign.openingDiameter / 24)) * xScale);
    } else {
      x[1] = xCenter - (this._ductDesign.roundStorageDiameter * xScale / 2);
    }

    y[1] = y[0];
    x[2] = x[1];
    y[3] = Math.floor(yBottom - (this._ductDesign.ductDiameter / 24 * yScale));
    y[2] = y[3];

    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.SingleFan) {
      x[3] = x[1] + ((this._ductDesign.solidDuctLengthFt + this._ductDesign.perforatedDuctLengthFt) * xScale);
    } else if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.MultipleFans) {
      x[3] = xCenter;
    }

    let startArc = Math.PI / 2;
    let endArc = 3 * Math.PI / 2;

    if (this._ductDesign.ductType == AqsDuctTypeEnum.HalfRoundDuct) {
      y[3] = yBottom;
      startArc = Math.PI;
      endArc = 0;
    }

    let ductY;
    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      ductY = this._ductDesign.steamwall > 0 ? y[3] : y[3] - (this._ductDesign.ductDiameter / 24 * yScale);
    } else {
      ductY = this._ductDesign.steamwall > 0 ?
        y[3] + (this._ductDesign.openingDiameter / 32 * yScale) :
        y[0] + (this._ductDesign.ductDiameter / 36 * yScale);
    }

    //Draw Solid Duct
    for (let i = x[2]; i < x[3] - (this._ductDesign.perforatedDuctLengthFt * xScale); i++) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        ductY,
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        startArc,
        endArc);
      this.ctx.stroke();
    }

    //Draw Duct Work
    if (this._ductDesign.steamwall > 0) {
      //Opening Diameter
      for (let i = x[0]; i < x[1] + (this._ductDesign.openingDiameter / 72 * xScale); i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          i,
          y[0] + (this._ductDesign.openingDiameter / 33 * xScale),
          yScale * this._ductDesign.openingDiameter / 24,
          (yScale * this._ductDesign.openingDiameter / 18) * sideAspect,
          0,
          Math.PI / 2,
          (3 * Math.PI / 2),
          false);
        this.ctx.fill();
      }

      //Bottom Rounding
      for (let i = 1; i < this._ductDesign.openingDiameter / 24 * xScale; i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          x[2],
          y[2] + (this._ductDesign.openingDiameter / 32 * yScale),
          i,
          i * (5 / 12),
          0,
          Math.PI,
          -(3 * Math.PI / 2),
          true);
        this.ctx.fill();
      }

      //Steamwall Duct 
      for (let i = y[1] + (this._ductDesign.openingDiameter / 120 * xScale);
        i < y[2] + (this._ductDesign.openingDiameter / 24 * yScale); i += 0.5) {
        this.ctx.beginPath();
        this.ctx.moveTo(x[1] - (xScale * this._ductDesign.openingDiameter / 24), i);
        this.ctx.lineTo(x[1] + (xScale * this._ductDesign.openingDiameter / 24), i);
        this.ctx.closePath()
        this.ctx.stroke();
      }

      //Top Rounding
      for (let i = 1; i < this._ductDesign.openingDiameter / 24 * xScale; i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          x[1],
          y[1] + (this._ductDesign.openingDiameter / 120 * xScale),
          i,
          i * (5 / 12),
          0,
          3 * Math.PI / 2,
          -Math.PI / 2,
          true);
        this.ctx.fill();
      }

      this.ctx.beginPath();
      this.ctx.rect(x[2],
        y[2] + (this._ductDesign.openingDiameter / 24 * yScale),
        (xScale * this._ductDesign.openingDiameter / 24),
        (xScale * this._ductDesign.openingDiameter / 18) * topAspect)
      this.ctx.fill();
    } else {
      //Opening Diameter
      for (let i = x[0]; i < x[1]; i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          i,
          y[0] - yScale * this._ductDesign.openingDiameter / 24,
          yScale * this._ductDesign.openingDiameter / 24,
          (yScale * this._ductDesign.openingDiameter / 24) * sideAspect,
          0,
          Math.PI / 2,
          3 * Math.PI / 2);
        this.ctx.stroke();
      }
    }

    let addY = this._ductDesign.steamwall > 0 ? (this._ductDesign.ductDiameter / 32 * yScale) : 0;
    this.ctx.beginPath();
    this.ctx.moveTo(xCenter - (this._ductDesign.roundStorageDiameter / 2 * xScale), cutOffY);
    this.ctx.lineTo(xCenter - (this._ductDesign.roundStorageDiameter / 2 * xScale), yBottom + addY);
    this.ctx.closePath();
    this.ctx.stroke();

    //Perforated Duct
    let delimer = this._ductDesign.ductType == AqsDuctTypeEnum.HalfRoundDuct ? 18 : 24

    let startPerfX = x[3] - (this._ductDesign.perforatedDuctLengthFt * xScale);
    let maxPerfI = this._ductDesign.steamwall > 0 &&
      this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.SingleFan ? x[3] -
    (this._ductDesign.openingDiameter / delimer * xScale) : x[3];

    if (this._ductDesign.steamwall > 0 &&
      this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.MultipleFans) {
      startPerfX = x[1]
    }

    for (let i = startPerfX + 3; i < maxPerfI; i += 3) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        ductY,
        yScale * this._ductDesign.ductDiameter / 24,
        ((yScale * this._ductDesign.ductDiameter / 24) * sideAspect),
        0,
        startArc,
        endArc);
      this.ctx.stroke();
    }

    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct &&
      (x[3] < xCenter + (this._ductDesign.roundStorageDiameter / 2 * xScale) ||
        this._ductDesign.steamwall == 0)) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        maxPerfI,
        ductY,
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        0,
        Math.PI * 2);
      this.ctx.stroke();
    }

    //Draw Bin
    this.ctx.beginPath();
    this.ctx.ellipse(
      xCenter,
      cutOffY,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
      0,
      0,
      -Math.PI,
      true);
    this.ctx.stroke();

    let startLineY = this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct ?
      ductY + (this._ductDesign.ductDiameter / 14 * yScale) : ductY;
    this.ctx.beginPath();
    this.ctx.moveTo(this.width / 2, startLineY)
    this.ctx.ellipse(
      xCenter,
      yBottom,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
      0,
      -13 * Math.PI / 8,
      Math.PI,
      true);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(this.width / 2, startLineY);
    this.ctx.lineTo(this.width / 2 - (this._ductDesign.roundStorageDiameter / 2 * xScale), startLineY);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.ellipse(
      xCenter,
      steamwallY,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
      0,
      0,
      -Math.PI,
      true);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(xCenter + (this._ductDesign.roundStorageDiameter / 2 * xScale), cutOffY);
    this.ctx.lineTo(xCenter + (this._ductDesign.roundStorageDiameter / 2 * xScale), yBottom);
    this.ctx.closePath();
    this.ctx.stroke();

    for (let i = cutOffY; i < steamwallY; i += 4) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        xCenter,
        i,
        this._ductDesign.roundStorageDiameter / 2 * xScale,
        this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
        0,
        -(13 * Math.PI / 8),
        0,
        true);
      this.ctx.stroke();
    }

    for (let i = steamwallY; i < yBottom; i++) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        xCenter,
        i,
        this._ductDesign.roundStorageDiameter / 2 * xScale,
        this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
        0,
        -(13 * Math.PI / 8),
        0,
        true);
      this.ctx.stroke();
    }

    //LABELS
    let sizeB: TextMetrics = this.ctx.measureText('B');
    let sizeC: TextMetrics = this.ctx.measureText('C');
    let sizeD: TextMetrics = this.ctx.measureText('D');

    //A
    let aYPosition = this._ductDesign.steamwall > 0 ?
      (y[0] - (this._ductDesign.openingDiameter / 36 * xScale)) :
      y[0] - yScale * this._ductDesign.openingDiameter / 24 -
      ((yScale * this._ductDesign.openingDiameter / 11));

    this.drawLabel('A', x[0] - 5, aYPosition - (this.rectHeight - this.textHeight));

    //B
    let bXPosition = xCenter - (this._ductDesign.roundStorageDiameter / 2 * xScale) + (sizeB.width / 2);

    this.drawLabel(
      'B',
      bXPosition,
      (ductY - (this._ductDesign.ductDiameter / 36 * xScale)) -
      (yScale * this._ductDesign.ductDiameter / 36) -
      ((this.rectHeight - this.textHeight) / 2));

    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.SingleFan) {
      //C
      let cXPosition = (x[3] - (this._ductDesign.perforatedDuctLengthFt * xScale)) -
        (this._ductDesign.solidDuctLengthFt * xScale / 2) - (sizeC.width / 2);
      if (cXPosition < bXPosition + (sizeB.width * 2)) {
        cXPosition = bXPosition + (sizeB.width * 2) - (sizeC.width / 2);
      }
      this.drawLabel(
        'C',
        cXPosition,
        (ductY - (this._ductDesign.ductDiameter / 36 * xScale)) -
        (yScale * this._ductDesign.ductDiameter / 36) - ((this.rectHeight - this.textHeight) / 2));

      //D
      let dXPosition = maxPerfI <= cXPosition + sizeC.width + (sizeD.width * 4) ?
        cXPosition + (sizeC.width * 2) - (sizeD.width / 2) :
        startPerfX + (this._ductDesign.perforatedDuctLengthFt * xScale / 2) - (sizeD.width / 2);

      this.drawLabel(
        'D',
        dXPosition,
        (ductY - (this._ductDesign.ductDiameter / 36 * xScale)) -
        (yScale * this._ductDesign.ductDiameter / 36) - ((this.rectHeight - this.textHeight) / 2));
    } else {
      if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
        this.drawLabel(
          `C (2 to ${this._ductDesign.perfCMax})`,
          x[3] - (this._ductDesign.perforatedDuctLengthFt * xScale / 2),
          (ductY - (this._ductDesign.ductDiameter / 36 * xScale)) -
          (yScale * this._ductDesign.ductDiameter / 36));
      } else {
        let max = Math.floor(this._ductDesign.perfCMax / 12) + '-' +
          Math.round(this._ductDesign.perfCMax - (Math.floor(this._ductDesign.perfCMax / 12) * 12));
        let label = `C (1-4 to ${max})`;
        this.drawLabel(
          label,
          bXPosition + (this.ctx.measureText('B').width * 3),
          (ductY - (this._ductDesign.ductDiameter / 36 * xScale)) -
          (yScale * this._ductDesign.ductDiameter / 36));
      }

      if (this._ductDesign.solidDuctLengthNoSingle > 0) {
        this.drawLabel(
          this._ductDesign.letterSolidDuctLengthNoSingle,
          x[3] - (this._ductDesign.perforatedDuctLengthFt * xScale) - (this._ductDesign.solidDuctLengthNoSingle / 12 / 1.5 * xScale),
          ductY + (yScale * this._ductDesign.ductDiameter / 24 / (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct ? 1 : 4)) * sideAspect + this.textHeight + 2,
          //false
        );
      }
    }

    //Typical Of Fans Quantity
    if (this._ductDesign.numberOfDucts > 1) {
      this.drawTypicalOf((ductY - (this._ductDesign.ductDiameter / 36 * xScale)) -
        (yScale * this._ductDesign.ductDiameter / 36));
    }
  }

  private draw3DHopperConical() {
    let drawType = this._ductDesign.steamwall > 0 ? 1 : 3;

    if (this._ductDesign.ledge == 0) {
      drawType = drawType + 1;
    }

    let hopperHeight = (Math.tan(this._ductDesign.hopperPitch * Math.PI / 180) *
      (this._ductDesign.roundStorageDiameter / 2 - this._ductDesign.ledge)) -
      (Math.tan(this._ductDesign.hopperPitch * Math.PI / 180) *
        (this._ductDesign.outletDiameter / 2));

    let topAspect = 8 / 36;
    let sideAspect = 20 / 12;
    let cutOff = this._ductDesign.steamwall + 4;
    let totalY = hopperHeight + cutOff;
    let yTop = this.height - (this.overheadCircleRadius * 2 - 30);
    let yBottom = this.height - 50 - (50 - this._ductDesign.hopperPitch);
    let xRight = this.width - 25;
    let xLeft = 25;
    let yScale = (yBottom - yTop) / totalY;
    let xScale = yScale * 12 / 5;

    if (xScale * this._ductDesign.roundStorageDiameter > xRight - xLeft) {
      xScale = (xRight - xLeft) / this._ductDesign.roundStorageDiameter;
      yScale = 5 * xScale / 12;
    }

    let xCenter = this.width / 2;
    let outletY = yBottom;
    let ledgeY = outletY - (hopperHeight * yScale);
    let steamwallY = ledgeY - (this._ductDesign.steamwall * xScale);
    let cutOffY = ledgeY - (cutOff * xScale);

    //Calculate Points
    let x: number[] = [];
    x.length = 5;
    let y: number[] = [];
    y.length = 5;

    x[0] = xCenter - (this._ductDesign.roundStorageDiameter * xScale / 2) - 15;
    y[0] = Math.floor(steamwallY - (this._ductDesign.openingDiameter / 24 * yScale));

    if (drawType < 3) {
      x[1] = xCenter - (((this._ductDesign.roundStorageDiameter / 2) - (this._ductDesign.openingDiameter / 24)) * xScale);
    } else {
      x[1] = xCenter - (this._ductDesign.roundStorageDiameter * xScale / 2);
    }

    y[1] = y[0];
    x[2] = x[1];
    y[2] = Math.floor(ledgeY - (this._ductDesign.openingDiameter / 24 * yScale));

    let startArc = Math.PI / 2;
    let endArc;
    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      y[3] = Math.floor(ledgeY - (this._ductDesign.ductDiameter / 24 * yScale));
      x[3] = xCenter - ((this._ductDesign.roundStorageDiameter / 2 - this._ductDesign.ledge) * xScale);
      x[4] = xCenter - (this._ductDesign.outletDiameter / 2 * xScale);
      y[4] = Math.floor(outletY - (this._ductDesign.ductDiameter / 24 * yScale));
      endArc = 3 * Math.PI / 2;
    } else {
      y[3] = Math.floor(ledgeY + (this._ductDesign.ductDiameter / 6));
      x[3] = xCenter - ((this._ductDesign.roundStorageDiameter / 2 - this._ductDesign.ledge) * xScale);
      x[4] = xCenter - (this._ductDesign.outletDiameter / 2 * xScale);
      y[4] = Math.floor(outletY + (this._ductDesign.ductDiameter / 6));
      startArc = 0;
      endArc = Math.PI;
    }

    //Draw Solid Duct
    let addXForSteamwall = this._ductDesign.steamwall > 0 ? xScale * this._ductDesign.openingDiameter / 24 : 0;
    if (drawType == 1 || drawType == 3) {
      for (let i = x[2] + addXForSteamwall; i < x[3] - (this._ductDesign.perforatedCDuctLengthFt * xScale); i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          i,
          y[3] - (yScale * this._ductDesign.ductDiameter / 36),
          yScale * this._ductDesign.ductDiameter / 24,
          (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
          0,
          startArc,
          -endArc,
          true);
        this.ctx.stroke();
      }
    }

    let cone = Math.sqrt(Math.pow(hopperHeight, 2) +
      Math.pow((this._ductDesign.roundStorageDiameter / 2) -
        this._ductDesign.ledge -
        (this._ductDesign.outletDiameter / 2), 2));

    let beginX = xCenter - (this._ductDesign.outletDiameter / 2 * xScale) -
      ((this._ductDesign.perforatedDuctLengthFt / cone) *
        ((this._ductDesign.roundStorageDiameter / 2) -
          this._ductDesign.ledge -
          (this._ductDesign.outletDiameter / 2)) * xScale);

    let addXSolidD = this._ductDesign.ledge == 0 ? addXForSteamwall : 0;
    for (let i = x[3] + 4 + addXSolidD; i < beginX + 4; i++) {
      let yForDraw = (y[3] - (yScale * this._ductDesign.ductDiameter / 36)) + ((i - x[3]) / (x[4] - x[3]) * (y[4] - y[3]));
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        yForDraw,
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        startArc,
        -endArc,
        true);
      this.ctx.stroke();
    }

    //Draw Duct Work
    //Opening Diameter
    if (drawType < 3) {
      for (let i = x[1] - 35 - (yScale * this._ductDesign.openingDiameter / 12); i < x[1] + (this._ductDesign.openingDiameter / 72 * xScale); i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          i,
          y[0],
          yScale * this._ductDesign.openingDiameter / 24,
          (xScale * this._ductDesign.openingDiameter / 48) * sideAspect,
          0,
          Math.PI / 2,
          3 * Math.PI / 2);
        this.ctx.fill();
      }
    } else {
      for (let i = x[0]; i < x[1] + 3; i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          i,
          y[0] - (yScale * this._ductDesign.openingDiameter / 36),
          yScale * this._ductDesign.openingDiameter / 24,
          (yScale * this._ductDesign.openingDiameter / 24) * sideAspect,
          0,
          Math.PI / 2,
          3 * Math.PI / 2);
        this.ctx.stroke();
      }
    }

    if (drawType < 3) {
      //Bottom Rounding
      for (let i = 1; i < this._ductDesign.openingDiameter / 24 * xScale; i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          x[2],
          y[2],
          i,
          i * (5 / 12),
          0,
          Math.PI,
          -(3 * Math.PI / 2),
          true);
        this.ctx.fill();
      }

      //Steamwall Duct
      for (let i = y[1] - (this._ductDesign.openingDiameter / 24 * yScale); i < y[2]; i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          x[1],
          i,
          xScale * this._ductDesign.openingDiameter / 24,
          (xScale * this._ductDesign.openingDiameter / 24) * topAspect,
          0,
          -Math.PI,
          0,
          true);
        this.ctx.fill();
      }

      //Top Rounding
      for (let i = 1; i < this._ductDesign.openingDiameter / 24 * xScale; i++) {
        this.ctx.beginPath();
        this.ctx.ellipse(
          x[1],
          y[1] - (this._ductDesign.openingDiameter / 56 * xScale),
          i,
          i * (5 / 12),
          0,
          -(3 * Math.PI / 2),
          Math.PI / 2,
          true);
        this.ctx.fill();
      }

      for (let i = x[1]; i < x[1] + (this._ductDesign.openingDiameter / 24 * xScale); i++) {
        this.ctx.beginPath();
        this.ctx.moveTo(i, y[2]);
        this.ctx.lineTo(i, y[2] + (this._ductDesign.openingDiameter / 24 * yScale));
        this.ctx.closePath();
        this.ctx.stroke();
      }
    }

    this.ctx.beginPath();
    this.ctx.moveTo(xCenter - (this._ductDesign.roundStorageDiameter / 2 * xScale), cutOffY);
    this.ctx.lineTo(xCenter - (this._ductDesign.roundStorageDiameter / 2 * xScale), ledgeY);
    this.ctx.moveTo(xCenter - (this._ductDesign.roundStorageDiameter / 2 * xScale), ledgeY);
    this.ctx.lineTo(xCenter - ((this._ductDesign.roundStorageDiameter / 2 - this._ductDesign.ledge) * xScale), ledgeY);
    this.ctx.stroke();

    if (drawType < 3) {
      this.ctx.beginPath();
      this.ctx.moveTo(x[2], ledgeY);
      this.ctx.lineTo(x[2] + (this._ductDesign.openingDiameter / 24 * xScale), ledgeY);
      this.ctx.closePath();
      this.ctx.stroke();
    }

    //Perf C Duct
    let addXPerfC = x[3] - (this._ductDesign.perforatedCDuctLengthFt * xScale) < x[1] &&
      this._ductDesign.steamwall > 0 ? addXForSteamwall : 0;
    for (let i = x[3] - (this._ductDesign.perforatedCDuctLengthFt * xScale) + addXPerfC; i < x[3]; i += 4) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        y[3] - (yScale * this._ductDesign.ductDiameter / 36),
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        startArc,
        -endArc,
        true);
      this.ctx.stroke();
    }

    //Perf D Duct
    for (let i = beginX + 4; i < x[4]; i += 4) {
      let yForDraw = (y[3] - (yScale * this._ductDesign.ductDiameter / 36)) +
        ((i - x[3]) / (x[4] - x[3]) * (y[4] - y[3]));
      this.ctx.beginPath();
      this.ctx.ellipse(
        i,
        yForDraw,
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        startArc,
        -endArc,
        true);
      this.ctx.stroke();
    }

    //Last Ellipse
    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        x[4],
        y[4] - (yScale * this._ductDesign.ductDiameter / 36),
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        0,
        -(2 * Math.PI),
        true);
      this.ctx.stroke();
    } else {
      this.ctx.beginPath();
      this.ctx.ellipse(
        x[4],
        y[4] - (yScale * this._ductDesign.ductDiameter / 36),
        yScale * this._ductDesign.ductDiameter / 24,
        (yScale * this._ductDesign.ductDiameter / 24) * sideAspect,
        0,
        0,
        -Math.PI,
        true);
      this.ctx.stroke();
    }

    //Draw Bin    
    this.ctx.beginPath();
    this.ctx.ellipse(
      xCenter,
      cutOffY,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
      0,
      0,
      -Math.PI,
      true);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.ellipse(
      xCenter,
      outletY,
      this._ductDesign.outletDiameter / 2 * xScale,
      this._ductDesign.outletDiameter / 2 * xScale * topAspect,
      0,
      0,
      -(2 * Math.PI),
      true);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.ellipse(
      xCenter,
      ledgeY,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
      0,
      0,
      - Math.PI,
      true);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.ellipse(
      xCenter,
      steamwallY,
      this._ductDesign.roundStorageDiameter / 2 * xScale,
      this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
      0,
      0,
      -Math.PI,
      true);
    this.ctx.stroke();

    if (this._ductDesign.ledge > 0) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        xCenter,
        ledgeY,
        (this._ductDesign.roundStorageDiameter / 2 - this._ductDesign.ledge) * xScale,
        ((this._ductDesign.roundStorageDiameter / 2 - this._ductDesign.ledge) * xScale) * topAspect,
        0,
        0,
        -Math.PI,
        true);
      this.ctx.stroke();
    }

    this.ctx.beginPath();
    this.ctx.moveTo(xCenter - ((this._ductDesign.roundStorageDiameter / 2 - this._ductDesign.ledge) * xScale), ledgeY);
    this.ctx.lineTo(xCenter - (this._ductDesign.outletDiameter / 2 * xScale), outletY);
    this.ctx.closePath();
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(xCenter + (this._ductDesign.roundStorageDiameter / 2 * xScale), cutOffY);
    this.ctx.lineTo(xCenter + (this._ductDesign.roundStorageDiameter / 2 * xScale), ledgeY);
    this.ctx.closePath();
    this.ctx.stroke();

    for (let i = ledgeY; i < outletY; i += 6) {
      let x = ((1 - ((i - ledgeY) / (outletY - ledgeY))) *
        (this._ductDesign.roundStorageDiameter - this._ductDesign.outletDiameter -
          (2 * this._ductDesign.ledge)) + this._ductDesign.outletDiameter)
        * xScale / 2;
      this.ctx.beginPath();
      this.ctx.ellipse(
        xCenter,
        i,
        x,
        x * topAspect,
        0,
        -(13 * Math.PI / 8),
        0,
        true);
      this.ctx.stroke();
    }

    for (let i = steamwallY; i < ledgeY; i++) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        xCenter,
        i,
        this._ductDesign.roundStorageDiameter / 2 * xScale,
        this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
        0,
        -(13 * Math.PI / 8),
        0, true);
      this.ctx.stroke();
    }

    for (let i = cutOffY; i < steamwallY; i += 4) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        xCenter,
        i,
        this._ductDesign.roundStorageDiameter / 2 * xScale,
        this._ductDesign.roundStorageDiameter / 2 * xScale * topAspect,
        0,
        -(13 * Math.PI / 8),
        0,
        true);
      this.ctx.stroke();
    }

    //LABELS
    //A
    let aYPosition = y[0] - (yScale * this._ductDesign.openingDiameter / 36) -
      ((yScale * this._ductDesign.openingDiameter / 12))
    this.drawLabel('A', x[0] - 5, aYPosition - (this.rectHeight - this.textHeight));

    //B
    let bXPosition = xCenter - (this._ductDesign.roundStorageDiameter / 2 * xScale) +
      (this.ctx.measureText('B').width / 2);

    this.drawLabel(
      'B',
      bXPosition,
      y[3] - (yScale * this._ductDesign.ductDiameter / 36) -
      (yScale * this._ductDesign.ductDiameter / 12)
      - ((this.rectHeight - this.textHeight) / 2));

    //C
    let cStr: string;
    let cXPosition: number;
    if (this._ductDesign.ledge > 0) {

      if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
        cStr = `C (0-${this._ductDesign.perfCMax})`;
      } else {
        let perfCMaxStr = Math.floor(this._ductDesign.perfCMax / 12) + "-" +
          Math.round(this._ductDesign.perfCMax - (Math.floor(this._ductDesign.perfCMax / 12) * 12));
        cStr = `C (0 to ${perfCMaxStr})`;
      }

      cXPosition = x[3] - (this._ductDesign.perforatedCDuctLengthFt * xScale / 2) -
        (this.ctx.measureText(cStr).width / 2);

      if (cXPosition < bXPosition + (this.ctx.measureText('B').width * 2)) {
        cXPosition = bXPosition + (this.ctx.measureText('B').width * 2);
      }

      this.drawLabel(
        cStr,
        cXPosition,
        y[3] - (yScale * this._ductDesign.ductDiameter / 36) -
        (yScale * this._ductDesign.ductDiameter / 12));

      if (this._ductDesign.solidDuctCLengthNoSingle > 0) {
        let fXposition = x[3] - this._ductDesign.perforatedCDuctLengthFt * xScale - this._ductDesign.solidDuctCLengthNoSingle / 12 / 1.5 * xScale;
        let fYPostition = y[3] + (yScale * this._ductDesign.ductDiameter / 24 / (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct ? 2 : 10)) * sideAspect + this.textHeight + 2;
        this.drawLabel(this._ductDesign.letterSolidDuctCLengthNoSingle, fXposition, fYPostition);
      }
    }

    //D
    let dStr: string;
    if (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct) {
      dStr = `D (2-${this._ductDesign.perfDMax})`;
    } else {
      let perfDMaxStr = Math.floor(this._ductDesign.perfDMax / 12) + "-" +
        Math.round(this._ductDesign.perfDMax - (Math.floor(this._ductDesign.perfDMax / 12) * 12));
      dStr = `D (1-4 to ${perfDMaxStr})`;
    }
    let perfDHopperHeight = (y[3] - (yScale * this._ductDesign.ductDiameter / 36)) +
      ((beginX + 4 - x[3]) / (x[4] - x[3]) * (y[4] - y[3])) - (y[3] - (yScale * this._ductDesign.ductDiameter / 36)) +
      ((x[4] - x[3]) / (x[4] - x[3]) * (y[4] - y[3]));

    let addDistance = this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct ?
      (yScale * this._ductDesign.ductDiameter / 24) :
      (yScale * this._ductDesign.ductDiameter / 12);

    let dYPosition = (y[3] - addDistance) +
      ((x[4] - x[3]) / (x[4] - x[3]) * (y[4] - y[3]));

    let dXPosition = x[4] - (this._ductDesign.perforatedDuctLengthFt * xScale / 2);
    if (dYPosition - this.textHeight < y[3] - (yScale * this._ductDesign.ductDiameter / 36) -
      (yScale * this._ductDesign.ductDiameter / 12) &&
      dXPosition < cXPosition + this.ctx.measureText(cStr).width) {
      dYPosition = dYPosition + (perfDHopperHeight) + this.textHeight;
    } else {
      dYPosition = dYPosition - (perfDHopperHeight / 2)
    }

    this.drawLabel(
      dStr,
      dXPosition,
      dYPosition
    );

    if (this._ductDesign.solidDuctLengthNoSingle > 0) {
      let eX = x[4];
      let eY = y[3] + ((x[4] - x[3]) / (x[4] - x[3]) * (y[4] - y[3]));
      let t = this._ductDesign.perforatedDuctLengthFt + this._ductDesign.solidDuctLengthNoSingle / 12 / (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct ? 2 : 1.5);
      let r = t * Math.sin(this._ductDesign.hopperPitch * Math.PI / 180);
      eX = eX - t * Math.cos(this._ductDesign.hopperPitch * Math.PI / 180) * xScale;
      eY = eY - r * yScale + this.textHeight * (this._ductDesign.ductType == AqsDuctTypeEnum.FullRoundDuct ? 1.5 : 1);
      this.drawLabel(
        this._ductDesign.letterSolidDuctLengthNoSingle,
        eX,
        eY
      );
    }
    //Draw Typical Of Runs Quantity
    if (this._ductDesign.numberOfDucts > 1) {
      this.drawTypicalOf(y[3] - (yScale * this._ductDesign.ductDiameter / 36) -
        (yScale * this._ductDesign.ductDiameter / 24))
    }
  }

  private drawTypicalOf(yPosition: number) {
    let textTypicalOf = `Typical of ${this._ductDesign.numberOfDucts} Runs`;

    let typicalSize: TextMetrics = this.ctx.measureText(textTypicalOf);
    let borderSize = 5;

    this.ctx.fillStyle = 'white';
    this.ctx.fillRect(
      (this.width / 2) - (typicalSize.width / 2) - borderSize,
      yPosition - (this.textHeight * 3) - borderSize,
      typicalSize.width + (borderSize * 2),
      this.rectHeight + (borderSize * 2));

    this.ctx.fillStyle = 'black';
    this.ctx.fillText(
      textTypicalOf,
      (this.width / 2) - (typicalSize.width / 2),
      yPosition - (this.textHeight * 2));
  }

  private drawLabel(text: string, x: number, y: number) {
    this.ctx.font = '16pt sans-serif';
    let textSize: TextMetrics = this.ctx.measureText(text);

    this.ctx.fillStyle = 'white';
    this.ctx.fillRect(
      x,
      y - this.textHeight,
      textSize.width,
      this.rectHeight);

    this.ctx.fillStyle = 'black';
    this.ctx.fillText(text, x, y);
  }

  private drawOverheadStorage() {
    this.ctx.lineWidth = 1;
    this.ctx.beginPath();
    this.ctx.arc(this.overheadCircleCenterX, this.overheadCircleCenterY, this.overheadCircleRadius, 0, Math.PI * 2, true);
    this.ctx.closePath()
    this.ctx.stroke();

    if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.SingleFan) {
      this.drawSingleFanTypeOverhead();
    } else if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.MultipleFans ||
      this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchConical) {
      this.drawMultipleFansTypeOverhead();

      if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchConical &&
        this._ductDesign.outletDiameter > 0) {
        let outletDiameterWithScale = this._ductDesign.outletDiameter * this.overheadScale;
        this.ctx.beginPath();
        this.ctx.arc(this.overheadCircleCenterX,
          this.overheadCircleCenterY,
          outletDiameterWithScale / 2,
          0,
          Math.PI * 2);
        this.ctx.fillStyle = 'white';
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.fillStyle = 'black';
      }

      if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchConical &&
        this._ductDesign.ledge > 0) {
        let diameterWithLedge = (this._ductDesign.roundStorageDiameter - (this._ductDesign.ledge * 2)) * this.overheadScale
        this.ctx.beginPath();
        this.ctx.arc(this.overheadCircleCenterX,
          this.overheadCircleCenterY,
          diameterWithLedge / 2,
          0,
          Math.PI * 2);
        this.ctx.stroke();
      }
    } else if (this._ductDesign.roundDuctDesignType == AqsRoundDuctDesignType.HopperPitchSideDraw) {
      this.drawHopPitchSideDrawTypeOverhead();
    }
  }

  private drawHopPitchSideDrawTypeOverhead() {
    if (this._ductDesign.outletDiameter > 0) {
      let AX = Math.cos(Math.PI / 4) * this._ductDesign.outletDiameter / 2;
      let AY = AX;

      let leftX = this.overheadCircleCenterX +
        ((this._ductDesign.roundStorageDiameter / 2) -
          (this._ductDesign.outletDiameter / 2) - AX) * this.overheadScale;
      let rightX = this.overheadCircleCenterX +
        ((this._ductDesign.roundStorageDiameter / 2) -
          (this._ductDesign.outletDiameter / 2) + AX) * this.overheadScale;
      let upperY = this.overheadCircleCenterY - (AY * this.overheadScale);
      let lowerY = this.overheadCircleCenterY + (AY * this.overheadScale);

      this.ctx.moveTo(leftX, upperY);
      this.ctx.lineTo(leftX, lowerY);
      this.ctx.lineTo(rightX, lowerY);
      this.ctx.lineTo(rightX, upperY);
      this.ctx.lineTo(leftX, upperY);
      this.ctx.lineTo(rightX, lowerY);
      this.ctx.moveTo(rightX, upperY);
      this.ctx.lineTo(leftX, lowerY);
      this.ctx.closePath();
      this.ctx.stroke();
    }

    for (let i = 0; i < this._ductDesign.numberOfFans; i++) {
      for (let j = 0; j < this._ductDesign.ductsPerFan; j++) {
        let inDegree = this._ductDesign.innerDegree +
          (this._ductDesign.innerFanDegree * i) +
          (this._ductDesign.innerDuctDegree * j);

        let outDegree = 180 - this._ductDesign.outerDegree +
          (this._ductDesign.degreesBetweenFans * i) +
          (this._ductDesign.degreesBetweenDucts * j);
        let y = Math.sin(inDegree * Math.PI / 180) * (this._ductDesign.roundStorageDiameter / 2);
        let x = Math.cos(inDegree * Math.PI / 180) * (this._ductDesign.roundStorageDiameter / 2);
        x = x + this._ductDesign.roundStorageDiameter / 2;

        let ductPitch = Math.atan(this._ductDesign.coneHeight /
          Math.sqrt(Math.pow(this._ductDesign.roundStorageDiameter - x, 2) + Math.pow(y, 2)));
        let rr = Math.sqrt(Math.pow(this._ductDesign.roundStorageDiameter - x, 2) + Math.pow(y, 2)) -
          (Math.cos(ductPitch) * this._ductDesign.perfDMax);

        let yy = Math.sin((outDegree) * Math.PI / 180) * rr;
        let xx = Math.cos((outDegree) * Math.PI / 180) * rr;
        xx = this._ductDesign.roundStorageDiameter + xx;

        let addX = Math.cos((outDegree - 90) * Math.PI / 180) * (this._ductDesign.ductDiameter / 24);
        let addY = Math.sqrt(Math.pow(this._ductDesign.ductDiameter / 24, 2) - Math.pow(addX, 2));

        let pt1x = this.overheadCircleCenterX + ((x + addX - (this._ductDesign.roundStorageDiameter / 2)) * this.overheadScale);
        let pt2x = this.overheadCircleCenterX + ((addX + xx - (this._ductDesign.roundStorageDiameter / 2) - addX) * this.overheadScale);
        let pt3x = this.overheadCircleCenterX + ((xx - (this._ductDesign.roundStorageDiameter / 2) - addX) * this.overheadScale);
        let pt4x = this.overheadCircleCenterX + ((x - (this._ductDesign.roundStorageDiameter / 2) - addX) * this.overheadScale);
        let pt1y = this.overheadCircleCenterY - ((y + addY) * this.overheadScale);
        let pt2y = this.overheadCircleCenterY - ((yy + addY) * this.overheadScale);
        let pt3y = this.overheadCircleCenterY - ((yy - addY) * this.overheadScale);
        let pt4y = this.overheadCircleCenterY - ((y - addY) * this.overheadScale);

        this.ctx.beginPath();
        this.ctx.moveTo(pt1x, pt1y);
        this.ctx.lineTo(pt2x, pt2y);
        this.ctx.lineTo(pt3x, pt3y);
        this.ctx.lineTo(pt4x, pt4y);
        this.ctx.lineTo(pt1x, pt1y);
        this.ctx.fill();
      }
    }
  }

  private drawMultipleFansTypeOverhead() {
    let intervalDucts = this._ductDesign.degreesBetweenDucts;

    let arcSize = this.degreesToRadians(intervalDucts);

    let startAgle = 180;

    for (let i = 0; i < this._ductDesign.numberOfFans; i++) {
      let startDactAngle = startAgle;
      for (let j = 0; j < this._ductDesign.ductsPerFan; j++) {
        this.drawOverheadWithLineDegrees(arcSize, startDactAngle)
        if (j != this._ductDesign.ductsPerFan - 1) {
          startDactAngle = startDactAngle + intervalDucts;
        }
      }
      startAgle += this._ductDesign.degreesBetweenFans;
    }
  }

  private drawSingleFanTypeOverhead() {
    let ductDiameterWithScale = this._ductDesign.ductDiameter / 12 * this.overheadScale;

    let ductLengthIn = this._ductDesign.perforatedDuctLengthFt * 12;

    if (this._ductDesign.perforatedDuctLengthIn) {
      ductLengthIn = ductLengthIn + this._ductDesign.perforatedDuctLengthIn;
    }

    let solidLengthIn;
    if (this._ductDesign.solidDuctLengthFt) {
      ductLengthIn = ductLengthIn + this._ductDesign.solidDuctLengthFt * 12;
      solidLengthIn = this._ductDesign.solidDuctLengthFt * 12;
    }

    if (this._ductDesign.solidDuctLengthIn) {
      ductLengthIn = ductLengthIn + this._ductDesign.solidDuctLengthIn;
      solidLengthIn = solidLengthIn + this._ductDesign.solidDuctLengthIn;
    }

    let solidLengthWithScale;
    if (solidLengthIn) {
      let solidLengthPercentage = (solidLengthIn * 100) / (this._ductDesign.roundStorageDiameter * 12);
      solidLengthWithScale = (solidLengthPercentage * (this.overheadCircleRadius * 2)) / 100;
    }

    let ductLengthWithScale = ductLengthIn > this._ductDesign.roundStorageDiameter * 12 ?
      this._ductDesign.roundStorageDiameter * 12 * this.overheadScale :
      ductLengthIn / 12 * this.overheadScale;

    let ductLengthPercentage = (ductLengthIn * 100) / (this._ductDesign.roundStorageDiameter * 12);

    if (ductLengthPercentage > 100) {
      ductLengthPercentage = 100;
    }

    if (this._ductDesign.numberOfDucts % 2 !== 0) {
      let upperY = this.overheadCircleCenterY - (ductDiameterWithScale / 2);
      let lowerY = this.overheadCircleCenterY + (ductDiameterWithScale / 2);

      let coordinateY = lowerY - this.overheadCircleCenterY;
      let coordinateX = this.overheadCircleCenterX - Math.sqrt(Math.pow(this.overheadCircleRadius, 2) - Math.pow(coordinateY, 2));

      let finishX;
      if (ductLengthPercentage == 100) {
        finishX = this.width - coordinateX;
      } else {
        finishX = coordinateX + ductLengthWithScale;
      }

      this.drawOverheadPerfDuct(finishX, coordinateX, lowerY, coordinateX, upperY);

      if (solidLengthIn) {
        let finishXSolid = coordinateX + solidLengthWithScale;
        this.drawOverheadSolidDuct(finishXSolid, coordinateX, lowerY, coordinateX, upperY);
      }
    }

    if (this._ductDesign.numberOfDucts > 1) {
      let distanseCtrToCtrPercentage = ((this._ductDesign.distanceBetweenDuctsFt * 12 +
        this._ductDesign.distanceBetweenDuctsIn) * 100) / (this._ductDesign.roundStorageDiameter * 12);

      let distanceForDraw = (distanseCtrToCtrPercentage * (this.overheadCircleRadius * 2)) / 100;

      let distance = distanceForDraw;

      for (let i = 1; i <= Math.floor(this._ductDesign.numberOfDucts / 2); i++) {

        let firstDistance = this._ductDesign.numberOfDucts % 2 !== 0 ? distance : distance / 2

        let lowerY = this.overheadCircleCenterY - firstDistance + (ductDiameterWithScale / 2);
        let upperY = lowerY - ductDiameterWithScale;

        let lowerX = this.overheadCircleCenterX - (Math.sqrt(Math.pow(this.overheadCircleRadius, 2) - Math.pow(lowerY - this.overheadCircleCenterY, 2)));
        let upperX = this.overheadCircleCenterX - (Math.sqrt(Math.pow(this.overheadCircleRadius, 2) - Math.pow(upperY - this.overheadCircleCenterY, 2)));

        let finishX = lowerX + ductLengthWithScale;

        //Upper Duct
        this.drawOverheadPerfDuct(finishX, lowerX, lowerY, upperX, upperY);
        //Bottom Duct
        let bottomDuctUpperX = lowerX;
        let bottomDuctLowerX = upperX;
        let bottomDuctUpperY = this.overheadCircleCenterY + firstDistance - (ductDiameterWithScale / 2);
        let bottomDuctLowerY = bottomDuctUpperY + ductDiameterWithScale;
        this.drawOverheadPerfDuct(finishX, bottomDuctLowerX, bottomDuctLowerY, bottomDuctUpperX, bottomDuctUpperY);


        if (solidLengthWithScale) {
          let finishXSolid = lowerX + solidLengthWithScale;
          this.drawOverheadSolidDuct(finishXSolid, lowerX, lowerY, upperX, upperY);
          this.drawOverheadSolidDuct(finishXSolid, bottomDuctLowerX, bottomDuctLowerY, bottomDuctUpperX, bottomDuctUpperY);
        }

        distance = this._ductDesign.numberOfDucts % 2 !== 0 ? distance + distanceForDraw : distance + (distanceForDraw * 2)
      }
    }
  }

  private drawOverheadWithLineDegrees(arcSize, startingAngle) {
    this.ctx.beginPath();
    this.ctx.moveTo(this.overheadCircleCenterX, this.overheadCircleCenterY);
    this.ctx.arc(this.overheadCircleCenterX,
      this.overheadCircleCenterY,
      this.overheadCircleRadius,
      -this.degreesToRadians(startingAngle),
      -(startingAngle + arcSize), true);
    this.ctx.closePath();
    this.ctx.stroke();
  }

  private degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  private drawOverheadPerfDuct(finishX, lowerX, lowerY, upperX, upperY) {
    this.ctx.beginPath();
    this.ctx.moveTo(finishX, upperY);
    this.ctx.lineTo(finishX, lowerY);
    this.ctx.closePath();
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(lowerX, lowerY);
    this.ctx.lineTo(finishX, lowerY);
    this.ctx.moveTo(upperX, upperY);
    this.ctx.lineTo(finishX, upperY);
    this.ctx.closePath()
    this.ctx.stroke();
  }

  private drawOverheadSolidDuct(finishX, lowerX, lowerY, upperX, upperY) {
    this.ctx.beginPath();
    this.ctx.moveTo(upperX, upperY);
    this.ctx.lineTo(finishX, upperY);
    this.ctx.lineTo(finishX, lowerY);
    this.ctx.lineTo(lowerX, lowerY);
    this.ctx.lineTo(upperX, upperY);
    this.ctx.fill();
  }
}