import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService, ErrorModel } from '../../../common';
import { ApiRoutes } from '../../constants';
import { EquipmentViewModel, SensorViewModel, LocationBtxEquipmentViewModel, LocationBtxEquipmentPostModel, LocationBtxEquipmentGetModel, SensorInfo, BtxEquipmentWithTelemetryGetModel, BtxEquipmentWithTelemetryViewModel } from '../../models';
import { SensorPositionEnum, SensorTypeEnum, EquipmentSideEnum, EquipmentTypeEnum, EquipmentSubTypeEnum, SensorValuesStatusEnum, BtxSensorEventTypeEnum } from '../../enums';
import { LastTelemetryGetModel } from '../../models/telemetry.model';
import { LastTelemetrySensorResponse } from '../../models/lastTelemetrySensorResponse.model';
import { HistoricalDataFilter, HistoricalDataTelemetryResponse } from '../../models/historicalDataTelemetryResponse.model';

@Injectable()
export class EquipmentApiService {
  constructor(
    private _httpService: HttpService,
    private _router: Router,
  ) { }

  // TODO: add fake data for BTX/KTX equipments
  public async get(deviceId: string): Promise<LocationBtxEquipmentViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData(deviceId);
      }

      const result = await this._httpService.get(ApiRoutes.Equipment.get, { deviceId });

      return LocationBtxEquipmentGetModel.toViewModel(result);
    } catch (error) {
      return this.processError(error);
    }
  }

  public async addOrUpdate(model: LocationBtxEquipmentPostModel): Promise<boolean> {
    try {
      const result = await this._httpService.post(ApiRoutes.Equipment.edit, model);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getLastData(locationId: number, equipmentId: number, sensorsIds: number[]): Promise<LastTelemetrySensorResponse[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        let result = await this.getFakeTelemetry(sensorsIds);
        return result as LastTelemetrySensorResponse[];
      }

      const result = await this._httpService.post(ApiRoutes.Telemetry.getLastData, { locationId: locationId, equipmentId: equipmentId, sensorsIds: sensorsIds }) as LastTelemetryGetModel;
      return result.sensors;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getEquipmentWithTelemetry(equipmentId: number): Promise<BtxEquipmentWithTelemetryViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        const result = await this.getFakeEquipmentWithTelemetry(equipmentId);
        return BtxEquipmentWithTelemetryGetModel.toViewModel(result);
      }

      const result = await this._httpService.get(ApiRoutes.Equipment.getEquipmentWithTelemetry, { equipmentId: equipmentId }) as BtxEquipmentWithTelemetryGetModel;
      return BtxEquipmentWithTelemetryGetModel.toViewModel(result);
    } catch (error) {
      return this.processError(error);
    }
  }

  private getFakeEquipmentWithTelemetry(equipmentId: number): BtxEquipmentWithTelemetryGetModel {
    const equipmentViewModel: EquipmentViewModel = this.getFakeData("id").equipments.find(eq => eq.id === equipmentId);

    if (!equipmentViewModel) {
      return null;
    }

    const result: BtxEquipmentWithTelemetryGetModel = {
      id: equipmentViewModel.id,
      name: equipmentViewModel.name,
      type: equipmentViewModel.type,
      subType: equipmentViewModel.subType,
      locationName: 'Fake location',
      locationId: 0,
      maintenanceCountDownValue: equipmentViewModel.maintenanceCountDownValue,
      totalRunHoursValue: equipmentViewModel.totalRunHoursValue,
      timestamp: 1695224644,
      acquisitionRate: 30,
      isMaintenanceRequired: true,
      shutdownAlarm: true,
      sensors: equipmentViewModel.sensors.map((s, idx) => {
        const status = this.getRandomSensorStatusEnum(SensorValuesStatusEnum);
        return {
          id: s.id,
          type: s.type,
          position: s.position,
          side: s.side,
          value: 1 + idx,
          status: status,//this.getRandomSensorStatusEnum(SensorValuesStatusEnum),
          eventType: this.getSensorEventType(status),
        }
      })
    }

    return result;
  }

  public async getSensors(equipmentId: number, locationId: number): Promise<SensorInfo[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        //return this.getFakeData(locationId);
      }

      const result = await this._httpService.get(ApiRoutes.Equipment.getEquipmentSensorsInfo, { equipmentId, locationId });

      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async getHistoricalDataForThePeriod(request: HistoricalDataFilter): Promise<HistoricalDataTelemetryResponse[] | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.Telemetry.getHistoricalDataForThePeriod, request) as HistoricalDataTelemetryResponse[];
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  private getFakeData(deviceId: string): LocationBtxEquipmentViewModel {
    const equipments = [
      {
        id: 1,
        type: EquipmentTypeEnum.Silo,
        subType: EquipmentSubTypeEnum.Small,
        name: 'Silo small (None)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        maintenaceCountDown: 11,
        totalRunHours: 1111,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.GrainDepth,
          position: SensorPositionEnum.CenterOfTheEquipmentScheme,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }] as SensorViewModel[]
      },
      {
        id: 2,
        type: EquipmentTypeEnum.Silo,
        subType: EquipmentSubTypeEnum.Medium,
        name: 'Silo medium (None)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        maintenaceCountDown: 11,
        totalRunHours: 1111,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.GrainDepth,
          position: SensorPositionEnum.CenterOfTheEquipmentScheme,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }] as SensorViewModel[]
      },
      {
        id: 3,
        type: EquipmentTypeEnum.Silo,
        subType: EquipmentSubTypeEnum.Large,
        name: 'Silo large (None)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        maintenaceCountDown: 11,
        totalRunHours: 1111,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.GrainDepth,
          position: SensorPositionEnum.CenterOfTheEquipmentScheme,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }] as SensorViewModel[]
      },
      /* LEG */
      {
        id: 4,
        type: EquipmentTypeEnum.Leg,
        subType: EquipmentSubTypeEnum.WithKneeBearing,
        name: 'Leg with knee bearing (North-South)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        maintenaceCountDown: 11,
        totalRunHours: 1111,
        sensors: [
          {
            id: 1,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.Head,
            side: EquipmentSideEnum.North,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 2,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.Head,
            side: EquipmentSideEnum.South,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16,
            maintenaceCountDown: 11,
            totalRunHours: 1111,
          }, {
            id: 3,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.Knee,
            side: EquipmentSideEnum.North,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16,
            maintenaceCountDown: 11,
            totalRunHours: 1111,
          }, {
            id: 4,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.Knee,
            side: EquipmentSideEnum.South,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 5,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.Boot,
            side: EquipmentSideEnum.North,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 6,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.Boot,
            side: EquipmentSideEnum.South,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 7,
            type: SensorTypeEnum.RubBlockTemperature,
            position: SensorPositionEnum.Head,
            side: EquipmentSideEnum.North,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 8,
            type: SensorTypeEnum.RubBlockTemperature,
            position: SensorPositionEnum.Head,
            side: EquipmentSideEnum.South,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 9,
            type: SensorTypeEnum.RubBlockTemperature,
            position: SensorPositionEnum.Boot,
            side: EquipmentSideEnum.South,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 10,
            type: SensorTypeEnum.RubBlockTemperature,
            position: SensorPositionEnum.Boot,
            side: EquipmentSideEnum.North,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 11,
            type: SensorTypeEnum.Speed,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 12,
            type: SensorTypeEnum.GearBoxTemperature,
            position: SensorPositionEnum.Head,
            side: EquipmentSideEnum.South,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 13,
            type: SensorTypeEnum.GearBoxTemperature,
            position: SensorPositionEnum.Head,
            side: EquipmentSideEnum.North,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 14,
            type: SensorTypeEnum.ShaftBearingTemperature,
            position: SensorPositionEnum.Boot,
            side: EquipmentSideEnum.South,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          }, {
            id: 15,
            type: SensorTypeEnum.ShaftBearingTemperature,
            position: SensorPositionEnum.Boot,
            side: EquipmentSideEnum.North,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 16,
            type: SensorTypeEnum.CurrentAmpDraw,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null,
          },
          {
            id: 17,
            type: SensorTypeEnum.CurrentDesignLoad,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
          {
            id: 18,
            type: SensorTypeEnum.Vibration,
            position: SensorPositionEnum.Motor,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
          {
            id: 19,
            type: SensorTypeEnum.Vibration,
            position: SensorPositionEnum.Frame,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
        ] as SensorViewModel[]
      },
      {
        id: 5,
        type: EquipmentTypeEnum.Leg,
        subType: EquipmentSubTypeEnum.WithoutKneeBearing,
        name: 'Leg without knee bearing (North-South)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 7,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 8,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 9,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 10,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 11,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 12,
          type: SensorTypeEnum.GearBoxTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 13,
          type: SensorTypeEnum.GearBoxTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 14,
          type: SensorTypeEnum.ShaftBearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 15,
          type: SensorTypeEnum.ShaftBearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 16,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 17,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 18,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 19,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      /* CONVEYOR */
      {
        id: 6,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Drag,
        name: 'Conveyor drag (North-South)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 16,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 17,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 18,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 19,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      {
        id: 7,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Belt,
        name: 'Conveyor belt (North-South)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.BendHead,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.BendHead,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 7,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.BendTail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 8,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.BendTail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 9,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.SnubHead,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 10,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.SnubHead,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 11,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.SnubTail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 12,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.SnubTail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 13,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.KneeHead,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 14,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.KneeHead,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 15,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.KneeTail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 16,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.KneeTail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 17,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.UpperTripperHead,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 18,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.UpperTripperHead,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 19,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.UpperTripperTail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 20,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.UpperTripperTail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 21,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.LowerTripperHead,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 22,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.LowerTripperHead,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 23,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.LowerTripperTail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 24,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.LowerTripperTail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 25,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 26,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 27,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 28,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 29,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.BendHead,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 30,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.BendHead,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 31,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.BendTail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 32,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.BendTail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 33,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.SnubHead,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 34,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.SnubHead,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 35,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.SnubTail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 36,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.SnubTail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 37,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.KneeHead,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 38,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.KneeHead,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 39,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.KneeTail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 40,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.KneeTail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 41,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 42,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 43,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 44,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 45,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      {
        id: 8,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Screw,
        name: 'Conveyor screw (None)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 4,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 5,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 6,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 7,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      {
        id: 9,
        type: EquipmentTypeEnum.Leg,
        subType: EquipmentSubTypeEnum.WithKneeBearing,
        name: 'Leg with knee bearing (East-West)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Knee,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Knee,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 7,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 8,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 9,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 10,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 11,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 12,
          type: SensorTypeEnum.GearBoxTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 13,
          type: SensorTypeEnum.GearBoxTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 14,
          type: SensorTypeEnum.ShaftBearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 15,
          type: SensorTypeEnum.ShaftBearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 16,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 17,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 18,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 19,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      {
        id: 10,
        type: EquipmentTypeEnum.Leg,
        subType: EquipmentSubTypeEnum.WithoutKneeBearing,
        name: 'Leg without knee bearing (East-West)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 7,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 8,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 9,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 10,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 11,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 12,
          type: SensorTypeEnum.GearBoxTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 13,
          type: SensorTypeEnum.GearBoxTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 14,
          type: SensorTypeEnum.ShaftBearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 15,
          type: SensorTypeEnum.ShaftBearingTemperature,
          position: SensorPositionEnum.Boot,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 16,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 17,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 18,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 19,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      /* CONVEYOR */
      {
        id: 11,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Drag,
        name: 'Conveyor drag (East-West)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 6,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 7,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 8,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 9,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      {
        id: 12,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Belt,
        name: 'Conveyor belt (East-West)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.BendHead,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.BendHead,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 7,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.BendTail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 8,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.BendTail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 9,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.SnubHead,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 10,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.SnubHead,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 11,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.SnubTail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 12,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.SnubTail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 13,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.KneeHead,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 14,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.KneeHead,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 15,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.KneeTail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 16,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.KneeTail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 17,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.UpperTripperHead,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 18,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.UpperTripperHead,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 19,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.UpperTripperTail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 20,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.UpperTripperTail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 21,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.LowerTripperHead,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 22,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.LowerTripperHead,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 23,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.LowerTripperTail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 24,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.LowerTripperTail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 25,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 26,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 27,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 28,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 29,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.BendHead,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 30,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.BendHead,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 31,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.BendTail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 32,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.BendTail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 33,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.SnubHead,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 34,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.SnubHead,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 35,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.SnubTail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 36,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.SnubTail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 37,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.KneeHead,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 38,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.KneeHead,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 39,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.KneeTail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 40,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.KneeTail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 41,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 42,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 43,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 44,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 45,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      {
        id: 13,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.Screw,
        name: 'Conveyor screw (None)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 4,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 5,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 6,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 7,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ] as SensorViewModel[]
      },
      {
        id: 14,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.CoveredBelt,
        name: 'Conveyor covered belt (East-West)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 7,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 8,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 9,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 10,
          type: SensorTypeEnum.BottomRubTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 11,
          type: SensorTypeEnum.BottomRubTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 12,
          type: SensorTypeEnum.BottomRubTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 13,
          type: SensorTypeEnum.BottomRubTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 14,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 15,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 16,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 17,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ]
      }, {
        id: 15,
        type: EquipmentTypeEnum.Conveyor,
        subType: EquipmentSubTypeEnum.CoveredBelt,
        name: 'Conveyor covered belt (North-South)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 7,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 8,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 9,
          type: SensorTypeEnum.Speed,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 10,
          type: SensorTypeEnum.BottomRubTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 11,
          type: SensorTypeEnum.BottomRubTemperature,
          position: SensorPositionEnum.Head,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 12,
          type: SensorTypeEnum.BottomRubTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 13,
          type: SensorTypeEnum.BottomRubTemperature,
          position: SensorPositionEnum.Tail,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        },
        {
          id: 14,
          type: SensorTypeEnum.CurrentAmpDraw,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 15,
          type: SensorTypeEnum.CurrentDesignLoad,
          position: SensorPositionEnum.OnePerEquipment,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 16,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Motor,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        {
          id: 17,
          type: SensorTypeEnum.Vibration,
          position: SensorPositionEnum.Frame,
          side: EquipmentSideEnum.None,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: null,
          rateOfChangeWarningAddressBit: null,
          rateOfChangeAlarmAddress: null,
          rateOfChangeAlarmAddressBit: null,
        },
        ]
      }, {
        id: 16,
        type: EquipmentTypeEnum.Tripper,
        subType: EquipmentSubTypeEnum.None,
        name: 'Tripper (North-South)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Upper,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Upper,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Lower,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Lower,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Top,
          side: EquipmentSideEnum.North,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Top,
          side: EquipmentSideEnum.South,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }]
      }, {
        id: 17,
        type: EquipmentTypeEnum.Tripper,
        subType: EquipmentSubTypeEnum.None,
        name: 'Tripper (East-West)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [{
          id: 1,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Upper,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 2,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Upper,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 3,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Lower,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 4,
          type: SensorTypeEnum.BearingTemperature,
          position: SensorPositionEnum.Lower,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 5,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Top,
          side: EquipmentSideEnum.East,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }, {
          id: 6,
          type: SensorTypeEnum.RubBlockTemperature,
          position: SensorPositionEnum.Top,
          side: EquipmentSideEnum.West,
          modbusAddress: 65536,
          warningAddress: 113,
          warningAddressBit: 2,
          alarmAddress: 42,
          alarmAddressBit: 1,
          rateOfChangeWarningAddress: 33,
          rateOfChangeWarningAddressBit: 6,
          rateOfChangeAlarmAddress: 2,
          rateOfChangeAlarmAddressBit: 16
        }]
      },
      {
        id: 18,
        type: EquipmentTypeEnum.Fan,
        subType: EquipmentSubTypeEnum.DirectDrive,
        name: 'Fan Direct Drive (None)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [
          {
            id: 1,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.Drive,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 2,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.Frame,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 3,
            type: SensorTypeEnum.Speed,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 4,
            type: SensorTypeEnum.CurrentAmpDraw,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
          {
            id: 5,
            type: SensorTypeEnum.CurrentDesignLoad,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
          {
            id: 6,
            type: SensorTypeEnum.Vibration,
            position: SensorPositionEnum.Motor,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
          {
            id: 7,
            type: SensorTypeEnum.Vibration,
            position: SensorPositionEnum.Frame,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null,
          },
        ]
      },
      {
        id: 19,
        type: EquipmentTypeEnum.Fan,
        subType: EquipmentSubTypeEnum.BeltDrive,
        name: 'Fan Belt Drive (None)',
        totalRunHoursFirstRegisterAddress: 1,
        totalRunHoursSecondRegisterAddress: 65536,
        timeTillMaintenanceAddress: 2,
        shutDownAlarmAddress: 3,
        shutDownAlarmAddressBit: 1,
        maintenanceRequiredWarningAddress: 3,
        maintenanceRequiredWarningAddressBit: 2,
        sensors: [
          {
            id: 1,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.FrameUpper,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 2,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.FrameLower,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 3,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.DriveUpper,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 4,
            type: SensorTypeEnum.BearingTemperature,
            position: SensorPositionEnum.DriveLower,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 5,
            type: SensorTypeEnum.Speed,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: 33,
            rateOfChangeWarningAddressBit: 6,
            rateOfChangeAlarmAddress: 2,
            rateOfChangeAlarmAddressBit: 16
          },
          {
            id: 6,
            type: SensorTypeEnum.CurrentAmpDraw,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
          {
            id: 7,
            type: SensorTypeEnum.CurrentDesignLoad,
            position: SensorPositionEnum.OnePerEquipment,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
          {
            id: 8,
            type: SensorTypeEnum.Vibration,
            position: SensorPositionEnum.Motor,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null
          },
          {
            id: 9,
            type: SensorTypeEnum.Vibration,
            position: SensorPositionEnum.Frame,
            side: EquipmentSideEnum.None,
            modbusAddress: 65536,
            warningAddress: 113,
            warningAddressBit: 2,
            alarmAddress: 42,
            alarmAddressBit: 1,
            rateOfChangeWarningAddress: null,
            rateOfChangeWarningAddressBit: null,
            rateOfChangeAlarmAddress: null,
            rateOfChangeAlarmAddressBit: null,
          },
        ]
      },
    ] as EquipmentViewModel[];

    const locationEquipment = {
      deviceId: deviceId,
      equipments
    } as LocationBtxEquipmentViewModel

    return locationEquipment;
  }

  private async getFakeTelemetry(sensorsIds: number[]) {
    let lastTelemetry = [];
    let telemetry: LastTelemetrySensorResponse;
    sensorsIds.forEach(element => {
      telemetry = {
        id: element,
        value: 55 + element,
        timestamp: 1610969600 + element,
        status: this.getRandomSensorStatusEnum(SensorValuesStatusEnum)
      };

      lastTelemetry.push(telemetry);
    });

    return lastTelemetry;
  }

  private getRandomSensorStatusEnum<T>(anEnum: T): T[keyof T] {
    const enumValues = Object.keys(anEnum)
      .map(n => Number.parseInt(n))
      .filter(n => !Number.isNaN(n)) as unknown as T[keyof T][]
    const randomIndex = Math.floor(Math.random() * enumValues.length)
    const randomEnumValue = enumValues[randomIndex]
    return randomEnumValue;
  }

  private getSensorEventType(status: SensorValuesStatusEnum): BtxSensorEventTypeEnum {
    const rand = Math.floor(Math.random() * 2)
    switch (status) {
      case SensorValuesStatusEnum.Norm:
        return BtxSensorEventTypeEnum.Norm;
      case SensorValuesStatusEnum.Error:
        return BtxSensorEventTypeEnum.Error;
      case SensorValuesStatusEnum.Alarm:
        return rand == 0 ? BtxSensorEventTypeEnum.Alarm : BtxSensorEventTypeEnum.RateOfChangeAlarm;
      case SensorValuesStatusEnum.Warning:
        return rand == 0 ? BtxSensorEventTypeEnum.Warning : BtxSensorEventTypeEnum.RateOfChangeWarning;
    }
  }
}