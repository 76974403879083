import { AqsFlushFloorTunnelDesignEnum } from './../../../enums';

export class AqsFlushFloorViewModel {
    id: number;
    tunnelChoice: AqsFlushFloorTunnelDesignEnum;
    aa: number;
    bb: number;
    cc: number;
    dd: number;
    ee: number;
    ff: number;
    gg: number;
    hh: number;
    ii: number;
    jj: number;
    kk: number;
    ll: number;
    supportHeight1: number;
    supportHeight2: number;
    supportHeight3: number;
    totalTunnelSupport1: number;
    totalTunnelSupport2: number;
    totalTunnelSupport3: number;
    totalTunnelSupport4: number;
    totalTunnelSupportSpan1: number;
    totalTunnelSupportSpan2: number;
    totalTunnelSupportSpan3: number;
    totalTunnelSupportSpan4: number;
    constructor() {
        this.id = 0;
        this.aa = null;
        this.bb = null;
        this.cc = null;
        this.ee = null;
        this.ff = null;
        this.gg = null;
        this.hh = null;
        this.ii = null;
        this.jj = null;
        this.kk = null;
        this.supportHeight1 = null;
        this.supportHeight2 = null;
        this.supportHeight3 = null;
        this.tunnelChoice = null;
        this.totalTunnelSupport1 = null;
        this.totalTunnelSupport2 = null;
        this.totalTunnelSupport3 = null;
        this.totalTunnelSupport4 = null;
        this.totalTunnelSupportSpan1 = null;
        this.totalTunnelSupportSpan2 = null;
        this.totalTunnelSupportSpan3 = null;
        this.totalTunnelSupportSpan4 = null;
    }
}

export class AqsFlushFloorCalculatedValues {
    rad: number;
    lowerLarge: number;
    mcsv: number;
    msv: number;
    csa: number;
    maxSpan: number;
    minSpan: number = 7;
    maxTunnelWidth: number = 120;
    maxWideTunnelWidth: number = 144;
    crossec: ReadonlyArray<number> = [
        24, 30, 36, 40, 48, 60, 72, 78, 84, 90, 96, 102, 108, 114, 120, 126, 132, 138, 144
    ]

    supportHeights: ReadonlyArray<number> = [7.25, 11.25];
    //50 DIM CROSSSEC(3,11): FOR I = 0 TO 2: FOR J = 0 TO 10: READ CROSSSEC(I, J): NEXT J, I
    //560 DATA -1, 24, 30, 36, 40, 48, 60, 72, 84, 96, 120
    //570 DATA 7.25, .9063, 1.2083, 1.5104, 1.7118, 2.1146, 2.7188, 3.3229, 3.9271, 4.5313, 5.7396
    //580 DATA 11.25, 1.4063, 1.8750, 2.3438, 2.6563, 3.2813, 4.2188, 5.1563, 6.0938, 7.0313, 8.9063

    public calcMainTunnelWidth(max: number): [number, number] {
        let i = 0;
        let j = 0;
        let maxIndex = this.crossec.indexOf(max);
        if (maxIndex == -1) return;
        for (i = 0; i < this.supportHeights.length; i++) {
            for (j = 0; j <= maxIndex; j++) {
                let widthCsa = this.supportHeights[i] * (this.crossec[j] - 6) / 144;
                if (widthCsa >= this.csa) {
                    return [this.supportHeights[i], this.crossec[j]];
                }
            }
        }

        return [this.supportHeights[1], this.crossec[maxIndex]];
    }

    public calcSupportTunnelFan(width: number, maxTunnelWidth: number = 120): number {
        let item: number;
        for (item of this.crossec) {
            if (item >= width) break;
        }
        if (item > maxTunnelWidth) item = maxTunnelWidth;
        return item;
    }


    public checkValueByCrossSec(value: number, max: number): number {
        let i: number;
        let maxIndex = this.crossec.indexOf(max);
        for (i = 0; i <= maxIndex; i++) {
            if (value <= this.crossec[i]) break;
        }
        if (i > maxIndex) i = maxIndex;
        return this.crossec[i];
    }

    public calcCSVelocityFans(sHeight: number, tWidth: number, tunnelCoef: number = 1): number {
        let actualTWidth = tWidth - 6;
        return Math.round(this.lowerLarge / (sHeight * actualTWidth / 144) / (2 * tunnelCoef));
    }
}