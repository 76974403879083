import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BACK_DOOR, BackDoorRouteToRoleMap } from '../../constants';
import { LocalStorageService, UserRoleEnum } from '../../../common';
import { NavigationUser } from '@models';
import { ProjectEnum } from '../../enums';

@Injectable()
export class RoleService {
  constructor(
    private _localStorageService: LocalStorageService,
    private _router: Router
  ) {}

  public userHasRole(role: UserRoleEnum): boolean {
    const url = this._router.url;
    const isBackDoor = url.indexOf(BACK_DOOR) !== -1;

    const userLoginInfo = isBackDoor
      ? this.getBackDoorUserWithRole(url)
      : this._localStorageService.getLoginInfo();

    return userLoginInfo && userLoginInfo.role === role;
  }

  public getBackDoorUserWithRole(url: string): NavigationUser {
    const role = Object.keys(BackDoorRouteToRoleMap).find(
      (key) => url.indexOf(key) !== -1
    );

    return {
      role: role ? BackDoorRouteToRoleMap[role] : UserRoleEnum.ServiceUser,
      projects: ProjectEnum.Btx | ProjectEnum.Ktx | ProjectEnum.Itx,
    };
  }
}
