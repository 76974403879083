import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { CompaniesDataSourceService } from '../../../services';
import { UserRoleEnum, ErrorModel, ErrorCodeEnum } from '../../../../common';
import { CompanyViewModel, CompanyPostModel } from '../../../models';

@Component({
  selector: 'greensleeves-companies-add-edit-popup',
  templateUrl: './companies-add-edit-popup.component.html',
  styles: []
})
export class CompaniesAddEditPopupComponent {
  _isHidden = true;
  _submitted = false;
  _companiesForm: FormGroup;
  _isAddPopup: boolean;
  _company: CompanyViewModel;
  _invalidName = false;

  _role: UserRoleEnum;

  constructor(
    private _formBuilder: FormBuilder,
    private _companiesService: CompaniesDataSourceService,
  ) {
  }

  onChange() {
    this._submitted = false;
  }

  public showAdd() {
    this._invalidName = false;
    this._companiesForm = this._formBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ])
      ]
    });

    this._isAddPopup = true;
    this._submitted = false;
    this._isHidden = false;
    this._companiesForm.reset();
  }

  public showEdit(company: CompanyViewModel) {
    this._invalidName = false;
    this._company = company;
    this._companiesForm = this._formBuilder.group({
      name: [
        this._company.name,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ])
      ]
    });

    this._isAddPopup = false;
    this._submitted = false;
    this._isHidden = false;
  }

  async onSubmit() {
    this._submitted = true;
    if (this._companiesForm.invalid && !this._invalidName) {
      return;
    }

    if (this._isAddPopup) {
      const value = this._companiesForm.value as string;
      this._companiesService.add(value)
        .then((response: CompanyViewModel) => {
          if(response instanceof ErrorModel && response.code === ErrorCodeEnum.NotUniqueCompanyName) {
            this._invalidName = true;
          } else if (response) {
            this._submitted = false;
            this._isHidden = true;
          }
        })
    }

    else {
      const value = {
        ...this._companiesForm.value,
        id: this._company.id,
      } as CompanyPostModel;
      this._companiesService.edit(value)
        .then((response: CompanyViewModel) => {
          if(response instanceof ErrorModel && response.code === ErrorCodeEnum.NotUniqueCompanyName) {
            this._invalidName = true;
          } else if (response) {
            this._submitted = false;
            this._isHidden = true;
          }
        })
    }
  }

  async onClose() {
    this._companiesService.markCompanyForOthersAsUpdated(this._company, false);
  }
}