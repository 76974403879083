import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import { HubMethods } from '../../constants';
import {
  CompanyAdminViewModel,
  CompanyAdminPostModel,
  CompanyAdminInviteModel,
  CompanyAdminAddedHubMessage,
  CompanyAdminDeletedHubMessage,
  CompanyAdminInUpdateHubMessage,
  CompanyAdminUpdatedHubMessage,
} from '../../models';
import { CompanyAdminsApiService, HubService } from '../http';

@Injectable()
export class CompanyAdminsDataSourceService {
  public companyAdmins$: BehaviorSubject<CompanyAdminViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(
    private _companyAdminsApi: CompanyAdminsApiService,
    private _hubService: HubService,
    ) {
      this.subscribeToHubService();
    }

  public async get(companyId: number) {
    try {
      if (companyId != null) {
        const data = await this._companyAdminsApi.loadAllForCompany(companyId);
        this.companyAdmins$.next(data as CompanyAdminViewModel[]);
      }
      else {
        const data = await this._companyAdminsApi.loadAll();
        this.companyAdmins$.next(data as CompanyAdminViewModel[]);
      }

      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async invite(companyAdmin: CompanyAdminInviteModel): Promise<CompanyAdminViewModel | ErrorModel> {
    try {
      const result = await this._companyAdminsApi.invite(companyAdmin) as CompanyAdminViewModel;
      if (result) {
        this._hubService.sendToCompanyAdminsAndAllServiceUsers(HubMethods.CompanyAdmin.companyAdminAdded, result.companyId, new CompanyAdminAddedHubMessage(result));
        if (this.companyAdmins$.getValue().find(x => x.email == result.email)) {
          const companyAdmins = this.companyAdmins$.getValue().map(l => l.email === result.email ? { ...l, ...result} : l);
          this.companyAdmins$.next(companyAdmins);
        } else {
          this.companyAdmins$.next([
            ...this.companyAdmins$.getValue(),
            result
          ]);
        }
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async edit(companyAdmin: CompanyAdminPostModel) : Promise<boolean|ErrorModel> {
    try
    {
      const result = await this._companyAdminsApi.edit(companyAdmin) as CompanyAdminViewModel;
      if (result) {
        const companyAdmins = this.companyAdmins$.getValue().map(l => l.id === result.id ? { ...l, ...result} : l);
        this.companyAdmins$.next(companyAdmins);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async delete(companyAdminId: number, companyId: number) : Promise<boolean|ErrorModel> {
    try
    {
      const result = await this._companyAdminsApi.delete(companyAdminId);
      if (result) {
        this._hubService.sendToCompanyAdminsAndAllServiceUsers(HubMethods.CompanyAdmin.companyAdminDeleted, companyId, new CompanyAdminDeletedHubMessage(companyAdminId));
        const companyAdmins = this.companyAdmins$.getValue().filter(l => l.id !== result);
        this.companyAdmins$.next(companyAdmins);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async markCompanyAdminForOthersAsInUpdate(userId: number, companyId: number): Promise<any> {
    this._hubService.sendToCompanyAdminsAndAllServiceUsers(HubMethods.CompanyAdmin.companyAdminInUpdate, companyId, new CompanyAdminInUpdateHubMessage(userId));
  }

  public async markCompanyAdminForOthersAsUpdated(companyAdmin: CompanyAdminViewModel, withChanges: boolean): Promise<any> {
    this._hubService.sendToCompanyAdminsAndAllServiceUsers(HubMethods.CompanyAdmin.companyAdminUpdated, companyAdmin.companyId, new CompanyAdminUpdatedHubMessage(companyAdmin, withChanges));
  }

  private subscribeToHubService() {
    this._hubService.companyAdminAdded.subscribe((hubMessage: CompanyAdminAddedHubMessage) => {
      if (hubMessage && hubMessage.newCompanyAdmin) {
        this.companyAdmins$.next([
          ...this.companyAdmins$.getValue(),
          hubMessage.newCompanyAdmin
        ]);
      }
    });
    this._hubService.companyAdminDeleted.subscribe((hubMessage: CompanyAdminDeletedHubMessage) => {
      if (hubMessage) {
        const users = this.companyAdmins$.getValue().filter(l => l.id !== hubMessage.companyAdminId);
        this.companyAdmins$.next(users);
      }
    });
    this._hubService.companyAdminInUpdate.subscribe((hubMessage: CompanyAdminInUpdateHubMessage) => {
      if (hubMessage) {
        const companyAdmin = this.companyAdmins$.getValue().find(l => l.id === hubMessage.companyAdminId);
        if (companyAdmin) {
          companyAdmin.isSomeoneUpdateCompanyAdmin = true;
        }
      }
    });
    this._hubService.companyAdminUpdated.subscribe((hubMessage: CompanyAdminUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedCompanyAdmin) {
        if (hubMessage.withChanges) {
          hubMessage.updatedCompanyAdmin.isSomeoneUpdateCompanyAdmin = undefined;
          this.companyAdmins$.next(this.companyAdmins$.getValue().map(l => l.id === hubMessage.updatedCompanyAdmin.id ? hubMessage.updatedCompanyAdmin : l));
        } else {
          const companyAdmin = this.companyAdmins$.getValue().find(l => l.id == hubMessage.updatedCompanyAdmin.id);
          companyAdmin.isSomeoneUpdateCompanyAdmin = false;
        }
      }
    });
  }
}
