import { PLCDeviceEnum } from './../enums/equipment.enum';
export class BtxHardwareIconClass {
    public static readonly arduino_device = 'arduino-hardware-icon';
    public static readonly raspberry_device = 'raspberry-hardware-icon';
    public static getIconClass(deviceType: PLCDeviceEnum): string {
        switch (deviceType) {
            case PLCDeviceEnum.Arduino:
                return BtxHardwareIconClass.arduino_device;
            case PLCDeviceEnum.Raspberry:
                return BtxHardwareIconClass.raspberry_device;
        }
    }

}

export const BtxPLCDeviceLabel = {
    [PLCDeviceEnum.Arduino]: "P1AM",
    [PLCDeviceEnum.Raspberry]: "Rev Pi",
}