import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AqsSessionTypeEnum } from '../../../enums';

@Component({
  selector: 'greensleeves-aqs-new-quote-popup',
  templateUrl: './aqs-new-quote-popup.component.html',
  styleUrls: []
})

export class AqsNewQuotePopupComponent implements OnInit {
  @Output() redirectMethod = new EventEmitter<AqsSessionTypeEnum>();

  _isHidden: boolean = true;
  _sessionTypes = AqsSessionTypeEnum;
  _selectedSessionType: AqsSessionTypeEnum = null;

  get isSelectedType() {
    return this._selectedSessionType != null;
  }

  constructor() { }

  ngOnInit() {
  }

  public onSelectQuoteType(sessionType: AqsSessionTypeEnum) {
    this._selectedSessionType = sessionType;
  }

  public onClickCreate() {
    this.redirectMethod.emit(this._selectedSessionType);
    this.onCancel();
  }

  public showConfirmPopup() {
    this._isHidden = false;
  }

  public onCancel() {
    this._selectedSessionType = null;
    this._isHidden = true;
  }

}
