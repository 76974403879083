import { TreeNode } from 'primeng';
import { PLCDeviceEnum } from './../../enums/equipment.enum';
import { AcquisitionRateEnum } from "../../enums";
import { ModelsTypes } from "../../constants";
import { EquipmentViewModel, EquipmentGetModel, EquipmentPostModel, BtxLiveDataEquipmentViewModel, BtxLiveDataEquipmentGetModel } from "../equipment-btx.model";
import { BTXLocationConfiguration, BtxLocationEquipmentConfiguration } from './location.model';

export class LocationBtxViewModel {
    public deviceId: string;
    public id: number;
    public locationName: string;
    public companyName: string;

    //Device fields
    public btxIp: string;
    public btxPort: number = 502;
    public deviceIp: string;
    public deviceMacAddress: string;
    public acquisitionRate: AcquisitionRateEnum;
    public batteryAlarmAddress: number;
    public batteryAlarmAddressBit: number;
    public suppressorAlarmAddress: number;
    public suppressorAlarmAddressBit: number;
    public communicationAlarmAddress: number;
    public communicationAlarmAddressBit: number;
    public isInitialized: boolean;
    public isConnectionFileUpdated: boolean;
    public plcDeviceConnection: PLCDeviceEnum;

    //User behavior lock fields
    public isSomeoneUpdateLocation: boolean;
    public isDeviceInInitialization: boolean;

    //Infrastructure fields
    modelType = ModelsTypes.locationBtxViewModel;
}

export class LocationBtxGetModel {
    public deviceId: string;
    public locationId: number;
    public locationName: string;
    public companyName: string;

    // Device fields
    public btxIp: string;
    public btxPort: number;
    public deviceIp: string;
    public deviceMacAddress: string;
    public acquisitionRate: AcquisitionRateEnum;
    public batteryAlarmAddress: number;
    public batteryAlarmAddressBit: number;
    public suppressorAlarmAddress: number;
    public suppressorAlarmAddressBit: number;
    public communicationAlarmAddress: number;
    public communicationAlarmAddressBit: number;
    public isInitialized: boolean;
    public isConnectionFileUpdated: boolean;
    public plcDeviceConnection: PLCDeviceEnum;

    public static toViewModel(getModel: LocationBtxGetModel): LocationBtxViewModel {
        const viewModel = new LocationBtxViewModel();
        viewModel.deviceId = getModel.deviceId;
        viewModel.id = getModel.locationId;
        viewModel.locationName = getModel.locationName;
        viewModel.companyName = getModel.companyName;

        viewModel.btxIp = getModel.btxIp;
        viewModel.btxPort = getModel.btxPort;
        viewModel.deviceIp = getModel.deviceIp;
        viewModel.deviceMacAddress = getModel.deviceMacAddress;
        viewModel.acquisitionRate = getModel.acquisitionRate;
        viewModel.batteryAlarmAddress = getModel.batteryAlarmAddress;
        viewModel.batteryAlarmAddressBit = getModel.batteryAlarmAddressBit;
        viewModel.suppressorAlarmAddress = getModel.suppressorAlarmAddress;
        viewModel.suppressorAlarmAddressBit = getModel.suppressorAlarmAddressBit;
        viewModel.communicationAlarmAddress = getModel.communicationAlarmAddress;
        viewModel.communicationAlarmAddressBit = getModel.communicationAlarmAddressBit;
        viewModel.isInitialized = getModel.isInitialized;
        viewModel.isConnectionFileUpdated = getModel.isConnectionFileUpdated;
        viewModel.plcDeviceConnection = getModel.plcDeviceConnection;

        return viewModel;
      }
}

export class LocationBtxPostModel {
    public deviceId: string;
    public id: number;

    //Device fields
    public btxIp: string;
    public btxPort: number;
    public deviceIp: string;
    public deviceMacAddress: string;
    public acquisitionRate: AcquisitionRateEnum;
    public batteryAlarmAddress: number;
    public batteryAlarmAddressBit: number;
    public suppressorAlarmAddress: number;
    public suppressorAlarmAddressBit: number;
    public communicationAlarmAddress: number;
    public communicationAlarmAddressBit: number;
    public plcDeviceConnection: PLCDeviceEnum;

    constructor(viewModel: LocationBtxViewModel) {
        this.deviceId = viewModel.deviceId;
        this.id = viewModel.id;

        this.btxIp = viewModel.btxIp;
        this.btxPort = Number(viewModel.btxPort);//field is string after input form 
        this.deviceIp = viewModel.deviceIp;
        this.deviceMacAddress = viewModel.deviceMacAddress;
        this.acquisitionRate = viewModel.acquisitionRate;
        this.batteryAlarmAddress = Number(viewModel.batteryAlarmAddress);//field is string after input form
        this.batteryAlarmAddressBit = viewModel.batteryAlarmAddressBit;
        this.suppressorAlarmAddress = Number(viewModel.suppressorAlarmAddress);//field is string after input form
        this.suppressorAlarmAddressBit = viewModel.suppressorAlarmAddressBit;
        this.communicationAlarmAddress = Number(viewModel.communicationAlarmAddress);//field is string after input form
        this.communicationAlarmAddressBit = viewModel.communicationAlarmAddressBit;
        this.plcDeviceConnection = viewModel.plcDeviceConnection;
    }
}

export class LocationBtxEquipmentViewModel extends LocationBtxViewModel {
    equipments: EquipmentViewModel[];

    public static toViewModel(location: LocationBtxViewModel, equipments: BtxLocationEquipmentConfiguration[]) : LocationBtxEquipmentViewModel {
        const viewModel = new LocationBtxEquipmentViewModel;
        viewModel.id = location.id;
        viewModel.locationName = location.locationName;
        viewModel.batteryAlarmAddress = location.batteryAlarmAddress;
        viewModel.batteryAlarmAddressBit = location.batteryAlarmAddressBit;
        viewModel.suppressorAlarmAddress = location.suppressorAlarmAddress;
        viewModel.suppressorAlarmAddressBit = location.suppressorAlarmAddressBit;
        viewModel.communicationAlarmAddress = location.communicationAlarmAddress;
        viewModel.communicationAlarmAddressBit = location.communicationAlarmAddressBit;
        viewModel.equipments = equipments.map(equipment => EquipmentViewModel.toViewModel(equipment));

        return viewModel;
    }
}

export class LocationBtxEquipmentGetModel {
    deviceId: string;
    id: number;
    equipments: EquipmentGetModel[];

    public static toViewModel(getModel: LocationBtxEquipmentGetModel): LocationBtxEquipmentViewModel {
        const viewModel = new LocationBtxEquipmentViewModel();
        viewModel.deviceId = getModel.deviceId;
        viewModel.id = getModel.id;

        if (getModel.equipments) {
            viewModel.equipments = getModel.equipments.map(eq => EquipmentGetModel.toViewModel(eq));
        }

        return viewModel;
      }
}

export class LocationBtxEquipmentPostModel {
    deviceId: string;
    Id: number;
    equipments: EquipmentPostModel[];

    constructor(viewModel: LocationBtxEquipmentViewModel) {
        this.deviceId = viewModel.deviceId;
        this.Id = viewModel.id;
        this.equipments = viewModel.equipments.map(eq => new EquipmentPostModel(eq));
    }
}

export class BtxLiveDataLocationViewModel {
    id: number;
    name: string;
    equipments: BtxLiveDataEquipmentViewModel[];

    public static toReportLocationTreeNode(locationViewModel: BtxLiveDataLocationViewModel): TreeNode {
      const locationTreeNode: TreeNode = {
          label: locationViewModel.name,
          data: locationViewModel.id,
          selectable: true,
          key: locationViewModel.id.toString(),
          children: locationViewModel.equipments.map(BtxLiveDataEquipmentViewModel.toReportEquipmentTreeNode)
      };

      return locationTreeNode;
    }
}

export class BtxLiveDataLocationGetModel {
    id: number;
    name: string;
    equipments: BtxLiveDataEquipmentGetModel[];

    public static toViewModel(getModel: BtxLiveDataLocationGetModel): BtxLiveDataLocationViewModel {
        const viewModel = new BtxLiveDataLocationViewModel();

        viewModel.id = getModel.id;
        viewModel.name = getModel.name;

        if (getModel.equipments) {
            viewModel.equipments = getModel.equipments.map(eq => BtxLiveDataEquipmentGetModel.toViewModel(eq));
        }

        return viewModel;
      }
}

export class BtxAccordionLocationItemViewModel extends BtxLiveDataLocationViewModel {
  isActive: boolean;
}
