import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ItxBinQuickEditPostModel } from '@models';
import { ItxBinDataSourceService } from './../../../services';

@Component({
  selector: 'greensleeves-itx-quick-edit-popup',
  templateUrl: './itx-quick-edit-popup.component.html',
  styles: []
})
export class ItxQuickEditPopupComponent implements OnInit {

  _isHidden: boolean = true;
  _itxBinName: string;
  _editStorageForm: FormGroup;
  _submitted = false;
  _material: string;
  _editBin: ItxBinQuickEditPostModel;
  _isRun=false;
  needToUpdateLiveData = new EventEmitter<ItxBinQuickEditPostModel>();
  constructor(private _formBuilder: FormBuilder,
    private itxBinDataSource: ItxBinDataSourceService) { }

  ngOnInit() {
  }

  public async show(editBin: ItxBinQuickEditPostModel) {
    this._itxBinName = editBin.name;
    this._editBin = editBin;
    this.initForm();
    this._isHidden = false;
    this._submitted = false;
  }

  public async onCancel() {
    this._isHidden = true;
    this._editBin = null;
    this._submitted = false;
    this._editStorageForm.reset();
    this._isRun=false;
  }

  public async onSubmit() {
    this._submitted = true;
    if (this._editStorageForm.invalid) {
      return;
    }
    let itxBin = this._editStorageForm.value as ItxBinQuickEditPostModel;
    this._isRun=true;
    let result = await this.itxBinDataSource.quickEdit(itxBin);
    if (result) {
      this.needToUpdateLiveData.emit(itxBin);
    }
    this.onCancel();
  }

  private initForm() {
    this._editStorageForm = this._formBuilder.group({
      material: [
        this._editBin.material,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      id: [this._editBin.id],
      name: [
        this._editBin.name,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
    });
  }
}
