export class TemperatureRangePostModel {
    locationId: number;
    ranges: TemperatureRangeViewModel[];
    alarm: AlarmViewModel;
    public static toTemperatureRangeAlarmViewModel(postModel: TemperatureRangePostModel): TemperatureRangeAlarmViewModel {
        const temperatureAlarmViewModel = new TemperatureRangeAlarmViewModel();
        temperatureAlarmViewModel.alarm = postModel.alarm;
        temperatureAlarmViewModel.temperatureRanges = postModel.ranges;
        temperatureAlarmViewModel.locationId = postModel.locationId;
        return temperatureAlarmViewModel;
    }
}

export class TemperatureRangeGetModel {
    public locationId: number;
    public temperatureRanges: TemperatureRangeViewModel[];
    public alarm: AlarmViewModel;
    public canDeviceBeInitialized: boolean;
    public isInitialized: boolean;

    public static toTemperatureRangeAlarmViewModel(getModel: TemperatureRangeGetModel): TemperatureRangeAlarmViewModel {
        const viewModel = new TemperatureRangeAlarmViewModel();
        viewModel.locationId = getModel.locationId;
        viewModel.temperatureRanges = getModel.temperatureRanges;
        viewModel.alarm = getModel.alarm;

        return viewModel;
    }
}

export class TemperatureRangeViewModel {
    id: number;
    from: number;
    to: number;
    colorHex: string;
    public static toTemperatureColorViewModels(postModels: TemperatureRangeViewModel[]): TemperatureColorViewModel[] {

        return postModels.map((postModel) => {
            const temperatureColorViewModel = new TemperatureColorViewModel();
            temperatureColorViewModel.value = postModel.to.toString();
            temperatureColorViewModel.colorHex = postModel.colorHex;
            return temperatureColorViewModel;
        });
    }
}

export class AlarmViewModel {
    maxRiseValue: number;
    maxRiseColorHex: string = '';
    highTemperatureValue: number;
    highTemperatureColorHex: string = '';
    highResistanceValue: number;
    highResistanceColorHex: string = '';
    normalResistanceColorHex: string = '';
    openTcColorHex: string;
    noResponseColorHex: string;
}

export class TemperatureRangeAlarmViewModel {
    public locationId: number;
    public temperatureRanges: TemperatureRangeViewModel[];
    public alarm: AlarmViewModel;
}

export class TemperatureColorViewModel {
    value: string;
    colorHex: string;
}