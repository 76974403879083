import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'greensleeves-location-changed-popup',
  templateUrl: './location-changed-popup.component.html',
  styles: []
})

export class LocationChangedPopupComponent implements OnInit {
  _isHidden = true;

  constructor(
  ) { }

  ngOnInit() {
  }

  public show() {
    this._isHidden = false;
  }

  async onSubmit() {
    this._isHidden = true;
  }

  onCancel() {
    this._isHidden = true;
  }
}