import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';

import { BinSchemeViewModel, CableSchemeViewModel } from '../../../models';
import { BinTypeEnum, ColumnTypeEnum, SwitchTypeEnum } from '../../../enums';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-ktx-list-cables-popup',
  templateUrl: './ktx-list-cables-popup.component.html'
})
export class KtxListCablesPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy {
  @ViewChild('cableTable', { static: false }) table: Table;
  static NAME_FIELD = 'name';
  static ID_FIELD = 'connectionId';
  static ANGLE_FIELD = 'angle';
  static RADIUS_FIELD = 'radius';
  static SENSORS_AMOUNT_FIELD = 'thermocoupleCount';
  static MUX_OR_DEVICE_FIELD = 'multiplexerId';
  static X_POSITION_FIELD = 'xPosition';
  static Y_POSITION_FIELD = 'yPosition';

  _isHidden: boolean = true;
  _gridData: CableSchemeViewModel[] = [];
  _columnTypeEnum = ColumnTypeEnum;
  _switchTypeEnum = SwitchTypeEnum;
  _binTypeEnum = BinTypeEnum;
  _binType: BinTypeEnum;
  _displayScheme: boolean;
  _sortField = KtxListCablesPopupComponent.NAME_FIELD;

  private muxOrDeviceHeaderName: string;

  get _columnsView() {
    if (this._binType === BinTypeEnum.Circular) {
      return [
        { header: 'Cable name', columnType: ColumnTypeEnum.CableName, dataField: KtxListCablesPopupComponent.NAME_FIELD },
        { header: 'Cable ID', columnType: ColumnTypeEnum.CableID, dataField: KtxListCablesPopupComponent.ID_FIELD },
        { header: 'Angle', columnType: ColumnTypeEnum.Angle, dataField: KtxListCablesPopupComponent.ANGLE_FIELD },
        { header: 'Radius', columnType: ColumnTypeEnum.Radius, dataField: KtxListCablesPopupComponent.RADIUS_FIELD },
        { header: 'Number of sensors', columnType: ColumnTypeEnum.SensorsAmount, dataField: KtxListCablesPopupComponent.SENSORS_AMOUNT_FIELD },
        { header: this.muxOrDeviceHeaderName, columnType: ColumnTypeEnum.MUXOrDevice, dataField: KtxListCablesPopupComponent.MUX_OR_DEVICE_FIELD },
      ];
    } else if (this._binType === BinTypeEnum.Rectangular) {
      return [
        { header: 'Cable name', columnType: ColumnTypeEnum.CableName, dataField: KtxListCablesPopupComponent.NAME_FIELD },
        { header: 'Cable ID', columnType: ColumnTypeEnum.CableID, dataField: KtxListCablesPopupComponent.ID_FIELD },
        { header: 'X Position', columnType: ColumnTypeEnum.Angle, dataField: KtxListCablesPopupComponent.X_POSITION_FIELD },
        { header: 'Y Position', columnType: ColumnTypeEnum.Radius, dataField: KtxListCablesPopupComponent.Y_POSITION_FIELD },
        { header: 'Number of sensors', columnType: ColumnTypeEnum.SensorsAmount, dataField: KtxListCablesPopupComponent.SENSORS_AMOUNT_FIELD },
        { header: this.muxOrDeviceHeaderName, columnType: ColumnTypeEnum.MUXOrDevice, dataField: KtxListCablesPopupComponent.MUX_OR_DEVICE_FIELD },
      ];
    }

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  constructor() {
    super();
  }

  ngOnInit() {

  }

  onClose() {
    this._gridData = [];
    this._displayScheme = false;
    this._isHidden = true;
    this.table.first = 0;
  }

  public show(bin: BinSchemeViewModel) {
    this._isHidden = false;
    this._displayScheme = bin.displaySchemes;
    this._binType = bin.binType;
    this._columnsView;

    if (bin.switchType === SwitchTypeEnum.MUX) {
      this.muxOrDeviceHeaderName = 'Device';
    } else {
      this.muxOrDeviceHeaderName = 'MUX';
    }

    this._gridData = bin.cables;
  }
}
