import { CommonFunctions } from './../common/functions/commonFunctions';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RoutePaths, AccountService, UserRoleEnum, CompleteRegistrationVendorModel } from '../common';

//TODO
//Check invalid messages
@Component({
  selector: 'greensleeves-registration',
  templateUrl: './complete-registration.component.html'
})

export class CompleteRegistrationComponent implements OnInit {
   private _token: string;
  _completeRegistrationFormGroup: FormGroup;
  _isSubmit = false;
  _isShowPassword: boolean;
  _isShowPasswordConfirm: boolean;
  _privacyLink = RoutePaths.PRIVACY;
  _userRole: UserRoleEnum;

  get _isVendor() {
    return this._userRole === UserRoleEnum.Vendor;
  }
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _accountService: AccountService,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this._token = params['token'];
    });
    this._userRole = CommonFunctions.getRoleFromInvitationToken(this._token);

    this._completeRegistrationFormGroup = this.formBuilder.group({
      companyName: [this._isVendor?'':'empty', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(50)])],
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50)])],
      confirm: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50)])]
    });
  }

  get f() { return this._completeRegistrationFormGroup.controls; }

  onSubmit() {
    this._isSubmit = true;
    const companyName = this._isVendor?this.f.companyName.value.trim():'';
    const firstName = this.f.firstName.value.trim();
    const lastName = this.f.lastName.value.trim();
    const password = this.f.password.value.trim();
    const confirm = this.f.confirm.value.trim();

    if (this._completeRegistrationFormGroup.invalid) {
      return;
    }
    if (password != confirm) {
      return;
    }

    const completeRegistrationModel = new CompleteRegistrationVendorModel(companyName,this._token, firstName, lastName, password, password);
      const result = this._isVendor?this._accountService.completeRegistrationVendor(completeRegistrationModel)
                                   :this._accountService.completeRegistration(completeRegistrationModel);
      if (result != null) {
        this.router.navigateByUrl(RoutePaths.SIGN_IN);
      }
  }

  onSwitchShowHidePassword() {
    this._isShowPassword = !this._isShowPassword;
  }

  onSwitchShowHidePasswordConfirmation() {
    this._isShowPasswordConfirm = !this._isShowPasswordConfirm;
  }
}
