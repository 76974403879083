import { DefaultDateRangeEnum } from './../enums';

class DateLocale {
    firstDayOfWeek: number;
    dayNames: Array<string>;
    dayNamesShort: Array<string>;
    dayNamesMin: Array<string>;
    monthNames: Array<string>;
    monthNamesShort: Array<string>;
    dateFormat: string;
    today: string;
}

export class EnglishDateLocale extends DateLocale {
    constructor() {
        super();
        this.firstDayOfWeek = 0;
        this.dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        this.dayNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        this.dayNamesMin = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
        this.monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        this.dateFormat = 'mm/dd/yy';
    }
}

export const DefaultDateRangeLabel = {
    [DefaultDateRangeEnum.Day]: 'Day',
    [DefaultDateRangeEnum.Week]: 'Week',
    [DefaultDateRangeEnum.Month]: 'Month',
}


export class CalendarDefaultValues {
    public static readonly defaultOneMonthDays: number = 29;
    public static readonly defaultOneWeekDays: number = 6;
    public static readonly defaultOneSecondMilliseconds: number = 1000;
}
