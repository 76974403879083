import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
  BtxEventByPeriodPostModel,
  BtxEventsRequestModel,
  ItxEventByPeriodPostModel,
  ItxEventsRequestModel,
  KtxEventByPeriodPostModel,
  KtxEventsRequestModel,
} from '@models';
import { EventApiService } from '../http';
import { ErrorModel } from './../../../common';

@Injectable({
  providedIn: 'root',
})
export class EventDatasourceService {
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  constructor(private _eventApi: EventApiService) { }

  public async getItxEventsByFilter(
    query: ItxEventByPeriodPostModel
  ): Promise<ItxEventsRequestModel | ErrorModel> {
    try {
      const result = (await this._eventApi.getItxByFilter(
        query
      )) as ItxEventsRequestModel;
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getKtxEventsByFilter(
    query: KtxEventByPeriodPostModel
  ): Promise<KtxEventsRequestModel | ErrorModel> {
    try {
      const result = (await this._eventApi.getKtxByFilter(
        query
      )) as KtxEventsRequestModel;
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getBtxEventsByFilter(
    query: BtxEventByPeriodPostModel
  ): Promise<BtxEventsRequestModel | ErrorModel> {
    try {
      const result = (await this._eventApi.getBtxByFilter(
        query
      )) as BtxEventsRequestModel;
      return result;
    } catch (error) {
      this.errors$.next(error);
    }
  }
}
