import { SensorStatusEnum } from "../../enums";

export class SensorKtxViewModel {
    id: number;
    status: SensorStatusEnum;
    value: number;

    public static toSchemeViewModel(getModel: SensorKtxGetModel): SensorKtxSchemeViewModel {
        const viewModel = new SensorKtxSchemeViewModel();

        viewModel.id = getModel.id;
        viewModel.status = getModel.status;
        viewModel.value = getModel.value;

        return viewModel;
    }
}

export class SensorKtxSchemeViewModel {
    id: number;
    status: SensorStatusEnum;
    value: number;
}

export class SensorKtxGetModel {
    id: number;
    status: SensorStatusEnum;
    value: number;

    public static toViewModel(getModel: SensorKtxGetModel): SensorKtxViewModel {
        const viewModel = new SensorKtxViewModel();

        viewModel.id = getModel.id;
        viewModel.status = getModel.status;
        viewModel.value = getModel.value;

        return viewModel;
    }
}