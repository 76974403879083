import { Injectable, EventEmitter } from '@angular/core';
import { HubConnectionBuilder, HubConnection, LogLevel, HttpTransportType } from '@microsoft/signalr';

import { ApiRoutes, HubMethods } from '../../constants';
import { UserRoleEnum } from '../../../common';
import { AccessTokenFactory } from '../../access-token.factory';
import {
  LocationAddedHubMessage,
  LocationDeletedHubMessage,
  LocationInUpdateHubMessage,
  LocationUpdatedHubMessage,
  LocationBtxInUpdateHubMessage,
  LocationBtxUpdatedHubMessage,
  LocationEquipmentsInUpdateHubMessage,
  LocationEquipmentsUpdatedHubMessage,
  LocationDeviceInInitializationHubMessage,
  LocationDeviceInitializedHubMessage,
  CompanyAddedHubMessage,
  CompanyDeletedHubMessage,
  CompanyInUpdateHubMessage,
  CompanyUpdatedHubMessage,
  ServiceUserAddedHubMessage,
  ServiceUserDeletedHubMessage,
  ServiceUserInUpdateHubMessage,
  ServiceUserUpdatedHubMessage,
  CompanyAdminAddedHubMessage,
  CompanyAdminDeletedHubMessage,
  CompanyAdminInUpdateHubMessage,
  CompanyAdminUpdatedHubMessage,
  CompanyUserAddedHubMessage,
  CompanyUserDeletedHubMessage,
  CompanyUserInUpdateHubMessage,
  CompanyUserUpdatedHubMessage,
  LocationKtxAddedHubMessage,
  LocationKtxUpdatedHubMessage,
  LocationItxUpdatedHubMessage,
  LocationKtxInUpdateHubMessage,
  LocationKtxDeletedHubMessage,
  LocationKtxBinsUpdatedHubMessage,
  LocationKtxDeviceInitializedHubMessage,
  LocationKtxDeviceInInitializationHubMessage,
  LocationKtxBinsInUpdateHubMessage,
  LocationBtxDeletedHubMessage,
  LocationBtxAddedHubMessage,
  LocationKtxTemperatureRangeUpdatedHubMessage,
  VendorDeletedHubMessage,
  VendorAddedHubMessage,
  VendorInUpdateHubMessage,
  VendorUpdatedHubMessage,
  LocationItxStoragesInViewHubMessage,
  LocationItxFullnessRangeUpdatedHubMessage,
  LocationItxDeletedHubMessage,
  LocationItxInUpdateHubMessage,
  ItxBinAddedHubMessage,
  LocationItxAddedHubMessage,
  ItxBinInUpdateHubMessage,
  ItxBinUpdatedHubMessage,
  ItxBinDeletedHubMessage,
  ItxBinRadarAddedHubMessage,
  ItxBinRadarInUpdateHubMessage,
  ItxBinRadarUpdatedHubMessage,
  ItxBinRadarDeletedHubMessage,
  LocationItxDeviceInInitializationHubMessage,
  LocationItxDeviceInitializedHubMessage,
  SalesUserAddedHubMessage,
  SalesUserDeletedHubMessage,
  SalesUserInUpdateHubMessage,
  SalesUserUpdatedHubMessage,
  UpdaterProjectUpdateFirmwareHubMessage
} from '../../models';
import { LocalStorageService } from '../../../common';

type HubMessages =
  LocationAddedHubMessage |
  LocationDeletedHubMessage |
  LocationInUpdateHubMessage |
  LocationUpdatedHubMessage |
  LocationEquipmentsInUpdateHubMessage |
  LocationEquipmentsUpdatedHubMessage |
  LocationDeviceInInitializationHubMessage |
  LocationDeviceInitializedHubMessage |
  CompanyAddedHubMessage |
  CompanyDeletedHubMessage |
  CompanyInUpdateHubMessage |
  CompanyUpdatedHubMessage |
  ServiceUserAddedHubMessage |
  ServiceUserDeletedHubMessage |
  ServiceUserInUpdateHubMessage |
  ServiceUserUpdatedHubMessage |
  CompanyAdminAddedHubMessage |
  CompanyAdminDeletedHubMessage |
  CompanyAdminInUpdateHubMessage |
  CompanyAdminUpdatedHubMessage |
  CompanyUserAddedHubMessage |
  CompanyUserDeletedHubMessage |
  CompanyUserInUpdateHubMessage |
  CompanyUserUpdatedHubMessage |
  LocationBtxInUpdateHubMessage |
  LocationBtxUpdatedHubMessage |
  LocationKtxInUpdateHubMessage |
  LocationKtxUpdatedHubMessage |
  LocationItxUpdatedHubMessage |
  LocationItxAddedHubMessage |
  LocationKtxAddedHubMessage |
  LocationKtxDeletedHubMessage |
  LocationKtxBinsInUpdateHubMessage |
  LocationKtxBinsUpdatedHubMessage |
  LocationKtxDeviceInitializedHubMessage |
  LocationKtxDeviceInInitializationHubMessage |
  LocationBtxAddedHubMessage |
  LocationBtxUpdatedHubMessage |
  LocationBtxUpdatedHubMessage |
  LocationKtxTemperatureRangeUpdatedHubMessage |
  VendorDeletedHubMessage |
  VendorAddedHubMessage |
  VendorInUpdateHubMessage |
  VendorUpdatedHubMessage |
  LocationItxInUpdateHubMessage |
  LocationItxStoragesInViewHubMessage |
  LocationItxUpdatedHubMessage |
  LocationItxFullnessRangeUpdatedHubMessage |
  LocationItxDeletedHubMessage |
  ItxBinAddedHubMessage |
  ItxBinInUpdateHubMessage |
  ItxBinUpdatedHubMessage |
  ItxBinDeletedHubMessage |
  ItxBinRadarAddedHubMessage |
  ItxBinRadarInUpdateHubMessage |
  ItxBinRadarUpdatedHubMessage |
  ItxBinRadarDeletedHubMessage |
  LocationItxDeviceInInitializationHubMessage |
  LocationItxDeviceInitializedHubMessage |
  SalesUserAddedHubMessage |
  SalesUserDeletedHubMessage |
  SalesUserInUpdateHubMessage |
  SalesUserUpdatedHubMessage |
  UpdaterProjectUpdateFirmwareHubMessage

@Injectable()
export class HubService {
  private _hubConnection: HubConnection;
  private _shouldBeConnected = false;

  //locations
  public locationAdded = new EventEmitter<LocationAddedHubMessage>();
  public locationDeleted = new EventEmitter<LocationDeletedHubMessage>();
  public locationInUpdate = new EventEmitter<LocationInUpdateHubMessage>();
  public locationUpdated = new EventEmitter<LocationUpdatedHubMessage>();
  //BTX location
  public locationBtxAdded = new EventEmitter<LocationBtxAddedHubMessage>();
  public locationBtxDeleted = new EventEmitter<LocationBtxDeletedHubMessage>();
  public locationBtxInUpdate = new EventEmitter<LocationBtxInUpdateHubMessage>();
  public locationBtxUpdated = new EventEmitter<LocationBtxUpdatedHubMessage>();
  public locationBtxEquipmentsInUpdate = new EventEmitter<LocationEquipmentsInUpdateHubMessage>();
  public locationBtxEquipmentsUpdated = new EventEmitter<LocationEquipmentsUpdatedHubMessage>();
  public locationBtxDeviceInInitialization = new EventEmitter<LocationDeviceInInitializationHubMessage>();
  public locationBtxDeviceInitialized = new EventEmitter<LocationDeviceInitializedHubMessage>();
  //companies
  public companyAdded = new EventEmitter<CompanyAddedHubMessage>();
  public companyDeleted = new EventEmitter<CompanyDeletedHubMessage>();
  public companyInUpdate = new EventEmitter<CompanyInUpdateHubMessage>();
  public companyUpdated = new EventEmitter<CompanyUpdatedHubMessage>();
  //service users
  public serviceUserAdded = new EventEmitter<ServiceUserAddedHubMessage>();
  public serviceUserDeleted = new EventEmitter<ServiceUserDeletedHubMessage>();
  public serviceUserInUpdate = new EventEmitter<ServiceUserInUpdateHubMessage>();
  public serviceUserUpdated = new EventEmitter<ServiceUserUpdatedHubMessage>();
  //sales users
  public salesUserAdded = new EventEmitter<SalesUserAddedHubMessage>();
  public salesUserDeleted = new EventEmitter<SalesUserDeletedHubMessage>();
  public salesUserInUpdate = new EventEmitter<SalesUserInUpdateHubMessage>();
  public salesUserUpdated = new EventEmitter<SalesUserUpdatedHubMessage>();
  //company users
  public companyUserAdded = new EventEmitter<CompanyUserAddedHubMessage>();
  public companyUserDeleted = new EventEmitter<CompanyUserDeletedHubMessage>();
  public companyUserInUpdate = new EventEmitter<CompanyUserInUpdateHubMessage>();
  public companyUserUpdated = new EventEmitter<CompanyUserUpdatedHubMessage>();
  //company admins
  public companyAdminAdded = new EventEmitter<CompanyAdminAddedHubMessage>();
  public companyAdminDeleted = new EventEmitter<CompanyAdminDeletedHubMessage>();
  public companyAdminInUpdate = new EventEmitter<CompanyAdminInUpdateHubMessage>();
  public companyAdminUpdated = new EventEmitter<CompanyAdminUpdatedHubMessage>();
  // vendor
  public vendorAdded = new EventEmitter<VendorAddedHubMessage>();
  public vendorDeleted = new EventEmitter<VendorDeletedHubMessage>();
  public vendorInUpdate = new EventEmitter<VendorInUpdateHubMessage>();
  public vendorUpdated = new EventEmitter<VendorUpdatedHubMessage>();
  //KTX location
  public locationKtxAdded = new EventEmitter<LocationKtxAddedHubMessage>();
  public locationKtxInUpdate = new EventEmitter<LocationKtxInUpdateHubMessage>();
  public locationKtxUpdated = new EventEmitter<LocationKtxUpdatedHubMessage>();
  public locationKtxDeleted = new EventEmitter<LocationKtxDeletedHubMessage>();
  public locationKtxBinsInUpdate = new EventEmitter<LocationKtxBinsInUpdateHubMessage>();
  public locationKtxBinsUpdated = new EventEmitter<LocationKtxBinsUpdatedHubMessage>();
  public locationKtxDeviceInInitialization = new EventEmitter<LocationKtxDeviceInInitializationHubMessage>();
  public locationKtxDeviceInitialized = new EventEmitter<LocationKtxDeviceInitializedHubMessage>();
  // Location KTX scheme
  public temperatureRangeUpdated = new EventEmitter<LocationKtxTemperatureRangeUpdatedHubMessage>();
  //ITX locations
  public fullnessRangeUpdated = new EventEmitter<LocationItxFullnessRangeUpdatedHubMessage>();
  public locationItxStorageInView = new EventEmitter<LocationItxStoragesInViewHubMessage>();
  public locationItxUpdated = new EventEmitter<LocationItxUpdatedHubMessage>();
  public locationItxAdded = new EventEmitter<LocationItxAddedHubMessage>();
  public locationItxInUpdate = new EventEmitter<LocationItxInUpdateHubMessage>();
  public locationItxDeleted = new EventEmitter<LocationItxDeletedHubMessage>();
  public locationItxDeviceInInitialization = new EventEmitter<LocationItxDeviceInInitializationHubMessage>();
  public locationItxDeviceInitialized = new EventEmitter<LocationItxDeviceInitializedHubMessage>();
  //ITX BIn
  public itxBinAdded = new EventEmitter<ItxBinAddedHubMessage>();
  public itxBinInUpdate = new EventEmitter<ItxBinInUpdateHubMessage>();
  public itxBinUpdated = new EventEmitter<ItxBinUpdatedHubMessage>();
  public itxBinDeleted = new EventEmitter<ItxBinDeletedHubMessage>();
  public itxBinRadarAdded = new EventEmitter<ItxBinRadarAddedHubMessage>();
  public itxBinRadarInUpdate = new EventEmitter<ItxBinRadarInUpdateHubMessage>();
  public itxBinRadarUpdated = new EventEmitter<ItxBinRadarUpdatedHubMessage>();
  public itxBinRadarDeleted = new EventEmitter<ItxBinRadarDeletedHubMessage>();

  //Updater
  public updaterProjectUpdateFirmware = new EventEmitter<UpdaterProjectUpdateFirmwareHubMessage>();

  constructor(
    private _accessTokenFactory: AccessTokenFactory,
    private _localStorageService: LocalStorageService,
  ) {
  }

  public connect() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(ApiRoutes.hub, {
        transport: HttpTransportType.LongPolling,
        accessTokenFactory: this._accessTokenFactory.getAuthorizationAttributeValue.bind(this._accessTokenFactory),
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.None)
      .build();

    this.subscribeToServerEvents();

    this._hubConnection
      .start()
      .then(async () => {
        console.log('SignalR Hub connection started');
        const loginInfo = this._localStorageService.getLoginInfo();
        if (loginInfo.role === UserRoleEnum.CompanyAdmin) {
          await this._hubConnection.send(ApiRoutes.Hub.bindCompanyAdminToCompanyGroup, loginInfo.companyId);
        }
      })
      .catch(err => {
        this.restartConnection(err);
      });

    this._shouldBeConnected = true;
  }

  public async sendToAll(method: string, messageData: HubMessages) {
    await this._hubConnection.send(ApiRoutes.Hub.sendToAll, method, messageData);
  }

  public async sendToAllServiceUsers(method: string, messageData: HubMessages) {
    await this._hubConnection.send(ApiRoutes.Hub.sendToAllServiceUsers, method, messageData);
  }

  public async sendToCompanyAdminsAndAllServiceUsers(method: string, companyId: number, messageData: HubMessages) {
    await this._hubConnection.send(ApiRoutes.Hub.sendToCompanyAdminsAllAndServiceUsers, method, companyId, messageData);
  }

  public async sendFromCompanyAdminToCompanyAdmins(method: string, messageData: HubMessages) {
    const loginInfo = this._localStorageService.getLoginInfo();
    await this._hubConnection.send(ApiRoutes.Hub.sendToCompanyAdmins, method, loginInfo.companyId, messageData);
  }

  private restartConnection(err: Error): void {
    console.log(`Error ${err}`);
    console.log('Retrying connection to SignalR Hub ...');
    setTimeout(() => {
      this.connect();
    }, 1000);
  }

  private subscribeToServerEvents(): void {
    //locations
    this._hubConnection.on(HubMethods.Location.locationAdded, (messageData: LocationAddedHubMessage) => {
      console.log(HubMethods.Location.locationAdded, messageData);
      this.locationAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Location.locationDeleted, (messageData: LocationDeletedHubMessage) => {
      console.log(HubMethods.Location.locationDeleted, messageData);
      this.locationDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Location.locationInUpdate, (messageData: LocationInUpdateHubMessage) => {
      console.log(HubMethods.Location.locationInUpdate, messageData);
      this.locationInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Location.locationUpdated, (messageData: LocationUpdatedHubMessage) => {
      console.log(HubMethods.Location.locationUpdated, messageData);
      this.locationUpdated.emit(messageData);
    });

    //location BTX
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxEquipmentsInUpdate, (messageData: LocationEquipmentsInUpdateHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxEquipmentsInUpdate, messageData);
      this.locationBtxEquipmentsInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxInUpdate, (messageData: LocationBtxInUpdateHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxInUpdate, messageData);
      this.locationBtxInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxAdded, (messageData: LocationBtxAddedHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxAdded, messageData);
      this.locationBtxAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxDeleted, (messageData: LocationBtxDeletedHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxDeleted, messageData);
      this.locationBtxDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxEquipmentsInUpdate, (messageData: LocationEquipmentsInUpdateHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxEquipmentsInUpdate, messageData);
      this.locationBtxEquipmentsInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxEquipmentsUpdated, (messageData: LocationEquipmentsUpdatedHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxEquipmentsUpdated, messageData);
      this.locationBtxEquipmentsUpdated.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxUpdated, (messageData: LocationBtxUpdatedHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxUpdated, messageData);
      this.locationBtxUpdated.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxDeviceInInitialization, (messageData: LocationDeviceInInitializationHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxDeviceInInitialization, messageData);
      this.locationBtxDeviceInInitialization.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxDeviceInitialized, (messageData: LocationDeviceInitializedHubMessage) => {
      console.log(HubMethods.LocationBtx.locationBtxDeviceInitialized, messageData);
      this.locationBtxDeviceInitialized.emit(messageData);
    });

    //locations
    this._hubConnection.on(HubMethods.LocationBtx.locationBtxDeleted, (messageData: LocationBtxDeletedHubMessage) => {
      console.log(HubMethods.Location.locationAdded, messageData);
      this.locationBtxDeleted.emit(messageData);
    });

    //companies
    this._hubConnection.on(HubMethods.Company.companyAdded, (messageData: CompanyAddedHubMessage) => {
      console.log(HubMethods.Company.companyAdded, messageData);
      this.companyAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Company.companyDeleted, (messageData: CompanyDeletedHubMessage) => {
      console.log(HubMethods.Company.companyDeleted, messageData);
      this.companyDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Company.companyInUpdate, (messageData: CompanyInUpdateHubMessage) => {
      console.log(HubMethods.Company.companyInUpdate, messageData);
      this.companyInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Company.companyUpdated, (messageData: CompanyUpdatedHubMessage) => {
      console.log(HubMethods.Company.companyUpdated, messageData);
      this.companyUpdated.emit(messageData);
    });

    //service users
    this._hubConnection.on(HubMethods.ServiceUser.serviceUserAdded, (messageData: ServiceUserAddedHubMessage) => {
      console.log(HubMethods.ServiceUser.serviceUserAdded, messageData);
      this.serviceUserAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ServiceUser.serviceUserDeleted, (messageData: ServiceUserDeletedHubMessage) => {
      console.log(HubMethods.ServiceUser.serviceUserDeleted, messageData);
      this.serviceUserDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ServiceUser.serviceUserInUpdate, (messageData: ServiceUserInUpdateHubMessage) => {
      console.log(HubMethods.ServiceUser.serviceUserInUpdate, messageData);
      this.serviceUserInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ServiceUser.serviceUserUpdated, (messageData: ServiceUserUpdatedHubMessage) => {
      console.log(HubMethods.ServiceUser.serviceUserUpdated, messageData);
      this.serviceUserUpdated.emit(messageData);
    });

    //sales users
    this._hubConnection.on(HubMethods.SalesUser.salesUserAdded, (messageData: SalesUserAddedHubMessage) => {
      console.log(HubMethods.SalesUser.salesUserAdded, messageData);
      this.salesUserAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.SalesUser.salesUserDeleted, (messageData: SalesUserDeletedHubMessage) => {
      console.log(HubMethods.SalesUser.salesUserDeleted, messageData);
      this.salesUserDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.SalesUser.salesUserInUpdate, (messageData: SalesUserInUpdateHubMessage) => {
      console.log(HubMethods.SalesUser.salesUserInUpdate, messageData);
      this.salesUserInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.SalesUser.salesUserUpdated, (messageData: SalesUserUpdatedHubMessage) => {
      console.log(HubMethods.SalesUser.salesUserUpdated, messageData);
      this.salesUserUpdated.emit(messageData);
    });

    //company users
    this._hubConnection.on(HubMethods.CompanyUser.companyUserAdded, (messageData: CompanyUserAddedHubMessage) => {
      console.log(HubMethods.CompanyUser.companyUserAdded, messageData);
      this.companyUserAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.CompanyUser.companyUserDeleted, (messageData: CompanyUserDeletedHubMessage) => {
      console.log(HubMethods.CompanyUser.companyUserDeleted, messageData);
      this.companyUserDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.CompanyUser.companyUserInUpdate, (messageData: CompanyUserInUpdateHubMessage) => {
      console.log(HubMethods.CompanyUser.companyUserInUpdate, messageData);
      this.companyUserInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.CompanyUser.companyUserUpdated, (messageData: CompanyUserUpdatedHubMessage) => {
      console.log(HubMethods.CompanyUser.companyUserUpdated, messageData);
      this.companyUserUpdated.emit(messageData);
    });

    //company admins
    this._hubConnection.on(HubMethods.CompanyAdmin.companyAdminAdded, (messageData: CompanyAdminAddedHubMessage) => {
      console.log(HubMethods.CompanyAdmin.companyAdminAdded, messageData);
      this.companyAdminAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.CompanyAdmin.companyAdminDeleted, (messageData: CompanyAdminDeletedHubMessage) => {
      console.log(HubMethods.CompanyAdmin.companyAdminDeleted, messageData);
      this.companyAdminDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.CompanyAdmin.companyAdminInUpdate, (messageData: CompanyAdminInUpdateHubMessage) => {
      console.log(HubMethods.CompanyAdmin.companyAdminInUpdate, messageData);
      this.companyAdminInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.CompanyAdmin.companyAdminUpdated, (messageData: CompanyAdminUpdatedHubMessage) => {
      console.log(HubMethods.CompanyAdmin.companyAdminUpdated, messageData);
      this.companyAdminUpdated.emit(messageData);
    });

    //vendor
    this._hubConnection.on(HubMethods.Vendor.vendorAdded, (messageData: VendorAddedHubMessage) => {
      console.log(HubMethods.Vendor.vendorAdded, messageData);
      this.vendorAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Vendor.vendorDeleted, (messageData: VendorDeletedHubMessage) => {
      console.log(HubMethods.CompanyUser.companyUserDeleted, messageData);
      this.vendorDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Vendor.vendorInUpdate, (messageData: VendorInUpdateHubMessage) => {
      console.log(HubMethods.CompanyUser.companyUserInUpdate, messageData);
      this.vendorInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.Vendor.vendorUpdated, (messageData: VendorUpdatedHubMessage) => {
      console.log(HubMethods.Vendor.vendorUpdated, messageData);
      this.vendorUpdated.emit(messageData);
    });

    //KTX locations
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxAdded, (messageData: LocationKtxAddedHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxAdded, messageData);
      this.locationKtxAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxInUpdate, (messageData: LocationKtxInUpdateHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxInUpdate, messageData);
      this.locationKtxInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxUpdated, (messageData: LocationKtxUpdatedHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxUpdated, messageData);
      this.locationKtxUpdated.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxDeleted, (messageData: LocationKtxDeletedHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxDeleted, messageData);
      this.locationKtxDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxBinsInUpdate, (messageData: LocationKtxBinsInUpdateHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxBinsInUpdate, messageData);
      this.locationKtxBinsInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxBinsUpdated, (messageData: LocationKtxBinsUpdatedHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxBinsUpdated, messageData);
      this.locationKtxBinsUpdated.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxDeviceInInitialization, (messageData: LocationKtxDeviceInInitializationHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxDeviceInInitialization, messageData);
      this.locationKtxDeviceInInitialization.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxDeviceInitialized, (messageData: LocationKtxDeviceInitializedHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxDeviceInitialized, messageData);
      this.locationKtxDeviceInitialized.emit(messageData);
    });

    // Location KTX Scheme
    this._hubConnection.on(HubMethods.LocationKtx.locationKtxTemperatureRangeUpdated, (messageData: LocationKtxTemperatureRangeUpdatedHubMessage) => {
      console.log(HubMethods.LocationKtx.locationKtxTemperatureRangeUpdated, messageData);
      this.temperatureRangeUpdated.emit(messageData);
    });

    //Location ITX 
    this._hubConnection.on(HubMethods.LocationItx.locationItxInUpdate, (messageData: LocationItxInUpdateHubMessage) => {
      console.log(HubMethods.LocationItx.locationItxInUpdate, messageData);
      this.locationItxInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationItx.locationItxUpdated, (messageData: LocationItxUpdatedHubMessage) => {
      console.log(HubMethods.LocationItx.locationItxUpdated, messageData);
      this.locationItxUpdated.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationItx.locationItxFullnessRangeUpdated, (messageData: LocationItxFullnessRangeUpdatedHubMessage) => {
      console.log(HubMethods.LocationItx.locationItxFullnessRangeUpdated, messageData);
      this.fullnessRangeUpdated.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationItx.locationItxAdded, (messageData: LocationItxAddedHubMessage) => {
      console.log(HubMethods.LocationItx.locationItxAdded, messageData);
      this.locationItxAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationItx.locationItxDeleted, (messageData: LocationItxDeletedHubMessage) => {
      console.log(HubMethods.LocationItx.locationItxDeleted, messageData);
      this.locationItxDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationItx.locationItxStorageInView, (messageData: LocationItxStoragesInViewHubMessage) => {
      console.log(HubMethods.LocationItx.locationItxStorageInView, messageData);
      this.locationItxStorageInView.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationItx.locationItxDeviceInInitialization, (messageData: LocationItxDeviceInInitializationHubMessage) => {
      console.log(HubMethods.LocationItx.locationItxDeviceInInitialization, messageData);
      this.locationItxDeviceInInitialization.emit(messageData);
    });
    this._hubConnection.on(HubMethods.LocationItx.locationItxDeviceInitialized, (messageData: LocationItxDeviceInitializedHubMessage) => {
      console.log(HubMethods.LocationItx.locationItxDeviceInitialized, messageData);
      this.locationItxDeviceInitialized.emit(messageData);
    });

    //ITX Bin
    this._hubConnection.on(HubMethods.ItxBin.itxBinAdded, (messageData: ItxBinAddedHubMessage) => {
      console.log(HubMethods.ItxBin.itxBinAdded, messageData);
      this.itxBinAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ItxBin.itxBinInUpdate, (messageData: ItxBinInUpdateHubMessage) => {
      console.log(HubMethods.ItxBin.itxBinInUpdate, messageData);
      this.itxBinInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ItxBin.itxBinUpdated, (messageData: ItxBinUpdatedHubMessage) => {
      console.log(HubMethods.ItxBin.itxBinUpdated, messageData);
      this.itxBinUpdated.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ItxBin.itxBinDeleted, (messageData: ItxBinDeletedHubMessage) => {
      console.log(HubMethods.ItxBin.itxBinDeleted, messageData);
      this.itxBinDeleted.emit(messageData);
    });
    //ITX Bin radar
    this._hubConnection.on(HubMethods.ItxBin.itxBinRadarDeleted, (messageData: ItxBinRadarDeletedHubMessage) => {
      console.log(HubMethods.ItxBin.itxBinRadarDeleted, messageData);
      this.itxBinRadarDeleted.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ItxBin.itxBinRadarAdded, (messageData: ItxBinRadarAddedHubMessage) => {
      console.log(HubMethods.ItxBin.itxBinRadarAdded, messageData);
      this.itxBinRadarAdded.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ItxBin.itxBinRadarInUpdate, (messageData: ItxBinRadarInUpdateHubMessage) => {
      console.log(HubMethods.ItxBin.itxBinRadarInUpdate, messageData);
      this.itxBinRadarInUpdate.emit(messageData);
    });
    this._hubConnection.on(HubMethods.ItxBin.itxBinRadarUpdated, (messageData: ItxBinRadarUpdatedHubMessage) => {
      console.log(HubMethods.ItxBin.itxBinRadarUpdated, messageData);
      this.itxBinRadarUpdated.emit(messageData);
    });

    //Updater
    this._hubConnection.on(HubMethods.Updater.updaterProjectUpdateFirmware, (messageData: UpdaterProjectUpdateFirmwareHubMessage) => {
      console.log(HubMethods.Updater.updaterProjectUpdateFirmware, messageData);
      this.updaterProjectUpdateFirmware.emit(messageData);
    });

    this._hubConnection.onclose(async (error) => {
      if (this._shouldBeConnected) {
        this.restartConnection(new Error(`SignalR disconnected '${error ? '' : error.message}'`));
      }
    });
  }

  public disconect() {
    if (this._hubConnection) {
      this._shouldBeConnected = false;
      this._hubConnection.stop();
      this._hubConnection = null;
    }
  }
}