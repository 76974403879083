import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng';
import { isNumber } from 'util';

import { ErrorModel } from '../../../../common';
import { KtxReportCurrentDataFilter, LocationKtxSchemeViewModel } from '@models';
import { ReportDatasourceService } from '@services';

@Component({
  selector: 'greensleeves-ktx-current-data-export-report-popup',
  templateUrl: './ktx-current-data-export-report-popup.component.html',
})
export class KtxCurrentDataExportReportPopupComponent implements OnInit {
  static SELECT_ALL = 'Select All';

  _isHidden = true;
  _isReportRun = false;
  _locationBinsNodes: TreeNode[] = [];
  _selectedBinNodes: TreeNode[] = [];
  _locationScheme: LocationKtxSchemeViewModel;
  _selectedTemperatureMeasureType: number;

  constructor(
    private _reportDatasource: ReportDatasourceService
  ) { }

  ngOnInit() {
  }

  public show(locationScheme: LocationKtxSchemeViewModel, temperatureMeasureType: number) {
    this._isHidden = false;
    this._locationScheme = locationScheme;
    this._selectedTemperatureMeasureType = temperatureMeasureType;
    this.initLocationBinNodes(locationScheme);
  }

  onCancel() {
    this._isHidden = true;
  }

  async download() {
    this._isReportRun = true;

    let filter: KtxReportCurrentDataFilter = new KtxReportCurrentDataFilter();
    filter.locationId = this._locationScheme.locationId;
    filter.temperatureMeasureType = this._selectedTemperatureMeasureType;
    filter.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    filter.binIds = this._selectedBinNodes.map(x => {
      return x.data
    }).filter(x => isNumber(x));

    let result = await this._reportDatasource.downloadKtxCurrentDataReportFile(filter);
    if (!(result instanceof ErrorModel)) {
      let fileName = this._locationScheme.locationName + ' ' + new Date().toLocaleString();
      const url = URL.createObjectURL(result as Blob);
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    this._isReportRun = false;
  }

  onNodeSelect(event: any) {
    if (this.isSelectAllNode(event.node)) {
      this.selectAllNodes();
    }
  }

  onNodeUnselect(event: any) {
    if (this.isSelectAllNode(event.node)) {
      this.unselectAllNodes();
      return;
    }

    this._selectedBinNodes = this._selectedBinNodes.filter(n => !this.isSelectAllNode(n))
  }

  private initLocationBinNodes(locationScheme: LocationKtxSchemeViewModel) {
    const { SELECT_ALL } = KtxCurrentDataExportReportPopupComponent;

    const selectAllNodes: TreeNode = {
      label: SELECT_ALL,
      data: SELECT_ALL,
      key: SELECT_ALL,
    };

    this._locationBinsNodes = [
      selectAllNodes,
      ...LocationKtxSchemeViewModel.toReportsBinsTreeNode(locationScheme),
    ];
  }

  private selectAllNodes() {
    const parentsNodes: TreeNode[] = [...this._locationBinsNodes];
    const childrenNodes: TreeNode[] = this._locationBinsNodes
      .reduce((result, companyNode) => result.concat(companyNode.children), [])
      .filter((n) => n);

    this._selectedBinNodes = [...parentsNodes, ...childrenNodes];
  }

  private unselectAllNodes() {
    this._selectedBinNodes = [];
  }

  private isSelectAllNode(node: TreeNode) {
    const { label, data, key } = node;
    const { SELECT_ALL } = KtxCurrentDataExportReportPopupComponent;

    return label === SELECT_ALL && data === SELECT_ALL && key === SELECT_ALL;
  }
}
