export class AqsExhausterViewModel {
  id: number;
  code: number;
  qty: number;

  clearModel() {
    this.code = 0;
    this.qty = 0;
  }
}

export class AqsExhausterFanModel {
  code: number;
  fanType: string;
  model: string;
  qty1: number;
  qty2: number;
  safety1: number;
  safety2: number;
  mgva1: number;
  mgva2: number;

  constructor() {}
}

export class AqsExhausterFanDataBaseModel {
  code: number;
  qty: number;
}

export class AqsExhausterModel {
  name: string;
  exhauster: number;
  fanType:string;

  constructor() {
      // { name: '14 in 1 hp', exhauster: 2500 },
    // { name: '16 in 1.5 hp', exhauster: 3780 },
    // { name: '18 in 1.5 hp', exhauster: 4975 },
    // { name: '18 in 3 hp', exhauster: 6225 },
    // { name: '24 in 5 hp', exhauster: 10725 },
    // { name: '24 in 7.5 hp', exhauster: 13450 },
    // { name: '28 in 10 hp', exhauster: 17450 },
    // { name: '20 in 0.75 hp', exhauster: 6800 },
   // { name: '24 in 2 hp', exhauster: 10200},
    // { name: '40 in 5 hp', exhauster: 27450 },
    // { name: '20 in 0.75 hp', exhauster: 6800 },
    //{ name: '24 in 2 hp', exhauster: 10200 },
  }
}

export class AqsExhausterSelectedFanModel {
  code: number;
  model: string;
  qty: number;
  safety: number;
  gva: number;

  constructor() {}
}
