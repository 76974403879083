import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { ApiRoutes } from '../../constants';
import { ErrorModel } from '../../models';

@Injectable()
export class HttpService {
    constructor(
      private _http: HttpClient,
      ) { }

    public async post(url: string, body: any): Promise<any> {
      const options = { headers: this.generateJsonHeaders() };
      const data = JSON.stringify(body);
      return await this._http.post(`${ApiRoutes.endpoint}${url}`, data, options).toPromise()
      .then((response) => {
         return response;
      })
      .catch(async (error: HttpErrorResponse) => {
        await this.handleErrorResponse(error);
      });
    }

    public async put(url: string, body: any): Promise<any> {
      const options = { headers: this.generateJsonHeaders() };
      const data = JSON.stringify(body);
      return await this._http.put(`${ApiRoutes.endpoint}${url}`, data, options).toPromise()
      .then((response) => {
        return response;
      })
      .catch(async (error: HttpErrorResponse) => {
        await this.handleErrorResponse(error);
      });
    }

    public async postData(url: string, data: FormData): Promise<any> {
      const options = { headers: this.generateJsonHeaders() };
      return await this._http.post(`${ApiRoutes.endpoint}${url}`, data, options).toPromise()
      .then((response) => {
        return response;
      })
      .catch(async (error: HttpErrorResponse) => {
        await this.handleErrorResponse(error);
      });
    }

    public async postDataWithContent(url: string, data: FormData): Promise<any> {
      const options = { headers: new HttpHeaders() };
      return await this._http.post(`${ApiRoutes.endpoint}${url}`, data, options).toPromise()
      .then((response) => {
        return response;
      })
      .catch(async (error: HttpErrorResponse) => {
        await this.handleErrorResponse(error);
      });
    }

    public async get(url: string, params: { [key: string] : any | any[] } = {}): Promise<any> {
      const options = { headers: this.generateJsonHeaders(), params };
      return await this._http.get(`${ApiRoutes.endpoint}${url}`, options).toPromise()
      .then((response) => {
        return response;
      })
      .catch(async (error: HttpErrorResponse) => {
        await this.handleErrorResponse(error);
      });
    }

    public async getArrayBuffer(url: string, params: { [key: string] : any | any[] } = {}): Promise<any> {
      return await this._http.get(`${ApiRoutes.endpoint}${url}`, { headers: this.generateJsonHeaders(), responseType: 'arraybuffer', params }).toPromise()
      .then((response) => {
        return response;
      })
      .catch(async (error: HttpErrorResponse) => {
        await this.handleErrorResponse(error);
      });
    }

    public async postArrayBuffer(url: string, data: any): Promise<any> {
      return await this._http.post(`${ApiRoutes.endpoint}${url}`, data, { headers: this.generateJsonHeaders(), responseType: 'arraybuffer'}).toPromise()
      .then((response) => {
        return response;
      })
      .catch(async (error: HttpErrorResponse) => {
        await this.handleErrorResponse(error);
      });
    }

    public async delete(url: string, params: { [key: string] : any | any[] } = {}): Promise<any> {
      const options = { headers: this.generateJsonHeaders(), params };
      return await this._http.delete(`${ApiRoutes.endpoint}${url}`, options).toPromise()
      .then((response) => {
        return response;
      })
      .catch(async (error: HttpErrorResponse) => {
        await this.handleErrorResponse(error);
      });
    }

    private async handleErrorResponse(error: HttpErrorResponse) {
      console.log(error);

      if (error.error) {
        const errorModel = Object.assign(new ErrorModel(), error.error);
        throw errorModel;
      }

      throw error;
    }

    private generateJsonHeaders(): HttpHeaders {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');

      return headers;
    }
}
