import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormGroup, FormBuilder } from '@angular/forms';

import { ColumnTypeEnum } from '../../../enums';
import { CompanyViewModel } from '../../../models';
import { CompaniesDeletePopupComponent, CompaniesAddEditPopupComponent } from '../../popups';
import { CompaniesDataSourceService } from '../../../services';

@Component({
  selector: 'greensleeves-companies-grid',
  templateUrl: './companies-grid.component.html'
})
export class CompaniesGridComponent implements OnInit {
  @ViewChild(CompaniesAddEditPopupComponent, { read: false, static: false })
  _addEditModal: CompaniesAddEditPopupComponent;

  @ViewChild(CompaniesDeletePopupComponent, { read: false, static: false })
  _deleteModal: CompaniesDeletePopupComponent;

  static LOCATIONS_NAME_KEY = 'locations';
  static COMPANY_NAME_KEY = 'name';
  static SORT_ORDER_DEFAULT = -1;

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = CompaniesGridComponent.LOCATIONS_NAME_KEY;
  _sortOrder = CompaniesGridComponent.SORT_ORDER_DEFAULT;
  _gridData: CompanyViewModel[];
  _companyNameKey = CompaniesGridComponent.COMPANY_NAME_KEY;

  _searchForm: FormGroup;
  _lastSearchPhrase: string;

  _columnsView = [
    { header: 'Company', columnType: ColumnTypeEnum.Company, dataField: CompaniesGridComponent.COMPANY_NAME_KEY },
    { header: 'Locations', columnType: ColumnTypeEnum.LocationName, dataField: CompaniesGridComponent.LOCATIONS_NAME_KEY },
    { header: '', columnType: ColumnTypeEnum.Edit, width: "46" },
    { header: '', columnType: ColumnTypeEnum.Delete, width: "46", dataField: CompaniesGridComponent.LOCATIONS_NAME_KEY }
  ]
  _loading: boolean = false;
  private _companiesSubscription: Subscription;

  constructor(
    private _companiesService: CompaniesDataSourceService,
    formBuilder: FormBuilder
  ) {
    this._searchForm = formBuilder.group({
      searchPhrase: ['']
    });
  }

  ngOnInit() {
    this._companiesSubscription = this._companiesService.companies$.subscribe(items => {
      this._gridData = items ? items : [];
      this._loading = false;
    })
    this._loading = true;
    this._companiesService.get();
  }

  ngOnDestroy() {
    this._companiesSubscription
      && !this._companiesSubscription.closed
      && this._companiesSubscription.unsubscribe();

    this._companiesSubscription = null;
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
  }

  onClickEdit(company: CompanyViewModel) {
    this._companiesService.markCompanyForOthersAsInUpdate(company.id);
    this._addEditModal.showEdit(company);
  }

  onClickDelete(companyName: CompanyViewModel) {
    this._deleteModal.show(companyName);
  }

  onGetLocationsNames(company: CompanyViewModel): string {
    return company.locations ? company.locations.map(l => l.name).join(', ') : '';
  }
}
