import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { AqsFlatDuctModel, FlatStorageParams } from '@models';

@Component({
  selector: 'greensleeves-aqs-flat-duct-view-tab',
  templateUrl: './aqs-flat-duct-view-tab.component.html',
  styleUrls: []
})
export class AqsFlatDuctViewTabComponent implements OnInit {
  @ViewChild('viewCanvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @Input() _ductParams: AqsFlatDuctModel;
  @Input() _storageParams: FlatStorageParams;

  private ctx: CanvasRenderingContext2D;

  constructor() { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.drawDuct();
  }

  clearView() {
    if (this.ctx === undefined) {
      this.canvas.nativeElement.getContext('2d');
    }

    this.ctx.clearRect(0, 0, 745, 505);
  }

  drawDuct() {
    this.ctx.fillStyle = 'darkgrey';
    this.clearView();

    let xCenter = this.ctx.canvas.width / 2;
    let yCenter = this.ctx.canvas.height / 2;
    let biggest = this.ctx.canvas.width < this.ctx.canvas.height ? this.ctx.canvas.width : this.ctx.canvas.height;
    let scale = (this.ctx.canvas.width / this._storageParams.lenght) < (this.ctx.canvas.height / this._storageParams.width) ? (this.ctx.canvas.width - 75) / this._storageParams.lenght : ((this.ctx.canvas.height - 75) / this._storageParams.width);

    let fontSize = 24;
    this.ctx.font = `${fontSize}px serif`;
    let x = xCenter - this._storageParams.lenght / 2 * scale;
    let y = yCenter - this._storageParams.width * scale / 2;
    let w =  xCenter + this._storageParams.lenght / 2 * scale - x;
    let h = yCenter + this._storageParams.width * scale / 2 - y;

    this.ctx.beginPath();
    this.ctx.rect(x, y, w, h);
    this.ctx.stroke();

    if (this._ductParams.far.numberFansPerSide !== 0) {
      for (let i = 0; i < this._ductParams.far.numberFansPerSide; i++) {
        let x1 = xCenter - this._storageParams.lenght / 2 * scale;
        let y1 = yCenter - this._storageParams.width * scale / 2;
        x1 += (this._ductParams.far.distanceFromEdge + i * this._ductParams.far.ctroctr - this._ductParams.ductDiameter / 2) / 12 * scale;
        let x2 = x1 + this._ductParams.ductDiameter / 12 * scale;
        let y2 = y1 + this._ductParams.far.solidPerRun / 12 * scale;
        let x3 = x1;
        let y3 = y2 + this._ductParams.far.perfPerRun / 12 * scale;

        this.ctx.beginPath();
        this.ctx.moveTo(x1, y1);
        this.ctx.lineTo(x2, y1);
        this.ctx.lineTo(x2, y2);
        this.ctx.lineTo(x1, y2);
        this.ctx.lineTo(x1, y1);
        this.ctx.stroke();

        this.ctx.beginPath();
        this.ctx.moveTo(x2, y2);
        this.ctx.lineTo(x2, y3);
        this.ctx.lineTo(x3, y3);
        this.ctx.lineTo(x3, y2);
        this.ctx.lineTo(x2, y2);
        this.ctx.fill();
        this.ctx.stroke();
      }
    }

    if (this._ductParams.near.numberFansPerSide !== 0) {
      for (let i = 0; i < this._ductParams.near.numberFansPerSide; i++) {
        let x1 = xCenter - this._storageParams.lenght / 2 * scale;
        let y1 = yCenter + this._storageParams.width * scale / 2;
        x1 += (this._ductParams.near.distanceFromEdge + i * this._ductParams.near.ctroctr - this._ductParams.ductDiameter / 2) / 12 * scale;
        let x2 = x1 + this._ductParams.ductDiameter / 12 * scale;
        let y2 = y1 - this._ductParams.near.solidPerRun / 12 * scale;
        let x3 = x1;
        let y3 = y2 - this._ductParams.near.perfPerRun / 12 * scale;

        this.ctx.beginPath();
        this.ctx.moveTo(x1, y1);
        this.ctx.lineTo(x2, y1);
        this.ctx.lineTo(x2, y2);
        this.ctx.lineTo(x1, y2);
        this.ctx.lineTo(x1, y1);
        this.ctx.stroke();

        this.ctx.beginPath();
        this.ctx.moveTo(x2, y2);
        this.ctx.lineTo(x2, y3);
        this.ctx.lineTo(x3, y3);
        this.ctx.lineTo(x3, y2);
        this.ctx.lineTo(x2, y2);
        this.ctx.fill();
        this.ctx.stroke();
      }
    }

    if (this._ductParams.front.numberFansPerSide !== 0) {
      for (let i = 0; i < this._ductParams.front.numberFansPerSide; i++) {
        let x1 = xCenter - this._storageParams.lenght / 2 * scale;
        let y1 = yCenter - this._storageParams.width / 2 * scale;
        y1 += (this._ductParams.front.distanceFromEdge + i * this._ductParams.front.ctroctr - this._ductParams.ductDiameter /2) / 12 * scale;
        let y2 = y1 + this._ductParams.ductDiameter / 12 * scale;
        let x2 = x1 + this._ductParams.front.solidPerRun / 12 * scale;
        let y3 = y1;
        let x3 = x2 + this._ductParams.front.perfPerRun / 12 * scale;

        this.ctx.beginPath();
        this.ctx.moveTo(x1, y1);
        this.ctx.lineTo(x2, y1);
        this.ctx.lineTo(x2, y2);
        this.ctx.lineTo(x1, y2);
        this.ctx.lineTo(x1, y1);
        this.ctx.stroke();

        this.ctx.beginPath();
        this.ctx.moveTo(x2, y2);
        this.ctx.lineTo(x2, y3);
        this.ctx.lineTo(x3, y3);
        this.ctx.lineTo(x3, y2);
        this.ctx.lineTo(x2, y2);
        this.ctx.fill();
        this.ctx.stroke();
      }
    }

    if (this._ductParams.rear.numberFansPerSide !== 0) {
      for (let i = 0; i < this._ductParams.rear.numberFansPerSide; i++) {
        let x1 = xCenter + this._storageParams.lenght / 2 * scale;
        let y1 = yCenter - this._storageParams.width / 2 * scale;
        y1 += (this._ductParams.rear.distanceFromEdge + i * this._ductParams.rear.ctroctr - this._ductParams.ductDiameter /2) / 12 * scale;
        let y2 = y1 + this._ductParams.ductDiameter / 12 * scale;
        let x2 = x1 - this._ductParams.rear.solidPerRun / 12 * scale;
        let y3 = y1;
        let x3 = x2 - this._ductParams.rear.perfPerRun / 12 * scale;

        this.ctx.beginPath();
        this.ctx.moveTo(x1, y1);
        this.ctx.lineTo(x2, y1);
        this.ctx.lineTo(x2, y2);
        this.ctx.lineTo(x1, y2);
        this.ctx.lineTo(x1, y1);
        this.ctx.stroke();

        this.ctx.beginPath();
        this.ctx.moveTo(x2, y2);
        this.ctx.lineTo(x2, y3);
        this.ctx.lineTo(x3, y3);
        this.ctx.lineTo(x3, y2);
        this.ctx.lineTo(x2, y2);
        this.ctx.fill();
        this.ctx.stroke();
      }
    }

    let bottomLabel = 'Near side';
    let leftLabel = 'Front';
    this.ctx.save();
    this.ctx.fillStyle = 'black';
    this.ctx.fillText(bottomLabel, xCenter - bottomLabel.length / 2 * fontSize / 2, yCenter + this._storageParams.width / 2 * scale + fontSize);
    this.ctx.translate(x - 5, yCenter + leftLabel.length / 2 * fontSize / 2);
    this.ctx.rotate((-90 * Math.PI / 180));
    this.ctx.fillText(leftLabel, 0, 0);
    this.ctx.restore();
  }
}
