import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RoleService } from '@services';
import { CommonFunctions, LocalStorageService, UserRoleEnum } from '../../../../common';
import { BACK_DOOR } from './../../../constants';
import { ProjectEnum, TutorialMenuEnum, TutorialParagraphEnum } from './../../../enums';

@Component({
  selector: 'greensleeves-tutorial-popup',
  templateUrl: './tutorial-popup.component.html',
  styles: []
})
export class TutorialPopupComponent implements OnInit {
  _isHidden = true;
  _paragraph: TutorialParagraphEnum[];
  menuNum: TutorialMenuEnum = TutorialMenuEnum.None;
  private role: UserRoleEnum;
  private projects: ProjectEnum
  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService,
    private _roleService: RoleService,
  ) { }

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    if (this._router.url.indexOf(BACK_DOOR) !== -1) {
      const backDorInfo = this._roleService.getBackDoorUserWithRole(this._router.url);
      this.role = backDorInfo.role
      this.projects = backDorInfo.projects;
    }
    else {
      this.role = info.role;
      this.projects = info.projects;
    }
  }

  showPopup(menuNum = TutorialMenuEnum.None, paragraph: number = 0) {
    this._isHidden = false;
    if (menuNum == TutorialMenuEnum.None) {
      this.menuNum = this.getDefauleSelectMenu();
    }
    else {
      this.menuNum = menuNum;
    }
    this._paragraph =[paragraph];
  }

  private getDefauleSelectMenu(): TutorialMenuEnum {
    let menu: TutorialMenuEnum = TutorialMenuEnum.UserManagement;
    switch (this.role) {
      case UserRoleEnum.CompanyAdmin:
        menu = TutorialMenuEnum.UserManagement;
        break;
      case UserRoleEnum.CompanyUser:
        if (CommonFunctions.containsProject(this.projects, ProjectEnum.Btx)) {
          menu = TutorialMenuEnum.BtxLiveData;
        } else if (CommonFunctions.containsProject(this.projects, ProjectEnum.Ktx)) {
          menu = TutorialMenuEnum.KtxCurrentData;
        } else if (CommonFunctions.containsProject(this.projects, ProjectEnum.Itx)) {
          menu = TutorialMenuEnum.ItxLiveData;
        }
        break;
      case UserRoleEnum.Vendor:
        menu = TutorialMenuEnum.ItxLiveData;
        break;
    }
    return menu;
  }
}
