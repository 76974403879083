import { SensorKtxSchemeViewModel, SensorKtxGetModel, SensorKtxViewModel } from './sensors';

export class CableSchemeViewModel {
    public id: number;
    public connectionId: number;
    public firstThermocoupleId: number;
    public thermocoupleCount: number;
    public length: number;
    public angle: number;
    public radius: number;
    public xPosition: number;
    public yPosition: number;
    public name: number;
    public multiplexerId: number;
    public sensors: SensorKtxSchemeViewModel[];
}

export class CableViewModel {
    public id: number;
    public connectionId: number;
    public firstThermocoupleId: number;
    public thermocoupleCount: number;
    public length: number;
    public angle: number;
    public radius: number;
    public xPosition: number;
    public yPosition: number;
    public name: number;
    public multiplexerId: number;
    public sensors: SensorKtxViewModel[];

    public static toSchemeViewModel(viewModel: CableViewModel): CableSchemeViewModel {
        const schemeViewModel = new CableSchemeViewModel();

        schemeViewModel.id = viewModel.id;
        schemeViewModel.connectionId = viewModel.connectionId;
        schemeViewModel.firstThermocoupleId = viewModel.firstThermocoupleId;
        schemeViewModel.thermocoupleCount = viewModel.thermocoupleCount;
        schemeViewModel.length = viewModel.length;
        schemeViewModel.angle = viewModel.angle;
        schemeViewModel.radius = viewModel.radius;
        schemeViewModel.xPosition = viewModel.xPosition;
        schemeViewModel.yPosition = viewModel.yPosition;
        schemeViewModel.name = viewModel.name;
        schemeViewModel.multiplexerId = viewModel.multiplexerId;

        if (viewModel.sensors) {
            schemeViewModel.sensors = viewModel.sensors.map(s => SensorKtxViewModel.toSchemeViewModel(s));
        }

        return schemeViewModel;
    }
}

export class CableGetModel {
    public id: number;
    public connectionId: number;
    public firstThermocoupleId: number;
    public thermocoupleCount: number;
    public length: number;
    public angle: number;
    public radius: number;
    public xPosition: number;
    public yPosition: number;
    public name: number;
    public multiplexerId: number
    public sensors: SensorKtxGetModel[];

    public static toViewModel(getModel: CableGetModel) {
        const viewModel = new CableViewModel();

        viewModel.id = getModel.id;
        viewModel.connectionId = getModel.connectionId;
        viewModel.firstThermocoupleId = getModel.firstThermocoupleId;
        viewModel.thermocoupleCount = getModel.thermocoupleCount;
        viewModel.length = getModel.length;
        viewModel.angle = getModel.angle;
        viewModel.radius = getModel.radius;
        viewModel.xPosition = getModel.xPosition;
        viewModel.yPosition = getModel.yPosition;
        viewModel.name = getModel.name;
        viewModel.multiplexerId = getModel.multiplexerId;
        
        if (getModel.sensors) {
            viewModel.sensors = getModel.sensors.map(s => SensorKtxGetModel.toViewModel(s));
        }

        return viewModel;
    }
}

export class AddEditCableSchemePostModel {
    public connectionId: number;
    public firstThermocoupleId: number;
    public thermocoupleCount: number;
    public length: number;
    public angle: number;
    public radius: number;
    public xPosition: number;
    public yPosition: number;
    public name: number;
    public multiplexerId: number;

    constructor(viewModel: CableViewModel) {
        this.connectionId = viewModel.connectionId;
        this.firstThermocoupleId = viewModel.firstThermocoupleId;
        this.thermocoupleCount = viewModel.thermocoupleCount;
        this.length = viewModel.length;
        this.angle = viewModel.angle;
        this.radius = viewModel.radius;
        this.xPosition = viewModel.xPosition;
        this.yPosition = viewModel.yPosition;
        this.name = viewModel.name;
        this.multiplexerId = viewModel.multiplexerId;
    }
}