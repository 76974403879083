import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PasswordUpdateComponent } from './password-update.component';
import { RoutePaths } from '../common';

@NgModule({
  declarations: [
    PasswordUpdateComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      { path: RoutePaths.PASSWORD_UPDATE, component: PasswordUpdateComponent },
    ])
  ]
})

export class PasswordUpdateModule { }
