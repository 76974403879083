import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiRoutes, RoutePaths } from '../../constants';
import { LocalStorageService } from '../common';
import { HttpService } from './http.service';
import { LoginPostModel, LoginGetModel, LoginViewModel, ErrorModel, RefreshGetModel, RefreshPostModel } from '../../models';
import { ErrorCodeEnum, UserRoleEnum } from '../../enums';
import { CommonFunctions } from '../../functions';

@Injectable()
export class AuthorizeService {
  public redirectUrl: string = null;

  constructor(
    private _httpService: HttpService,
    private _localStorageService: LocalStorageService,
    private _router: Router) {
  }

  public async login(userEmail: string, password: string): Promise<LoginViewModel | ErrorModel> {
    try {
      const requestModel = new LoginPostModel(userEmail, password);
      const result = await this._httpService.post(ApiRoutes.Auth.login, requestModel);
      const loginViewModel = LoginGetModel.toViewModel(result);

      this._localStorageService.setLoginInfo(loginViewModel);
      this.navigateByUserRoleOrRedirectUrl(loginViewModel);

      return loginViewModel;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }

      console.log(error);
      return null;
    }
  }

  public checkIfAuthorizedAndNavigate(): boolean {
    const loginViewModel = this._localStorageService.getLoginInfo();

    if (loginViewModel && CommonFunctions.checkIfTimeNotExpired(loginViewModel.expiresAt)) {
      this.navigateByUserRoleOrRedirectUrl(loginViewModel);
      return true;
    }
  }

  public async logout(): Promise<any> {
    try {
      await this._httpService.post(ApiRoutes.Auth.logout, {});
      this._localStorageService.clear();
      this._router.navigate([RoutePaths.SIGN_IN]);
    } catch (error) {
      if (error instanceof ErrorModel) {
        return Promise.reject(error);
      }

      console.log(error);
      return null;
    }
  }

  public getToken(): string {
    return this._localStorageService.getAuthToken();
  }

  public async refreshTokens() {
    try {
      const loginViewModel = this._localStorageService.getLoginInfo();
      const requestModel = new RefreshPostModel(loginViewModel.authToken, loginViewModel.refreshToken);
      const result = await this._httpService.post(ApiRoutes.Auth.refreshToken, requestModel);
      const refreshViewModel = RefreshGetModel.toViewModel(result);

      loginViewModel.authToken = refreshViewModel.authToken;
      loginViewModel.refreshToken = refreshViewModel.refreshToken;
      loginViewModel.expiresAt = refreshViewModel.expiresAt;

      this._localStorageService.setLoginInfo(loginViewModel);

      return refreshViewModel;
    } catch (error) {
      if (error instanceof ErrorModel) {
        if (error.code == ErrorCodeEnum.InvalidToken) {
          this._localStorageService.clear();
          this._router.navigate([RoutePaths.SIGN_IN]);
        }
        return error;
      }

      console.log(error);
      return null;
    }
  }

  private navigateByUserRoleOrRedirectUrl(loginViewModel: LoginViewModel) {
    if (this.redirectUrl) {
      this._router.navigateByUrl(this.redirectUrl);
    } else {
      switch (loginViewModel.role) {
        case (UserRoleEnum.CompanyAdmin):
          this._router.navigate([RoutePaths.MAIN_ADMIN]);
          break;
        case (UserRoleEnum.ServiceUser):
          this._router.navigate([RoutePaths.MAIN_USER]);
          break;
        case (UserRoleEnum.CompanyUser):
          this._router.navigate([RoutePaths.COMPANY_USER]);
          break;
        case (UserRoleEnum.Vendor):
          this._router.navigate([RoutePaths.VENDOR]);
          break;
          case (UserRoleEnum.SalesUser):
            this._router.navigate([RoutePaths.SALES_USER]);
            break;
      }
    }
    this.redirectUrl = null;
  }
}
