import { Component, EventEmitter, OnInit } from '@angular/core';

import { TemperatureRangeViewModel } from '../../../../models';
import { BasePopupBehavior } from '../../common';

@Component({
  selector: 'greensleeves-ktx-range-delete-popup',
  templateUrl: './ktx-range-delete-popup.component.html'
})
export class KtxRangeDeletePopupComponent extends BasePopupBehavior implements OnInit {
  _isHidden = true;
  range: TemperatureRangeViewModel;
  onSubmit$ = new EventEmitter<TemperatureRangeViewModel>();

  constructor() { 
    super();
  }
  ngOnInit() {
  }

  onCancel() {
    this._isHidden = true;
    this.range = null;
  }

  onSubmit() {
    this.onSubmit$.emit(this.range);
    this._isHidden = true;
  }

  public show(range: TemperatureRangeViewModel) {
    this._isHidden = false;
    this.range = range;
  }
}
