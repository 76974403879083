export class KtxReportHistoricalDataFilter {
    public locationId: number;
    public binId: number;
    public timestampFrom: number;
    public timestampTo: number;
    public temperatureMeasureType: number;
    public timeZone: string;
}

export class KtxReportFormat {
    label: string;
    value: number;
}

export class KtxReportCurrentDataFilter {
    public locationId: number;
    public binIds: number[];
    public temperatureMeasureType: number;
    public timeZone: string;
}