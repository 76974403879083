import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { BasePopupBehavior } from '../common';
import { ColumnTypeEnum } from './../../../enums';
import { ProjectEnum } from './../../../enums/project.enum';
import { DeviceFileLogInfoViewModel } from './../../../models';
import { DeviceLogDatasourceService, LocationsBtxDataSourceService, LocationsItxDatasourceService, LocationsKtxDataSourceService, UpdaterDeviceDatasourceService } from './../../../services';

@Component({
  selector: 'greensleeves-device-communication-popup',
  templateUrl: './device-communication-popup.component.html',
  styleUrls: []
})
export class DeviceCommunicationPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy {
  static FILE_NAME_WITHOUT_DEVICE_ID = 'fileNameWithoutDeviceId';
  static CREATING_DATE_KEY = 'createdDate';
  static SORT_ORDER_DEFAULT = -1;

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = DeviceCommunicationPopupComponent.CREATING_DATE_KEY;
  _sortOrder = DeviceCommunicationPopupComponent.SORT_ORDER_DEFAULT;
  _isHidden = true;
  _locationName: string = '';
  _gridData: DeviceFileLogInfoViewModel[];
  _selectedFiles: DeviceFileLogInfoViewModel[] = [];
  _loading = true;
  _isDownloadArchive = false;
  _isDownloadFile = 'isDownload';
  _isDeiveceTwinHasLogLink: boolean = false;
  _addLinkToTwin: boolean = false;
  _availableProjects: string = null;

  private deviceInfo: { locationName: string, locationId: number, project: ProjectEnum, deviceId: string };
  private subscrible: Subscription;

  _columnsView = [
    { header: 'Selected', columnType: ColumnTypeEnum.QuoteSelected, dataField: 'isSelected', width: 100 },
    { header: 'File', columnType: ColumnTypeEnum.FileName, dataField: DeviceCommunicationPopupComponent.FILE_NAME_WITHOUT_DEVICE_ID, width: 200 },
    { header: 'Last modified', columnType: ColumnTypeEnum.Date, dataField: 'lastModifiedDate', width: 150 },
    { header: 'Creation date', columnType: ColumnTypeEnum.Date, dataField: DeviceCommunicationPopupComponent.CREATING_DATE_KEY, width: 150 },
    { header: 'Size', columnType: ColumnTypeEnum.Details, dataField: 'size', width: 100 },
    { header: '', columnType: ColumnTypeEnum.DownloadConnectionFile, width: 150 },
  ];

  constructor(
    private _locationsItxDataSourceService: LocationsItxDatasourceService,
    private _locationsKtxDataSourceService: LocationsKtxDataSourceService,
    private _locationsBtxDataSourceService: LocationsBtxDataSourceService,
    private _updaterDataSourceSerice: UpdaterDeviceDatasourceService,
    private _deviceLogDatasource: DeviceLogDatasourceService,
  ) {
    super();
  }

  ngOnInit() {
    this.subscrible = this._deviceLogDatasource.deviceLogInfo$.subscribe(item => {
      this._gridData = item.deviceFileLogInfo;
      this._isDeiveceTwinHasLogLink = item.isTwinHasLogLink;
      this._loading = false;
    })
  }

  ngOnDestroy() {
    this.subscrible.unsubscribe();
  }

  onShow(deviceInfo: { locationName: string, locationId: number, project: ProjectEnum, deviceId: string }) {
    this.deviceInfo = deviceInfo;
    this._deviceLogDatasource.getLogsInfoList(deviceInfo.deviceId);
    this._locationName = this.deviceInfo.locationName;
    this._selectedFiles = [];
    this._isHidden = false;
    this._loading = true;
  }

  onShowUpdaterDevice(deviceInfo: { locationName: string, locationId: number, project: ProjectEnum, deviceId: string }, availableProjects: ProjectEnum){
    this.onShow(deviceInfo);
    this._availableProjects = this.flagToString(availableProjects);
    console.log(this._availableProjects);
  }

  onCancel() {
    this._isHidden = true;
    this.deviceInfo = null;
    this._selectedFiles = [];
    this._gridData = [];
    this._locationName = '';
    this._loading = false;
    this._availableProjects = null;
  }

  async onClickDownloadConfigFile() {
    let result;
    switch (this.deviceInfo.project) {
      case ProjectEnum.Itx:
        result = await this._locationsItxDataSourceService.downloadConnectionFile(this.deviceInfo.locationId);
        break;
      case ProjectEnum.Ktx:
        result = await this._locationsKtxDataSourceService.downloadConnectionFile(this.deviceInfo.locationId);
        break;
      case ProjectEnum.Btx:
        result = await this._locationsBtxDataSourceService.downloadConnectionFile(this.deviceInfo.locationId);
        break;
      case ProjectEnum.Updater:
        result = await this._updaterDataSourceSerice.downloadConnectionFile(this.deviceInfo.deviceId);
        break;
      default:
        return;
    }

    if (result instanceof Blob) {
      const url = URL.createObjectURL(result as Blob);
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'configuration.zip';
      downloadLink.click();
    }
  }

  async onDownloadFileLog(fileInfo: DeviceFileLogInfoViewModel) {
    fileInfo[this._isDownloadFile] = true;
    let result = await this._deviceLogDatasource.downloadLogFile(this.deviceInfo.deviceId, fileInfo.fileName);
    if (result instanceof Blob) {
      const url = URL.createObjectURL(result as Blob);
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileInfo.fileName;
      downloadLink.click();
    }
    fileInfo[this._isDownloadFile] = false;
  }

  onSelectLog(file: DeviceFileLogInfoViewModel) {
    if (this._selectedFiles.includes(file)) {
      this._selectedFiles = this._selectedFiles.filter(x => x != file);
    }
    else {
      this._selectedFiles.push(file);
    }
  }

  async onDownloadSelectedFileLogs() {
    if (this._selectedFiles.length > 0) {
      this._isDownloadArchive = true;
      this._selectedFiles = this._selectedFiles.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
      let fileList = this._selectedFiles.map(x => x.fileName);

      let result = await this._deviceLogDatasource.downloadLogFiles(this.deviceInfo.deviceId, fileList);
      if (result instanceof Blob) {
        let fileName: string = `${this.deviceInfo.deviceId}_${new Date(this._selectedFiles[0].createdDate).getDate()}-${new Date(this._selectedFiles[0].createdDate).getMonth() + 1}-${new Date(this._selectedFiles[0].createdDate).getFullYear()}`;
        if (this._selectedFiles.length > 1) {
          let lastIndex = this._selectedFiles.length - 1;
          fileName = fileName + `_${new Date(this._selectedFiles[lastIndex].createdDate).getDate()}-${new Date(this._selectedFiles[lastIndex].createdDate).getMonth() + 1}-${new Date(this._selectedFiles[lastIndex].createdDate).getFullYear()}`
        }
        fileName = fileName + '.zip';
        const url = URL.createObjectURL(result as Blob);
        let downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
        this.onClearSelected();
      }
      this._isDownloadArchive = false;
    }
  }

  onClearSelected() {
    this._gridData.forEach(item => {
      item['isSelected'] = false;
    });
    this._selectedFiles = [];
  }

  async onClickAddLink() {
    this._addLinkToTwin = true;
    let result = await this._deviceLogDatasource.addLogLinkToDeviceTwin(this.deviceInfo.deviceId);
    this._isDeiveceTwinHasLogLink = result;
    this._addLinkToTwin = false;
  }

  private flagToString(value: ProjectEnum): string {
    const flagNames = Object.keys(ProjectEnum)
      .filter((key) => isNaN(Number(key)))
      .filter((key) => ProjectEnum[key as keyof typeof ProjectEnum] !== ProjectEnum.None)
      .filter((key) => (value & ProjectEnum[key as keyof typeof ProjectEnum]) === ProjectEnum[key as keyof typeof ProjectEnum])
      .join(', ');
  
    return flagNames || 'None';
  }
}
