import { VendorInUpdateHubMessage, VendorDeletedHubMessage } from './../../models/';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HubService, VendorApiService } from '../http';
import { VendorAddedHubMessage, VendorUpdatedHubMessage, VendorViewModel, VendorInviteModel, VendorEditModel } from '../../models';
import { ErrorModel } from '../../../common';
import { HubMethods } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class VendorDataSourceService {

  public companyVendors$: BehaviorSubject<VendorViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(
    private _hubService: HubService,
    private _vendorApi: VendorApiService
  ) {
    this.subscribeToHubService();
  }

  public async get(companyId: number) {
    try {
      const data = await this._vendorApi.getAllForCompany(companyId);
      if (data) {
        this.companyVendors$.next(data as VendorViewModel[]);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async invite(vendor: VendorInviteModel): Promise<VendorViewModel | ErrorModel> {
    try {
      const result = await this._vendorApi.invite(vendor) as VendorViewModel;
      if (result) {
        this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.Vendor.vendorAdded, new VendorAddedHubMessage(result));
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }

  public async editForCompany(vendor: VendorEditModel): Promise<boolean | ErrorModel> {
    try {
      const result = await this._vendorApi.editForCompany(vendor) as VendorViewModel;
      if (result) {
        this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.Vendor.vendorUpdated, new VendorUpdatedHubMessage(result, true));
        const companyUsers = this.companyVendors$.getValue().map(l => l.id === result.id ? { ...l, ...result } : l);
        this.companyVendors$.next(companyUsers);
        return Promise.resolve(true);
      }
      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async deleteVendor(vendorId: number, companyId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._vendorApi.deleteVendor(vendorId, companyId);
      if (result) {
        this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.Vendor.vendorDeleted, new VendorDeletedHubMessage(vendorId));
        const vendors = this.companyVendors$.getValue().filter(l => l.id !== result);
        this.companyVendors$.next(vendors);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async markCompanyUserForOthersAsInUpdate(vendorId: number): Promise<any> {
    this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.Vendor.vendorInUpdate, new VendorInUpdateHubMessage(vendorId));
  }

  public async markCompanyUserForOthersAsUpdated(vendor: VendorViewModel, isWithChanges: boolean): Promise<any> {
    this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.Vendor.vendorUpdated, new VendorUpdatedHubMessage(vendor, isWithChanges));
  }

  private subscribeToHubService() {
    this._hubService.vendorDeleted.subscribe((hubMessage: VendorDeletedHubMessage) => {
      if (hubMessage) {
        const users = this.companyVendors$.getValue().filter(l => l.id !== hubMessage.vendorId);
        this.companyVendors$.next(users);
      }
    });
    this._hubService.vendorAdded.subscribe((hubMessage: VendorAddedHubMessage) => {
      if (hubMessage && hubMessage.newVendor) {
        this.companyVendors$.next([
          ...this.companyVendors$.getValue(),
          hubMessage.newVendor
        ]);
      }
    });
    this._hubService.vendorInUpdate.subscribe((hubMessage: VendorInUpdateHubMessage) => {
      if (hubMessage) {
        const vendor = this.companyVendors$.getValue().find(l => l.id === hubMessage.vendorId);
        if (vendor) {
          vendor.isSomeoneUpdateVendor = true;
        }
      }
    });
    this._hubService.vendorUpdated.subscribe((hubMessage: VendorUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedVendor) {
        if (hubMessage.withChanges) {
          hubMessage.updatedVendor.isSomeoneUpdateVendor = undefined;
          this.companyVendors$.next(this.companyVendors$.getValue().map(l => l.id === hubMessage.updatedVendor.id ? hubMessage.updatedVendor : l));
        } else {
          const vendor = this.companyVendors$.getValue().find(c => c.id == hubMessage.updatedVendor.id);
          vendor.isSomeoneUpdateVendor = false;
        }
      }
    });
  }
}
