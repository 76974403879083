import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { BasePopupBehavior } from '../common';
import { ConfirmationDialogEnum } from './../../../enums';

@Component({
  selector: 'greensleeves-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
})
export class ConfirmationPopupComponent extends BasePopupBehavior implements OnInit {
  @Output() onConfirmationClick: EventEmitter<ConfirmationDialogEnum> = new EventEmitter()
  _mainDialogTitle: string = '';
  _secondDialogTitle: string = '';
  _confirmButtonText: string = 'Delete';
  _cancelButtonText: string = 'Cancel';
  _isHidden = true;

  constructor() { super(); }

  ngOnInit() {
  }

  show(mainDialogTitle, secondTitle, confirmButtonText: string = 'Delete', cancelButtonText: string = 'Cancel') {
    this._isHidden = false;
    this._mainDialogTitle = mainDialogTitle;
    this._secondDialogTitle = secondTitle;
    this._confirmButtonText = confirmButtonText;
    this._cancelButtonText = cancelButtonText;
  }

  onCancel() {
    this.closeDialog();
    this.onConfirmationClick.emit(ConfirmationDialogEnum.Cancel);
  }

  onConfirm() {
    this.closeDialog();
    this.onConfirmationClick.emit(ConfirmationDialogEnum.Confirmation);
  }

  private closeDialog() {
    this._isHidden = true;
    this._mainDialogTitle = '';
    this._secondDialogTitle = '';
  }
}
