import { Component, HostListener } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
@Component({
  selector: 'greensleeves-privacy',
  templateUrl: './privacy.component.html'
})

export class PrivacyComponent {
  private static readonly classForScroll = '.privacy-content';
  private perfectScrollbarMain: PerfectScrollbar;
  

  ngOnInit() {
    const container = document.querySelector(PrivacyComponent.classForScroll) as HTMLElement;
    this.reinitMainScrollBar(container);
  }
  ngAFterViewInit() {
    const container = document.querySelector(PrivacyComponent.classForScroll) as HTMLElement;
    this.reinitMainScrollBar(container);
  }

  @HostListener('window: resize', ['$event.target'])
  onResize() {
    const container = document.querySelector(PrivacyComponent.classForScroll) as HTMLElement;
    this.reinitMainScrollBar(container);
  }

  private reinitMainScrollBar(element: HTMLElement, timeout?: number) {
    this.perfectScrollbarMain && this.perfectScrollbarMain.destroy();

    const createScrollbarFunc = () => {
      this.perfectScrollbarMain = new PerfectScrollbar(element, {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20
      });
    };
    
    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }
}
