import { Component, OnInit } from '@angular/core';
import {  RoutePaths } from '../common';
import { HubService } from './services';

@Component({
  selector: 'greensleeves-page-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
  _privacyLink = RoutePaths.PRIVACY;
  _isOpenNavigationBar: boolean = false;

  constructor(
    private _hubService: HubService,
  ) { }

  ngOnInit() {
    this._hubService.connect();
  }

  ngOnDestroy() {
    this._hubService.disconect();
  }

  onToggleNavigationBar(isOpen): void {
    this._isOpenNavigationBar = isOpen;
  }
}
