import { Component, OnInit } from '@angular/core';
import { UpdaterDeviceDatasourceService } from '@services';
import { BasePopupBehavior } from '../common';
import { ColumnTypeEnum } from 'src/app/main/enums';
import { VersionHistoryResponseModel } from '@models';

@Component({
  selector: 'greensleeves-update-versions-management-popup',
  templateUrl: './update-versions-management-popup.component.html',
  styleUrls: []
})
export class UpdateVersionsManagementPopupComponent extends BasePopupBehavior implements OnInit{
  _isHidden = true;
  _loading = true;
  _deviceId: string;
  _locationId: number;
  _locationName: string;
  _projectName: string;

  _columnTypeEnum = ColumnTypeEnum;
  _gridData: VersionHistoryResponseModel[];

  _columnsView = [
    { header: 'Update date', columnType: ColumnTypeEnum.Date, dataField: 'realUpdateTime', width: 200 },
    { header: 'Current version', columnType: ColumnTypeEnum.FileName, dataField: 'currentVersion', width: 150 },
    { header: 'Target version', columnType: ColumnTypeEnum.FileName, dataField: 'targetVersion', width: 150 },
    { header: 'Status', columnType: ColumnTypeEnum.Status, dataField: 'status', width: 120 },
    { header: 'Message', columnType: ColumnTypeEnum.Data, dataField: 'lastErrorMessage', width: 420 },
  ];

  constructor(
    private _updaterDeviceDatasourceService: UpdaterDeviceDatasourceService,
  ) {
    super();
  }

  ngOnInit() {
  }

  async onShow(deviceId: string, locationId: number, locationName: string, projectName: string){
    this._loading = true;
    this._isHidden = false;

    this._locationName = locationName;
    this._projectName = projectName;

    this._deviceId = deviceId;
    this._locationId = locationId;
    this._gridData = await this._updaterDeviceDatasourceService.getUpdateHistoryVersions(this._deviceId, this._locationId.toString()) as VersionHistoryResponseModel[];
    this._loading = false;
  }

  onCancel(){
    this._isHidden = true;
    this._loading = false;
    this._gridData = [];
  }
}
