import { ItxStorageSortingEnum } from './../enums/itx-bin.enum';
import { ItxReportsReadingUnitEnum, ReportFormatEnum } from "../enums";

export class ItxTelemetryDataResponse {
    public reportDataTelemetry: ItxTelemetry[];
    public radarId:number;
}

export class ItxTelemetry {
    public timestamp: number;
    public value: number;
}

export class ReportDataFilterPost {
    public dateFrom: number;
    public dateTo: number;
    public locationId: number;
    public itxBinId: number;
    public radarIds: number[];
    public reportsReadingUnit: ItxReportsReadingUnitEnum;
    public reportsRadarReadingUnit: ItxReportsReadingUnitEnum;
    public unit: number;
}

export class ReportHistoricalDataResponse {
    reportData: ItxTelemetryDataResponse[];
    isAverageValues: boolean;
}

export class ReportDataFileFilterPost extends ReportDataFilterPost {
    public reportFormat: ReportFormatEnum;
    public timeZone: string;
}

export class ReportLiveDataFilterPost {
    public itxBinIds: number[];
    public itxBinSortParameter: ItxStorageSortingEnum;
    public reportFormat: ReportFormatEnum;
    public timeZone: string;
}
