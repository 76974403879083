import { ItxReportsReadingUnitEnum, ItxBinTypeEnum, ReportFormatEnum } from "../enums"

export class StorageFakeDataModel {
  id: number;
  name: string;
  totalCapacity: number;
  material: string;
  headSpace: number;
  temperature: number;
  binType: string;
  storageFull: number;
  rangeColor: string;
}

export const StorageBinTypesClasses = {
  [ItxBinTypeEnum.BinFlat]: "bin-flat",
  [ItxBinTypeEnum.BinConical]: "bin-conical",
  [ItxBinTypeEnum.BinSloped]: "bin-sloped",

  [ItxBinTypeEnum.SiloFlat]: "silo-flat",
  [ItxBinTypeEnum.SiloConical]: "silo-conical",
  [ItxBinTypeEnum.SiloSloped]: "silo-sloped",

  [ItxBinTypeEnum.FlatStorageLevel]: "flat-storage",
  [ItxBinTypeEnum.FlatStorageConical]: "flat-conical",
  [ItxBinTypeEnum.FlatStorageSloped]: "flat-sloped",

  [ItxBinTypeEnum.LiquidStorageFlat]: "liquid-flat",
  [ItxBinTypeEnum.LiquidStorageConical]: "liquid-conical",
  [ItxBinTypeEnum.LiquidStorageSloped]: "liquid-sloped",
}

export const ItxReportReadingUnitLabel = {
  [ItxReportsReadingUnitEnum.AvailableCapacity]: "Available Capacity",
  [ItxReportsReadingUnitEnum.UsedCapacity]: "Used Capacity",
  [ItxReportsReadingUnitEnum.HeadSpace]: "Head Space",
  [ItxReportsReadingUnitEnum.PercentFull]: "Percent Full",
  [ItxReportsReadingUnitEnum.Temperature]: "Temperature of the Electronics",
  [ItxReportsReadingUnitEnum.FillHeight]: "Fill Height of Material in Storage",
  [ItxReportsReadingUnitEnum.CalcUsedCapacity]: "Calc'd Used Cap",
  [ItxReportsReadingUnitEnum.CalcAvailableCapacity]: "Calc'd Available Cap",
}

export const ItxReportRadarReadingUnitLabel = {
  [ItxReportsReadingUnitEnum.UsedCapacity]: "Used Capacity",
  [ItxReportsReadingUnitEnum.HeadSpace]: "Head Space",
  [ItxReportsReadingUnitEnum.PercentFull]: "Percent Full",
  [ItxReportsReadingUnitEnum.Temperature]: "Temperature of the Electronics",
}

export const StorageFakeData: StorageFakeDataModel[] = [
  {
    id: 1,
    name: "Storage 1",
    totalCapacity: 80.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "binFlat",
    storageFull: 40,
    rangeColor: "#71c5a3",
  },
  {
    id: 2,
    name: "Storage 2",
    totalCapacity: 120.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "binConical",
    storageFull: 80,
    rangeColor: "#f4bd56",
  },
  {
    id: 3,
    name: "Storage 3",
    totalCapacity: 120.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "binSloped",
    storageFull: 65,
    rangeColor: "#8bc9ff",
  },
  {
    id: 4,
    name: "Storage 4",
    totalCapacity: 10.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "siloFlat",
    storageFull: 20,
    rangeColor: "#6cc3a0",
  },
  {
    id: 5,
    name: "Storage 5",
    totalCapacity: 10.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "siloConical",
    storageFull: 32,
    rangeColor: "#6cc3a0",
  },
  {
    id: 6,
    name: "Storage 6",
    totalCapacity: 10.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "siloSloped",
    storageFull: 75,
    rangeColor: "#f4bd56",
  },
  {
    id: 7,
    name: "Storage 7",
    totalCapacity: 100.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "flatStorageLevel",
    storageFull: 50,
    rangeColor: "#8bc9ff",
  },
  {
    id: 8,
    name: "Storage 8",
    totalCapacity: 100.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "flatStorageConical",
    storageFull: 82,
    rangeColor: "#8f65c7",
  },
  {
    id: 9,
    name: "Storage 9",
    totalCapacity: 100.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "flatStorageSloped",
    storageFull: 91,
    rangeColor: "#d95656",
  },
  {
    id: 10,
    name: "Storage 10",
    totalCapacity: 100.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "liquidStorageFlat",
    storageFull: 11,
    rangeColor: "#6cc3a0",
  },
  {
    id: 11,
    name: "Storage 11",
    totalCapacity: 50.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "liquidStorageConical",
    storageFull: 72,
    rangeColor: "#f4bd56",
  },
  {
    id: 12,
    name: "Storage 12",
    totalCapacity: 50.000,
    material: "Corn",
    headSpace: 21,
    temperature: 98,
    binType: "liquidStorageSloped",
    storageFull: 98,
    rangeColor: "#d95656",
  }
]
export const ReportFileFormatLabel = {
  [ReportFormatEnum.csv]: "csv",
  [ReportFormatEnum.pdf]: "pdf",
  [ReportFormatEnum.png]: "png"
}
