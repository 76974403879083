import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CanvasFlushFloorSupportFunction } from '../aqs-canvas-flushfloor-support-functions';
import { ConvertDataFunctions } from './../../../../../common';
import { AqsFlushFloorCalculatedValues, AqsFlushFloorViewModel, AqsRoundStorageWiewModel } from './../../../../models';

@Component({
  selector: 'greensleeves-aqs-round-flush-design-double-two-runs',
  templateUrl: './aqs-round-flush-design-double-two-runs.component.html',
  styles: []
})
export class AqsRoundFlushDesignDoubleTwoRunsComponent implements OnInit {
  @Input() storageConfiguration: AqsRoundStorageWiewModel;
  @Input() flushFloorConfiguration: AqsFlushFloorViewModel;
  @Input() calcValues: AqsFlushFloorCalculatedValues;

  @Input() flushFloorTunnelFillColor: string;
  @Input() flushFloorTunnelStrokeColor: string;

  @Output() currentPageErrorChange = new EventEmitter<boolean>();
  @Output() onChangeFlushFloorModel = new EventEmitter();

  @ViewChild('flushFloorCanvas', { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;

  _a: string;
  _b: number;
  _c: number;
  _d: number;
  _e: string;

  private ee: number;

  _errorBToLarge: boolean = false;
  _errorACBeyondBinDia: boolean = false;
  _errorALessOrEqualcC: boolean = false;
  _errorVelocities: boolean = false;
  private pageError: boolean = false;

  _supportHeightOptions = CanvasFlushFloorSupportFunction.supportHeightOptions;
  _tunnelSupportOptions: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport: { totalTunnelSupport: number, span: number };

  _velocityTable: { value: string, crossSectional: number, surfaceVelocity: number, error: boolean }[];

  _columnsView = CanvasFlushFloorSupportFunction.velocitiesFor2FanTableColumns;

  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;
  private scale: number;

  constructor() { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;
    if (this.flushFloorConfiguration.aa == undefined || this.flushFloorConfiguration.aa == null) {
      this.calculatedRecommendationParameters();
    }

    this.fillInputValues();
    this.reDrawSchema();
  }

  onChangeValueA() {
    if (this._a != '') {
      let splitStrings = this._a.split('-');
      let val = parseFloat(splitStrings[0]);
      if (!isNaN(val) && (val >= 2 && val < 1000)) {
        this.flushFloorConfiguration.aa = val * 12;
        if (splitStrings.length > 1) {
          let index = this._a.indexOf('-');
          val = parseFloat(this._a.slice(++index));
          if (!isNaN(val)) {
            this.flushFloorConfiguration.aa += val;
          }
        }
        this.calcE();
        this.checkError();
        this.reDrawSchema();
        this.calcTunnelSupport();
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._a = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.aa);;
  }

  onChangeValueB() {
    if (this.checkValue(this._b)) {
      this.flushFloorConfiguration.bb = this._b * 12;
      this.calculateVelocities();
      this.checkError();
      this.calcE();
      this.reDrawSchema();
      this.calcTunnelSupport();
      this.onChangeFlushFloorModel.emit();
    }
    else this._b = Math.round(this.flushFloorConfiguration.bb / 12);
  }

  onChangeValueC() {
    if (this._c != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._c, this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.cc) {
        this.flushFloorConfiguration.cc = calcValue
        this.calculateVelocities();
        this.checkError();
        this.reDrawSchema();
        this.calcTunnelSupport();
        this.onChangeFlushFloorModel.emit();
      }
    }

    this._c = this.flushFloorConfiguration.cc;
  }

  onChangeValueD() {
    if (this._d >= 0 && this._d <= 100 && this._d != null) {
      this.flushFloorConfiguration.ee = this._d;
      this.flushFloorConfiguration.dd = (this.calcValues.rad * this._d) / 100;
      this.reDrawSchema();
      this.onChangeFlushFloorModel.emit();
    }
    else this._d = this.flushFloorConfiguration.ee;
  }

  onChangeSupportHeight() {
    this.calculateVelocities();
    this.onChangeFlushFloorModel.emit();
  }

  onChangeTunnelSupport() {
    this.flushFloorConfiguration.totalTunnelSupport1 = this._tunnelSupport.totalTunnelSupport;
    this.flushFloorConfiguration.totalTunnelSupportSpan1 = this._tunnelSupport.span;
    this.onChangeFlushFloorModel.emit();
  }

  numberOnly(event) {
    if (!_isNumberValue(event.key) && event.key !== '.' && event.key !== '-' && event.key !== 'Enter') {
      event.preventDefault();
    }
  }

  private checkError() {
    this._errorBToLarge = this.flushFloorConfiguration.bb > this.calcValues.rad * 2;
    this._errorACBeyondBinDia = Math.pow(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.cc / 2, 2) > Math.pow(this.calcValues.rad, 2);
    this._errorALessOrEqualcC = this.flushFloorConfiguration.aa <= this.flushFloorConfiguration.cc;
    this.pageError = this._errorBToLarge || this._errorACBeyondBinDia || this._errorALessOrEqualcC || this._errorVelocities;
    this.currentPageErrorChange.emit(this.pageError);
  }

  private checkValue(value: number): boolean {
    return value >= 2 && value % 2 == 0;
  }

  private calculatedRecommendationParameters() {
    [this.flushFloorConfiguration.supportHeight1, this.flushFloorConfiguration.cc] = this.calcValues.calcMainTunnelWidth(this.calcValues.maxTunnelWidth);
    this.flushFloorConfiguration.ee = 75;//instead of DDD
    this.flushFloorConfiguration.dd = (this.flushFloorConfiguration.ee * this.calcValues.rad) / 100;
    this.flushFloorConfiguration.aa = Math.floor((this.calcValues.rad * 2) / 3);
    this.flushFloorConfiguration.bb = Math.round((Math.sqrt(Math.pow(this.flushFloorConfiguration.dd, 2) - Math.pow(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.cc / 2, 2)) * 2) / 24) * 24;
    //BB=CINT( (SQR(DD^2-(AA/2+CC/2)^2)*2)/24 )*24: IF BB<24 THEN BB=24
    if (this.flushFloorConfiguration.bb < 24) this.flushFloorConfiguration.bb = 24;

  }

  private fillInputValues() {
    this._a = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.aa);
    this._b = Math.round(this.flushFloorConfiguration.bb / 12);
    this._c = this.flushFloorConfiguration.cc;
    this._d = this.flushFloorConfiguration.ee;
    this.calcE();
    this.calculateVelocities();
    this.calcTunnelSupport(true);
    if (!this.flushFloorConfiguration.totalTunnelSupport1) {
      this.onChangeTunnelSupport();
    } else {
      this._tunnelSupport.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport1;
      this._tunnelSupport.span = this.flushFloorConfiguration.totalTunnelSupportSpan1;
    }
  }

  private calcE() {
    this.ee = CanvasFlushFloorSupportFunction.aqsCalcDimensionBeforeTunnel(this.calcValues.rad, this.flushFloorConfiguration.bb, this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.cc / 2);
    this._e = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.ee);
  }

  private calculateVelocities() {
    this._velocityTable = [];
    this._velocityTable.push({ value: 'Maximal', crossSectional: this.calcValues.mcsv, surfaceVelocity: this.calcValues.msv, error: false });

    let totalSurfaceArea = (this.flushFloorConfiguration.bb * 2 * this.flushFloorConfiguration.cc) / 144;
    // 4750 TOTALSURFACEAREA=(BB*2*CC)/144
    let sv = Math.round((this.calcValues.lowerLarge / totalSurfaceArea) * 10) / 10;

    let csv = this.calcValues.calcCSVelocityFans(this.flushFloorConfiguration.supportHeight1, this.flushFloorConfiguration.cc);
    //4810 CSV=INT((LOWERLARGE/(2*ACTUALCSA))+.5)
    this._errorVelocities = sv > this.calcValues.msv || csv > this.calcValues.mcsv;
    this._velocityTable.push({ value: 'Actual', crossSectional: csv, surfaceVelocity: sv, error: this._errorVelocities });
  }

  private calcTunnelSupport(install: boolean = false) {
    let tunnelSupports = CanvasFlushFloorSupportFunction.aqsCalcTunnelSupport(this.flushFloorConfiguration.cc, 2 * (this.flushFloorConfiguration.bb + this.ee), this.calcValues.maxSpan, this.calcValues.minSpan);
    this._tunnelSupportOptions = [];
    tunnelSupports.forEach(item => {
      this._tunnelSupportOptions.push({
        label: `${item.totalTunnelSupport}, Span: ${item.span}'`,
        value: { totalTunnelSupport: item.totalTunnelSupport, span: item.span }
      });
    });

    this._tunnelSupport = this._tunnelSupportOptions[0].value;

    if (!install) {
      this.flushFloorConfiguration.totalTunnelSupport1 = this._tunnelSupport.totalTunnelSupport;
      this.flushFloorConfiguration.totalTunnelSupportSpan1 = this._tunnelSupport.span;
    }
  }

  private reDrawSchema() {
    if (this.pageError) {
      CanvasFlushFloorSupportFunction.drawErrorBorder(this.ctx);
      return;
    }
    CanvasFlushFloorSupportFunction.clearCanvas(this.ctx);
    this.scale = CanvasFlushFloorSupportFunction.calculateScale(this.ctx, this.storageConfiguration.diameter);
    this.ctx.strokeStyle = this.flushFloorTunnelStrokeColor;
    this.drawFlushSchema();
    this.drawCirclesSchema();
    this.drawSchemaLabels();
  }

  private drawSchemaLabels() {
    this.ctx.font = `${CanvasFlushFloorSupportFunction.FontSize}px serif`;
    this.ctx.strokeStyle = this.flushFloorTunnelStrokeColor;
    this.ctx.fillStyle = this.flushFloorTunnelStrokeColor;
    this.drawLabelA();
    this.drawLabelB();
    this.drawLabelC();
    this.drawLabelD();
    this.drawLabelE();
  }

  private drawLabelA() {
    let x = this.width / 2 - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 - this.flushFloorConfiguration.bb / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 3;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithTopLabel(this.ctx, this.scale, x, y, 'A', this.flushFloorConfiguration.aa);
  }

  private drawLabelB() {
    let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale - this.flushFloorConfiguration.cc / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize;
    let y = this.height / 2;
    CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelLeft(this.ctx, this.scale, x, y, 'B', this.flushFloorConfiguration.bb);
  }

  private drawLabelC() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.calcValues.rad * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'C', this.flushFloorConfiguration.cc);
  }

  private drawLabelD() {
    if (this.flushFloorConfiguration.ee > 0) {
      let x = this.width / 2;
      let y = this.height / 2;
      CanvasFlushFloorSupportFunction.drawObliqueLineBottomWithLabel(this.ctx, this.scale, x, y, 'D', this.flushFloorConfiguration.dd);
    }
  }

  private drawLabelE() {
    if (this.ee > 0) {
      let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.cc / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
      let y = this.height / 2 + this.flushFloorConfiguration.bb / 2 * this.scale + this.ee / 2 * this.scale;
      CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'E', this.ee);
    }
  }

  private drawFlushSchema() {
    //RTUNS2.BAS  3770-3990
    let x: number[] = this.determinePointsX();
    let y: number[] = this.determinePointsY();
    CanvasFlushFloorSupportFunction.drawFlushFloorForTwoFans(this.ctx, x, y, this.flushFloorTunnelFillColor);
  }

  private determinePointsY(): number[] {
    let y: number[] = [];
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow((this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.cc / 2), 2)));
    y.push(-this.flushFloorConfiguration.bb / 2);
    y.push(this.flushFloorConfiguration.bb / 2);
    y.push(y[2]);
    y.push(y[1]);
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow((this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.cc / 2), 2)));
    for (let i = 0; i < y.length; i++) {
      y[i] = this.height / 2 - y[i] * this.scale;
    }
    return y;
  }

  private determinePointsX(): number[] {
    let x: number[] = [];
    x.push(-(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.cc / 2));
    x.push(x[0]);
    x.push(x[1]);
    x.push(x[2] + this.flushFloorConfiguration.cc);
    x.push(x[1] + this.flushFloorConfiguration.cc);
    x.push(-(this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.cc / 2));
    for (let i = 0; i < x.length; i++) {
      x[i] = this.width / 2 + x[i] * this.scale;
    }
    return x;
  }

  private drawCirclesSchema() {
    CanvasFlushFloorSupportFunction.drawCircleFromCenter(this.ctx, this.calcValues.rad, this.scale);
    CanvasFlushFloorSupportFunction.drawCircleFromCenter(this.ctx, this.flushFloorConfiguration.dd, this.scale);
  }
}
