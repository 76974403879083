import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { LocationItxViewModel } from '../../models';
import { ItxAddEditPopupComponent, LocationBtxKtxDeletePopupComponent } from '../popups';
import { ITXGridComponent } from './itx-grid';

@Component({
  selector: 'greensleeves-itx-tab',
  templateUrl: './itx-tab.component.html'
})

export class ItxTabComponent implements AfterViewInit, OnDestroy {
  @ViewChild(ItxAddEditPopupComponent, { read: false, static: false })
  _addEditModal: ItxAddEditPopupComponent;
  
  @ViewChild(ITXGridComponent, {read: false, static: false})
   _gridComponent: ITXGridComponent;

  @ViewChild(LocationBtxKtxDeletePopupComponent, {read:false, static:false})
   _deleteComponent: LocationBtxKtxDeletePopupComponent;

  private _subscriptions: Subscription[] = [];

  constructor() { }

  ngOnDestroy() {
    this._subscriptions &&
      this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit() {

    let editSubscription = this._gridComponent.onEditClicked.subscribe((location: LocationItxViewModel) => {
      this._addEditModal.showEdit(location);
    });

    let deleteItxSubscription = this._gridComponent.onDeleteClicked.subscribe((location: LocationItxViewModel) => {
      this._deleteComponent.show(location);
    });

    this._subscriptions.push(
      editSubscription,
      deleteItxSubscription,
    );
  }

  public onAdd() {
    this._addEditModal.showAdd();
  }
}
