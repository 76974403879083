import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[greensleeves-touch-on-focus]'
})
export class TouchOnFocusDirective {
    constructor(
        private _control: NgControl
    ) {}

    @HostListener('focus')
    onFocus() {
        this._control.control.markAsTouched();
    }

    @HostListener('blur')
    onBlur() {
        this._control.control.markAsUntouched();
    }
}