import { CalendarDefaultValues } from '../constants';
import { KtxEventTypeEnum } from '../enums';

export class KtxEventViewModel {
  public eventId: number;
  public title: string;
  public body: string;
  public firstNotificationTimeStamp: number;
  public lastNotificationTimeStamp: number;
  public ktxAlertType: KtxEventTypeEnum;
  public binId: number;
  public locationId: number;
}

export class KtxEventGetModel {
  public eventId: number;
  public title: string;
  public body: string;
  public locationId: number;
  public firstNotificationTimeStamp: number;
  public lastNotificationTimeStamp: number;
  public isAlarm: boolean;
  public ktxOptions: KtxOptions;

  public static toViewModel(getModel: KtxEventGetModel): KtxEventViewModel {
    const viewModel = new KtxEventViewModel();
    const { defaultOneSecondMilliseconds } = CalendarDefaultValues;

    const correctBody = KtxEventGetModel.getCorrectBody(
      getModel.body,
      getModel.ktxOptions.nextReadTimeStamp * defaultOneSecondMilliseconds,
      getModel.isAlarm
    );

    viewModel.eventId = getModel.eventId;
    viewModel.title = getModel.title;
    viewModel.body = correctBody;
    viewModel.locationId = getModel.locationId;
    if (getModel.ktxOptions) {
      viewModel.binId = getModel.ktxOptions.binId;
    }
    viewModel.firstNotificationTimeStamp =
      getModel.firstNotificationTimeStamp * defaultOneSecondMilliseconds;

    viewModel.lastNotificationTimeStamp =
      getModel.lastNotificationTimeStamp * defaultOneSecondMilliseconds;

    viewModel.ktxAlertType = getModel.isAlarm
      ? KtxEventTypeEnum.Alarm
      : KtxEventTypeEnum.Info;

    return viewModel;
  }

  private static getCorrectBody(
    body: string,
    nextReadTimeStamp: number,
    isAlarm: boolean
  ) {
    if (!isAlarm && body.startsWith('A new reading is available for')) {
      const localTime = new Date(nextReadTimeStamp).toLocaleString();
      return `${body} ${localTime}`;
    }

    return body;
  }
}

export class KtxOptions {
  public binName: string;
  public unitName: string;
  public locationName: string;
  public nextReadTimeStamp: number;
  public binId: number;
}

export class KtxEventsRequestModel {
  public events: Array<KtxEventGetModel>;
  public totalCount: number;
  public itemsPerPage: number;
}

export class KtxEventByPeriodPostModel {
  public locationIds: Array<number>;
  public binIds: Array<number>;
  public dateTimeFrom: number;
  public dateTimeTo: number;
  public currentPage: number;
  public eventTypes: Array<KtxEventTypeEnum>;
  public isWeb: boolean=true;

  public get countSelectedFilter() {
    let count = 0;
    count += this.eventTypes.length;
    count += this.locationIds.length;
    count += this.binIds.length;
    return count;
  }

  constructor(public eventsPerPage: number) {
    this.locationIds = [];
    this.binIds = [];
    this.eventTypes = [];
    this.currentPage = 1;
  }
}
