import { switchMap } from 'rxjs/operators';
import { RoutePaths } from 'src/app/common';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MainRoutePaths } from '../../constants';
import { LocationsItxDatasourceService } from '../../services';
import { ItxBinAddEditPopupComponent } from './../popups';
import { ItxBinGridComponent } from './itx-bin-grid';
import { ItxBinViewModel } from './../../models';
@Component({
  selector: 'greensleeves-itx-bins-tab',
  templateUrl: './itx-bins-tab.component.html',
  styles: []
})
export class ItxBinsTabComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(ItxBinAddEditPopupComponent, { read: false, static: false })
  private _addEditModal: ItxBinAddEditPopupComponent;

  @ViewChild(ItxBinGridComponent, { read: false, static: false })
  private _itxBinGridComponent: ItxBinGridComponent;

  private _subscription: Subscription;
  _locationId: number | undefined;
  _locationName: string = '';
  _linkGoBack = `/${RoutePaths.MAIN_USER}/${MainRoutePaths.ITX_MANAGEMENT}`;

  constructor(
    private _route: ActivatedRoute,
    private _locationsItxDataSourceService: LocationsItxDatasourceService,
    private _router: Router) { }

  ngOnInit() {
    const subscribtion = this._locationsItxDataSourceService.locationsItx$;
    if (this._router.url.indexOf('/back-door') > -1) {
      this._locationId = 0;
      this._locationName = 'LocationName';
      return;
    }

    this._route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    ).subscribe(data => {
      this._locationId = +data;
      const location = subscribtion.getValue().find(l => l.id == this._locationId);
      if (location) {
        this._locationName = location.locationName;
      }
      else {
        this._router.navigateByUrl(`/${RoutePaths.MAIN_USER}/${MainRoutePaths.ITX_MANAGEMENT}`);
      }
    });
  }

  ngAfterViewInit(){
    this._subscription = this._itxBinGridComponent.onEditClicked.subscribe((itxBin: ItxBinViewModel) => {
      this._addEditModal.showEdit(itxBin);
    });
  }

  ngOnDestroy() {
    this._locationsItxDataSourceService.markLocationItxForOthersAsStoragesInView(this._locationId, false);
    this._subscription.unsubscribe();
  }

  onClickAdd() {
    let location = { id: this._locationId, name: this._locationName };
    this._addEditModal.showAdd(location);
  }
}
