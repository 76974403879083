export class CompanyAdminViewModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public companyId: number;
    public companyName: string;
    public email: string;
    public lastLoginDate: Date;

    //User behavior lock fields
    public isSomeoneUpdateCompanyAdmin: boolean;
}

export class CompanyAdminInviteModel {
    email: string;
    companyId?: number;
}

export class CompanyAdminPostModel {
    id: number;
    firstName: string;
    lastName: string;
    companyId?: number;
    companyName?: string;
}

export class CompanyAdminGetModel {
    id: number;
    firstName: string;
    lastName: string;
    companyId: number;
    companyName: string;
    email: string;
    lastLoginDate: Date;

    public static toViewModel(getModel: CompanyAdminGetModel): CompanyAdminViewModel {
        const viewModel = new CompanyAdminViewModel();
        viewModel.id = getModel.id;
        viewModel.firstName = getModel.firstName;
        viewModel.lastName = getModel.lastName;
        viewModel.companyId = getModel.companyId;
        viewModel.companyName = getModel.companyName;
        viewModel.email = getModel.email;
        viewModel.lastLoginDate = getModel.lastLoginDate;

        return viewModel;
    }
}