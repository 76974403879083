import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { CompanyUserInviteModel, CompanyUserViewModel } from '../../../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { LocationsDataSourceService, CompanyUsersDataSourceService } from '../../../services';
import { LocalStorageService, UserRoleEnum, ErrorModel, ErrorCodeEnum } from '../../../../common';
import { emailValidator } from '../../../../common/validators';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-company-user-add-popup',
  templateUrl: './company-user-add-popup.component.html',
  styles: []
})
export class CompanyUserAddPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked {
  _isHidden = true;
  _submitted = false;
  _isSubmit = false;
  _invalidEmail = false;
  _noLocationSelected = false;
  _companyUserForm: FormGroup;

  _btxLocations: { label: string, value: number }[];
  _ktxLocations: { label: string, value: number }[];
  _itxLocations: { label: string, value: number }[];
  private _locationsSubscription: Subscription;

  _role: UserRoleEnum;

  get _isCompanyAdmin() {
    return this._role === UserRoleEnum.CompanyAdmin;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _locationsService: LocationsDataSourceService,
    private _localStorageService: LocalStorageService,
    private _companyUsersService: CompanyUsersDataSourceService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const loginInfo = this._localStorageService.getLoginInfo();
    this._role = loginInfo ? loginInfo.role : null;
    if (this._isCompanyAdmin) {
      this._locationsService.getKtxBtxItxLocationForCompany(loginInfo.companyId);

      this._locationsSubscription = this._locationsService.btxLocationsForCompany$.subscribe(data => {
        if (data) {
          this._btxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
        }
      });

      this._locationsSubscription = this._locationsService.ktxLocationsForCompany$.subscribe(data => {
        if (data) {
          this._ktxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
        }
      });

      this._locationsSubscription = this._locationsService.itxLocationsForCompany$.subscribe(data => {
        if (data) {
          this._itxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
        }
      });
    }

    this._companyUserForm = this._formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          emailValidator(),
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      ...(this._isCompanyAdmin ? {
        btxLocationIds: [
          '',
          Validators.compose([
          ])
        ],
      } : {}),
      ...(this._isCompanyAdmin ? {
        ktxLocationIds: [
          '',
          Validators.compose([
          ])
        ],
      } : {}),
      ...(this._isCompanyAdmin ? {
        itxLocationIds: [
          '',
          Validators.compose([
          ])
        ],
      } : {}),
    });

    this._companyUserForm.valueChanges.subscribe(() => this._invalidEmail = false);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._locationsSubscription && this._locationsSubscription.unsubscribe();
    this._locationsSubscription = null;
  }

  ngAfterViewChecked() {
    super.ngAfterViewChecked();
  }

  public show() {
    this.resetForm();
    this._isHidden = false;
  }

  async onSubmit() {
    this._submitted = true;
    this._noLocationSelected = false;
    const value = this._companyUserForm.value as CompanyUserInviteModel;

    if (this._companyUserForm.invalid || this._invalidEmail) {
      if ((!value.btxLocationIds || value.btxLocationIds.length === 0) && (!value.ktxLocationIds || value.ktxLocationIds.length === 0) && (!value.itxLocationIds || value.itxLocationIds.length === 0)) {
        this._noLocationSelected = true;
      }

      return;
    }

    if ((!value.btxLocationIds || value.btxLocationIds.length === 0) && (!value.ktxLocationIds || value.ktxLocationIds.length === 0) && (!value.itxLocationIds || value.itxLocationIds.length === 0)) {
      this._noLocationSelected = true;
      return;
    }

    this._companyUsersService.invite(value)
      .then((response: CompanyUserViewModel) => {
        if (response instanceof ErrorModel && response.code === ErrorCodeEnum.UserAlreadyExist) {
          this._isSubmit = false;
          this._invalidEmail = true;
        } else if (response) {
          const loginInfo = this._localStorageService.getLoginInfo();
          this._companyUsersService.get(loginInfo.companyId);
          this.resetForm();
        }
      })
  }

  private resetForm() {
    this._submitted = false;
    this._isSubmit = false;
    this._isHidden = true;
    this._noLocationSelected = false;
    this._companyUserForm.reset();
    this._companyUserForm.markAsUntouched();
    Object.keys(this._companyUserForm.controls).forEach((name) => {
      const control = this._companyUserForm.controls[name];
      control.setErrors(null);
    });
  }
}
