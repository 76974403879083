import { Component, ViewChild } from '@angular/core';
import { ServiceUserAddPopupComponent } from '../popups';
import { LocalStorageService, UserRoleEnum } from '../../../common';

@Component({
  selector: 'greensleeves-service-users-tab',
  templateUrl: './service-users-tab.component.html'
})
export class ServiceUsersTabComponent {
  @ViewChild(ServiceUserAddPopupComponent, { read: false, static: false })
  _addModal: ServiceUserAddPopupComponent;
  _role: UserRoleEnum;
  
  constructor(
    private _localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;
  }

  onClickAdd() {
    this._addModal.show();
  }
}
