import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorModel } from '../../../common';
import {
  CompanyUserViewModel,
  CompanyUserPostModel,
  CompanyUserInviteModel,
  CompanyUserAddedHubMessage,
  CompanyUserDeletedHubMessage,
  CompanyUserInUpdateHubMessage,
  CompanyUserUpdatedHubMessage,
} from '../../models';
import { CompanyUsersApiService, HubService } from '../http';
import { HubMethods } from '../../constants';

@Injectable()
export class CompanyUsersDataSourceService {
  public companyUsers$: BehaviorSubject<CompanyUserViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);

  constructor(
    private _companyUsersApi: CompanyUsersApiService,
    private _hubService: HubService,) {
      this.subscribeToHubService();
    }

  public async get(userCompanyId: number) {
    try {
      const data = await this._companyUsersApi.loadAll(userCompanyId);
      if (data) {
        this.companyUsers$.next(data as CompanyUserViewModel[]);
        this.errors$.next(null);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async invite(companyUser: CompanyUserInviteModel) : Promise<CompanyUserViewModel|ErrorModel> {
    try {
      const result = await this._companyUsersApi.invite(companyUser) as CompanyUserViewModel;
      if (result) {
        this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.CompanyUser.companyUserAdded, new CompanyUserAddedHubMessage(result));
        return result;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      }
    }
  }
  
  public async edit(companyUser: CompanyUserPostModel) : Promise<boolean|ErrorModel> {
    try
    {
      const result = await this._companyUsersApi.edit(companyUser) as CompanyUserViewModel;
      if (result) {
        this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.CompanyUser.companyUserUpdated, new CompanyUserUpdatedHubMessage(result, true));
        const companyUsers = this.companyUsers$.getValue().map(l => l.id === result.id ? { ...l, ...result} : l);
        this.companyUsers$.next(companyUsers);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async delete(companyUserId: number) : Promise<boolean|ErrorModel> {
    try
    {
      const result = await this._companyUsersApi.delete(companyUserId);
      if (result) {
        this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.CompanyUser.companyUserDeleted, new CompanyUserDeletedHubMessage(companyUserId));
        const companyUsers = this.companyUsers$.getValue().filter(l => l.id !== result);
        this.companyUsers$.next(companyUsers);
        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      return error instanceof ErrorModel ? error : false;
    }
  }

  public async markCompanyUserForOthersAsInUpdate(userId: number): Promise<any> {
    this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.CompanyUser.companyUserInUpdate, new CompanyUserInUpdateHubMessage(userId));
  }

  public async markCompanyUserForOthersAsUpdated(companyUser: CompanyUserViewModel, isWithChanges: boolean): Promise<any> {
    this._hubService.sendFromCompanyAdminToCompanyAdmins(HubMethods.CompanyUser.companyUserUpdated, new CompanyUserUpdatedHubMessage(companyUser, isWithChanges));
  }

  private subscribeToHubService() {
    this._hubService.companyUserAdded.subscribe((hubMessage: CompanyUserAddedHubMessage) => {
      if (hubMessage && hubMessage.newCompanyUser) {
        this.companyUsers$.next([
          ...this.companyUsers$.getValue(),
          hubMessage.newCompanyUser
        ]);
      }
    });
    this._hubService.companyUserDeleted.subscribe((hubMessage: CompanyUserDeletedHubMessage) => {
      if (hubMessage) {
        const users = this.companyUsers$.getValue().filter(l => l.id !== hubMessage.companyUserId);
        this.companyUsers$.next(users);
      }
    });
    this._hubService.companyUserInUpdate.subscribe((hubMessage: CompanyUserInUpdateHubMessage) => {
      if (hubMessage) {
        const companyUser = this.companyUsers$.getValue().find(l => l.id === hubMessage.companyUserId);
        if (companyUser) {
          companyUser.isSomeoneUpdateCompanyUser = true;
        }
      }
    });
    this._hubService.companyUserUpdated.subscribe((hubMessage: CompanyUserUpdatedHubMessage) => {
      if (hubMessage && hubMessage.updatedCompanyUser) {
        if (hubMessage.withChanges) {
          hubMessage.updatedCompanyUser.isSomeoneUpdateCompanyUser = undefined;
          this.companyUsers$.next(this.companyUsers$.getValue().map(l => l.id === hubMessage.updatedCompanyUser.id ? hubMessage.updatedCompanyUser : l));
        } else {
          const companyUser = this.companyUsers$.getValue().find(c => c.id == hubMessage.updatedCompanyUser.id);
          companyUser.isSomeoneUpdateCompanyUser = false;
        }
      }
    });
  }
}
