import { EquipmentSubTypeEnum, EquipmentTypeEnum } from '../enums';

export const EquipmentPlaceholderClassNames = {
  [EquipmentTypeEnum.Leg]: {
    [EquipmentSubTypeEnum.WithKneeBearing]: 'leg-with-knee-bearing-placeholder',
    [EquipmentSubTypeEnum.WithoutKneeBearing]: 'leg-without-knee-bearing-placeholder',
  },
  [EquipmentTypeEnum.Conveyor]: {
    [EquipmentSubTypeEnum.Belt]: 'conveyor-belt-placeholder',
    [EquipmentSubTypeEnum.CoveredBelt]: 'conveyor-covered-belt-placeholder',
    [EquipmentSubTypeEnum.Drag]: 'conveyor-drag-placeholder',
    [EquipmentSubTypeEnum.Screw]: 'conveyor-screw-placeholder',
  },
  [EquipmentTypeEnum.Silo]: {
    [EquipmentSubTypeEnum.Small]: 'silo-placeholder',
    [EquipmentSubTypeEnum.Medium]: 'silo-placeholder',
    [EquipmentSubTypeEnum.Large]: 'silo-placeholder',
  },
  [EquipmentTypeEnum.Tripper]: {
    [EquipmentSubTypeEnum.None]: 'tripper-placeholder',
  },
  [EquipmentTypeEnum.Fan]: {
    [EquipmentSubTypeEnum.BeltDrive]: 'fan-belt-drive-placeholder',
    [EquipmentSubTypeEnum.DirectDrive]: 'fan-direct-drive-placeholder',
  },
};
