import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ConvertDataFunctions } from './../../../../../common';
import { AqsAirflowViewModel, AqsFlushFloorCalculatedValues, AqsFlushFloorViewModel, AqsRoundStorageWiewModel } from './../../../../models';
import { CanvasFlushFloorSupportFunction } from './../aqs-canvas-flushfloor-support-functions';

@Component({
  selector: 'greensleeves-aqs-round-flush-design-double-t',
  templateUrl: './aqs-round-flush-design-double-t.component.html',
  styles: []
})
export class AqsRoundFlushDesignDoubleTComponent implements OnInit {
  @Input() storageConfiguration: AqsRoundStorageWiewModel;
  @Input() flushFloorConfiguration: AqsFlushFloorViewModel;
  @Input() calcValues: AqsFlushFloorCalculatedValues;

  @Input() flushFloorTunnelFillColor: string;
  @Input() flushFloorTunnelStrokeColor: string;

  @Output() currentPageErrorChange = new EventEmitter<boolean>();
  @Output() onChangeFlushFloorModel = new EventEmitter();

  @ViewChild('flushFloorCanvas', { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;

  _a: string;
  _b: number;
  _c: number;
  _d: number;
  _e: number;
  _f: number;
  _g: string;
  private gg: number;

  _errorALessOrEqualsC: boolean = false;
  _errorACFBeyondBinDia: boolean = false;
  _errorBToLarge: boolean = false;
  _errorFToLarge: boolean = false;
  _errorVelocities: boolean = false;
  private pageError: boolean = false;

  _supportHeightOptions = CanvasFlushFloorSupportFunction.supportHeightOptions;

  _tunnelSupportOptions1: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport1: { totalTunnelSupport: number, span: number };
  _tunnelSupportOptions2: { label: string, value: { totalTunnelSupport: number, span: number } }[];
  _tunnelSupport2: { totalTunnelSupport: number, span: number };

  _velocityTable: { value: string, crossSectional: number, surfaceVelocity: number, error: boolean }[];

  _columnsView = CanvasFlushFloorSupportFunction.velocitiesFor2FanTableColumns;

  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;
  private scale: number;

  constructor() { }

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;

    if (this.flushFloorConfiguration.aa == undefined || this.flushFloorConfiguration.aa == null) {
      this.calculatedRecommendationParameters();
    }

    this.fillInputValues();
  }

  onChangeValueA() {
    if (this._a != '') {
      let splitStrings = this._a.split('-');
      let val = parseFloat(splitStrings[0]);
      if (!isNaN(val) && (val >= 2 && val < 1000)) {
        this.flushFloorConfiguration.aa = val * 12;
        if (splitStrings.length > 1) {
          let index = this._a.indexOf('-');
          val = parseFloat(this._a.slice(++index));
          if (!isNaN(val)) {
            this.flushFloorConfiguration.aa += val;
          }
        }
        this.calcG();
        this.checkError();
        this.reDrawSchema();
        this.calcTunnelSupport(1);
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._a = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.aa);
  }

  onChangeValueB() {
    if (this.checkValue(this._b)) {
      this.flushFloorConfiguration.bb = this._b * 12;
      this.calcG();
      this.calculateVelocities();
      this.checkError();
      this.reDrawSchema();
      this.calcTunnelSupport(1);
      this.onChangeFlushFloorModel.emit();
    }
    else this._b = Math.round(this.flushFloorConfiguration.bb / 12);
  }

  onChangeValueC() {
    if (this._c != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._c, this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.cc) {
        this.flushFloorConfiguration.cc = calcValue;
        this.calculateVelocities();
        this.checkError();
        this.reDrawSchema();
        this.calcTunnelSupport(1);
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._c = this.flushFloorConfiguration.cc;
  }

  onChangeValueD() {
    if (this._d != null) {
      let calcValue = this.calcValues.checkValueByCrossSec(this._d, this.calcValues.maxTunnelWidth);
      if (calcValue != this.flushFloorConfiguration.dd) {
        this.flushFloorConfiguration.dd = calcValue;
        this.calculateVelocities();
        this.checkError();
        this.reDrawSchema();
        this.calcTunnelSupport(2);
        this.onChangeFlushFloorModel.emit();
      }
    }
    this._d = this.flushFloorConfiguration.dd;
  }

  onChangeValueE() {
    if (this._e >= 0 && this._e <= 100 && this._e != null) {
      this.flushFloorConfiguration.gg = this._e;
      this.flushFloorConfiguration.ee = (this.calcValues.rad * this._e) / 100;
      this.reDrawSchema();
      this.onChangeFlushFloorModel.emit();
    }
    else this._e = this.flushFloorConfiguration.gg;
  }

  onChangeValueF() {
    if (this._f >= 0 && this._f % 2 == 0) {
      this.flushFloorConfiguration.ff = this._f * 12;
      this.calculateVelocities();
      this.checkError();
      this.reDrawSchema();
      this.calcTunnelSupport(2);
      this.onChangeFlushFloorModel.emit();
    }
    else this._f = Math.round(this.flushFloorConfiguration.ff / 12);
  }

  onChangeSupportHeight() {
    this.calculateVelocities();
    this.onChangeFlushFloorModel.emit();
  }

  onChangeTunnelSupport(tunnelNumber: number) {
    switch (tunnelNumber) {
      case 2:
        this.flushFloorConfiguration.totalTunnelSupport2 = this._tunnelSupport2.totalTunnelSupport;
        this.flushFloorConfiguration.totalTunnelSupportSpan2 = this._tunnelSupport2.span;
        break;
      default:
        this.flushFloorConfiguration.totalTunnelSupport1 = this._tunnelSupport1.totalTunnelSupport;
        this.flushFloorConfiguration.totalTunnelSupportSpan1 = this._tunnelSupport1.span;
        break;
    }

    this.onChangeFlushFloorModel.emit();
  }

  numberOnly(event) {
    if (!_isNumberValue(event.key) && event.key !== '.' && event.key !== '-' && event.key !== 'Enter') {
      event.preventDefault();
    }
  }

  private checkValue(value: number): boolean {
    return value >= 2 && value % 2 == 0;
  }

  private checkError() {
    this._errorALessOrEqualsC = this.flushFloorConfiguration.aa <= this.flushFloorConfiguration.cc;
    this._errorACFBeyondBinDia = Math.pow(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.cc / 2 + this.flushFloorConfiguration.ff, 2) > Math.pow(this.calcValues.rad, 2);
    //8070 IF (AA/2+CC/2+FF)^2>RAD^2 THEN STATUS=-1:LOCATE 25,1: PRINT "UNABLE TO DRAW-- BEYOND BIN DIA";:RETURN
    this._errorBToLarge = this.flushFloorConfiguration.bb > this.calcValues.rad * 2;
    this._errorFToLarge = this.flushFloorConfiguration.ff > this.calcValues.rad * 2;
    this.pageError = this._errorALessOrEqualsC || this._errorACFBeyondBinDia || this._errorBToLarge || this._errorFToLarge || this._errorVelocities;

    this.currentPageErrorChange.emit(this.pageError);
  }

  private calculatedRecommendationParameters() {
    [this.flushFloorConfiguration.supportHeight1, this.flushFloorConfiguration.cc] = this.calcValues.calcMainTunnelWidth(this.calcValues.maxTunnelWidth);
    this.flushFloorConfiguration.gg = 75;//instad of EEE
    this.flushFloorConfiguration.ee = (this.flushFloorConfiguration.gg * this.calcValues.rad) / 100;
    this.flushFloorConfiguration.aa = (this.calcValues.rad * 2 * .275);
    this.flushFloorConfiguration.bb = Math.round((Math.sqrt(Math.pow(this.flushFloorConfiguration.ee, 2) - Math.pow(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.cc / 2, 2)) * 2) / 24) * 24;
    if (this.flushFloorConfiguration.bb < 24) this.flushFloorConfiguration.bb = 24;
    //6640 BB=CINT((SQR(EE^2-(AA/2+CC/2)^2)*2)/24)*24: IF BB<24 THEN BB=24
    this.flushFloorConfiguration.dd = this.flushFloorConfiguration.cc;
    this.flushFloorConfiguration.ff = Math.sqrt(Math.pow(this.flushFloorConfiguration.ee, 2) - Math.pow(this.flushFloorConfiguration.dd / 2, 2));
    this.flushFloorConfiguration.ff = Math.floor((this.flushFloorConfiguration.ff - this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.cc / 2) / 24) * 24;
    //FF=SQR(EE^2-(DD/2)^2):FF=INT((FF-(AA/2)-(CC/2))/24)*24
  }

  private fillInputValues() {
    this._a = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.flushFloorConfiguration.aa);
    this._b = Math.round(this.flushFloorConfiguration.bb / 12);
    this._c = this.flushFloorConfiguration.cc;
    this._d = this.flushFloorConfiguration.dd;
    this._e = this.flushFloorConfiguration.gg;//gg insted of EEE
    this._f = Math.round(this.flushFloorConfiguration.ff / 12);

    this.calcG();
    this.calculateVelocities();
    this.fillTunnelSupports();
    this.reDrawSchema();
  }

  public fillTunnelSupports() {
    this.calcTunnelSupport(1, true);
    if (!this.flushFloorConfiguration.totalTunnelSupport1) {
      this.onChangeTunnelSupport(1);
    } else {
      this._tunnelSupport1.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport1;
      this._tunnelSupport1.span = this.flushFloorConfiguration.totalTunnelSupportSpan1;
    }

    this.calcTunnelSupport(2, true);
    if (!this.flushFloorConfiguration.totalTunnelSupport2) {
      this.onChangeTunnelSupport(2);
    } else {
      this._tunnelSupport2.totalTunnelSupport = this.flushFloorConfiguration.totalTunnelSupport2;
      this._tunnelSupport2.span = this.flushFloorConfiguration.totalTunnelSupportSpan2;
    }
  }

  private calcTunnelSupport(tunnelNum: number, install: boolean = false) {
    let tunnelSupportOptions: { label: string, value: { totalTunnelSupport: number, span: number } }[] = [];
    let tunnelDia: number;
    let tunnelLength: number;
    switch (tunnelNum) {
      case 1:
        tunnelDia = this.flushFloorConfiguration.cc;
        tunnelLength = 2 * (this.flushFloorConfiguration.bb + this.gg);
        break;
      case 2:
        tunnelDia = this.flushFloorConfiguration.dd;
        tunnelLength = 2 * this.flushFloorConfiguration.ff;
        break;
    }

    let tunnelSupports = CanvasFlushFloorSupportFunction.aqsCalcTunnelSupport(tunnelDia, tunnelLength, this.calcValues.maxSpan, this.calcValues.minSpan);
    tunnelSupports.forEach(item => {
      tunnelSupportOptions.push({
        label: `Supports: ${item.totalTunnelSupport}, Span: ${item.span}'`,
        value: { totalTunnelSupport: item.totalTunnelSupport, span: item.span }
      });
    });

    switch (tunnelNum) {
      case 1:
        this._tunnelSupportOptions1 = tunnelSupportOptions;
        this._tunnelSupport1 = this._tunnelSupportOptions1[0].value;
        if (!install) {
          this.flushFloorConfiguration.totalTunnelSupport1 = this._tunnelSupport1.totalTunnelSupport;
          this.flushFloorConfiguration.totalTunnelSupportSpan1 = this._tunnelSupport1.span;
        }
        break;
      case 2:
        this._tunnelSupportOptions2 = tunnelSupportOptions;
        this._tunnelSupport2 = this._tunnelSupportOptions2[0].value;
        if (!install) {
          this.flushFloorConfiguration.totalTunnelSupport2 = this._tunnelSupport2.totalTunnelSupport;
          this.flushFloorConfiguration.totalTunnelSupportSpan2 = this._tunnelSupport2.span;
        }
        break;
    }
  }

  private calcG() {
    this.gg = CanvasFlushFloorSupportFunction.aqsCalcDimensionBeforeTunnel(this.calcValues.rad, this.flushFloorConfiguration.bb, this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.cc / 2);
    this._g = ConvertDataFunctions.aqsMakeStringFromFtToFtIn(this.gg);
  }

  private calculateVelocities() {
    this._velocityTable = [];
    this._velocityTable.push({ value: 'Maximal', crossSectional: this.calcValues.mcsv, surfaceVelocity: this.calcValues.msv, error: false });
    let totalSurfaceArea = (this.flushFloorConfiguration.bb * 2 * this.flushFloorConfiguration.cc + this.flushFloorConfiguration.dd * 2 * this.flushFloorConfiguration.ff) / 144;
    //7930 TOTALSURFACEAREA=((BB*2*CC)+(DD*2*FF))/144:'in sq ft
    let sv = Math.round((this.calcValues.lowerLarge / totalSurfaceArea) * 10) / 10;
    let csv = this.calcValues.calcCSVelocityFans(this.flushFloorConfiguration.supportHeight1, this.flushFloorConfiguration.cc);
    this._errorVelocities = sv > this.calcValues.msv || csv > this.calcValues.mcsv;
    this._velocityTable.push({ value: 'Actual', crossSectional: csv, surfaceVelocity: sv, error: this._errorVelocities });
  }

  private reDrawSchema() {
    if (this.pageError) {
      CanvasFlushFloorSupportFunction.drawErrorBorder(this.ctx);
      return;
    }
    CanvasFlushFloorSupportFunction.clearCanvas(this.ctx);
    this.scale = CanvasFlushFloorSupportFunction.calculateScale(this.ctx, this.storageConfiguration.diameter);
    this.ctx.strokeStyle = this.flushFloorTunnelStrokeColor;
    this.drawFlushSchema();
    this.drawCirclesSchema();
    this.drawSchemaLabels();
  }

  private drawFlushSchema() {
    //RTUNS2.BAS  6680-6950
    let [x, y] = this.determinePointsXY();
    CanvasFlushFloorSupportFunction.drawFlushFloorForTwoFans(this.ctx, x, y, this.flushFloorTunnelFillColor);
  }

  private determinePointsXY(): [number[], number[]] {
    let x: number[] = [];
    let y: number[] = [];
    x.push(-(this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.cc / 2));
    x.push(x[0]);
    x.push(x[1]);
    x.push(x[2] - this.flushFloorConfiguration.ff);
    x.push(x[3]);
    x.push(x[2]);
    x.push(x[5]);
    x.push(x[0] + this.flushFloorConfiguration.cc);
    x.push(x[7]);
    x.push(x[8]);
    for (let i = 0; i < x.length; i++) {
      x[i] = this.width / 2 + x[i] * this.scale;
    }

    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow(this.flushFloorConfiguration.cc / 2 + this.flushFloorConfiguration.aa / 2, 2)));
    y.push(-this.flushFloorConfiguration.bb / 2);
    y.push(-this.flushFloorConfiguration.dd / 2);
    y.push(y[2]);
    y.push(this.flushFloorConfiguration.dd / 2);
    y.push(y[4]);
    y.push(this.flushFloorConfiguration.bb / 2);
    y.push(y[6]);
    y.push(y[1]);
    y.push(-Math.sqrt(Math.pow(this.calcValues.rad, 2) - Math.pow(this.flushFloorConfiguration.aa / 2 - this.flushFloorConfiguration.cc / 2, 2)));
    for (let i = 0; i < y.length; i++) {
      y[i] = this.height / 2 - y[i] * this.scale;
    }

    return [x, y];
  }

  private drawCirclesSchema() {
    CanvasFlushFloorSupportFunction.drawCircleFromCenter(this.ctx, this.calcValues.rad, this.scale);
    CanvasFlushFloorSupportFunction.drawCircleFromCenter(this.ctx, this.flushFloorConfiguration.ee, this.scale);
  }

  private drawSchemaLabels() {
    this.ctx.font = `${CanvasFlushFloorSupportFunction.FontSize}px serif`;
    this.ctx.strokeStyle = this.flushFloorTunnelStrokeColor;
    this.ctx.fillStyle = this.flushFloorTunnelStrokeColor;
    this.drawLabelA();
    this.drawLabelB();
    this.drawLabelC();
    this.drawLabelD();
    this.drawLabelE();
    this.drawLabelF();
    this.drawLabelG();
    this.drawLabel1();
    this.drawLabel2();
  }

  private drawLabelA() {
    let x = this.width / 2 - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 - this.flushFloorConfiguration.bb / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 3;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithTopLabel(this.ctx, this.scale, x, y, 'A', this.flushFloorConfiguration.aa);
  }

  private drawLabelB() {
    let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.cc / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
    let y = this.height / 2;
    CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'B', this.flushFloorConfiguration.bb);
  }

  private drawLabelC() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.calcValues.rad * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'C', this.flushFloorConfiguration.cc);
  }

  private drawLabelD() {
    let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale - this.flushFloorConfiguration.cc / 2 * this.scale - this.flushFloorConfiguration.ff * this.scale - CanvasFlushFloorSupportFunction.FontSize;
    let y = this.height / 2;
    CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelLeft(this.ctx, this.scale, x, y, 'D', this.flushFloorConfiguration.dd);
  }

  private drawLabelE() {
    if (this.flushFloorConfiguration.gg > 0) {
      let x = this.width / 2;
      let y = this.height / 2;
      CanvasFlushFloorSupportFunction.drawObliqueLineBottomWithLabel(this.ctx, this.scale, x, y, 'E', this.flushFloorConfiguration.ee);
    }
  }

  private drawLabelF() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.cc / 2 * this.scale +
      this.flushFloorConfiguration.ff / 2 * this.scale - CanvasFlushFloorSupportFunction.FontSize / 4;
    let y = this.height / 2 + this.flushFloorConfiguration.dd / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize;
    CanvasFlushFloorSupportFunction.drawHorizontalLineWithBottomLabel(this.ctx, this.scale, x, y, 'F', this.flushFloorConfiguration.ff);
  }

  private drawLabelG() {
    if (this.gg > 0) {
      let x = this.width / 2 - this.flushFloorConfiguration.aa / 2 * this.scale + this.flushFloorConfiguration.cc / 2 * this.scale + CanvasFlushFloorSupportFunction.FontSize / 3;
      let y = this.height / 2 + this.flushFloorConfiguration.bb / 2 * this.scale + this.gg / 2 * this.scale;
      CanvasFlushFloorSupportFunction.drawVerticalLineWithLabelRight(this.ctx, this.scale, x, y, 'G', this.gg);
    }
  }

  private drawLabel1() {
    let x = this.width / 2 + this.flushFloorConfiguration.aa / 2 * this.scale;
    let y = this.height / 2 - this.flushFloorConfiguration.bb / 4 * this.scale;
    CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, '1');
  }

  private drawLabel2() {
    let x = this.width / 2 + (this.flushFloorConfiguration.aa / 2 + this.flushFloorConfiguration.cc / 2 + this.flushFloorConfiguration.ff / 2) * this.scale;
    let y = this.height / 2 - this.flushFloorConfiguration.dd / 2 * this.scale;
    CanvasFlushFloorSupportFunction.drawLabelInRect(this.ctx, x, y, '2');
  }
}
