import { Injectable } from '@angular/core';

import { HttpService, ErrorModel } from '../../../common';
import { ApiRoutes } from '../../constants';

@Injectable()
export class BinApiService {
  constructor(
    private _httpService: HttpService,
  ) { }

  public async get2DScheme() : Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.getArrayBuffer(ApiRoutes.Bin.get2dScheme);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async get3DScheme() : Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.getArrayBuffer(ApiRoutes.Bin.get3dScheme);
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }
}
