import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  AqsAirFlowDataViewModel, AqsCommoditiesViewModel,
  AqsExhausterFanModel, AqsExhausterModel, AqsExhausterSelectedFanModel,
  AqsExhausterViewModel, AqsRoundStorageWiewModel,
  FlatStorageParams
} from '@models';

import { AqsSessionTypeEnum } from '../../../../enums';

@Component({
  selector: 'greensleeves-aqs-exhauster-recommendations',
  templateUrl: './aqs-exhauster-recommendations.component.html',
  styleUrls: []
})
export class AqsExhausterRecommendationsComponent implements OnInit {
  @Output() onChangedExhausterModel = new EventEmitter();

  @Input() _exhausterViewModel: AqsExhausterViewModel;
  @Input() sessionType: AqsSessionTypeEnum;
  @Input() storageConfiguration: any;
  @Input() commodities: AqsCommoditiesViewModel[];
  @Input() airflowData: AqsAirFlowDataViewModel[];
  @Input() numberFans: number;

  _fansTable: AqsExhausterFanModel[];
  _selectedFanViewTable: AqsExhausterSelectedFanModel;
  _lowerLarge: number;
  _lowerSmall: number;
  _largest: number;
  _smallest: number;
  _selectedFan: AqsExhausterFanModel;
  pitch: boolean;
  fansData: AqsExhausterModel[] = [
    { name: '24 in 2 hp', exhauster: 10200, fanType: 'Vertical Axial' },
    { name: '24 in 2 hp', exhauster: 10200, fanType: 'Square Tapered' },
  ];

  _cols = [
    { field: 'code', header: 'Code' },
    { field: 'fanType', header: 'Fan type' },
    { field: 'model', header: 'Model' },
    { field: 'qty1', header: 'Qty 1' },
    { field: 'qty2', header: 'Qty 2' },
    { field: 'safety1', header: 'Safety 1 (%)' },
    { field: 'safety2', header: 'Safety 2 (%)' },
    { field: 'mgva1', header: 'MGVa 1 (sq ft.)' },
    { field: 'mgva2', header: 'MGVa 2 (sq ft.)' },
  ];

  constructor() { }

  ngOnInit() {
    this._fansTable = [];
    this._selectedFanViewTable = new AqsExhausterSelectedFanModel();
    this._selectedFan = new AqsExhausterFanModel();
    this.checkForPitch();
    this.onCalcualteFirstStart();
  }

  checkForPitch() {
    if (this.sessionType == AqsSessionTypeEnum.RoundSessionType) {
      this.pitch = (this.storageConfiguration as AqsRoundStorageWiewModel).roofPitch > 0;
    } else if (this.sessionType == AqsSessionTypeEnum.FlatSessionType) {
      this.pitch = (this.storageConfiguration as FlatStorageParams).roof > 0;
    } else {
      console.log('Session type is undefined');
      this.pitch = false;
    }
  }

  onCalcualteFirstStart() {
    this.onCalculateLargestSmallest();
    this.onCalculateLowerLargeLowerSmall();
    this.onCalculateExhausters();

    if (this.CheckForRecoverData()) {
      this.onCalcualteRecoverData();
    } else {
      this.onCalculateRecommendedFan();
      this.saveDataToModel();
    }

    this.checkForEmptyFields();
  }

  private saveDataToModel() {
    this._exhausterViewModel.code = this._selectedFanViewTable.code;
    this._exhausterViewModel.qty = this._selectedFanViewTable.qty;
  }

  private CheckForRecoverData(): boolean {
    if (this._exhausterViewModel.qty || this._exhausterViewModel.code) {
      return true;
    }

    return false;
  }

  onChangeQuantity() {
    if (this._selectedFanViewTable.qty < 1) {
      this._selectedFanViewTable.qty = this._exhausterViewModel.qty
    } else {
      if (this._selectedFanViewTable.qty > 99) {
        this._selectedFanViewTable.qty = 99;
      }

      this._exhausterViewModel.qty = this._selectedFanViewTable.qty;

      this.onCalculateSelectedFan();
      this.changeExhausterModel();
    }
  }

  private onCalcualteRecoverData() {
    this._selectedFan = this._fansTable.find(fan => fan.code === this._exhausterViewModel.code);
    this._selectedFanViewTable.code = this._exhausterViewModel.code;
    this._selectedFanViewTable.qty = this._exhausterViewModel.qty;
    this.onCalculateSelectedFan();
  }

  private onCalculateSelectedFan() {
    this._selectedFanViewTable.safety = this.fansData[this._exhausterViewModel.code].exhauster * this._selectedFanViewTable.qty / this._lowerLarge;
    //SAFETY=(EXHAUSTER(EXHAUSTERCODE)*QTY)/LOWERLARGE
    this._selectedFanViewTable.gva = this._getRoundToOneDecimalPlaces((this.fansData[this._exhausterViewModel.code].exhauster * this._selectedFanViewTable.qty - this._lowerSmall) / 1000);
    //GVA=((EXHAUSTER(EXHAUSTERCODE)*QTY)-LOWERSMALL)/1000
    this._selectedFanViewTable.safety = Math.round(this._selectedFanViewTable.safety * 100 - 100);
    this._selectedFanViewTable.model = this._fansTable[this._exhausterViewModel.code].model;
  }

  private onCalculateLargestSmallest() {
    for (let j = 0; j < this.airflowData.length; j++) {
      if (this.airflowData[j].kcfm !== -1) {
        this._smallest = j;
        this._largest = j;

        for (let i = j + 1; i < this.airflowData.length; i++) {
          if (this.airflowData[i].kcfm < this.airflowData[this._smallest].kcfm && this.airflowData[i].kcfm !== -1) {
            this._smallest = i;
          } else if (this.airflowData[i].kcfm > this.airflowData[this._largest].kcfm && this.airflowData[i].kcfm !== -1) {
            this._largest = i;
          }
        }

        break;
      }
    }
  }

  private onCalculateLowerLargeLowerSmall() {
    this._lowerLarge = Math.round(this.airflowData[this._largest].kcfm * this.numberFans * 1000);
    this._lowerSmall = Math.round(this.airflowData[this._smallest].kcfm * this.numberFans * 1000);
    //LOWERLARGE=INT(KCFM(LARGEST)*NUMBERFANS*1000+.5): LOWERSMALL=INT(KCFM(SMALLEST)*NUMBERFANS*1000+.5)
  }

  private onCalculateRecommendedFan() {
    let choice1 = 0;
    let choice2 = 1;
    //IF PITCH>0 THEN CHOICE1=10:CHOICE2=11 ELSE CHOICE1=7:CHOICE2=9

    this.onChangeSelectedFan(choice1);
    //I=CHOICE1: EXHAUSTERCODE=CHOICE1
    //QTY=CINT((LOWERLARGE*1.5)/(EXHAUSTER(I))+.49): IF QTY<1 THEN QTY=1
    //SAFETY=(EXHAUSTER(I)*QTY)/LOWERLARGE
    //GVA=((EXHAUSTER(I)*QTY)-LOWERSMALL)/1000

    let codeOld = this._selectedFanViewTable.code;
    let codeNew = choice1 + 1;
    let safetyOld = this._selectedFanViewTable.safety;
    let safetyNew = 0;
    for (let i = choice1 + 1; i <= choice2; i++) {
      this.onChangeSelectedFan(i);
      safetyNew = this._selectedFanViewTable.safety;
      if (safetyNew < safetyOld) {
        codeOld = codeNew;
        safetyOld = safetyNew;
      }
    }
    this.onChangeSelectedFan(codeOld);
    //FOR I=(CHOICE1+1) TO CHOICE2
    //QTY1=CINT((LOWERLARGE*1.5)/(EXHAUSTER(I))+.49): IF QTY1<1 THEN QTY1=1
    //SAFETY1=(EXHAUSTER(I)*QTY1)/LOWERLARGE
    //GVA1=((EXHAUSTER(I)*QTY1)-LOWERSMALL)/1000
    //IF SAFETY1<SAFETY THEN SAFETY=SAFETY1:QTY=QTY1:GVA=GVA1:EXHAUSTERCODE=I
  }

  onChangeSelectedFan(fanCode: number = undefined) {
    if (fanCode != undefined) {
      this._selectedFan.code = fanCode;
    }

    this._selectedFanViewTable.code = this._selectedFan.code;
    this._selectedFanViewTable.qty = Math.round(this._lowerLarge * 1.5 / this.fansData[this._selectedFan.code].exhauster + 0.49);
    if (this._selectedFanViewTable.qty < 1) this._selectedFanViewTable.qty = 1;
    //QTY=CINT((LOWERLARGE*1.5)/(EXHAUSTER(EXHAUSTERCODE))+.49): IF QTY<1 THEN QTY=1
    this._selectedFanViewTable.safety = this.fansData[this._selectedFan.code].exhauster * this._selectedFanViewTable.qty / this._lowerLarge;
    //SAFETY=(EXHAUSTER(EXHAUSTERCODE)*QTY)/LOWERLARGE
    this._selectedFanViewTable.gva = this._getRoundToOneDecimalPlaces((this.fansData[this._selectedFan.code].exhauster * this._selectedFanViewTable.qty - this._lowerSmall) / 1000);
    //GVA=((EXHAUSTER(EXHAUSTERCODE)*QTY)-LOWERSMALL)/1000
    this._selectedFanViewTable.safety = Math.round(this._selectedFanViewTable.safety * 100 - 100);
    //GVA=GVA+.05:SAFETY=INT(SAFETY*100-100+.5)
    this._selectedFanViewTable.model = this._fansTable[this._selectedFan.code].model;

    this._exhausterViewModel.code = this._selectedFanViewTable.code
    this._exhausterViewModel.qty = this._selectedFanViewTable.qty;

    this.changeExhausterModel();
  }

  onCalculateExhausters() {
    for (let i = 0; i < this.fansData.length; i++) {
      let qty1 = this._getRoundToZeroDecimalPlaces(this._lowerLarge * 1.3 / this.fansData[i].exhauster);
      //QTY1=CINT((LOWERLARGE*1.3)/(EXHAUSTER(I)))
      if (qty1 < 1) qty1 = 1;
      //IF QTY1<1 THEN QTY1=1
      let qty2 = this._getRoundToZeroDecimalPlaces(this._lowerLarge * 1.5 / this.fansData[i].exhauster + 0.49);
      //QTY2=CINT((LOWERLARGE*1.5)/(EXHAUSTER(I))+.49)
      if (qty2 < 1) qty2 = 2;
      //IF QTY2<1 THEN QTY2=1
      let safety1 = this.fansData[i].exhauster * qty1 / this._lowerLarge;
      safety1 = Math.round(safety1 * 100 - 100);
      // SAFETY1=(EXHAUSTER(I)*QTY1)/LOWERLARGE SAFETY1=INT((SAFETY1)*100-100+.5)
      let safety2 = this.fansData[i].exhauster * qty2 / this._lowerLarge;
      safety2 = Math.round(safety2 * 100 - 100);
      // SAFETY2=(EXHAUSTER(I)*QTY2)/LOWERLARGE SAFETY2=INT((SAFETY2)*100-100+.5)
      let gva1 = this._getRoundToOneDecimalPlaces((this.fansData[i].exhauster * qty1 - this._lowerSmall) / 1000);
      // GVA1=((EXHAUSTER(I)*QTY1)-LOWERSMALL)/1000:GVA1=GVA1+.05
      let gva2 = this._getRoundToOneDecimalPlaces((this.fansData[i].exhauster * qty2 - this._lowerSmall) / 1000);
      // GVA2=((EXHAUSTER(I)*QTY2)-LOWERSMALL)/1000:GVA2=GVA2+.05

      this._fansTable.push({
        code: i,
        fanType: this.fansData[i].fanType,
        model: this.fansData[i].name,
        qty1: qty1,
        qty2: qty2,
        safety1: safety1,
        safety2: safety2,
        mgva1: gva1,
        mgva2: gva2
      });
    }
  }

  private _getRoundToZeroDecimalPlaces(number: number): number {
    return Math.round((number + Number.EPSILON));
  }

  private _getRoundToOneDecimalPlaces(number: number): number {
    return Math.round((number + Number.EPSILON) * 10) / 10;
  }

  checkForEmptyFields() {
    if (
      !this._lowerLarge ||
      !this._lowerSmall ||
      !this.numberFans ||
      !this.airflowData
    ) {
      let message = 'This fields are empty!:'
      if (!this._lowerLarge) {
        message += '\n LowerLarge: ' + this._lowerLarge;
      }

      if (!this._lowerSmall) {
        message += '\n lowerSmall: ' + this._lowerSmall;
      }

      if (!this.numberFans) {
        message += '\n numberFans: ' + this.numberFans;
      }

      if (!this.airflowData) {
        message += '\n airflow data: ' + this.airflowData;
      }

      console.log(message);
    }
  }

  changeExhausterModel() {
    this.onChangedExhausterModel.emit();
  }
}
