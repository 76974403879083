import { AqsDuctTypeEnum, AqsRoundStorageHopperTypeEnum } from "../enums";

export const AqsRoundStorageTypeLabel = {
    [AqsRoundStorageHopperTypeEnum.Conical]: 'Conical',
    [AqsRoundStorageHopperTypeEnum.SideDraw]: 'Side Draw',
}

export const AqsRoundDuctTypeLabel = {
    [AqsDuctTypeEnum.FullRoundDuct]: 'Full Round Duct',
    [AqsDuctTypeEnum.HalfRoundDuct]: 'Half Round Duct',
}