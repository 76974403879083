import { TreeNode } from 'primeng';
import { LocationNameGetModel, LocationNameViewModel } from './locations/location.model';
import { KtxNotificationFilterNodeTypeEnum } from '../enums/ktx-notification-filter-type.enum';
import { NotificationFilterTreeNodeData } from './notifications.model';
import { BtxNotificationFilterNodeTypeEnum } from '../enums';
import { CommonFunctions } from '../../common';

export class CompanyViewModel {
    id: number;
    name: string;
    locations: LocationNameViewModel[];

    //User behavior lock fields
    public isSomeoneUpdateCompany: boolean;

    static toKtxTreeNode(company: CompanyViewModel): TreeNode {
        const data: NotificationFilterTreeNodeData<KtxNotificationFilterNodeTypeEnum> = {
            id: company.id,
            nodeType: KtxNotificationFilterNodeTypeEnum.Company,
            locationId: null,
        };

        const companyWithLocationNodes: TreeNode = {
            label: company.name,
            data,
            selectable: true,
            children: company.locations && company.locations
                .map<TreeNode>(LocationNameViewModel.toKtxTreeNode)
                .filter(l => l.children && l.children.length > 0)
                .sort(CommonFunctions.compareTreeNodeLables)
        };

        return companyWithLocationNodes;
    }

    static toBtxTreeNode(company: CompanyViewModel): TreeNode {
        const data: NotificationFilterTreeNodeData<BtxNotificationFilterNodeTypeEnum> = {
            id: company.id,
            nodeType: BtxNotificationFilterNodeTypeEnum.Company,
            locationId: null,
        };

        const companyWithLocationNodes: TreeNode = {
            label: company.name,
            data,
            selectable: true,
            children: company.locations && company.locations
                .filter(l => l.equipments && l.equipments.length > 0)
                .map<TreeNode>(LocationNameViewModel.toBtxTreeNode)
                .sort((a, b) => a.label.localeCompare(b.label)),
        };

        return companyWithLocationNodes;
    }
}

export class CompanyGetModel {
    id: number;
    name: string;
    locations: LocationNameGetModel[];

    public static toViewModel(getModel: CompanyGetModel): CompanyViewModel {
        const viewModel = new CompanyViewModel();

        viewModel.id = getModel.id;
        viewModel.name = getModel.name;

        if (getModel.locations) {
            viewModel.locations = getModel.locations.map(LocationNameGetModel.toViewModel);
        }

        return viewModel;
    }
}

export class CompanyPostModel {
    id: number;
    name: string;
}
