import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceFileLogInfoViewModel, DeviceLogInfoViewModel } from '../../models';

import { ErrorModel, HttpService } from './../../../common';
import { ApiRoutes } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class DeviceLogApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router) { }

  public async getLogsInfoList(deviceId: string): Promise<DeviceLogInfoViewModel | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData(deviceId);
      }

      const data = await this._httpService.get(ApiRoutes.DeviceLog.getLogsInfoForDeivce, { deviceId: deviceId });
      if (data) {
        return data;
      }

    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadLogFile(deviceId: string, fileName: string): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.getArrayBuffer(ApiRoutes.DeviceLog.downloadLogFile, { deviceId: deviceId, fileName: fileName });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async downloadLogFiles(deviceId: string, fileNames: string[]): Promise<string | ErrorModel> {
    try {
      const result = await this._httpService.postArrayBuffer(ApiRoutes.DeviceLog.downloadLogFiles, { deviceId: deviceId, fileNames: fileNames });
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  public async addLogLinkToDeviceTwin(deviceId: string): Promise<boolean | ErrorModel> {
    try {
      const result = await this._httpService.post(ApiRoutes.DeviceLog.addLogLinkToDeviceTwin,  deviceId );
      return result;
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  private getFakeData(deviceId: string): DeviceLogInfoViewModel {
    let fake: DeviceFileLogInfoViewModel[] = [];
    for (let i = 0; i < 100; i++) {
      fake.push({ createdDate: new Date(), lastModifiedDate: new Date(), fileNameWithoutDeviceId: `_log_file_${i}.txt`, fileName: `log_file_${i}.txt`, fullFileName: `log_file_${i}.txt`,size:0, sizeLabel:`${i*10} MB` });
    }
    let fakeData = { isTwinHasLogLink: true, deviceFileLogInfo: fake } as DeviceLogInfoViewModel;
    return fakeData;
  }
}
