import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { CompanyAdminViewModel, CompanyAdminPostModel } from '../../../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CompanyAdminsDataSourceService, CompaniesDataSourceService } from '../../../services';
import { LocalStorageService, UserRoleEnum } from '../../../../common';
import { BasePopupBehavior } from '../common';

@Component({
  selector: 'greensleeves-company-admin-edit-popup',
  templateUrl: './company-admin-edit-popup.component.html',
  styles: []
})
export class CompanyAdminEditPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked {
  _isHidden = true;
  _submitted = false;

  _companyAdmin: CompanyAdminViewModel;
  _companyAdminForm: FormGroup;

  _companies: { label: string, value: number }[];
  private _companiesSubscription: Subscription;

  _role: UserRoleEnum;

  get _isServiceUser() {
    return this._role === UserRoleEnum.ServiceUser;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _companyAdminsService: CompanyAdminsDataSourceService,
    private _companiesService: CompaniesDataSourceService,
    private _localStorageService: LocalStorageService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this._isServiceUser) {
      this._companiesService.get();
    }
    this._companiesSubscription = this._companiesService.companies$.subscribe(data => {
      if (data) {
        this._companies = data.map(x => ({ label: x.name, value: x.id }));
      }
    });

    const loginInfo = this._localStorageService.getLoginInfo();
    this._role = loginInfo ? loginInfo.role : null;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._companiesSubscription && this._companiesSubscription.unsubscribe();
    this._companiesSubscription = null;
  }

  ngAfterViewChecked() {
    super.ngAfterViewChecked();
  }

  public show(companyAdmin: CompanyAdminViewModel) {
    this._companyAdmin = companyAdmin;
    this._companyAdminForm = this._formBuilder.group({
      firstName: [
        this._companyAdmin.firstName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ])
      ],
      lastName: [
        this._companyAdmin.lastName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ])
      ],
      ...(this._isServiceUser ? {
        companyId: [
          this._companyAdmin.companyId,
          Validators.compose([
            Validators.required
          ])
        ],
      } : {})
    });
    this._isHidden = false;
  }

  async onSubmit() {
    this._submitted = true;
    if (this._companyAdminForm.invalid) {
      return;
    }

    try {
      let value = {
        ...this._companyAdminForm.value,
        id: this._companyAdmin.id,
        ...(this._isServiceUser ? {
          companyName: this._companies.find(c => c.value === this._companyAdminForm.value.companyId).label
        } : {})
      } as CompanyAdminPostModel;
      const result = await this._companyAdminsService.edit(value);
      if (result) {
        if (this._isServiceUser) {
          this._companyAdminsService.get(null)
        }
        value = {
          ...this._companyAdmin,
          ...value,
        };
        this._companyAdminsService.markCompanyAdminForOthersAsUpdated(<CompanyAdminViewModel>value, true);

        this._isHidden = true;
        this._submitted = false;
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  async onClose() {
    this._companyAdminsService.markCompanyAdminForOthersAsUpdated(this._companyAdmin, false);
    this._submitted = false;
    this._companyAdminForm.reset();
  }
}
