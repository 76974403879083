export enum TutorialMenuEnum {
    UserManagement = 0,
    EmailManagement = 1,
    Locations = 2,
    BtxLiveData = 3,
    BtxHistoricalData = 4,
    BtxNotifications = 5,
    KtxCurrentData = 6,
    KtxHistoricalData = 7,
    KtxNotifications = 8,
    ItxLiveData = 9,
    ItxAlerts = 10,
    ItxReports = 11,

    None = 255,
}

export enum TutorialParagraphEnum {
    None = -1,
    AddCompanyAdmin,
    AddCompanyUser,
    AddVendor,
    EmailServiceOverview,
    AddNewEmailService,
    ViewLocationData,
    BtxLiveDataLocationView,
    BtxLiveDataSearchEquipment,
    BtxLiveDataEquipmentDetails,
    BtxLiveDataExportReport,
    BtxHistoricalDataSetupPlot,
    BtxHistoricalDataExportReport,
    BtxNotificationViewActiveAlerts,
    BtxNotificationViewHistoryAlerts,
    BtxNotificationExport,
    KtxCurrentDataView,
    KtxCurrentDataSetSchedule,
    KtxCurrentDataSetTemperature,
    KtxCurrentDataExport,
    KtxHistoricalDataView,
    KtxHistoricalDataExport,
    KtxNotificationViewActiveAlerts,
    KtxNotificationViewHistoicalAlerts,
    KtxNotificationExport,
    ItxLiveDataStorageView,
    ItxLiveDataSearch,
    ItxLiveDataProfile,
    ItxLiveDataEditStorage,
    ItxLiveDataRadarView,
    ItxLiveDataExport,
    ItxNotificationViewActiveAlerts,
    ItxNotificationViewHistoricalAlerts,
    ItxNotificationExport,
    ItxReportSetupView,
    ItxReportExport,
}