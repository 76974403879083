import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'greensleeves-aqs-computation-sheet-resave-popup',
  templateUrl: './aqs-computation-sheet-resave-popup.component.html',
  styles: []
})
export class AqsComputationSheetResavePopupComponent implements OnInit {

  constructor() { }

  @Output() saveMethod = new EventEmitter();
  @Output() saveAsNewMethod = new EventEmitter();

  _saveTitle: string = '';
  _isHidden = true;

  ngOnInit() {
  }

  save() {
    this.saveMethod.emit();
    this.onCancel();
  }

  saveAsNew() {
    this.saveAsNewMethod.emit();
    this.onCancel();
  }

  public showConfirmPopup(title: string = 'Save Computation Sheet') {
    this._saveTitle = title;
    this._isHidden = false;
  }

  public onCancel() {
    this._isHidden = true;
  }
}
