import { Component, OnInit, ViewChild } from '@angular/core';

import { CompaniesDataSourceService, LocationsBtxDataSourceService } from '../../services';
import { BtxLocationEditPopupComponent } from '../popups';
import { LocationBtxViewModel } from '../../models';

@Component({
  selector: 'greensleeves-btx-tab',
  templateUrl: './btx-tab.component.html'
})
export class BtxTabComponent implements OnInit {
  @ViewChild(BtxLocationEditPopupComponent, { read: false, static: false })
  private _editModal: BtxLocationEditPopupComponent;
  
  constructor(
    private _companiesService: CompaniesDataSourceService,
    private _locationsBtxDataSourceService: LocationsBtxDataSourceService,
  ) {}

  ngOnInit() {
    this._companiesService.get();
  }

  async onEdit(location?: LocationBtxViewModel) {
    if (location) {
      let locationEquipment = await this._locationsBtxDataSourceService.getEquipment(location.deviceId);
      locationEquipment = {
        ...location,
        ...locationEquipment
      }
      
      this._editModal.show(locationEquipment);
    } else {
      this._editModal.show();
    }
  }
}
