import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { TreeNode } from 'primeng/api';
import { Calendar } from 'primeng/calendar';

import {
  CompanyViewModel,
  BtxEventByPeriodPostModel,
  BtxEventGetModel,
  BtxEventsRequestModel,
  BtxEventViewModel,
} from '@models';
import {
  EventDatasourceService,
  LocationsBtxDataSourceService,
  RoleService,
} from '@services';
import {
  BtxEventTypesIconClass,
  BtxEventTypesLabel,
  EnglishDateLocale,
  DefaultDateRangeLabel,
  CalendarDefaultValues,
} from '../../constants';
import {
  ColumnTypeEnum,
  BtxEventTypeEnum,
  DefaultDateRangeEnum,
  BtxNotificationFilterNodeTypeEnum,
  TutorialMenuEnum,
  ProjectEnum
} from '../../enums';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserRoleEnum } from './../../../common';
import { TutorialPopupComponent, ExportNotificationPopupComponent, BtxEquipmentDetailsPopupComponent } from '../popups';

@Component({
  selector: 'greensleeves-btx-notifications-tab',
  templateUrl: './btx-notifications-tab.component.html',
})
export class BtxNotificationsTabComponent implements OnInit, OnDestroy {
  static ALERT_TYPE = 'alertType';
  static SORT_ORDER_DEFAULT = 1;
  static EVENTS_PER_PAGE = 11;

  @ViewChild('calendar', { static: false })
  private calendar: Calendar;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  @ViewChild(ExportNotificationPopupComponent, { read: false, static: false })
  private exportPopup: ExportNotificationPopupComponent;

  @ViewChild(BtxEquipmentDetailsPopupComponent, { read: false, static: false })
  private equipmentDetailsPopup: BtxEquipmentDetailsPopupComponent;

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = BtxNotificationsTabComponent.ALERT_TYPE;
  _sortOrder = BtxNotificationsTabComponent.SORT_ORDER_DEFAULT;
  _gridData: BtxEventViewModel[];
  _ps: PerfectScrollbar;
  _alertTypes: { label: string; value: BtxEventTypeEnum }[] = [];
  _isOpenFilterList: boolean = false;
  _totalAlertsCount: number;
  _eventFilter: BtxEventByPeriodPostModel = new BtxEventByPeriodPostModel(
    BtxNotificationsTabComponent.EVENTS_PER_PAGE
  );
  _btxLocationNodes: TreeNode[] = [];
  _btxLocationNodesSelected: TreeNode[];
  _defaultDateRange: { label: string; value: DefaultDateRangeEnum }[] = [];
  _reportPeriod: FormGroup;
  _rangeDate: Date[] = [];
  _dataLocale: EnglishDateLocale = new EnglishDateLocale();
  _selectedDefaultDateRange: DefaultDateRangeEnum = null;
  _loading: boolean = false;
  _loadMore: boolean = false;

  private defaultDateRangePreviousState: DefaultDateRangeEnum = null;
  private subscriptions: Subscription[] = [];

  _columnsView = [
    {
      header: 'First Time:',
      columnType: ColumnTypeEnum.Date,
      dataField: 'firstNotificationTimeStamp',
    },
    {
      header: 'Last Time:',
      columnType: ColumnTypeEnum.Date,
      dataField: 'lastNotificationTimeStamp',
    },
    {
      header: 'Alert type:',
      columnType: ColumnTypeEnum.AlertIcon,
      dataField: 'btxAlertType',
    },
    {
      header: 'Message:',
      columnType: ColumnTypeEnum.AlertTitle,
      dataField: 'title',
    },
  ];

  get _startDate() {
    return this._rangeDate[0];
  }
  get _endDate() {
    return !this._rangeDate[1] ? this._rangeDate[0] : this._rangeDate[1];
  }

  get _isActiveAlert() {
    return !this._eventFilter.dateTimeFrom && !this._eventFilter.dateTimeTo;
  }

  get _isCompanyUsers(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser);
  }

  get getDataPickterYearRange(): string {
    const dateNow = new Date();
    return `${dateNow.getFullYear() - 3}:${dateNow.getFullYear()}`;
  }

  constructor(
    private _el: ElementRef,
    private _zone: NgZone,
    private _eventDatasourceService: EventDatasourceService,
    private _btxLocationService: LocationsBtxDataSourceService,
    private _formBuilder: FormBuilder,
    private _roleService: RoleService,
  ) { }

  ngOnInit() {
    this.setSubscriptions();
    this.reinitScrollBar(0);
    this.resetCalendar();

    for (const [key, value] of Object.entries(BtxEventTypesLabel)) {
      this._alertTypes.push({ label: value, value: Number(key) });
    }

    for (const [key, value] of Object.entries(DefaultDateRangeLabel)) {
      this._defaultDateRange.push({ label: value, value: Number(key) });
    }

    this._reportPeriod = this._formBuilder.group({
      selectedPeriod: [this._rangeDate],
      dateRange: [],
      startPeriod: [this._startDate],
      endOfPeriod: [this._endDate],
    });

    this.onUpdateEventsByFilter();
  }

  ngOnDestroy() {
    this._ps && this._ps.destroy();
    this._ps = null;

    this.clearSubscriptions();
  }

  ngAfterViewInit() {
    this.reinitScrollBar(0);
  }

  setSubscriptions(): void {
    const companiesSubscription =
      this._btxLocationService.companiesWithLocations$.subscribe(
        (companies) => {
          this._btxLocationNodes = this.initCompanyWithLocationsNodes(companies);
        }
      );

    const withEquipment = true;
    this._btxLocationService.getCompaniesWithLocations(withEquipment);

    this.subscriptions.push(companiesSubscription);
  }

  clearSubscriptions(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = null;
  }

  getAlertClassByType(alert: BtxEventTypeEnum) {
    return BtxEventTypesIconClass[alert];
  }

  getAlertLabelByType(alert: BtxEventTypeEnum) {
    return BtxEventTypesLabel[alert];
  }

  openFilterList() {
    this._isOpenFilterList = !this._isOpenFilterList;
  }

  async onUpdateEventsByFilter() {
    this._loading = true;
    this._eventFilter.currentPage = 1;
    let result = (await this._eventDatasourceService.getBtxEventsByFilter(
      this._eventFilter
    )) as BtxEventsRequestModel;
    if (result) {
      this._gridData = result.events.map((i) =>
        BtxEventGetModel.toViewModel(i)
      );
      this._totalAlertsCount = result.totalCount;
    }
    this._loading = false;
  }

  async getMoreEvents() {
    this._loadMore = true;
    this._eventFilter.currentPage++;
    let result = (await this._eventDatasourceService.getBtxEventsByFilter(
      this._eventFilter
    )) as BtxEventsRequestModel;
    if (result) {
      this._gridData.push(
        ...result.events.map((i) => BtxEventGetModel.toViewModel(i))
      );
    }
    this._loadMore = false;
  }

  onClickLocationFilter() {
    this._eventFilter.equipmentIds = [];
    this._eventFilter.locationIds = [];
    this._btxLocationNodesSelected.forEach((i) => {
      switch (i.data.nodeType) {
        case BtxNotificationFilterNodeTypeEnum.Location:
          this._eventFilter.locationIds.push(i.data.id);
          break;
        case BtxNotificationFilterNodeTypeEnum.Equipment:
          this._eventFilter.equipmentIds.push(i.data.id);
          break;
      }
    });

    this.onUpdateEventsByFilter();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  setFastDate(fastRange: DefaultDateRangeEnum) {
    let endDate = new Date();
    let startDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    this._rangeDate = [];
    switch (fastRange) {
      case DefaultDateRangeEnum.Week:
        startDate.setDate(
          startDate.getDate() - CalendarDefaultValues.defaultOneWeekDays
        );
        break;
      case DefaultDateRangeEnum.Month:
        startDate.setDate(
          startDate.getDate() - CalendarDefaultValues.defaultOneMonthDays
        );
        break;
    }
    this._selectedDefaultDateRange = fastRange;
    this._rangeDate.push(startDate);
    this._rangeDate.push(endDate);
  }

  getCurrentEvents() {
    this.resetCalendar();
    this._selectedDefaultDateRange = null;
    this._eventFilter.dateTimeFrom = null;
    this._eventFilter.dateTimeTo = null;
    this.onUpdateEventsByFilter();
  }

  onApplyDateRangeFilter() {
    if (this._rangeDate == undefined || this._rangeDate.length == 0) {
      this.resetCalendar();
    }
    if (this._rangeDate[1] == undefined || this._rangeDate[1] == null) {
      this._rangeDate[1] = this._rangeDate[0];
    }
    this._eventFilter.dateTimeFrom =
      (this._rangeDate[0].getTime() /
        CalendarDefaultValues.defaultOneSecondMilliseconds) |
      0;
    this._eventFilter.dateTimeTo =
      (this._rangeDate[1].getTime() /
        CalendarDefaultValues.defaultOneSecondMilliseconds) |
      0;
    this.onUpdateEventsByFilter();
    this.defaultDateRangePreviousState = this._selectedDefaultDateRange;
    this.calendar.toggle();
  }

  onSeclectCalendar() {
    this._selectedDefaultDateRange = null;
  }

  onCloseCalendar() {
    if (
      this._eventFilter.dateTimeFrom == null &&
      this._eventFilter.dateTimeTo == null
    ) {
      this.resetCalendar();
      return;
    }
    if (this.defaultDateRangePreviousState != null) {
      this.setFastDate(this.defaultDateRangePreviousState);
      return;
    }
    this._rangeDate = [];
    this._rangeDate.push(
      new Date(
        this._eventFilter.dateTimeFrom *
        CalendarDefaultValues.defaultOneSecondMilliseconds
      )
    );
    this._rangeDate.push(
      new Date(
        this._eventFilter.dateTimeTo *
        CalendarDefaultValues.defaultOneSecondMilliseconds
      )
    );
  }

  showExport() {
    const locationNodes: TreeNode[] = [];
    const locations = this.initCompanyWithLocationsNodes(this._btxLocationService.companiesWithLocations$.getValue());
    locations.forEach(x => {
      if (x.children.length > 0) {
        locationNodes.push(...x.children);
      }
    }
    );
    this.exportPopup.onShow(locationNodes, ProjectEnum.Btx);
  }

  onClickEquipmentDetails(equipmentId: number) {
    this.equipmentDetailsPopup.ShowWithLoad(equipmentId);
  }

  reinitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector(
            '.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body'
          );
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20,
          });
        } catch (error) { }
      });
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  private resetCalendar() {
    this.defaultDateRangePreviousState = null;
    this._selectedDefaultDateRange = null;
    this._rangeDate = [];
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this._rangeDate.push(today);
    this._rangeDate.push(today);
  }

  private initCompanyWithLocationsNodes(companies: CompanyViewModel[]): TreeNode[] {
    const companyWithLocationNodes =
      companies &&
      companies
        .map(CompanyViewModel.toBtxTreeNode)
        .sort((a, b) => a.label.localeCompare(b.label));

    return companyWithLocationNodes;
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.BtxNotifications);
  }
}
