export enum UpdateProjectStatusEnum {
    Continue = 100,
    Ok = 200,
    ClientError = 400,
    Forbidden = 403,
    NotAcceptable = 406,
    Conflict = 409,
    InternalError = 500,
    ServiceUnavailable = 503,
    Offline = 600,
}

export enum UpdateProjectStateEnum {
    Unknown = 0,
    Ok = 1,
    Updating = 2,
    Failed = 3,
    Starting = 4,
}