import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RoutePaths, AccountService, PasswordRecoverModel } from '../common';
import { emailValidator } from '../common/validators';

//TODO
//Check invalid messages
@Component({
  selector: 'greensleeves-password-recover',
  templateUrl: './password-recover.component.html'
})

export class PasswordRecoverComponent implements OnInit {
  _passwordRecoverFormGroup: FormGroup;
  _isSubmit = false;
  _isShow = false;
  _userEmail: string;
  _privacyLink = RoutePaths.PRIVACY;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _accountService: AccountService
  ) { }

  ngOnInit() {
    this._passwordRecoverFormGroup = this.formBuilder.group({
      userEmail: [
        '',
        Validators.compose(
        [Validators.required,
        Validators.email,
        emailValidator()
      ])]
    });
  }

  get f() { return this._passwordRecoverFormGroup.controls; }

  onSubmit() {
    this._isSubmit = true;
    const userEmail = this.f.userEmail.value.trim();

    if (this._passwordRecoverFormGroup.invalid) {
      return;
    }
    const passwordRecoverModel = new PasswordRecoverModel(userEmail);
    const result = this._accountService.passwordRecover(passwordRecoverModel);
    if (result != null)
    {
      this._isShow = true;
      this._userEmail = userEmail;
    }
  }
}
