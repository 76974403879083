import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LocationsBtxDataSourceService } from '../../../services';
import {
  SensorViewModel,
  EquipmentViewModel,
  LocationBtxEquipmentViewModel,
  LocationBtxEquipmentPostModel,
  LocationBtxViewModel
} from './../../../models';
import { EditSensorPopupComponent } from './edit-sensor-popup';
import { ViewSensorPopupComponent } from './view-sensor-popup';
import { AddEditEquipmentPopupComponent } from '../add-edit-equipment-popup';
import { equipmentSensorMapping, equipmentSchemes, sensorTypeLabels, sensorPositionLabels, equipmentSideLabels, SideAB } from 'src/app/main/constants';
import { EquipmentSideEnum } from '../../../enums';
import { EquipmentSensorDeletePopupComponent } from './equipment-sensor-delete-popup/equipment-sensor-delete-popup.component';
import { ValidationFunctions, ModbussAddress } from '../../common';
import { ModelsTypes } from '../../../constants';
import { EquipmentViewDataPopupComponent } from './equipment-view-data/equipment-view-data.component';

@Component({
  selector: 'greensleeves-equipment-popup',
  templateUrl: './equipment-popup.component.html',
  styles: []
})

export class EquipmentPopupComponent implements AfterViewInit, OnDestroy {
  @ViewChild(EditSensorPopupComponent, { read: false, static: false })
  private _editSensorModal: EditSensorPopupComponent;

  @ViewChild(ViewSensorPopupComponent, { read: false, static: false })
  private _viewSensorModal: ViewSensorPopupComponent;

  @ViewChild(AddEditEquipmentPopupComponent, { read: false, static: false })
  private _addEquipmentModal: AddEditEquipmentPopupComponent;

  @ViewChild(EquipmentSensorDeletePopupComponent, { read: false, static: false })
  private _equipmentSensorDeleteModal: EquipmentSensorDeletePopupComponent;

  @ViewChild(EquipmentViewDataPopupComponent, { read: false, static: false })
  private _equipmentViewDataModal: EquipmentViewDataPopupComponent;

  _isHidden = true;
  _submitted = false;

  _location: LocationBtxEquipmentViewModel;

  _companies: { label: string, value: number }[];

  _activeEquipment: EquipmentViewModel = null;
  _activeSensor: SensorViewModel = null;

  _activeAccordionTabIndex = -1;

  private _activeSensorIndex: any;
  private subscriptions: Subscription[] = [];

  private _editingEquipmentIndex?: number = null;

  _schema: Array<{ className: string, markers: Array<{ className: string, index: number, tooltip: string }> }> = [];

  constructor(
    private _locationBtxService: LocationsBtxDataSourceService,
  ) {
    this.isSensorUnique = this.isSensorUnique.bind(this);
    this.equipmentHasUniqueName = this.equipmentHasUniqueName.bind(this);
    this.isSensorAddressesUnique = this.isSensorAddressesUnique.bind(this);
    this.isEquipmentAddressesUnique = this.isEquipmentAddressesUnique.bind(this);
  }

  ngAfterViewInit() {
    let subs = this._addEquipmentModal.onSave.subscribe((emitModel: [EquipmentViewModel, boolean]) => {
      if (emitModel[1]) {
        this.onEquipmentEditSave(emitModel[0]);
      } else {
        this.onEquipmentAddSave(emitModel[0]);
      }
    });
    this.subscriptions.push(subs);

    subs = this._addEquipmentModal.onCancel.subscribe(() => this.onEquipmentAddCancel());
    this.subscriptions.push(subs);

    subs = this._addEquipmentModal.closedPopup.subscribe(() => this._locationBtxService.markLocationEquipmentsForOthersAsUpdated(this._location.id));
    this.subscriptions.push(subs);
  }

  ngAfterViewChecked() {
    this._equipmentSensorDeleteModal.equipmentDelete.subscribe(equipmentId => { this.onDeleteEquipment(equipmentId) });
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.forEach(subs => subs.unsubscribe());
    this._activeEquipment = null;
  }

  public show(locationEquipment: LocationBtxEquipmentViewModel) {
    this._location = locationEquipment;
    this.buildSchema();
    if (!this._location.equipments || !this._location.equipments.length) {
      this._addEquipmentModal.show("Add first equipment", "Add first equipment");
    } else {
      this._isHidden = false;
    }
  }

  onToggleMenu({ isOpen, index }) {
    if (isOpen) {
      this._activeAccordionTabIndex = index;
    }
  }

  onAccordionOpen(index) {
    this._activeEquipment = this._location.equipments[index];
    this.buildSchema();

    setTimeout(() => {
      let tab = document.getElementById(`${this._activeEquipment.id}`);
      if (tab) {
        let sensorWrap = document.getElementById('sensorsWrap');
        if (sensorWrap) {
          sensorWrap.style.setProperty('top', `${tab.offsetTop}px`);
        }
      }
    }, 300);
  }

  onAccordionClose() {
    this._activeEquipment = null;
    this.buildSchema();
  }

  onSensorEditClicked(e: { sensor: SensorViewModel, index: number }) {
    this._activeSensorIndex = e.index;
    this._editSensorModal.show(e.sensor);
  }

  onSensorViewClicked(sensor: SensorViewModel) {
    this._viewSensorModal.show(sensor);
  }

  async onClickSave() {
    this._submitted = true;
    const postModel = new LocationBtxEquipmentPostModel(this._location);
    const result = await this._locationBtxService.addOrUpdateEquipment(postModel);

    this._submitted = false;
    if (result) {
      this._isHidden = true;
      this._activeEquipment = null;
      this._location = null;
    }
  }

  onSensorChanged(sensor: SensorViewModel) {
    this._activeEquipment.sensors[this._activeSensorIndex] = sensor;
    this._activeSensorIndex = null;
    this._activeSensor = null;
    this.buildSchema();
  }

  onDeleteEquipment(equipmentId: number) {
    this._location.equipments = this._location.equipments.filter(equipment => equipment.id !== equipmentId);
    this._activeEquipment = null;
    this.buildSchema();
  }

  onChangeSensors(sensors: SensorViewModel[]) {
    if (!this._activeEquipment) {
      return;
    }

    this._activeEquipment.sensors = sensors
    this.buildSchema();
  }

  private sortSensors() {
    if (!this._activeEquipment) {
      return;
    }

    const sortByPosition = (a: SensorViewModel, b: SensorViewModel) => {
      const aString = `${sensorTypeLabels[a.type]}${sensorPositionLabels[a.position]}${equipmentSideLabels[a.side]}`;
      const bString = `${sensorTypeLabels[b.type]}${sensorPositionLabels[b.position]}${equipmentSideLabels[b.side]}`;
      if (aString < bString) {
        return -1;
      }
      if (aString > bString) {
        return 1;
      }
      return 0;
    };
    this._activeEquipment.sensors = this._activeEquipment.sensors.sort(sortByPosition);
  }

  onEquipmentAddSave(equipment: EquipmentViewModel) {
    if (!this._location.equipments || !this._location.equipments.length) {
      this._location.equipments = [];
    }

    this._location.equipments.push(equipment);

    if (this._location.equipments.length) {
      this._isHidden = false;
      this.buildSchema();
    }
  }

  onEquipmentEditSave(equipment: EquipmentViewModel) {
    this._location.equipments[this._editingEquipmentIndex] = equipment as EquipmentViewModel;
    this._editingEquipmentIndex = null;

    if (this._location.equipments.length) {
      this._isHidden = false;
      this.buildSchema();
    }
  }

  onEquipmentAddCancel() {
    if (!this._location.equipments || !this._location.equipments.length) {
      this._isHidden = true;
    }
  }

  onClickAddMenu() {
    this._editingEquipmentIndex = null;
    this._addEquipmentModal.show("Add new equipment", "Add");
  }

  onClickDeleteMenu(equipment: EquipmentViewModel) {
    this._equipmentSensorDeleteModal.showEquipmentView(equipment.id);
  }

  onClickEditMenu(equipment: EquipmentViewModel, index: number) {
    this._editingEquipmentIndex = index;
    this._addEquipmentModal.show("Edit equipment", "Save", equipment);
  }

  onClickViewData(equipment: EquipmentViewModel) {
    this._equipmentViewDataModal.show(equipment, this._location.id);
  }

  async onClickClose() {
    await this._locationBtxService.markLocationEquipmentsForOthersAsUpdated(this._location.id);
    this._activeEquipment = null;
    this._isHidden = true;
    this._location = null;
    this._submitted = false;
  }

  buildSchema() {
    if (!this._activeEquipment) {
      this._schema = [];
      return;
    }

    this.sortSensors();
    const mapping = equipmentSensorMapping[this._activeEquipment.type][this._activeEquipment.subType];

    let selectedSideA: EquipmentSideEnum;
    let selectedSideB: EquipmentSideEnum;

    if (this._activeEquipment.sensors && this._activeEquipment.sensors.length > 0 && (this._activeEquipment.sensors[0].side as EquipmentSideEnum === EquipmentSideEnum.North ||
      this._activeEquipment.sensors[0].side as EquipmentSideEnum === EquipmentSideEnum.South)) {
      selectedSideA = EquipmentSideEnum.South;
      selectedSideB = EquipmentSideEnum.North;
    } else {
      selectedSideA = EquipmentSideEnum.East;
      selectedSideB = EquipmentSideEnum.West;
    }


    const sides = [
      equipmentSchemes[this._activeEquipment.type][this._activeEquipment.subType][0],
      equipmentSchemes[this._activeEquipment.type][this._activeEquipment.subType][selectedSideA],
      equipmentSchemes[this._activeEquipment.type][this._activeEquipment.subType][selectedSideB]
    ];

    const shouldAddNonSided = Object.keys(sides).some(x => Number(x) as EquipmentSideEnum === EquipmentSideEnum.None);
    this._schema = Object.entries(sides)
      .filter(([side, schemaClassName]) => !!schemaClassName)
      .map(([side, schemaClassName]) => ({ side: Number(side) as EquipmentSideEnum, schemaClassName: schemaClassName as string }))
      .map(({ side, schemaClassName: className }) => {
        const markers = this._activeEquipment.sensors
          .map((sensor, index) => ({ ...sensor, index: index + 1 }))
          .filter(sensor => sensor.side === side || sensor.side ===  SideAB[side] || (shouldAddNonSided && sensor.side === EquipmentSideEnum.None))
          .map(sensor => ({
            index: sensor.index,
            tooltip: sensor.index + ' - ' + sensorPositionLabels[sensor.position] + '\n' + sensorTypeLabels[sensor.type],
            className: mapping[sensor.type][sensor.position][sensor.side]
          }))
          .filter(marker => !!marker.className);
        return {
          className,
          markers
        };
      });
  }

  isSensorUnique(sensor: SensorViewModel): boolean {
    return this._activeEquipment && !this._activeEquipment.sensors
      .filter((s, i) => this._activeSensorIndex === null || this._activeSensorIndex === undefined || i !== this._activeSensorIndex)
      .some(s => s.type === sensor.type && s.position === sensor.position && s.side === sensor.side);
  }

  equipmentHasUniqueName(equipment: EquipmentViewModel) {
    return !this._location.equipments
      .some((e, i) => (this._editingEquipmentIndex === null || this._editingEquipmentIndex !== i) && e.name === equipment.name);
  }

  isSensorAddressesUnique(sensor: SensorViewModel): ModbussAddress[] {
    const existentItems: (LocationBtxViewModel | EquipmentViewModel | SensorViewModel)[] = [this._location];
    this._location.equipments.forEach(eq => {
      existentItems.push(eq);
      eq.sensors.forEach(s => (s.id !== sensor.id) && existentItems.push(s));
    });
    sensor.modelType = ModelsTypes.sensorViewModel;
    return ValidationFunctions.checkIsAllAddressesUnique(existentItems, sensor, this._location);
  }

  isEquipmentAddressesUnique(equipment: EquipmentViewModel): ModbussAddress[] {
    const existentItems: (LocationBtxViewModel | EquipmentViewModel | SensorViewModel)[] = [this._location];
    this._location.equipments.forEach(eq => {
      if (eq.id !== equipment.id) {
        existentItems.push(eq);
      }

      eq.sensors.forEach(s => existentItems.push(s));
    });
    equipment.modelType = ModelsTypes.equipmentViewModel;
    return ValidationFunctions.checkIsAllAddressesUnique(existentItems, equipment);
  }
}
