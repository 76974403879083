import { Component, OnInit } from '@angular/core';

import { ReportDataFileFilterPost, ReportDataFilterPost, ReportLiveDataFilterPost } from '@models';
import { ReportDatasourceService } from '@services';
import { CalendarDefaultValues, ReportFileFormatLabel } from '../../../constants';
import { ReportFormatEnum, ItxStorageSortingEnum } from './../../../enums';
import { ErrorModel } from '../../../../common';

@Component({
  selector: 'greensleeves-itx-export-report-popup',
  templateUrl: './itx-export-report-popup.component.html',
  styles: []
})
export class ItxExportReportPopupComponent implements OnInit {
  _isHidden = true;
  _formateReport: { label: string, value: number }[] = [];
  _selectedFormat: ReportFormatEnum;
  _isReportRun = false;
  private storageName: string;
  private reportHistorivalFileFilter: ReportDataFileFilterPost;
  private isLiveDataReport: boolean;
  private reportLiveDataFileFilter: ReportLiveDataFilterPost = new ReportLiveDataFilterPost();

  constructor(
    private _reportDatasource: ReportDatasourceService,
  ) { }

  ngOnInit() {
  }

  public showDownloadHistoricalReport(dataFilter: ReportDataFilterPost, storageName: string) {
    this._isReportRun = false;
    this.reportHistorivalFileFilter = dataFilter as ReportDataFileFilterPost;
    this.storageName = storageName;
    this.initFormatLabels(false);
    this._isHidden = false;
  }

  public showDownloadLiveDataReport(binIds: number[], itxBinSortParameter: ItxStorageSortingEnum) {
    this.initFormatLabels(true);
    this.reportLiveDataFileFilter.itxBinIds = binIds;
    this.reportLiveDataFileFilter.itxBinSortParameter = itxBinSortParameter;
    this._isHidden = false;
  }

  onCancel() {
    this._isHidden = true;
  }

  public async download() {
    this._isReportRun = true;
    let result: any;
    if (this.isLiveDataReport) {
      this.reportLiveDataFileFilter.reportFormat = this._selectedFormat;
      this.reportLiveDataFileFilter.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    else {
      this.reportHistorivalFileFilter.reportFormat = this._selectedFormat;
      this.reportHistorivalFileFilter.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    result = this.isLiveDataReport ? await this._reportDatasource.downloadItxLiveDataReportFile(this.reportLiveDataFileFilter)
      : await this._reportDatasource.downloadItxHistoricalReportFile(this.reportHistorivalFileFilter);
      if (!(result instanceof ErrorModel)) {
      let fileName = this.isLiveDataReport ? this.generateLiveDataFileName() : this.generateHistoricalFileName();
      const url = URL.createObjectURL(result);
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      this.onCancel();
    }
    this._isReportRun = false;
  }

  private generateHistoricalFileName(): string {
    let from = new Date(this.reportHistorivalFileFilter.dateFrom * CalendarDefaultValues.defaultOneSecondMilliseconds);
    let to = new Date(this.reportHistorivalFileFilter.dateTo * CalendarDefaultValues.defaultOneSecondMilliseconds);
    let fileExtension = ReportFileFormatLabel[this.reportHistorivalFileFilter.reportFormat];
    return `${this.storageName.replace(/ /g,'_')}_${from.toLocaleDateString('en-US')}-${to.toLocaleDateString('en-US')}.${fileExtension}`;
  }

  private generateLiveDataFileName(): string {
    let date = new Date();
    let fileName = this.reportLiveDataFileFilter.reportFormat == ReportFormatEnum.png ? `LiveDataReport-${date.toLocaleDateString()}.zip`
      : `LiveDataReport-${date.toLocaleDateString()}.${ReportFileFormatLabel[this.reportLiveDataFileFilter.reportFormat]}`
    return fileName;
  }

  private initFormatLabels(isLiveData: boolean) {
    this.isLiveDataReport = isLiveData;
    this._formateReport = [];
    for (const [key, value] of Object.entries(ReportFileFormatLabel)) {
      let label=value as string;
      if (Number(key) == ReportFormatEnum.png && this.isLiveDataReport) {
        label += ' (zip)';
      }
      this._formateReport.push({ label: label, value: Number(key) })
    }
    this._selectedFormat = this._formateReport[0].value;
  }
}
