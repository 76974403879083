import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { digitsValidator, ErrorCodeEnum, ErrorModel } from '../../../../common';
import { ItxEditRadarPopupComponent } from './../itx-edit-radar-popup';
import { ItxDeleteRadarPopupComponent } from './../itx-delete-radar-popup';
import { RadarViewModel, ItxBinViewModel, RadarPostModel } from '@models';
import { ItxBinDataSourceService, LocationsItxDatasourceService } from '../../../services';
import { ItxBinTypeEnum, ItxRadarLocationEnum, ColumnTypeEnum } from '../../../enums';
import { ItxRadarLocationLabel, SizeMeasureLabel } from './../../../constants';
import { ConvertDataFunctions } from '../../../../common';

@Component({
  selector: 'greensleeves-itx-add-radars-popup',
  templateUrl: './itx-list-add-radar-popup.component.html',
  styles: []
})
export class ItxListAddRadarPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  _isHidden = true;
  _itxAddRadarForm: FormGroup;
  _radarIdAddresses: { label: string, value: number }[];
  _radarLocations: { label: string, value: ItxRadarLocationEnum }[];
  _submitted: boolean;
  _hasIdErrors: boolean;
  _hasDistanceError: boolean;
  _hasRadarLocationError: boolean;
  _hasIdAddressErrors: boolean;
  _radarIdNotUnique: boolean;
  _radarIdAddressNotUnique: boolean;
  _columnTypeEnum = ColumnTypeEnum;
  _itxBinViewModel: ItxBinViewModel;
  allRadars: RadarViewModel[];
  _sizeMeasureLabel = SizeMeasureLabel;
  onNeedToUpdateItxDeviceEnrollment = new EventEmitter<number>();

  private isNeedToUpdateLocationEnrollment: boolean = false;
  private subscriptions: Subscription[] = [];
  private radarLocationLabel = ItxRadarLocationLabel;

  @ViewChild(ItxEditRadarPopupComponent, { read: false, static: false })
  private editRadarModal: ItxEditRadarPopupComponent;

  @ViewChild(ItxDeleteRadarPopupComponent, { read: false, static: false })
  private deleteRadarModal: ItxDeleteRadarPopupComponent;

  _gridData: RadarViewModel[];
  _columnsView = [
    { header: 'Radar number:', columnType: ColumnTypeEnum.Counter },
    { header: 'Radar ID:', dataField: 'radarId', columnType: ColumnTypeEnum.Number },
    { header: 'Radar ID address:', dataField: 'radarIdAddress', columnType: ColumnTypeEnum.Number },
    { header: 'Radar location:', dataField: 'radarLocation', columnType: ColumnTypeEnum.RadarLocation },
    { header: 'Distance:', dataField: 'distanceFromBinCenter', columnType: ColumnTypeEnum.RadarDistance },
  ]
  get _isSiloOrBinTypes(): boolean {
    return this.getRequireRadarLocation();
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _itxBinDataSouceService: ItxBinDataSourceService,
    private _itxLocationService: LocationsItxDatasourceService
  ) { }

  ngOnInit() {

    this._radarLocations = [];
    for (const [key, value] of Object.entries(ItxRadarLocationLabel)) {
      this._radarLocations.push({ label: value, value: Number(key) });
    }
  }

  ngAfterViewInit() {
    let subDelete = this.deleteRadarModal.onDeletedRadar.subscribe((id: number) => {
      this.isNeedToUpdateLocationEnrollment = true;
    });

    let subNeedUpdate = this.editRadarModal.needToUpdateItxDeviceEnrollment.subscribe(() => {
      this.isNeedToUpdateLocationEnrollment = true;
    });

    let itxBins = this._itxBinDataSouceService.itxBin$.subscribe(item => {
      let radarIDAddressesBusy: number[] = [];
      item.forEach(bin => {
        radarIDAddressesBusy.push(...bin.radars.map(r => r.radarIdAddress));
        if (this._itxBinViewModel && bin.id == this._itxBinViewModel.id) {
          this._gridData = bin.radars;
          this._itxBinViewModel = bin;
          this.initForm();
        }
      })
      this._radarIdAddresses = this.getRadarIdAddressesToSelect(radarIDAddressesBusy);
    });

    this.subscriptions.push(subDelete);
    this.subscriptions.push(subNeedUpdate);
    this.subscriptions.push(itxBins);
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  onClickEdit(radar: RadarViewModel) {
    let postRadar = RadarViewModel.ToPostModel(radar);
    postRadar.locationId = this._itxBinViewModel.locationId;
    this.editRadarModal.show(postRadar, this._itxBinViewModel, this._radarIdAddresses, this._isSiloOrBinTypes);
  }

  onClickDelete(radar: RadarViewModel) {
    this.deleteRadarModal.show(radar);
  }

  async onSubmit() {
    this._submitted = true;
    if (this._itxAddRadarForm.invalid) {
      if (this._itxAddRadarForm.controls.radarId.invalid)
        this._hasIdErrors = true;
      if (this._itxAddRadarForm.controls.radarIdAddress.invalid)
        this._hasIdAddressErrors = true;
      if (this._itxAddRadarForm.controls.radarLocation.invalid)
        this._hasRadarLocationError = true;
      if (this._itxAddRadarForm.controls.distanceFromBinCenter.invalid)
        this._hasDistanceError = true;
      return;
    }

    let distance: number = null;
    if (this._itxBinViewModel.radars.some(r => r.radarLocation != null) || this._itxAddRadarForm.controls.radarLocation.value != null) {
      distance = parseFloat(this._itxAddRadarForm.controls.distanceFromBinCenter.value);
      if (isNaN(distance) || (distance < 0 || distance > this._itxBinViewModel.width / 2)) {
        this._hasDistanceError = true;
        this._itxAddRadarForm.controls.distanceFromBinCenter.setErrors({ digitsValidator: true });
        return;
      }
    }

    const value = { ...this._itxAddRadarForm.value, distanceFromBinCenter: distance, itxBinId: this._itxBinViewModel.id, locationId: this._itxBinViewModel.locationId, id: 0 } as RadarPostModel

    if (!this.checkRadarUniquenessPerLocation(value)) {
      return;
    }

    if (value.distanceFromBinCenter != null) {
      value.distanceFromBinCenter = ConvertDataFunctions.convertSizeToFt(value.distanceFromBinCenter, this._itxBinViewModel.sizeMeasure);
    }

    await this._itxBinDataSouceService.addRadar(value).then((response: RadarViewModel) => {
      if (response instanceof ErrorModel && response.code === ErrorCodeEnum.ItxRadarAlreadyExixtsInThisLocation) {
        this._radarIdAddressNotUnique = true;
        this._radarIdNotUnique = true;
      }
      else {
        this._submitted = false;
        this.isNeedToUpdateLocationEnrollment = true;
        this._itxAddRadarForm.reset();
      }
    });
  }

  public show(itxBinViewModel: ItxBinViewModel) {
    this._isHidden = false;
    this._itxBinViewModel = itxBinViewModel;
    this._gridData = itxBinViewModel.radars;
    this.isNeedToUpdateLocationEnrollment = false;
    this.initForm();
  }

  onCancel() {
    this._itxBinDataSouceService.markItxBinRadarForOthersAsInUpdate(this._itxBinViewModel.id, false);
    this._isHidden = true;
    this._submitted = false;
    this.clearErrors();
    this._itxAddRadarForm.reset();
    if (this.isNeedToUpdateLocationEnrollment) {
      let location = this._itxLocationService.locationsItx$.getValue().find(l => l.id == this._itxBinViewModel.locationId);
      if (location && location.isInitialized) {
        this._itxLocationService.initializeDeviceConfiguration(this._itxBinViewModel.locationId);
      }
    }
  }

  getRadarDistanceByBinSizeWithMeasureLabel(distance: number): string {
    if (distance != null) {
      return `${ConvertDataFunctions.convertSizeFromFt(distance, this._itxBinViewModel.sizeMeasure)} ${this._sizeMeasureLabel[this._itxBinViewModel.sizeMeasure]}`;
    }
    return '-';
  }

  getRadarLocationLabel(radarLocation: ItxRadarLocationEnum): string {
    if (radarLocation != null) {
      return this.radarLocationLabel[radarLocation];
    }
    return '-';
  }

  onChangeRadarLocationValues() {
    this._itxAddRadarForm.controls.distanceFromBinCenter.clearValidators();
    this._itxAddRadarForm.controls.distanceFromBinCenter.setValidators(Validators.compose([
      Validators.required,]));
    this._itxAddRadarForm.controls.distanceFromBinCenter.updateValueAndValidity();

    this._itxAddRadarForm.controls.radarLocation.clearValidators();
    this._itxAddRadarForm.controls.radarLocation.setValidators(Validators.compose([Validators.required]));
    this._itxAddRadarForm.controls.radarLocation.updateValueAndValidity();

    if (this._itxAddRadarForm.controls.distanceFromBinCenter.value == '') {
      this._itxAddRadarForm.controls.distanceFromBinCenter.setValue(null)
    };

    if (this._itxAddRadarForm.controls.radarLocation.value == null && this._itxAddRadarForm.controls.distanceFromBinCenter.value == null) {
      this._itxAddRadarForm.controls.radarLocation.clearValidators();
      this._itxAddRadarForm.controls.radarLocation.updateValueAndValidity();
      this._itxAddRadarForm.controls.distanceFromBinCenter.clearValidators();
      this._itxAddRadarForm.controls.distanceFromBinCenter.updateValueAndValidity();
    }
  }

  private checkRadarUniquenessPerLocation(radar: RadarPostModel): boolean {
    let _allBins = this._itxBinDataSouceService.itxBin$.getValue();
    this.allRadars = _allBins.map(b => b.radars).reduce((a, b) => a.concat(b));
    if (this.allRadars.findIndex(r => r.radarId == radar.radarId) > -1) {
      this._hasIdErrors = true;
      this._radarIdNotUnique = true;
    }
    if (this.allRadars.findIndex(r => r.radarIdAddress == radar.radarIdAddress) > -1) {
      this._hasIdAddressErrors = true;
      this._radarIdAddressNotUnique = true;
    }
    if (this._radarIdNotUnique || this._radarIdAddressNotUnique) {
      return false;
    }
    return true;
  }

  private getRadarIdAddressesToSelect(busyAddresses: number[]): { label: string, value: number }[] {
    let radarIDAddresses: { label: string, value: number }[] = [];
    let currentRadarIDAddress: number = 50;
    while (radarIDAddresses.length < 5) {
      if (busyAddresses.indexOf(currentRadarIDAddress) == -1) {
        radarIDAddresses.push({ label: currentRadarIDAddress.toString(), value: currentRadarIDAddress });
      }
      currentRadarIDAddress += 100;
    }
    return radarIDAddresses;
  }

  private getRequireRadarLocation(): boolean {
    if (this._itxBinViewModel) {
      let binType = this._itxBinViewModel.binType;
      return binType == ItxBinTypeEnum.BinConical || binType == ItxBinTypeEnum.BinFlat || binType == ItxBinTypeEnum.BinSloped ||
        binType == ItxBinTypeEnum.SiloConical || binType == ItxBinTypeEnum.SiloFlat || binType == ItxBinTypeEnum.SiloSloped;
    }
    return false
  }

  private initForm() {
    this._itxAddRadarForm = this._formBuilder.group({
      radarIdAddress: [null, Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(65535),
        digitsValidator
      ])],
      radarId: [null, Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(65535),
        digitsValidator
      ])],
      radarLocation: [null],
      distanceFromBinCenter: [null],
    });
    this._itxAddRadarForm.controls.radarId.valueChanges.subscribe(() => {
      this._hasIdErrors = false;
      this._radarIdNotUnique = false;
    })
    this._itxAddRadarForm.controls.radarIdAddress.valueChanges.subscribe(() => {
      this._hasIdAddressErrors = false;
      this._radarIdAddressNotUnique = false;
    });
    this._itxAddRadarForm.controls.distanceFromBinCenter.valueChanges.subscribe(() => {
      this._hasDistanceError = false;
    });
    this._itxAddRadarForm.controls.radarLocation.valueChanges.subscribe(() => {
      this._hasRadarLocationError = false;
    });
    if (this._itxBinViewModel.radars.some(r => r.radarLocation != null)) {
      this._itxAddRadarForm.controls.radarLocation.setValidators(Validators.compose([Validators.required]));
      this._itxAddRadarForm.controls.distanceFromBinCenter.setValidators(Validators.compose([Validators.required,]));
    }
  }

  private clearErrors() {
    this._hasIdErrors = false;
    this._hasIdAddressErrors = false;
    this._hasRadarLocationError = false;
    this._hasDistanceError = false;
  }
}
