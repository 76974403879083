import { PartInfoModel, PartInfoAddEditModel, PartInfoResponseModel, AqsCoverSheetModel } from "@models";
import { ReportFormatEnum } from "../../../enums";

export class ComputationSheetModel {
    public id: number;
    public customerCompany: CustomerCompany;
    public customerStorageLocation: CustomerStorageLocation;
    public description: ComputationSheetDescription;
    public crewTravelNetPrice: number;
    public freightNetPrice: number;
    public materialDiscountPercentage: number;
    public laborDiscountPercentage: number;
    public materialTaxPercentage: number;
    public laborTaxPercentage: number;
    public crewTravelTaxPercentage: number;
    public freightTaxPercentage: number;
    public preparedBy: string;
    public ductTable: PartInfoModel[];
    public fansTable: PartInfoModel[];
    public manifoldTable: PartInfoModel[];
    public miscellaneousEquipmentTable: PartInfoModel[];
    public totalFreightWeight: number;
    public totalPriceAllMaterials: number;
    public totalPriceAllLabors: number;
    public materialPriceWithDiscountAndTax: number;
    public laborPriceWithDiscountAndTax: number;
    public crewTravelNetWithTax: number;
    public freightNetWithTax: number;
    public grandTotal: number;
    public leadTime: string;
}

export class ComputationSheetAddEditModel {
    public id: number;
    public customerCompany: CustomerCompany;
    public customerStorageLocation: CustomerStorageLocation;
    public description: ComputationSheetDescription;
    public crewTravelNetPrice: number;
    public freightNetPrice: number;
    public materialDiscountPercentage: number;
    public laborDiscountPercentage: number;
    public materialTaxPercentage: number;
    public laborTaxPercentage: number;
    public crewTravelTaxPercentage: number;
    public freightTaxPercentage: number;
    public preparedBy: string;
    public partInfos: PartInfoAddEditModel[];
    public partsInfoIdsForDelete: number[];
    public leadTime: string;
}

export class ComputationSheetResponseModel {
    public id: number;
    public customerCompany: CustomerCompany;
    public customerStorageLocation: CustomerStorageLocation;
    public description: ComputationSheetDescription;
    public crewTravelNetPrice: number;
    public freightNetPrice: number;
    public materialDiscountPercentage: number;
    public laborDiscountPercentage: number;
    public materialTaxPercentage: number;
    public laborTaxPercentage: number;
    public crewTravelTaxPercentage: number;
    public freightTaxPercentage: number;
    public preparedBy: string;
    public partInfos: PartInfoResponseModel[];
    public userId: number;
    public leadTime: string;
}

export class ReportComputationSheetModel {
    public id: number;
    public customerCompany: CustomerCompany;
    public customerStorageLocation: CustomerStorageLocation;
    public description: ComputationSheetDescription;
    public crewTravelNetPrice: number;
    public freightNetPrice: number;
    public materialDiscountPercentage: number;
    public laborDiscountPercentage: number;
    public materialTaxPercentage: number;
    public laborTaxPercentage: number;
    public crewTravelTaxPercentage: number;
    public freightTaxPercentage: number;
    public preparedBy: string;
    public partInfos: PartInfoModel[];
    public totalFreightWeight: number;
    public totalPriceAllMaterials: number;
    public totalPriceAllLabors: number;
    public materialPriceWithDiscountAndTax: number;
    public laborPriceWithDiscountAndTax: number;
    public crewTravelNetWithTax: number;
    public freightNetWithTax: number;
    public grandTotal: number;
    public reportFormat: ReportFormatEnum
    public timeZone: string;
    public printDate: Date;
    public leadTime: string;
}

export class CustomerCompany {
    name: string;
    companyName: string;
    city: string;
    contact: string;
}

export class CustomerStorageLocation {
    quoteNumber: string;
    name: string;
    city: string;
    description: string;
}

export class ComputationSheetDescription {
    descriptionOfStorage: string;
    descriptionOfSystem: string;
}

export class BindingComputationSheets{
    id:number;
    coverSheet:AqsCoverSheetModel;
}