import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiRoutes } from '../../constants';
import { ErrorModel, HttpService } from './../../../common';
import { AqsCommoditiesViewModel } from './../../models';

@Injectable({
  providedIn: 'root'
})
export class AqsCommoditiesApiService {

  constructor(
    private _httpService: HttpService,
    private _router: Router,) { }

  public async getAll(): Promise<AqsCommoditiesViewModel[] | ErrorModel> {
    try {
      if (this._router.url.indexOf('/back-door') > -1) {
        return this.getFakeData();
      }
      const result = await this._httpService.get(ApiRoutes.Commodity.getAll);
      return result
    } catch (error) {
      return this.processError(error);
    }
  }

  private processError(error: any) {
    if (error instanceof ErrorModel) {
      return Promise.reject(error);
    }

    console.log(error);
    return null;
  }

  private getFakeData(): AqsCommoditiesViewModel[] {
    let fakeModel: AqsCommoditiesViewModel[] = [];
    fakeModel.push({ id: 0, name: 'Alfalfa', aa: 0.00202302928025107, bb: 0.0202692405869278 });
    fakeModel.push({ id: 1, name: 'Barley', aa: 0.000676450415583951, bb: 0.0670561331433332 });
    fakeModel.push({ id: 2, name: 'Brome grass', aa: 0.00042673273880296, bb: 0.0451104908023415 });
    fakeModel.push({ id: 3, name: 'Canola (campestris)', aa: 0.00158049162519615, bb: 0.0299720604285874 });
    fakeModel.push({ id: 4, name: 'Canola (napus)', aa: 0.00158049162519615, bb: 0.0179832357726849 });
    fakeModel.push({ id: 5, name: 'Clover alsike', aa: 0.00193136076598969, bb: 0.0113792228068923 });
    for (let index = 6; index < 34; index++) {
      fakeModel.push({ id: index, name: `Alfalfa ${index + 1}`, aa: 0.00202302928025107, bb: 0.0202692405869278 });
    }
    return fakeModel;
  }
}
