import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';

import { ErrorModel, RoutePaths } from '../common';
import { LocalStorageService, AuthorizeService } from '../common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _localStorageService: LocalStorageService,
    private _authorizeService: AuthorizeService,
    private _router: Router,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const modifiedRequest = this.normalizeRequestHeaders(request);
    return next.handle(modifiedRequest)
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.tryAuthWithRefreshToken(request, next, error);
          } else {
            return throwError(error);
          }
        }),
        switchMap((event: HttpEvent<any>) => {
          return of(event);
        })
      );
  }

  private async tryAuthWithRefreshToken(request: HttpRequest<any>, next: HttpHandler, error: any): Promise<HttpEvent<any>> {
    const result = await this._authorizeService.refreshTokens();
    if (!result || result instanceof ErrorModel) {
      this._router.navigate([RoutePaths.SIGN_IN]);
    }

    const modifiedRequest = this.normalizeRequestHeaders(request);
    return next.handle(modifiedRequest).toPromise();
  }

  private normalizeRequestHeaders(request: HttpRequest<any>) {
    let requestToRetrun = request;
    const token = this._localStorageService.getAuthToken();
    if (token) {
      const headers = request.headers.set('Authorization', `Bearer ${token}`);
      requestToRetrun = request.clone({ headers });
    }
    return requestToRetrun;
  }
}