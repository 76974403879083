import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { ErrorModel } from '../../../common';
import { EmailServicePostModel, EmailServiceViewModel } from "../../models";
import { EmailServiceApiService } from "../http";

@Injectable()
export class EmailServiceDataSourceService {
    public emailServices$: BehaviorSubject<EmailServiceViewModel[]> = new BehaviorSubject(null);
    public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
    constructor(
        private _emailServiceApi: EmailServiceApiService) { }

    public async getAll(userCompanyId: number) {
        try {
            const data = await this._emailServiceApi.loadAll(userCompanyId);
            if (data) {
                this.emailServices$.next(data as EmailServiceViewModel[]);
                this.errors$.next(null);
            }
        } catch (error) {
            this.errors$.next(error);
        }
    }

    public async addEditSevice(emailService: EmailServicePostModel): Promise<boolean | ErrorModel> {
        try {
            const result = await this._emailServiceApi.addEdit(emailService) as EmailServiceViewModel;
            if (result) {
                let emailServices = this.emailServices$.getValue();
                if (emailService.id == 0) {
                    emailServices.push(result);
                }
                else {
                    emailServices = emailServices.map(l => l.id === result.id ? { ...l, ...result } : l)
                }
                this.emailServices$.next(emailServices);
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        }
        catch (error) {
            return error instanceof ErrorModel ? error : false;
        }
    }

    public async delete(id: number, companyId: number): Promise<boolean | ErrorModel> {
        try {
            const result = await this._emailServiceApi.delete(id, companyId);
            if (result) {
                const emailServices = this.emailServices$.getValue().filter(x => x.id != id);
                this.emailServices$.next(emailServices);
                Promise.resolve(true)
            }
            return Promise.resolve(false);
        }
        catch (error) {
            return error instanceof ErrorModel ? error : false;
        }
    }
}
